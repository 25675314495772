import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { AccordionModule } from 'primeng/accordion';
import { Button } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SliderModule } from 'primeng/slider';
import { AnnouncementService } from '../../services/announcement.service';
import { BoxShadowModel } from './types/box-shadow.model';

@Component({
  selector: 'app-box-shadow-generator',
  standalone: true,
  imports: [SliderModule, FormsModule, InputNumberModule, ColorPickerModule, CheckboxModule, Button, InputTextareaModule, AccordionModule],
  templateUrl: './box-shadow-generator.component.html',
  styleUrl: './box-shadow-generator.component.scss',
})
export class BoxShadowGeneratorComponent {
  boxShadows: BoxShadowModel[] = [];
  @Output() onChange = new EventEmitter();
  private _styles: Partial<StylesDto> = {};
  private readonly announcement = inject(AnnouncementService);
  private readonly shadowRegex = /(?:(inset)\s+)?(-?\d+px)\s+(-?\d+px)\s+(\d+px)\s+(\d+px)\s+(rgba?\(.+?\)|#[0-9a-fA-F]{3,8})/;

  @Input() set styles(dto: Partial<StylesDto>) {
    this._styles = dto || {};
    if (dto?.boxShadow && dto.boxShadow !== 'none') this.parseBoxShadow(dto.boxShadow);
  }

  get combinedBoxShadows(): string {
    const boxShadow = this.boxShadows
      .map(
        shadow =>
          `${shadow.isInset ? 'inset ' : ''}${shadow.offsetX}px ${shadow.offsetY}px ${shadow.blurRadius}px ${shadow.spreadRadius}px ${shadow.shadowColor}`,
      )
      .join(', ');
    this._styles.boxShadow = boxShadow;
    return boxShadow;
  }

  addShadow(): void {
    this.boxShadows.push(
      new BoxShadowModel({
        offsetX: 0,
        offsetY: 0,
        blurRadius: 10,
        spreadRadius: 0,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        isInset: false,
      }),
    );
    this.onChange.emit();
  }

  removeShadow(index: number): void {
    this.boxShadows.splice(index, 1);
    this.onChange.emit();
  }

  copyCss(): void {
    navigator.clipboard.writeText(this.combinedBoxShadows).then(() => this.announcement.success('CSS box shadow successfully copied to clipboard'));
  }

  private parseBoxShadow(cssString: string): void {
    this.boxShadows = [];
    const regex = /,(?![^(]*\))/;
    const shadowParts = cssString.split(regex).map(part => part.trim());

    shadowParts.forEach(shadow => {
      const trimmedShadow = shadow.trim();
      const match = trimmedShadow.match(this.shadowRegex);

      if (match) {
        const [, inset, offsetX, offsetY, blurRadius, spreadRadius, shadowColor] = match;

        this.boxShadows.push(
          new BoxShadowModel({
            offsetX: parseInt(offsetX.replace('px', ''), 10),
            offsetY: parseInt(offsetY.replace('px', ''), 10),
            blurRadius: parseInt(blurRadius.replace('px', ''), 10),
            spreadRadius: parseInt(spreadRadius.replace('px', ''), 10),
            shadowColor,
            isInset: !!inset,
          }),
        );
      } else {
        throw new Error(`Invalid box-shadow format: "${shadow}"`);
      }
    });
    this.onChange.emit();
  }
}
