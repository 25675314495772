import { Component, Injector, Input, OnInit, Type } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { ListWidgetLinkSettingsTypes } from '@widgets/shared/components/artifact-list-table/types/list-widget-link-settings.types';
import { ArtifactAdditionalData } from '@widgets/shared/types/artifact-additional-data';
import {
  APPLICATION_ID,
  ARTIFACT,
  ARTIFACT_ADDITIONAL_DATA,
  CURRENT_PAGE,
  DISABLED,
  HASH,
  ID_PREFIX,
  IS_CLICKABLE,
  IS_IN_CARD,
  IS_IN_SIDEBAR,
  IS_LAYOUT_MODE,
  IS_LIST_MATRIX_CARD,
  IS_PREVIEW_MODE,
  IS_SHOW_PICKER,
  LABEL,
  LIST_SELECTION_SETTINGS,
  PAGE_ID,
  WIDGET,
  WIDGET_ID,
} from '@widgets/widgets-core/constants/widgets-core.constants';
import { WidgetOption } from '@widgets/widgets-core/types/widgets.types';

@Component({
  selector: 'app-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss'],
})
export class WidgetContainerComponent implements OnInit {
  @Input() applicationId: string;
  @Input() pageId: string;
  @Input() page: Page;
  @Input() widget: BlockPartWidget;
  @Input() label: string;
  @Input() isLayoutMode: boolean;
  @Input() isPreviewMode: boolean;
  @Input() disabled: boolean;
  @Input() hash: string | null = null;
  @Input() idPrefix: string;
  @Input() isListMatrixCard: boolean;
  @Input() artifactAdditionalData: ArtifactAdditionalData | null = null;
  @Input() isInSidebar: boolean;
  @Input() isInCardWidget: boolean;
  @Input() artifact: ArtifactResponseDto;
  @Input() isClickable: boolean;
  @Input() listSelectionSettings: ListWidgetLinkSettingsTypes;

  widgetComponent: Type<any>;
  injectorForWidgetComponent: Injector;

  constructor(
    private readonly injector: Injector,
    private readonly widgetService: WidgetService,
  ) {}

  ngOnInit(): void {
    this.injectorForWidgetComponent = this.getInjectorForWidgetComponent();

    try {
      this.widgetComponent = this.getWidgetComponentConstructor();
    } catch (e) {
      console.error(e);
      console.error(`Widget: `, this);
    }
  }

  onSelectedChange(event: any): void {
    console.log(event);
  }

  private getInjectorForWidgetComponent(): Injector {
    return Injector.create({
      providers: [
        { provide: APPLICATION_ID, useValue: this.applicationId },
        { provide: PAGE_ID, useValue: this.pageId },
        { provide: CURRENT_PAGE, useValue: this.page },
        { provide: WIDGET_ID, useValue: this.widget.id },
        { provide: WIDGET, useValue: this.widget },
        { provide: LABEL, useValue: this.label },
        { provide: IS_LAYOUT_MODE, useValue: this.isLayoutMode },
        { provide: DISABLED, useValue: this.disabled },
        { provide: HASH, useValue: this.hash },
        { provide: IS_PREVIEW_MODE, useValue: this.isPreviewMode },
        { provide: ID_PREFIX, useValue: this.idPrefix },
        { provide: IS_LIST_MATRIX_CARD, useValue: this.isListMatrixCard },
        { provide: ARTIFACT_ADDITIONAL_DATA, useValue: this.artifactAdditionalData },
        { provide: IS_IN_SIDEBAR, useValue: this.isInSidebar },
        { provide: IS_IN_CARD, useValue: this.isInCardWidget },
        { provide: ARTIFACT, useValue: this.artifact },
        { provide: IS_CLICKABLE, useValue: this.isClickable },
        { provide: IS_SHOW_PICKER, useValue: false },
        { provide: LIST_SELECTION_SETTINGS, useValue: this.listSelectionSettings },
      ],
      parent: this.injector,
    });
  }

  private getWidgetComponentConstructor(): Type<any> {
    const widgetOptions = this.widgetService.getWidgetOptions();
    const option = widgetOptions.find(({ code }: WidgetOption<any>) => code === this.widget.code);
    return option ? option!.component : null!;
  }
}
