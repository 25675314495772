import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import {
  ElementDisplayStylesDtoKeysArray,
  FlexContainerStylesDtoKeysArray,
  StylesDtoKeys,
} from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { DisplayAtOverlayControlService } from '@shared/components/common-display-at';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { HyperlinkDisplayVariant } from '@shared/types/display-at-types';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { DisplayAtControlService } from '../../services';
import { AbstractDisplayAtComponent } from '../abstract-display-at.component';

@Component({
  selector: 'app-display-at-hyperlink',
  templateUrl: './display-at-hyperlink.component.html',
  styleUrls: ['display-at-hyperlink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtHyperlinkComponent extends AbstractDisplayAtComponent {
  @Input() tooltipEnabled: boolean;
  @Input() dataType: NewDataType;
  @Input() attribute: NewAttribute;
  @Input() valueStyles: FormatStyles;
  @Input() columnStylesEvent: RuntimeStateNotification<any> | undefined;

  displayVariant = HyperlinkDisplayVariant;
  topContainerRelevantStyleKeys: StylesDtoKeys[] = [];

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly datOverlayControlService: DisplayAtOverlayControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(isPreviewMode, displayAtControlService, dataTypeHelper, cdr);
    this.setCssKeys();
  }

  get isMultiple(): boolean {
    return this.attribute.multipleValues;
  }

  private setCssKeys() {
    this.topContainerRelevantStyleKeys = [...FlexContainerStylesDtoKeysArray, ...ElementDisplayStylesDtoKeysArray];
  }
}
