import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayAtEnumModule } from '@shared/components/common-display-at';
import { UserAutocompleteComponent } from '@shared/components/user-autocomplete/user-autocomplete.component';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { FilterWidgetSettingsComponent } from '@widgets/filter-widget/components/filter-widget-settings/filter-widget-settings.component';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TagModule } from 'primeng/tag';
import { FilterWidgetComponent } from './filter-widget.component';

@NgModule({
  declarations: [FilterWidgetComponent, FilterWidgetSettingsComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ElvisSharedModule,
    FormsModule,
    InputTextModule,
    TagModule,
    DisplayAtEnumModule,
    TranslateModule,
    ButtonModule,
    CheckboxModule,
    ArtifactFiltersModule,
    UserAutocompleteComponent,
  ],
  exports: [FilterWidgetComponent],
})
export class FilterWidgetModule {}
