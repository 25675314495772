import { Component, OnInit } from '@angular/core';
import { TenantUserListResponseDto } from '@api/models';
import { TenantUserService } from '@api/services/tenant-user.service';
import { UserListModel } from '@private/pages/user-management/user-list/types/user-list.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { EMAIL_KEY, EMAIL_LABEL, ID_KEY, ID_LABEL, IS_TENANT_ADMIN_KEY, IS_TENANT_ADMIN_LABEL } from '@shared/constants/constants';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { TableColumn } from '@shared/types/table.types';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  m = new UserListModel();
  loadDataMethod: (params?: Record<string, string>, extras?: Record<string, any>) => Promise<TenantUserListResponseDto>;

  constructor(
    private readonly tenantUserService: TenantUserService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly cache: NewCacheService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Users' }]);
    this.m.columns = [new TableColumn(ID_LABEL, ID_KEY), new TableColumn(EMAIL_LABEL, EMAIL_KEY), new TableColumn(IS_TENANT_ADMIN_LABEL, IS_TENANT_ADMIN_KEY)];
    this.m.loggedUserMeta = this.cache.userMeta;
    this.loadDataMethod = async (params?: Record<string, string>) => lastValueFrom(this.tenantUserService.userControllerList(params));
  }
}
