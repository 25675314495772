import { Pipe, PipeTransform } from '@angular/core';
import { MenuWidgetItemActions } from '@shared/types/actions.types';
import { ActionType } from '@widgets/shared/types/click-action-settings-dto';

@Pipe({ name: 'isMenuWidgetSpecial' })
export class IsMenuWidgetSpecialPipe implements PipeTransform {
  transform(actionType: ActionType): boolean {
    return Object.keys(MenuWidgetItemActions).includes(actionType);
  }
}
