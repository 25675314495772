import { Pipe, PipeTransform } from '@angular/core';
import { IsUser } from '@shared/methods/data-type.methods';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { GroupAttributeItem } from '../components/artifact-list-table/types/list-widget-grouping.types';

@Pipe({ name: 'isGroupingByUser', standalone: true })
export class IsGroupingByUserPipe implements PipeTransform {
  transform(groupAttributeOption: SelectOption<string, GroupAttributeItem>[]): boolean {
    if (!groupAttributeOption?.length) return false;
    const groupAttribute = groupAttributeOption[0].value;

    return NonAttributeKeys.isUserSpecificAttributeKey(groupAttribute.id) || IsUser(groupAttribute.baseDataType);
  }
}
