import { ArtifactLinkResponseDto } from '@api/models';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { Application } from '@private/pages/application-management/application/types/application.types';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { LinkMethods } from '@shared/methods/link.methods';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetActionAfterLogin, ArtifactWidgetModel, ArtifactWidgetType } from '@widgets/artifact-widget/types/artifact-widget.types';
import { ArtifactWidgetAction } from '@widgets/shared/types/custom-event';
import { BorderStylesEnum, BorderWidthEnum, StyleOptions, WidgetSaveButtonVisibilityEnum } from '@widgets/shared/types/style.types';

export class ArtifactWidgetOptions extends StyleOptions {
  dataTypes = new ListContainer<NewDataType>();
  attributes = new ListContainer<NewAttribute>();
  systemAttributes = new ListContainer<NewAttribute>();
  artifactTypes = new ListContainer<NewArtifactType>();
  applications = new ListContainer<Application>();
  pages = new ListContainer<Page>();
  currentUser: ArtifactLinkResponseDto;
  linkTypes = new ListContainer<LinkType>();
  linkTypeOptions: SelectOption<string, LinkType, LinkDirection>[] = [];
  linkedArtifactLinkTypeOptions: SelectOption<string, LinkType, LinkDirection>[] = [];
  allLinkTypeOptions: SelectOption<string, LinkType, LinkDirection>[] = [];
  clientAttributes: SelectOption<string, NewClientAttribute>[] = [];
  clientAttributesPattern: Record<string, any> = {};
  alreadyUsedFormItemsMap: Record<string, boolean> = {};
  linkTypeIdsMap: Record<string, boolean> = {};
  currentFormItem: SelectOption<string, NewClientAttribute | LinkType>[] = [];
  saveButton: SelectOption<string, WidgetSaveButtonVisibilityEnum>[] = [];
  saveAndNotifyButton: SelectOption<string, WidgetSaveButtonVisibilityEnum>[] = [];
  widgetTypes = new ListContainer<ArtifactWidgetType>();
  actionsAfterLogin: SelectOption<ArtifactWidgetActionAfterLogin, ArtifactWidgetActionAfterLogin>[] = [];
  actionOptions: SelectOption<ArtifactWidgetAction, ArtifactWidgetAction>[] = GetSelectOptionsFromEnum(ArtifactWidgetAction);

  constructor() {
    super();
    Object.assign(this, {
      borderWidth: GetSelectOptionsFromEnum(BorderWidthEnum),
      borderStyles: GetSelectOptionsFromEnum(BorderStylesEnum),
    });
    this.widgetTypes.setList(Object.keys(ArtifactWidgetType).map(key => ArtifactWidgetType[key as keyof typeof ArtifactWidgetType]));
  }

  init(cache: NewCacheService): void {
    this.setCurrentUser(cache);
    this.setPages(cache);
    this.setApplications(cache);
    this.setArtifactTypes(cache);
    this.setAttributes(cache);
    this.setDataTypes(cache);
    this.setLinkTypes(cache);
    this.setActionAfterLoginOptions();
  }

  setCurrentUser(cache: NewCacheService): void {
    this.currentUser = cache.userProfile.value as ArtifactLinkResponseDto;
  }

  setPages(cache: NewCacheService): void {
    const pages = (cache.data.pages.getValue() as PageResponseDto[]).map(dto => new Page(dto));
    this.pages.setList(pages, ID_KEY);
  }

  setApplications(cache: NewCacheService): void {
    const applications = cache.data.applications.getValue() as ApplicationResponseDto[];
    this.applications.setList(applications, ID_KEY);
  }

  setArtifactTypes(cache: NewCacheService): void {
    const artifactTypes = (cache.data.artifactTypes.getValue() as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto));
    this.artifactTypes.setList(artifactTypes, ID_KEY);
  }

  setAttributes(cache: NewCacheService): void {
    const attributes = (cache.data.attributes.getValue() as AttributeResponseDto[]).map(dto => new NewAttribute(dto));
    this.attributes.setList(attributes, ID_KEY);
  }

  setDataTypes(cache: NewCacheService): void {
    const dataTypes = (cache.data.dataTypes.getValue() as DataTypeResponseDto[]).map(dto => new NewDataType(dto));
    this.dataTypes.setList(dataTypes, ID_KEY);
  }

  setLinkTypes(cache: NewCacheService): void {
    const linkTypes = (cache.data.linkTypes.getValue() as LinkTypeResponseDto[]).map(dto => new LinkType(dto));
    this.linkTypes.setList(linkTypes, ID_KEY);
  }

  async setLinkTypeOptionsAndIdsMap(restrictions: ArtifactTypeLinkRestriction[], linkTypes: ListContainer<LinkType>): Promise<void> {
    try {
      this.linkTypeOptions = [];
      this.linkedArtifactLinkTypeOptions = [];
      this.linkTypeIdsMap = {};

      const relevantLinkTypes = LinkMethods.getLinkTypesFromRestrictions(restrictions);
      const usedLinks = new Set<string>();

      relevantLinkTypes.forEach((relevantLinkType: SelectOption<string, string, LinkDirection>) => {
        const linkType = relevantLinkType.value ? linkTypes.listMap[relevantLinkType.value] : null;
        const linkName = relevantLinkType.value + '_' + relevantLinkType.meta;

        if (linkType && linkType.id && !usedLinks.has(linkName)) {
          usedLinks.add(linkName);
          this.linkTypeOptions.push(new SelectOption(relevantLinkType.label || '', linkType, relevantLinkType.meta));
          this.linkedArtifactLinkTypeOptions.push(new SelectOption(relevantLinkType.label || '', linkType, relevantLinkType.meta));
          this.linkTypeIdsMap[linkType.id] = true;
        }
      });
    } catch (e) {
      console.error('Failed to load link types', e);
    }
  }

  createOptionsFromEnums(model: ArtifactWidgetModel): void {
    this.saveButton = Object.keys(WidgetSaveButtonVisibilityEnum).map((key: any) => {
      const disabled =
        key === WidgetSaveButtonVisibilityEnum.hide &&
        !model.settings.automaticSave &&
        model.settings.saveButtonVisibility() === WidgetSaveButtonVisibilityEnum.hide;
      return new SelectOption(key, WidgetSaveButtonVisibilityEnum[key as keyof typeof WidgetSaveButtonVisibilityEnum], null, disabled);
    });
    this.saveAndNotifyButton = Object.keys(WidgetSaveButtonVisibilityEnum).map((key: any) => {
      const disabled =
        key === WidgetSaveButtonVisibilityEnum.hide &&
        !model.settings.automaticSave &&
        model.settings.saveAndNotifyButtonVisibility() === WidgetSaveButtonVisibilityEnum.hide;
      return new SelectOption(key, WidgetSaveButtonVisibilityEnum[key as keyof typeof WidgetSaveButtonVisibilityEnum], null, disabled);
    });
  }

  private setActionAfterLoginOptions(): void {
    this.actionsAfterLogin = Object.values(ArtifactWidgetActionAfterLogin).map(value => new SelectOption(value, value));
  }
}
