<div class="artifact-item-picker" cdkDropList (cdkDropListDropped)="dropItem($event)">
  <div class="row column-header mb-2" *ngIf="model?.form?.length" [@fadeAnimation]>
    <div class="col-1 table-item"></div>
    <div class="col-6 table-item" translate> Attribute</div>
    <div class="col-2 table-item" translate> Columns</div>
    <div class="col-3 table-item p-text-right" translate>Action</div>
  </div>

  <div *ngFor="let item of model.form; let itemIndex = index;" cdkDrag
       (click)="selectItem(item)"
       [ngClass]="model.selected.item | activeItem : item"
       class="row row-setting">
    <div class="item-placeholder" *cdkDragPlaceholder></div>

    <div class="col-1 table-item drag-handle">
      <span class="bi bi-arrows-move" cdkDragHandle pTooltip="{{'Move' | translate}}" tooltipPosition="top"
            pRipple></span>
    </div>

    <div class="col-6 table-item" style="position: relative">
      <div *ngIf="item.tabSettings.tabKey && item.tabSettings.tabValue" class="item-tab-info">
        {{ item.tabSettings.tabKey }} = {{ item.tabSettings.tabValue }}
      </div>
      <span class="dynamic-label p-float-label">
        <p-dropdown
          [options]="(model.options.clientAttributes | getAttributeAndLinkTypeOptions : model.options.linkTypeOptions)"
          [(ngModel)]="item.value"
          [autoDisplayFirst]="false"
          (onChange)="onAttributeOrLinkTypeChange($event.value, itemIndex)"
          [filter]="true"
          optionLabel="label"
          placeholder="Choose attribute or link-type"
          appendTo="body">
          <ng-template let-item pTemplate="item">
            <app-link-option-with-arrow [label]="item.label" [direction]="item.meta"></app-link-option-with-arrow>
          </ng-template>
          <ng-template let-item pTemplate="selectedItem">
            <app-link-option-with-arrow [label]="item.label" [direction]="item.meta"></app-link-option-with-arrow>
          </ng-template>
        </p-dropdown>
      </span>
    </div>

    <div class="col-2 table-item settings_form_item">
      <span class="dynamic-label p-float-label">
        <input [(ngModel)]="item.columns" pInputText type="text" placeholder="12" />
      </span>
    </div>

    <div class="col-3 table-item table-item-buttons p-text-right">
      <ng-container *ngIf="item?.attribute">
        <button pTooltip="Copy attribute style" pButton type="button" class="p-button p-button-warning"
                [icon]="styleService.copiedAttributeId === item?.attribute?.value?.id ? 'bi bi-clipboard-check':'pi pi-copy'"
                (click)="styleService.copyStyle(item?.attribute?.value?.id, itemType.attribute, model); $event?.stopPropagation()">
        </button>
        <button *ngIf="!!styleService.copiedAttributeFormatSettings"
                (click)="onPasteAttributeStyle($event, item)"
                pTooltip="Paste attribute style" pButton type="button" icon="bi bi-clipboard-plus"
                class="p-button p-button-secondary">
        </button>
      </ng-container>

      <ng-container *ngIf="item?.linkType">
        <button
          pTooltip="Copy link-type style" pButton type="button" class="p-button p-button-warning mr-2"
          [icon]="styleService.copiedLinkTypeId === item?.linkType?.value?.id + '_' + item?.linkType?.meta ? 'bi bi-clipboard-check':'pi pi-copy'"
          (click)="styleService.copyStyle(item?.linkType?.value?.id +  '_' + item?.linkType?.meta, itemType.linkType, model); $event?.stopPropagation()">
        </button>
        <button *ngIf="!!styleService.copiedLinkTypeFormatSettings"
                (click)="styleService.pasteStyle(model.formatsMap.linkType[item?.linkType?.value?.id + '_' + item?.linkType?.meta]); $event?.stopPropagation()"
                pTooltip="Paste link-type style" pButton type="button" icon="bi bi-clipboard-plus"
                class="p-button p-button-secondary">
        </button>
      </ng-container>

      <button (click)="onTabSettingsClick($event, item)"
              pButton class="p-button p-button-info" icon="bi bi-ui-checks-grid" pTooltip="Tab settings">
      </button>
      <button pButton class="p-button p-button-danger" icon="pi pi-trash" pTooltip="Remove item"
              [disabled]="(model.form.length <= 1) || (model.settings.widgetType() | isLoginWidgetType) || (model.settings.widgetType() | isRecoverPasswordWidgetType)"
              (click)="onRemoveItemClick($event, itemIndex)">
      </button>
    </div>
  </div>
</div>

<p-overlayPanel #tabOp appendTo="body" [showCloseIcon]="true" [style]="{'max-width':'600px'}">
  <ng-template pTemplate>
    <div class="row" style="justify-content: space-between">
      <div class="col-12 tooltip-container">
        <span class="pi pi-question-circle tooltip" [pTooltip]="ARTIFACT_WIDGET_TABS_TOOLTIP | translate"
              [escape]="false"></span>
      </div>
      <div class="col-12 pt-0 pb-0">
        <p-checkbox [(ngModel)]="selectedItemClickedOnTab.tabSettings.alwaysShow"
                    (ngModelChange)="onTabSettingsChange()"
                    binary="true" id="alwaysShow" class="mr-2">
        </p-checkbox>
        <label for="alwaysShow" translate>Ignore tab settings and always show this attribute</label>
      </div>
      <div class="col-12" [ngClass]="{'p-disabled': selectedItemClickedOnTab.tabSettings.alwaysShow}">
        <p-checkbox [(ngModel)]="selectedItemClickedOnTab.tabSettings.showWhenNoTabIsActive"
                    (ngModelChange)="onTabSettingsChange()"
                    binary="true" id="showWhenNoTabIsActive" class="mr-2">
        </p-checkbox>
        <label for="showWhenNoTabIsActive" translate>Also show when no tab is active</label>
      </div>

      <div class="row" [ngClass]="{'p-disabled': selectedItemClickedOnTab.tabSettings.alwaysShow}">
        <div class="col-5">
          <span> Tab url key</span>
          <input type="text" pInputText placeholder="Enter tab key" style="width: 100%"
                 [(ngModel)]="selectedItemClickedOnTab.tabSettings.tabKey"
                 (blur)="onTabSettingsChange()" />
        </div>
        <div class="col-5">
          <span> Tab url value </span>
          <input type="text" pInputText placeholder="Enter tab value" style="width: 100%"
                 [(ngModel)]="selectedItemClickedOnTab.tabSettings.tabValue"
                 (blur)="onTabSettingsChange()" />
        </div>
        <div class="col-2" style="align-self: end">
          <button (click)="onClearClick()" pButton class="p-button p-button-danger" icon="pi pi-trash"
                  style="width: 100%"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
