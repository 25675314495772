import {
  RecordDto,
  WorkflowActionDto,
  WorkflowConditionGroupDto,
  WorkflowCreateRequestDto,
  WorkflowResponseDto,
  WorkflowTriggerDto,
  WorkflowUpdateRequestDto,
} from '@api/models';
import { SaveableItem } from '@shared/interfaces/saveable-item';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { WorkflowAction } from './action.types';
import { RuleConditionGroup } from './conditions/rule-condition-group';
import { OnRuleActivation, OnRuleDeactivation } from './rule-common.types';
import { WorkflowTrigger } from './rule-trigger.types';

export interface WorkflowSetting {
  isDynamic: boolean;
  value: any;
}

export enum WorkflowOwnerType {
  DATA_TYPE = 'data-type',
  ATTRIBUTE = 'attribute',
  ARTIFACT_TYPE = 'artifact-type',
  ARTIFACT = 'artifact',
  LINK_TYPE = 'link-type',
  LINK = 'link',
  WIDGET = 'widget',
  PAGE = 'page',
  TEMPLATE = 'template',
  CUSTOM = 'CUSTOM',
}

export const ImplementedWorkflowOwnerType = {
  [WorkflowOwnerType.DATA_TYPE]: false,
  [WorkflowOwnerType.ATTRIBUTE]: false,
  [WorkflowOwnerType.ARTIFACT_TYPE]: false,
  [WorkflowOwnerType.ARTIFACT]: true,
  [WorkflowOwnerType.LINK_TYPE]: false,
  [WorkflowOwnerType.LINK]: true,
  [WorkflowOwnerType.WIDGET]: false,
  [WorkflowOwnerType.PAGE]: true,
  [WorkflowOwnerType.TEMPLATE]: false,
  [WorkflowOwnerType.CUSTOM]: true,
};

export enum WorkflowType {
  be = 'be',
  fe = 'fe',
}

export interface RuleAdministrationFilter {
  name?: string;
  artifactTypeIds?: string[];
}

export class WorkflowRule implements Rule, SaveableItem<WorkflowUpdateRequestDto>, OnRuleActivation, OnRuleDeactivation {
  id: string;
  /** rule name */
  name: string;
  /** rule description */
  description?: string;
  /** default artifact type */
  defaultArtifactTypeId?: string;
  /** trigger */
  triggers: WorkflowTrigger[] = [];
  /** owner of the rule */
  ownerId: string | null;
  /** where the rules are used */
  usedIn: string[] | null = null;
  /** priority */
  priority: number;
  /** set of condition's groups */
  condition: RuleConditionGroup | null = null;
  /** whether the rule is active or not */
  active = true;
  /** whether the rule is global or not */
  global = true;
  /** whether is system or not */
  isProtected = false;
  /** whether workflow is on backend or frontend */
  type: WorkflowType;
  /** type of the owner, e.g. Widget, Page, Artifact, Artifact-type... */
  ownerType: WorkflowOwnerType;
  /** list of actions */
  actions: WorkflowAction[] = [];
  created: RecordDto;
  deleted: RecordDto | null;
  updated: RecordDto;
  selected: boolean;

  constructor(dto?: Rule | WorkflowResponseDto) {
    // this.id = SharedMethods.getUniqueId(4);
    if (dto) {
      Object.assign(this, dto);
    }
  }

  toServer(converterService: RuleConditionValueConverterService): WorkflowUpdateRequestDto {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      defaultArtifactTypeId: this.defaultArtifactTypeId,
      priority: this.priority,
      active: this.active,
      global: this.global,
      condition: this.condition?.toServer(converterService) || null,
      usedIn: this.transformUsedInToServer(this.usedIn),
      actions: this.actions.map(action => action.toServer()),
      triggers: this.triggers.map(trigger => trigger.toServer()),
    };
  }

  toCreateDto(converterService: RuleConditionValueConverterService): WorkflowCreateRequestDto {
    return {
      name: this.name,
      description: this.description,
      defaultArtifactTypeId: this.defaultArtifactTypeId,
      priority: this.priority,
      active: this.active,
      global: this.global,
      condition: this.condition?.toServer(converterService) || null,
      usedIn: this.transformUsedInToServer(this.usedIn),
      actions: this.actions.map(action => action.toServer()),
      triggers: this.triggers.map(trigger => trigger.toServer()),
      ownerType: this.ownerType,
      type: this.type,
      ownerId: this.ownerId,
    };
  }

  fromDto(): void {
    throw new Error('Not implemented, use service for loading rules from dto!');
  }

  onRuleActivation(): void {
    this.actions.forEach(action => action.onRuleActivation(this.ownerId));
  }

  onRuleDeactivation(): void {
    this.actions.forEach(action => action.onRuleDeactivation(this.ownerId));
  }

  transformUsedInToServer(usedIn: string[] | null): string[] | null {
    if (!usedIn) return usedIn;

    let usedInFiltered: string[] | null = this.usedIn!.filter(usedIn => usedIn !== null);
    if (!usedInFiltered.length) {
      usedInFiltered = null;
    }
    return usedInFiltered;
  }
}

export interface Rule extends WorkflowResponseDto {
  /** id of the rule - may be generated id */
  id: string;
  /** trigger */
  triggers: WorkflowTriggerDto[];
  /** owner of the rule */
  ownerId: string | null;
  /** where the rules are used */
  usedIn: string[] | null;
  /** rule name */
  name: string;
  /** rule description */
  description?: string;
  /** default artifact type */
  defaultArtifactTypeId?: string;
  /** priority */
  priority: number;
  /** set of condition's groups */
  condition: WorkflowConditionGroupDto | null;
  /** whether the rule is active or not */
  active: boolean;
  /** whether the rule is global or not */
  global: boolean;
  /** whether is system or not */
  isProtected: boolean;
  /** whether workflow is on backend or frontend */
  type: WorkflowType;
  /** type of the owner, e.g. Widget, Page, Artifact, Artifact-type... */
  ownerType: WorkflowOwnerType;
  /** list of actions */
  actions: WorkflowActionDto[];
  // preActions?: RuleAction[];
  // postActions?: RuleAction[];
}
