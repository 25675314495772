import { CustomScriptModelDto } from '@api/models/custom-script-model-dto';
import { SharedMethods } from '../methods/shared.methods';

export class CustomScriptModel implements CustomScriptModelDto {
  id: string = SharedMethods.getUniqueId();
  type: ScriptType = ScriptType.external;
  name: string = '';
  url?: string;
  body?: string;
  disabled?: boolean;
  isModule?: boolean;
  defer?: boolean;

  constructor(model?: Partial<CustomScriptModel>) {
    model && Object.assign(this, model);
  }
}

export enum ScriptType {
  external,
  custom,
}
