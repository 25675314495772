import { Pipe, PipeTransform } from '@angular/core';
import { NewArtifact } from '@shared/types/artifact.types';
import { ListWidgetTableSettings } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';
import { Params } from '@angular/router';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';

@Pipe({
  name: 'rowSelectorBackgroundColor',
})
export class RowSelectorBackgroundColorPipe implements PipeTransform {
  transform(
    artifactId: string,
    rowIndex: number,
    settings: ListWidgetTableSettings,
    nowSelectedArtifacts: Map<string, NewArtifact>,
    queryParams: Params,
    hoveredRowId?: string,
  ): string {

    const action = settings.actions.find(action => action.type === ArtifactListItemClickAction.addToLink);

    if (action && action.shouldEmitKey && action?.emittingKey && queryParams[action.emittingKey.toLowerCase()] === artifactId) {
      return (action.model?.selectedBackground || '#e9ecef') + ' !important' || '';
    }

    if (!settings?.customColors) {
      return "";
    }

    if (hoveredRowId && hoveredRowId === artifactId) {
      return settings.background.hover || '#e9ecef';
    }

    if (settings.background.active && nowSelectedArtifacts.has(artifactId)) {
      return settings.background.active;
    }

    if ((settings.rowsStriped && rowIndex % 2) && settings.background.rowsOdd) {
      return settings.background.rowsOdd;
    }

    if(settings.background.rows) return settings.background.rows;

    return "";
  }
}
