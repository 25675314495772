import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FolderDataResponseDto } from '@api/models/folder-data-response-dto';
import { TenantFolderService } from '@api/services/tenant-folder.service';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { FadeAnimation } from '@shared/animations/animations';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { SetMenuItemAliases } from '@shared/methods/menu.methods';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { SelectOption } from '@shared/types/shared.types';
import { ButtonContainerPositionEnum } from '@widgets/artifact-widget/types/artifact-widget-button-containers.types';
import { ArtifactWidgetSettings } from '@widgets/artifact-widget/types/artifact-widget-settings.types';
import { ArtifactWidgetModel } from '@widgets/artifact-widget/types/artifact-widget.types';
import { FolderTreeNode } from '@widgets/folder-widget/types/folder-widget.types';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';
import { FolderPickerComponent } from '@widgets/shared/components/folder-picker/folder-picker.component';
import { ARTIFACT_WIDGET_EMPTY_FOLDER_PATH } from '@widgets/shared/constants/widget.constants';
import { WidgetSaveButtonVisibilityEnum } from '@widgets/shared/types/style.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-artifact-widget-settings',
  templateUrl: './artifact-widget-settings.component.html',
  styleUrls: ['./artifact-widget-settings.component.scss'],
  animations: [FadeAnimation],
})
export class ArtifactWidgetSettingsComponent implements OnInit, AfterViewInit {
  @Input() model: ArtifactWidgetModel;
  @Input() artifactType: NewArtifactType;
  @Input() saveButtonOptions: SelectOption<string, WidgetSaveButtonVisibilityEnum>[];
  @Input() saveAndNotifyButtonOptions: SelectOption<string, WidgetSaveButtonVisibilityEnum>[];
  @Input() widgetSettings: ArtifactWidgetSettings;

  @Output() onChangeUrlKey = new EventEmitter();

  @ViewChild('folderPicker') folderPicker: FolderPickerComponent;

  NAME_VALUE = NAME_KEY;
  artifactTypeFormat = ArtifactTypeFormatEnum;
  NAME_KEY = NAME_KEY;
  currentPath = ARTIFACT_WIDGET_EMPTY_FOLDER_PATH;
  pageOptions: SelectOption<string, string>[] = [];
  protected buttonContainerPositionOptions: SelectOption<string, ButtonContainerPositionEnum>[];
  protected unavailableButtonPositions: Partial<Record<ButtonContainerPositionEnum, boolean>> = {};
  protected readonly ButtonContainerPositions = Object.values(ButtonContainerPositionEnum) as ButtonContainerPositionEnum[];

  constructor(private folderService: TenantFolderService) {
    this.unavailableButtonPositions = {};
    this.buttonContainerPositionOptions = Object.values(ButtonContainerPositionEnum).map(position => new SelectOption(position, position));
  }

  ngOnInit(): void {
    this.pageOptions = this.model.options.pages.toSelectOptions('name', ID_KEY);
  }

  ngAfterViewInit(): void {
    this.setPath();
  }

  onListenForUrlParamCheckBoxClick(listen: boolean): void {
    if (!listen && this.widgetSettings.automaticSave) {
      this.widgetSettings.automaticSave = false;
      this.widgetSettings.notifyOnAutomaticSave = false;
      this.onAutomaticSaveCheckBoxClick(false);
    }
  }

  onAutomaticSaveCheckBoxClick(value: boolean): void {
    const saveButtonHideOption = this.saveButtonOptions.find(option => option.value === WidgetSaveButtonVisibilityEnum.hide);
    saveButtonHideOption &&
      (saveButtonHideOption.disabled = !value && this.widgetSettings.saveAndNotifyButtonVisibility() === WidgetSaveButtonVisibilityEnum.hide);
    const saveAndNotifyButtonHideOption = this.saveAndNotifyButtonOptions.find(option => option.value === WidgetSaveButtonVisibilityEnum.hide);
    saveAndNotifyButtonHideOption && (saveAndNotifyButtonHideOption.disabled = false);
    if (!value) this.widgetSettings.notifyOnAutomaticSave = false;

    if (value && this.widgetSettings.initiateEditWithEditButton()) this.widgetSettings.initiateEditWithEditButton.set(false);

    if (
      !value &&
      this.widgetSettings.saveButtonVisibility() === WidgetSaveButtonVisibilityEnum.hide &&
      this.widgetSettings.saveAndNotifyButtonVisibility() === WidgetSaveButtonVisibilityEnum.hide
    ) {
      this.widgetSettings.saveButtonVisibility.set(WidgetSaveButtonVisibilityEnum.always);
      this.model.updateShowSaveButtonFlag();
    }
  }

  onSaveButtonVisibilityChange(): void {
    if (!this.widgetSettings.automaticSave) {
      const saveAndNotifyButtonHideOption = this.saveAndNotifyButtonOptions.find(option => option.value === WidgetSaveButtonVisibilityEnum.hide);
      saveAndNotifyButtonHideOption &&
        (saveAndNotifyButtonHideOption.disabled = this.widgetSettings.saveButtonVisibility() === WidgetSaveButtonVisibilityEnum.hide);
      const saveButtonHideOption = this.saveButtonOptions.find(option => option.value === WidgetSaveButtonVisibilityEnum.hide);
      saveButtonHideOption && (saveButtonHideOption.disabled = this.widgetSettings.saveAndNotifyButtonVisibility() === WidgetSaveButtonVisibilityEnum.hide);
    }
    this.model.updateShowSaveButtonFlag();
  }

  async setPath(): Promise<void> {
    if (!this.widgetSettings.storeToFolderId) return;

    this.folderService.folderControllerGet({ id: this.widgetSettings.storeToFolderId });
    const folder = await lastValueFrom(this.folderService.folderControllerGet({ id: this.widgetSettings.storeToFolderId }));
    folder && (this.currentPath = this.getFolderPath(folder.folderPath) + folder.name);
  }

  onChangeEnableStore(): void {
    if (!this.widgetSettings.enableStoreToFolder) {
      this.widgetSettings.storeToFolderId = null;
      this.currentPath = ARTIFACT_WIDGET_EMPTY_FOLDER_PATH;
    }
  }

  openFolderPicker(): void {
    if (!this.widgetSettings.enableStoreToFolder) return;
    this.model.selected.artifact ??= new NewArtifact();
    this.model.hasExistingArtifactContext.set(!!this.model.selected.artifact?.id);
    this.folderPicker.showFolderPicker(this.model.selected.artifact, false);
  }

  onChangeFolder(folder: FolderTreeNode): void {
    this.widgetSettings.storeToFolderId = folder.id;
    this.currentPath = this.getFolderPath(folder.folderPath) + folder.label;
    if (this.model.selected.artifact) {
      this.model.selected.artifact.folderData = {
        parentId: folder.id,
        path: '' + folder.folderPath + folder.label,
      } as FolderDataResponseDto;
    }
  }

  onChangeKey(): void {
    this.onChangeUrlKey.emit();
  }

  protected onAddButtonContainer(position: ButtonContainerPositionEnum): void {
    this.widgetSettings.buttonContainers[position] = new BlockPartWidget({ code: WidgetType.menu });
  }

  openButtonSettingContainer(position: ButtonContainerPositionEnum): void {
    this.model.advancedModeMenuWidget = this.widgetSettings.buttonContainers[position] || null;
  }

  removeButtonContainer(position: ButtonContainerPositionEnum): void {
    delete this.widgetSettings.buttonContainers[position];
  }

  onWidgetAliasChange(alias: string): void {
    Object.values(this.model.settings.buttonContainers).forEach(container => {
      const model = (container as BlockPartWidget).value.model as MenuWidgetModel;
      model.items.menu.forEach(item => SetMenuItemAliases(item, alias));
    });
  }

  private getFolderPath(path: string | undefined): string {
    return path ? path : '';
  }
}
