import { Injectable } from '@angular/core';
import { AvrTypes, BaseAvrAbstractService } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGenerationAvrService } from '@shared/services/artifact-visual-representation/document-generation/document-generation.avr.service';
import { QrCodesAvrService } from '@shared/services/artifact-visual-representation/qr-codes/qr-codes.avr.service';
import { SwissBillAvrService } from '@shared/services/artifact-visual-representation/swiss-bill/swiss-bill.avr.service';

export const AvrServicesArray = [SwissBillAvrService, DocumentGenerationAvrService, QrCodesAvrService] as const;

type DefinedAvrTypesSettings = `${InstanceType<(typeof AvrServicesArray)[number]>['type']}`;
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avrTypesServicesCheck: [DefinedAvrTypesSettings] extends [`${AvrTypes}`] ? (`${AvrTypes}` extends DefinedAvrTypesSettings ? true : false) : false = true;

@Injectable()
export class AvrServices {
  private avrServices: {
    [K in AvrTypes]: BaseAvrAbstractService<K, any>;
  };

  constructor(avrServices: InstanceType<(typeof AvrServicesArray)[number]>[]) {
    this.avrServices = avrServices.reduce(
      (output, avrService) => {
        output[avrService.type] = avrService as any;
        return output;
      },
      {} as typeof this.avrServices,
    );
  }

  getAvrServices<K extends AvrTypes>(avrType: K): (typeof this.avrServices)[K] {
    return this.avrServices[avrType];
  }
}
