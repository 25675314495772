import { FormatActionWorkflowSettingsDto } from '@api/models/format-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { NewArtifact } from '@shared/types/artifact.types';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { FormatAttributeAction, WorkflowTriggerEvent } from '@workflows/types';
import { RuleFormatHandlerService } from '../../shared';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum FormatActionsOnConditionsNotMet {
  clearFormatting = 'clearFormatting',
  doNothing = 'doNothing',
}

export enum ActionFormatKeys {
  styles = 'styles',
}

export class WorkflowActionFormatDto implements FormatActionWorkflowSettingsDto {
  styles: FormatActionWorkflowSettingsDto[ActionFormatKeys.styles] = { value: {}, isDynamic: false };

  constructor(action?: WorkflowActionFormatDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionFormat extends AbstractWorkflowAction<FormatActionWorkflowSettingsDto> {
  attributeId: string;

  constructor(
    conditionConverter: RuleConditionValueConverterService,
    dto?: WorkflowActionDto,
    public actionFormatHandler?: RuleFormatHandlerService,
  ) {
    super({ actionSettingDto: WorkflowActionFormatDto, type: WorkflowActionType.FORMAT, dto, conditionConverter });
    this.attributeId = dto?.attributeId || this.attributeId;
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifacts?.length;
  }

  execute(ruleTriggerEvent: WorkflowTriggerEvent, artifact: NewArtifact): void {
    const event: FormatAttributeAction = {
      attributeId: this.attributeId,
      artifactId: artifact.id,
      widgetId: ruleTriggerEvent.widgetId!,
      formatStyles: this.actionSettings.styles.value,
      artifactTypeId: artifact.artifactTypeId || '',
    };
    this.actionFormatHandler?.notifyFormatEvent(event);
  }

  isValid(): boolean {
    return !!this.actionFormatHandler && !!this.attributeId;
  }

  isAttributeRequired(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      attributeId: this.attributeId,
      actionSettings: {
        [ActionFormatKeys.styles]: {
          isDynamic: false,
          value: this.actionSettings.styles.value,
        },
      },
    };
  }
}
