import { Component, Input, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { FileWithUrl } from '@widgets/card-widget/types/file-with.url';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-preview',
  template: `<canvas #pdfCanvas></canvas>`,
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements AfterViewInit {
  @Input() pdfFile: FileWithUrl;
  @ViewChild('pdfCanvas') canvasRef: ElementRef<HTMLCanvasElement>;

  constructor(
    private sanitizer: DomSanitizer
  ) {}

  async ngAfterViewInit(): Promise<void> {
    await this.renderPDFDocument();
  }

  async renderPDFDocument(): Promise<void> {
    if(!this.pdfFile) {
      return;
    }

    const url = this.sanitizer.sanitize(4, this.pdfFile.url);
    if (!url) {
      return;
    }

    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], this.pdfFile.formatData.filename, { type: this.pdfFile.formatData.mimetype || blob.type });

    const reader = new FileReader();
    reader.onload = async (event: any) => {
      const arrayBuffer = event.target.result;

      const pdfjsLib = await import('pdfjs-dist/build/pdf.min.mjs');
      pdfjsLib.GlobalWorkerOptions.workerSrc = /* @vite-ignore */ new URL(
        'pdfjs-dist/build/pdf.worker.min.mjs',
        import.meta.url
      ).toString();

      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      const maxWidth = 480;
      const maxHeight = 640;
      let scale = Math.min(maxWidth / viewport.width, maxHeight / viewport.height, 1);
      const scaledViewport = page.getViewport({ scale });

      const canvas = this.canvasRef.nativeElement;
      const context = canvas.getContext('2d');
      canvas.width = scaledViewport.width;
      canvas.height = scaledViewport.height;

      if (context) {
        await page.render({ canvasContext: context, viewport: scaledViewport }).promise;
      }
    };
    reader.readAsArrayBuffer(file);
  }
}
