import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ArtifactLinkResponseDto, ArtifactResponseDto, LinkFilterNew, LinkListResponseDto, LinkResponseDto } from '@api/models';
import { TenantLinkService } from '@api/services';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { APPLICATION_ID_KEY } from '@shared/constants/constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { LinkFilterEnum } from '@shared/types/filter.types';
import { LinkType } from '@shared/types/link-type.types';
import { NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ObjectUtil } from '@shared/utils/object.util';
import { cloneDeep } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ArtifactLinks, ArtifactListTableModel } from '../types/artifact-list-widget-table.types';
import { ListWidgetOptions } from '../types/list-widget-options.types';
import {
  LinkFilterByLinked,
  LinkFilterMatchType
} from '@widgets/shared/components/artifact-list-table/components/table-header-column/link-filter/link-filter-by-linked.types';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Injectable({ providedIn: 'root' })
export class ArtifactListWidgetLinkService {
  constructor(
      private readonly tenantLinkService: TenantLinkService,
      private readonly filterService: ArtifactFiltersService,
      private cache: NewCacheService,
      private readonly objectU: ObjectUtil
  ) {
  }

  getLinkById$(linkId: string): Observable<NewLink> {
    return this.tenantLinkService.linkControllerGet({ id: linkId }).pipe(map(linkDto => new NewLink(linkDto)));
  }

  loadLinksForArtifacts$(artifacts: NewArtifact[], linkTypes: ListContainer<LinkType>, applicationId: string): Observable<LinkListResponseDto> {
    const dataMongoIds = artifacts.map(artifact => ({ $oid: artifact.id }));
    const linkTypeMongoIds = linkTypes.filterByKey(APPLICATION_ID_KEY, applicationId).map(linkType => ({ $oid: linkType.id }));
    const filter = this.makeLinksFilter(dataMongoIds, linkTypeMongoIds);

    return this.tenantLinkService.linkControllerList({ body: { filter } });
  }

  getLinksDataForArtifacts$(
    artifacts: NewArtifact[],
    appId: string,
    model: ArtifactListTableModel,
    options: ListWidgetOptions,
  ): Observable<ArtifactLinkResponseDto[]> {
    return this.loadLinksForArtifacts$(artifacts, options.linkTypes, appId).pipe(
      tap(linkListDto => this.addLinksToModel(linkListDto.data, model)),
      switchMap(value => this.loadArtifactDataByLinks$(value.data)),
      tap(artifactsDto => this.addLinkedArtifactsToModel(artifactsDto, model, options.artifactTypes)),
    );
  }

  loadArtifactDataByLinks$(links: LinkResponseDto[]): Observable<ArtifactLinkResponseDto[]> {
    const artifactIds: Set<string> = new Set();
    links.forEach(({ destinationArtifactId, sourceArtifactId }) => {
      artifactIds.add(destinationArtifactId);
      artifactIds.add(sourceArtifactId);
    });

    return this.cache.data.artifacts.getMany$([...artifactIds]);
  }

  getLinksByLinkTypeAndArtifacts$(linkTypeId: string, artifactIds: string[]): Observable<any> {
    const filter = this.makeLinksFilter(
      artifactIds.map(id => ({ $oid: id })),
      [{ $oid: linkTypeId }],
    );
    return this.tenantLinkService.linkControllerList({ body: { filter } });
  }

  addLinkedArtifactsToModel(dtos: ArtifactResponseDto[], model: ArtifactListTableModel, artifactTypes: ListContainer<NewArtifactType>): void {
    model.linkedData ??= {};

    const linkedData = this.getLinkedData(dtos, artifactTypes);
    model.linkedData = cloneDeep(model.linkedData);
    Object.keys(linkedData).forEach(dtoId => {
      model.linkedData[dtoId] = linkedData[dtoId];
    });
  }

  getLinkArtifactMeta$(queryParams: Params, linkFilters: Record<LinkFilterEnum | string, LinkFilterNew>): Observable<any | undefined> {
    const arr = Object.keys(linkFilters).map((linkFilterName: any) => {
      if (linkFilterName === LinkFilterEnum.linkedFilter) {
        const linkColumns = linkFilters[linkFilterName].linkColumns as any;
        const linkMeta = { $and: [] } as any;

        Object.keys(linkColumns).forEach((linkTypeId: string) => {
          const filterLink = linkColumns[linkTypeId].linkedFilter as LinkFilterByLinked;
          const sign = filterLink.matchType === LinkFilterMatchType.matchAll? '$and': '$or';
          const meta = { [sign]: [] };
          filterLink.ruleTypes.forEach(rule => {
            rule.filtersHolder.attributesFilter.forEach((af: ArtifactFilter) => {
              if (!(af instanceof ArtifactFilter)) {
                const filter = new ArtifactFilter();
                Object.setPrototypeOf(af, filter);
              }

              const query = this.filterService.getQuery(af);
              // @ts-ignore
              query && meta[sign].push(query);
            })
          })

          linkMeta.linkTypeId = linkTypeId;
          meta[sign].length && (linkMeta.linkFilter = meta);
        });

        return of(linkMeta);
      }


      const filter = JSON.stringify(this.getLinkMetaFromLinkFilters(queryParams, linkFilterName, linkFilters));
      if (!filter) {
        return of(undefined);
      }
      return this.tenantLinkService.linkControllerList({ body: { filter } }).pipe(
          map(result => {
            const links = result.data;
            const artifactIds = this.getArtifactIdsFromLinkFilter(queryParams, links, linkFilters, linkFilterName);
            return { $and: [{ _id: { $in: [...artifactIds].map(id => ({ $oid: id })) } }] } as Record<string, any>;
          }),
      );
    });

    if (!arr.length) return of(undefined);

    return forkJoin(arr).pipe(map((res) => {
      const linkMeta = { $and: [] } as any;

      res.forEach(item => {
        item.$and?.length && (linkMeta.$and = [...linkMeta.$and, ...item.$and]);
        item.linkFilter && (linkMeta.linkFilter = item.linkFilter);
        item.linkTypeId && (linkMeta.linkTypeId = item.linkTypeId);
      });

      return linkMeta;
    }));
  }

  /**
   * Returns link meta data (mongo query addon / part) for further processing.
   * @param queryParams query params object
   * @param linkFilterName name for the link filter
   * @param linkFilters link filters object
   * @returns link meta data
   */
  getLinkMetaFromLinkFilters(queryParams: Params, linkFilterName: LinkFilterEnum | undefined, linkFilters: Record<LinkFilterEnum | string, LinkFilterNew>): any {
    if (!Object.keys(linkFilters).length || !linkFilterName) {
      return;
    }
    const linkColumns = linkFilters[linkFilterName].linkColumns;
    const linkMeta = { $or: [] } as any;

    Object.keys(linkColumns).forEach((linkTypeId: string) => {
      if (linkColumns[linkTypeId]) {
        if (linkColumns[linkTypeId].linkDirection === LinkDirection.outgoing) {
          linkMeta.$or.push(this.transformLinkFilterToLinkMeta(queryParams, linkTypeId, LinkDirection.outgoing, linkFilterName, linkFilters));
        }
        if (linkColumns[linkTypeId].linkDirection === LinkDirection.incoming) {
          linkMeta.$or.push(this.transformLinkFilterToLinkMeta(queryParams, linkTypeId, LinkDirection.incoming, linkFilterName, linkFilters));
        }
      }
    });

    return linkMeta;
  }

  addLinksToModel(links: LinkResponseDto[], model: ArtifactListTableModel): void {
    if (!model.links) {
      model.links = {};
    }
    const newLinks: Record<string, Record<string, ArtifactLinks>> = {};
    links.forEach(link => {
      this.checkExistingLinksByLinkType(newLinks, link);
      this.checkExistingLinksByArtifact(newLinks[link.linkTypeId], link);
      this.setLinkToModel(newLinks[link.linkTypeId], link);
    });
    model.links = cloneDeep(model.links);
    Object.keys(newLinks).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(newLinks, key)) {
        Object.keys(newLinks[key]).forEach(artifact => {
          if (!model.links[key]) {
            model.links[key] = {};
          }
          model.links[key][artifact] = newLinks[key][artifact];
        });
      }
    });
  }

  /**
   * Transform link filter object into link specific meta data object (mongo query addon / part).
   * @param queryParams query params object
   * @param linkTypeId id of the link type
   * @param direction flexDirection (incoming / outgoing)
   * @param linkFilterName name for the link filter
   * @param linkFilters link filters object
   * @returns link meta data
   */
  private transformLinkFilterToLinkMeta(
    queryParams: Params,
    linkTypeId: string,
    direction: LinkDirection,
    linkFilterName: LinkFilterEnum,
    linkFilters: Record<LinkFilterEnum | string, LinkFilterNew>,
  ): any {
    const linkMeta = {
      $and: [{ deleted: { $eq: null } }, { linkTypeId: { $in: [{ $oid: linkTypeId }] } }],
    };
    if (linkFilterName === LinkFilterEnum.containsUrlParamKey && linkFilters[linkFilterName].linkColumns[linkTypeId].linkFilterUrlParamKey) {
      const sourceOrDestinationArtifactId = direction === LinkDirection.outgoing ? 'destinationArtifactId' : 'sourceArtifactId';
      const artifactId = queryParams[(linkFilters[linkFilterName].linkColumns[linkTypeId].linkFilterUrlParamKey as string)?.toLowerCase()];
      if (artifactId) {
        linkMeta.$and.push({ [sourceOrDestinationArtifactId]: { $in: [{ $oid: artifactId }] } } as any);
      }
    }
    return linkMeta;
  }

  /**
   * Extracts artifact ids from given links.
   * @param queryParams query params
   * @param links list of links
   * @param linkFilters link filters object
   * @returns artifact ids from given links
   */
  private getArtifactIdsFromLinkFilter(queryParams: Params, links: NewLink[], linkFilters: Record<LinkFilterEnum | string, LinkFilterNew>, linkFilterName?: LinkFilterEnum): string[] {
    if (!linkFilterName) return [];

    const ids: Set<string>[] = [];
    const linkFiltersValue = linkFilters[linkFilterName];
    Object.keys(linkFiltersValue.linkColumns).forEach(linkTypeId => {
      if (linkFiltersValue.linkColumns[linkTypeId].linkDirection === LinkDirection.outgoing) {
        ids.push(
          new Set(
            links
              .filter(link => link.destinationArtifactId === queryParams[linkFiltersValue.linkColumns[linkTypeId].linkFilterUrlParamKey?.toLowerCase() || ''])
              .map(link => link.sourceArtifactId),
          ),
        );
      }
      if (linkFiltersValue.linkColumns[linkTypeId].linkDirection === LinkDirection.incoming) {
        ids.push(
          new Set(
            links
              .filter(link => link.sourceArtifactId === queryParams[linkFiltersValue.linkColumns[linkTypeId].linkFilterUrlParamKey?.toLowerCase() || ''])
              .map(link => link.destinationArtifactId),
          ),
        );
      }
    });
    return this.objectU.getIntersectionOfArrays(ids.map(a => Array.from(a)));
  }

  private mapLinkedArtifactsToModel(dtos: ArtifactResponseDto[], model: ArtifactListTableModel, artifactTypes: ListContainer<NewArtifactType>): void {
    model.linkedData = this.getLinkedData(dtos, artifactTypes);
  }

  private getLinkedData(dtos: ArtifactResponseDto[], artifactTypes: ListContainer<NewArtifactType>): Record<string, NewArtifact> {
    return dtos.reduce((linkedData: Record<string, NewArtifact>, dto: ArtifactResponseDto) => {
      linkedData[dto.id] = new NewArtifact({ dto, artifactTypesMap: artifactTypes.listMap });
      return linkedData;
    }, {});
  }

  private getUpdatedLinks(links: LinkResponseDto[]): Record<string, Record<string, ArtifactLinks>> {
    const newLinks: Record<string, Record<string, ArtifactLinks>> = {};
    links.forEach(link => {
      this.checkExistingLinksByLinkType(newLinks, link);
      this.checkExistingLinksByArtifact(newLinks[link.linkTypeId], link);
      this.setLinkToModel(newLinks[link.linkTypeId], link);
    });
    return newLinks;
  }

  private mapLinksToModel(links: LinkResponseDto[], model: ArtifactListTableModel): void {
    model.links = {};
    const newLinks: Record<string, Record<string, ArtifactLinks>> = {};
    links.forEach(link => {
      this.checkExistingLinksByLinkType(newLinks, link);
      this.checkExistingLinksByArtifact(newLinks[link.linkTypeId], link);
      this.setLinkToModel(newLinks[link.linkTypeId], link);
    });
    model.links = newLinks;
  }

  private makeLinksFilter(dataMongoIds: { $oid: string }[], linkTypeMongoIds: { $oid: string }[]): string {
    return JSON.stringify({
      $and: [
        { $or: [{ destinationArtifactId: { $in: dataMongoIds } }, { sourceArtifactId: { $in: dataMongoIds } }] },
        { deleted: { $eq: null } },
        { linkTypeId: { $in: linkTypeMongoIds } },
      ],
    });
  }

  private checkExistingLinksByLinkType(links: Record<string, Record<string, ArtifactLinks>>, link: LinkResponseDto): void {
    if (!links[link.linkTypeId]) links[link.linkTypeId] = {};
  }

  private checkExistingLinksByArtifact(linksByLinkType: Record<string, ArtifactLinks>, link: LinkResponseDto): void {
    if (!linksByLinkType[link.sourceArtifactId]) linksByLinkType[link.sourceArtifactId] = { [LinkDirection.incoming]: [], [LinkDirection.outgoing]: [] };
    if (!linksByLinkType[link.destinationArtifactId])
      linksByLinkType[link.destinationArtifactId] = { [LinkDirection.incoming]: [], [LinkDirection.outgoing]: [] };
  }

  private setLinkToModel(links: Record<string, ArtifactLinks>, link: LinkResponseDto): void {
    links[link.sourceArtifactId][LinkDirection.outgoing].push(link);
    links[link.destinationArtifactId][LinkDirection.incoming].push(link);
  }
}
