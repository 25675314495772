import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, NgZone, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { DisplayAtHtmlControlShowEvent, DisplayAtOverlayControlService } from '@shared/components/common-display-at';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { TableColumnFormat } from '@shared/types/table.types';
import { CardWidgetModelOptions } from '@widgets/card-widget/types/card-widget-model';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { fromEvent, merge, Subscription } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DisplayAtControlService, DisplayAtTemplateService } from '../../services';
import { AbstractDisplayAtComponent } from '../abstract-display-at.component';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';

@Component({
  selector: 'app-display-at-text',
  templateUrl: './display-at-text.component.html',
  styleUrls: ['display-at-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtTextComponent extends AbstractDisplayAtComponent implements OnDestroy {
  @Input() attribute: NewAttribute;
  @Input() artifactType: NewArtifactType;
  @Input() tooltipEnabled: boolean;
  @Input() forceShowContent: boolean;
  @Input() truncate = true;
  @Input() options: CardWidgetModelOptions;
  @Input() files: Record<string, ArtifactResponseDto> | null = null;
  @Input() filesLoaded: boolean;
  @Input() links: LinkResponseDto[] = [];
  @Input() linkedArtifacts: NewArtifact[];
  @Input() contentColumnFormat: TableColumnFormat;
  @Input() valueStyles: FormatStyles;

  @ViewChild('default') defaultTextTemplate: TemplateRef<any>;
  private clickSubscription: Subscription;
  private eventsSubscription: Subscription;

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtTemplateService: DisplayAtTemplateService,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly datOverlayControlService: DisplayAtOverlayControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly cdr: ChangeDetectorRef,
    private readonly zone: NgZone,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    super(isPreviewMode, displayAtControlService, dataTypeHelper, cdr);
    this.init();
  }

  @ViewChild('iconClick') set iconClick(content: ElementRef) {
    if (content) {
      const contentNative = content.nativeElement;
      this.initObservers(contentNative);
    }
  }

  @ViewChild('defaultText') set defaultText(content: TemplateRef<any>) {
    if (content) {
      const contentNative = content.elementRef.nativeElement;
      if (contentNative?.offsetWidth >= contentNative?.scrollWidth) {
        // ellipsis applied, not initing any observers...
        return;
      }
      this.initObservers(contentNative);
    }
  }

  ngOnDestroy(): void {
    this.clickSubscription && this.clickSubscription.unsubscribe();
    this.eventsSubscription && this.eventsSubscription.unsubscribe();
  }

  transformFilesToArray(): ArtifactResponseDto[] {
    return this.files ? Object.values(this.files) : [];
  }

  private init(): void {
    this.eventsSubscription = this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<any>) => {
      if (event.type === RuntimeStateNotificationEnum.updateColumnStyles) {
        event?.data?.key === this.attribute.id && this.cdr.markForCheck();
      }
    });
  }

  private initObservers(nativeElement: any) {
    if (!nativeElement) return;
    this.zone.runOutsideAngular(() => {
      const click$ = fromEvent(nativeElement, 'click').pipe(
        tap(event =>
          this.zone.run(() => {
            const showEvent = this.getDisplayAtHtmlControlShowEvent(event as Event, true);
            this.datOverlayControlService.doShowOverlay(showEvent);
          }),
        ),
      );

      const mouseEnter$ = fromEvent(nativeElement, 'mouseenter').pipe(
        tap(event =>
          this.zone.run(() => {
            const showEvent = this.getDisplayAtHtmlControlShowEvent(event as Event, false);
            this.datOverlayControlService.doShowOverlay(showEvent);
          }),
        ),
      );

      const mouseOut$ = fromEvent(nativeElement, 'mouseout').pipe(
        tap(() =>
          this.zone.run(() => {
            this.datOverlayControlService.doHideOverlay(this.ownerId);
          }),
        ),
      );

      const preview$ = mouseEnter$.pipe(switchMap(() => mouseOut$.pipe(takeUntil(click$))));

      this.clickSubscription = merge(preview$, click$).subscribe();
    });
  }

  private getDisplayAtHtmlControlShowEvent(event: Event, closable: boolean): DisplayAtHtmlControlShowEvent {
    return {
      ownerId: this.ownerId,
      contentTemplate: this.defaultTextTemplate,
      event,
      closable,
    };
  }
}
