<a href="https://formulajs.info/functions/" target="_blank">
  <span class="formula-info pi pi-external-link"></span>
</a>

<input
  #formulaInput
  pInputText
  appAttributeAutocomplete
  appFormulaAutocomplete
  id="expression"
  type="text"
  class="formula-input"
  autocomplete="off"
  [ownerId]="actionInputOwner"
  [disabled]="!editable"
  [attributes]="selectedAttributes"
  [(ngModel)]="action.actionSettings.formula.value"
  (change)="onFormulaChange($event)"
/>

<div class="p-error error-message" [class.visible]="errorMessage$ | async">{{ (errorMessage$ | async) || '' | translate }}</div>

<ng-container *ngIf="action.actionSettings.formula.value">
  {{ 'WORKFLOWS.COMMON.SET_INTO' | translate }}
  <app-workflow-attribute-selector
    [selectedAttributeId]="action.attributeId"
    [attributes]="selectedAttributes"
    (attributeChange)="onAttributeChange($event)"
    [editable]="editable">
  </app-workflow-attribute-selector>
</ng-container>
