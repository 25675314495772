import { UpdateArtifactsActionWorkflowSettingsDto } from '@api/models/update-artifacts-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export type WorkflowUpdateAttributeMapValue = string | string[] | null;
export type WorkflowUpdateAttributeMap = Record<string, WorkflowUpdateAttributeMapValue>;

export interface UpdateArtifactAttributes {
  id: string;
  value: string | string[] | null;
  useInitialValue: boolean;
  isMulti: boolean;
}

export enum UpdateArtifactsKeys {
  id = 'id',
  forEachPath = 'forEachPath',
  attributesMap = 'attributesMap',
  storeResultsPath = 'storeResultsPath',
  parentFolderId = 'parentFolderId',
}

export class WorkflowActionUpdateArtifactsDto implements UpdateArtifactsActionWorkflowSettingsDto {
  id: UpdateArtifactsActionWorkflowSettingsDto[UpdateArtifactsKeys.id] = { value: '', isDynamic: false };
  forEachPath: UpdateArtifactsActionWorkflowSettingsDto[UpdateArtifactsKeys.forEachPath] = { value: '', isDynamic: false };
  attributesMap: UpdateArtifactsActionWorkflowSettingsDto[UpdateArtifactsKeys.attributesMap] = { value: {}, isDynamic: false };
  storeResultsPath: UpdateArtifactsActionWorkflowSettingsDto[UpdateArtifactsKeys.storeResultsPath] = { value: '', isDynamic: false };
  parentFolderId: UpdateArtifactsActionWorkflowSettingsDto[UpdateArtifactsKeys.parentFolderId] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionUpdateArtifactsDto) {
    action && Object.assign(action);
  }
}

export class WorkflowActionUpdateArtifacts extends AbstractWorkflowAction<WorkflowActionUpdateArtifactsDto> {
  clientAttributesArray: UpdateArtifactAttributes[] = [];

  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionUpdateArtifactsDto, conditionConverter, type: WorkflowActionType.UPDATE_ARTIFACTS, dto });
    dto && this.fromDto(dto);
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  fromDto(dto: WorkflowActionDto) {
    if (dto) {
      Object.assign(this, dto);

      const data = (dto.actionSettings as UpdateArtifactsActionWorkflowSettingsDto).attributesMap.value || ({} as WorkflowUpdateAttributeMap);
      this.clientAttributesArray = Object.entries(data).map(([key, value]) => ({
        id: key,
        value: value,
        useInitialValue: value === null,
        isMulti: Array.isArray(value),
      }));
    }
  }

  toServer(): WorkflowActionDto {
    const attributesMap: WorkflowUpdateAttributeMap = this.clientAttributesArray.reduce(
      (inputDataMap: WorkflowUpdateAttributeMap, clientInputData: UpdateArtifactAttributes) => {
        return {
          ...inputDataMap,
          [clientInputData.id.replace(/ /g, '_')]: clientInputData.value,
        };
      },
      {},
    );

    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [UpdateArtifactsKeys.attributesMap]: {
          isDynamic: this.actionSettings[UpdateArtifactsKeys.attributesMap].isDynamic,
          value: attributesMap,
        },
        [UpdateArtifactsKeys.parentFolderId]: {
          isDynamic: this.actionSettings[UpdateArtifactsKeys.parentFolderId].isDynamic,
          value: this.actionSettings[UpdateArtifactsKeys.parentFolderId].value || null,
        },
        [UpdateArtifactsKeys.storeResultsPath]: {
          isDynamic: false,
          value: this.actionSettings[UpdateArtifactsKeys.storeResultsPath].value || null,
        },
        [UpdateArtifactsKeys.forEachPath]: {
          isDynamic: false,
          value: this.actionSettings[UpdateArtifactsKeys.forEachPath].value || null,
        },
      },
    };
  }
}
