<tr>
  <td>{{ nonMappableFields['debtor.isOptional']!.readableName }}</td>
  <td>
    <p-triStateCheckbox [ngModel]="!nonMappableFields['debtor.isOptional']!.isOptional" disabled="true"></p-triStateCheckbox>
  </td>
  <td>
    <span>
      <i class="pi pi-info-circle ml-1" [pTooltip]="nonMappableFields['debtor.isOptional']!.description" tooltipPosition="top" [escape]="false"></i>
      <p-dropdown
        [options]="debtorIsOptionalOptions"
        [(ngModel)]="nonMappableFields['debtor.isOptional']!.value"
        [showClear]="nonMappableFields['debtor.isOptional']!.isOptional"
        [placeholder]="'Select value' | translate"
        ngClass="full-width-container"
        appendTo="body"
      >
      </p-dropdown>
    </span>
  </td>
</tr>
<tr>
  <td>{{ nonMappableFields['outputTypes']!.readableName }}</td>
  <td>
    <p-triStateCheckbox [ngModel]="!nonMappableFields['outputTypes']!.isOptional" disabled="true"></p-triStateCheckbox>
  </td>
  <td>
    <span>
      <i class="pi pi-info-circle ml-1" [pTooltip]="nonMappableFields['outputTypes']!.description" tooltipPosition="top" [escape]="false"></i>
      <p-multiSelect
        [options]="outputTypesOptions"
        [(ngModel)]="nonMappableFields['outputTypes']!.value"
        [showClear]="nonMappableFields['outputTypes']!.isOptional"
        [placeholder]="'Select value'"
        filter="false"
        ngClass="dynamic-label full-width-container"
        appendTo="body"
      >
      </p-multiSelect>
    </span>
  </td>
</tr>
