import { RuleConditionsUtilService } from '@workflows/services';
import { BaseDataType, DataTypeKind } from '../../../private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { WorkflowConditionType } from '../index';
import { AbstractWorkflowCondition } from './abstract/abstract.condition';

export class WorkflowNotEqualsCondition extends AbstractWorkflowCondition {
  constructor(conditionsUtil: RuleConditionsUtilService) {
    super(conditionsUtil);
    this.type = WorkflowConditionType.NOT_EQUALS;
    this.supportedTypes = [
      BaseDataType.integer,
      BaseDataType.decimal,
      BaseDataType.text,
      BaseDataType.html,
      BaseDataType.date,
      BaseDataType.dateTime,
      BaseDataType.time,
      BaseDataType.file,
      BaseDataType.hyperlink,
    ];
    this.supportedKinds = [DataTypeKind.simple];
  }

  isMet(source: any, value?: any): boolean {
    if (Array.isArray(source)) {
      if (Array.isArray(value)) {
        return source.length !== value.length || !source.every((sourceValue, index) => sourceValue === value[index]);
      }

      if (!value) {
        return !!source.length;
      }
    }

    return source !== value;
  }
}
