import { Application } from '@private/pages/application-management/application/types/application.types';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { AvrActionTypes, AvrArtifactSources } from '@widgets/avr-widget/types/avr-widget-settings.types';

export class AvrWidgetOptions {
  // STATIC
  artifactSourcesOptions: SelectOption<AvrArtifactSources, AvrArtifactSources>[] = GetSelectOptionsFromEnum<AvrArtifactSources>(AvrArtifactSources);
  avrTypesOptions: SelectOption<string, AvrTypes>[] = [];
  avrActionTypesOptions: SelectOption<string, AvrActionTypes>[] = [];
  // FROM BE
  artifactTypesOptions: SelectOption<string, NewArtifactType>[] = [];
  outputTypesOptions: SelectOption<string, AvrOutputTypes>[] = [];
  artifactOptions: SelectOption<string, NewArtifact>[] = [];
  applications: ListContainer<Application> = new ListContainer<NewApplication>();
}
