import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';

@Pipe({ name: 'isShowQueryOptions' })
export class IsShowQueryOptionsPipe implements PipeTransform {
	transform(actionType: string): boolean {
		const { goToPage, goToExternalPage, goToDefaultArtifactPage } = ArtifactListItemClickAction;
		return actionType === goToPage || actionType === goToExternalPage || actionType === goToDefaultArtifactPage;
	}
}
