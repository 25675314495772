import {
  BaseDataType,
  BaseDataTypeEnumerated,
  BaseDataTypeSimple,
  DataTypeKind,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewDataType } from '@shared/types/data-type.types';
import { WorkflowConditionType } from './condition.types';

export class RuleConditionOperationTypeDefinitions {
  static readonly SIMPLE_DEFINITIONS: Record<BaseDataTypeSimple, WorkflowConditionType[]> = {
    [BaseDataTypeSimple.integer]: [
      WorkflowConditionType.EQUALS,
      WorkflowConditionType.NOT_EQUALS,
      WorkflowConditionType.GREATER_THAN,
      WorkflowConditionType.GREATER_OR_EQUALS_THAN,
      WorkflowConditionType.LOWER_THAN,
      WorkflowConditionType.LOWER_OR_EQUALS_THAN,
    ],
    [BaseDataTypeSimple.decimal]: [
      WorkflowConditionType.EQUALS,
      WorkflowConditionType.NOT_EQUALS,
      WorkflowConditionType.GREATER_THAN,
      WorkflowConditionType.GREATER_OR_EQUALS_THAN,
      WorkflowConditionType.LOWER_THAN,
      WorkflowConditionType.LOWER_OR_EQUALS_THAN,
    ],
    [BaseDataTypeSimple.text]: [
      WorkflowConditionType.CONTAINS,
      WorkflowConditionType.NOT_CONTAINS,
      WorkflowConditionType.EQUALS,
      WorkflowConditionType.NOT_EQUALS,
      WorkflowConditionType.STARTS_WITH,
      WorkflowConditionType.ENDS_WITH,
    ],
    [BaseDataTypeSimple.html]: [
      WorkflowConditionType.CONTAINS,
      WorkflowConditionType.NOT_CONTAINS,
      WorkflowConditionType.EQUALS,
      WorkflowConditionType.NOT_EQUALS,
      WorkflowConditionType.STARTS_WITH,
      WorkflowConditionType.ENDS_WITH,
    ],
    [BaseDataTypeSimple.user]: [WorkflowConditionType.EQUALS, WorkflowConditionType.NOT_EQUALS],
    [BaseDataTypeSimple.boolean]: [WorkflowConditionType.EQUALS],
    [BaseDataTypeSimple.date]: [WorkflowConditionType.DATES],
    [BaseDataTypeSimple.dateTime]: [WorkflowConditionType.DATES],
    [BaseDataTypeSimple.time]: [
      WorkflowConditionType.EQUALS,
      WorkflowConditionType.NOT_EQUALS,
      WorkflowConditionType.GREATER_THAN,
      WorkflowConditionType.GREATER_OR_EQUALS_THAN,
      WorkflowConditionType.LOWER_THAN,
      WorkflowConditionType.LOWER_OR_EQUALS_THAN,
    ],
    [BaseDataTypeSimple.file]: [
      WorkflowConditionType.CONTAINS,
      WorkflowConditionType.NOT_CONTAINS,
      WorkflowConditionType.EQUALS,
      WorkflowConditionType.NOT_EQUALS,
      WorkflowConditionType.STARTS_WITH,
      WorkflowConditionType.ENDS_WITH,
    ],
    [BaseDataTypeSimple.hyperlink]: [
      WorkflowConditionType.CONTAINS,
      WorkflowConditionType.NOT_CONTAINS,
      WorkflowConditionType.EQUALS,
      WorkflowConditionType.NOT_EQUALS,
      WorkflowConditionType.STARTS_WITH,
      WorkflowConditionType.ENDS_WITH,
    ],
  };

  static readonly SIMPLE_USER_MULTI: Record<BaseDataTypeSimple.user, WorkflowConditionType[]> = {
    [BaseDataTypeSimple.user]: [WorkflowConditionType.INCLUDES, WorkflowConditionType.NOT_INCLUDES],
  };

  static readonly ENUMERATED_SINGLE_DEFINITIONS: Record<BaseDataTypeEnumerated, WorkflowConditionType[]> = {
    [BaseDataTypeEnumerated.text]: [WorkflowConditionType.EQUALS, WorkflowConditionType.NOT_EQUALS],
    [BaseDataTypeEnumerated.integer]: [WorkflowConditionType.EQUALS, WorkflowConditionType.NOT_EQUALS],
  };

  static readonly ENUMERATED_MULTI_DEFINITIONS: Record<BaseDataTypeEnumerated, WorkflowConditionType[]> = {
    [BaseDataTypeEnumerated.text]: [WorkflowConditionType.INCLUDES, WorkflowConditionType.NOT_INCLUDES],
    [BaseDataTypeEnumerated.integer]: [WorkflowConditionType.INCLUDES, WorkflowConditionType.NOT_INCLUDES],
  };

  static getOperationTypes(dataType: NewDataType, isMulti: boolean): WorkflowConditionType[] {
    if (dataType.kind === DataTypeKind.enumerated) {
      return isMulti
        ? RuleConditionOperationTypeDefinitions.ENUMERATED_MULTI_DEFINITIONS[dataType.baseDataType as any as BaseDataTypeEnumerated]
        : RuleConditionOperationTypeDefinitions.ENUMERATED_SINGLE_DEFINITIONS[dataType.baseDataType as any as BaseDataTypeEnumerated];
    }

    if (dataType.kind === DataTypeKind.simple || dataType.kind === DataTypeKind.bounded) {
      if (dataType.baseDataType === BaseDataType.user && isMulti) {
        return RuleConditionOperationTypeDefinitions.SIMPLE_USER_MULTI[dataType.baseDataType!];
      }
      return RuleConditionOperationTypeDefinitions.SIMPLE_DEFINITIONS[dataType.baseDataType!];
    }

    return [];
  }
}
