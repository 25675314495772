import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ListContainer } from '@shared/types/list-container.types';
import { WorkflowResponseDto } from '@api/models/workflow-response-dto';
import { NAME_KEY } from '@shared/constants/constants';
import { RuleTriggerType, WorkflowType } from '@workflows/types';
import { ManualTriggerWorkflowSettingsDto } from '@api/models/manual-trigger-workflow-settings-dto';
import {
  MenuWidgetWorkflowValueItem, MenuWidgetWorkflowValueItemDto,
  WfItem, WorkflowDataSourceTypes, WorkflowValueType
} from '@widgets/shared/components/action-list/components/menu-widget-actions/types/menu-widget-workflow-action.types';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { SelectOption } from '@shared/types/shared.types';
import { ELWIS_INIT } from '@widgets/shared/constants/widget.constants';

@Component({
  selector: 'app-menu-widget-actions',
  templateUrl: './menu-widget-actions.component.html',
  styleUrl: './menu-widget-actions.component.scss'
})
export class MenuWidgetActionsComponent {
  @Input() action: ActionItem;

  @Output() actionChange = new EventEmitter<ActionItem>();

  workflows = new ListContainer<WorkflowResponseDto>();
  workflow: WorkflowResponseDto;
  NAME_KEY = NAME_KEY;
  workflowData: MenuWidgetWorkflowValueItem[];
  valueItemTypeOptions: SelectOption<string, string>[];
  scheduleDateTime: Date = new Date(ELWIS_INIT);
  waitUntilFinished = true;

  constructor(private readonly cache: NewCacheService,) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    })
  }

  onChangeWorkflow(): void {
    const data: WfItem = (this.workflow.triggers[0]?.triggerSettings as ManualTriggerWorkflowSettingsDto)?.inputData?.value;
    this.workflowData = Object.keys(data).map(key => new MenuWidgetWorkflowValueItem({
      key,
      valueType: data[key][0] === 'ARRAY' ? WorkflowValueType.array: WorkflowValueType.string,
      isOptional: data[key][1] === 'OPTIONAL',
      } as MenuWidgetWorkflowValueItem));
    this.onActionChange();
  }

  onActionChange(): void {
    this.action.model.workflow = {
      id: this.workflow.id,
      data: this.workflowData,
      scheduleDateTime: this.scheduleDateTime,
      waitUntilFinished: this.waitUntilFinished,
    } as ActionWorkflow;
    this.actionChange.emit(this.action);
  }

  isStatic(item: MenuWidgetWorkflowValueItem): boolean {
    return item.type === WorkflowDataSourceTypes.static;
  }

  private init() {
    // @ts-ignore
    this.workflows.setList(this.cache.data.workflows.value.filter(wf => wf.type === WorkflowType.be && wf.triggers[0]?.type === RuleTriggerType.MANUAL), 'id');
    this.valueItemTypeOptions = GetSelectOptionsFromEnum(WorkflowDataSourceTypes);

    if (this.action?.model?.workflow) {
      const { id, data, scheduleDateTime, waitUntilFinished } = this.action.model.workflow as ActionWorkflow;
      this.workflowData = data.map(dto => new MenuWidgetWorkflowValueItem(dto));
      this.workflow = this.workflows.listMap[id];
      scheduleDateTime && (this.scheduleDateTime = new Date(scheduleDateTime));
      waitUntilFinished !== undefined && (this.waitUntilFinished = waitUntilFinished);

      !this.action.model.workflow.scheduleDateTime && this.onActionChange();
    }
  }
}

export class ActionWorkflow {
  id: string;
  data: MenuWidgetWorkflowValueItem[];
  scheduleDateTime: Date;
  waitUntilFinished: boolean;
}

export class ActionWorkflowDto {
  id: string;
  data: MenuWidgetWorkflowValueItemDto[];
  scheduleDateTime?: string;
  waitUntilFinished?: boolean;
}
