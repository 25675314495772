import { Injectable } from '@angular/core';
import { AclRecordDto } from '@api/models/acl-record-dto';
import { AclRecordIdExpirationsDto } from '@api/models/acl-record-id-expirations-dto';
import { ArtifactAclResponseDto } from '@api/models/artifact-acl-response-dto';
import { AttributeAclResponseDto } from '@api/models/attribute-acl-response-dto';
import { FolderAclMultiRequestDto } from '@api/models/folder-acl-multi-request-dto';
import { FolderAclRequestDto } from '@api/models/folder-acl-request-dto';
import { FolderAclResponseDto } from '@api/models/folder-acl-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantAttributeService } from '@api/services/tenant-attribute.service';
import { TenantFolderService } from '@api/services/tenant-folder.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { ConvertToServerDate } from '@shared/methods/date.methods';
import { ClientData } from '@shared/types/local-storage.types';
import { NewTeam } from '@shared/types/team.types';
import { AclDtoType, AclMemberTypes, AclTypeModel, AclTypes } from '@widgets/shared/components/acl/types/acl.types';
import { lastValueFrom } from 'rxjs';
import { AclComponent } from '../acl.component';

@Injectable()
export class AclComponentService {
  c: AclComponent;

  oldValue: FolderAclResponseDto | ArtifactAclResponseDto | AttributeAclResponseDto;
  aclDto: AclDtoType;

  constructor(
    private readonly cache: NewCacheService,
    private readonly tenantFolderService: TenantFolderService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly tenantAttributeService: TenantAttributeService,
  ) {}

  async init(context: AclComponent): Promise<void> {
    this.c = context;

    this.c.memberTypesList = Object.values(AclMemberTypes);

    this.c.folderId &&
      this.tenantFolderService.folderControllerGetAcl({ id: this.c.folderId }).subscribe(res => {
        this.aclDto = res;
        this.oldValue = Object.assign({}, res);
        this.raceResolver();
      });

    this.c.artifactId &&
      this.tenantArtifactService.artifactControllerGetAcl({ id: this.c.artifactId }).subscribe(res => {
        this.aclDto = res;
        this.oldValue = Object.assign({}, res);
        this.raceResolver();
      });

    this.c.attributeId &&
      this.tenantAttributeService.attributeControllerGetAcl({ id: this.c.attributeId }).subscribe(res => {
        this.aclDto = res;
        this.oldValue = Object.assign({}, res);
        this.raceResolver();
      });

    this.c.userData = (this.cache.user.value as SelfUserResponseDto).clientData as ClientData;

    const teams = await this.cache.data.teams.getManyAsync([], [{ deleted: { $eq: null } }]);
    this.c.teams.setList(
      teams.map(t => new NewTeam(t)),
      ID_KEY,
    );
    this.raceResolver();
  }

  async raceResolver(): Promise<void> {
    if (!this.c.teams?.loaded) return;

    let keys: AclTypes[] = [AclTypes.view, AclTypes.modify, AclTypes.permissions];
    this.c.folderId && (keys = keys.concat([AclTypes.modifyItems, AclTypes.delete, AclTypes.deleteItems]));
    this.c.artifactId && (keys = keys.concat([AclTypes.delete]));
    this.c.aclTypeList = keys;

    this.c.model = new AclTypeModel();
    await this.c.model.init(this, this.aclDto);

    await this.setUsersList();
  }

  isOpenPickerByKey(key: string): boolean {
    return this.c.showUserPicker && this.c.currentAclType === (this.c.aclTypes as any)[key];
  }

  async addMembers(): Promise<void> {
    await this.setUsersList();
    this.c.showUserPicker = !this.c.showUserPicker;
    this.c.isChanged = true;
  }

  remove(list: string[], id: string): void {
    this.c.isChanged = true;

    const index = list.indexOf(id);
    const removed = list.splice(index, 1);
    removed.forEach(id => id in this.c.model[this.c.currentAclType].expirations && delete (this.c.model[this.c.currentAclType].expirations as any)[id]);
  }

  closeAcl(): void {
    this.c.updateAcl.emit();
  }

  async update(): Promise<void> {
    if (!this.c.folderId && !this.c.artifactId && !this.c.attributeId) {
      this.c.updateAcl.emit();
      return;
    }

    const aclToRemove: any[] = [];
    const aclToInsert: any[] = [];
    const isFolder = !!this.c.folderId;
    const id = isFolder ? this.c.folderId : this.c.artifactId || this.c.attributeId;

    const body: FolderAclMultiRequestDto = { id, acl: [] };

    if (isFolder) {
      body.expireFolderItems = this.c.setFolderItemsAcl;
      body.setFolderItemsAcl = this.c.setFolderItemsAcl;
    }

    this.c.aclTypeList.forEach(key => {
      const currentAcl: AclRecordDto = this.c.model[key];
      const oldAcl: AclRecordDto | null = this.oldValue ? (this.oldValue as any)[key] : null;
      const usersToRemove = oldAcl?.userIds.filter(id => !currentAcl?.userIds.includes(id)).map(id => ({ id })) || [];
      const teamsToRemove = oldAcl?.teamIds.filter(id => !currentAcl?.teamIds.includes(id)).map(id => ({ id })) || [];

      if (usersToRemove.length > 0 || teamsToRemove.length > 0) {
        const aclRequest = { aclType: key } as FolderAclRequestDto;
        usersToRemove.length && (aclRequest.users = usersToRemove);
        teamsToRemove.length && (aclRequest.teams = teamsToRemove);
        aclToRemove.push(aclRequest);
      }

      const userToInsert: AclRecordIdExpirationsDto[] = currentAcl.userIds
        .filter(id => !(oldAcl?.userIds || [])?.includes(id) || this.isExpirationModify(id, currentAcl, oldAcl))
        .map(id => this.getAclRecord(id, currentAcl));
      const teamToInsert: AclRecordIdExpirationsDto[] = currentAcl.teamIds
        .filter(id => !(oldAcl?.teamIds || [])?.includes(id) || this.isExpirationModify(id, currentAcl, oldAcl))
        .map(id => this.getAclRecord(id, currentAcl));

      if (userToInsert.length > 0 || teamToInsert.length > 0) {
        const aclRequest = { aclType: key } as FolderAclRequestDto;

        userToInsert.length && (aclRequest.users = userToInsert);
        teamToInsert.length && (aclRequest.teams = teamToInsert);
        aclToInsert.push(aclRequest);
      }
    });

    if (isFolder) {
      aclToRemove.length && (await lastValueFrom(this.tenantFolderService.folderControllerAclRemove({ body: { ...body, acl: aclToRemove } })));
      aclToInsert.length && (await lastValueFrom(this.tenantFolderService.folderControllerAclInsert({ body: { ...body, acl: aclToInsert } })));
    } else if (this.c.attributeId) {
      aclToRemove.length && (await lastValueFrom(this.tenantAttributeService.attributeControllerAclRemove({ body: { ...body, acl: aclToRemove } })));
      aclToInsert.length && (await lastValueFrom(this.tenantAttributeService.attributeControllerAclInsert({ body: { ...body, acl: aclToInsert } })));
    } else {
      aclToRemove.length && (await lastValueFrom(this.tenantArtifactService.artifactControllerAclRemove({ body: { ...body, acl: aclToRemove } })));
      aclToInsert.length && (await lastValueFrom(this.tenantArtifactService.artifactControllerAclInsert({ body: { ...body, acl: aclToInsert } })));
    }

    this.c.updateAcl.emit();
  }

  private isExpirationModify(id: string, currentAcl: AclRecordDto, oldAcl?: AclRecordDto | null): boolean {
    const oldExpiration = ((oldAcl?.expirations as any)?.[id] && new Date((oldAcl?.expirations as any)?.[id]).toString()) || null;
    const currentExpiration = ((currentAcl?.expirations as any)?.[id] && new Date((currentAcl?.expirations as any)?.[id]).toString()) || null;
    return oldExpiration !== currentExpiration;
  }

  private getAclRecord(id: string, acl: AclRecordDto): AclRecordIdExpirationsDto {
    const expirationDate = (acl?.expirations as any)?.[id] ? ConvertToServerDate((acl?.expirations as any)?.[id]) : null;
    return { id, expirationDate };
  }

  private async setUsersList(): Promise<void> {
    const userIds = Object.entries(this.c.model).reduce<string[]>((res: string[], [key, aclRecord]) => {
      if (!key || !aclRecord) return res;

      aclRecord.userIds.forEach((id: string) => res.push(id));
      return res;
    }, []);
    const users = await this.cache.data.users.getManyAsync(userIds);
    this.c.users.setList(users, ID_KEY);
  }
}
