import { SharedTestUtil } from '../../../../../../e2e/helper/general-data-helper/shared-test-util.methods';

export class GradientGeneratorPresetModel {
  id: string = SharedTestUtil.getUniqueId();
  name: string;
  css: string;

  constructor(color?: Partial<GradientGeneratorPresetModel>) {
    color && Object.assign(this, color);
  }
}
