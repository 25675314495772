import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { AvrInputMapperDto } from '@api/models';
import {
  ArtifactTypeAvrFormModel,
  NON_MAPPABLE_FIELDS,
} from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/types/artifact-type-avr-form.types';
import { ArtifactTypeModel } from '@private/pages/artifact-type-management/artifact-type/types/artifact.type.types';
import {
  AVR_TYPE_FIELD_NAME,
  AVR_TYPE_FIELD_NAME_KEY,
  AVR_TYPE_FIELD_VALUE,
  AVR_TYPE_FIELD_VALUE_KEY,
  IS_MANDATORY_KEY,
  IS_MANDATORY_LABEL,
} from '@shared/constants/constants';
import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrTypes, BaseAvrAbstractService } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AvrServices } from '@shared/services/artifact-visual-representation/index.avr-services';
import { NewAttribute } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';

@Component({
  selector: 'app-artifact-type-avr-form',
  templateUrl: './artifact-type-avr-form.component.html',
  styleUrls: ['./artifact-type-avr-form.component.scss'],
})
export class ArtifactTypeAvrFormComponent implements OnInit {
  @Input() model: ArtifactTypeModel;
  @Input() set selectedAvrTypes(avrTypes: AvrTypes[]) {
    const avrMapper: typeof this.m.artifactTypeAvrMapper = {};
    const injectorsForAvrMapper: typeof this.m.injectorsForAvrMapper = {};

    for (const avrType of avrTypes) {
      this.assignAvrMapper(avrMapper, avrType);

      injectorsForAvrMapper[avrType] =
        this.m.injectorsForAvrMapper[avrType] || this.getNonMappableFieldsInjector(avrType, avrMapper[avrType]?.nonMappableFields);
    }

    this.m.artifactTypeAvrMapper = avrMapper;
    this.m.injectorsForAvrMapper = injectorsForAvrMapper;
  }

  m = new ArtifactTypeAvrFormModel();

  constructor(
    @Inject('AvrNonMappableFields') public readonly avrNonMappableFields: Record<string, Component>,
    private readonly avrServices: AvrServices,
    private readonly injector: Injector,
  ) {}

  ngOnInit(): void {
    this.model.options.avrOptions = Object.values(AvrTypes).map(avrType => new SelectOption(this.avrServices.getAvrServices(avrType).readableName, avrType));

    this.m.avrColumns = [
      new TableColumn(AVR_TYPE_FIELD_NAME, AVR_TYPE_FIELD_NAME_KEY),
      new TableColumn(IS_MANDATORY_LABEL, IS_MANDATORY_KEY, undefined, { minWidthCol: true }),
      new TableColumn(AVR_TYPE_FIELD_VALUE, AVR_TYPE_FIELD_VALUE_KEY),
    ];
  }

  // function is executed from parent (ArtifactTypeComponent) during save of artifact type phase
  toServer(): AvrInputMapperDto {
    return Object.entries(this.m.artifactTypeAvrMapper).reduce((output, entry) => {
      const [avrType, avrMapper] = entry as [AvrTypes, BaseAvrInputMapperDto<AvrTypes>];
      Object.assign(output, { [avrType]: this.avrServices.getAvrServices(avrType).toServer(avrMapper) });
      return output;
    }, {});
  }

  filterAttributes(): SelectOption<string, NewAttribute>[] {
    return this.model.options.attributeOptions.filter(attributeOption => attributeOption.disabled === true);
  }

  private getNonMappableFieldsInjector(avrType: AvrTypes, value?: BaseAvrInputMapperDto<AvrTypes>['nonMappableFields']): Injector {
    return Injector.create({
      providers: [{ provide: NON_MAPPABLE_FIELDS, useValue: value ? value : this.m.artifactTypeAvrMapper[avrType]?.nonMappableFields }],
      parent: this.injector,
    });
  }

  private assignAvrMapper<K extends AvrTypes>(avrMapper: typeof this.m.artifactTypeAvrMapper, avrType: K): void {
    // if avrType is already in "m.artifactTypeAvrMapper" => nothing changes
    // else if avrType is not in "m.artifactTypeAvrMapper", but is in "model.artifactType.avrMapper" => after load of artifact type
    // else new selected avrType => create new instance of mapper
    if (this.m.artifactTypeAvrMapper[avrType] !== undefined) {
      avrMapper[avrType] = this.m.artifactTypeAvrMapper[avrType];
    } else if (this.model.artifactType.avrMapper[avrType]) {
      avrMapper[avrType] = (this.avrServices.getAvrServices(avrType) as BaseAvrAbstractService<AvrTypes, any>).fromDto(
        this.model.artifactType.avrMapper[avrType]!,
      );
      // without this delete if I would unselect alteady saved avrType and then select it back, saved mapper would load
      delete this.model.artifactType.avrMapper[avrType];
    } else {
      avrMapper[avrType] = new (this.avrServices.getAvrServices(avrType).inputMapperDto)();
    }
  }
}
