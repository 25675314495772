import { ListWidgetTypeResponseDto } from '@api/models/list-widget-type-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { LinkingPopupUtil } from '@shared/utils/linking-popup.util';
import { WidgetModel } from '@widgets/shared/types/widget-model.interface';
import { WidgetTableUtil } from '@widgets/shared/utils/table.util';
import { MenuItem } from 'primeng/api';
import { ListWidgetOptions } from './list-widget-options.types';
import { ListInternalWidgetModelDto, ListWidgetSelected } from './list-widget-selected.types';
import { ListWidgetTableSettings } from './list-widget-settings.types';

export class ListWidgetValue {
  model: ListWidgetModel = new ListWidgetModel();

  constructor(value?: Partial<ListWidgetValue>) {
    value && Object.assign(this, value);
  }
}

export const TABLE_FORMAT_STATE_KEY = 'tableFormatSettings';
export const TABLE_PARAM_FILTER_STATE_KEY = 'tableParamFilterSettings';

export class ListWidgetModel implements WidgetModel<ListInternalWidgetModelDto> {
  isFirstLoad = true;
  hash: string | null = null;
  addButtonItems: MenuItem[] = [];
  options = new ListWidgetOptions();
  selected = new ListWidgetSelected();
  settings = new ListWidgetTableSettings();
  restrictions: Record<string, ArtifactTypeLinkRestriction[]> | null = null;
  state: Record<string, any> | null = null;
  applicationId: string;
  listType: ListWidgetType | null = null;
  linkingPopupDtoMap: Record<string, Record<string, Partial<ListWidgetTypeResponseDto>>> = {};

  private _cache: NewCacheService;

  constructor(model?: Partial<ListWidgetModel>) {
    model && Object.assign(this, model);
  }

  async initModel(modelDto: ListInternalWidgetModelDto, applicationId: string, cache: NewCacheService): Promise<void> {
    this._cache = cache;
    this.applicationId = applicationId;
    await this.fromDto(modelDto);
    this.settings.showTable = false;
  }

  async fromDto(dto: ListInternalWidgetModelDto) {
    this.settings.fromDto(dto.settings, this.options);
    this.selected.setFromDto(dto.selected, this.options, this.applicationId);
    this.state = WidgetTableUtil.convertStateFromDto(dto.state, this.options);
    this.listType = dto.listType;
    this.linkingPopupDtoMap = await LinkingPopupUtil.processLinkingPopupDtoMap(dto.linkingPopupDtoMap || {}, this._cache);
  }

  toServer(): ListInternalWidgetModelDto {
    return {
      listUiType: 'STANDARD',
      listType: this.listType,
      selected: this.selected.toServer(),
      settings: this.settings.toServer(),
      state: WidgetTableUtil.convertStateToServer(this.state),
      linkingPopupDtoMap: LinkingPopupUtil.toServer(this.linkingPopupDtoMap),
    };
  }
}

export enum ListWidgetType {
  // system-user = 'USER',
  // tenant = 'TENANT',
  file = 'File',
  artifact = 'Artifacts',
  // linkedArtifact = 'Linked artifacts',
  // application = 'APPLICATION',
}

export enum ArtifactPromotionAction {
  PROMOTE = 'PROMOTE',
  DEMOTE = 'DEMOTE',
}

export enum AddArtifactModulePositionEnum {
  BEFORE = 'BEFORE',
  INTO = 'INTO',
  AFTER = 'AFTER',
}

export enum RowSelectionMode {
  NONE = 'NONE',
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
}
