import { Component, Input } from '@angular/core';
import { GetDataTypeFromClientAttribute } from '@shared/methods/artifact.methods';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

import { BaseDataType, DataTypeKind } from '../../../../artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';

@Component({
  selector: 'app-artifact-form',
  templateUrl: './artifact-form.component.html',
  styleUrls: ['./artifact-form.component.scss'],
})
export class ArtifactFormComponent {
  @Input() allAttributes: ListContainer<NewAttribute>;
  @Input() allDataTypes: ListContainer<NewDataType>;
  @Input() attributes: NewClientAttribute[];

  isSimple(artifactAttribute: NewClientAttribute): boolean {
    return GetDataTypeFromClientAttribute(artifactAttribute, this.allAttributes.listMap, this.allDataTypes.listMap)?.kind === DataTypeKind.simple;
  }

  isFile(artifactAttribute: NewClientAttribute): boolean {
    return GetDataTypeFromClientAttribute(artifactAttribute, this.allAttributes.listMap, this.allDataTypes.listMap)?.baseDataType === BaseDataType.file;
  }

  isHyperlink(artifactAttribute: NewClientAttribute): boolean {
    return GetDataTypeFromClientAttribute(artifactAttribute, this.allAttributes.listMap, this.allDataTypes.listMap)?.baseDataType === BaseDataType.hyperlink;
  }

  isEnumerated(artifactAttribute: NewClientAttribute): boolean {
    return GetDataTypeFromClientAttribute(artifactAttribute, this.allAttributes.listMap, this.allDataTypes.listMap)?.kind === DataTypeKind.enumerated;
  }

  isBounded(artifactAttribute: NewClientAttribute): boolean {
    return GetDataTypeFromClientAttribute(artifactAttribute, this.allAttributes.listMap, this.allDataTypes.listMap)?.kind === DataTypeKind.bounded;
  }

  isText(artifactAttribute: NewClientAttribute): boolean {
    return GetDataTypeFromClientAttribute(artifactAttribute, this.allAttributes.listMap, this.allDataTypes.listMap)?.baseDataType === BaseDataType.text;
  }
}
