export class SharedTestUtil {
  /**
   * generate groups of 4 random characters
   * @example getUniqueId(1) : 607f
   * @example getUniqueId(4) : 95ca-361a-f8a1-1e73
   */
  static getUniqueId(parts = 8): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      const s4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(s4);
    }
    return stringArr.join('-');
  }
}
