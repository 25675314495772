import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { UNRESOLVED_VALUE } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { ConvertToClientDate } from '@shared/methods/date.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { RuntimeVariablesMethods } from '../../methods/runtime-variables.methods';
import { RuntimeVariablesOptions } from './runtime-variables-options.types';

export enum RuntimeArtifactKeys {
  id = 'id',
  label = 'label',
  format = 'format',
  createdOn = 'created-on',
  createdBy = 'created-by',
  updatedOn = 'updated-on',
  updatedBy = 'updated-by',
}

export class RuntimeArtifact {
  [RuntimeArtifactKeys.id]: string;
  [RuntimeArtifactKeys.label]: string;
  [RuntimeArtifactKeys.format]: string;
  [RuntimeArtifactKeys.createdOn]: string;
  [RuntimeArtifactKeys.createdBy]: string;
  [RuntimeArtifactKeys.updatedOn]: string;
  [RuntimeArtifactKeys.updatedBy]: string;

  private readonly cache: NewCacheService;
  private readonly dto: ArtifactResponseDto;
  private readonly options: RuntimeVariablesOptions;

  constructor(dto: ArtifactResponseDto, options: RuntimeVariablesOptions, cache: NewCacheService) {
    this.cache = cache;
    this.dto = dto;
    this.options = options;
    const { id, format } = dto;

    this[RuntimeArtifactKeys.id] = id;
    this[RuntimeArtifactKeys.format] = format;
  }

  async init(): Promise<void> {
    const { created, updated } = this.dto;

    await this.setLabel(this.dto, this.options);
    await this.initCreatedAndUpdated(created, updated);
    await this.getClientAttributes(this.dto, this.options).then(attributes => Object.assign(this, attributes));
  }

  private async setLabel(dto: ArtifactResponseDto | NewArtifact, options: RuntimeVariablesOptions): Promise<void> {
    const artifactType = options.artifactTypes.listMap[dto.artifactTypeId];

    if (!artifactType) {
      this[RuntimeArtifactKeys.label] = '';
      return;
    }

    const primaryAttributeValues = await Promise.all(
      artifactType.primaryAttributes.map(attributeId =>
        RuntimeVariablesMethods.formatVariableValue(
          dto,
          attributeId,
          options.artifactTypes.listMap,
          options.attributes.listMap,
          options.dataTypes.listMap,
          this.cache,
        ),
      ),
    );

    this[RuntimeArtifactKeys.label] = primaryAttributeValues.join(', ');
  }

  private async getClientAttributes(dto: ArtifactResponseDto, options: RuntimeVariablesOptions): Promise<Record<string, string>> {
    const res: Record<string, string> = {};
    const attributeIds: string[] = [];

    const aliases: string[] = Object.keys(dto.attributes)
      .map(attributeId => {
        const attribute = options.attributes.listMap[attributeId];
        if (!attribute) return null;

        attributeIds.push(attributeId);
        return attribute?.alias || RuntimeVariablesMethods.formatVariableName(attribute?.name || '');
      })
      .filter(Boolean) as string[];

    await Promise.all(
      attributeIds.map(attributeId =>
        RuntimeVariablesMethods.formatVariableValue(
          dto,
          attributeId,
          options.artifactTypes.listMap,
          options.attributes.listMap,
          options.dataTypes.listMap,
          this.cache,
        ),
      ),
    ).then(values => values.forEach((value, index) => (res[aliases[index]] = value)));

    return res;
  }

  private async initCreatedAndUpdated(created: RecordDto, updated: RecordDto): Promise<void> {
    this[RuntimeArtifactKeys.createdOn] = ConvertToClientDate(new Date(created.on));
    this[RuntimeArtifactKeys.createdBy] =
      ((await this.cache.data.users.getAsync(created.by))?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) ||
      UNRESOLVED_VALUE;

    this[RuntimeArtifactKeys.updatedOn] = ConvertToClientDate(new Date(updated.on));
    this[RuntimeArtifactKeys.updatedBy] =
      ((await this.cache.data.users.getAsync(updated.by))?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) ||
      UNRESOLVED_VALUE;
  }
}
