import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantDataTypeService } from '@api/services/tenant-data-type.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { DataTypeListService } from './services/data-type-list.service';
import { DataTypeListModel } from './types/data-type-list.types';

@Component({
  selector: 'app-data-type-list',
  templateUrl: './data-type-list.component.html',
  styleUrls: ['./data-type-list.component.scss'],
})
export class DataTypeListComponent extends CoreComponent<DataTypeListService, DataTypeListModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: DataTypeListService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly tenantDataTypeService: TenantDataTypeService,
  ) {
    super(route, router, translate, new DataTypeListModel(), service, announcement);
  }

  loadData() {
    return (meta?: Record<string, string>) => this.tenantDataTypeService.dataTypeControllerList(meta).toPromise();
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Data types' }]);
  }
}
