<p-accordion [styleClass]="'p-accordion-shared-styles-settings'">
  <!-- Margin Section -->
  @if (!settingsVisibility.hideMargin) {
    <p-accordionTab header="{{'Margin (Outer)' | translate}}">
      <div class="grid padding-5">
        <div class="col-6 field-padding">
          <label>{{ 'Top' | translate }}</label>
          <input class="w-100" type="text" pInputText [(ngModel)]="styles.marginTop"
                 (ngModelChange)="onAnythingChange()" />
        </div>
        <div class="col-6 field-padding">
          <label>{{ 'Right' | translate }}</label>
          <input class="w-100" type="text" pInputText [(ngModel)]="styles.marginRight"
                 (ngModelChange)="onAnythingChange()" />
        </div>
        <div class="col-6 field-padding">
          <label>{{ 'Bottom' | translate }}</label>
          <input class="w-100" type="text" pInputText [(ngModel)]="styles.marginBottom"
                 (ngModelChange)="onAnythingChange()" />
        </div>
        <div class="col-6 field-padding">
          <label>{{ 'Left' | translate }}</label>
          <input class="w-100" type="text" pInputText [(ngModel)]="styles.marginLeft"
                 (ngModelChange)="onAnythingChange()" />
        </div>
      </div>
    </p-accordionTab>
  }

  <!-- Padding Section -->
  @if (!settingsVisibility.hidePadding) {
    <p-accordionTab header="{{'Padding (Inner)' | translate}}">
      <div class="grid padding-5">
        <div class="col-6 field-padding">
          <label>{{ 'Top' | translate }}</label>
          <input class="w-100" type="text" pInputText [(ngModel)]="styles.paddingTop"
                 (ngModelChange)="onAnythingChange()" />
        </div>
        <div class="col-6 field-padding">
          <label>{{ 'Right' | translate }}</label>
          <input class="w-100" type="text" pInputText [(ngModel)]="styles.paddingRight"
                 (ngModelChange)="onAnythingChange()" />
        </div>
        <div class="col-6 field-padding">
          <label>{{ 'Bottom' | translate }}</label>
          <input class="w-100" type="text" pInputText [(ngModel)]="styles.paddingBottom"
                 (ngModelChange)="onAnythingChange()" />
        </div>
        <div class="col-6 field-padding">
          <label>{{ 'Left' | translate }}</label>
          <input class="w-100" type="text" pInputText [(ngModel)]="styles.paddingLeft"
                 (ngModelChange)="onAnythingChange()" />
        </div>
      </div>
    </p-accordionTab>
  }

  <!-- Background Section -->
  @if (!settingsVisibility.hideBackground) {
    <p-accordionTab header="{{'Background' | translate}}">
      <p-dropdown
        [(ngModel)]="styles.backgroundType"
        [options]="options.backgroundType"
        (onChange)="onBgTypeChange()"
      ></p-dropdown>

      @if (styles.backgroundType === BackgroundTypeEnum.color) {
        <div class="field-padding">
          <app-color-picker [(color)]="styles.backgroundColor"
                            (colorChange)="onColorChange.emit(); onAnythingChange();"></app-color-picker>
        </div>
      }

      @if (styles.backgroundType === BackgroundTypeEnum.image) {
        <div class="field-padding">
          <app-image-pointer
            [applicationId]="applicationId"
            [imageUrl]="styles.backgroundImage"
            [styles]="styles"
            (imageFileIdChange)="onBackgroundFileIdChange($event)"
            (imageUrlChange)="onBackgroundFileUrlChange($event)"
            (stylesChange)="onStylesChange($event)"
          >
          </app-image-pointer>
        </div>
      }

      @if (styles.backgroundType === BackgroundTypeEnum.gradient) {
        <div class="field-padding">
          <app-gradient-generator [styles]="styles" (onChange)="onAnythingChange()"></app-gradient-generator>
        </div>
      }
    </p-accordionTab>
  }

  <!-- Border Section -->
  @if (!settingsVisibility.hideBoxShadow) {
    <p-accordionTab header="Box shadow">
      <!-- Add more border settings here if needed -->
      <app-box-shadow-generator [styles]="styles" (onChange)="onAnythingChange()"></app-box-shadow-generator>
    </p-accordionTab>
  }

  <!-- Border Section -->
  @if (!settingsVisibility.hideBorder) {
    <p-accordionTab header="Border">
      <app-shared-border-settings [options]="options" [styles]="styles"
                                  (onChange)="onAnythingChange()"></app-shared-border-settings>
    </p-accordionTab>
  }

  <!-- Display Section -->
  @if (!settingsVisibility.hideDisplay) {
    <p-accordionTab header="Display">
      <p-dropdown
        [(ngModel)]="styles.display"
        [options]="options.display"
        placeholder="Select Display Type"
        (ngModelChange)="onAnythingChange()"
      ></p-dropdown>

      @if (styles.display === DisplayEnum.flex) {
        <div class="form-group">
          <div class="p-float-label mt-4 mb-4">
            <p-dropdown [(ngModel)]="styles.flexDirection" [options]="options.flexDirection"
                        (ngModelChange)="onAnythingChange()"
                        inputId="flexDirection"></p-dropdown>
            <label for="flexDirection">Flex Direction</label>
          </div>

          <div class="p-float-label mt-4 mb-4">
            <p-dropdown [(ngModel)]="styles.flexWrap" [options]="options.flexWrap"
                        (ngModelChange)="onAnythingChange()"
                        inputId="flexWrap"></p-dropdown>
            <label for="flexWrap">Flex Wrap</label>
          </div>

          <div class="p-float-label mt-4 mb-4">
            <p-dropdown [(ngModel)]="styles.justifyContent" [options]="options.justifyContent"
                        (ngModelChange)="onAnythingChange()"
                        inputId="justifyContent"></p-dropdown>
            <label for="justifyContent">Justify Content</label>
          </div>

          <div class="p-float-label mt-4 mb-4">
            <p-dropdown [(ngModel)]="styles.alignItems" [options]="options.alignItems"
                        (ngModelChange)="onAnythingChange()"
                        inputId="alignItems"></p-dropdown>
            <label for="alignItems">Align Items</label>
          </div>

          <div class="p-float-label mt-4 mb-4">
            <p-dropdown [(ngModel)]="styles.alignContent" [options]="options.alignContent"
                        (ngModelChange)="onAnythingChange()"
                        inputId="alignContent"></p-dropdown>
            <label for="alignContent">Align Content</label>
          </div>

          <div class="p-float-label mt-4">
            <input class="w-100" type="text" pInputText [(ngModel)]="styles.gap" (ngModelChange)="onAnythingChange()" />
            <label for="alignContent">Gap</label>
          </div>
        </div>
      }
    </p-accordionTab>

    <!-- Overflow -->
    @if (!settingsVisibility.hideOverflow) {
      <p-accordionTab header="Overflow">
        <div class="grid">
          <div class="col-6 p-0 pr-1 pl-1">
            <div class="p-float-label mt-4">
              <p-dropdown [(ngModel)]="styles.overflowX" [options]="options.overflow" inputId="overflowX"
                          (ngModelChange)="onAnythingChange()"></p-dropdown>
              <label for="overflowX">Overflow X</label>
            </div>
          </div>

          <div class="col-6 p-0 pl-1 pr-1">
            <div class="p-float-label mt-4">
              <p-dropdown [(ngModel)]="styles.overflowY" [options]="options.overflow" inputId="overflowY"
                          (ngModelChange)="onAnythingChange()"></p-dropdown>
              <label for="overflowY">Overflow Y</label>
            </div>
          </div>
        </div>
      </p-accordionTab>
    }

    @if (!settingsVisibility.hideTextFormatSettings) {
      <p-accordionTab header="Text formatting">
        <app-font-style-form [fontStyles]="styles" (onChange)="onAnythingChange()"></app-font-style-form>
      </p-accordionTab>
    }
  }
</p-accordion>