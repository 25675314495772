import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AvrDocumentGenerationNonMappableFieldsComponent } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/components/document-generation/avr-document-generation.non-mappable-fields.component';
import { AvrQrCodesNonMappableFieldsComponent } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/components/qr-codes/avr-qr-codes.non-mappable-fields.component';
import { AvrSwissBillNonMappableFieldsComponent } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/components/swiss-bill/avr-swiss-bill.non-mappable-fields.component';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

@NgModule({
  declarations: [AvrSwissBillNonMappableFieldsComponent, AvrDocumentGenerationNonMappableFieldsComponent, AvrQrCodesNonMappableFieldsComponent],
  providers: [
    {
      provide: 'AvrNonMappableFields',
      useFactory: () => {
        return {
          [AvrTypes.swissBill]: AvrSwissBillNonMappableFieldsComponent,
          [AvrTypes.documentGeneration]: AvrDocumentGenerationNonMappableFieldsComponent,
          [AvrTypes.qrCodes]: AvrQrCodesNonMappableFieldsComponent,
        };
      },
    },
  ],
  imports: [CommonModule, FormsModule, RouterModule, TriStateCheckboxModule, DropdownModule, MultiSelectModule, TooltipModule, TranslateModule, ButtonModule],
})
export class AvrNonMappableFieldsModule {}
