import { Component, Input } from '@angular/core';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import {
  ManageUserApplicationsKeys,
  ManageUserApplicationsOperations,
  WorkflowActionManageUserApplications,
} from '@workflows/types/actions/action-manage-user-applications';

@Component({
  selector: 'app-action-manage-user-applications',
  templateUrl: './action-manage-user-applications.component.html',
  styleUrls: ['./action-manage-user-applications.component.scss'],
})
export class ActionManageUserApplicationsComponent {
  @Input() action: WorkflowActionManageUserApplications;
  @Input() applications: ListContainer<NewApplication>;

  protected readonly RuleKeys = ManageUserApplicationsKeys;
  protected readonly ApplicationsOperations = ManageUserApplicationsOperations;
  protected applicationOperationOptions: SelectOption<string, ManageUserApplicationsOperations>[];

  constructor(private readonly translateUtil: TranslateUtil) {
    Promise.all(
      Object.values(ManageUserApplicationsOperations).map(
        async operation => new SelectOption(await this.translateUtil.get(`WORKFLOWS.ADDITIONAL_TRANSLATIONS.MANAGE_USER_APPLICATIONS.${operation}`), operation),
      ),
    ).then(options => (this.applicationOperationOptions = options));
  }

  onAddApplication(): void {
    this.action.manageUserApplicationsApplications.push({ applicationId: '', operation: ManageUserApplicationsOperations.addNonAdmin, setDefault: false });
  }

  onRemoveApplication(index: number): void {
    this.action.manageUserApplicationsApplications.splice(index, 1);
  }
}
