<div *ngIf="artifact.attributes[attributeId]?.value" [ngSwitch]="selectedVariant"
     class="at-html-container full-size-container">
  <ng-container *ngSwitchCase="'DEFAULT'" [ngTemplateOutlet]="default"></ng-container>

  <div *ngSwitchCase="'DETAIL_ON_CLICK'" class="icon-with-detail-container">
    <i #iconClick *ngIf="artifact.attributes[attributeId].value"
       class="{{ attribute.icon || 'pi pi-window-maximize' }} clickable"></i>
  </div>

  <div *ngSwitchCase="'CUSTOM'">
    <!-- [applicationId]="applicationId" -->
    <ng-container *ngIf="customVariantTemplate$ | async as widget">
      <app-widget-container
        *ngIf="widget.code === widgetType.card"
        [artifact]="artifactDto || artifact"
        [isClickable]="false"
        [isLayoutMode]="true"
        [isListMatrixCard]="true"
        [isPreviewMode]="isPreviewMode"
        [disabled]="isPreviewMode"
        [widget]="widget"
        appWidgetContainerStopPropagation
      >
      </app-widget-container>
      <!-- <app-widget-container
        *ngIf="widget.code === widgetType.avr"
        [artifact]="artifactDto || artifact"
        [isLayoutMode]="true"
        [disabled]="isPreviewMode"
        [widget]="widget"
        appWidgetContainerStopPropagation
      >
      </app-widget-container> -->
    </ng-container>
  </div>
</div>

<ng-template #default>
  <div
    [innerHTML]="artifact.attributes[attributeId].value | toHtml : dataType.baseDataType | replaceRuntimeVariables : artifact | async | safeHtml"
    [ngStyle]="contentStyles"
    class="mce-content-body mce-content-readonly"
  ></div>
</ng-template>
