import { Component, Input } from '@angular/core';
import { ArtifactHistoryResponseDto } from '@api/models/artifact-history-response-dto';
import { GlobalConstants } from '@shared/constants/global.constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ListContainer } from '@shared/types/list-container.types';

@Component({
  selector: 'app-artifact-history-view',
  templateUrl: './artifact-history-view.component.html',
  styleUrls: ['./artifact-history-view.component.scss'],
})
export class ArtifactHistoryViewComponent {
  @Input() artifactHistory: ArtifactHistoryResponseDto[];
  @Input() artifactType: NewArtifactType;
  @Input() attributes: ListContainer<NewAttribute>;
  @Input() dataTypes: ListContainer<NewDataType>;

  nameAttributeId = GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId);
}
