import { Component, Inject } from '@angular/core';
import { DocumentGenerationAvrInputMapperDto } from '@api/models';
import { TenantArtifactService } from '@api/services';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGenerationAvrService } from '@shared/services/artifact-visual-representation/document-generation/document-generation.avr.service';
import { ArtifactTypeFormatEnum } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { SelectOption } from '@shared/types/shared.types';
import {
  AbstractAvrQuerySettingsComponent,
  AVR_SETTINGS_INJECTOR,
  AvrSettingsInjectorBody,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/abstract.avr-types-settings.component';
import {
  AvrTemplateFileArtifactSources,
  DocumentGenerationAvrWidgetSettingsDto,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/document-generation/types/document-generation.avr-types-settings.types';
import { DocumentGenerationAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/document-generation/document-generation.avr-types-services.service';
import { omit } from 'lodash';
import { lastValueFrom, map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-avr-widget-document-generation-types-settingss',
  templateUrl: './document-generation.avr-types-settings.component.html',
  styleUrls: ['./document-generation.avr-types-settings.component.scss'],
})
export class AvrDocumentGenerationTypesSettingsComponent extends AbstractAvrQuerySettingsComponent<
  AvrTypes.documentGeneration,
  DocumentGenerationAvrTypeService
> {
  protected templateArtifactSourcesOptions: SelectOption<AvrTemplateFileArtifactSources, AvrTemplateFileArtifactSources>[] =
    GetSelectOptionsFromEnum<AvrTemplateFileArtifactSources>(AvrTemplateFileArtifactSources);
  protected artifactOptions: SelectOption<string, NewArtifact>[] = [];
  protected AvrTemplateFileArtifactSources = AvrTemplateFileArtifactSources;
  protected init$: Observable<boolean> = of(false).pipe(
    map(() => {
      const avrMapper = this.service.m.currentArtifactTypeOption?.value.avrMapper[AvrTypes.documentGeneration] as DocumentGenerationAvrInputMapperDto;
      if (avrMapper.everythingWithinArtifactType === true) {
        let mimetypes: string[] = DocumentGenerationAvrService.mimetypeIntersection(avrMapper.outputTypes as AvrOutputTypes[]);

        return JSON.stringify({
          format: ArtifactTypeFormatEnum.file,
          artifactTypeId: { $oid: avrMapper.fileArtifactTypeId },
          'formatData.mimetype': {
            $in: mimetypes,
          },
        });
      }

      return JSON.stringify({
        _id: {
          $in: avrMapper.templates.map(template => {
            return { $oid: template.fileArtifactId };
          }),
        },
        deleted: null,
      });
    }),
    switchMap(filter => {
      return this.artifactService.artifactControllerList({ body: { filter } });
    }),
    map(({ data }) => {
      this.artifactOptions = data.map(artifactResponse => {
        const transformedArtifact = this.service.artifactResponseTransform(artifactResponse, this.service.templateFileArtifactType!);
        const label = transformedArtifact.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value || '';
        return new SelectOption(label, transformedArtifact);
      });

      const avrMapper = this.service.m.currentArtifactTypeOption!.value.avrMapper[AvrTypes.documentGeneration] as DocumentGenerationAvrInputMapperDto;
      if (avrMapper.everythingWithinArtifactType === false) {
        const missingArtifacts = avrMapper.templates
          .filter(template => template.fileArtifactId !== '' && data.findIndex(artifact => artifact.id === template.fileArtifactId) === -1)
          .entries();
        for (const [i, missingArtifact] of missingArtifacts) {
          this.service.s.addNonAccessibleOption(this.artifactOptions, i + 1, missingArtifact);
        }
      }

      return true;
    }),
  );

  constructor(
    @Inject(AVR_SETTINGS_INJECTOR) protected injector: AvrSettingsInjectorBody,
    private readonly artifactService: TenantArtifactService,
  ) {
    super(injector, AvrTypes.documentGeneration, DocumentGenerationAvrWidgetSettingsDto);
  }

  protected async onTemplateArtifactSourceChange(): Promise<void> {
    Object.assign(this.settingFields!, omit(new this.avrSettingDto(), 'templateFileArtifactSource', 'outputType'));
    await this.service.initTemplateArtifactSource();
    this.service.updateSelectedArtifact();
  }

  protected async onTemplateArtifactListeningKeyChange(): Promise<void> {
    this.service.listeningKeyChange.next();
  }

  protected onStaticArtifactChange(): void {
    lastValueFrom(this.service.setTemplateArtifact$(this.settingFields!.templateFileArtifactId)).then(() => this.service.updateSelectedArtifact());
  }
}
