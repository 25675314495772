import { Injectable } from '@angular/core';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { QrCodesOutputTypes } from '@shared/services/artifact-visual-representation/qr-codes/dto/qr-codes.input-mapper.dto';

import { QrCodesAvrQueryDto } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/qr-codes/types/qr-codes.avr-types-settings.types';
import { AbstractAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/abstract.avr-types-services.service';

@Injectable()
export class QrCodesAvrTypeService extends AbstractAvrTypeService<AvrTypes.qrCodes> {
  constructor() {
    super(AvrTypes.qrCodes);
  }

  getAdditionalQueryParams(): QrCodesAvrQueryDto | null {
    if (this.m.currentAvrFileType === null) {
      this.m.avrBlobError = 'Output type is not selected';
      return null;
    }
    return new QrCodesAvrQueryDto({
      outputType: this.m.currentAvrFileType as Extract<AvrOutputTypes, `${(typeof QrCodesOutputTypes)[number]}`>,
    });
  }
}
