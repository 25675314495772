import { Injectable } from '@angular/core';
import { PageResponseDto } from '@api/models/page-response-dto';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { ArtifactWidgetItemActions, DefaultElvisActions, MenuWidgetItemActions } from '@shared/types/actions.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { MenuItemCurrentPageBehaviorEnum } from '@widgets/menu-widget/types/tree-types';
import { ActionListComponent } from '@widgets/shared/components/action-list/action-list.component';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { TreeNode } from 'primeng/api/treenode';

@Injectable()
export class ActionListService {
  c: ActionListComponent;

  constructor(
    private readonly cache: NewCacheService,
    private translateUtil: TranslateUtil,
  ) {}

  init(context: ActionListComponent): void {
    this.c = context;
    this.c.elvisActions = [...this.specialActions, ...this.getElvisActions()];

    this.c.elvisActions?.forEach((group: TreeNode) => group?.children?.length && this.setTranslatedLabels(group.children));

    this.c.handler = this.cache.data.pages.subscribe(pages =>
      this.c.pages.setList(
        (pages as PageResponseDto[]).filter(page => !page.deleted).map(dto => new Page(dto)),
        ID_KEY,
      ),
    );
  }

  getElvisActions(basePartType: any = MenuActionParts): TreeNode[] {
    return Object.keys(basePartType).map((part: string, i: number) => ({
      children: this.getChildrenByGroupName(part),
      selectable: false,
      expanded: false,
      key: `group-${i}`,
      label: basePartType[part as keyof typeof basePartType],
      styleClass: 'group-node',
    }));
  }

  get specialActions(): TreeNode[] {
    if (this.c.showListWidgetGroup) {
      return this.getElvisActions(SpecialActionParts);
    } else if (this.c.showMenuWidgetGroup) {
      return this.getElvisActions(SpecialMenuActionParts);
    } else if (this.c.showMatrixWidgetGroup) {
			return this.getElvisActions(SpecialMatrixActionParts);
		}
    return [];
  }

  private async setTranslatedLabels(children: TreeNode[]): Promise<void> {
    const translatedLabels = await this.translateUtil.getAll(children.map((item: TreeNode) => item?.label || ''));
    children.forEach((child: any, i: number) => (child.label = translatedLabels[i]));
  }

  private getChildrenByGroupName(name: string): TreeNode[] {
    const groups = {
      [MenuActionParts.other]: [DefaultElvisActions.goToProfile, DefaultElvisActions.logout, DefaultElvisActions.customEvent, DefaultElvisActions.doNothing],
      [MenuActionParts.navigation]: [MenuItemCurrentPageBehaviorEnum.leaveCurrentPage],
      [MenuActionParts.query]: [
        MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage,
        DefaultElvisActions.removeAllQueryParams,
        DefaultElvisActions.removeQueryParamsFromThisWidget,
      ],
      [SpecialActionParts.listWidgetGroup]: [
        ArtifactListItemClickAction.addToLink,
        ArtifactListItemClickAction.goToPage,
        ArtifactListItemClickAction.goToExternalPage,
        ArtifactListItemClickAction.goToDefaultArtifactPage,
        ArtifactListItemClickAction.selectItem,
      ],
      [SpecialMenuActionParts.menuWidgetGroup]: [MenuWidgetItemActions.beWfTrigger],
      [SpecialMenuActionParts.artifactWidgetGroup]: [
        ArtifactWidgetItemActions.save,
        ArtifactWidgetItemActions.saveAndNotify,
        ArtifactWidgetItemActions.edit,
        ArtifactWidgetItemActions.cancel,
        ArtifactWidgetItemActions.showOperationHistory,
        ArtifactWidgetItemActions.showAccessRights,
        ArtifactWidgetItemActions.delete,
      ],
    [SpecialMatrixActionParts.matrixWidgetGroup]: [
				ArtifactListItemClickAction.addToLink,
			]
		};

    // @ts-ignore
    const group =
      groups[MenuActionParts[name as keyof typeof MenuActionParts]] ||
      groups[SpecialActionParts[name as keyof typeof SpecialActionParts]] ||
      groups[SpecialMatrixActionParts[name as keyof typeof SpecialMatrixActionParts]] ||groups[SpecialMenuActionParts[name as keyof typeof SpecialMenuActionParts]];
    return group ? group.map((item: string, i: number) => this.getTreeItem(item, `${name}-item-${i}`)) : [];
  }

  private getTreeItem(name: string, id: string): TreeNode {
    return {
      label: name,
      longLabel: name,
      data: name,
      selectable: true,
      key: id,
    } as TreeNode;
  }
}

export enum MenuActionParts {
  other = 'Other',
  navigation = 'Navigation',
  query = 'Work with query parameters',
}

export enum SpecialActionParts {
  listWidgetGroup = 'List Widget actions',
}

export enum SpecialMenuActionParts {
  menuWidgetGroup = 'Menu Widget actions',
  artifactWidgetGroup = 'Artifact widget actions',
}

export enum SpecialMatrixActionParts {
	matrixWidgetGroup = 'Matrix Widget actions',
}
