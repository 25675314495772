import { GetArtifactsActionWorkflowSettingsDto } from '@api/models/get-artifacts-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum GetArtifactsKeys {
  filter = 'filter',
  sort = 'sort',
  scopePathToStore = 'scopePathToStore',
  userId = 'userId',
}

export class WorkflowActionGetArtifactsDto implements GetArtifactsActionWorkflowSettingsDto {
  filter: GetArtifactsActionWorkflowSettingsDto[GetArtifactsKeys.filter] = { value: '', isDynamic: false };
  sort: GetArtifactsActionWorkflowSettingsDto[GetArtifactsKeys.sort] = { value: '', isDynamic: false };
  scopePathToStore: GetArtifactsActionWorkflowSettingsDto[GetArtifactsKeys.scopePathToStore] = { value: '', isDynamic: false };
  userId: GetArtifactsActionWorkflowSettingsDto[GetArtifactsKeys.userId] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionGetArtifactsDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionGetArtifacts extends AbstractWorkflowAction<WorkflowActionGetArtifactsDto> {
  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionGetArtifactsDto, conditionConverter, type: WorkflowActionType.GET_ARTIFACTS, dto });
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    const userActionSettings = { isDynamic: false, value: this.actionSettings.userId.value };

    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [GetArtifactsKeys.userId]: userActionSettings,
      },
    };
  }
}
