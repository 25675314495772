import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NAME_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { LinkType } from '@shared/types/link-type.types';
import { LinkRestrictionParamsBase } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import {
  LinkFilterByLinked,
  LinkFilterOption,
  LinkFilterOptions,
} from '@widgets/shared/components/artifact-list-table/components/table-header-column/link-filter/link-filter-by-linked.types';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  standalone: true,
  selector: 'app-link-filter',
  templateUrl: './link-filter.component.html',
  styleUrl: './link-filter.component.scss',
  imports: [CommonModule, FormsModule, DropdownModule, CommonPipesModule, TranslateModule, MultiSelectModule, ArtifactFiltersModule],
})
export class LinkFilterComponent implements AfterViewInit {
  @Input() restrictions: LinkRestrictionParamsBase;
  @Input() linkTypes: ListContainer<LinkType>;
  @Input() artifactTypes: ListContainer<NewArtifactType>;
  @Input() attributes: ListContainer<NewAttribute>;
  @Input() dataTypes: NewDataType[];
  @Input() linkedFilter: LinkFilterByLinked = new LinkFilterByLinked();

  @Output() onApply = new EventEmitter();

  artifactTypesList: NewArtifactType[];
  options = new LinkFilterOptions();
  NAME_KEY = NAME_KEY;

  preselectAttribute: NewAttribute | NewClientAttribute | DirectionalLinkType;

  constructor(private readonly cache: NewCacheService) {}

  ngAfterViewInit(): void {
    const linkType = this.linkTypes.listMap[this.restrictions.linkTypeId];
    const artifactTypeIds: string[] = [];

    linkType.restrictions.forEach(r => {
      const id = this.restrictions.direction === LinkDirection.outgoing ? r.destinationArtifactTypeId : r.sourceArtifactTypeId;
      !artifactTypeIds.includes(id) && artifactTypeIds.push(id);
    });
    this.artifactTypesList = artifactTypeIds.map(id => this.artifactTypes.listMap[id]);

    let nameAttr: NewAttribute | undefined;
    const attrId = this.cache.data.attributes.get(GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId))?.id;
    this.artifactTypesList.forEach(at => {
      Object.keys(at.attributes).forEach(id => id === attrId && (nameAttr = this.attributes.listMap[id]));
    });

    if (this.linkedFilter.ruleTypes.length === 1 && !this.linkedFilter.ruleTypes[0].artifactType.length) {
      this.linkedFilter.ruleTypes = [];
      this.linkedFilter.ruleTypes.push(new LinkFilterOption());
      this.linkedFilter.ruleTypes[0].artifactType = [];

      this.artifactTypesList.forEach(at => {
        this.linkedFilter.ruleTypes[0].artifactType.push(at);
        this.onArtifactTypeChange(0);
      });
    } else {
      this.linkedFilter.ruleTypes.forEach(rule => {
        rule.artifactType = rule.artifactType.map(artifactType => this.artifactTypes.listMap[artifactType.id]);
      });
    }

    nameAttr && (this.preselectAttribute = nameAttr);
  }

  onPressKey(e: any): void {
    (e.key === 'Enter') && this.onApply.emit();
  }

  addRule(): void {
    this.linkedFilter.ruleTypes.push(new LinkFilterOption());
  }

  removeRule(index: number): void {
    this.linkedFilter.ruleTypes.splice(index, 1);
  }

  onArtifactTypeChange(index: number): void {
    const rule = this.linkedFilter.ruleTypes[index];
    const attributes: NewAttribute[] = [];

    rule.artifactType.forEach(at => {
      Object.keys(this.artifactTypes.listMap[at.id].attributes).forEach(id => {
        attributes.push(this.attributes.listMap[id]);
      });
    });

    rule.attributes = [...new Set(attributes)];
  }
}
