import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppArtifactAttributeValueEditFieldModule } from '@private/components/artifact-attribute-value-edit-field/artifact-attribute-value-edit-field.component';
import { FileAttributeValueEditFieldModule } from '@private/components/file-attribute-value-edit-field/file-attribute-value-edit-field.component';
import { PublicModule } from '@public/public.module';
import { AppAttributeValueEditFieldModule } from '@shared/components/attribute-value-edit-field/attribute-value-edit-field.component';
import { FileAttributeModule } from '@shared/components/file-attribute/file-attribute.component';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ArtifactPipesModule } from '@shared/pipes/artifact-pipes/artifact-pipes.module';
import { AttributePipesModule } from '@shared/pipes/attribute-pipes/attribute-pipes.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { WithEmptyEnumOptionModule } from '@shared/pipes/with-empty-enum-option/with-empty-enum-option.module';
import { WithEmptySelectOptionModule } from '@shared/pipes/with-empty-select-option/with-empty-select-option.module';
import { FolderWidgetModule } from '@widgets/folder-widget/folder-widget.module';
import { NewLinkPopupModule } from '@widgets/link-popup/artifact-link-dialog.module';
import { AclModule } from '@widgets/shared/components/acl/acl.module';
import { ActionListModule } from '@widgets/shared/components/action-list/action-list.module';
import { AttributeFormatLabelStylesFormModule } from '@widgets/shared/components/attribute-format-settings';
import { ListItemClickActionSettingsFormModule } from '@widgets/shared/components/list-item-click-action-settings-form/list-item-click-action-settings-form.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { GetRowsPerPageHeaderPipeModule } from '@widgets/shared/pipes/get-rows-per-page-header.pipe';
import { IsGroupingByDatePipeModule } from '@widgets/shared/pipes/is-grouping-by-date.pipe';
import { IsGroupingByUserPipe } from '@widgets/shared/pipes/is-grouping-by-user.pipe';
import { ShouldShowCustomRangeCalendarOptionPipeModule } from '@widgets/shared/pipes/should-show-custom-range-calendar-option.pipe';
import { ShouldShowDateOffsetOptionPipeModule } from '@widgets/shared/pipes/should-show-date-offset-option.pipe';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ArtifactListWidgetTableModule } from '../shared/components/artifact-list-table/artifact-list-widget-table.module';
import { ListWidgetTableSettingsComponent } from './components/table-settings/list-widget-table-settings.component';
import { ShouldShowFolderEmittingOptionPipe } from './components/table-settings/pipes/should-show-folder-emitting-option.pipe';
import { ListWidgetComponent } from './list-widget.component';

const components = [ListWidgetComponent, ListWidgetTableSettingsComponent];

@NgModule({
  declarations: [...components, ShouldShowFolderEmittingOptionPipe],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    TranslateModule,
    AccordionModule,
    ButtonModule,
    RippleModule,
    AppArtifactAttributeValueEditFieldModule,
    CheckboxModule,
    RadioButtonModule,
    InputNumberModule,
    InputTextModule,
    RouterModule,
    BadgeModule,
    CalendarModule,
    TriStateCheckboxModule,
    PublicModule,
    DialogModule,
    TooltipModule,
    FileAttributeModule,
    ElvisSharedModule,
    NewLinkPopupModule,
    DragDropModule,
    SplitButtonModule,
    ConfirmDialogModule,
    CommonPipesModule,
    DataTypePipesModule,
    ArtifactPipesModule,
    AppAttributeValueEditFieldModule,
    AttributePipesModule,
    WithEmptySelectOptionModule,
    WithEmptyEnumOptionModule,
    FileAttributeValueEditFieldModule,
    PaginatorModule,
    ListItemClickActionSettingsFormModule,
    OverlayPanelModule,
    TabViewModule,
    StyleFormModule,
    MenuModule,
    AclModule,
    FolderWidgetModule,
    AttributeFormatLabelStylesFormModule,
    IsGroupingByDatePipeModule,
    ShouldShowDateOffsetOptionPipeModule,
    ShouldShowCustomRangeCalendarOptionPipeModule,
    GetRowsPerPageHeaderPipeModule,
    SelectButtonModule,
    SidebarModule,
    TreeModule,
    ArtifactListWidgetTableModule,
    ActionListModule,
    IsGroupingByUserPipe,
  ],
  exports: [...components],
})
export class ListWidgetModule {}
