import { ServerSendEventMethods } from '@shared/methods/server-send-event.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { WorkflowRule } from '@workflows/types';

export class RuntimeStateNotification<Data = any> {
  constructor(
    public type: RuntimeStateNotificationEnum,
    public data: Data,
    public hash: string | null = null,
    public extras: any = null,
  ) {}

  get isOfTypeCreateArtifact(): boolean {
    return ServerSendEventMethods.isCreateArtifact(this.type);
  }

  get isOfTypeUpdateArtifact(): boolean {
    return ServerSendEventMethods.isUpdateArtifact(this.type);
  }

  get isOfTypeDeleteArtifact(): boolean {
    return ServerSendEventMethods.isDeleteArtifact(this.type);
  }

  get isOfTypeCreateLink(): boolean {
    return ServerSendEventMethods.isCreateLink(this.type);
  }

  get isOfTypeUpdateLinks(): boolean {
    return ServerSendEventMethods.isUpdateLinks(this.type);
  }

  get isOfTypeDeleteLink(): boolean {
    return ServerSendEventMethods.isDeleteLink(this.type);
  }
}

export enum RuntimeStateNotificationEnum {
  customEvent = 'customEvent', // { data }
  uploadProgress = 'uploadProgress', // return { fileName, percent }
  updateDisplayVariant = 'updateDisplayVariant', // return null
  openConditionalFormattingDialog = 'openConditionalFormattingDialog',
  // LW
  updateColumnStyles = 'updateColumnStyles', // return attribute id
  // show/hide page builder header
  togglePageBuilderHeaderVisible = 'togglePageBuilderHeaderVisible', // return boolean
  // open/close system panel
  toggleSystemPanel = 'toggleSystemPanel', // return null
  // folder picker
  closeFolderPicker = 'closeFolderPicker', // return null or FolderTreeNode
  // sidebar
  openSidebar = 'openSidebar', // RuntimeStateNotification.data must be sidebar "id"
  // widget
  copyWidget = 'copyWidget', // RuntimeStateNotification.data must be "PageBlockPart" (partToCopy)
  // artifact
  createArtifact = 'createArtifact', // RuntimeStateNotification.data must be "ArtifactResponseDto"
  updateArtifact = 'updateArtifact', // RuntimeStateNotification.data must be "ArtifactResponseDto"
  deleteArtifact = 'deleteArtifact', // RuntimeStateNotification.data must be "ArtifactResponseDto"
  // link
  createLink = 'createLink', // RuntimeStateNotification.data must be "[sourceArtifactId, destinationArtifactId]", RuntimeStateNotification.extras must be "linkTypeId"
  updateLinks = 'updateLinks', // RuntimeStateNotification.data must be "Array<UpdateLinksDto>"
  deleteLink = 'deleteLink', // RuntimeStateNotification.data must be "[sourceArtifactId, destinationArtifactId]", RuntimeStateNotification.extras must be "linkTypeId"
}

export interface UpdateLinksDto {
  artifactIds: string[]; // Array<artifactId>
  linkTypeId: string;
}

export interface ConditionalFormattingDialogEvent {
  artifactTypes: NewArtifactType[];
  attribute: NewAttribute;
  widgetId: string;
  selectedRule?: WorkflowRule;
}
