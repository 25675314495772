import { NewArtifact } from '@shared/types/artifact.types';
import { CustomDateSettings, DateDiffsEnum, DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { WorkflowConditionType } from '@workflows/types';

export class RuleCondition {
  source = new RuleConditionValue();
  destination = new RuleConditionValue();
  operationType: WorkflowConditionType = WorkflowConditionType.CONTAINS;
  extra?: RuleConditionExtra;

  constructor(condition?: Partial<RuleCondition>) {
    if (condition) {
      Object.assign(this, condition);
      condition.extra && (this.extra = new RuleConditionExtra(condition.extra));
    }
  }

  toServer(): any {
    return {
      source: this.source.toServer(),
      destination: this.destination.toServer(),
      operationType: this.operationType,
      extra: this.extra,
    };
  }
}

export class RuleConditionValue {
  isDynamic = false;
  value: any = '';
  private _manual = false;

  constructor(conditionValue?: Partial<RuleConditionValue>) {
    conditionValue && Object.assign(this, conditionValue);
  }

  get manual(): boolean {
    return this._manual;
  }

  set manual(newManual: boolean) {
    if (newManual === true) this.isDynamic = true;
    this._manual = newManual;
  }

  toServer(): any {
    return {
      value: this.value,
      isDynamic: this.isDynamic,
    };
  }
}

export class RuleConditionExtra {
  type = RuleConditionExtraTypeEnum.DATE;
  settings: RuleConditionDateSettings = new RuleConditionDateSettings();

  constructor(dto?: Partial<RuleConditionExtra>) {
    if (dto) {
      Object.assign(this, dto);
      dto.settings && (this.settings = new RuleConditionDateSettings(dto.settings));
    }
  }
}

export enum RuleConditionExtraTypeEnum {
  DATE = 'DATE',
}

export class RuleConditionDateSettings extends CustomDateSettings {
  operationType: DateFilterEnum | DateRangeFilterEnum | DateDiffsEnum = DateFilterEnum.dateIs;
  artifact?: NewArtifact;
  diffOperationType: NumericComparisonOperationEnum = NumericComparisonOperationEnum.EQUALS;
  diffInDays: number;
  getAttributeValueForArtifactFn?: (artifact: NewArtifact, attributeId: string) => any; // dynamic value from list-widget
  getAttributeValueFn?: (attributeId: string) => any; // dynamic value from artifact-widget

  constructor(dto?: Partial<RuleConditionDateSettings>) {
    super();
    if (dto) {
      Object.assign(this, dto);
      dto.start && (this.start = new Date(dto.start));
      dto.end && (this.end = new Date(dto.end));
    }
  }
}

export type ConditionDateFilterType = DateFilterEnum | DateRangeFilterEnum | DateDiffsEnum;

export enum NumericComparisonOperationEnum {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_OR_EQUALS_THAN = 'GREATER_OR_EQUALS_THAN',
  LOWER_THAN = 'LOWER_THAN',
  LOWER_OR_EQUALS_THAN = 'LOWER_OR_EQUALS_THAN',
}
