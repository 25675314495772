/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ArtifactCountResponseDto } from '../../models/artifact-count-response-dto';

export interface ArtifactControllerCount$Params {
  filter?: string;
  attributeIds?: string;
  subOperations?: Array<'sum' | 'min' | 'max'>;
  linkFilter?: string;
  linkTypeId?: string;

}

export function artifactControllerCount(http: HttpClient, rootUrl: string, params?: ArtifactControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactCountResponseDto>> {
  const rb = new RequestBuilder(rootUrl, artifactControllerCount.PATH, 'get');
  if (params) {
    rb.query('filter', params.filter, {});
    rb.query('attributeIds', params.attributeIds, {});
    rb.query('subOperations', params.subOperations, {});
    rb.query('linkFilter', params.linkFilter, {});
    rb.query('linkTypeId', params.linkTypeId, {});
  }
  rb.header('ngsw-bypass', 'true', {});

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ArtifactCountResponseDto>;
    })
  );
}

artifactControllerCount.PATH = '/api/tenant/artifact/count';
