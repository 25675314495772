import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GlobalConstants } from '@shared/constants/global.constants';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { SelectOption } from '@shared/types/shared.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { FilterTypeDetectionService } from '@widgets/shared/components/artifact-filters/services/filter-type-detection.service';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { CONTAINER_NAME, TEXT_CONTAINER } from '@widgets/shared/constants/widget.constants';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-artifact-filters',
  templateUrl: './artifact-filters.component.html',
  styleUrls: ['./artifact-filters.component.scss'],
})
export class ArtifactFiltersComponent implements AfterViewInit {
  @Input() filtersHolder: { [filtersKey: string]: ArtifactFilter[] };
  @Input() filtersKey: string;
  @Input() attributeAndLinkOptions: SelectOption<string, NewAttribute | NewClientAttribute | DirectionalLinkType>[];
  @Input() dataTypes: NewDataType[];
  @Input() preselectAttribute: NewAttribute | NewClientAttribute | DirectionalLinkType;

  @Output() filtersChange: EventEmitter<void> = new EventEmitter<void>();

  tabsActiveState: boolean[] = [];
  containerName = CONTAINER_NAME;

  constructor(
    public readonly filterTypeDetectionService: FilterTypeDetectionService,
    private readonly filterService: ArtifactFiltersService,
    private readonly cache: NewCacheService,
  ) {}

  get filters(): ArtifactFilter[] {
    return this.filtersHolder[this.filtersKey] || [];
  }

  set filters(filters: ArtifactFilter[]) {
    this.filtersHolder[this.filtersKey] = filters;
  }

  ngAfterViewInit(): void {
    if (this.preselectAttribute) {
      const filter = new ArtifactFilter();
      this.filters = [filter];
      // @ts-ignore
      this.onFilterAttributeChange(this.preselectAttribute, this.filters[0], 0);
    }

    this.setFocus();
  }

  setFocus(): void {
    setTimeout(() => {
      const cont: HTMLElement = document.getElementsByClassName(this.containerName)[0] as HTMLElement;
      const el: HTMLElement = cont && (cont.getElementsByClassName(TEXT_CONTAINER)[0] as HTMLElement);
      el && el.focus();
    }, 400);
  }

  addFilter(): void {
    this.filters = [...this.filters, ArtifactFilter.initial()];
  }

  removeFilter(removedFilter: ArtifactFilter): void {
    this.filters = this.filters.filter((filter: ArtifactFilter) => filter !== removedFilter);
    this.filtersChange.emit();
  }

  onFilterAttributeChange(newAttributeOrLinkType: NewAttribute | NewClientAttribute | DirectionalLinkType, updatedFilter: ArtifactFilter, index: number): void {
    this.filterService.updateFilter(updatedFilter, newAttributeOrLinkType, this.dataTypes);
    this.filters = this.filters.map((filter: ArtifactFilter) => {
      return filter === updatedFilter ? cloneDeep(filter) : filter;
    });
    this.tabsActiveState = this.tabsActiveState.map((state: boolean, stateIndex: number) => stateIndex === index);

    this.filterTypeDetectionService.isLink(updatedFilter) && this.filtersChange.emit();
    const attrId = this.cache.data.attributes.get(GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId))?.id;
    newAttributeOrLinkType.id === attrId && this.setFocus();
  }

  onFilterChange(): void {
    this.filtersChange.emit();
  }
}
