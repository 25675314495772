import { Injectable } from '@angular/core';
import { WorkflowTriggerEvent } from '@workflows/types';
import { filter, tap } from 'rxjs';
import { WorkflowRule } from '../types/rule.types';
import { RuleActionExecutorService } from './rule-action-executor.service';
import { RuleConditionsCheckerService } from './rule-conditions-checker.service';
import { RuleDataHolderService } from './rule-data-holder.service';
import { RuleTriggerEventHandlerService } from './rule-trigger-event-handler.service';

@Injectable({ providedIn: 'root' })
export class RuleEngineService {
  private engineEnabled = false;

  constructor(
    private readonly ruleConditionsChecker: RuleConditionsCheckerService,
    private readonly ruleActionExecutor: RuleActionExecutorService,
    private readonly ruleTriggerEventHandler: RuleTriggerEventHandlerService,
    private readonly ruleDataHolder: RuleDataHolderService,
  ) {
    this.ruleTriggerEventHandler.ruleTriggerEvent$
      .pipe(
        filter(() => this.engineEnabled /*&& this.ruleDataHolder.hasRuleForPageAndTrigger(triggerEvent.pageId, triggerEvent)*/),
        tap(triggerEvent => {
          const affectedRules = this.ruleDataHolder.getRulesForPageAndTrigger(triggerEvent.pageId, triggerEvent) || [];
          if (triggerEvent.widgetId) {
            const widgetRules = this.ruleDataHolder.getRulesForWidgetAndTrigger(triggerEvent.widgetId, triggerEvent) || [];
            affectedRules.push(...widgetRules);
          }
          if (affectedRules) {
            this.executeRules(affectedRules, triggerEvent);
          }
        }),
      )
      .subscribe();
  }

  private executeRules(rules: WorkflowRule[], triggerEvent?: WorkflowTriggerEvent) {
    rules
      .filter(rule => rule.active)
      .forEach(rule => {
        const conditionsMet = !rule.condition || this.ruleConditionsChecker.areConditionsMet(rule.condition, triggerEvent);
        if (conditionsMet) {
          this.ruleActionExecutor.executeActions(rule, triggerEvent);
        }
      });
  }

  enableRuleEngine(enable: boolean) {
    this.engineEnabled = enable;
  }
}
