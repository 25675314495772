import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns user initials.
 */
@Pipe({ name: 'toUserInitials' })
export class ToUserInitialsPipe implements PipeTransform {
  transform(userName: string, userEmail: string): string {
    if (!userName && !userEmail) return '';

    const rawSource = userName ?? userEmail;
    const source = rawSource.split('@')[0] as string;
    const separator = source?.includes(' ') ? ' ' : '.';
    const result = source.includes(separator)
      ? source
          .split(separator)
          .map(part => part[0])
          .join('')
      : source;
    return result.substring(0, 2).toUpperCase();
  }
}
