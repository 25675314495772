<div class="main-rules-container full-height-container">
  <div class="rule-list-and-operations" [class.is-bulk-operation-active]="isAnyRuleSelected()">
    <div class="rule-list" [class.is-bulk-operation-active]="isAnyRuleSelected()">
      <div class="add-rule-element clickable" [class.active]="highlightedRule && highlightedRule === rule"
         (click)="addNewWorkflowRule()">
        <div>
          <i class="bi bi-plus-circle"></i>
          {{ 'WORKFLOWS.COMMON.ADD_NEW_RULE' | translate }}
        </div>
      </div>
      <p-tabView [activeIndex]="activeIndex" styleClass="workflow-tab-view" (onChange)="onTabChange($event.index)">
        <p-tabPanel header="{{ 'WORKFLOWS.COMMON.PAGE_RULES' | translate }}">
          <div class="rule-list-container">
            <div class="rules-title-header">
              <ng-container [ngTemplateOutlet]="ruleHeaders"></ng-container>
            </div>
            @if (pageRules?.length) {
              <div
                *ngFor="let rule of pageRules; trackBy: trackByRuleFn"
                class="clickable single-rule-container single-rule"
                [class.selected]="highlightedRule?.id === rule.id"
                (click)="highlightRule(rule)"
              >
                <span>
                  <p-checkbox [(ngModel)]="rule.selected" (click)="onSelectRuleCBClick($event)" binary="true"></p-checkbox>
                </span>
                <span [class.inactive]="!rule.active">
                  <div>{{ rule.name }}</div>
                </span>
                <span [class.inactive]="!rule.active">
                    {{ rule | getRuleDefaultArtifactType : artifactTypes }}
                </span>
                <span [pTooltip]="'WORKFLOWS.COMMON.PAGE_RULE_OWNER' | translate"
                      [class.visibility-hidden]="!(rule | isWorkflowOwnedByPage : pageId)"
                      class="owner-rule-mark self-center pi pi-crown"
                ></span>
                <span [pTooltip]="'WORKFLOWS.COMMON.GLOBAL_RULE' | translate"
                      [class.visibility-hidden]="!(rule | isGlobalWorkflowUsedByPage : pageId)"
                      class="global-rule-mark">
                    G
                </span>
                <span [pTooltip]="'WORKFLOWS.COMMON.RULE_PRIORITY' | translate" class="priority-rule-mark"> {{ rule.priority }}</span>
              </div>
            }

            @if (!pageRules?.length && !highlightedRule) {
              <div class="no-rules-placeholder">{{ 'WORKFLOWS.COMMON.NO_PAGE_RULES' | translate }}</div>
            }

            @if (highlightedRule && highlightedRule === rule) {
              <div class="clickable single-rule-container single-rule" [class.selected]="highlightedRule === rule">
                <span></span>
                <span>
                  <i class="bi bi-plus-info"></i>
                  @if (rule.name) {
                    {{ rule.name }}
                  } @else {
                    [ <span class="new-rule-placeholder"> {{ 'WORKFLOWS.COMMON.ADDING_NEW_RULE' | translate }}
                    . . . </span> ]
                  }
                </span>
              </div>
            }
          </div>
        </p-tabPanel>

        <p-tabPanel header="{{ 'WORKFLOWS.COMMON.GLOBAL_RULES' | translate }}">
          <div class="rule-list-container">
            <div class="rules-title-header rules-title-header-global">
              <ng-container [ngTemplateOutlet]="ruleHeaders"></ng-container>
            </div>
            @if (globalRules?.length) {
              <div
                *ngFor="let rule of globalRules; trackBy: trackByRuleFn"
                class="clickable single-rule-container single-rule-container-global single-rule"
                [class.selected]="highlightedRule?.id === rule.id"
                [class.inactive]="!rule.active"
                (click)="highlightRule(rule)"
              >
                <span>
                  <p-checkbox [(ngModel)]="rule.selected" (click)="onSelectRuleCBClick($event)" binary="true"></p-checkbox>
                </span>
                <span [class.inactive]="!rule.active">
                  {{ rule.name }}
                </span>
                <span [class.inactive]="!rule.active">
                  {{ rule | getRuleDefaultArtifactType : artifactTypes }}
                </span>
              </div>
            } @else {
              <div class="no-rules-placeholder">{{ 'WORKFLOWS.COMMON.NO_GLOBAL_RULES' | translate }}</div>
            }
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>

    @if (isAnyRuleSelected()) {
      <div class="rule-bulk-operations">
        @if (activeIndex === 1) {
          <p-button [label]="'Assign selected' | translate" severity="success" (click)="doUseSelectedRules(); deselectAllRules()"></p-button>
        } @else {
          <p-button [label]="'Unassign selected' | translate" severity="warning" (click)="doUnuseSelectedRules(); deselectAllRules()"></p-button>
          <p-button [label]="'Activate selected' | translate" severity="success" (click)="doActivateSelectedRules(); deselectAllRules()"></p-button>
          <p-button [label]="'Deactivate selected' | translate" severity="danger" (click)="doDeactivateSelectedRules(); deselectAllRules()"></p-button>
          <span class="cursor-pointer pi pi-info-circle self-center" [pTooltip]="'Actions will only be executed on eligible rules' | translate"></span>
        }
      </div>
    }
  </div>

  <div class="rule-detail-and-operations">
    <app-workflows-rule
      *ngIf="highlightedRule"
      [rule]="highlightedRule"
      [isNew]="rule === highlightedRule"
      [artifactTypes]="artifactTypes?.list"
      [attributes]="attributes"
      [dataTypes]="dataTypes"
      [linkTypes]="linkTypes"
      [applications]="applications"
      [pageWidgets]="pageWidgets"
      [editable]="highlightedRule.ownerId === pageId"
      [ruleAdministrationType]="RuleAdministrationType.PAGE"
      [scrollable]="true"
      (onSaveRule)="doSaveRule($event)"
      (onUseRule)="doUseRule($event)"
      (onDeleteRule)="doDeleteRule($event)"
    ></app-workflows-rule>
    <div class="rule-bottom-action-bar">
      <!-- <ng-container *ngIf="editable && hasValidTriggers()"> -->
      <ng-container *ngIf="activeIndex === 0 && highlightedRule?.ownerId === pageId">
        <button
          *ngIf="rule === highlightedRule"
          pButton
          label="{{ 'WORKFLOWS.COMMON.SAVE_RULE' | translate }}"
          class="p-button p-button-success workflows-action-btn"
          (click)="doSaveRule(highlightedRule)"
        ></button>
        <button
          *ngIf="rule !== highlightedRule"
          pButton
          label="{{ 'WORKFLOWS.COMMON.UPDATE_RULE' | translate }}"
          class="p-button p-button-warning workflows-action-btn"
          (click)="doSaveRule(highlightedRule)"
        ></button>
        @if (highlightedRule?.id) {
          <button
            pButton
            label="{{ 'WORKFLOWS.COMMON.DELETE_RULE' | translate }}"
            class="p-button p-button-danger workflows-action-btn"
            (click)="doDeleteRule(highlightedRule)"
          ></button>
        }
      </ng-container>
      <button
        *ngIf="activeIndex === 1 && highlightedRule"
        pButton
        label="{{ 'WORKFLOWS.COMMON.USE_RULE' | translate }}"
        class="p-button p-button-success workflows-action-btn"
        (click)="doUseRule(highlightedRule)"
      ></button>
      <button
        *ngIf="highlightedRule?.id && highlightedRule | canRuleBeUnassigned : pageId"
        pButton
        label="{{ 'WORKFLOWS.COMMON.UNUSE_RULE' | translate }}"
        class="p-button p-button-danger workflows-action-btn"
        (click)="doUnuseRule(highlightedRule)"
      ></button>
    </div>
  </div>
</div>

<!-- FILTER DIALOGS -->
<p-overlayPanel #nameFilterOp [style]="{ 'max-width': '250px', width: '250px' }">
  <input [autofocus]="true" pInputText [(ngModel)]="nameFilterValue" class="mb-4 w-100">
  <ng-container
    [ngTemplateOutlet]="filterButtons"
    [ngTemplateOutletContext]="{ clear: clearNameFilter.bind(this), filter: filterByName.bind(this), dialog: nameFilterOp }"></ng-container>
</p-overlayPanel>

<p-overlayPanel #artifactTypeFilterOp [style]="{ 'max-width': '250px', width: '250px' }">
  <span class="dynamic-label">
    <p-multiSelect
      [(ngModel)]="artifactTypeFilterValue"
      [options]="artifactTypes.list | transformArrayToSelectOptions : 'name' : 'id'"
      styleClass="artifact-type-filter-selection"
      (onChange)="filterByArtifactType()">
    </p-multiSelect>
  </span>
  <ng-container
    [ngTemplateOutlet]="filterButtons"
    [ngTemplateOutletContext]="{ clear: clearArtifactTypeFilter.bind(this), filter: filterByArtifactType.bind(this), dialog: artifactTypeFilterOp }">
  </ng-container>
</p-overlayPanel>

<ng-template #filterButtons let-clear="clear" let-filter="filter" let-dialog="dialog">
  <div class="dialog-buttons">
    <p-button [label]="'Clear' | translate" severity="primary" outlined="true"
              (click)="clear(); dialog.hide();"></p-button>
    <p-button [label]="'Apply' | translate" severity="primary" (click)="filter(); dialog.hide()"></p-button>
  </div>
</ng-template>

<ng-template #ruleHeaders>
  <div>
    <p-checkbox [(ngModel)]="areAllRulesSelected" (onChange)="onSelectAllRules()" binary="true"></p-checkbox>
  </div>
  <div>
    <span> Name </span>
    <span class="pi pi-filter clickable" [class.active]="nameFilterActive" (click)="nameFilterOp.toggle($event)"></span>
  </div>
  <div>
    <span> Artifact type </span>
    <span class="pi pi-filter clickable" [class.active]="artifactTypeFilterActive"
          (click)="artifactTypeFilterOp.toggle($event)"></span>
  </div>
</ng-template>
