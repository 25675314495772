import { Pipe, PipeTransform } from '@angular/core';
import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { ImageFilterStylesPipe } from '@shared/pipes/internal-image/image-filter-styles.pipe';

@Pipe({
  name: 'imageFilterStylesAdmin',
  pure: false,
})
export class ImageFilterStylesAdmin implements PipeTransform {
  constructor(private imageFilterStylesPipe: ImageFilterStylesPipe) {}

  transform(styles: Partial<StylesDto>): { [key: string]: string | number | number[] } {
    return this.imageFilterStylesPipe.transform(styles);
  }
}
