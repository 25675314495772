import { Injectable } from '@angular/core';
import { NewArtifact } from '@shared/types/artifact.types';
import { WorkflowConditionFactory } from '@workflows/services/factory/workflow-condition-factory';
import { RuleConditionsUtilService } from '@workflows/services/rule-conditions-util.service';
import { RuleConditionOperatorType } from '@workflows/types';
import { RuleCondition } from '@workflows/types/conditions/rule-condition';
import { RuleConditionGroup } from '@workflows/types/conditions/rule-condition-group';
import { WorkflowTriggerEvent } from '../types';

@Injectable({ providedIn: 'root' })
export class RuleConditionsCheckerService {
  constructor(
    private readonly conditionFactory: WorkflowConditionFactory,
    private readonly conditionsUtil: RuleConditionsUtilService,
  ) {}

  /**
   * Function to check if all conditions in a group are met.
   * @param group condition group
   * @param ruleTriggerEvent rule trigger event
   * @param artifact artifact, if present, the condition source value will be extracted from the artifact
   * @returns true if all conditions are met, false otherwise
   */
  areConditionsMet(group: RuleConditionGroup, ruleTriggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): boolean {
    return group.operator === RuleConditionOperatorType.AND
      ? this.allAreMet(group, ruleTriggerEvent, artifact)
      : this.someAreMet(group, ruleTriggerEvent, artifact);
  }

  /**
   * Function to check if all conditions in a group are met.
   * @param group condition group
   * @param ruleTriggerEvent rule trigger event
   * @param artifact artifact, if present, the condition source value will be extracted from the artifact
   * @returns true if all conditions are met, false otherwise
   */
  private allAreMet(group: RuleConditionGroup, ruleTriggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): boolean {
    const conditionsMet = group.conditions.every(condition => this.isConditionMet(condition, ruleTriggerEvent, artifact));
    const nestedConditionsMet = group.groups ? group.groups.every(group => this.areConditionsMet(group, ruleTriggerEvent, artifact)) : true;
    return conditionsMet && nestedConditionsMet;
  }

  /**
   * Function to check if some conditions in a group are met.
   * @param group condition group
   * @param ruleTriggerEvent rule trigger event
   * @param artifact artifact, if present, the condition source value will be extracted from the artifact
   * @returns true if some conditions are met, false otherwise
   */
  private someAreMet(group: RuleConditionGroup, ruleTriggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): boolean {
    const conditionsMet = group.conditions.some(condition => this.isConditionMet(condition, ruleTriggerEvent, artifact));
    const nestedConditionsMet = group.groups ? group.groups.some(group => this.areConditionsMet(group, ruleTriggerEvent, artifact)) : true;
    return conditionsMet || nestedConditionsMet;
  }

  /**
   * Function to check if a condition is met.
   * @param condition condition
   * @param ruleTriggerEvent rule trigger event
   * @param artifact artifact, if present, the condition source value will be extracted from the artifact
   * @returns true if the condition is met, false otherwise
   */
  private isConditionMet(condition: RuleCondition, ruleTriggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): boolean {
    const workflowCondition = this.conditionFactory.getWorkflowCondition(condition.operationType);
    return workflowCondition.isConditionMet(condition, ruleTriggerEvent, artifact);
  }
}
