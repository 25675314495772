import { WorkflowActionDto } from '@api/models';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { AbstractActionParams } from '@workflows/shared/types/rule-action-params.types';
import { WorkflowOptions } from '@workflows/shared/types/rule-options.types';
import { WorkflowAction, WorkflowActionType, WorkflowActionValueType, WorkflowTriggerEvent } from '@workflows/types';
import { RuleConditionGroup } from '@workflows/types/conditions/rule-condition-group';

export abstract class AbstractWorkflowAction<T extends WorkflowActionDto['actionSettings']> implements WorkflowAction {
  id: string;
  type: WorkflowActionType;
  actionSettings: T;
  conditionConverter: RuleConditionValueConverterService;
  alias?: string;
  condition?: RuleConditionGroup;

  protected options?: WorkflowOptions;
  protected value: WorkflowActionValueType | null = null;
  protected supportedAttributeDataTypes: BaseDataType[] | null = null;
  protected supportedAttributeDataKinds: DataTypeKind[] | null = null;

  constructor(params: AbstractActionParams<T>) {
    const { type, conditionConverter, dto, actionSettingDto, cache } = params;

    this.type = type || this.type;
    this.alias = dto?.alias;
    this.actionSettings = new actionSettingDto(dto?.actionSettings);
    this.conditionConverter = conditionConverter;
    this.options = cache ? new WorkflowOptions(cache) : undefined;

    dto && this.fromDto(dto);
  }

  abstract canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean;

  abstract execute(triggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): void;

  abstract isValid(): boolean;

  isCalculatedValueType(): boolean {
    return false;
  }

  getCalculatedValue(): number | null {
    return null;
  }

  isArtifactTypeRequired(): boolean {
    return false;
  }

  isAttributeRequired(): boolean {
    return false;
  }

  getSupportedDataTypes(): BaseDataType[] | null {
    return this.supportedAttributeDataTypes;
  }

  getSupportedDataKinds(): DataTypeKind[] | null {
    return this.supportedAttributeDataKinds;
  }

  toServer(): WorkflowActionDto {
    return {
      type: this.type,
      alias: this.alias,
      actionSettings: this.actionSettings,
      condition: this.condition?.toServer(this.conditionConverter),
    };
  }

  fromDto(dto: WorkflowActionDto): void {
    if (dto) {
      Object.assign(this, dto);
      dto.condition && (this.condition = new RuleConditionGroup(this.conditionConverter, dto.condition));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRuleActivation(pageId: string): void {
    // do nothing
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRuleDeactivation(pageId: string): void {
    // do nothing
  }
}
