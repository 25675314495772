export enum DefaultElvisActions {
  logout = 'logout',
  goToProfile = 'goToProfile',
  removeQueryParamsFromThisWidget = 'removeQueryParamsFromThisWidget',
  removeAllQueryParams = 'removeAllQueryParams',
  customEvent = 'customEvent',
  doNothing = 'Do nothing',
}

export enum MenuWidgetItemActions {
  beWfTrigger = 'beWfTrigger',
}

export enum ArtifactWidgetItemActions {
  save = 'save',
  saveAndNotify = 'saveAndNotify',
  edit = 'edit',
  cancel = 'cancel',
  showOperationHistory = 'showOperationHistory',
  showAccessRights = 'showAccessRights',
  delete = 'delete',
  openMoreActions = 'openMoreActions',
}
