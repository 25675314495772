import { Pipe, PipeTransform } from '@angular/core';
import { IsDateOrDateTime } from '@shared/methods/data-type.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({
  name: 'getDateAttributes',
  standalone: true,
})
export class GetDateAttributesPipe implements PipeTransform {
  transform(attributes: NewAttribute[], dataTypes: ListContainer<NewDataType>): NewAttribute[] {
    return attributes.filter(attribute => {
      const dataType = dataTypes.listMap[attribute.dataTypeId];
      return IsDateOrDateTime(dataType.baseDataType);
    });
  }
}
