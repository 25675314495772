import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamListResponseDto } from '@api/models';
import { TenantTeamService } from '@api/services/tenant-team.service';
import { TranslateService } from '@ngx-translate/core';
import { TeamListModel } from '@private/pages/team-management/team-list/types/team-list.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { ID_LABEL, ID_KEY, NAME_LABEL, NAME_KEY } from '@shared/constants/constants';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { TableColumn } from '@shared/types/table.types';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss'],
})
export class TeamListComponent implements OnInit {
  m = new TeamListModel();
  loadDataMethod: (params?: Record<string, string>, extras?: Record<string, TeamListResponseDto>) => Promise<TeamListResponseDto>;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    private readonly tenantTeamService: TenantTeamService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Teams' }]);
    this.loadDataMethod = async (params?: Record<string, string>) => lastValueFrom(this.tenantTeamService.teamControllerList(params));
    this.m.columns = [new TableColumn(ID_LABEL, ID_KEY), new TableColumn(NAME_LABEL, NAME_KEY)];
  }
}
