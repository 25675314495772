import { Component, Inject } from '@angular/core';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import {
  AbstractAvrQuerySettingsComponent,
  AVR_SETTINGS_INJECTOR,
  AvrSettingsInjectorBody,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/abstract.avr-types-settings.component';
import { QrCodesAvrWidgetSettingsDto } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/qr-codes/types/qr-codes.avr-types-settings.types';
import { QrCodesAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/qr-codes/qr-codes.avr-types-services.service';

@Component({
  selector: 'app-avr-widget-qr-codes-types-settingss',
  templateUrl: './qr-codes.avr-types-settings.component.html',
  styleUrls: ['./qr-codes.avr-types-settings.component.scss'],
})
export class AvrQrCodesTypesSettingsComponent extends AbstractAvrQuerySettingsComponent<AvrTypes.qrCodes, QrCodesAvrTypeService> {
  constructor(@Inject(AVR_SETTINGS_INJECTOR) protected injector: AvrSettingsInjectorBody) {
    super(injector, AvrTypes.qrCodes, QrCodesAvrWidgetSettingsDto);
  }
}
