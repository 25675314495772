<div *ngIf="artifact.attributes[attributeId]?.value" [ngSwitch]="selectedVariant"
     class="at-hyperlink-container full-size-container"
     [ngStyle]="valueStyles | filterRelevantStyles : topContainerRelevantStyleKeys : columnStylesEvent">
  <ng-container *ngSwitchCase="displayVariant.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="displayVariant.REDIRECT_ICON">
    <app-display-hyperlink-icon
      [attr]="artifact.attributes[attributeId]"
      [attribute]="attribute"
      [ownerId]="ownerId"
    ></app-display-hyperlink-icon>
  </ng-container>
</div>

<ng-template #default>
  <span *ngIf="!isMultiple" class="table-hyperlink-item" isSingle>
    <a [href]="artifact.attributes[attributeId].value">{{ artifact.attributes[attributeId].value }}</a>
  </span>
  <div *ngIf="isMultiple" class="at-html-container full-size-container">
    <div
      [innerHTML]="artifact.attributes[attributeId].value | toHtml : dataType.baseDataType | replaceRuntimeVariables : artifact | async | safeHtml"
      [ngStyle]="valueStyles | filterRelevantStyles : topContainerRelevantStyleKeys : columnStylesEvent"
      class="mce-content-body mce-content-readonly"
    ></div>
  </div>
</ng-template>
