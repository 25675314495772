import { Directive } from '@angular/core';
import { SwaggerAvrQueryDto } from '@api/models';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AvrWidgetService } from '@widgets/avr-widget/services/avr-widget.service';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';
import { Observable, map, of, tap } from 'rxjs';

@Directive()
export abstract class AbstractAvrTypeService<K extends Extract<AvrTypes, keyof SwaggerAvrQueryDto>> {
  m: AvrWidgetModel;
  s: AvrWidgetService;

  constructor(public readonly type: K) {}

  abstract getAdditionalQueryParams(): SwaggerAvrQueryDto[K] | null;

  init$(m: AvrWidgetModel, s: AvrWidgetService): Observable<boolean> {
    if (m.settings.dataSource.artifactTypeId === null) return of(true);
    return of({ m, s }).pipe(
      tap(({ m, s }) => {
        this.m = m;
        this.s = s;
      }),
      map(() => true),
    );
  }
}
