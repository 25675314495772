import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AclRecordDto } from '@api/models/acl-record-dto';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { Environment } from '@environments/environment';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ListContainer } from '@shared/types/list-container.types';
import { ClientData } from '@shared/types/local-storage.types';
import { NewTeam } from '@shared/types/team.types';
import { AclComponentService } from '@widgets/shared/components/acl/services/acl.service';
import { AclMemberTypes, AclTypeModel, AclTypes } from '@widgets/shared/components/acl/types/acl.types';

@Component({
  selector: 'app-acl',
  templateUrl: './acl.component.html',
  styleUrls: ['./acl.component.scss'],
  providers: [AclComponentService],
})
export class AclComponent implements OnInit {
  @Input() folderId: string;
  @Input() artifactId: string;
  @Input() attributeId: string;
  @Output() updateAcl = new EventEmitter<null>();

  model: AclTypeModel;
  showUserPicker: boolean;
  memberTypesList: string[];
  memberType: AclMemberTypes = AclMemberTypes.users;

  edit = [false, false, false];
  activeState: boolean[] = [false, false, false];

  nameAttributeId: string = GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId);
  users: ListContainer<ArtifactLinkResponseDto> = new ListContainer<ArtifactLinkResponseDto>();
  teams: ListContainer<NewTeam> = new ListContainer<NewTeam>();

  currentAclType: AclTypes;
  aclTypes = AclTypes;
  aclTypeList: AclTypes[];
  isChanged: boolean;
  setFolderItemsAcl = true;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  userData: ClientData;

  constructor(public readonly s: AclComponentService) {}

  ngOnInit(): void {
    this.s.init(this);
  }

  removeExpiration(groupKey: keyof AclTypeModel, item: string): void {
    ((this.model[groupKey] as AclRecordDto).expirations as any)[item] = null;
    this.isChanged = true;
  }

  toggleEdit(e: MouseEvent, type: AclTypes, index: number): void {
    if (this.activeState[index] || this.edit[index]) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.edit[index] = !this.edit[index];
    this.showUserPicker = false;
    this.currentAclType = type;
  }

  openPickUsers(e: MouseEvent, type: AclTypes, index: number): void {
    if (this.activeState[index]) {
      e.stopPropagation();
      e.preventDefault();
    }
    (!this.showUserPicker || this.currentAclType === type) && (this.showUserPicker = !this.showUserPicker);

    if (this.showUserPicker) {
      this.currentAclType = type;
    }
  }

  onChangeDate(): void {
    this.isChanged = true;
  }
}
