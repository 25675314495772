import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { RuleCondition, RuleConditionValue } from '@workflows/types/conditions/rule-condition';
import { RuleConditionsUtilService, WorkflowBaseCondition, WorkflowConditionType, WorkflowTriggerEvent } from '../../../index';

export abstract class AbstractWorkflowCondition implements WorkflowBaseCondition {
  protected type: WorkflowConditionType;
  protected supportedTypes: BaseDataType[];
  protected supportedKinds: DataTypeKind[];

  private readonly DYNAMIC_VALUE = 'dynamic_value';

  protected constructor(protected readonly conditionsUtil: RuleConditionsUtilService) {}

  abstract isMet(source: any, value?: any): boolean;

  isConditionMet(condition: RuleCondition, ruleTriggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): boolean {
    const { source } = condition;

    let sourceValue = this.getConditionSourceValue(source, ruleTriggerEvent, artifact);
    sourceValue = this.conditionsUtil.formatValue(sourceValue, source.value);

    let value = this.getConditionValue(condition, ruleTriggerEvent, artifact);
    value = this.conditionsUtil.formatValue(value, source.value);

    return this.isMet(sourceValue, value);
  }

  getType(): WorkflowConditionType {
    return this.type;
  }

  getSupportedDataTypes(): BaseDataType[] {
    return this.supportedTypes;
  }

  getSupportedDataKinds(): DataTypeKind[] {
    return this.supportedKinds;
  }

  /**
   * Function to get the condition source value.
   * @param source condition source
   * @param ruleTriggerEvent rule trigger event
   * @param artifact artifact, if present, the condition source value will be extracted from the artifact
   * @returns condition source value
   */
  protected getConditionSourceValue(source: RuleConditionValue, ruleTriggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): any {
    if (artifact && ruleTriggerEvent?.payload?.getAttributeValueForArtifactFn) {
      return ruleTriggerEvent.payload.getAttributeValueForArtifactFn(artifact, source.value);
    }
    if (ruleTriggerEvent?.payload?.getAttributeValueFn) {
      return ruleTriggerEvent.payload.getAttributeValueFn(source.value);
    }
    return null;
  }

  protected getConditionValue(condition: RuleCondition, ruleTriggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): any {
    // dynamic value is not supported currently
    if (condition.destination.isDynamic) {
      return this.DYNAMIC_VALUE;
    }

    return condition.destination.value;
  }
}
