<p-dropdown
  [(ngModel)]="linkedFilter.matchType"
  [options]="options.matchType.list | transformArrayToSelectOptions"
></p-dropdown>
<br>
<ng-container *ngFor="let rule of linkedFilter.ruleTypes; let i = index">
  <p-multiSelect
    (onChange)="onArtifactTypeChange(i)"
    [(ngModel)]="rule.artifactType"
    [options]="artifactTypesList | transformArrayToSelectOptions : NAME_KEY"
    [appendTo]="'body'"
    [placeholder]="'Artifact type' | translate"
    class="mt-2"
  ></p-multiSelect>
  <br>
  <div class="p-2" (keyup)="onPressKey($event)">
    <app-artifact-filters
      *ngIf="rule.artifactType && rule?.attributes?.length"
      [attributeAndLinkOptions]="(rule.attributes | transformArrayToSelectOptions : NAME_KEY)!"
      [dataTypes]="dataTypes"
      [filtersHolder]="rule.filtersHolder!"
      [filtersKey]="'attributesFilter'"
      [preselectAttribute]="preselectAttribute"
    ></app-artifact-filters>
  </div>


  @if (linkedFilter?.ruleTypes.length > 1) {
    <p class="rule rule-rm" (click)="removeRule(i)">Remove rule</p>
  }
</ng-container>

@if (linkedFilter?.ruleTypes?.length < 2) {
  <p class="rule rule-add" (click)="addRule()">+ Add rule</p>
}
