import { SelectOption } from '@shared/types/shared.types';
import { FontStyles, IconStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { ArtifactFilter, ArtifactFilterDto, ArtifactFilterOptions } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { LabelPositionEnum } from '@widgets/shared/types/style.types';
import { ActionItem, ActionItemDto } from '@widgets/menu-widget/types/menu-action-types';

export class NumberWidgetSettings {
  label: string;
  prefix = '';
  postfix = '';
  minDecimalPlaces?: number;
  maxDecimalPlaces?: number;
  fixedCounter: string;
  showRoundLabel = true;
  labelPosition: SelectOption<string, string> = new SelectOption<string, string>(LabelPositionEnum.top);
  iconPosition: SelectOption<string, string> = new SelectOption<string, string>(LabelPositionEnum.left);
  counterStyles = new FontStyles();
  labelStyles = new FontStyles();
  iconStyles = new IconStyles({ order: '0' });
  badgeStyles = new IconStyles({ color: '#ffffff', fontSize: '14px' });
  actions: ActionItem[] = [new ActionItem()];
  filtersForQueryParams: ArtifactFilter[] = [];

  constructor(dto?: NumberWidgetSettingsDto, options?: ArtifactFilterOptions) {
    dto && this.fromDto(dto, options!);
  }

  toServer(): NumberWidgetSettingsDto {
    return new NumberWidgetSettingsDto(this);
  }

  fromDto(dto: NumberWidgetSettingsDto, options: ArtifactFilterOptions): void {
    const {
      label,
      fixedCounter,
      showRoundLabel = true,
      counterStyles,
      labelStyles,
      iconStyles,
      badgeStyles,
      prefix = '',
      postfix = '',
      labelPosition,
      iconPosition,
      minDecimalPlaces,
      maxDecimalPlaces,
      filtersForQueryParams,
      actions,
    } = dto;
    Object.assign(this, {
      label,
      fixedCounter,
      showRoundLabel,
      prefix,
      postfix,
      counterStyles: counterStyles ? counterStyles : new FontStyles(),
      labelStyles: labelStyles ? labelStyles : new FontStyles(),
      iconStyles: iconStyles ? iconStyles : new IconStyles({ order: '0' }),
      badgeStyles: badgeStyles ? badgeStyles : new IconStyles({ color: '#ffffff', fontSize: '14px' }),
    });

    labelPosition && (this.labelPosition = new SelectOption<string, string>(labelPosition));
    iconPosition && (this.iconPosition = new SelectOption<string, string>(iconPosition));
    actions?.length && (this.actions = actions.map(action => new ActionItem(action as ActionItemDto)));
    filtersForQueryParams?.length && (this.filtersForQueryParams = filtersForQueryParams.map((dto: ArtifactFilterDto) => ArtifactFilter.fromDtoAndOptions(dto, options))),

    minDecimalPlaces && (this.minDecimalPlaces = minDecimalPlaces);
    maxDecimalPlaces && (this.maxDecimalPlaces = maxDecimalPlaces);
  }
}

export class NumberWidgetSettingsDto {
  label: string;
  prefix: string;
  postfix: string;
  fixedCounter: string;
  showRoundLabel: boolean;
  labelPosition: string;
  iconPosition: string;
  counterStyles: FontStyles;
  labelStyles: FontStyles;
  iconStyles: IconStyles;
  badgeStyles: IconStyles;
  actions: ActionItemDto[];
  filtersForQueryParams: ArtifactFilterDto[];
  minDecimalPlaces?: number;
  maxDecimalPlaces?: number;

  constructor(settings: NumberWidgetSettings) {
    const { label, counterStyles, labelStyles, iconStyles, badgeStyles, prefix, postfix, fixedCounter, showRoundLabel, minDecimalPlaces, maxDecimalPlaces } = settings;
    Object.assign(this, {
      label,
      counterStyles,
      labelStyles,
      iconStyles,
      badgeStyles,
      prefix,
      postfix,
      fixedCounter,
      showRoundLabel,
    });
    settings.labelPosition && (this.labelPosition = settings.labelPosition.value);
    settings.iconPosition && (this.iconPosition = settings.iconPosition.value);

    settings.actions && (this.actions = settings.actions.map(action => action.toServer()) as ActionItemDto[]);
    settings.filtersForQueryParams && (this.filtersForQueryParams = settings.filtersForQueryParams.map((filter: ArtifactFilter) => filter.toServer()));

    minDecimalPlaces && (this.minDecimalPlaces = minDecimalPlaces);
    maxDecimalPlaces && (this.maxDecimalPlaces = maxDecimalPlaces);
  }
}
