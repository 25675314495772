import { MenuWidgetEventResponse, WidgetEventResponseSettings, WidgetEventResponseSettingsDto } from '../../shared/types/custom-event';

export class MenuWidgetEventResponseSettings extends WidgetEventResponseSettings<MenuWidgetEventResponse> {
  events: MenuWidgetEventResponse[] = [];

  constructor(dto?: MenuWidgetEventResponseSettingsDto) {
    super(dto);
    dto && this.fromDto(dto);
  }

  addEvent(): void {
    this.events.push(new MenuWidgetEventResponse());
  }
}

export interface MenuWidgetEventResponseSettingsDto extends WidgetEventResponseSettingsDto<MenuWidgetEventResponse> {}
