import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AclRecordDto } from '@api/models/acl-record-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ListContainer } from '@shared/types/list-container.types';
import { ClientData } from '@shared/types/local-storage.types';
import { NewTeam } from '@shared/types/team.types';
import { AclMemberTypes } from '@widgets/shared/components/acl/types/acl.types';

@Component({
  selector: 'app-acl-user-picker',
  templateUrl: './acl-user-picker.component.html',
  styleUrls: ['./acl-user-picker.component.scss'],
})
export class AclUserPickerComponent {
  @Input() label: string;
  @Input() memberType: AclMemberTypes = AclMemberTypes.users;
  @Input() showExpiration = false;
  @Input() aclType: AclRecordDto;
  @Input() teams: ListContainer<NewTeam>;

  @Output() onClose = new EventEmitter();
  @Output() onAddMembers = new EventEmitter<void>();

  userIds: string[] = [];
  teamIds: string[] = [];
  expiration?: Date;

  memberTypesList: string[] = Object.values(AclMemberTypes);
  AclMemberTypes = AclMemberTypes;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  userData: ClientData;

  constructor(private readonly cache: NewCacheService) {
    this.userData = (this.cache.user.value as SelfUserResponseDto).clientData as ClientData;
  }

  closePicker(): void {
    this.onClose.emit();
  }

  addMembers(): void {
    if (this.expiration) {
      [...this.userIds, ...this.teamIds].forEach(id => ((this.aclType.expirations as any)[id] = this.expiration));
    }

    this.aclType.userIds = [...new Set([...this.aclType.userIds, ...this.userIds])];
    this.aclType.teamIds = [...new Set([...this.aclType.teamIds, ...this.teamIds])];
    this.onAddMembers.emit();
  }
}
