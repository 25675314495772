import { AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { Debounce } from '@shared/utils/debounce.util';
import { PictureWidgetService } from '@widgets/picture-widget/services/picture-widget.service';
import { PictureFormatEnum, PictureSizeEnum, PictureWidgetModel, PictureWidgetModelDto } from '@widgets/picture-widget/types/picture-widget.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { GetSizeInPixelsPipe } from './pipes/get-size-in-pixels.pipe';

@Component({
  selector: 'app-picture-widget',
  templateUrl: './picture-widget.component.html',
  styleUrls: ['./picture-widget.component.scss'],
  providers: [PictureWidgetService],
})
export class PictureWidgetComponent extends WidgetsCoreComponent implements OnInit, AfterViewInit {
  @ViewChildren('imageContainer') imageContainers: QueryList<ElementRef>;
  @ViewChildren('imageInContainer') imagesInContainer: QueryList<ElementRef>;
  m: PictureWidgetModel;

  ID_VALUE = ID_KEY;
  NAME_KEY = NAME_KEY;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<PictureWidgetModel>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    public readonly s: PictureWidgetService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private sizeInPixelsPipe: GetSizeInPixelsPipe,
    private sanitizer: DomSanitizer,
  ) {
    super(route, router, announcement, elRef);
  }

  get isCircle(): boolean {
    return this.m.settings.format === PictureFormatEnum.circle;
  }

  ngOnInit(): void {
    this.s.init(this, this.isLayoutMode && this.widget?.value?.model ? (this.widget.value.model as any as PictureWidgetModelDto) : null);
    this.m.showBorderRadius = this.m.settings.format === PictureFormatEnum.rectangle;
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.m.initialized = true));
  }

  onOpenInLightboxChange(): void {
    if (this.m.settings.openInLightbox) {
      this.m.settings.pictureLinkOptions.enabled = false;
    } else {
      this.m.settings.pictureOverlay.enabled = false;
    }
  }

  onImageClick(): void {
    const { openSidebar, openInLightbox, pictureLinkOptions } = this.m.settings;
    const { enabled, openInNewTab, url, page, external } = pictureLinkOptions;

    if (openSidebar && this.m.settings.sidebarId) {
      this.runtimeStateNotificationService.events$.next(new RuntimeStateNotification(RuntimeStateNotificationEnum.openSidebar, this.m.settings.sidebarId));
    }

    if (openInLightbox) {
      this.m.lightboxVisible = true;
      return;
    }

    if (enabled) {
      if (external && url) {
        window.open(url, openInNewTab ? '_blank' : '_self');
        return;
      }

      if (page) {
        const alias = this.m.options.pageOptions.find(option => option.value === page.value)?.meta.alias;
        const url = '/page/' + page;

        if (openInNewTab) {
          window.open(alias || url, '_blank');
        } else {
          this.router.navigate([alias || url]);
        }
      }
    }
  }

  onPictureFormatChange(): void {
    this.m.showBorderRadius = this.m.settings.format === PictureFormatEnum.rectangle;
    if (!this.m.showBorderRadius) {
      this.m.settings.borderRadius = 0;
    }

    this.m.settings.format === PictureFormatEnum.circle && (this.m.settings.pictureSize = PictureSizeEnum.cover);
    this.m.settings.format === PictureFormatEnum.ellipse && (this.m.settings.pictureSize = PictureSizeEnum.cover);

    console.log('onPictureFormatChange => ');
    this.adaptImageSize(50);
  }

  onImageUrlChange(value: string) {
    if (this.m.settings.useExternalLink) {
      this.m.settings.url = value ? value : 'assets/images/default-avatar.jpg';
    } else this.m.fileObjectUrl = value;
  }

  onUseExternalLink(value: boolean) {
    this.m.settings.useExternalLink = value;
  }

  onImageFileIdChange(value: string) {
    this.m.settings.fileId = value;
  }

  adaptImageSize(curImgSize: number) {
    const setupHeight = this.sizeInPixelsPipe.transform(this.m.settings.height);
    const height = curImgSize < setupHeight ? curImgSize : setupHeight;

    this.imageContainers.toArray().forEach(elem => this.applySizeToElement(elem, height));
    this.imagesInContainer.toArray().forEach(elem => this.applySizeToElement(elem, height));
  }

  applySizeToElement(element: ElementRef, height: number): void {
    element.nativeElement.style.height = height + 'px';
    if (this.isCircle) element.nativeElement.style.width = height + 'px';
  }

  @Debounce(200)
  onContainerResize(event: ResizeObserverEntry) {
    const imgSize = event.target.clientWidth;
    this.adaptImageSize(imgSize);
  }

  getStylesBackground() {
    return (
      this.m.settings.backgroundColorContainer +
      ' url(' +
      this.m.settings.url +
      ') ' +
      this.m.settings.picturePosition +
      '/' +
      this.m.settings.pictureSize +
      this.m.settings.repeat
    );
  }
}
