import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DisplayAtControlService, DisplayAtUtilService } from '@shared/components/common-display-at';
import { SharedMethods } from '@shared/methods/shared.methods';
import { TemplateService } from '@shared/services/page-management/template.service';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { CUSTOM_VARIANT_KEY, DisplayAtCustomVariantMetadata, DisplayAtDropdownItem, DisplayAtSelectionTypeEnum } from '@shared/types/display-at-types';
import { FilterType, NewTableColumn, SortEnumColumnBy, TableColumnFormat, TableColumnFormatSettings } from '@shared/types/table.types';
import { ArtifactListWidgetArtifactService } from '@widgets/shared/components/artifact-list-table/services/artifact-list-widget-artifact.service';
import { ListWidgetSelected } from '@widgets/shared/components/artifact-list-table/types/list-widget-selected.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { ConditionalFormattingDialogEvent, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { RuleDataHolderService } from '@workflows/services';
import { WorkflowRule } from '@workflows/types';
import { OverlayPanel } from 'primeng/overlaypanel';
import { from, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  TableColumnContextChangeEvent,
  TableColumnContextChangeType,
  TableColumnControlContext,
} from '../../services/table-column-control/table-column-context-types';
import { TableColumnControlService } from '../../services/table-column-control/table-column-control.service';

@Component({
  selector: 'app-alw-table-header-menu',
  templateUrl: './artifact-list-table-header-menu.component.html',
  styleUrls: ['artifact-list-table-header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtifactListWidgetTableHeaderMenuComponent implements OnInit, OnDestroy {
  @Input({ required: true }) applicationId: string;
  @Input() tableUniqueId: string;
  @Input() selected: ListWidgetSelected;
  @Input() widgetId?: string;

  @ViewChild('menuOp', { static: true }) menuOverlayPanel: OverlayPanel;
  @ViewChild('accordion', { read: ElementRef }) menuOverlayPanelAccordion: ElementRef;
  @ViewChild('headerFontStyleOp', { static: true }) headerFontStyleOp: OverlayPanel;
  @ViewChild('headerPaddingFormOp', { static: true }) headerPaddingFormOp: OverlayPanel;
  @ViewChild('headerAlignmentFormOp', { static: true }) headerAlignmentFormOp: OverlayPanel;

  styleTypes = ['Standard'];
  displayAtDropdownItems: DisplayAtDropdownItem[];
  displayAtEditDropdownItems: DisplayAtDropdownItem[];
  displayAtSortEnumItems: DisplayAtDropdownItem[] = Object.keys(SortEnumColumnBy).map(key => ({
    label: SortEnumColumnBy[key as keyof typeof SortEnumColumnBy],
    code: SortEnumColumnBy[key as keyof typeof SortEnumColumnBy],
  }));
  /** selected display variant */
  selectedItem?: DisplayAtDropdownItem;
  /** selected sort by for enum */
  selectedSortByItem?: DisplayAtDropdownItem;
  /** selected edit variant */
  selectedEditItem?: any;
  columnFormat: TableColumnFormat;
  contentColumnFormat: TableColumnFormat;
  selectedVariant?: string;
  attributeSettings: AttributeFormatSettings;
  isInPageBuilder: boolean;
  protected column: NewTableColumn;
  private initSubscription: Subscription[];
  formattingRules: WorkflowRule[] = [];

  constructor(
    private readonly tableColumnControlService: TableColumnControlService,
    private readonly displayAtUtilService: DisplayAtUtilService,
    private readonly displayAtControlService: DisplayAtControlService,
    private readonly templateService: TemplateService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly artifactListWidgetArtifactService: ArtifactListWidgetArtifactService,
    private readonly ruleDataHolder: RuleDataHolderService,
  ) {
    this.isInPageBuilder = SharedMethods.isInPageBuilder();
  }

  get showStyling(): boolean {
    if (this.column.meta.filterKey === NonAttributeKeys.ARTIFACT_TYPE_ID) return true;

    const filterType = this.column.meta?.filterType;
    const { text, numeric, counter, folder, datetime, date } = FilterType;
    return filterType === text || filterType === numeric || filterType === counter || filterType === folder || filterType === datetime || filterType === date;
  }

  ngOnInit(): void {
    this.initSubscription = [
      this.tableColumnControlService.openMenu$.subscribe(tableColumnControlContext => this.doOpenMenuOverlay(tableColumnControlContext)),
    ];
  }

  ngOnDestroy(): void {
    this.initSubscription.forEach(subscription => subscription.unsubscribe());
  }

  onSelectedSortByItemChange(option: DisplayAtDropdownItem): void {
    this.selectedSortByItem = option;
    this.column.meta.columnFormatSettings.sortBy = option.code as SortEnumColumnBy;
    this.notifyColumnContextChange(TableColumnContextChangeType.SORT_ORDER);
  }

  onSelectedItemChange(selectedItem: DisplayAtDropdownItem) {
    this.selectedItem = selectedItem;
    if (this.column.meta.displayAtMetadata) this.column.meta.displayAtMetadata.selectedVariantCode = selectedItem.code;

    this.column.meta.columnFormatSettings.columnKey = this.column.key;
    this.column.meta.columnFormatSettings.selectedVariant = selectedItem.code;

    if (selectedItem.code === CUSTOM_VARIANT_KEY) {
      this.onCustomVariantSelection();
      return;
    }

    this.notifyColumnContextChange(TableColumnContextChangeType.VARIANT);
    this.displayAtControlService.doUpdateSelection(this.tableUniqueId, this.column.key, this.selectedItem.code);
  }

  onEditModeChange(selectedEditItem: DisplayAtDropdownItem) {
    this.selectedEditItem = selectedEditItem;
    if (this.column.meta.displayAtMetadata) {
      this.column.meta.displayAtMetadata.selectedEditCode = selectedEditItem.code;
    }
    this.column.meta.columnFormatSettings.columnKey = this.column.key;
    this.column.meta.columnFormatSettings.selectedEditVariant = selectedEditItem.code;
    this.notifyColumnContextChange(TableColumnContextChangeType.VARIANT);
    this.displayAtControlService.doUpdateSelection(this.tableUniqueId, this.column.key, this.selectedEditItem.code, DisplayAtSelectionTypeEnum.EDIT);
  }

  onColumnFormatChange() {
    this.column.meta.columnFormatSettings.columnFormat = this.columnFormat;
    this.column.meta.columnFormatSettings.contentColumnFormat = this.contentColumnFormat;
    this.notifyColumnContextChange(TableColumnContextChangeType.STYLES);
    this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.updateColumnStyles, this.column);
  }

  onHideFilterItemChange(value: boolean) {
    this.column.meta.columnFormatSettings.hideFilter = value;
    this.artifactListWidgetArtifactService.transferHidingFilterOption(value);
    this.notifyColumnContextChange(TableColumnContextChangeType.STYLES);
  }

  onHideSortingItemChange(value: boolean) {
    this.column.meta.columnFormatSettings.hideSorting = value;
    this.artifactListWidgetArtifactService.transferHidingSortingOption(value);
    this.notifyColumnContextChange(TableColumnContextChangeType.STYLES);
  }

  onOpenConditionalFormatting(selectedRule?: WorkflowRule): void {
    if (!this.column.meta.attributeMetadata?.attribute || !this.widgetId) return;

    const artifactTypes = this.selected.artifactTypes.map(selectOption => selectOption.value);
    const event: ConditionalFormattingDialogEvent = {
      artifactTypes,
      attribute: this.column.meta.attributeMetadata?.attribute,
      widgetId: this.widgetId,
      selectedRule,
    };

    this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.openConditionalFormattingDialog, event);
    this.menuOverlayPanel.hide();
  }

  private notifyColumnContextChange(changeType: TableColumnContextChangeType) {
    const columnContextChangeEvent: TableColumnContextChangeEvent = {
      ownerId: this.tableUniqueId,
      changeType,
      tableColumn: this.column,
      tableColumnFormatSettings: this.column.meta.columnFormatSettings as TableColumnFormatSettings,
    };
    this.tableColumnControlService.doNotifyColumnContextChange(columnContextChangeEvent);
  }

  private doOpenMenuOverlay(tableColumnControlContext: TableColumnControlContext) {
    const { event, tableColumn } = tableColumnControlContext;
    const displayAtEnumEditObject = this.displayAtUtilService.getDisplayAtEnumEditObjectForType(tableColumn.meta.displayAtMetadata?.attributeType);
    const displayAtEnumObject: any = {};
    const enumObj = this.displayAtUtilService.getDisplayAtEnumObjectForType(tableColumn.meta.displayAtMetadata?.attributeType);

    Object.keys(enumObj)
      .filter(key => key !== 'DROPDOWN')
      .forEach(key => {
        displayAtEnumObject[key] = enumObj[key];
      });

    this.column = tableColumn;
    this.displayAtDropdownItems = this.displayAtUtilService.getDisplayAtDropdownItems(displayAtEnumObject);
    this.displayAtEditDropdownItems = this.displayAtUtilService.getDisplayAtDropdownItems(displayAtEnumEditObject);
    this.column = tableColumn;
    this.selectedItem = this.displayAtDropdownItems.find(item => item.code === tableColumn.meta.displayAtMetadata?.selectedVariantCode);
    this.selectedSortByItem = this.displayAtSortEnumItems.find(item => item.code === tableColumn.meta.columnFormatSettings?.sortBy);
    this.selectedEditItem = this.displayAtEditDropdownItems.find(item => item.code === tableColumn.meta.displayAtMetadata?.selectedEditCode);
    this.columnFormat = (tableColumn.meta.columnFormatSettings.columnFormat as TableColumnFormat) || new TableColumnFormat();
    this.contentColumnFormat = (tableColumn.meta.columnFormatSettings.contentColumnFormat as TableColumnFormat) || new TableColumnFormat();

    if (this.widgetId && tableColumn.meta.attributeMetadata?.attribute) {
      this.formattingRules = this.ruleDataHolder.getFormattingRulesForWidgetAndAttribute(this.widgetId, tableColumn.meta.attributeMetadata.attribute.id);
    }

    this.menuOverlayPanel.toggle(event);

    setTimeout(() => {
      this.menuOverlayPanelAccordion.nativeElement.classList.remove('hidden');
    }, 175);
  }

  private onCustomVariantSelection() {
    this.column.meta.columnFormatSettings.columnKey = this.column.key;
    this.column.meta.columnFormatSettings.selectedVariant = CUSTOM_VARIANT_KEY;

    if (this.column.meta.displayAtMetadata?.customVariantMetadata?.cardWidgetId) {
      const { cardWidgetId } = this.column.meta.displayAtMetadata?.customVariantMetadata || {};
      this.column.meta.columnFormatSettings.selectedVariantMetadata = this.column.meta.displayAtMetadata?.customVariantMetadata;
      this.displayAtControlService.doUpdateCustomVariantSelection(this.tableUniqueId, this.column.key, CUSTOM_VARIANT_KEY, cardWidgetId);
      this.displayAtControlService.doUpdateSelection(this.tableUniqueId, this.column.key, CUSTOM_VARIANT_KEY);
      this.notifyColumnContextChange(TableColumnContextChangeType.VARIANT);
    }

    from(this.templateService.pickTemplate())
      .pipe(
        take(1),
        filter(result => Array.isArray(result.templates) && result.templates.length > 0),
        map(result => (result.templates && ((result.templates[0] as any).template as any)).widgetId),
      )
      .subscribe(cardWidgetId => {
        const customVariantMetadata: DisplayAtCustomVariantMetadata = {
          cardWidgetId,
        };
        this.column.meta.columnFormatSettings.selectedVariantMetadata = customVariantMetadata;
        this.displayAtControlService.doUpdateCustomVariantSelection(this.tableUniqueId, this.column.key, CUSTOM_VARIANT_KEY, cardWidgetId);
        this.displayAtControlService.doUpdateSelection(this.tableUniqueId, this.column.key, CUSTOM_VARIANT_KEY);
        this.notifyColumnContextChange(TableColumnContextChangeType.VARIANT);
      });
  }
}
