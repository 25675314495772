import { NgForOf, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOption } from '@shared/types/shared.types';
import { ButtonDirective } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { Ripple } from 'primeng/ripple';
import { WidgetActionType, WidgetEventResponseSettings, WidgetEventResponseType } from '../../types/custom-event';

@Component({
  selector: 'app-custom-event-listening-settings',
  standalone: true,
  imports: [ButtonDirective, DropdownModule, FloatLabelModule, FormsModule, InputTextModule, NgForOf, NgIf, Ripple, TranslateModule],
  templateUrl: './custom-event-listening-settings.component.html',
  styleUrl: './custom-event-listening-settings.component.scss',
})
export class CustomEventListeningSettingsComponent {
  @Input() eventResponseSettings: WidgetEventResponseSettings<WidgetEventResponseType>;
  @Input() actionOptions: SelectOption<WidgetActionType, WidgetActionType>[];
}
