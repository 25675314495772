import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactWidgetModelDto } from '@api/models/artifact-widget-model-dto';
import { MenuWidgetModelDto } from '@api/models/menu-widget-model-dto';
import { MenuWidgetTypeResponseDto } from '@api/models/menu-widget-type-response-dto';
import { PageBlockPartWidgetResponseDto } from '@api/models/page-block-part-widget-response-dto';
import { PageCreateRequestDto } from '@api/models/page-create-request-dto';
import { PageUpdateRequestDto } from '@api/models/page-update-request-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TemplateCreateRequestDto } from '@api/models/template-create-request-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import { TemplateUpdateRequestDto } from '@api/models/template-update-request-dto';
import { TenantPageService } from '@api/services/tenant-page.service';
import { TenantTemplateService } from '@api/services/tenant-template.service';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageBuilderGraphicalModel } from '@private/pages/page-management/page-builder-graphical/types/page-builder-graphical-model';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { RowLocation } from '@private/pages/page-management/page-builder-graphical/types/row-location';
import { SectionLocation } from '@private/pages/page-management/page-builder-graphical/types/section-location';
import { PageElementWithHtmlId } from '@private/pages/page-management/page-builder-graphical/types/set-html-id-event';
import { TemplateMeta } from '@private/pages/page-management/page-builder-graphical/types/template-meta';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { Template, TemplateType } from '@shared/components/templates/types/templates.types';
import { CoreService } from '@shared/core/services/core.service';
import { PageHelper } from '@shared/helpers/page-helper';
import { SetMenuItemAliases } from '@shared/methods/menu.methods';
import { TableMethods } from '@shared/methods/table.methods';
import { UrlMethods } from '@shared/methods/url.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { TemplateService } from '@shared/services/page-management/template.service';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { NewSystemUser } from '@shared/types/user.types';
import { WidgetCreateRequestDto, WidgetResponseDto, WidgetUpdateRequestDto } from '@shared/types/widget.types';
import { CustomScriptsUtil } from '@shared/utils/custom-scripts.util';
import { ElvisUtil } from '@shared/utils/elvis.util';
import {
  ArtifactButtonContainersDto,
  ArtifactButtonContainersType,
  ButtonContainerPositionEnum,
} from '@widgets/artifact-widget/types/artifact-widget-button-containers.types';
import { ArtifactWidgetModel, ArtifactWidgetValue } from '@widgets/artifact-widget/types/artifact-widget.types';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetAreaContentItem, IsCardContentItemWidget } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { MenuItem, MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';
import { ReplaceRuntimeVariablesService } from '@widgets/shared/services/replace-runtime-variables.service';
import { SidebarModalWidgetValue } from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { SidebarWidgetValue } from '@widgets/sidebar-widget/types/sidebar-widget.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { cloneDeep } from 'lodash';
import { lastValueFrom, take } from 'rxjs';

@Injectable()
export class PageBuilderGraphicalService extends CoreService<any, PageBuilderGraphicalModel> {
  constructor(
    public readonly tenantPageService: TenantPageService,
    public readonly tenantWidgetService: TenantWidgetService,
    public readonly tenantTemplateService: TenantTemplateService,
    public readonly elvisUtil: ElvisUtil,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
    public readonly pageHelper: PageHelper,
    private readonly replaceRuntimeVariablesService: ReplaceRuntimeVariablesService,
    private readonly widgetService: WidgetService,
    private readonly templateService: TemplateService,
    private readonly cache: NewCacheService,
    private readonly router: Router,
    private readonly dragDropService: PageBuilderGraphicalDragDropService,
    private readonly announcement: AnnouncementService,
    private readonly localStorageService: LocalStorageService,
    private readonly authorizationService: AuthorizationService,
  ) {
    super();
  }

  get params(): string {
    return (this.router.url.split('?')[1] || '')
      .split('&')
      .filter(p => !p.includes('prevPageUrl'))
      .join('&');
  }

  async init(context: any, model: PageBuilderGraphicalModel): Promise<void> {
    super.init(context, model);

    if (this.cache.isLoaded) await this.postInit();
    else
      this.cache.isLoaded$.pipe(take(1)).subscribe({
        next: () => this.postInit(),
      });
  }

  async createPage(): Promise<void> {
    await this.deleteWidgets();
    await this.saveWidgetsFromSections();
    await this.updateSectionTemplates();

    const body: PageCreateRequestDto = {
      name: this.m.page.name.trim() || 'Untitled-' + ElvisUtil.makeHash(15),
      ...(this.m.page.alias ? { alias: this.m.page.alias } : {}),
      pageParameters: this.m.page.pageParameters,
      applicationId: this.applicationSwitcherService.selectedApplication?.id || '',
      sections: this.m.page.sections.map((section: PageSection) => section.requestDto),
      settings: this.m.page.settings,
      seoFields: this.m.page.seoFields,
      scriptModels: this.m.page.scriptModels,
    };

    if (this.m.page.isPublic === Boolean(this.m.page.isPublic)) {
      body.isPublic = this.m.page.isPublic;
    }

    const page = await lastValueFrom(this.tenantPageService.pageControllerCreate({ body }));
    if (page) {
      this.cache.data.pages.setItem(page);
      this.c.canDeactivate = true;
      await this.router.navigate(['admin/page-builder', page.id]);
    }
  }

  async updatePage(): Promise<void> {
    await this.deleteWidgets();
    await this.saveWidgetsFromSections();
    await this.updateSectionTemplates();

    const body: PageUpdateRequestDto = {
      id: this.m.page.id,
      pageParameters: this.m.page.pageParameters,
      name: this.m.page.name.trim(),
      sections: this.m.page.sections.map((section: PageSection) => section.requestDto),
      applicationId: this.m.page.applicationId,
      settings: this.m.page.settings,
      seoFields: this.m.page.seoFields,
      scriptModels: this.m.page.scriptModels,
    };

    if (this.m.page.alias !== this.m.originalAlias) {
      body.alias = this.m.page.alias;
    }

    if (this.m.user?.tenant?.isPublic) {
      if (this.m.page.isPublic === Boolean(this.m.page.isPublic)) {
        body.isPublic = this.m.page.isPublic;
      }

      if (this.getOriginalObject?.alias !== this.m.page.alias) {
        body.alias = this.m.page.alias;
      }
    } else {
      Object.assign(body, this.m.page.alias ? { alias: this.m.page.alias } : {});
    }

    const page = await lastValueFrom(this.tenantPageService.pageControllerUpdate({ body }));

    this.cache.data.pages.setItem(page);

    this.c.canDeactivate = true;
    if (this.c.queryParams?.prevPageUrl) {
      UrlMethods.windowOpen(this.c.queryParams.prevPageUrl, this.params);
    }
    this.m.page.alias = page.alias;
  }

  async createSectionTemplate(templateMeta: TemplateMeta): Promise<void> {
    const section = this.m.sectionForTemplate!;
    await this.saveWidgetsFromSections([section]);

    const body: TemplateCreateRequestDto = {
      ...templateMeta,
      template: section.requestDto,
      type: TemplateType.section,
    };

    const dto = await lastValueFrom(this.tenantTemplateService.templateControllerCreate({ body }));
    section.templateId = dto.id;
    section.templateName = dto.name;
    this.m.page.templates[dto.id] = Template.fromDto(dto);
    this.cache.data.templates.setItem(dto);
  }

  async createWidgetTemplate(templateMeta: TemplateMeta): Promise<void> {
    const part = this.m.partWithWidgetForTemplate!;

    if (part.widget) {
      await this.saveWidgetAndStoreItsId(part);
    }

    const body: TemplateCreateRequestDto = {
      ...templateMeta,
      template: { widgetId: part.widget?.id, templateId: null },
      type: TemplateType.widget,
    } as TemplateCreateRequestDto;

    const dto = await lastValueFrom(this.tenantTemplateService.templateControllerCreate({ body }));
    part.widget!.templateId = dto.id;
    part.widget!.templateName = dto.name;
    this.m.page.templates[dto.id] = Template.fromDto(dto);
    this.cache.data.templates.setItem(dto);
  }

  async updateSectionTemplates(): Promise<void> {
    const requests: Promise<any>[] = [];

    this.m.page.sections
      .filter(({ templateId }: PageSection) => templateId)
      .forEach((section: PageSection) => {
        const template: Template = this.m.page.templates[section.templateId!];
        const body: TemplateUpdateRequestDto = {
          description: template.description,
          icon: template.icon,
          id: template.id,
          name: template.name,
          template: section.templateUpdateDto,
          categories: template.categories,
          thumbnailFileArtifactId: template.thumbnailFileArtifactId,
        };

        requests.push(lastValueFrom(this.tenantTemplateService.templateControllerUpdate({ body })).then(dto => this.cache.data.templates.setItem(dto)));
      });

    await Promise.all(requests);
  }

  async pasteSectionTemplateAsReused(template: TemplateResponseDto, previousSectionIndex: number, page = this.m.page, modalId?: string): Promise<void> {
    page.templates[template.id] = Template.fromDto(template);

    const section = PageSection.fromTemplateDto(template);
    modalId && (section.modalId = modalId);
    await this.templateService.loadWidgetTemplates(section.getWidgetsWithTemplateToLoad());
    await this.widgetService.loadWidgets(section.getPartsWithWidgetToLoad());
    await this.templateService.loadWidgetTemplates(section.getWidgetsWithTemplateToLoad(true));
    await this.widgetService.loadWidgets(section.getPartsWithWidgetToLoad(true));
    await this.widgetService.loadInnerWidgetsForPartsWithCard(section.getPartsWithCardWidgetToLoad());
    section.getPartsWithArtifactWidgetToLoad().forEach(part => this.onArtifactWidgetPaste(part.widget!.value.model as ArtifactWidgetModelDto, true));
    await this.widgetService.loadInnerWidgetsForArtifactWidgets(section.getPartsWithArtifactWidgetToLoad());
    await this.checkUniqueSectionHtmlIds(section);
    page.insertSection(previousSectionIndex, section);
    this.dragDropService.setDropListConnectionIds(page.sections, true, modalId);
  }

  async pasteSectionTemplateAsCopy(template: TemplateResponseDto, previousSectionIndex: number, page = this.m.page, modalId?: string): Promise<void> {
    const section = PageSection.fromTemplateDto(template);
    modalId && (section.modalId = modalId);
    await this.templateService.loadWidgetTemplates(section.getWidgetsWithTemplateToLoad());
    await this.widgetService.loadWidgets(section.getPartsWithWidgetToLoad());
    await this.templateService.loadWidgetTemplates(section.getWidgetsWithTemplateToLoad(true));
    await this.widgetService.loadWidgets(section.getPartsWithWidgetToLoad(true));
    await this.widgetService.loadInnerWidgetsForPartsWithCard(section.getPartsWithCardWidgetToLoad());
    section.getPartsWithArtifactWidgetToLoad().forEach(part => this.onArtifactWidgetPaste(part.widget!.value.model as ArtifactWidgetModelDto));
    await this.widgetService.loadInnerWidgetsForArtifactWidgets(section.getPartsWithArtifactWidgetToLoad());
    section.removeIdsForReuse();
    await this.checkUniqueSectionHtmlIds(section);
    page.insertSection(previousSectionIndex, section);
    this.dragDropService.setDropListConnectionIds(page.sections, true, modalId);
  }

  async pasteWidgetTemplate(template: TemplateResponseDto, part: PageBlockPart, isReused?: boolean): Promise<void> {
    const dto = await this.cache.data.widgets.getAsync((template.template as any).widgetId);

    if (dto.code === WidgetType.artifact && dto.value) {
      await this.onArtifactWidgetPaste(dto.value.model as ArtifactWidgetModelDto, !!isReused);
    }

    if (dto.code === WidgetType.sidebarModal && dto.value) {
      const page: Page = new Page((dto.value as any).model.page);
      await Promise.all(page.sections.map(section => this.widgetService.loadWidgets(section.getPartsWithWidgetToLoad())));

      isReused && ((dto as any).id = null);
      (dto.value as any).model.page = page;
    }

    // fetch widgets inside sidebar by widgetId
    if (dto.code === WidgetType.sidebar && (dto.value as any)?.model?.parts) {
      const parts: PageBlockPart[] = [];
      await this.widgetService.loadWidgets(
        (dto.value as any).model.parts.map(async (innerPart: PageBlockPart) => {
          const widgetId = (innerPart as any).widgetId;
          const widget = new BlockPartWidget({ widgetId } as PageBlockPartWidgetResponseDto);
          const newPart = new PageBlockPart(widget);
          (newPart.widget as BlockPartWidget).id = widgetId;
          if (newPart.htmlId && !this.isHtmlIdUnique(newPart.htmlId)) {
            await this.resetHtmlIdAndWarn(newPart);
          }
          await this.widgetService.loadWidgets([newPart]);
          !isReused && newPart.widget && (newPart.widget.id = null);

          parts.push(newPart);
          return newPart;
        }),
      );
      (dto.value as any).model.parts = parts;
    }

    part.widget = new BlockPartWidget(cloneDeep(dto));

    if (isReused) {
      part.widget.templateId = template.id;
      part.widget.templateName = template.name;
    } else {
      part.widget.id = null;
    }

    dto.code !== WidgetType.artifact && (await this.widgetService.loadInnerWidgetsForPartsWithCard([part]));
  }

  generateNewSection(scheme: string[], previousSectionIndex: number): void {
    this.m.page.insertSection(previousSectionIndex, new PageSection(this.generatePageRows(scheme)));
    this.dragDropService.setDropListConnectionIds(this.m.page.sections, true);
  }

  addBlockPart(block: PageBlock): void {
    block.parts.push(new PageBlockPart());
    this.dragDropService.setDropListConnectionIds(this.m.page.sections, true);
  }

  deleteBlockPart({ sectionIndex, rowIndex, blockIndex, partIndex }: PartLocation, page: Page): void {
    const section = page.getByLocation<PageSection>({ sectionIndex });
    const row = page.getByLocation<PageRow>({ sectionIndex, rowIndex });
    const { blocks, layout } = row;
    const block = page.getByLocation<PageBlock>({ sectionIndex, rowIndex, blockIndex });
    const part = page.getByLocation<PageBlockPart>({ sectionIndex, rowIndex, blockIndex, partIndex });

    if (block.parts.length === 1) {
      if (part.widget) {
        page.saveWidgetIdForDeletion(part);
        part.widget = null;
        return;
      }

      if (layout[blockIndex] === '12' && blocks.length > 1) {
        this.deleteAnyFromArrayByIndex(blocks, blockIndex);
        this.deleteAnyFromArrayByIndex(layout, blockIndex);
        this.dragDropService.setDropListConnectionIds(page.sections, true, page.modalId);
        page.saveWidgetIdForDeletion(part);
        return;
      } else {
        if (blocks.length === 1) {
          if (section.rows.length === 1) {
            this.deleteSection({ sectionIndex }, page);
          } else {
            this.deleteRow({ sectionIndex, rowIndex }, page);
          }
          return;
        }

        block.parts.forEach((part: PageBlockPart) => page.saveWidgetIdForDeletion(part));

        this.deleteAnyFromArrayByIndex(row.blocks, blockIndex);
        row.layout[blockIndex ? blockIndex - 1 : blockIndex + 1] = String(
          Number(layout[blockIndex ? blockIndex - 1 : blockIndex + 1]) + Number(layout[blockIndex]),
        );
        this.deleteAnyFromArrayByIndex(row.layout, blockIndex);
        this.dragDropService.setDropListConnectionIds(page.sections, true, page.modalId);
        return;
      }
    }

    page.saveWidgetIdForDeletion(part);
    this.deleteAnyFromArrayByIndex(block.parts, partIndex);
    this.dragDropService.setDropListConnectionIds(page.sections, true, page.modalId);
  }

  deleteSection(location: SectionLocation, page: Page): void {
    page.saveSectionWidgetIdsForDeletionIfNeeded(location);
    page.deleteSection(location);
    this.dragDropService.setDropListConnectionIds(page.sections, true, page.modalId);
  }

  deleteRow(location: RowLocation, page: Page): void {
    page.saveRowWidgetIdsForDeletionIfNeeded(location);
    page.deleteRow(location);
    this.dragDropService.setDropListConnectionIds(page.sections, true, page.modalId);
  }

  getExportMethod(): any {
    return this.tenantPageService.pageControllerExport$Response.bind(this.tenantPageService);
  }

  exportPagePart(location: SectionLocation | PartLocation, name: string, type: TemplateType): void {
    if (!this.m.page.id) return;
    this.m.selectedPageParts = [
      {
        id: this.m.page.id,
        name: name,
        pagePart: {
          pageId: this.m.page.id,
          type: type,
          ...location,
        },
      },
    ];
    this.m.exportModalOpened = true;
  }

  deleteAnyFromArrayByIndex(arr: any[], index: number): void {
    arr.splice(index, 1);
  }

  toggleSectionRedesignForm(event: Event, section: PageSection, sectionI: number): void {
    this.m.redesignColumnsModel.sectionIndex = sectionI;

    const scheme = section.rows.reduce((acc: string[][], row: PageRow) => {
      return [...acc, row.layout];
    }, []);
    this.c.sectionGenerationForm.toggle(event, scheme);
  }

  redesignSection(scheme: string[]): void {
    if (this.m.redesignColumnsModel.sectionIndex === null) {
      return;
    }

    const originalSection = this.m.page.sections[this.m.redesignColumnsModel.sectionIndex];
    originalSection.redesignByScheme(scheme);

    this.dragDropService.setDropListConnectionIds(this.m.page.sections, true);
  }

  async onArtifactWidgetPaste(model: ArtifactWidgetModelDto, isReused = false): Promise<void> {
    const newWidgetAlias = ElvisUtil.makeHash(10);
    !isReused && (model.settings.widgetAlias = newWidgetAlias);
    const containers = model.settings?.buttonContainers as ArtifactButtonContainersDto;
    if (containers) {
      for (let position of Object.keys(containers)) {
        const menuWidgetId = containers[position as ButtonContainerPositionEnum] as string;
        const menuWidgetDto = await this.cache.data.widgets.getAsync(menuWidgetId!);
        if (!isReused) {
          (menuWidgetDto.value.model as MenuWidgetModelDto).menuItems.forEach(item => SetMenuItemAliases(item as MenuItem, newWidgetAlias));
        }
        (containers as ArtifactButtonContainersType)[position as ButtonContainerPositionEnum] = new BlockPartWidget({
          code: WidgetType.menu,
          value: { model: new MenuWidgetModel(menuWidgetDto.value.model) },
        } as unknown as MenuWidgetTypeResponseDto);
      }
    }
  }

  private async postInit(): Promise<void> {
    if (this.m.isFirstLoad) {
      this.c.localStorageService.clearRedundantData();
      this.m.allWidgetOptions = this.widgetService.getWidgetOptions();
      this.m.widgetOptions = this.widgetService.getWidgetOptions([WidgetType.sidebar]);
      this.replaceRuntimeVariablesService.init();

      if (this.c.urlParams.id) {
        try {
          this.m.loading = true;
          this.m.user = new NewSystemUser(this.cache.user.value as SelfUserResponseDto);
          const dto = await this.cache.data.pages.getAsync(this.c.urlParams.id).catch((e: any) => {
            if (e?.status === HttpStatusCode.NotFound) {
              this.announcement.error('Page not found');
            }
            return undefined;
          });

          if (!(this.m.user.tenant.isAdmin || this.m.user.tenant.applications.find(app => app.id === dto?.applicationId)?.isAdmin)) {
            await this.router.navigate(['admin', 'dashboard']);
            return this.announcement.error("You can't edit this page");
          }

          const page = new Page(dto);

          this.setOriginalObject(page);
          this.m.isDefaultTenantPage = page.alias === '' && Boolean(this.m.user?.tenant?.isPublic);

          await this.templateService.loadPageSectionTemplates(page);
          await this.templateService.loadWidgetTemplates(page.getWidgetsWithTemplateToLoad());
          await this.widgetService.loadWidgets(page.getPartsWithWidgetToLoad());
          await this.templateService.loadSidebarModalPageSectionTemplates(page);
          await this.templateService.loadWidgetTemplates(page.getWidgetsWithTemplateToLoad(true));
          await this.widgetService.loadWidgets(page.getPartsWithWidgetToLoad(true));
          await this.widgetService.loadInnerWidgetsForPartsWithCard(page.getPartsWithCardWidget());

          const sidebarsWithCW = page.getSidebarsThatContain(WidgetType.card);
          const sidebarsWithAW = page.getSidebarsThatContain(WidgetType.artifact);
          await this.widgetService.loadSidebarInnerWidgets(sidebarsWithCW, WidgetType.card);
          await this.widgetService.loadSidebarInnerWidgets(sidebarsWithAW, WidgetType.artifact);

          const sidebarModalsWithCW = page.getSidebarModalsThatContain(WidgetType.card);
          const sidebarModalsWithAW = page.getSidebarModalsThatContain(WidgetType.artifact);
          await this.widgetService.loadSidebarModalInnerWidgets(sidebarModalsWithCW, WidgetType.card);
          await this.widgetService.loadSidebarModalInnerWidgets(sidebarModalsWithAW, WidgetType.artifact);

          await this.widgetService.loadInnerWidgetsForArtifactWidgets(page.getPartsWithArtifactWidget());

          this.m.page = page;
          this.m.originalAlias = this.m.page.alias || '';
          this.widgetService.setPageSidebars(page.getSidebars());
          this.pageHelper.setBackgroundToBody(this.m.page.settings.styles, this.authorizationService.getToken, this.authorizationService.getAnonymousToken);
          this.dragDropService.setDropListConnectionIds(this.m.page.sections, true);
          this.setOriginalObject<Page>(this.m.page);
          this.setAppropriateApplication(page.applicationId);

          CustomScriptsUtil.processAndAddScripts(this.m.page.scriptModels || []);

          setTimeout(() => {
            this.dragDropService.setDropListConnectionIds(this.m.page.sections, true);
          });
        } finally {
          this.m.loading = false;
        }
      }

      this.m.isFirstLoad = false;
    }
  }

  private async saveWidgetsFromSections(sections: PageSection[] = this.m.page.sections): Promise<void> {
    for (const section of sections) {
      for (const part of section.partsWithWidgets) {
        await this.saveWidgetAndStoreItsId(part);
      }
    }
  }

  private async saveArtifactWidget(widget: BlockPartWidget<ArtifactWidgetValue>): Promise<WidgetResponseDto> {
    await Promise.all(
      Object.values(widget.value.model.settings.buttonContainers as ArtifactButtonContainersType).map(async menuWidgetBlockPart => {
        const { id } = await this.widgetService.saveWidget(menuWidgetBlockPart.requestDto);
        menuWidgetBlockPart.id = id;
      }),
    );

    return await this.widgetService.saveWidget(widget.requestDto);
  }

  private async saveCardWidget(widget: BlockPartWidget<{ model: CardWidgetModel }>): Promise<WidgetResponseDto> {
    await Promise.all([
      ...widget.value.model.areas.map(async (area: GenericArea<CardWidgetAreaContent>) => {
        return await Promise.all(
          area.content.items.filter(IsCardContentItemWidget).map(async (item: CardWidgetAreaContentItem) => {
            const innerWidget = item.content as BlockPartWidget;
            const { id } = await this.widgetService.saveWidget(innerWidget.requestDto);
            innerWidget.id = id;
          }),
        );
      }),
    ]);

    return await this.widgetService.saveWidget(widget.requestDto);
  }

  private async saveSidebarWidget(widget: BlockPartWidget<SidebarWidgetValue>): Promise<WidgetResponseDto> {
    const innerPartsWithWidgets = (widget.value.model.parts || []).filter(({ widget }: PageBlockPart) => !!widget);

    await Promise.all([
      ...innerPartsWithWidgets.map(async (part: PageBlockPart) => {
        const innerWidget = part.widget;
        const body = innerWidget!.requestDto;
        let widgetResponseDto: WidgetResponseDto;

        if (innerWidget!.code === WidgetType.artifact) {
          widgetResponseDto = await this.saveArtifactWidget(innerWidget!);
        } else if (innerWidget!.code === WidgetType.card) {
          widgetResponseDto = await this.saveCardWidget(innerWidget!);
        } else {
          if (innerWidget!.code === WidgetType.listNew) {
            const state = this.localStorageService.get(part.hash);

            if (state && innerWidget) {
              if (!state.tableFormatSettings && innerWidget.value.model.state?.tableFormatSettings) {
                state.tableFormatSettings = innerWidget.value.model.state?.tableFormatSettings;
              }
              delete state.selection;
              state.columnOrder = innerWidget.value.model.selected.columns.map((col: TableColumn) => col.key);

              if (this.isColumnCountInconsistent(state, part)) this.fixColumnCountInconsistency(state);

              innerWidget.value.model.state = state;
              state.filters && TableMethods.formatTableFilterNamesToServer(innerWidget.value.model.state.filters);
              this.localStorageService.remove(part.hash as string);
            }

            (body.value as any).model.state = innerWidget!.value.model.state;
          }

          widgetResponseDto = await this.widgetService.saveWidget(body);
        }

        innerWidget!.id = widgetResponseDto.id;
      }),
    ]);

    return await this.widgetService.saveWidget(widget.requestDto);
  }

  private async saveSidebarModalWidget(widget: BlockPartWidget<SidebarModalWidgetValue>): Promise<WidgetResponseDto> {
    const innerParts: PageBlockPart[] = [];
    (widget.value.model.page as Page).sections.forEach(section => {
      section.partsWithWidgets.forEach(part => {
        innerParts.push(part);
      });
    });

    await Promise.all([
      ...innerParts.map(async (part: PageBlockPart) => {
        const innerWidget = part.widget;
        const body = innerWidget!.requestDto;

        let widgetResponseDto: WidgetResponseDto;

        if (innerWidget!.code === WidgetType.artifact) {
          widgetResponseDto = await this.saveArtifactWidget(innerWidget!);
        } else if (innerWidget!.code === WidgetType.card) {
          widgetResponseDto = await this.saveCardWidget(innerWidget!);
        } else {
          if (innerWidget!.code === WidgetType.listNew) {
            const state = this.localStorageService.get(part.hash);

            if (state && innerWidget) {
              if (!state.tableFormatSettings && innerWidget.value.model.state?.tableFormatSettings) {
                state.tableFormatSettings = innerWidget.value.model.state?.tableFormatSettings;
              }
              delete state.selection;
              state.columnOrder = innerWidget.value.model.selected.columns.map((col: TableColumn) => col.key);

              if (this.isColumnCountInconsistent(state, part)) this.fixColumnCountInconsistency(state);

              innerWidget.value.model.state = state;
              state.filters && TableMethods.formatTableFilterNamesToServer(innerWidget.value.model.state.filters);
              this.localStorageService.remove(part.hash as string);
            }

            (body.value as any).model.state = innerWidget!.value.model.state;
          }

          widgetResponseDto = await this.widgetService.saveWidget(body);
        }

        innerWidget!.id = widgetResponseDto.id;
      }),
    ]);

    return await this.widgetService.saveWidget(widget.requestDto);
  }

  private async saveWidgetAndStoreItsId(part: PageBlockPart): Promise<void> {
    const widget: BlockPartWidget = part.widget!;
    let widgetResponseDto: WidgetResponseDto;

    try {
      if (widget.code === WidgetType.artifact) {
        await this.pageHelper.saveAndProcessLinkingPopupWidgets((widget.value.model as ArtifactWidgetModel).linkingPopupDtoMap);
        widgetResponseDto = await this.saveArtifactWidget(widget);
      } else if (widget.code === WidgetType.card) {
        widgetResponseDto = await this.saveCardWidget(widget);
      } else if (widget.code === WidgetType.sidebar) {
        widgetResponseDto = await this.saveSidebarWidget(widget);
      } else if (widget.code === WidgetType.sidebarModal) {
        widgetResponseDto = await this.saveSidebarModalWidget(widget);
      } else {
        const body: WidgetCreateRequestDto | WidgetUpdateRequestDto = await this.pageHelper.getPreparedToServerListWidget(part);
        widgetResponseDto = await this.widgetService.saveWidget(body);
      }

      widget.id = widgetResponseDto!.id;
    } catch (e) {
      console.error(e);
      this.onWidgetFailedToSave(part);
      throw new Error('Failed to save widget');
    }
  }

  private isColumnCountInconsistent(state: any, part: Partial<PageBlockPart>): boolean {
    const utilColsCount = part.widget?.value.model.settings.editableRow ? 2 : 1;
    return state.columnWidths && state.columnWidths.split(',').length - utilColsCount !== state.columnOrder.length;
  }

  private fixColumnCountInconsistency(state: Record<string, any>): void {
    const colWidths = state.columnWidths.split(',');
    const difference = state.columnOrder.length - (colWidths.length - 2);
    for (let i = 0; i < difference; i++) {
      colWidths.splice(colWidths.length - 2, 0, '200');
    }
    state.columnWidths = colWidths.join(',');
  }

  private async deleteWidgets(): Promise<void> {
    const ids = this.dragDropService.moveIds;
    await Promise.all(
      [...this.m.page.widgetIdsToDelete.filter(id => !ids.includes(id)), ...this.getSidebarWidgetsToDelete().filter(id => !ids.includes(id))].map(
        (id: string) => {
          return lastValueFrom(this.tenantWidgetService.widgetControllerDelete({ id }));
        },
      ),
    );
  }

  private getSidebarWidgetsToDelete(): string[] {
    const widgetsToDelete: string[] = [];
    // todo think about re-use this iteration in this service
    this.m.page.sections.forEach((section: PageSection) => {
      section.rows?.forEach((row: PageRow) => {
        row.blocks?.forEach((block: PageBlock) => {
          block.parts?.forEach((part: PageBlockPart) => {
            const widget = part.widget;

            if (widget && widget.code === WidgetType.sidebar) {
              widget.value.model.widgetsToDelete?.length && widgetsToDelete.push(...widget.value.model.widgetsToDelete);
            }

            if (widget && widget.code === WidgetType.card) {
              widget.value.model.widgetsToDelete?.length && widgetsToDelete.push(...widget.value.model.widgetsToDelete);
            }
          });
        });
      });
    });

    return widgetsToDelete;
  }

  private generatePageRows(rows: string[]): PageRow[] {
    return rows.map(row => {
      const layout = row.split('+').filter(item => !!item);
      return new PageRow(
        layout.map(() => new PageBlock([new PageBlockPart()])),
        layout,
        null,
      );
    });
  }

  private setAppropriateApplication(applicationId: string): void {
    this.applicationSwitcherService.selectApplicationById(applicationId);

    const applications$ = this.cache.data.applications.subscribe(applications => {
      this.m.applicationOptions = (applications as ApplicationResponseDto[])!.map(app => new SelectOption<string, string>(app.name, app.id));

      setTimeout(() => {
        this.applicationSwitcherService.selectApplicationById(applicationId);
        applications$.unsubscribe();
      });
    });
  }

  private onWidgetFailedToSave(part: PageBlockPart): void {
    const widgetPart = document.getElementById(part.hash);
    widgetPart && this.highlightFailedWidget(widgetPart);
  }

  private highlightFailedWidget(widgetPart: HTMLElement): void {
    widgetPart.scrollIntoView({ behavior: 'smooth', block: 'center' });
    widgetPart.classList.add('failed-widget');
    setTimeout(() => widgetPart.classList.remove('failed-widget'), 8000);
  }

  private async checkUniqueSectionHtmlIds(section: PageSection): Promise<void> {
    if (section.htmlId && !this.isHtmlIdUnique(section.htmlId)) {
      await this.resetHtmlIdAndWarn(section);
    }

    for (const part of section.parts) {
      if (part.htmlId && !this.isHtmlIdUnique(part.htmlId)) {
        await this.resetHtmlIdAndWarn(part);
      }
    }
  }

  private isHtmlIdUnique(htmlId: string): boolean {
    return !document.getElementById(htmlId);
  }

  private async resetHtmlIdAndWarn(pageElement: PageElementWithHtmlId): Promise<void> {
    await this.announcement.warn(`Html id '${pageElement.htmlId}' already found on page, removing html id from this template.`);
    pageElement.htmlId = undefined;
  }
}
