import { Pipe, PipeTransform } from '@angular/core';
import { RuleUtilsService } from '../services';
import { WorkflowRule } from '../types';

@Pipe({
  name: 'isWorkflowOwnedByPage',
  standalone: true,
})
export class IsWorkflowOwnedByPagePipe implements PipeTransform {
  constructor(private ruleUtils: RuleUtilsService) {}

  transform(rule: WorkflowRule, pageId: string): boolean {
    return this.ruleUtils.isOwnedByPage(rule, pageId);
  }
}
