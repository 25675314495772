import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';
import { FlexChildElementStylesDtoKeysArray, StylesDtoKeys } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { FadeAnimation } from '@shared/animations/animations';
import { IsActionArtifactWidgetType, IsActionCustomEventType } from '@shared/methods/action-item.methods';
import { ElvisActionService } from '@shared/services/elvis-action.service';
import { IsItemInFirstLevelPipe } from '@widgets/menu-widget/pipes/is-item-in-first-level.pipe';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import { CustomClickService } from '@widgets/shared/services/custom-click-service';
import { ArtifactCustomEventSettings, ArtifactWidgetAction, CustomEventSettings } from '@widgets/shared/types/custom-event';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { MenuStylesPropNamesEnum } from '../../types/menu-widget-styles.types';
import { MenuItem, MenuWidgetModel } from '../../types/menu-widget.types';
import { MenuItemUiType, TreeItem } from '../../types/tree-types';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [FadeAnimation],
})
export class MenuItemComponent {
  @Input() item: MenuItem | TreeItem;
  @Input() m: MenuWidgetModel;
  @Input() stopPropagation = true;
  @Input() queryParams: Params;

  menuStylesPropNamesEnum = MenuStylesPropNamesEnum;
  menuItemUiType = MenuItemUiType;
  subMenuMouseOvered = false;
  subMenuDomRectangle: DOMRect;
  isFire: boolean;
  relevantStyles: StylesDtoKeys[] = [];

  constructor(
    private readonly elvisActionService: ElvisActionService,
    private readonly customClickService: CustomClickService,
  ) {
    this.setCssKeys();
  }

  get mouseOvered(): boolean {
    return this.m.settings.item[this.item.hash].mouseOvered;
  }

  get directionStyles(): any {
    return { flexDirection: this.item.innerFlexDirection };
  }

  onMouseEnter(event: MouseEvent, item: MenuItem): void {
    if (item.isTextOnly) {
      this.m.settings.item[item.hash].mouseOvered = true;
    }
    if (this.shouldComputeSubmenuOffset()) {
      this.subMenuDomRectangle = this.getSubmenuDomRectangle(event.target as Element);
    }
    if (!this.isAdvancedMode() && this.shouldOpenSubmenuOnHover()) {
      this.openSubMenu();
    }
  }

  onMouseLeave(item: MenuItem): void {
    if (item.isTextOnly) {
      this.m.settings.item[item.hash].mouseOvered = false;
    }
    if (!this.isAdvancedMode() && this.shouldOpenSubmenuOnHover()) {
      this.closeSubMenu();
    }
  }

  async onAnchorClick($event: Event, item: MenuItem | TreeItem): Promise<void> {
    $event.preventDefault();
    this.stopPropagation && $event.stopPropagation();
    if (this.isAdvancedMode()) return;

    if (this.isFire) return;
    this.isFire = true;

    for (const action of item.actions) {
      if (IsActionArtifactWidgetType(action.type)) {
        this.sendArtifactEvent(action, $event);
      } else if (IsActionCustomEventType(action.type)) {
        this.sendCustomEvent(action);
      } else {
        if (this.isAdvancedMode()) return;
        if (this.shouldOpenSubmenuOnClick()) this.item.expanded = !this.item.expanded;
        try {
          await this.elvisActionService.fireElvisAction(action, this.m.items.menu, this.queryParams);
        } catch (e) {
          break;
        }
      }
    }
    this.isFire = false;
  }

  private isAdvancedMode(): boolean {
    return this.item instanceof TreeItem;
  }

  private shouldOpenSubmenuOnHover(): boolean {
    return this.m.settings.menu.type !== MenuTypesEnum.panel || this.m.settings.menu.openSubmenuOnHover;
  }

  private shouldOpenSubmenuOnClick(): boolean {
    return !this.shouldOpenSubmenuOnHover();
  }

  private openSubMenu(): void {
    this.item.expanded = true;
  }

  private closeSubMenu(): void {
    setTimeout(() => {
      if (!this.m.settings.item[this.item.hash].mouseOvered && !this.subMenuMouseOvered) {
        this.item.expanded = false;
      }
    }, 300);
  }

  // Computing offset is done only in horizontal menu, on TOP level, if item has children and is not yet expanded
  private shouldComputeSubmenuOffset(): boolean {
    return (
      !!this.item.children.length &&
      !this.item.expanded &&
      this.m.settings.menu.type === MenuTypesEnum.horizontal &&
      new IsItemInFirstLevelPipe().transform(this.m.items.menu, this.item as MenuItem)
    );
  }

  private getSubmenuDomRectangle(target: Element): DOMRect {
    return target.getBoundingClientRect();
  }

  private setCssKeys() {
    // this.relevantStyles = [...ElementDisplayStylesDtoKeysArray, ...FlexContainerStylesDtoKeysArray, ...FlexChildElementStylesDtoKeysArray];
    this.relevantStyles = [...FlexChildElementStylesDtoKeysArray];
  }

  private sendArtifactEvent(action: ActionItem, event: Event): void {
    if (!action.widgetAlias) return;
    const eventSettings = new ArtifactCustomEventSettings({
      key: action.selectedType.data,
      enable: true,
      payload: WidgetType.artifact,
      event: action.selectedType.data === ArtifactWidgetAction.showAccessRights ? (event as PointerEvent) : undefined,
    });
    this.customClickService.sendArtifactEvent(eventSettings, action.widgetAlias);
  }

  private sendCustomEvent(action: ActionItem): void {
    const eventSettings = new CustomEventSettings({ key: action.model.name || '', enable: true, payload: null });
    this.customClickService.sendCustomEvent(eventSettings);
  }
}
