import { NewTeam } from '@shared/types/team.types';
import { MenuItem } from 'primeng/api';

export class FolderWidgetOptions {
  contextItems: MenuItem[];
  isMultiselect: boolean;
  selectTypeList: string[];
  teams: NewTeam[];

  constructor(isMultiselect = false, isRootFolder = false, dto?: FolderWidgetOptions) {
    this.contextItems = Object.keys(ContextType)
      .filter(key => this.isAllowForMultiselect(key, isMultiselect) && this.isAllowForRootFolders(key, isRootFolder))
      .map(key => ({
        label: ContextType[key as keyof typeof ContextType],
        icon: this.getIconByLabel(ContextType[key as keyof typeof ContextType]),
        command: event => this.handler(event),
      }));

    this.selectTypeList = Object.values(MemberTypes);
    this.isMultiselect = isMultiselect;
    dto?.teams && (this.teams = dto.teams);
  }

  handler(e: any): void {
    console.log(e);
  }

  isAllowForMultiselect(key: string, isMultiselect: boolean): boolean {
    return isMultiselect ? [ContextType.remove, ContextType.details].includes(ContextType[key as keyof typeof ContextType]) : true;
  }

  isAllowForRootFolders(key: string, isRootFolder: boolean): boolean {
    return isRootFolder
      ? [ContextType.access, ContextType.create, ContextType.rename, ContextType.details, ContextType.color].includes(
          ContextType[key as keyof typeof ContextType],
        )
      : true;
  }

  getIconByLabel(label: string): string {
    const iconMap: any = {
      [ContextType.create]: 'pi pi-plus',
      [ContextType.rename]: 'pi pi-pencil',
      [ContextType.remove]: 'pi pi-minus',
      [ContextType.details]: 'pi pi-book',
      [ContextType.color]: 'pi pi-palette',
      [ContextType.access]: 'pi pi-user',
    };

    return iconMap[label] || '';
  }
}

export enum MemberTypes {
  all = 'All',
  users = 'Users',
  teams = 'Teams',
}

export enum ContextType {
  create = 'Create folder',
  // createRoot = 'Create root folder',
  rename = 'Rename folder',
  remove = 'Remove folder(s)',
  details = 'Folder(s) details',
  color = 'Change color',
  access = 'Access rights',
}
