import { Pipe, PipeTransform } from '@angular/core';
import { StylesDto, StylesDtoKeys } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';

@Pipe({
  name: 'filterRelevantStyles',
  standalone: true,
})
export class FilterRelevantStylesPipe implements PipeTransform {
  transform(styles: Partial<StylesDto> = {}, relevantKeys: StylesDtoKeys[] = [], columnStylesEvent: RuntimeStateNotification): Partial<StylesDto> {
    return relevantKeys.reduce<any>((acc, key) => {
      acc[key] = styles?.[key];
      return acc;
    }, {} as any);
  }
}
