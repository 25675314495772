import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

export class WorkflowOptions {
  attributes: ListContainer<NewAttribute> = new ListContainer();
  dataTypes: ListContainer<NewDataType> = new ListContainer();

  constructor(cache: NewCacheService) {
    this.initAttributes(cache);
    this.initDataTypes(cache);
  }

  private initAttributes(cache: NewCacheService): void {
    this.attributes.setList(
      (cache.data.attributes.value as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
      ID_KEY,
    );
  }

  private initDataTypes(cache: NewCacheService): void {
    this.dataTypes.setList(
      (cache.data.dataTypes.value as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
      ID_KEY,
    );
  }
}
