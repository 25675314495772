import { Pipe, PipeTransform } from '@angular/core';
import { NonAttributeKeys } from '../../../shared/types/attribute.types';

@Pipe({
  name: 'isSystemFileAttribute',
})
export class IsSystemFileAttributePipe implements PipeTransform {
  transform(attributeId: string): boolean {
    return attributeId === NonAttributeKeys.FILE_ARTIFACT_PATH_ID;
  }
}
