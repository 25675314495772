@if (service.m.currentArtifactTypeOption) {
  <div class="col-12 p-0 d-flex flex-wrap">
    <div class="col-6">
      <div class="dynamic-label">
        <p-dropdown
          (onChange)="onOutputTypeChange()"
          [options]="outputTypesOptions"
          [(ngModel)]="settingFields.outputType"
          [autoDisplayFirst]="false"
          appendTo="body"
          inputId="avrQrCodesOutputType"
        >
        </p-dropdown>
        <label [for]="'avrQrCodesOutputType'" class="dynamic-label-text">{{ 'Output type' | translate }}</label>
      </div>
    </div>
  </div>
}
