<div class="header-column-container full-size-container">
  <p-sortIcon
    #columnSortRef
    *ngIf="(column.key | isInStringArray : sortableColumnKeys) && (!column.meta.columnFormatSettings.hideSorting || isInPageBuilder)"
    [field]="column.key"
    [pSortableColumn]="(column.key | isInStringArray : sortableColumnKeys) ? column.key : ''"
    class="header-column-icon control-icon position-top sort-icon"
  >
  </p-sortIcon>

  <div *ngIf="disabled" class="tooltip-disabled" [pTooltip]="'This filter is set in grouping' | translate"></div>

  <p-columnFilter
    #columnFilterRef
    *ngIf="hasTemplate && (!column.meta.columnFormatSettings?.hideFilter || isInPageBuilder)"
    [field]="column.meta.filterKey"
    [type]="column.meta.filterType | getFilterType"
    [showAddButton]="columnFilterMetaData.showAddButton"
    [showMatchModes]="columnFilterMetaData.showMatchModes"
    [showOperator]="columnFilterMetaData.showOperator"
    [showClearButton]="columnFilterMetaData.showBottomButtons"
    [showApplyButton]="columnFilterMetaData.showBottomButtons"
    [matchMode]="columnFilterMetaData.matchMode"
    [matchModeOptions]="columnFilterMetaData.matchModeOptions"
    [ngClass]="{ 'filter-icon-disabled': disabled }"
    display="menu"
    class="header-column-icon control-icon position-top filter-icon"
  >
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
      <ng-container *ngTemplateOutlet="filterTemplate; context: { $implicit: value, filter: filter }"></ng-container>
    </ng-template>

    <ng-template pTemplate="filtericon">
      <span class="pi pi-filter-icon pi-filter" (click)="onFilterOpen()"></span>
    </ng-template>
  </p-columnFilter>

  <i class="pi pi-ellipsis-v header-column-icon control-icon position-top column-menu-icon"
     (click)="doOpenColumnMenu($event)"></i>

  <div
    class="header-column-content full-size-container"
    [class.column-link-header]="column.meta"
    [ngStyle]="column.meta.columnFormatSettings?.columnFormat?.styles"
  >
    <span *ngIf="column.meta.columnFormatSettings?.columnFormat?.showIcon" class="attribute-icon-container">
      <i class="{{ column.meta.attributeMetadata?.attribute?.icon }} attribute-icon"
         [ngStyle]="column.meta.columnFormatSettings?.columnFormat?.styles"></i>
    </span>
    <ng-container *ngIf="column.meta.columnFormatSettings?.columnFormat?.useCustomLabel; else defaultLabel">
      {{ column.meta.columnFormatSettings?.columnFormat?.customLabel }}
    </ng-container>

    <ng-container
      *ngIf="column.meta.isLink && (!column.meta.columnFormatSettings?.columnFormat || column.meta.columnFormatSettings?.columnFormat?.showLink)">
      <i *ngIf="isOutgoing()" class="pi pi-arrow-circle-right outgoing-link"></i>
      <i *ngIf="isIncoming()" class="pi pi-arrow-circle-left incoming-link"></i>
    </ng-container>
  </div>
</div>

<!-- Template definitions --------------------------------------------------------------------------------------------------------------------------------- -->
<!-- default label -->
<ng-template #defaultLabel>
  {{ column.label }}
</ng-template>

<!-- text filter -->
<ng-template #textFilterRef let-value let-filter="filter">
  <input
    pInputText
    type="text"
    [ngModel]="value"
    (keyup.enter)="filter($event.target.value)"
    (focusin)="textFilterFocusInValue = $event.target.value"
    (focusout)="applyTextFilterCallbackOnFocusout(filter, $event.target.value)"
    appAutofocus
  />
</ng-template>

<!-- enum filter -->
<ng-template #enumFilterRef let-value let-filter="filter">
  <p-multiSelect
    [ngModel]="value"
    (onChange)="filter($event.value)"
    [options]="column.key | getEnumOrUserFilterOptions : lwOptions : column.meta.attributeMetadata?.attribute?.multipleValues"
    placeholder="Any"
  >
  </p-multiSelect>
</ng-template>

<ng-template #enumFilterArtifactTypeRef let-value let-filter="filter">
  <p-multiSelect
    [ngModel]="value"
    (onChange)="onArtifactTypeFilter(filter, $event.value)"
    [options]="column.meta.artifactTypeEnumOptions | getEnumOptionsFromDataTypeValues"
    placeholder="Any"
  >
  </p-multiSelect>
</ng-template>

<!-- user filter -->
<ng-template #userFilterRef let-filter="filter">
  <app-user-autocomplete [value]="selectedUserFilter.value"
                         (valueChange)="onUserSelect($event, filter)"
                         [multipleValues]="true"
                         [maxSelectedLabels]="2"
                         [addUserTypeOptions]="true"
  ></app-user-autocomplete>

  <!--  Kept it for future if we will decide to use it back-->
  <!--  <p-dropdown-->
  <!--    [options]="userFilterEnumOptions"-->
  <!--    [(ngModel)]="selectedUserFilter.type"-->
  <!--    (ngModelChange)="onUserFilterTypeChange(filter)"-->
  <!--    placeholder="Choose either user or team filter"-->
  <!--  >-->
  <!--    <ng-template let-option pTemplate="item">{{ option.label | translate }}</ng-template>-->
  <!--    <ng-template let-option pTemplate="selectedItem">{{ option.label | translate }}</ng-template>-->
  <!--  </p-dropdown>-->

  <!--  <p-multiSelect-->
  <!--    *ngIf="selectedUserFilter.type === userFilterTypeEnum.filterByUser"-->
  <!--    [ngModel]="selectedUserFilter.value"-->
  <!--    (onChange)="onUserSelect($event.value, filter)"-->
  <!--    [options]="column.key | getEnumOrUserFilterOptions : lwOptions : column.meta.attributeMetadata?.attribute?.multipleValues"-->
  <!--    placeholder="Any"-->
  <!--  >-->
  <!--  </p-multiSelect>-->

  <!--  <p-multiSelect-->
  <!--    *ngIf="selectedUserFilter.type === userFilterTypeEnum.filterByTeam"-->
  <!--    [ngModel]="selectedUserFilter.value"-->
  <!--    (onChange)="onTeamSelect($event.value, filter)"-->
  <!--    [options]="column.key | getEnumOrUserFilterOptions : lwOptions : column.meta.attributeMetadata?.attribute?.multipleValues : true"-->
  <!--    placeholder="Any"-->
  <!--  >-->
  <!--  </p-multiSelect>-->

  <span class="bottom-buttons">
    <button (click)="onUserFilterClear(filter)" class="p-button p-button-outlined" label="{{ 'Clear' | translate }}"
            pButton type="button"></button>
    <button (click)="onUserFilterApply()" class="p-button" label="{{ 'Apply' | translate }}" pButton
            type="button"></button>
  </span>
</ng-template>

<!-- date filter -->
<ng-template #dateFilterRef let-filter="filter">
  <app-date-filter
    [filter]="filter"
    [advancedDateFilterObject]="selectedDateFilterObject"
    [showTime]="false"
    [onApplyCb]="onDateFilterApply.bind(this)"
    [onClearCb]="onDateFilterClear.bind(this)"
    (onMatchModeChange)="onDateMatchModeChange($event)"
  >
  </app-date-filter>
</ng-template>

<!-- datetime filter -->
<ng-template #datetimeFilterRef let-filter="filter">
  <app-date-filter
    [filter]="filter"
    [advancedDateFilterObject]="selectedDateFilterObject"
    [showTime]="true"
    [onApplyCb]="onDateFilterApply.bind(this)"
    [onClearCb]="onDateFilterClear.bind(this)"
    (onMatchModeChange)="onDateMatchModeChange($event)"
  >
  </app-date-filter>
</ng-template>

<!-- boolean -->
<ng-template #booleanFilterRef let-value let-filter="filter">
  <p-multiSelect #bfMultiSelect [options]="booleanFilterSelectOptions" [ngModel]="value" placeholder="Any"
                 (onChange)="filter($event.value)"></p-multiSelect>
</ng-template>

<!-- link -->
<ng-template pTemplate="filter" #linkFilterRef>
  <p-dropdown [options]="lwOptions.linkFilter" [(ngModel)]="linkFilter" [autoDisplayFirst]="false"
              placeholder="Choose link filter">
    <ng-template let-option pTemplate="item">{{ option.label | translate }}</ng-template>
    <ng-template let-option pTemplate="selectedItem">{{ option.label | translate }}</ng-template>
  </p-dropdown>

  <input *ngIf="linkFilter === linkFilterEnumOptions.containsUrlParamKey" [(ngModel)]="linkFilterUrlParamKey" pInputText
         type="text" id="linkFilter" />

  @if (linkFilter === linkFilterEnumOptions.linkedFilter) {
    <app-link-filter
      [linkTypes]="lwOptions.linkTypes"
      [artifactTypes]="lwOptions.artifactTypes"
      [attributes]="lwOptions.attributes"
      [dataTypes]="lwOptions.dataTypes.list"
      [restrictions]="column.meta.linkRestrictionParams"
      [linkedFilter]="linkedFilter"
      (onApply)="onLinkFilterApply()"
    ></app-link-filter>
  }

  <div class="d-flex p-jc-between justify-content-center p-ai-center mt-3">
    <p-button label="Clear" styleClass="p-button-outlined mr-2" (onClick)="onLinkFilterClear()"></p-button>
    <p-button label="Apply" styleClass="ml-2" (onClick)="onLinkFilterApply()"></p-button>
  </div>
</ng-template>

<!-- folder -->
<ng-template pTemplate="filter" #folderFilterRef let-filter="filter">
  <app-folder-filter
    [applications]="lwOptions.applications"
    [folderFilterData]="folderFilterData"
    controlButtonsStyleClass="p-column-filter-buttonbar"
    (onFilterApply)="onFolderFilterApply($event)"
    (onFilterClear)="onFolderFilterClear()"
    (onFilterDataUpdate)="onFolderFilterDataUpdate($event)"
  >
  </app-folder-filter>
</ng-template>

<!-- file -->
<ng-template pTemplate="filter" #fileFilterRef let-value let-filter="filter">
  <div class="file-filter">
    <p-dropdown [options]="fileFilterOptions"
                [ngModel]="value"
                (ngModelChange)="onFileFilterChange($event, filter)"
                [showClear]="true"
                placeholder="Choose file filter">
    </p-dropdown>
  </div>
</ng-template>
