import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { MenuRepresentation } from '@widgets/menu-widget/types/menu-representation-types';
import { LinkQueryParamActionEnum, MenuItemCurrentPageBehaviorEnum, MenuItemUiType } from '@widgets/menu-widget/types/tree-types';
import { MenuWidgetAction } from '@widgets/shared/types/custom-event';
import { FlexDirection, FlexDirectionEnum, StyleOptions } from '../../shared/types/style.types';
import { MenuItemHorizontalPosition, MenuItemVerticalPosition } from './menu-widget-styles.types';

export class MenuWidgetOptions extends StyleOptions {
  display = GetSelectOptionsFromEnum(FlexDirectionEnum);
  pages = new ListContainer<Page>();
  menuItemHorizontalAlign = GetSelectOptionsFromEnum(MenuItemHorizontalPosition);
  menuItemVerticalAlign = GetSelectOptionsFromEnum(MenuItemVerticalPosition);
  menuTypes = GetSelectOptionsFromEnum(MenuTypesEnum);
  menuItemUiType = GetSelectOptionsFromEnum(MenuItemUiType);
  menuItemRepresentationType = GetSelectOptionsFromEnum(MenuRepresentation);
  defaultElvisActions = GetSelectOptionsFromEnum(DefaultElvisActions);
  currentPageBehavior = GetSelectOptionsFromEnum(MenuItemCurrentPageBehaviorEnum);
  linkQueryParamAction = GetSelectOptionsFromEnum(LinkQueryParamActionEnum);
  flexDirection: SelectOption<FlexDirection, FlexDirection>[] = GetSelectOptionsFromEnum<FlexDirection>(FlexDirection);
  actionOptions: SelectOption<MenuWidgetAction, MenuWidgetAction>[] = GetSelectOptionsFromEnum(MenuWidgetAction);

  constructor() {
    super();
  }
}

export enum MenuTypesEnum {
  horizontal = 'horizontal',
  tiered = 'tiered',
  panel = 'panel',
  // slide = 'slide'
}
