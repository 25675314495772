<!-- menu overlay panel -->
<p-overlayPanel #menuOp appOverlayPanelShowFix appendTo="body" styleClass="table-header-menu cop-no-padding"
                [style]="{ 'max-width': '380px' }">
  <ng-template pTemplate>
    <p-accordion #accordion [activeIndex]="0" class="hidden" styleClass="accordion-no-highlight accordion-menu-style" collapseIcon="pi pi-fw pi-minus"
                 expandIcon="pi pi-fw pi-plus">

      <p-accordionTab header="Header" *ngIf="isInPageBuilder">
        <app-attribute-format-label-styles-form
          [showLabelOption]="false"
          [showFontBackgroundSettings]="true"
          [enableLabelPositioning]="false"
          [labelFormatSettings]="columnFormat"
          (labelStyleChange)="onColumnFormatChange()"
          (labelPositionChange)="onColumnFormatChange()"
          [isLink]="column.meta.isLink"
          [hideFilter]="this.column.meta.columnFormatSettings.hideFilter"
          [hideSorting]="this.column.meta.columnFormatSettings.hideSorting"
          (hideFilterChange)="onHideFilterItemChange($event)"
          (hideSortingChange)="onHideSortingItemChange($event)"
        >
        </app-attribute-format-label-styles-form>
      </p-accordionTab>

      <p-accordionTab header="Content Styles" *ngIf="isInPageBuilder">
        <app-shared-styles-settings [styles]="contentColumnFormat.styles"
                                    [applicationId]="applicationId"
                                    (onChange)="onColumnFormatChange()">
        </app-shared-styles-settings>
      </p-accordionTab>

      <p-accordionTab header="Content formats" *ngIf="showStyling">
        <app-attribute-format-label-styles-form
          [hideLabelFontSettings]="true"
          [showLabelOption]="false"
          [showFontBackgroundSettings]="true"
          [enableLabelPositioning]="false"
          [showPrefixSuffixSettings]="
            (column.meta.attributeMetadata?.dataType.baseDataType | isInteger)
            || (column.meta.attributeMetadata?.dataType.baseDataType | isDecimal)
          "
          [showDecimalPlacesSettings]="column.meta.attributeMetadata?.dataType.baseDataType | isDecimal"
          [isContentStyles]="true"
          [labelFormatSettings]="contentColumnFormat"
          (labelStyleChange)="onColumnFormatChange()"
          (labelPositionChange)="onColumnFormatChange()"
        >
        </app-attribute-format-label-styles-form>
      </p-accordionTab>
      <p-accordionTab header="Edit mode">
        <p-dropdown
          [autoDisplayFirst]="false"
          (onChange)="onEditModeChange($event.value)"
          [options]="displayAtEditDropdownItems"
          [(ngModel)]="selectedEditItem"
          id="artifactType"
        >
          <ng-template pTemplate="selectedItem">
            <div>{{ selectedEditItem?.label | translate }}</div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div>{{ item.label | translate }}</div>
          </ng-template>
        </p-dropdown>
      </p-accordionTab>

      <p-accordionTab header="Display variant">
        <p-dropdown
          [autoDisplayFirst]="false"
          (onChange)="onSelectedItemChange($event.value)"
          [options]="displayAtDropdownItems"
          [(ngModel)]="selectedItem"
        >
          <ng-template pTemplate="selectedItem">
            <div>{{ selectedItem.label | translate }}</div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div>{{ item.label | translate }}</div>
          </ng-template>
        </p-dropdown>
      </p-accordionTab>

      <p-accordionTab header="Sort options" *ngIf="column?.meta?.attributeMetadata?.dataType?.isEnum">
        <p-dropdown
          [autoDisplayFirst]="false"
          (onChange)="onSelectedSortByItemChange($event.value)"
          [options]="displayAtSortEnumItems"
          [(ngModel)]="selectedSortByItem"
        >
          <ng-template pTemplate="selectedItem" let-item>
            <div>{{ item.label | translate }}</div>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div>{{ item.label | translate }}</div>
          </ng-template>
        </p-dropdown>
      </p-accordionTab>

      @if (isInPageBuilder) {
        <p-accordionTab header="Conditional formatting">
          @if (widgetId) {
            @if (formattingRules.length) {
              <div class="formatting-rule-row-container">
                @for (rule of formattingRules; track rule.id) {
                  <div class="formatting-rule-row">
                    <span class="rule-name pl-2"> {{ rule.name }} </span>
                    <button pButton class="p-button p-button-text edit-conditional-formatting-button" type="button" icon="pi pi-pencil"
                            (click)="onOpenConditionalFormatting(rule)"></button>
                  </div>
                }
              </div>
            } @else {
              <div class="pb-2">
                {{ 'WORKFLOWS.COMMON.NO_FORMATTING_RULES' | translate }}
              </div>
            }
            <button pButton class="p-button-info" type="button" [label]="'Add' | translate" (click)="onOpenConditionalFormatting()"></button>
          } @else {
            <span> To enable formatting, please save this widget and reload the page </span>
          }
        </p-accordionTab>
      }
    </p-accordion>
  </ng-template>
</p-overlayPanel>

<!-- column header settings -->
<p-overlayPanel #headerFontStyleOp [dismissable]="true" [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <p-tabView [activeIndex]="0">
      <p-tabPanel *ngFor="let styleType of styleTypes" [header]="styleType | translate">
        <h5>{{ 'Label styles' | translate }}</h5>
        <app-font-style-form-op
          [fontStyles]="columnFormat"
          [hideFontFamilySettings]="false"
          [hideBackgroundColorSetting]="false"
          [hideHorizontalAlignSettings]="true"
          (onChange)="onColumnFormatChange()"
        ></app-font-style-form-op>
      </p-tabPanel>
    </p-tabView>
  </ng-template>
</p-overlayPanel>
<!-- column padding settings -->
<p-overlayPanel #headerPaddingFormOp [dismissable]="true" [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-padding-form [paddingStyles]="padding"></app-padding-form>
  </ng-template>
</p-overlayPanel>
<!-- column alignment settings -->
<p-overlayPanel #headerAlignmentFormOp [dismissable]="true" [style]="{ 'max-width': '380px' }" appendTo="body">
  <ng-template pTemplate>
    <app-flex-content-alignment-form [styles]="columnFormat" [showVertical]="false"
                                     (onChange)="onColumnFormatChange()"></app-flex-content-alignment-form>
  </ng-template>
</p-overlayPanel>
