<div class="list_top">
  <div class="grid list_top__filters" style="padding-bottom: 300px">
    <div class="col-6">
      <p-dropdown
        (onChange)="s.onArtifactTypeChange()"
        [(ngModel)]="m.selected.artifactType"
        [options]="m.options.artifactTypes.list | transformArrayToSelectOptions: NAME_KEY"
        showClear="true"
        filter="true"
        optionLabel="label"
        placeholder="Select artifact type"
      >
      </p-dropdown>
    </div>
    <div *ngIf="m.selected.artifactType && m.options.artifactAttributes" class="col-6">
      <p-dropdown
        (onChange)="s.onGroupByChange()"
        [(ngModel)]="m.selected.groupByAttribute"
        [options]="m.options.artifactAttributes | transformArrayToSelectOptions: NAME_KEY"
        showClear="true"
        optionLabel="label"
        placeholder="Select group by attribute"
      >
      </p-dropdown>
    </div>
    <ng-container *ngIf="m.selected.artifactType">
      <div class="col-6">
        <p-dropdown
          (onChange)="s.onChartTypeChange()"
          [(ngModel)]="m.settings.chartType"
          [options]="m.options.chartTypes.list | transformArrayToSelectOptions"
          [showClear]="true"
          autoDisplayFirst="false"
          filterBy="label"
          placeholder="Select a chart type"
        >
        </p-dropdown>
      </div>
      <div class="col-6"></div>

      <div class="col-6 checkbox-cont">
        <label for="isShowLegend">Show legend</label>
        <p-checkbox
          (onChange)="s.updateChartData()"
          [(ngModel)]="m.settings.chartOptions.isShowLegend"
          [binary]="true"
          [disabled]="isVerticalFit"
          id="isShowLegend"
        ></p-checkbox>
      </div>
      <div class="col-6">
        <p-dropdown
          (onChange)="s.updateChartData()"
          [(ngModel)]="m.settings.chartOptions.legend.position"
          [options]="m.options.chartLegendPosition.list | transformArrayToSelectOptions"
          [showClear]="true"
          autoDisplayFirst="false"
          filterBy="label"
          placeholder="Select a legend position"
        >
        </p-dropdown>
      </div>

      <div *ngIf="isDoughnut()" class="col-6">{{ 'Cutout percentage' | translate }}</div>
      <div *ngIf="isDoughnut()" class="col-6">
        <p-inputNumber
          [(ngModel)]="m.settings.chartOptions.cutoutPercentage"
          (onInput)="s.updateChartData()"
          [suffix]="' %'"
          min="2"
          max="98"
        ></p-inputNumber>
      </div>

      <div class="col-6">{{ 'Fit mode' | translate }}</div>
      <div class="col-6">
        <p-dropdown
          (onChange)="s.onFitChange()"
          [(ngModel)]="m.selected.fitMode"
          [options]="m.options.fitModes.list | transformArrayToSelectOptions"
        >
        </p-dropdown>
      </div>

      <p-accordion class="col-12">
        <p-accordionTab [header]="'Counter settings' | translate">
          <div class="grid">
            <div class="col-6 checkbox-cont">
              <label for="isShowTotal">Show total</label>
              <p-checkbox
                (onChange)="s.updateChartData()"
                [(ngModel)]="m.settings.chartOptions.isShowTotal"
                [disabled]="!isDoughnut() && isVertical()"
                [binary]="true"
                id="isShowTotal"
              ></p-checkbox>
            </div>
            <div class="col-6">
              <p-dropdown
                (onChange)="s.updateChartData()"
                [(ngModel)]="m.selected.totalByAttribute"
                [disabled]="!isDoughnut() && isVertical()"
                [options]="m.options.artifactAttributesForTotal | transformArrayToSelectOptions: NAME_KEY"
                showClear="true"
                optionLabel="label"
                placeholder="Select total count by attribute"
              ></p-dropdown>
            </div>
            <div class="col-6">
              <p-dropdown
                (onChange)="s.updateChartData()"
                [(ngModel)]="m.selected.chartTotalPosition"
                [options]="m.options.chartTotalPosition.list | transformArrayToSelectOptions"
                [disabled]="!isDoughnut() && isVertical() || !m.settings.chartOptions.isShowTotal"
                autoDisplayFirst="false"
                filterBy="label"
                placeholder="Select a total position"
              >
              </p-dropdown>
            </div>
            <div class="col-6 mb-0">
              <button (click)="counterStyleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Counter styles"
                      pButton></button>
            </div>

            <div class="col-6">
                <span class="dynamic-label p-float-label">
                    <input [(ngModel)]="m.selected.prefix" id="prefix" pInputText type="text" />
                    <label class="dynamic-label-text" for="prefix" translate>Prefix</label>
                </span>
            </div>
            <div class="col-6">
                <span class="dynamic-label p-float-label">
                    <input [(ngModel)]="m.selected.postfix" id="postfix" pInputText type="text" />
                    <label class="dynamic-label-text" for="postfix" translate>Postfix</label>
                </span>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>

      <div *ngIf="m.selected.groupByAttribute" class="col-12">
        <app-artifact-filters
          (filtersChange)="s.updateChartData()"
          [attributeAndLinkOptions]="(m.options.artifactAttributes | transformArrayToSelectOptions : NAME_KEY)!"
          [dataTypes]="m.options.dataTypes.list"
          [filtersHolder]="m.selected!"
          [filtersKey]="'attributesFilter'"
        ></app-artifact-filters>
      </div>
    </ng-container>
  </div>
</div>

<p-overlayPanel #counterStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{ 'Counter styles'| translate }}</h5>
    <app-font-style-form
      [fontStyles]="m.selected.counterStyles"
      [hideFontFamilySettings]="true"
      [hideHorizontalAlignSettings]="true"
      (onChange)="s.updateTotalPosition()"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #titleStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{ 'Label styles'| translate }}</h5>
    <app-font-style-form
      [fontStyles]="m.selected.titleStyles"
      [hideFontFamilySettings]="true"
      [hideHorizontalAlignSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>
