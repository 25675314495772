import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';

@Pipe({
  name: 'fileAttributeNumberOfFiles',
})
export class FileAttributeNumberOfFilesPipe implements PipeTransform {

  transform(files: ArtifactResponseDto | ArtifactResponseDto[] | null): number {
    if(files) {
      if (Array.isArray(files)) {
        return files.length;
      }
      else return 1
    }
    return 0;
  }
}
