import { Injectable } from '@angular/core';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { SwissBillOutputTypes } from '@shared/services/artifact-visual-representation/swiss-bill/dto/swiss-bill.input-mapper.dto';
import { SwissBillAvrQueryDto } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/swiss-bill/types/swiss-bill.avr-types-settings.types';

import { AbstractAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/abstract.avr-types-services.service';

@Injectable()
export class SwissBillAvrTypeService extends AbstractAvrTypeService<AvrTypes.swissBill> {
  constructor() {
    super(AvrTypes.swissBill);
  }

  getAdditionalQueryParams(): SwissBillAvrQueryDto | null {
    if (this.m.currentAvrFileType === null) {
      this.m.avrBlobError = 'Output type is not selected';
      return null;
    }
    return new SwissBillAvrQueryDto({
      outputType: this.m.currentAvrFileType as Extract<AvrOutputTypes, `${(typeof SwissBillOutputTypes)[number]}`>,
    });
  }
}
