import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { TenantArtifactService } from '@api/services';
import { TranslateModule } from '@ngx-translate/core';
import { UserOptionMeta } from '@shared/components/user-autocomplete/types/user-option-meta.model';
import {
  BLANK_FILTER_OPTION_LABEL,
  BLANK_OPTION_FILTER_URL_VALUE,
  CURRENT_USER_OPTION_LABEL,
  INVALID_USER_LABEL,
  IS_NOT_EMPTY_OPTION_FILTER_LABEL,
  IS_NOT_EMPTY_OPTION_FILTER_VALUE,
} from '@shared/constants/constants';
import { GetArtifactAttributeValuePath } from '@shared/methods/client-attribute.methods';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { RemoveSquareBracketsPipe } from '@shared/pipes/common/remove-square-brackets.pipe';
import { ImagePipesModule } from '@shared/pipes/internal-image/image-pipes.module';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { UserIconSizeEnum } from '@shared/types/display-at-types';
import { Debounce } from '@shared/utils/debounce.util';
import { isEqual } from 'lodash';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { NewCacheService } from '../../cache/new-cache.service';
import { GlobalConstants } from '../../constants/global.constants';
import { GlobalConstantsEnum } from '../../types/global-constants.enum';
import { SelectOption } from '../../types/shared.types';

@Component({
  selector: 'app-user-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule,
    MultiSelectModule,
    TranslateModule,
    DropdownModule,
    CommonPipesModule,
    ImagePipesModule,
    RemoveSquareBracketsPipe,
  ],
  templateUrl: './user-autocomplete.component.html',
  styleUrl: './user-autocomplete.component.scss',
})
export class UserAutocompleteComponent implements OnInit, OnChanges {
  @Input() attribute?: NewAttribute;
  @Input() multipleValues?: boolean;
  @Input() value: string | string[] | null;
  @Input() appendTo: string | null = 'body';
  @Input() placeholder: string | null;
  @Input() disabled: boolean;
  @Input() selectionLimit?: number;
  @Input() maxSelectedLabels?: number;
  @Input() addUserTypeOptions?: boolean;

  @Output() valueChange = new EventEmitter<string | string[] | null>();
  @Output() onBlur = new EventEmitter<any>();
  @Output() onHide = new EventEmitter<any>();

  @ViewChild('dropdown') dropdown: any;
  @ViewChild('multiselect') multiselect?: MultiSelect;

  selectedOption: SelectOption<string, string, UserOptionMeta> | null = null;
  selectedOptions: SelectOption<string, string, UserOptionMeta>[] = [];
  filteredUsers: SelectOption<string, string, UserOptionMeta>[] = [];
  iconSize: UserIconSizeEnum = UserIconSizeEnum.LARGE;
  isLoading: boolean = true;
  canLoadMore: boolean = true;

  private pageNumber: number = 1;
  private pageSize: number = 20;
  private lastQuery: string = '';

  private readonly userRelatedKeys = [NonAttributeKeys.CURRENT_USER_ID, BLANK_OPTION_FILTER_URL_VALUE, IS_NOT_EMPTY_OPTION_FILTER_VALUE];
  private readonly tenantArtifactService = inject(TenantArtifactService);
  private readonly cache = inject(NewCacheService);

  async ngOnInit(): Promise<void> {
    this.placeholder ??= this.isMultiSelect() ? 'Select users' : 'Select user';
    await this.initSelectedOptions(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value && !isEqual(changes.value.previousValue, changes.value.currentValue) && !changes.value.firstChange) {
      let isFirstInit = false;

      if (this.isMultiSelect() && (this.value as string[])?.some(id => !this.filteredUsers?.find(user => user?.value === id))) isFirstInit = true;
      if (!this.isMultiSelect() && !this.filteredUsers?.find(user => user?.value === this.value)) isFirstInit = true;

      this.initSelectedOptions(isFirstInit);
    }
  }

  @Debounce(300)
  onFilter(event?: { originalEvent?: Event; filter: string }): void {
    this.filteredUsers = [];
    this.searchUsers(event, false);
  }

  searchUsers(event?: { originalEvent?: Event; filter: string }, incrementPage = false): void {
    this.isLoading = true;

    if (incrementPage) {
      this.pageNumber++;
    } else {
      this.lastQuery = event?.filter || '';
      this.pageNumber = 1;
    }

    const query = { $regex: this.lastQuery, $options: 'i' };
    const $or = [
      { [GetArtifactAttributeValuePath(GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId))]: query },
      { [GetArtifactAttributeValuePath(GlobalConstants.getValue(GlobalConstantsEnum.emailAttributeId))]: query },
    ];
    const $and = [{ deleted: { $eq: null } }, { $or }, { artifactTypeId: { $oid: GlobalConstants.getValue(GlobalConstantsEnum.profileArtifactTypeId) } }];
    const body = { filter: JSON.stringify({ $and }), limit: this.pageSize, skip: (this.pageNumber - 1) * this.pageSize };

    this.tenantArtifactService.artifactControllerList({ body }).subscribe({
      next: response => {
        this.canLoadMore = Boolean(response.meta?.totalCount - response.meta?.skip! > 0);

        const prepared = response.data
          .map(user => this.mapUserToSelectOption(user))
          .filter(option => !this.filteredUsers.find(user => user?.value === option.value));
        this.filteredUsers = [...(incrementPage ? this.filteredUsers : []), ...prepared];

        if (!incrementPage) {
          const selected = (this.isMultiSelect() ? this.selectedOptions || [] : [this.selectedOption]).filter(Boolean);
          this.filteredUsers = [
            ...(selected as SelectOption<string, string, UserOptionMeta>[]),
            ...this.getTypeOptions(this.lastQuery),
            ...this.filteredUsers.filter(user => !selected.find(selectedUser => selectedUser?.value === user.value)),
          ];
        }

        this.isLoading = false;
      },
    });
  }

  onPanelShow(): void {
    this.filteredUsers = [];

    const container = this.isMultiSelect()
      ? this.multiselect?.itemsViewChild?.nativeElement?.parentElement
      : this.dropdown?.itemsViewChild?.nativeElement?.parentElement;
    container.addEventListener('scroll', () => this.onScroll(container));
  }

  private getTypeOptions(query = ''): SelectOption<string, string, UserOptionMeta>[] {
    if (!this.addUserTypeOptions) return [];

    return [
      new SelectOption(CURRENT_USER_OPTION_LABEL, NonAttributeKeys.CURRENT_USER_ID, new UserOptionMeta()),
      new SelectOption(BLANK_FILTER_OPTION_LABEL, BLANK_OPTION_FILTER_URL_VALUE, new UserOptionMeta()),
      new SelectOption(IS_NOT_EMPTY_OPTION_FILTER_LABEL, IS_NOT_EMPTY_OPTION_FILTER_VALUE, new UserOptionMeta()),
    ].filter(
      option =>
        (!query || option.label.toLowerCase().includes(query.toLowerCase())) &&
        !this.selectedOptions?.find(user => user?.value === option.value) &&
        this.selectedOption?.value !== option.value,
    );
  }

  private onScroll(container: HTMLDivElement): void {
    const threshold = 250;
    const position = container.scrollTop + container.clientHeight;
    const height = container.scrollHeight;

    if (this.canLoadMore && !this.isLoading && height - position < threshold) {
      this.searchUsers(undefined, true);
    }
  }

  private isMultiSelect(): boolean {
    return Boolean(this.attribute?.multipleValues || this.multipleValues);
  }

  private mapUserToSelectOption(user: ArtifactLinkResponseDto): SelectOption<string, string> {
    const label = user.attributes[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string;
    return new SelectOption(label, user.id, new UserOptionMeta(user));
  }

  private async initSelectedOptions(isFirstInit = false): Promise<void> {
    if (!this.value || !this.value.length) {
      this.value = null;
      this.valueChange.emit(null);
      return;
    }

    if ((this.attribute?.multipleValues || this.multipleValues) && Array.isArray(this.value)) {
      const users = await this.cache.data.users.getManyAsync(this.value.filter(userId => !this.userRelatedKeys.includes(userId))).catch(() => []);
      const invalidUsers = this.value.filter(userId => !users.find(user => user.id === userId) && !this.userRelatedKeys.includes(userId));
      const userRelatedOptions = this.value
        .filter(userId => !users.find(user => user.id === userId) && this.userRelatedKeys.includes(userId))
        .map(id => this.getTypeOptions().find(option => option.value === id) || this.getInvalidUserOption(id));

      this.selectedOptions = [
        ...userRelatedOptions,
        ...users.map(user => this.mapUserToSelectOption(user)),
        ...invalidUsers.map(id => this.getInvalidUserOption(id)),
      ];
      isFirstInit && (this.filteredUsers = [...this.selectedOptions]);
      this.valueChange.emit(this.selectedOptions.map(({ value }) => value));
      return;
    }

    if (typeof this.value === 'string' && this.value.length && !this.userRelatedKeys.includes(this.value)) {
      const user = await this.cache.data.users.getAsync(this.value).catch(() => null);
      this.selectedOption = user ? this.mapUserToSelectOption(user) : this.getInvalidUserOption(this.value);
      isFirstInit && (this.filteredUsers = [this.selectedOption, ...this.filteredUsers]);
      this.valueChange.emit(this.selectedOption.value);
    }
  }

  private getInvalidUserOption(id: string): SelectOption<string, string> {
    return new SelectOption(INVALID_USER_LABEL, id, new UserOptionMeta());
  }
}
