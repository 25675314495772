import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';

import { TenantSessionFlowService } from '@api/services';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';

import { SessionFlowListService } from './services/session-flow-list.service';
import { SessionFlowListModel } from './types/session-flow-list.types';

@Component({
  selector: 'app-session-flow-list',
  templateUrl: './session-flow-list.component.html',
  styleUrls: ['./session-flow-list.component.scss'],
  providers: [SessionFlowListService],
})
export class SessionFlowListComponent extends CoreComponent<SessionFlowListService, SessionFlowListModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: SessionFlowListService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly tenantSessionFlowService: TenantSessionFlowService,
  ) {
    super(route, router, translate, new SessionFlowListModel(), service, announcement);
  }

  loadData() {
    return (meta?: Record<string, string>) => lastValueFrom(this.tenantSessionFlowService.sessionFlowControllerList(meta));
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Session flows' }]);
  }
}
