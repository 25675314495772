<p-dialog #modal
          [(visible)]="displayModal"
          [baseZIndex]="2"
          [draggable]="false"
          [modal]="true"
          [resizable]="false"
          [contentStyle]="{minHeight: '250px', overflowY: 'scroll'}"
          [style]="{width: '80vw', position: 'absolute', top: '5rem'}"
          [appendTo]="'body'"
          header="Operation history">
  <app-artifact-history-view [artifactHistory]="artifactHistory"
                             [artifactType]="artifactType"
                             [attributes]="attributes"
                             [dataTypes]="dataTypes">
  </app-artifact-history-view>
</p-dialog>
