import { inject, Injectable } from '@angular/core';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { RuleConditionsUtilService } from '@workflows/services';
import { AbstractWorkflowCondition } from '@workflows/types/conditions/abstract/abstract.condition';
import { WorkflowContainsCondition } from '@workflows/types/conditions/contains.condition';
import { WorkflowDatesCondition } from '@workflows/types/conditions/dates.condition';
import { WorkflowEndsWithCondition } from '@workflows/types/conditions/ends-with.condition';
import { WorkflowIncludesCondition } from '@workflows/types/conditions/includes.condition';
import { WorkflowNotContainsCondition } from '@workflows/types/conditions/not-contains.condition';
import { WorkflowNotEqualsCondition } from '@workflows/types/conditions/not-equals.condition';
import { WorkflowNotIncludesCondition } from '@workflows/types/conditions/not-includes.condition';
import { WorkflowStartsWithCondition } from '@workflows/types/conditions/starts-with.condition';
import { WorkflowConditionType } from '../../types';
import {
  GreaterEqualsThanCondition,
  GreaterThanCondition,
  LowerEqualsThanCondition,
  LowerThanCondition,
  WorkflowEqualsCondition,
} from '../../types/conditions';

@Injectable({ providedIn: 'root' })
export class WorkflowConditionFactory {
  private readonly filterMetadataUtil: FilterMetadataUtil = inject(FilterMetadataUtil);
  private readonly conditionsUtil: RuleConditionsUtilService = inject(RuleConditionsUtilService);

  getWorkflowCondition(type: WorkflowConditionType): AbstractWorkflowCondition {
    switch (type) {
      case WorkflowConditionType.CONTAINS:
        return new WorkflowContainsCondition(this.conditionsUtil);
      case WorkflowConditionType.NOT_CONTAINS:
        return new WorkflowNotContainsCondition(this.conditionsUtil);
      case WorkflowConditionType.INCLUDES:
        return new WorkflowIncludesCondition(this.conditionsUtil);
      case WorkflowConditionType.NOT_INCLUDES:
        return new WorkflowNotIncludesCondition(this.conditionsUtil);
      case WorkflowConditionType.EQUALS:
        return new WorkflowEqualsCondition(this.conditionsUtil);
      case WorkflowConditionType.NOT_EQUALS:
        return new WorkflowNotEqualsCondition(this.conditionsUtil);
      case WorkflowConditionType.GREATER_THAN:
        return new GreaterThanCondition(this.conditionsUtil);
      case WorkflowConditionType.GREATER_OR_EQUALS_THAN:
        return new GreaterEqualsThanCondition(this.conditionsUtil);
      case WorkflowConditionType.LOWER_THAN:
        return new LowerThanCondition(this.conditionsUtil);
      case WorkflowConditionType.LOWER_OR_EQUALS_THAN:
        return new LowerEqualsThanCondition(this.conditionsUtil);
      case WorkflowConditionType.STARTS_WITH:
        return new WorkflowStartsWithCondition(this.conditionsUtil);
      case WorkflowConditionType.ENDS_WITH:
        return new WorkflowEndsWithCondition(this.conditionsUtil);
      case WorkflowConditionType.DATES:
        return new WorkflowDatesCondition(this.conditionsUtil, this.filterMetadataUtil);
    }
  }
}
