import { RuleConditionsUtilService, WorkflowConditionType } from '../../index';
import { AbstractWorkflowComparableCondition } from './abstract/abstract-comparable.condition';

export class GreaterEqualsThanCondition extends AbstractWorkflowComparableCondition {
  constructor(conditionsUtil: RuleConditionsUtilService) {
    super(conditionsUtil);
    this.type = WorkflowConditionType.GREATER_THAN;
  }

  isMet(source: string | number | Date, value: string | number | Date): boolean {
    return source >= value;
  }
}
