import { Injectable } from '@angular/core';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { Constants } from '@shared/constants/constants';
import {
  IsBoolean,
  IsDate,
  IsDateTime,
  IsDecimal,
  IsEnumerated,
  IsFile,
  IsHtml,
  IsHyperlink,
  IsInteger,
  IsText,
  IsTime,
  IsUser,
} from '@shared/methods/data-type.methods';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { BooleanFilterService } from '@widgets/shared/components/artifact-filters/components/boolean-filter/service/boolean-filter.service';
import { DateTimeFilterService } from '@widgets/shared/components/artifact-filters/components/date-time-filter/service/date-time-filter.service';
import { DateTimeFilter } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter.types';
import { EnumFilterService } from '@widgets/shared/components/artifact-filters/components/enum-filter/services/enum-filter.service';
import { EnumFilterValue } from '@widgets/shared/components/artifact-filters/components/enum-filter/types/enum-filter.value';
import { FileFilterService } from '@widgets/shared/components/artifact-filters/components/file-filter/service/file-filter.service';
import { LinkFilterService } from '@widgets/shared/components/artifact-filters/components/link-filter/service/link-filter.service';
import { LinkFilterTypes } from '@widgets/shared/components/artifact-filters/components/link-filter/types/link-filter.types';
import { NumberFilterService } from '@widgets/shared/components/artifact-filters/components/number-filter/service/number-filter.service';
import { NumberFilter } from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter.types';
import { TextFilterService } from '@widgets/shared/components/artifact-filters/components/text-filter/service/text-filter.service';
import { TextFilter } from '@widgets/shared/components/artifact-filters/components/text-filter/types/text-filter.types';
import { UserFilterService } from '@widgets/shared/components/artifact-filters/components/user-filter/service/user-filter.service';
import { FilterTypeDetectionService } from '@widgets/shared/components/artifact-filters/services/filter-type-detection.service';
import { ArtifactFilter, ArtifactFilterType } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Injectable({
  providedIn: 'root',
})
export class ArtifactFiltersService {
  private systemIds: string[] = [
    NonAttributeKeys.CREATED_ON_ID,
    NonAttributeKeys.UPDATED_ON_ID,
    NonAttributeKeys.CREATED_BY_ID,
    NonAttributeKeys.UPDATED_BY_ID,
  ];

  constructor(
    private readonly filterTypeDetectionService: FilterTypeDetectionService,
    private readonly numberService: NumberFilterService,
    private readonly enumService: EnumFilterService,
    private readonly userService: UserFilterService,
    private readonly textService: TextFilterService,
    private readonly boolService: BooleanFilterService,
    private readonly fileService: FileFilterService,
    private readonly linkService: LinkFilterService,
    private readonly dateTimeService: DateTimeFilterService,
  ) {}

  async getAttributesAndLinksQuery(filters: ArtifactFilter[]): Promise<any[]> {
    const $and = [];

    for (const filter of filters) {
      const newParam = filter.isLinkFilter ? await this.getLinkQuery(filter) : this.getQuery(filter);

      if (newParam && Object.keys(newParam).length) {
        $and.push(newParam);
      }
    }

    return $and;
  }

  async getLinkQuery(filter: ArtifactFilter): Promise<any> {
    return await this.linkService.getQuery(filter);
  }

  async getFilterValueFromString(
    str: string,
    dataType: NewDataType,
    systemAttributeKey?: string,
  ): Promise<NumberFilter | EnumFilterValue | TextFilter | DateTimeFilter | SelectOption<string, string>[] | string | null> {
    if (systemAttributeKey && NonAttributeKeys.isUserSpecificAttributeKey(systemAttributeKey)) {
      return this.userService.getFilterValueFromString(str);
    }

    if (systemAttributeKey && NonAttributeKeys.isDateSpecificAttributeKey(systemAttributeKey)) {
      return this.dateTimeService.getFilterValueFromString(str);
    }

    if (!dataType || !dataType.kind || !dataType.baseDataType) {
      return null;
    }

    if (IsEnumerated(dataType.kind)) {
      return this.enumService.getFilterValueFromString(str, dataType);
    }

    if (IsUser(dataType.baseDataType)) {
      return this.userService.getFilterValueFromString(str);
    }

    if (IsInteger(dataType.baseDataType) || IsDecimal(dataType.baseDataType)) {
      return this.numberService.getFilterValueFromString(str);
    }

    if (IsText(dataType.baseDataType) || IsHyperlink(dataType.baseDataType)) {
      return this.textService.getFilterValueFromString(str);
    }

    if (IsBoolean(dataType.baseDataType)) {
      return this.boolService.getFilterValueFromString(str);
    }

    if (IsDateTime(dataType.baseDataType) || IsDate(dataType.baseDataType)) {
      return this.dateTimeService.getFilterValueFromString(str);
    }

    return null;
  }

  getQuery(filter: ArtifactFilter): any {
    if (this.filterTypeDetectionService.isFolderPath(filter)) {
      return this.textService.getQuery(filter, true);
    }

    if (filter?.type === ArtifactFilterType.system) {
      return [NonAttributeKeys.UPDATED_BY_LABEL, NonAttributeKeys.CREATED_BY_LABEL].includes(filter.name)
        ? this.userService.getQuery(filter)
        : this.dateTimeService.getQuery(filter);
    }

    if (!filter.dataType || !filter.dataType.kind || !filter.dataType.baseDataType) {
      return null;
    }

    if (IsEnumerated(filter.dataType.kind)) {
      return this.enumService.getQuery(filter);
    }

    if (IsInteger(filter.dataType.baseDataType) || IsDecimal(filter.dataType.baseDataType)) {
      return this.numberService.getQuery(filter);
    }

    if (IsText(filter.dataType.baseDataType) || IsHyperlink(filter.dataType.baseDataType) || IsHtml(filter.dataType.baseDataType)) {
      return this.textService.getQuery(filter, false);
    }

    if (IsBoolean(filter.dataType.baseDataType)) {
      return this.boolService.getQuery(filter);
    }

    if (IsDateTime(filter.dataType.baseDataType) || IsDate(filter.dataType.baseDataType) || IsTime(filter.dataType.baseDataType)) {
      return this.dateTimeService.getQuery(filter);
    }

    if (IsUser(filter.dataType.baseDataType)) {
      return this.userService.getQuery(filter);
    }

    if (IsFile(filter.dataType.baseDataType)) {
      return this.fileService.getQuery(filter);
    }
  }

  getSystemAttributes(): any {
    return Constants.artifactCustomAttributes
      .filter(systemAttr =>
        [
          NonAttributeKeys.FOLDER_PATH,
          NonAttributeKeys.CREATED_ON,
          NonAttributeKeys.UPDATED_ON,
          NonAttributeKeys.CREATED_BY,
          NonAttributeKeys.UPDATED_BY,
        ].includes(systemAttr.code),
      )
      .map(systemAttr => {
        this.systemIds.push(systemAttr.value);
        return new NewAttribute({
          name: systemAttr.name,
          id: systemAttr.value,
          systemAttributeKey: systemAttr.code,
        } as Partial<AttributeResponseDto>);
      });
  }

  isSystemAttribute(attributeId: string): boolean {
    return this.systemIds.includes(attributeId);
  }

  isDateTimeSystemAttribute(attributeId: string): boolean {
    return [NonAttributeKeys.CREATED_ON_ID, NonAttributeKeys.UPDATED_ON_ID].includes(attributeId);
  }

  isUserSystemAttribute(attributeId: string): boolean {
    return [NonAttributeKeys.CREATED_BY_ID, NonAttributeKeys.UPDATED_BY_ID].includes(attributeId);
  }

  updateFilter(filter: ArtifactFilter, attributeOrLinkType: NewAttribute | NewClientAttribute | DirectionalLinkType, dataTypes: NewDataType[]): void {
    if (!filter) {
      return;
    }

    filter.dataType = dataTypes.find(({ id }: NewDataType) => id === (attributeOrLinkType as any)?.dataTypeId) || null;
    filter.attribute = attributeOrLinkType;
    filter.value = null;

    if (NonAttributeKeys.isDateSpecificAttributeId(attributeOrLinkType.id)) {
      const systemAttribute = Constants.artifactCustomAttributes.find(attr => attr.value === attributeOrLinkType.id);
      filter.name = systemAttribute?.name || '';
      filter.systemAttributeCode = systemAttribute?.code || '';
    } else {
      filter.name = attributeOrLinkType.name || Constants.artifactCustomAttributes.find(attr => attr.value === attributeOrLinkType.id)?.name || '';
    }

    filter.type = null;

    if (attributeOrLinkType instanceof DirectionalLinkType) {
      filter.type = ArtifactFilterType.link;
      filter.linkDirection = attributeOrLinkType.direction;
      filter.value = new LinkFilterTypes();
    }

    if (filter.dataType?.kind === DataTypeKind.enumerated) {
      filter.value = new EnumFilterValue();
    }

    const isSystemAttributeFilter = this.systemIds.includes(filter.attributeId);

    if (isSystemAttributeFilter) {
      filter.type = ArtifactFilterType.system;
    }
  }
}
