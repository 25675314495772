import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { AnnouncementService } from '../services/announcement.service';
import { NewArtifactTypeClientAttribute } from '../types/artifact-type.types';
import { NewArtifact } from '../types/artifact.types';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '../types/attribute.types';
import { NewDataType } from '../types/data-type.types';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';

export const CheckMandatoryFields = (
  artifact: NewArtifact,
  attributesMap: Record<string, NewAttribute>,
  dataTypesMap: Record<string, NewDataType>,
  announcement: AnnouncementService,
): boolean => {
  const fields: any = [];
  let hasMandatoryValues = true;
  const addMandatoryValue = (name: string): void => {
    fields.push(name);
    hasMandatoryValues = false;
  };

  artifact.clientAttributes.forEach(({ id, value, isMandatory, valueFilled }) => {
    if (isMandatory) {
      const attribute = attributesMap[id];
      const dataType = dataTypesMap[attribute.dataTypeId];

      if (dataType?.isCounter) {
        return;
      }

      if (dataType.baseDataType === BaseDataType.boolean) {
        if (!['true', 'false'].includes(String(value))) addMandatoryValue(attribute.name);
      } else {
        if ((!value || value?.length === 0) && !valueFilled) addMandatoryValue(attribute.name);
      }
    }
  });

  !hasMandatoryValues && announcement.error('Mandatory fields: {{fields}} are not set', { fields: fields.join(', ') });

  return hasMandatoryValues;
};

export const GetDataTypeFromClientAttribute = (
  clientAttribute: NewArtifactTypeClientAttribute | NewClientAttribute,
  attributes: Record<string, NewAttribute>,
  dataTypes: Record<string, NewDataType>,
): NewDataType | null => {
  const attribute = GetAttributeFromClientAttribute(clientAttribute, attributes);
  if (attribute) return GetDataTypeFromDataTypeId(attribute.dataTypeId, dataTypes);
  else return null;
};

export const formatArtifactSection = (artifact: ArtifactResponseDto): string => {
  return artifact.moduleData
    ? artifact.moduleData.isHeading
      ? `${artifact.moduleData.level.join('.')}`
      : `${artifact.moduleData.level.slice(0, artifact.moduleData.level.length > 1 ? artifact.moduleData.level.length - 1 : 1).join('.')}-${
          artifact.moduleData.sequenceInParent + 1
        }`
    : '';
};

// TODO: you really need this method??
export const GetDataTypeFromDataTypeId = (id: string, dataTypes: Record<string, NewDataType>): NewDataType => {
  return dataTypes[id];
};

// TODO: you really need this method??
export const GetAttributeFromClientAttribute = (
  attribute: NewArtifactTypeClientAttribute | NewClientAttribute | undefined,
  attributes: Record<string, NewAttribute>,
): NewAttribute | null => {
  return attribute && attributes[attribute.id] ? attributes[attribute.id] : null;
};

export const GetNonAttributeValueFromArtifact = (nonAttributeId: NonAttributeKeys | string, artifact: NewArtifact): string | null => {
  switch (nonAttributeId) {
    case NonAttributeKeys.CREATED_BY:
      return artifact.created.by;
    case NonAttributeKeys.CREATED_ON:
      return artifact.created.on;
    case NonAttributeKeys.UPDATED_BY:
      return artifact.updated.by;
    case NonAttributeKeys.UPDATED_ON:
      return artifact.updated.on;
    case NonAttributeKeys.FOLDER_PATH:
      return artifact.folderPath;
    default:
      return null;
  }
};
