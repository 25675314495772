import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UserAutocompleteComponent } from '@shared/components/user-autocomplete/user-autocomplete.component';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { BooleanFilterComponent } from '@widgets/shared/components/artifact-filters/components/boolean-filter/boolean-filter.component';
import { DateTimeFilterComponent } from '@widgets/shared/components/artifact-filters/components/date-time-filter/date-time-filter.component';
import { IsTimeFilterCalendarTypePipe } from '@widgets/shared/components/artifact-filters/components/date-time-filter/pipes/is-time-filter-type';
import { EnumFilterComponent } from '@widgets/shared/components/artifact-filters/components/enum-filter/enum-filter.component';
import { FileFilterComponent } from '@widgets/shared/components/artifact-filters/components/file-filter/file-filter.component';
import { LinkFilterComponent } from '@widgets/shared/components/artifact-filters/components/link-filter/link-filter.component';
import { NumberFilterComponent } from '@widgets/shared/components/artifact-filters/components/number-filter/number-filter.component';
import { TextFilterComponent } from '@widgets/shared/components/artifact-filters/components/text-filter/text-filter.component';
import { UserFilterComponent } from '@widgets/shared/components/artifact-filters/components/user-filter/user-filter.component';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ArtifactFiltersComponent } from './artifact-filters.component';
import { IsDatetimeFilterCalendarTypePipe } from './components/date-time-filter/pipes/is-datetime-filter-calendar-type.pipe';
import { IsDatetimeFilterNumericTypePipe } from './components/date-time-filter/pipes/is-datetime-filter-numeric-type.pipe';
import { IsDatetimeFilterRangeTypePipe } from './components/date-time-filter/pipes/is-datetime-filter-range-type.pipe';

@NgModule({
  declarations: [
    ArtifactFiltersComponent,
    NumberFilterComponent,
    EnumFilterComponent,
    TextFilterComponent,
    BooleanFilterComponent,
    DateTimeFilterComponent,
    UserFilterComponent,
    FileFilterComponent,
    LinkFilterComponent,
    IsDatetimeFilterCalendarTypePipe,
    IsTimeFilterCalendarTypePipe,
    IsDatetimeFilterNumericTypePipe,
    IsDatetimeFilterRangeTypePipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ElvisSharedModule,
    CommonPipesModule,
    DropdownModule,
    AccordionModule,
    FormsModule,
    MultiSelectModule,
    CheckboxModule,
    ButtonModule,
    CalendarModule,
    InputNumberModule,
    ProgressSpinnerModule,
    RippleModule,
    InputTextModule,
    TriStateCheckboxModule,
    UserAutocompleteComponent,
  ],
  exports: [ArtifactFiltersComponent, DateTimeFilterComponent, BooleanFilterComponent, UserFilterComponent, NumberFilterComponent],
})
export class ArtifactFiltersModule {}
