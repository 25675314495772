import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { PageBuilderGraphicalEventsService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-events.service';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { UrlMethods } from '@shared/methods/url.methods';
import { CustomScriptsUtil } from '@shared/utils/custom-scripts.util';
import { ReplaceRuntimeVariablesService } from '@widgets/shared/services/replace-runtime-variables.service';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { RuleTriggerEventHandlerService } from '@workflows/services';
import { WorkflowTriggerPageLoad } from '@workflows/types';
import { CoreComponent } from '../../core/components/core.component';
import { CoreParams } from '../../core/types/core.types';
import { AnnouncementService } from '../../services/announcement.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ServerSideEventService } from '../../services/server-side-event.service';
import { StickyBlockPartDirective } from './directives/sticky-block-part.directive';
import { StickySectionDirective } from './directives/sticky-section.directive';
import { PageService } from './services/page.service';
import { StickyPageElementService } from './services/sticky-page-element.service';
import { PageModel } from './types/page.types';

@Component({
  selector: 'app-page-preview',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  providers: [PageService, StickyPageElementService, PageBuilderGraphicalEventsService, PageBuilderGraphicalDragDropService],
})
export class PageComponent extends CoreComponent<PageService, PageModel> implements AfterViewInit {
  @ViewChildren(StickySectionDirective) sectionDirectives: QueryList<StickySectionDirective>;
  @ViewChildren(StickyBlockPartDirective) partDirectives: QueryList<StickyBlockPartDirective>;

  readonly widgetType = WidgetType;
  rowContainerClass = '';

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    announcement: AnnouncementService,
    public service: PageService,
    public readonly serverSideEventService: ServerSideEventService,
    public readonly replaceRuntimeVariablesService: ReplaceRuntimeVariablesService,
    private readonly stickyPageElementService: StickyPageElementService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly localStorageService: LocalStorageService,
    private readonly cache: NewCacheService,
    private ruleTriggerEventHandler: RuleTriggerEventHandlerService,
  ) {
    super(route, router, translate, new PageModel(), service, announcement, new CoreParams({ initOnQueryParamChange: false }));
  }

  ngAfterViewInit(): void {
    this.stickyPageElementService.page = this.m.page;
    this.stickyPageElementService.sectionDirectives = this.sectionDirectives;
    this.stickyPageElementService.partDirectives = this.partDirectives;
  }

  async onInit(): Promise<void> {
    await super.onInit();
    this.s.pageHelper.isOnPage = true;
    this.breadcrumbService.setItems([{ label: 'Preview' }]);
    this.serverSideEventService.setEventSource();
    this.ruleTriggerEventHandler.notifyTriggerEvent({
      pageId: this.m.page.id,
      definition: new WorkflowTriggerPageLoad(),
    });
  }

  async onInitCb(): Promise<void> {
    if (this.isFirstCall) return;

    const pageChanged = this.pageChanged(this.url, this.router.url);

    this.url = this.router.url;

    if (pageChanged) {
      await this.s.init(this, this.m);
      this.onInit();
    }
  }

  onDestroy(): void {
    super.onDestroy();

    this.s.pageHelper.usePublicToken = false;
    this.s.pageHelper.isOnPage = false;
    this.localStorageService.clearRedundantData();
    this.s.setPageMetaData();
    this.s.removePageMetaElements();
    this.s.pageHelper.resetBackgroundOnBody();
    CustomScriptsUtil.removeScripts(this.m.page.scriptModels || []);
  }

  async navigateToEdit(): Promise<void> {
    const path = this.url.split('?')[0] || '';
    const search = window.location.search ? window.location.search + `&prevPageUrl=${path}` : `prevPageUrl=${path}`;
    UrlMethods.windowOpen(`/admin/page-builder/${this.m.page.id}`, search);
  }

  private pageChanged(oldUrl: string, newUrl: string): boolean {
    const oldIdOrAlias = UrlMethods.getPageIdOrAliasFromUrl(oldUrl);
    const newIdOrAlias = UrlMethods.getPageIdOrAliasFromUrl(newUrl);

    return oldIdOrAlias !== newIdOrAlias;
  }
}
