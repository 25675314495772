import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationResponseDto, ArtifactTypeResponseDto, AvrWidgetModelDto } from '@api/models';
import { TenantArtifactService } from '@api/services';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AvrServices } from '@shared/services/artifact-visual-representation/index.avr-services';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { SelectOption } from '@shared/types/shared.types';
import { DbEntityCachedData } from '@shared/utils/db-entity-cached-data.subject';
import { TranslateUtil } from '@shared/utils/translateUtil';

import { AvrWidgetComponent } from '@widgets/avr-widget/avr-widget.component';
import { AbstractAvrWidgetArtifactSourceService } from '@widgets/avr-widget/services/artifact-source-services/abstract.artifact-source.service';
import { DynamicArtifactAvrWidgetArtifactSourceService } from '@widgets/avr-widget/services/artifact-source-services/dynamic-artifact.artifact-source.service';
import { ListItemAvrWidgetArtifactSourceService } from '@widgets/avr-widget/services/artifact-source-services/list-item.artifact-source.service';
import { StaticArtifactAvrWidgetArtifactSourceService } from '@widgets/avr-widget/services/artifact-source-services/static-artifact.artifact-source.service';
import { AvrTypesSettingsService } from '@widgets/avr-widget/services/avr-types-services/index.avr-types-services';
import { AvrActionTypes, AvrArtifactSources } from '@widgets/avr-widget/types/avr-widget-settings.types';
import { AvrWidgetModel, AvrWidgetValue } from '@widgets/avr-widget/types/avr-widget.types';

import { saveAs } from 'file-saver';
import { BehaviorSubject, concatMap, lastValueFrom, Subject, Subscription, take } from 'rxjs';

@Injectable()
export class AvrWidgetService {
  c: AvrWidgetComponent;
  m: AvrWidgetModel;
  init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private serializedSetArtifact = new Subject<void>();

  constructor(
    public readonly avrTypesServices: AvrTypesSettingsService,
    private readonly listItemService: ListItemAvrWidgetArtifactSourceService,
    private readonly staticArtifactService: StaticArtifactAvrWidgetArtifactSourceService,
    private readonly dynamicArtifactService: DynamicArtifactAvrWidgetArtifactSourceService,
    private readonly cache: NewCacheService,
    private readonly translateUtil: TranslateUtil,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly avrServices: AvrServices,
  ) {}

  async init(context: AvrWidgetComponent, dto: AvrWidgetModelDto | null): Promise<void> {
    this.prepareContext(context, dto);
    this.setContextAndModel(context);
    context.isLayoutMode && (await this.initOptions());
    await this.initAvrTypesServices(this.m);
    await this.initArtifactSourceServices(context);
    context.isLayoutMode && this.setAvrFileType();
    this.init$.next(true);
  }

  async onArtifactSourceChange(): Promise<void> {
    await this.commonBeforeArtifactSourceChange();
    await this.getArtifactSourceService()?.onArtifactSourceChange();
  }

  async onSelectedArtifactChange(): Promise<void> {
    await this.commonBeforeSelectedArtifactChange();
    await this.getArtifactSourceService()?.onSelectedArtifactChange();
  }

  async onArtifactTypeChange(): Promise<void> {
    await this.commonBeforeArtifactTypeChange();
    await this.getArtifactSourceService()?.onArtifactTypeChange();
    this.disableAvrTypes();
  }

  async onArtifactListeningKeyChange(): Promise<void> {
    this.commonBeforeArtifactListeningKeyChange();
    await this.getArtifactSourceService()?.onArtifactListeningKeyChange();
  }

  async onAvrTypeChange(): Promise<void> {
    await this.getArtifactSourceService()?.onAvrTypeChange();
    await this.initAvrTypesServices(this.m);
  }

  setCurrentArtifact(artifact: NewArtifact | null): void {
    // TODO: This will require refactoring, but now it works reliably
    this.serializedSetArtifact
      .pipe(
        concatMap(async () => {
          this.m.currentArtifact = artifact;
          if (this.m.currentArtifact?.id && this.m.settings.response.avrType) {
            this.m.avrBlob = null;
            this.m.avrBlobError = '';
            const avrType = this.m.settings.response.avrType;
            const additionalQueryParams = { [avrType]: JSON.stringify(this.avrTypesServices.getAvrTypesServices(avrType).getAdditionalQueryParams()) };
            if (this.m.avrBlobError !== '') return;
            await lastValueFrom(
              this.tenantArtifactService.artifactControllerGetAvr$Response({
                id: this.m.currentArtifact?.id,
                avrType,
                ...additionalQueryParams,
              }),
            )
              .then(avrBlob => {
                const header = avrBlob.headers.get('content-disposition') || '';
                this.m.avrBlob = {
                  file: avrBlob.body,
                  fileName: header.slice(header.search('filename="') + 10, -1) || `${this.m.settings.response.avrType || 'AVR'}`,
                };
              })
              .catch(async (error: HttpErrorResponse) => {
                this.m.avrBlob = null;
                const fields = JSON.parse(await error.error.text()).fields;
                switch (error.status) {
                  case 400:
                    this.m.avrBlobError = 'Artifact Visual Representation is not ready, try later';
                    break;
                  case 405:
                    this.m.avrBlobError = `AVR generation failed: '${Object.values(fields)[0]}'`;
                    break;
                }
              });
          } else {
            this.m.avrBlob = null;
          }
        }),
        take(1),
      )
      .subscribe();

    this.serializedSetArtifact.next();
  }

  isArtifactSource(artifactSource: AvrArtifactSources): boolean {
    return this.getArtifactSourceService()?.artifactSource === artifactSource;
  }

  isSettingStep(settingStep: number): boolean {
    return this.m.settingsStep >= settingStep;
  }

  downloadFile(): void {
    this.m.avrBlob && saveAs(this.m.avrBlob.file, this.m.avrBlob.fileName);
  }

  setAvrFileType(): void {
    const getOutputType = (): AvrOutputTypes | null => {
      const avrType = this.m.settings.response.avrType;
      if (!avrType) return null;
      return (this.m.settings.response.avrTypeSettings[avrType]?.outputType ?? null) as AvrOutputTypes | null;
    };

    this.m.currentAvrFileType = getOutputType();
  }

  addNonAccessibleOption(selectOptions: SelectOption<string, any>[], index?: number, value: any = null): void {
    selectOptions.unshift(new SelectOption(`[Inaccessible option${index ? ` (${index})` : ''}]`, value, null, true));
  }

  private async commonBeforeArtifactSourceChange(): Promise<void> {
    (this.getArtifactSourceService(AvrArtifactSources.dynamicArtifact) as DynamicArtifactAvrWidgetArtifactSourceService).stopListenToUrl.next();
  }

  private async commonBeforeSelectedArtifactChange(): Promise<void> {
    return;
  }

  private async commonBeforeArtifactTypeChange(): Promise<void> {
    this.m.currentArtifactTypeOption =
      this.m.options.artifactTypesOptions.find(artifactTypeOption => artifactTypeOption.value.id === this.m.settings.dataSource.artifactTypeId) ?? null;
    return;
  }

  private async commonBeforeArtifactListeningKeyChange(): Promise<void> {
    return;
  }

  private disableAvrTypes(): void {
    if (this.m.settings.dataSource.artifactTypeId === null) return;
    const selectedArtifactType = this.m.currentArtifactTypeOption?.value || null;
    const supportedAvrTypes = Object.keys(selectedArtifactType?.avrMapper || {});

    const selectedOption = this.m.options.avrTypesOptions.filter(avrTypeOption => {
      avrTypeOption.disabled = !supportedAvrTypes.includes(avrTypeOption.value);
      return avrTypeOption.value === this.m.settings.response.avrType;
    })[0];
    if (selectedOption && selectedOption.disabled === true) {
      throw new Error('Selected inaccessible AVR type');
    }
  }

  private getArtifactSourceService(artifactSource?: AvrArtifactSources): AbstractAvrWidgetArtifactSourceService | null {
    switch (artifactSource || this.m.settings.dataSource.artifactSource) {
      case AvrArtifactSources.listItem:
        return this.listItemService;
      case AvrArtifactSources.staticArtifact:
        return this.staticArtifactService;
      case AvrArtifactSources.dynamicArtifact:
        return this.dynamicArtifactService;
      default:
        return null;
    }
  }

  private prepareContext(context: AvrWidgetComponent, dto: AvrWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new AvrWidgetValue();
    }
    if (dto) context.widget.value.model = new AvrWidgetModel(dto);

    if (!context.widget.value.model) context.widget.value.model = new AvrWidgetModel();
  }

  private setContextAndModel(context: AvrWidgetComponent): void {
    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
  }

  private async initAvrTypesServices(model: AvrWidgetModel): Promise<void> {
    await Promise.all(Object.values(AvrTypes).map(avrType => lastValueFrom(this.avrTypesServices.getAvrTypesServices(avrType).init$(model, this))));
    this.disableAvrTypes();
  }

  private async initArtifactSourceServices(context: AvrWidgetComponent): Promise<void> {
    await Promise.all([this.staticArtifactService.init(context), this.dynamicArtifactService.init(context), this.listItemService.init(context)]);
  }

  private async initOptions(): Promise<void> {
    const getApplicationsSubscription = (applications: DbEntityCachedData<ApplicationResponseDto>): Promise<Subscription> => {
      return new Promise<Subscription>(resolveSubscription => {
        let subscription: Subscription | null = null;
        new Promise<void>(resolve => {
          subscription = applications.subscribe(applications => {
            this.m.options.applications.setList(applications as ApplicationResponseDto[], ID_KEY);
            resolve();
          });
        }).then(() => resolveSubscription(subscription!));
      });
    };

    const getArtifactTypesSubscription = (artifactTypes: DbEntityCachedData<ArtifactTypeResponseDto>): Promise<Subscription> => {
      return new Promise<Subscription>(resolveSubscription => {
        let subscription: Subscription | null = null;
        new Promise<void>(resolve => {
          subscription = artifactTypes.subscribe(artifactTypes => {
            this.m.options.artifactTypesOptions = (artifactTypes as ArtifactTypeResponseDto[]).map(dto => {
              const artifactType = new NewArtifactType(dto);
              const option = new SelectOption<string, NewArtifactType>(artifactType.name, artifactType);
              if (this.m.settings.dataSource.artifactTypeId === dto.id) {
                this.m.currentArtifactTypeOption = option;
              }
              return option;
            });
            resolve();
          });
        }).then(() => resolveSubscription(subscription!));
      });
    };

    const { applications, artifactTypes } = this.cache.data;
    const subscriptions = await Promise.all([getApplicationsSubscription(applications), getArtifactTypesSubscription(artifactTypes)]);

    this.c.registerSubscriptions(subscriptions);

    this.m.options.avrTypesOptions = Object.values(AvrTypes).map(avrType => {
      this.avrServices.getAvrServices(avrType);
      return new SelectOption(this.avrServices.getAvrServices(avrType).readableName, avrType);
    });

    new Promise<SelectOption<string, AvrActionTypes>[]>(resolve => {
      const avrAvtionTypes = Object.values(AvrActionTypes);
      this.translateUtil.getAll(avrAvtionTypes).then(translated => {
        resolve(avrAvtionTypes.map((value, index) => new SelectOption(translated[index], value)));
      });
    }).then(avrResultTypeOptions => (this.m.options.avrActionTypesOptions = avrResultTypeOptions));

    // TODO temporary disable of listItem option
    const listItemOption = this.m.options.artifactSourcesOptions.find(value => value.value === AvrArtifactSources.listItem);
    listItemOption && (listItemOption.disabled = true);
  }
}
