import { WidgetType } from '@widgets/widgets-core/types/widgets.types';

export class CustomEventSettings {
  enable: boolean = true;
  key: string = '';
  payload: any;

  constructor(dto?: CustomEventSettingsDto) {
    dto && Object.assign(this, dto);
  }
}

export class ArtifactCustomEventSettings extends CustomEventSettings {
  payload: WidgetType = WidgetType.artifact;
  event?: PointerEvent;

  constructor(dto?: ArtifactCustomEventSettings) {
    super(dto);
    dto && Object.assign(this, dto);
  }
}

export class MenuCustomEventSettings extends CustomEventSettings {
  payload: WidgetType = WidgetType.menu;
}

export class CustomEventSettingsDto {
  enable: boolean;
  key: string;
  payload: any;
}

export abstract class CustomEventResponse<ActionType> {
  abstract key: string;
  abstract action: ActionType;
}

export class SidebarModalEventResponse extends CustomEventResponse<SidebarWidgetAction> {
  key: string;
  action: SidebarWidgetAction;
}

export class ArtifactWidgetEventResponse extends CustomEventResponse<ArtifactWidgetAction> {
  key: string;
  action: ArtifactWidgetAction;

  constructor(event?: Partial<ArtifactWidgetEventResponse>) {
    super();
    event && Object.assign(this, event);
  }
}

export class MenuWidgetEventResponse extends CustomEventResponse<MenuWidgetAction> {
  key: string;
  action: MenuWidgetAction;

  constructor(event?: Partial<MenuWidgetEventResponse>) {
    super();
    event && Object.assign(this, event);
  }
}

export type WidgetActionType = SidebarWidgetAction | ArtifactWidgetAction | MenuWidgetAction;
export type WidgetEventResponseType = SidebarModalEventResponse | ArtifactWidgetEventResponse | MenuWidgetEventResponse;

export enum SidebarWidgetAction {
  open = 'open',
  close = 'close',
  toggle = 'toggle',
}

export enum ArtifactWidgetAction {
  save = 'save',
  saveAndNotify = 'saveAndNotify',
  edit = 'edit',
  cancel = 'cancel',
  showOperationHistory = 'showOperationHistory',
  showAccessRights = 'showAccessRights',
  delete = 'delete',
}

export enum MenuWidgetAction {
  showSave = 'showSave',
  showSaveAndNotify = 'showSaveAndNotify',
  showEdit = 'showEdit',
  showCancel = 'showCancel',
  showOpenMoreActions = 'showOpenMoreActions',
  showOpenOperationHistory = 'showOpenOperationHistory',
  showOpenAccessRights = 'showOpenAccessRights',
  showDelete = 'showDelete',
  hideSave = 'hideSave',
  hideSaveAndNotify = 'hideSaveAndNotify',
  hideEdit = 'hideEdit',
  hideCancel = 'hideCancel',
  hideOpenMoreActions = 'hideOpenMoreActions',
  hideOpenOperationHistory = 'hideOpenOperationHistory',
  hideOpenAccessRights = 'hideOpenAccessRights',
  hideDelete = 'hideDelete',
}

export class WidgetEventResponseSettingsDto<T extends WidgetEventResponseType> {
  events: T[];
}

export abstract class WidgetEventResponseSettings<T extends WidgetEventResponseType> {
  abstract events: T[]; // TODO: Record would help performance

  abstract addEvent(): void;

  protected constructor(dto?: WidgetEventResponseSettingsDto<T>) {
    dto && this.fromDto(dto);
  }

  fromDto(dto?: WidgetEventResponseSettingsDto<T>): void {
    dto && Object.assign(this, dto);
  }

  toServer(): WidgetEventResponseSettingsDto<T> {
    const events = this.events.filter(e => e.action && e.key);
    return { events };
  }

  removeEvent(index: number): void {
    this.events.splice(index, 1);
  }

  getActionByKey(key: string): WidgetActionType | undefined {
    return this.events.find(event => event.key === key)?.action;
  }
}
