import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { StylesDto } from '../../../private/pages/page-management/page-builder-graphical/types/styles-dto';
import { StyleFormModule } from '../../../widgets/shared/components/style-form.module';
import { BorderSideEnum, BorderStylesEnum, StyleOptions } from '../../../widgets/shared/types/style.types';

@Component({
  selector: 'app-shared-border-settings',
  standalone: true,
  imports: [DropdownModule, InputTextModule, PaginatorModule, StyleFormModule],
  templateUrl: './shared-border-settings.component.html',
  styleUrl: './shared-border-settings.component.scss',
})
export class SharedBorderSettingsComponent {
  @Input({ required: true }) options: StyleOptions;
  @Input({ required: true }) styles: Partial<StylesDto>;
  @Output() onChange = new EventEmitter();

  checkIfColorEmptyMap: Record<BorderSideEnum, () => void> = {
    [BorderSideEnum.top]: () => (this.styles.borderTopStyle = BorderStylesEnum.none),
    [BorderSideEnum.left]: () => (this.styles.borderLeftStyle = BorderStylesEnum.none),
    [BorderSideEnum.right]: () => (this.styles.borderRightStyle = BorderStylesEnum.none),
    [BorderSideEnum.bottom]: () => (this.styles.borderBottomStyle = BorderStylesEnum.none),
  };
  protected readonly borderSideEnum = BorderSideEnum;

  checkIfColorEmpty(color: string, side: BorderSideEnum): void {
    !color && this.checkIfColorEmptyMap[side]();
  }

  isChange(): void {
    this.onChange.emit();
  }
}
