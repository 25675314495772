<p-accordion *ngIf="showAccordeon else content">
  <p-accordionTab [header]="'Additional actions'">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </p-accordionTab>
</p-accordion>

<ng-template #content>
  <ng-container *ngIf="customEvent.enable">
    <p-floatLabel>
      <input pInputText id="custom-event" [(ngModel)]="customEvent.key" (focusout)="onKeyChange()" />
      <label for="custom-event" translate>Event key</label>
    </p-floatLabel>
  </ng-container>
</ng-template>
