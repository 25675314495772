import { Injectable } from '@angular/core';
import { GetArtifactAttributeValuePath } from '@shared/methods/client-attribute.methods';
import { ArtifactFilter } from '../../../types/artifact-filter.types';

@Injectable({
  providedIn: 'root',
})
export class FileFilterService {
  getQuery(filter: ArtifactFilter): any {
    const key = GetArtifactAttributeValuePath(filter.attributeId);
    return filter.value && filter.value.isEnable
      ? {
          [key]: { [this.getSign(filter.value.value)]: '' },
        }
      : null;
  }

  private getSign(value: string): string {
    return value.toString() === 'true' ? '$ne' : '$eq';
  }
}
