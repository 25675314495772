import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { WorkflowOwnerType, WorkflowType } from '@workflows/types';

export class WorkflowListModel {
  options = new WorkflowListOptions();
  columns: TableColumn[];
}

export class WorkflowListOptions {
  workflowTypeOptions: SelectOption<string, WorkflowType>[] = [];
  workflowOwnerTypeOptions: SelectOption<string, WorkflowOwnerType>[] = [];
}
