import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Constants, ID_KEY } from '@shared/constants/constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { DbEntityCachedData } from '@shared/utils/db-entity-cached-data.subject';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { Subscription } from 'rxjs';
import { ListMatrixWidgetComponent } from '../list-matrix-widget.component';
import { ListMatrixWidgetModel } from '../types/list-matrix-widget-model';
import { ListMatrixWidgetOptions } from '../types/list-matrix-widget-options';

export abstract class ListMatrixWidgetOptionsService {
  m: ListMatrixWidgetModel;
  protected c: ListMatrixWidgetComponent;
  protected options: ListMatrixWidgetOptions = new ListMatrixWidgetOptions();

  protected constructor(protected readonly cache: NewCacheService) {
    const customAttributesWithoutArtifactType: NewAttribute[] = Constants.artifactCustomAttributes
      .filter(({ code }: { code: string; name: string; value: string }) =>
        [
          NonAttributeKeys.CREATED_ON,
          NonAttributeKeys.UPDATED_ON,
          NonAttributeKeys.CREATED_BY,
          NonAttributeKeys.UPDATED_BY,
          NonAttributeKeys.FOLDER_PATH,
        ].includes(code),
      )
      .map((attribute: { code: string; name: string; value: string }) => {
        return new NewAttribute({
          id: attribute.value,
          name: attribute.name,
          systemAttributeKey: attribute.code,
        } as Partial<AttributeResponseDto>);
      });

    this.options.customAttributes.setList(customAttributesWithoutArtifactType);
  }

  protected async loadOptions(): Promise<void> {
    const { applications, artifactTypes, attributes, dataTypes, pages, linkTypes } = this.cache.data;
    const subscriptions = await Promise.all([
      this.getApplicationsSubscription(applications),
      this.getArtifactTypesSubscription(artifactTypes),
      this.getAttributesSubscription(attributes),
      this.getDataTypesSubscription(dataTypes),
      this.getPagesSubscription(pages),
      this.getLinkTypesSubscription(linkTypes),
    ]);

    this.c.registerSubscriptions(subscriptions);
  }

  protected setSelectOptionsFromAttributesAndLinkTypes(): void {
    const attributeOptions = this.getAttributeOptions();
    const customAttributeOptions = this.getCustomAttributeOptions();
    const linkTypeOptions = this.getDirectionalLinkTypeOptions();

    this.options.combinedAttributes = [...attributeOptions, ...customAttributeOptions].map(option => option.value);
    this.options.combinedAttributeOptions = [...attributeOptions, ...customAttributeOptions];

    this.options.combinedAttributeAndLinkOptions = [...attributeOptions, ...customAttributeOptions, ...linkTypeOptions];
  }

  private getApplicationsSubscription(applications: DbEntityCachedData<ApplicationResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = applications.subscribe(applications => {
        this.options.applications.setList(applications as ApplicationResponseDto[], ID_KEY);
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getArtifactTypesSubscription(artifactTypes: DbEntityCachedData<ArtifactTypeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = artifactTypes.subscribe(artifactTypes => {
        this.options.artifactTypes.setList(
          (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
          ID_KEY,
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getAttributesSubscription(attributes: DbEntityCachedData<AttributeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = attributes.subscribe(attributes => {
        this.options.attributes.setList(
          (attributes as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
          'id',
        );
        this.options.combinedAttributes = [...this.options.attributes.list];
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getDataTypesSubscription(dataTypes: DbEntityCachedData<DataTypeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = dataTypes.subscribe(dataTypes => {
        this.options.dataTypes.setList(
          (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
          ID_KEY,
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getLinkTypesSubscription(linkTypes: DbEntityCachedData<LinkTypeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = linkTypes.subscribe(linkTypes => {
        const directionalLinkTypes: DirectionalLinkType[] = (linkTypes as LinkTypeResponseDto[])
          .map(dto => new LinkType(dto))
          .reduce((directionalLinkTypes: DirectionalLinkType[], linkType: LinkType) => {
            return [
              ...directionalLinkTypes,
              new DirectionalLinkType(LinkDirection.outgoing, linkType),
              new DirectionalLinkType(LinkDirection.incoming, linkType),
            ];
          }, []);
        this.options.linkTypes.setList(directionalLinkTypes, ID_KEY);
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getPagesSubscription(pages: DbEntityCachedData<PageResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = pages.subscribe(pages => {
        this.options.pages.setList(
          (pages as PageResponseDto[]).map(dto => new Page(dto)),
          ID_KEY,
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getAttributeOptions(): SelectOption<string, NewAttribute>[] {
    return this.options.attributes.list.map((attribute: NewAttribute) => {
      return new SelectOption<string, NewAttribute>(attribute.name || '', attribute);
    });
  }

  private getCustomAttributeOptions(): SelectOption<string, NewAttribute>[] {
    return this.options.customAttributes.list.map((attribute: NewAttribute) => {
      return new SelectOption<string, NewAttribute>(attribute.name || '', attribute);
    });
  }

  private getDirectionalLinkTypeOptions(): SelectOption<string, DirectionalLinkType>[] {
    return this.options.linkTypes.list.map((linkType: DirectionalLinkType) => new SelectOption<string, DirectionalLinkType>(linkType.label, linkType));
  }
}
