<div class="grid">
  <div class="col-5 mb-0" *ngIf="m.selected.treeItem">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox (onChange)="s.onDefaultStylesClick()" [(ngModel)]="m.selected?.treeItem.usesDefaultStyle" binary="true" id="defaultStyle"></p-checkbox>
        <label for="defaultStyle">{{ 'Use default styles' | translate }}</label>
      </span>
    </span>
  </div>

  <div class="col-7 default-style-information">
    <span *ngIf="m.selected?.treeItem?.usesDefaultStyle && (m.items.menu | isItemInFirstLevel : m.selected.menuItem)" class="my-0">
      Using default top level menu item style
    </span>
    <span *ngIf="m.selected?.treeItem?.usesDefaultStyle && !(m.items.menu | isItemInFirstLevel : m.selected.menuItem)" class="my-0">
      Using default sub menu item style
    </span>
    <span class="my-0" *ngIf="!m.selected?.treeItem?.usesDefaultStyle">Using own item style</span>
  </div>

  @if (m.selected.treeItem) {
    <div class="row col-12 p-4 halo">
      <div class="col-6">
        <span class="dynamic-label">
          <p-dropdown
            (onChange)="s.onDefaultStylesClick()"
            [(ngModel)]="m.selected.treeItem.uiType"
            [autoDisplayFirst]="false"
            [options]="m.options.menuItemUiType"
            id="e-type"
          ></p-dropdown>
          <label class="dynamic-label-text" for="e-type" translate> UI type</label>
        </span>
      </div>

      <div class="col-6">
        <span class="dynamic-label">
          <p-dropdown
            [(ngModel)]="m.selected.treeItem.innerFlexDirection"
            (ngModelChange)="s.setTreeItemsToMenu()"
            [options]="m.options.flexDirection"
            appendTo="body"
            id="direction">
          </p-dropdown>
          <label class="dynamic-label-text" for="direction" translate> Direction </label>
        </span>
      </div>
    </div>

    <div *ngIf="m.selected.treeItem.uiType === menuItemUiType.button" class="col-12 mb-2 action-container">
      <h5 class="text-center"> {{ 'Actions on click' | translate }} </h5>
      <app-action-list
        [actions]="m.selected.treeItem.actions"
        [(showEmptyParamsAsActive)]="m.selected?.treeItem.showEmptyParamsAsActive"
        [showMenuWidgetGroup]="true"
        (actionChange)="s.setTreeItemsToMenu()"
        (pageSelect)="s.onPageSelect($event)"
      ></app-action-list>
    </div>

    <!--    <div class="col-12 action-container">-->
      <!--      <h5 class="text-center"> {{ 'Custom event listening' | translate }} </h5>-->
      <!--      <app-custom-event-listening-settings-->
      <!--        [eventResponseSettings]="item.eventResponseSettings"-->
      <!--        [actionOptions]="m.options.actionOptions">-->
      <!--      </app-custom-event-listening-settings>-->
      <!--    </div>-->
  }
</div>
