import { QueryParamsHandling } from '@angular/router';
import { ArtifactWidgetItemActions } from '@shared/types/actions.types';
import { ActionItem, ActionItemDto } from '@widgets/menu-widget/types/menu-action-types';
import { MenuWidgetEventResponseSettings, MenuWidgetEventResponseSettingsDto } from '@widgets/menu-widget/types/menu-widget-event-response.types';
import { FlexDirectionEnum } from '@widgets/shared/types/style.types';
import { MenuWidgetOptions } from './menu-option.types';
import { MenuWidgetSettings } from './menu-widget-settings.types';
import {
  ChipStyles,
  FontStyles,
  IconStyles,
  MenuItemContentStyles,
  MenuItemDefaultContentStyles,
  MenuItemGeneralStyles,
  MenuItemStyles,
  MenuWidgetAllStyles,
} from './menu-widget-styles.types';
import { MenuItemCurrentPageBehaviorEnum, MenuItemUiType, TreeItem } from './tree-types';

export class MenuWidgetValue {
  constructor(public model: MenuWidgetModel = new MenuWidgetModel()) {}
}

export class MenuWidgetModel {
  options = new MenuWidgetOptions();
  items = new MenuWidgetItems();
  styles = new MenuWidgetAllStyles();
  settings = new MenuWidgetSettings();
  selected = new Selected();
  styleTypes = ['Standard', 'On hover', 'On active link'];
  horizontalMenuContainers = ['left', 'center', 'right'];
  isFirstLoad = true;
  behaviorOptionDisabled = true;
  canRenderSettings = false;
  communicationHashIds: string[] = []; // This array represents ids of artifact-widgets, which this menu will listen to, in case of events (showing, hiding...)

  constructor(model?: MenuWidgetModelDto) {
    if (model) {
      model.styles && (this.styles = new MenuWidgetAllStyles(model.styles));
      model.settings && (this.settings = new MenuWidgetSettings(model.settings));
      model.menuItems && (this.items.menu = model.menuItems.map(item => new MenuItem({ ...item, expanded: false })));

      this.communicationHashIds = this.items.menu.reduce((ids: string[], item: MenuItem) => {
        const hashIds = item.actions
          .filter(actionItem => Object.keys(ArtifactWidgetItemActions).includes(actionItem.type) && actionItem.widgetAlias)
          .map(actionItem => actionItem.widgetAlias)
          .filter(hash => hash !== undefined);
        return [...new Set([...ids, ...hashIds].flat())];
      }, []);
    }
    this.rerenderSettings();
  }

  toServer(): MenuWidgetModelDto {
    this.deleteMenuItemParent(this.items.menu);
    return {
      menuItems: this.items.toServer(),
      styles: this.styles,
      settings: this.settings,
    };
  }

  rerenderSettings(): void {
    this.canRenderSettings = false;
    setTimeout(() => (this.canRenderSettings = true));
  }

  private deleteMenuItemParent(items: MenuItem[]): void {
    items.forEach(item => {
      item.parent && delete item.parent;
      item.children && this.deleteMenuItemParent(item.children);
    });
  }
}

export interface MenuWidgetModelDto {
  menuItems: MenuItem[];
  styles: MenuWidgetAllStyles;
  settings: MenuWidgetSettings;
}

export class MenuWidgetItems {
  tree: TreeItem[] = [];
  menu: MenuItem[] = [];

  constructor(items?: Partial<MenuWidgetItems>) {
    if (items) {
      items.tree && (this.tree = items.tree.map(item => new TreeItem(item)));
      items.menu && (this.menu = items.menu.map(item => new MenuItem(item)));
    }
  }

  toServer(): MenuItem[] {
    return this.menu.map(menuItem => menuItem.toServer());
  }
}

export class MenuItem {
  showEmptyParamsAsActive = false;
  hash: string;
  visible = true;
  imageUrl?: string;
  menuIcon: string;
  label: string;
  subLabel: string;
  chip: string;
  usesDefaultStyle = true;
  target?: string;
  command?: (event?: any) => void;
  expanded?: boolean;
  disabled?: boolean;
  escape?: boolean;
  routerLinkActiveOptions?: any;
  separator?: boolean;
  badge?: string;
  badgeStyleClass?: string;
  title?: string;
  id?: string;
  uiType = MenuItemUiType.button;
  actions: ActionItem[] = [new ActionItem()];
  innerFlexDirection = FlexDirectionEnum.row;
  automationId?: any;
  tabindex?: string;
  routerLink?: any;
  queryParamsHandling?: QueryParamsHandling;
  preserveFragment?: boolean;
  skipLocationChange?: boolean;
  replaceUrl?: boolean;
  state?: {
    [k: string]: any;
  };
  children: MenuItem[] = [];
  parent?: MenuItem | null = null;
  eventResponseSettings: MenuWidgetEventResponseSettings = new MenuWidgetEventResponseSettings();

  constructor(dto?: Partial<MenuItem>) {
    dto && this.fromDto(dto);
  }

  get isTextOnly(): boolean {
    return this.children.length > 0;
  }

  get isStayOnCurrentPage(): boolean {
    let isStayOnCurrentPage = false;

    this.actions.forEach(action => {
      action.type === MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage && (isStayOnCurrentPage = true);
    });

    return isStayOnCurrentPage;
  }

  fromDto(dto: Partial<MenuItem>): void {
    if (dto) {
      Object.assign(this, dto);
      dto.actions?.length && (this.actions = dto.actions.map(action => new ActionItem(action as ActionItemDto)));
      dto.children?.length && (this.children = dto.children.map(item => new MenuItem({ ...item, menuIcon: item.menuIcon || '' })));
      dto.eventResponseSettings &&
        (this.eventResponseSettings = new MenuWidgetEventResponseSettings(dto.eventResponseSettings as MenuWidgetEventResponseSettingsDto));
    }
  }

  toServer(): MenuItem {
    const dto = new MenuItem({ ...this, menuIcon: this.menuIcon || '' });
    delete dto?.parent;
    dto.actions = this.actions.map(action => action.toServer()) as ActionItem[];

    dto.children.length && (dto.children = this.cutChildrenParent(dto.children));
    return dto;
  }

  private cutChildrenParent(children: MenuItem[]): MenuItem[] {
    return children.map(child => {
      delete child?.parent;
      child.actions = child.actions.map(action => action.toServer()) as ActionItem[];

      child?.children.length && (child.children = this.cutChildrenParent(child.children));
      return child;
    }) as MenuItem[];
  }
}

export class Selected {
  menuItem: MenuItem | null = null;
  treeItem: TreeItem | null = null;
  itemStyle: MenuItemStyles | null = null;
  style: MenuItemContentStyles | null = null;
  defaultStyle: MenuItemDefaultContentStyles | null = null;
  contentStyle: FontStyles | IconStyles | ChipStyles;
  defaultGeneralStyle: MenuItemGeneralStyles;

  constructor(selected?: Partial<Selected>) {
    selected && Object.assign(this, selected);
  }
}
