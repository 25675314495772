import { AclRecordDto } from '@api/models/acl-record-dto';
import { ArtifactAclResponseDto } from '@api/models/artifact-acl-response-dto';
import { AttributeAclResponseDto } from '@api/models/attribute-acl-response-dto';
import { FolderAclResponseDto } from '@api/models/folder-acl-response-dto';
import { AclComponentService } from '@widgets/shared/components/acl/services/acl.service';

export type AclDtoType = FolderAclResponseDto | ArtifactAclResponseDto | AttributeAclResponseDto;

export class AclTypeModel {
  view: AclRecordDto;
  move: AclRecordDto;
  modify: AclRecordDto;
  modifyItems: AclRecordDto;
  delete: AclRecordDto;
  deleteItems: AclRecordDto;
  permissions: AclRecordDto;

  async init(s: AclComponentService, dto: AclDtoType): Promise<void> {
    if (!dto) return;

    s.c.aclTypeList.forEach(key => {
      const record = (dto as any)[key] as AclRecordDto;
      if (!record) return;

      (this as any)[key] = structuredClone(record);

      const expirations = (this as any)[key].expirations;
      if (expirations) Object.keys(expirations).forEach(id => (expirations[id] = new Date((expirations[id] as string).split('T')[0])));
    });
  }
}

export interface AclItem {
  id: string;
  name: string;
  type: AclItemType;
  expirationDate?: ExpirationDateType;
}

export type ExpirationDateType = Date | null;

export enum AclItemType {
  user = 'USER',
  team = 'TEAM',
}

export enum AclMemberTypes {
  users = 'Users',
  teams = 'Teams',
}

export enum AclTypes {
  view = 'view',
  move = 'move',
  modify = 'modify',
  modifyItems = 'modifyItems',
  delete = 'delete',
  deleteItems = 'deleteItems',
  permissions = 'permissions',
}
