import { inject, Pipe, PipeTransform } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GlobalConstants } from '@shared/constants/global.constants';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';

/**
 * Applicable only for user data types.
 */
@Pipe({ name: 'toUser' })
export class ToUserPipe implements PipeTransform {
  private readonly cache = inject(NewCacheService);

  async transform(artifact: NewArtifact | ArtifactLinkResponseDto, key: string | null): Promise<string> {
    if (!key) return '';

    const rawUserValue = NonAttributeKeys.isUserSpecificAttributeKeyOrId(key) ? this.forSystemUser(key, artifact) : artifact.attributes[key]?.value;
    if (!rawUserValue) return '';

    const userIds = (Array.isArray(rawUserValue) ? rawUserValue : [rawUserValue]).map(userId => userId?.value || userId);
    const users = await this.cache.data.users.getManyAsync(userIds);

    return users.map(user => user.attributes[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value).join(', ');
  }

  private forSystemUser(key: string, artifact: NewArtifact | ArtifactLinkResponseDto) {
    let userKey = key;
    if (NonAttributeKeys.isUserSpecificAttributeId(key)) {
      userKey = key === NonAttributeKeys.CREATED_BY_ID ? NonAttributeKeys.CREATED_BY : NonAttributeKeys.UPDATED_BY;
    }
    return SharedMethods.getValueFromPath(userKey, artifact);
  }
}
