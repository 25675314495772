import { Pipe, PipeTransform } from '@angular/core';
import { ActionItem } from '../../menu-widget/types/menu-action-types';
import { ArtifactListItemClickAction } from '../types/artifact-list-item-click-action';

@Pipe({ name: 'isClickable', standalone: true })
export class IsClickablePipe implements PipeTransform {
	transform(actions: ActionItem[]): boolean {
		return actions.some(action => action.type !== ArtifactListItemClickAction.doNothing);
	}
}
