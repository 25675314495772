import { SwissBillAvrQueryDto as _QUERY_DTO, SwissBillAvrWidgetSettingsDto as _SETTINGS_DTO } from '@api/models';
import { AvrOutputTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { SwissBillOutputTypes } from '@shared/services/artifact-visual-representation/swiss-bill/dto/swiss-bill.input-mapper.dto';

export class SwissBillAvrQueryDto implements _QUERY_DTO {
  outputType: Extract<AvrOutputTypes, _QUERY_DTO['outputType']>;

  constructor(dto: Partial<SwissBillAvrQueryDto>) {
    Object.assign(this, dto);
  }
}

export class SwissBillAvrWidgetSettingsDto implements _SETTINGS_DTO {
  outputType: null | (typeof SwissBillOutputTypes)[number] = null;
}
