import { signal, WritableSignal } from '@angular/core';
import { ArtifactWidgetSettingsDto } from '@api/models/artifact-widget-settings-dto';
import { MenuWidgetModelDto } from '@api/models/menu-widget-model-dto';
import { SettingsStyles } from '@api/models/settings-styles';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import {
  URL_KEY_MODULE_INSERT_INTO,
  URL_KEY_MODULE_INSERT_IS_HEADING,
  URL_KEY_MODULE_INSERT_PARENT_ID,
  URL_KEY_VALUE_ARTIFACT_ID,
  URL_KEY_VALUE_SAVE_TO_FOLDER_ID,
} from '@shared/constants/constants';
import { SetMenuItemAliases } from '@shared/methods/menu.methods';
import { WidgetCreateRequestDto, WidgetUpdateRequestDto } from '@shared/types/widget.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import {
  ArtifactButtonContainers,
  ArtifactButtonContainersDto,
  ButtonContainerPositionEnum,
} from '@widgets/artifact-widget/types/artifact-widget-button-containers.types';
import {
  ArtifactWidgetEventResponseSettings,
  ArtifactWidgetEventResponseSettingsDto,
} from '@widgets/artifact-widget/types/artifact-widget-event-response.types';
import { ArtifactWidgetActionAfterLogin, ArtifactWidgetType } from '@widgets/artifact-widget/types/artifact-widget.types';
import { MenuItem } from '@widgets/menu-widget/types/menu-widget.types';
import { LabelBehaviourEnum, WidgetSaveButtonVisibilityEnum, WidgetStyles } from '@widgets/shared/types/style.types';

export class ArtifactWidgetSettings {
  widgetType: WritableSignal<ArtifactWidgetType> = signal(ArtifactWidgetType.artifact);
  urlChangeAction = true;
  urlKeys = new ArtifactUrlKeys();
  clearFormOnCreation = true;
  clearFormOnBlur = false;
  automaticSave = false;
  notifyOnAutomaticSave = false;
  saveButtonVisibility: WritableSignal<WidgetSaveButtonVisibilityEnum> = signal(WidgetSaveButtonVisibilityEnum.onChange);
  saveAndNotifyButtonVisibility: WritableSignal<WidgetSaveButtonVisibilityEnum> = signal(WidgetSaveButtonVisibilityEnum.onChange);
  labelBehaviour = LabelBehaviourEnum.static;
  initiateEditWithEditButton: WritableSignal<boolean> = signal(false);
  listenForFolderUrlParam = true;
  enableStoreToFolder = false;
  useReCaptcha: boolean | null = null;
  storeToFolderId: string | null;
  actionAfterLogin: ArtifactWidgetActionAfterLogin = ArtifactWidgetActionAfterLogin.applicationSelection;
  loginRedirectionPageId: string | null = null;
  registrationRedirectionPageId: string | null = null;
  passwordRecoveryRedirectionPageId: string | null = null;
  addCreatedArtifactIdToUrlParam = false;
  addCreatedFolderIdToUrlParam = false;
  useAliasForRedirection = false;
  showHistoryButton: WritableSignal<boolean> = signal(true);
  showAclButton: WritableSignal<boolean> = signal(true);
  showGoogleSso = false;
  showOpenIdSso = false;
  styles = new WidgetStyles();
  widgetAlias: string;
  buttonContainers: ArtifactButtonContainers = {};
  eventResponseSettings: ArtifactWidgetEventResponseSettings = new ArtifactWidgetEventResponseSettings();

  constructor(params: Partial<ArtifactWidgetSettingsDto> | null = null) {
    if (params) {
      params.styles && (params.styles = new WidgetStyles(params.styles as WidgetStyles) as SettingsStyles);
      params.urlKeys && (params.urlKeys = new ArtifactUrlKeys(params.urlKeys));
      params.eventResponseSettings &&
        (params.eventResponseSettings = new ArtifactWidgetEventResponseSettings(params.eventResponseSettings as ArtifactWidgetEventResponseSettingsDto));

      Object.assign(this, {
        ...params,
        widgetType: signal(params.widgetType),
        initiateEditWithEditButton: signal(params.initiateEditWithEditButton),
        showHistoryButton: signal(params.showHistoryButton),
        showAclButton: signal(params.showAclButton),
        saveButtonVisibility: signal(params.saveButtonVisibility),
        saveAndNotifyButtonVisibility: signal(params.saveAndNotifyButtonVisibility),
      });
    }
  }

  copy(): ArtifactWidgetSettingsDto {
    return { ...this.toServer(true) };
  }

  /**
   *  createCopyOfMenuWidgets true => menu widgets are transformed to BlockPartWidget => used for copying
   *  createCopyOfMenuWidgets false => menu widgets are transformed to IDs            => used for saving
   */
  toServer(createCopyOfMenuWidgets = false): ArtifactWidgetSettingsDto {
    const widgetAlias = createCopyOfMenuWidgets ? ElvisUtil.makeHash(10) : this.widgetAlias;

    const buttonContainers: ArtifactButtonContainersDto = {};

    if (this.buttonContainers[ButtonContainerPositionEnum.top]) {
      buttonContainers[ButtonContainerPositionEnum.top] = createCopyOfMenuWidgets
        ? this.getButtonContainerCopy(ButtonContainerPositionEnum.top)
        : this.buttonContainers[ButtonContainerPositionEnum.top].id || undefined;
    }
    if (this.buttonContainers[ButtonContainerPositionEnum.bottom]) {
      buttonContainers[ButtonContainerPositionEnum.bottom] = createCopyOfMenuWidgets
        ? this.getButtonContainerCopy(ButtonContainerPositionEnum.bottom)
        : this.buttonContainers[ButtonContainerPositionEnum.bottom].id || undefined;
    }
    if (this.buttonContainers[ButtonContainerPositionEnum.left]) {
      buttonContainers[ButtonContainerPositionEnum.left] = createCopyOfMenuWidgets
        ? this.getButtonContainerCopy(ButtonContainerPositionEnum.left)
        : this.buttonContainers[ButtonContainerPositionEnum.left].id || undefined;
    }
    if (this.buttonContainers[ButtonContainerPositionEnum.right]) {
      buttonContainers[ButtonContainerPositionEnum.right] = createCopyOfMenuWidgets
        ? this.getButtonContainerCopy(ButtonContainerPositionEnum.right)
        : this.buttonContainers[ButtonContainerPositionEnum.right].id || undefined;
    }

    if (createCopyOfMenuWidgets) {
      this.setNewAliases(buttonContainers, widgetAlias);
    }

    const dto = {
      ...this,
      widgetAlias,
      styles: { ...this.styles, borderRadiusRange: [] },
      buttonContainers,
      eventResponseSettings: this.eventResponseSettings.toServer(),
      widgetType: this.widgetType(),
      initiateEditWithEditButton: this.initiateEditWithEditButton(),
      showHistoryButton: this.showHistoryButton(),
      showAclButton: this.showAclButton(),
      saveButtonVisibility: this.saveButtonVisibility(),
      saveAndNotifyButtonVisibility: this.saveAndNotifyButtonVisibility(),
    };
    delete (dto as any)['preferredModuleArtifactTypeIds'];

    return dto;
  }

  private getButtonContainerCopy(position: ButtonContainerPositionEnum): BlockPartWidget {
    return new BlockPartWidget({ ...this.buttonContainers[position]!.requestDto, id: undefined });
  }

  private setNewAliases(buttonContainers: ArtifactButtonContainersDto, widgetAlias: string): void {
    Object.values(buttonContainers).forEach(container => {
      const model = (container as WidgetCreateRequestDto | WidgetUpdateRequestDto).value.model as MenuWidgetModelDto;
      model.menuItems.forEach(item => SetMenuItemAliases(item as MenuItem, widgetAlias));
    });
  }
}

export class ArtifactUrlKeys {
  listeningKeys = new ArtifactListeningKeys();
  emittingKeys = new ArtifactEmittingKeys();

  constructor(params?: Partial<ArtifactUrlKeys>) {
    if (params) {
      params.listeningKeys && (params.listeningKeys = new ArtifactListeningKeys(params.listeningKeys));
      params.emittingKeys && (params.emittingKeys = new ArtifactEmittingKeys(params.emittingKeys));
      Object.assign(this, params);
    }
  }
}

export class ArtifactListeningKeys {
  artifactId = URL_KEY_VALUE_ARTIFACT_ID;
  linkedArtifactId = URL_KEY_VALUE_ARTIFACT_ID;
  saveToFolderId = URL_KEY_VALUE_SAVE_TO_FOLDER_ID;
  moduleInsertParentId = URL_KEY_MODULE_INSERT_PARENT_ID;
  moduleInsertInto = URL_KEY_MODULE_INSERT_INTO;
  moduleInsertIsHeading = URL_KEY_MODULE_INSERT_IS_HEADING;

  constructor(params?: Partial<ArtifactListeningKeys>) {
    params && Object.assign(this, params);
  }
}

export class ArtifactEmittingKeys {
  moduleId = URL_KEY_VALUE_ARTIFACT_ID;
  folderId = URL_KEY_VALUE_SAVE_TO_FOLDER_ID;

  constructor(params?: Partial<ArtifactEmittingKeys>) {
    params && Object.assign(this, params);
  }
}
