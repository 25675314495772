import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';

export const QrCodesOutputTypes = [AvrOutputTypes.svg, AvrOutputTypes.png] as const;

export class QrCodesAvrInputMapperDto extends BaseAvrInputMapperDto<AvrTypes.qrCodes> {
  constructor() {
    super(
      {
        ['content']: {
          isOptional: false,
          value: '',
          readableName: 'Content',
          description: '[STRING] Content (URL, Plain Text, vCard, Email Address, SMS, WiFi Network Information, Geolocation, vEvent etc.)',
        },
      },
      {
        ['outputTypes']: {
          isOptional: false,
          value: [AvrOutputTypes.svg],
          readableName: 'Output type',
          description: 'Select from supported output types',
        },
      },
    );
  }
}
