<div class="grid">
  <div *ngIf="showLabelOption && !isContentStyles" class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox (onChange)="invokeLabelStyleChange()" [(ngModel)]="labelFormatSettings.visible"
                    binary="true" id="showLabel"></p-checkbox>
        <label for="showLabel" translate> Show label </label>
      </span>
    </span>
  </div>

  <div *ngIf="!isContentStyles && showHideOption" class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="hideFilter" binary="true" id="hideFilter"
                    (onChange)="onHideFilterChange($event)"></p-checkbox>
        <label for="hideFilter" translate> Hide filter </label>
      </span>
    </span>
  </div>

  <div *ngIf="!isContentStyles && showHideOption" class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="hideSorting" binary="true" id="hideSorting"
                    (onChange)="onHideSortingChange($event)"></p-checkbox>
        <label for="hideSorting" translate> Hide Sorting </label>
      </span>
    </span>
  </div>

  <div *ngIf="!isContentStyles" class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="labelFormatSettings.showIcon" binary="true" id="showIcon"
                    (onChange)="invokeLabelStyleChange()"></p-checkbox>
        <label for="showIcon" translate> Show icon </label>
      </span>
    </span>
  </div>

  <ng-container *ngIf="labelFormatSettings.visible && !isContentStyles">
    <div class="col-6 mb-0">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
              <p-checkbox id="useCustomLabel"
                          [(ngModel)]="labelFormatSettings.useCustomLabel"
                          binary="true"
                          (onChange)="onCustomLabelUseChange()">
              </p-checkbox>
              <label for="useCustomLabel" translate> Use custom label </label>
          </span>
        </span>
    </div>

    <div class="col-6 mb-0">
      <input [(ngModel)]="labelFormatSettings.customLabel"
             [disabled]="!labelFormatSettings.useCustomLabel"
             id="customLabel"
             pInputText
             placeholder="{{'Set custom placeholder'| translate}}"
             style="width: 100%"
             type="text"
             (blur)="invokeLabelStyleChange()">
    </div>
  </ng-container>

  <div class="col-6 mb-0" *ngIf="isLink">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="labelFormatSettings.showLink" binary="true" id="showIcon"
                    (onChange)="invokeLabelStyleChange()"></p-checkbox>
        <label for="showIcon" translate> Show link </label>
      </span>
    </span>
  </div>

  <div class="col-4 mb-0" *ngIf="!hideLabelFontSettings">
    <button (click)="labelFontStyleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="{{'Font'| translate}}"
            pButton type="button"></button>
  </div>

  <ng-container *ngIf="showPrefixSuffixSettings">
    <div class="flex">
      <div class="col-6 mb-0">
        <h6> {{ 'Prefix' | translate }} </h6>
        <span class="dynamic-label">
        <input [(ngModel)]="labelFormatSettings.styles.prefix" (ngModelChange)="invokeLabelStyleChange()" id="prefix"
               pInputText type="text" />
      </span>
      </div>
      <div class="col-6 mb-0">
        <h6> {{ 'Suffix' | translate }} </h6>
        <span class="dynamic-label">
        <input [(ngModel)]="labelFormatSettings.styles.suffix" (ngModelChange)="invokeLabelStyleChange()" id="suffix"
               pInputText type="text" />
      </span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showDecimalPlacesSettings">
    <div class="flex">
      <div class="col-6 mb-0">
        <h6> {{ 'Minimum decimal places' | translate }} </h6>
        <span class="dynamic-label">
          <p-inputNumber [(ngModel)]="labelFormatSettings.styles.minDecimalPlaces"
                         (ngModelChange)="onMinDecimalPlacesChange($event)" id="minDecimalPlaces" />
        </span>
      </div>
      <div class="col-6 mb-0">
        <h6> {{ 'Maximum decimal places' | translate }} </h6>
        <span class="dynamic-label">
          <p-inputNumber [(ngModel)]="labelFormatSettings.styles.maxDecimalPlaces"
                         (ngModelChange)="onMaxDecimalPlacesChange($event)" id="maxDecimalPlaces" />
        </span>
      </div>
    </div>
  </ng-container>

  <div *ngIf="!isContentStyles" class="col-4 mb-0">
    <button (click)="labelPositionOp.toggle($event)" class="w-100" icon="bi bi-align-middle"
            label="{{'Position'| translate}}" pButton type="button"></button>
  </div>
</div>

<p-overlayPanel #labelPositionOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <div class="d-flex flex-column">
      <!-- Position -->
      <div *ngIf="enableLabelPositioning" class="p-col">
        <h6> {{ 'Position'| translate }} </h6>
        <p-dropdown (onChange)="invokeLabelPositionChange($event)"
                    [(ngModel)]="labelFormatSettings.position"
                    [autoDisplayFirst]="false"
                    [options]="labelPositionOptions"
                    id="labelPosition">
        </p-dropdown>
      </div>

      <div *ngIf="enableHorizontalAlignment" class="p-col">
        <h6> {{ 'Horizontal align'| translate }} </h6>
        <p-dropdown (onChange)="invokeLabelStyleChange()"
                    [(ngModel)]="labelFormatSettings.styles.textAlign"
                    [autoDisplayFirst]="false"
                    [options]="textHorizontalAlignOptions"
                    id="labelTextHorizontalAlign">
        </p-dropdown>
      </div>

      <div *ngIf="enableVerticalAlignment || isVerticalAlignmentAvailable" class="p-col">
        <h6> {{ 'Vertical align'| translate }} </h6>
        <p-dropdown (onChange)="invokeLabelStyleChange()"
                    [(ngModel)]="labelFormatSettings.styles.verticalAlign"
                    [autoDisplayFirst]="false"
                    [options]="textVerticalAlignOptions"
                    id="labelTextVerticalAlign">
        </p-dropdown>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #labelFontStyleOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <app-font-style-form [fontStyles]="labelFormatSettings.styles"
                         [hideBackgroundColorSetting]="!showFontBackgroundSettings"
                         [hideHorizontalAlignSettings]="true"
                         (onChange)="invokeLabelStyleChange()">
    </app-font-style-form>
  </ng-template>
</p-overlayPanel>
