import { Component, Input, ViewChild } from '@angular/core';
import { AttributeListService } from '@private/pages/artifact-type-management/attribute-list/services/attribute-list.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { APPLICATION_ID_KEY, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { NewApplication } from '@shared/types/application.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import { TableColumn } from '@shared/types/table.types';
import { ObjectUtil } from '@shared/utils/object.util';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-attribute-list-table',
  templateUrl: './attribute-list-table.component.html',
  styleUrls: ['./attribute-list-table.component.scss'],
})
export class AttributeListTableComponent extends CoreListComponent<NewAttribute> {
  @ViewChild('table') table: Table;

  @Input() columns: TableColumn[];
  @Input() applications: ListContainer<NewApplication>;

  APPLICATION_ID_VALUE = APPLICATION_ID_KEY;
  NAME_KEY = NAME_KEY;
  ID_VALUE = ID_KEY;

  constructor(
    public readonly objectU: ObjectUtil,
    public readonly attributeListService: AttributeListService,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super();
  }

  protected onInit(): void {
    super.onInit();
    this.registerSubscription(this.applicationSwitcherService.selectedApplication$.subscribe(() => this.attributeListService.doFilter(this.table)));
  }
}
