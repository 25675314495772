import { SharedTestUtil } from '../../../../../../e2e/helper/general-data-helper/shared-test-util.methods';

export class BoxShadowModel {
  id: string = SharedTestUtil.getUniqueId();
  offsetX: number = 0;
  offsetY: number = 0;
  blurRadius: number = 0;
  spreadRadius: number = 0;
  shadowColor: string = 'rgba(0, 0, 0, 1)';
  isInset: boolean = false;

  constructor(init?: Partial<BoxShadowModel>) {
    init && Object.assign(this, init);
  }
}
