<div class="box-shadow-generator">
  <!-- Preview Section -->
  <div class="preview-section">
    <div class="box-preview" [style.box-shadow]="combinedBoxShadows">
      Preview
    </div>
  </div>

  <!-- Controls Section -->
  <div class="controls-section">
    <h3>Box Shadow Settings</h3>

    <!-- Individual Shadow Controls -->
    <p-accordion>
      @for (shadow of boxShadows; track shadow.id; let i = $index) {
        <p-accordionTab header="Shadow {{ i + 1 }}">
          <div class="shadow-control">
            <!-- Horizontal Offset -->
            <div class="control">
              <label for="offsetX-{{ i }}">Horizontal Offset:</label>
              <p-slider id="offsetX-{{ i }}" [(ngModel)]="shadow.offsetX" [min]="-100" [max]="100"></p-slider>
              <p-inputNumber [(ngModel)]="shadow.offsetX" [min]="-100" [max]="100"></p-inputNumber>
            </div>

            <!-- Vertical Offset -->
            <div class="control">
              <label for="offsetY-{{ i }}">Vertical Offset:</label>
              <p-slider id="offsetY-{{ i }}" [(ngModel)]="shadow.offsetY" [min]="-100" [max]="100"></p-slider>
              <p-inputNumber [(ngModel)]="shadow.offsetY" [min]="-100" [max]="100"></p-inputNumber>
            </div>

            <!-- Blur Radius -->
            <div class="control">
              <label for="blurRadius-{{ i }}">Blur Radius:</label>
              <p-slider id="blurRadius-{{ i }}" [(ngModel)]="shadow.blurRadius" [min]="0" [max]="100"></p-slider>
              <p-inputNumber [(ngModel)]="shadow.blurRadius" [min]="0" [max]="100"></p-inputNumber>
            </div>

            <!-- Spread Radius -->
            <div class="control">
              <label for="spreadRadius-{{ i }}">Spread Radius:</label>
              <p-slider id="spreadRadius-{{ i }}" [(ngModel)]="shadow.spreadRadius" [min]="-100" [max]="100"></p-slider>
              <p-inputNumber [(ngModel)]="shadow.spreadRadius" [min]="-100" [max]="100"></p-inputNumber>
            </div>

            <!-- Shadow Color -->
            <div class="control">
              <label for="shadowColor-{{ i }}">Shadow Color:</label>
              <p-colorPicker id="shadowColor-{{ i }}" [(ngModel)]="shadow.shadowColor"></p-colorPicker>
            </div>

            <!-- Inset Toggle -->
            <div class="control mt-1" [class.mb-2]="boxShadows.length > 1">
              <label for="inset-{{ i }}">Inset Shadow:</label>
              <p-checkbox id="inset-{{ i }}" [(ngModel)]="shadow.isInset" binary="true" label="Inset"></p-checkbox>
            </div>

            <!-- Remove Shadow Button -->
            <p-button [size]="'small'" label="Remove Shadow" icon="pi pi-trash" class="p-button-danger"
                      (click)="removeShadow(i)"></p-button>
          </div>
        </p-accordionTab>
      }
    </p-accordion>

    <!-- Add New Shadow -->
    <p-button label="Add New Shadow" icon="pi pi-plus" (click)="addShadow()" [size]="'small'"></p-button>

    <!-- CSS Output -->
    <div class="css-output">
      <h3>Generated CSS</h3>
      <textarea style="width: 100%;" [autoResize]="true" [rows]="3" [readonly]="true" pInputTextarea
                [ngModel]="combinedBoxShadows"></textarea>
      <p-button class="mt-2" label="Copy CSS" icon="pi pi-copy" (click)="copyCss()" [size]="'small'"></p-button>
    </div>
  </div>
</div>