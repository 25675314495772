import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-artifact-widget-actions',
  standalone: true,
  imports: [FloatLabelModule, InputTextModule, ReactiveFormsModule, TranslateModule, FormsModule],
  templateUrl: './artifact-widget-actions.component.html',
  styleUrl: './artifact-widget-actions.component.scss',
})
export class ArtifactWidgetActionsComponent {
  @Input() action: ActionItem;

  @Output() actionChange = new EventEmitter<ActionItem>();

  onActionChange(): void {
    this.actionChange.emit(this.action);
  }
}
