import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, signal, Signal, SimpleChanges, viewChild } from '@angular/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { BaseWorkflowsAdministrationComponent } from '@workflows/components/administration/base-workflows-administration.component';
import { WorkflowRuleComponent } from '@workflows/components/rule';
import { RuleAdministrationType } from '@workflows/shared';
import { RuleDataHolderService, RuleUtilsService, WorkflowTriggerFactory } from '../../../services';
import { RuleAdministrationFilter, WorkflowOwnerType, WorkflowRule, WorkflowType } from '../../../types';

@Component({
  selector: 'app-workflows-page-admin',
  templateUrl: './workflow-page-administration.component.html',
  styleUrls: ['./workflow-page-administration.component.scss', '../../workflow-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowsPageAdministrationComponent extends BaseWorkflowsAdministrationComponent implements OnChanges {
  @Input() pageId: string;
  @Input() highlightedRule?: WorkflowRule;
  @Input() pageWidgets: BlockPartWidget[];
  @Input() visible: boolean;

  workflowRuleComponent: Signal<WorkflowRuleComponent> = viewChild.required('workflowRuleComponent');
  areAllRulesSelected = false;
  isAnyRuleSelected = signal(false);

  activeIndex = 0;
  canBeAddedRule = true;
  rule: WorkflowRule;
  rules: WorkflowRule[] = [];
  artifactTypes: ListContainer<NewArtifactType> = new ListContainer();
  attributes: ListContainer<NewAttribute> = new ListContainer();
  dataTypes: ListContainer<NewDataType> = new ListContainer();
  linkTypes: ListContainer<LinkType> = new ListContainer();
  applications: ListContainer<NewApplication> = new ListContainer();
  pageRules: WorkflowRule[];
  globalRules: WorkflowRule[];
  nameFilterValue = '';
  nameFilterActive = false;
  artifactTypeFilterValue = [];
  artifactTypeFilterActive = false;

  protected readonly RuleAdministrationType = RuleAdministrationType;

  private ownerId: string;

  constructor(
    cache: NewCacheService,
    announcement: AnnouncementService,
    private triggerFactory: WorkflowTriggerFactory,
    private ruleDataHolder: RuleDataHolderService,
    private cdr: ChangeDetectorRef,
    private readonly ruleUtils: RuleUtilsService,
  ) {
    super(cache, announcement);
  }

  get selectedRules(): WorkflowRule[] {
    return this.activeIndex === 0 ? this.pageRules.filter(rule => rule.selected) : this.globalRules.filter(rule => rule.selected);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      if (this.visible) {
        this.activeIndex = 0;
        this.ownerId = this.pageId;
        this.pageRules = this.getRulesForPage();
        this.globalRules = this.getGlobalRulesForPage();
        if (!this.highlightedRule) {
          this.highlightFirstAvailableRule();
        }
      } else {
        this.highlightedRule = undefined;
      }
    }
  }

  addNewWorkflowRule() {
    this.canBeAddedRule = false;
    this.rule = new WorkflowRule();
    this.rule.ownerId = this.pageId;
    this.rule.usedIn = [this.pageId];
    this.rule.triggers = [this.triggerFactory.getBaseWorkflowTrigger()];
    this.rule.type = WorkflowType.fe;
    this.rule.ownerType = WorkflowOwnerType.PAGE;
    this.highlightedRule = this.rule;
    this.activeIndex = 0;
    this.deselectAllRules();

    setTimeout(() => this.workflowRuleComponent().focusNameInput());
  }

  doSaveRule(rule: WorkflowRule) {
    const isNew = rule === this.rule;
    if (isNew) {
      this.ruleDataHolder.saveRule$(rule).subscribe(serverRule => {
        this.highlightedRule = serverRule;
        this.reloadPageRules();
        this.announcement.success('Workflow rule successfully saved...');
      });
      return;
    }
    this.ruleDataHolder.updateRule$(rule).subscribe(serverRule => {
      this.highlightedRule = serverRule;
      this.reloadPageRules();
      this.announcement.success('Workflow rule successfully updated...');
    });
  }

  doUseRule(rule: any) {
    this.ruleDataHolder.useRule$(rule, this.ownerId).subscribe(serverRule => this.doUpdateOnUseChange(serverRule, true));
  }

  doUseSelectedRules(): void {
    this.selectedRules.forEach(rule => this.doUseRule(rule));
  }

  doUnuseRule(rule: WorkflowRule) {
    this.ruleDataHolder.unuseRule$(rule, this.ownerId).subscribe(serverRule => this.doUpdateOnUseChange(serverRule, false));
  }

  // Unuse only global rules that are used by the page and are not owned by the page
  doUnuseSelectedRules(): void {
    this.selectedRules
      .filter(rule => !!rule?.global && this.ruleUtils.isUsedByPage(rule, this.pageId) && !this.ruleUtils.isOwnedByPage(rule, this.pageId))
      .forEach(rule => this.doUnuseRule(rule));
  }

  doActivateRule(rule: WorkflowRule): void {
    this.ruleDataHolder.activateRule$(rule).subscribe(rule => {
      this.reloadPageRules();
      this.announcement.success('Workflow rule activated');
      rule.id === this.highlightedRule?.id && (this.highlightedRule = rule);
    });
  }

  doActivateSelectedRules(): void {
    this.selectedRules.filter(rule => this.ruleUtils.isOwnedByPage(rule, this.pageId)).forEach(rule => this.doActivateRule(rule));
  }

  doDeactivateRule(rule: WorkflowRule): void {
    this.ruleDataHolder.deactivateRule$(rule).subscribe(rule => {
      this.reloadPageRules();
      this.announcement.success('Workflow rule deactivated');
      rule.id === this.highlightedRule?.id && (this.highlightedRule = rule);
    });
  }

  doDeactivateSelectedRules(): void {
    this.selectedRules.filter(rule => this.ruleUtils.isOwnedByPage(rule, this.pageId)).forEach(rule => this.doDeactivateRule(rule));
  }

  doDeleteRule(rule: WorkflowRule) {
    this.ruleDataHolder.deleteRule$(rule).subscribe(() => {
      this.reloadPageRules();
      this.highlightedRule = undefined;
      this.announcement.success('Workflow rule successfully deleted...');
    });
  }

  doDeleteSelectedRules(): void {
    this.selectedRules.forEach(rule => this.doDeleteRule(rule));
  }

  highlightRule(rule: WorkflowRule) {
    this.highlightedRule = new WorkflowRule(rule);
  }

  onTabChange(index: number) {
    this.activeIndex = index;
    this.filterRules();
    this.highlightFirstAvailableRule();
    this.deselectAllRules();
  }

  trackByRuleFn(index: number, item: WorkflowRule): string {
    return item.id;
  }

  filterByName(): void {
    this.nameFilterActive = true;
    this.highlightedRule = undefined;
    this.filterRules();
  }

  filterByArtifactType(): void {
    this.artifactTypeFilterActive = true;
    this.highlightedRule = undefined;
    this.filterRules();
  }

  clearNameFilter(): void {
    this.nameFilterValue = '';
    this.nameFilterActive = false;
    this.filterRules();
  }

  clearArtifactTypeFilter(): void {
    this.artifactTypeFilterValue = [];
    this.artifactTypeFilterActive = false;
    this.filterRules();
  }

  onSelectAllRules(): void {
    this.areAllRulesSelected ? this.selectAllRules() : this.deselectAllRules();
  }

  onSelectRuleCBClick(event: any): void {
    event.stopPropagation();
    this.recomputeSelectedCheckboxes();
  }

  deselectAllRules(): void {
    const rules = this.activeIndex === 0 ? this.pageRules : this.globalRules;
    rules.forEach(rule => (rule.selected = false));
    this.areAllRulesSelected = false;
    this.isAnyRuleSelected.set(false);
  }

  private recomputeSelectedCheckboxes(): void {
    const rules = this.activeIndex === 0 ? this.pageRules : this.globalRules;
    this.areAllRulesSelected = rules.every(rule => rule.selected);
    this.isAnyRuleSelected.set(rules.some(rule => rule.selected));
  }

  private selectAllRules(): void {
    const rules = this.activeIndex === 0 ? this.pageRules : this.globalRules;
    rules.forEach(rule => (rule.selected = true));
    this.areAllRulesSelected = true;
    this.isAnyRuleSelected.set(true);
  }

  private doUpdateOnUseChange(serverRule: WorkflowRule, using: boolean) {
    this.highlightedRule = serverRule;
    if (using) {
      this.pageRules = [...this.pageRules, serverRule];
      this.globalRules = this.globalRules.filter(item => item.id !== serverRule.id);
      this.announcement.success('Workflow rule successfully assigned to this page...');
    } else {
      this.pageRules = this.pageRules.filter(item => item.id !== serverRule.id);
      this.globalRules = [...this.globalRules, serverRule];
      this.announcement.success('Workflow rule successfully removed from this page...');
    }
    this.highlightedRule = this.activeIndex === 0 ? this.pageRules[0] : this.globalRules[0];
    this.cdr.markForCheck();
  }

  private isGlobalRulesTabActive(): boolean {
    return this.activeIndex === 1;
  }

  private reloadPageRules() {
    this.pageRules = this.getRulesForPage();
    this.cdr.markForCheck();
  }

  private highlightFirstAvailableRule(): void {
    const rules = this.isGlobalRulesTabActive() ? this.globalRules : this.pageRules;
    this.highlightedRule = rules.length ? rules[0] : undefined;
  }

  private filterRules(): void {
    !this.isGlobalRulesTabActive() && (this.pageRules = this.getRulesForPage());
    this.isGlobalRulesTabActive() && (this.globalRules = this.getGlobalRulesForPage());
  }

  private getRulesForPage(): WorkflowRule[] {
    return (this.ruleDataHolder.getRulesForPage(this.pageId, this.getRuleFilters()) as WorkflowRule[]) || [];
  }

  private getGlobalRulesForPage(): WorkflowRule[] {
    return this.ruleDataHolder.getAvailableGlobalRulesForPage(this.pageId, this.getRuleFilters()) as WorkflowRule[];
  }

  private getRuleFilters(): RuleAdministrationFilter {
    return {
      name: this.nameFilterValue,
      artifactTypeIds: this.artifactTypeFilterValue,
    };
  }
}
