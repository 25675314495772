import { Component, Input } from '@angular/core';
import { ArtifactResponseDto } from 'src/app/api/models';
import { DATFileLayoutVariant } from 'src/app/shared/types/display-at-types';
import { SingleFileAttributeValuePresentationService } from 'src/app/widgets/card-widget/services/single-file-attribute-value-presentation.service';
import { FileWithUrl } from 'src/app/widgets/card-widget/types/file-with.url';
import { Observable, of } from 'rxjs';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';

@Component({
  selector: 'display-at-file',
  templateUrl: './display-at-file.component.html',
  styleUrls: ['./display-at-file.component.scss'],
})
export class DisplayAtFileComponent {
  @Input() layoutVariant: keyof typeof DATFileLayoutVariant;
  @Input() formatStyles: FormatStyles;
  file$: Observable<FileWithUrl | null>;
  readonly nonImageFileIcon = 'assets/images/non-image-file.ico';

  constructor(private readonly service: SingleFileAttributeValuePresentationService) {}

  @Input() set fileDto(dto: ArtifactResponseDto) {
    this.file$ = dto ? this.service.getFileArtifactByDto(dto) : of(null);
  }

  get isDefaultLayout(): boolean {
    return this.layoutVariant === 'DEFAULT';
  }

  get isSummaryIconLayout(): boolean {
    return this.layoutVariant === 'SUMMARY_ICON';
  }

  get isPictureLayout(): boolean {
    return this.layoutVariant === 'PICTURE';
  }

  get isPictureWithNameLayout(): boolean {
    return this.layoutVariant === 'PICTURE_WITH_NAME';
  }

  get isPictureWithLinkLayout(): boolean {
    return this.layoutVariant === 'PICTURE_WITH_LINK';
  }
}
