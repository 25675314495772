import { Injectable } from '@angular/core';
import { WorkflowTriggerDto } from '@api/models';
import {
  BaseWorkflowTrigger,
  CommonFeTrigger,
  RuleTriggerType,
  WorkflowTrigger,
  WorkflowTriggerAttributeValueChange,
  WorkflowTriggerButtonClick,
  WorkflowTriggerLinkAdded,
  WorkflowTriggerPageLoad,
  WorkflowTriggerWidgetDataLoad,
  WorkflowTriggerWidgetLoad,
} from '@workflows/types';
import { WorkflowTriggerArtifactWidgetCreateMode } from '@workflows/types/triggers/trigger-artifact-widget-create-mode';
import { WorkflowTriggerCreateUpdateDelete } from '@workflows/types/triggers/trigger-create-update-delete';
import { WorkflowTriggerManual } from '@workflows/types/triggers/trigger-manual';

@Injectable({ providedIn: 'root' })
export class WorkflowTriggerFactory {
  getWorkflowTrigger(type: RuleTriggerType, dto?: WorkflowTriggerDto): WorkflowTrigger {
    switch (type) {
      case RuleTriggerType.ATTRIBUTE_VALUE_CHANGE:
        return new WorkflowTriggerAttributeValueChange(dto?.artifactTypeId, dto?.attributeId);
      case RuleTriggerType.BUTTON_CLICK:
        return new WorkflowTriggerButtonClick(dto?.elementId);
      case RuleTriggerType.LINK_ADDED:
        return new WorkflowTriggerLinkAdded(dto?.artifactTypeId);
      case RuleTriggerType.PAGE_LOAD:
        return new WorkflowTriggerPageLoad();
      case RuleTriggerType.WIDGET_DATA_LOAD:
        return new WorkflowTriggerWidgetDataLoad(dto?.widgetId);
      case RuleTriggerType.WIDGET_LOAD:
        return new WorkflowTriggerWidgetLoad(dto?.widgetId);
      case RuleTriggerType.OWNER_CREATE:
      case RuleTriggerType.OWNER_UPDATE:
      case RuleTriggerType.OWNER_DELETE:
      case RuleTriggerType.OWNER_IMPORT:
      case RuleTriggerType.BEFORE_OWNER_CREATE:
      case RuleTriggerType.BEFORE_OWNER_UPDATE:
      case RuleTriggerType.BEFORE_OWNER_DELETE:
        return new WorkflowTriggerCreateUpdateDelete(type);
      case RuleTriggerType.MANUAL:
        return new WorkflowTriggerManual(dto);
      case RuleTriggerType.ARTIFACT_WIDGET_CREATE_MODE:
        return new WorkflowTriggerArtifactWidgetCreateMode(dto?.widgetId);
    }
    return this.getBaseWorkflowTrigger();
  }

  getBaseWorkflowTrigger(): WorkflowTrigger {
    return new BaseWorkflowTrigger(CommonFeTrigger);
  }
}
