import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { Constructor } from '@shared/types/constructor.types';

export type Literal<K> = K extends string ? K : never;

export type AvrInputMappersType = { [K in AvrTypes]: Exclude<ArtifactTypeResponseDto['avrMapper'][K], undefined> };

export enum AvrTypes {
  swissBill = 'swiss-bill',
  documentGeneration = 'document-generation',
  qrCodes = 'qr-codes',
}

type AvrTypesType = Literal<keyof ArtifactTypeResponseDto['avrMapper']>;
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avrTypesCheck: [AvrTypesType] extends [`${AvrTypes}`] ? (`${AvrTypes}` extends AvrTypesType ? true : false) : false = true;

export enum AvrOutputTypes {
  pdf = 'PDF',
  svg = 'SVG',
  docx = 'DOCX',
  pptx = 'PPTX',
  png = 'PNG',
}

type AvrOutputTypesType = AvrInputMappersType[AvrTypes]['outputTypes'][number] extends infer U ? U : never;
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avrOutputTypesCheck: [AvrOutputTypesType] extends [`${AvrOutputTypes}`] ? (`${AvrOutputTypes}` extends AvrOutputTypesType ? true : false) : false = true;

export abstract class BaseAvrAbstractService<
  AvrType extends AvrTypes,
  InputMapperDto extends BaseAvrInputMapperDto<AvrType>,
  OutputTypes = Extract<AvrOutputTypes, AvrInputMappersType[AvrType]['outputTypes'][number]>,
> {
  private _type: AvrType;
  private _inputMapperDto: Constructor<InputMapperDto>;
  private _readableName: string;
  private _outputTypes: OutputTypes[];

  protected superConstructor<T extends OutputTypes[]>(
    type: AvrType,
    inputMapperDto: Constructor<InputMapperDto>,
    readableName: string,
    outputTypes: T & ([OutputTypes] extends [T[number]] ? unknown : never),
  ): void {
    Object.assign(this, { _type: type, _inputMapperDto: inputMapperDto, _readableName: readableName, _outputTypes: outputTypes });
  }

  get type(): AvrType {
    return this._type;
  }

  get inputMapperDto(): Constructor<InputMapperDto> {
    return this._inputMapperDto;
  }

  get readableName(): string {
    return this._readableName;
  }

  get outputTypes(): OutputTypes[] {
    return this._outputTypes;
  }

  fromDto(inputMapperDto: AvrInputMappersType[AvrType]): InputMapperDto {
    const output = new this._inputMapperDto();
    for (const entry of Object.entries(inputMapperDto)) {
      const [key, value] = entry as [keyof (typeof output.mappableFields & typeof output.nonMappableFields), any];

      if (output.mappableFields[key]) {
        output.mappableFields[key]!.value = value;
      } else if (output.nonMappableFields[key as keyof typeof output.nonMappableFields]) {
        output.nonMappableFields[key as keyof typeof output.nonMappableFields]!.value = value;
      } else {
        console.log(key, value);
        throw new Error('Not expected key received from AVR mapper BE DTO');
      }
    }
    return output;
  }

  toServer(inputMapperDto: InputMapperDto): AvrInputMappersType[AvrType] {
    const output = {} as AvrInputMappersType[AvrType];
    for (const entry of Object.entries(inputMapperDto.mappableFields)) {
      const [key, value] = entry as [keyof AvrInputMappersType[AvrType], (typeof inputMapperDto.mappableFields)[any]];
      if (value!.value !== '') {
        output[key] = value!.value;
      }
    }
    for (const entry of Object.entries(inputMapperDto.nonMappableFields)) {
      // don't use similar condition like in mappable fields, theoretically empty string can also be valid value here
      const [key, value] = entry as [keyof AvrInputMappersType[AvrType], (typeof inputMapperDto.mappableFields)[any]];
      output[key] = value!.value;
    }
    return output;
  }
}
