import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UserAutocompleteComponent } from '@shared/components/user-autocomplete/user-autocomplete.component';
import { AclUserPickerComponent } from '@widgets/shared/components/acl-user-picker/acl-user-picker.component';
import { BackgroundImageComponent } from '@widgets/shared/components/background-image/background-image.component';
import { IconPickerComponent } from '@widgets/shared/components/icon-picker/icon-picker.component';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { BorderFormComponent } from './border-form/border-form.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { FlexContentAlignmentFormComponent } from './flex-content-alignment-form/flex-content-alignment-form.component';
import { FlexDirectionFormComponent } from './flex-direction-form/flex-direction-form.component';
import { FontStyleFormOpComponent } from './font-style-form/font-style-form-op.component';
import { FontStyleFormComponent } from './font-style-form/font-style-form.component';
import { BoldActivePipe } from './font-style-form/pipes/bold-active.pipe';
import { FieldStylesPipe } from './font-style-form/pipes/field-styles.pipe';
import { ItalicActivePipe } from './font-style-form/pipes/italic-active.pipe';
import { LabelStylesPipe } from './font-style-form/pipes/label-styles.pipe';
import { TextDecorationActivePipe } from './font-style-form/pipes/text-decoration-active.pipe';
import { MarginFormComponent } from './margin-form/margin-form.component';
import { OverflowFormComponent } from './overflow-form/overflow-form.component';
import { PaddingFormComponent } from './padding-form/padding-form.component';

const pipes = [LabelStylesPipe, BoldActivePipe, ItalicActivePipe, TextDecorationActivePipe, FieldStylesPipe];
const components = [
  FontStyleFormComponent,
  FontStyleFormOpComponent,
  BorderFormComponent,
  MarginFormComponent,
  PaddingFormComponent,
  ColorPickerComponent,
  BackgroundImageComponent,
  OverflowFormComponent,
  FlexDirectionFormComponent,
  FlexContentAlignmentFormComponent,
  IconPickerComponent,
  AclUserPickerComponent,
];

@NgModule({
  declarations: [...pipes, ...components],
  imports: [
    CommonModule,
    ColorPickerModule,
    DropdownModule,
    FormsModule,
    TranslateModule,
    InputTextModule,
    OrderListModule,
    CalendarModule,
    ButtonModule,
    InputNumberModule,
    CheckboxModule,
    UserAutocompleteComponent,
    MultiSelectModule,
  ],
  exports: [...pipes, ...components],
})
export class StyleFormModule {}
