import { ExecCustomJavascriptActionWorkflowSettingsDto } from '@api/models/exec-custom-javascript-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum ExecCustomJavascriptKeys {
  functionBody = 'functionBody',
}

export class WorkflowActionExecCustomJavascriptDto implements ExecCustomJavascriptActionWorkflowSettingsDto {
  functionBody: ExecCustomJavascriptActionWorkflowSettingsDto[ExecCustomJavascriptKeys.functionBody] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionExecCustomJavascriptDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionExecCustomJavascript extends AbstractWorkflowAction<WorkflowActionExecCustomJavascriptDto> {
  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionExecCustomJavascriptDto, conditionConverter, type: WorkflowActionType.EXEC_CUSTOM_JAVASCRIPT, dto });
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }
}
