import { WorkflowActionDto } from '@api/models';
import { AttributeActionHandlerService } from '@workflows/shared';
import { AttributeBasedActionParams } from '@workflows/shared/types/rule-action-params.types';
import { WorkflowOptions } from '@workflows/shared/types/rule-options.types';
import { AbstractWorkflowAction } from './abstract.action';

export abstract class AbstractWorkflowAttributeBasedAction<T extends WorkflowActionDto['actionSettings']> extends AbstractWorkflowAction<T> {
  artifactTypeId: string;
  attributeId: string;
  protected actionAttributeHandler: AttributeActionHandlerService;
  protected options?: WorkflowOptions;

  protected constructor(params: AttributeBasedActionParams<T>) {
    const { actionSettingDto, conditionConverter, actionAttributeHandler, dto, cache, type } = params;

    super({ actionSettingDto, type, dto, conditionConverter });
    this.conditionConverter = conditionConverter;
    this.artifactTypeId = dto?.artifactTypeId || this.artifactTypeId;
    this.attributeId = dto?.attributeId || this.attributeId;
    this.actionAttributeHandler = actionAttributeHandler ?? this.actionAttributeHandler;
    this.options = cache ? new WorkflowOptions(cache) : undefined;
  }

  setActionAttributeHandler(actionAttributeHandler: AttributeActionHandlerService) {
    this.actionAttributeHandler = actionAttributeHandler;
  }

  isValid(): boolean {
    return !!this.actionAttributeHandler;
  }

  isArtifactTypeRequired(): boolean {
    return true;
  }

  isAttributeRequired(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      artifactTypeId: this.artifactTypeId,
      attributeId: this.attributeId,
    };
  }
}
