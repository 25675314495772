import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeSquareBrackets', standalone: true })
export class RemoveSquareBracketsPipe implements PipeTransform {
  transform(str: string): string {
    if (!str) return '';

    return str.replace(/[\[\]]/g, '');
  }
}
