import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantPageService } from '@api/services/tenant-page.service';
import { TranslateService } from '@ngx-translate/core';
import { PagesTableComponent } from '@private/pages/page-management/pages/components/pages-table/pages-table.component';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { lastValueFrom } from 'rxjs';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { PagesService } from './services/pages.service';
import { PagesModel } from './types/pages.types';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [PagesService],
})
export class PagesComponent extends CoreComponent<PagesService, PagesModel> {
  @ViewChild(PagesTableComponent) table: PagesTableComponent;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: PagesService,
    announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
    public readonly tenantPageService: TenantPageService,
    public readonly cache: NewCacheService,
  ) {
    super(route, router, translate, new PagesModel(), service, announcement);
  }

  loadData() {
    return (meta?: Record<string, string>) => lastValueFrom(this.tenantPageService.pageControllerList(meta));
  }

  onCopy(id: string): void {
    this.tenantPageService.pageControllerDuplicate({ id }).subscribe(page => {
      this.cache.data.pages.setItem(page);
      this.router.navigate(['/admin/page-builder', page.id]);
    });
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Pages' }]);
  }
}
