import { DocumentGenerationAvrQueryDto as _QUERY_DTO, DocumentGenerationAvrWidgetSettingsDto as _SETTINGS_DTO } from '@api/models';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGenerationOutputTypes } from '@shared/services/artifact-visual-representation/document-generation/dto/document-generation.input-mapper.dto';

export enum AvrTemplateFileArtifactSources {
  staticArtifact = 'STATIC_ARTIFACT',
  dynamicArtifact = 'DYNAMIC_ARTIFACT',
}

type AvrTemplateFileArtifactSourcesType = Exclude<_SETTINGS_DTO['templateFileArtifactSource'], null>;
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avrTemplateFileArtifactSourcesCheck: [AvrTemplateFileArtifactSourcesType] extends [`${AvrTemplateFileArtifactSources}`]
  ? `${AvrTemplateFileArtifactSources}` extends AvrTemplateFileArtifactSourcesType
    ? true
    : false
  : false = true;

export class DocumentGenerationAvrQueryDto implements _QUERY_DTO {
  outputType: Extract<AvrOutputTypes, _QUERY_DTO['outputType']>;
  templateFileArtifactId: string;

  constructor(dto: Partial<DocumentGenerationAvrQueryDto>) {
    Object.assign(this, dto);
  }
}

export class DocumentGenerationAvrWidgetSettingsDto implements _SETTINGS_DTO {
  outputType: null | (typeof DocumentGenerationOutputTypes)[number] = null;
  templateFileArtifactId: null | string = null;
  templateFileArtifactListeningKey: null | string = null;
  templateFileArtifactSource: null | AvrTemplateFileArtifactSources = null;
}
