import { Component, Input } from '@angular/core';
import { NAME_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { TableColumn } from '@shared/types/table.types';
import { NewTeam } from '@shared/types/team.types';

@Component({
  selector: 'app-team-list-table',
  templateUrl: './team-list-table.component.html',
  styleUrls: ['./team-list-table.component.scss'],
})
export class TeamListTableComponent extends CoreListComponent<NewTeam> {
  @Input() columns: TableColumn[];

  NAME_KEY = NAME_KEY;

  constructor() {
    super();
  }
}
