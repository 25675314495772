import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TypeSystemElementDropdownModule } from '@private/components/type-system-element-dropdown/type-system-element-dropdown.module';
import { ArtifactAttributeFormFieldComponent } from '@shared/components/artifact-attribute-form-field/artifact-attribute-form-field.component';
import { AppAttributeValueEditFieldModule } from '@shared/components/attribute-value-edit-field/attribute-value-edit-field.component';
import { AttributeAutocompleteDirective } from '@shared/components/context-autocomplete/directives';
import { FormulaAutocompleteDirective } from '@shared/components/context-autocomplete/directives/formula-autocomplete.directive';
import { UserAutocompleteComponent } from '@shared/components/user-autocomplete/user-autocomplete.component';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { WorkflowAttributeSelectorComponent } from '@workflows/components/common';
import { ActionCopyAttributeValueComponent } from '@workflows/components/rule/action/attribute/copy-attribute-value/action-copy-attribute-value.component';
import { ActionSetAttributeValueComponent } from '@workflows/components/rule/action/attribute/set-attribute-value/action-set-attribute-value.component';
import { ActionCreateArtifactsComponent } from '@workflows/components/rule/action/backend/create-artifacts/action-create-artifacts.component';
import { ActionCreateLinksComponent } from '@workflows/components/rule/action/backend/create-links/action-create-links.component';
import { ActionDeleteArtifactsComponent } from '@workflows/components/rule/action/backend/delete-artifacts/action-delete-artifacts.component';
import { ActionDeleteLinksComponent } from '@workflows/components/rule/action/backend/delete-links/action-delete-links.component';
import { ActionExecCustomJavascriptComponent } from '@workflows/components/rule/action/backend/exec-custom-javascript/action-exec-custom-javascript.component';
import { ActionFetchDataImapComponent } from '@workflows/components/rule/action/backend/fetch-data-imap/action-fetch-data-imap.component';
import { ActionFetchDataComponent } from '@workflows/components/rule/action/backend/fetch-data/action-fetch-data.component';
import { ActionGetArtifactsComponent } from '@workflows/components/rule/action/backend/get-artifacts/action-get-artifacts.component';
import { ActionGetLinksComponent } from '@workflows/components/rule/action/backend/get-links/action-get-links.component';
import { ActionManageUserApplicationsComponent } from '@workflows/components/rule/action/backend/manage-user-applications/action-manage-user-applications.component';
import { ActionScheduleWorkflowComponent } from '@workflows/components/rule/action/backend/schedule-workflow/action-schedule-workflow.component';
import { ActionSendEmailComponent } from '@workflows/components/rule/action/backend/send-email/action-send-email.component';
import { ActionThrowErrorComponent } from '@workflows/components/rule/action/backend/throw-error/action-throw-error.component';
import { ActionUpdateArtifactsComponent } from '@workflows/components/rule/action/backend/update-artifacts/action-update-artifacts.component';
import { WorkflowRuleConditionComponent } from '@workflows/components/rule/condition';
import { WorkflowRuleDateConditionComponent } from '@workflows/components/rule/condition/components/workflow-rule-date-condition/workflow-rule-date-condition.component';
import { GetDateAttributesPipe } from '@workflows/components/rule/condition/pipes/get-date-attributes.pipe';
import { GetDateOperationTypesPipe } from '@workflows/components/rule/condition/pipes/get-date-operation-types.pipe';
import { ShowRuleOwnerTypeAndOwnerPipePipe } from '@workflows/components/rule/pipes/show-rule-owner-type-and-owner.pipe';
import { ShowRuleOwnerPipe } from '@workflows/components/rule/pipes/show-rule-owner.pipe';
import { AllowOnlyManualConditionsPipe } from '@workflows/pipes/allow-only-manual-conditions.pipe';
import { CanRuleBeUnassignedPipe } from '@workflows/pipes/can-rule-be-unassigned.pipe';
import { GetRuleDefaultArtifactTypePipe } from '@workflows/pipes/get-rule-default-artifact-type.pipe';
import { IsWorkflowOwnedByPagePipe } from '@workflows/pipes/is-global-workflow-owned-by-page.pipe';
import { AutoFocus } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Ripple } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { WorkflowsPipesModule } from '../pipes';
import { WorkflowsPageAdministrationComponent } from './administration/page';
import {
  ActionCalculateComponent,
  ActionCalculateExpressionComponent,
  ActionLinkFromLinkComponent,
  ActionValueFromLinkComponent,
  WorkflowRuleActionComponent,
  WorkflowRuleComponent,
  WorkflowRuleTriggerComponent,
} from './rule';
import { ActionFormulaComponent } from './rule/action/formula/action-formula.component';
import { IsActionCommonAttributeTypePipe } from './rule/action/pipes/is-action-common-attribute-type.pipe';
import { ShowModifyValueOperationPickerPipe } from './rule/action/pipes/show-modify-value-operation-picker.pipe';
import { GetConditionOperationTypesPipe } from './rule/condition/pipes/get-condition-operation-types.pipe';
import { IsOwnerDisabledPipe } from './rule/pipes/is-owner-disabled.pipe';
import { IsOwnerTypeDisabledPipe } from './rule/pipes/is-owner-type-disabled.pipe';
import { IsTriggerEditablePipe } from './rule/pipes/is-trigger-editable.pipe';
import { IsUsedInDisabledPipe } from './rule/pipes/is-used-in-disabled.pipe';
import { ShowIsGlobalPipe } from './rule/pipes/show-is-global.pipe';
import { ShowTriggerButtonsPipe } from './rule/pipes/show-trigger-buttons.pipe';
import { ShowUsedInPipe } from './rule/pipes/show-used-in.pipe';
import { TriggerManualComponent } from './rule/trigger/manual/trigger-manual.component';

const components = [
  WorkflowsPageAdministrationComponent,
  WorkflowRuleComponent,
  WorkflowRuleTriggerComponent,
  WorkflowRuleActionComponent,
  ActionCalculateExpressionComponent,
  ActionCalculateComponent,
  ActionValueFromLinkComponent,
  ActionLinkFromLinkComponent,
  WorkflowRuleConditionComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    CheckboxModule,
    InputTextareaModule,
    SplitButtonModule,
    TabViewModule,
    TooltipModule,
    WorkflowsPipesModule,
    OverlayPanelModule,
    TypeSystemElementDropdownModule,
    ArtifactAttributeFormFieldComponent,
    AppAttributeValueEditFieldModule,
    DataTypePipesModule,
    CommonPipesModule,
    CalendarModule,
    EditorComponent,
    InputNumberModule,
    TriStateCheckboxModule,
    ActionFetchDataImapComponent,
    AttributeAutocompleteDirective,
    FormulaAutocompleteDirective,
    GetDateOperationTypesPipe,
    WorkflowRuleDateConditionComponent,
    GetRuleDefaultArtifactTypePipe,
    AutoFocus,
    StyleFormModule,
    Ripple,
    GetDateAttributesPipe,
    WorkflowAttributeSelectorComponent,
    CanRuleBeUnassignedPipe,
    IsWorkflowOwnedByPagePipe,
    AllowOnlyManualConditionsPipe,
    UserAutocompleteComponent,
  ],
  exports: [...components],
  declarations: [
    ...components,
    // pipes
    ShowRuleOwnerTypeAndOwnerPipePipe,
    ShowUsedInPipe,
    IsOwnerTypeDisabledPipe,
    IsOwnerDisabledPipe,
    ShowRuleOwnerPipe,
    ShowIsGlobalPipe,
    IsUsedInDisabledPipe,
    ShowTriggerButtonsPipe,
    IsTriggerEditablePipe,
    GetConditionOperationTypesPipe,
    IsActionCommonAttributeTypePipe,
    ShowModifyValueOperationPickerPipe,
    // BE actions
    ActionSendEmailComponent,
    ActionScheduleWorkflowComponent,
    ActionExecCustomJavascriptComponent,
    ActionGetArtifactsComponent,
    ActionCreateArtifactsComponent,
    ActionUpdateArtifactsComponent,
    ActionDeleteArtifactsComponent,
    ActionFetchDataComponent,
    ActionGetLinksComponent,
    ActionCreateLinksComponent,
    ActionDeleteLinksComponent,
    ActionManageUserApplicationsComponent,
    ActionThrowErrorComponent,
    // others
    ActionCopyAttributeValueComponent,
    ActionSetAttributeValueComponent,
    WorkflowRuleConditionComponent,
    TriggerManualComponent,
    ActionFormulaComponent,
  ],
  providers: [],
})
export class WorkflowsComponentsModule {}
