import { Pipe, PipeTransform } from '@angular/core';
import { DateRangeFilterEnum } from '@shared/types/filter.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { ConditionDateFilterType } from '@workflows/types/conditions/rule-condition';

@Pipe({ name: 'isFilterNumericType' })
export class IsFilterNumericTypePipe implements PipeTransform {
  constructor(private readonly filterMetaDataUtil: FilterMetadataUtil) {}

  transform(filterType: ConditionDateFilterType): boolean {
    return this.filterMetaDataUtil.isFilterNumeric(filterType as DateRangeFilterEnum);
  }
}
