import { QrCodesAvrQueryDto as _QUERY_DTO, QrCodesAvrWidgetSettingsDto as _SETTINGS_DTO } from '@api/models';
import { AvrOutputTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { QrCodesOutputTypes } from '@shared/services/artifact-visual-representation/qr-codes/dto/qr-codes.input-mapper.dto';

export class QrCodesAvrQueryDto implements _QUERY_DTO {
  outputType: Extract<AvrOutputTypes, _QUERY_DTO['outputType']>;

  constructor(dto: Partial<QrCodesAvrQueryDto>) {
    Object.assign(this, dto);
  }
}

export class QrCodesAvrWidgetSettingsDto implements _SETTINGS_DTO {
  outputType: null | (typeof QrCodesOutputTypes)[number] = null;
}
