<div cdkDropList (cdkDropListDropped)="dropItem($event)" class="pl-3 pr-3 custom-script-main">
  @for (scriptModel of scriptModels; track scriptModel.id; let index = $index) {
    <div class="grid mt-2 custom-script-wrapper" cdkDrag>
      <div class="col-1 p-1">
        <i class="bi bi-grip-vertical" cdkDragHandle pTooltip="{{'Move' | translate}}" tooltipPosition="top"
           pRipple></i>
      </div>
      <div class="col-9 p-1">
        {{ scriptModel.name }}
      </div>
      <div class="col-1 p-1 pt-2 center cursor-pointer">
        <i class="pi" [class]="m.editingScriptId === scriptModel.id ? 'pi-check': 'pi-pencil'"
           (click)="toggleEdit(scriptModel)"></i>
      </div>
      <div class="col-1 p-1 right">
        <a (click)="remove(index)" class="p-1 pi pi-trash p-button p-button-rounded p-button-text p-button-danger"
           pRipple> </a>
      </div>

      @if (m.editingScriptId === scriptModel.id) {
        <div class="col-3"></div>
        <div class="col-3 flex items-center">
          <p-radioButton name="ScriptType" [value]="ScriptType.external" [(ngModel)]="scriptModel.type"
                         inputId="ScriptTypeExternal"></p-radioButton>
          <label for="ScriptTypeExternal" class="ml-2" translate>External</label>
        </div>

        <div class="col-3 flex items-center">
          <p-radioButton name="ScriptType" [value]="ScriptType.custom" [(ngModel)]="scriptModel.type"
                         inputId="ScriptTypeCustom"></p-radioButton>
          <label for="ScriptTypeCustom" class="ml-2" translate>Custom</label>
        </div>
        <div class="col-3"></div>

        <div class="col-12">
          <p-floatLabel>
            <input pInputText [id]="'script-name-' + scriptModel.id" class="full-width-container"
                   [(ngModel)]="scriptModel.name" />
            <label [for]="'script-name-' + scriptModel.id" translate>Script name</label>
          </p-floatLabel>
        </div>

        @if (scriptModel.type === ScriptType.external) {
          <div class="col-12">
            <p-floatLabel>
              <input pInputText [id]="'script-url-' + scriptModel.id" class="full-width-container"
                     [(ngModel)]="scriptModel.url" />
              <label [for]="'script-url-' + scriptModel.id" translate>Script URL</label>
            </p-floatLabel>
          </div>

          <div class="col-6 flex items-center">
            <p-checkbox [(ngModel)]="scriptModel.defer" [binary]="true" inputId="wait" />
            <label for="wait" class="ml-2" translate>Wait script load</label>
          </div>

          <div class="col-6 flex items-center">
            <p-checkbox [(ngModel)]="scriptModel.isModule" [binary]="true" inputId="isModule" />
            <label for="isModule" class="ml-2" translate>Is module</label>
          </div>
        }

        @if (scriptModel.type === ScriptType.custom) {
          <div class="col-12">
            <p-floatLabel>
              <textarea pInputTextarea [id]="'script-body-' + scriptModel.id" class="full-width-container"
                        [(ngModel)]="scriptModel.body" [autoResize]="true"></textarea>
              <label [for]="'script-body-' + scriptModel.id" translate>Script body</label>
            </p-floatLabel>
          </div>
        }

        <div class="col-6 flex items-center">
          <p-checkbox [(ngModel)]="scriptModel.disabled" [binary]="true" inputId="disabled" />
          <label for="disabled" class="ml-2" translate>Temporary disable</label>
        </div>
      }
    </div>
  }

  <div class="col-12 center p-0 pt-2">
    <i class="bi bi-plus-circle-fill cursor-pointer pl-4 pr-4" (click)="add()"></i>
  </div>
  <div class="col-12 center p-0">
    <button class="p-button p-button-text p-0" pButton type="button" (click)="add()">{{ 'Add script' | translate }}
    </button>
  </div>

  <div class="parse-text-button">
    <i class="bi bi-plus-circle cursor-pointer p-1" pTooltip="Parse from HTML string" (click)="showDialog()"></i>
  </div>
</div>

<p-dialog header="Parse HTML string to Custom Scripts" [modal]="true" [(visible)]="m.showParsingDialog"
          [style]="{ width: '70rem' }"
          (onHide)="m.htmlStringForParsing = ''">
  <div class="flex items-center gap-4 mb-4 pt-1">
    <textarea pInputTextarea class="full-width-container" [(ngModel)]="m.htmlStringForParsing" [autoResize]="true"
              style="min-height: 300px"></textarea>
  </div>

  <div class="flex justify-content-end gap-2">
    <p-button label="Cancel" severity="secondary" (click)="m.showParsingDialog = false" />
    <p-button label="Parse" [disabled]="!m.htmlStringForParsing" (click)="parseHtmlString()" />
  </div>
</p-dialog>