<div class="menu-widget">
  <app-menu-widget-settings *ngIf="!isLayoutMode" [m]="m" [s]="s"></app-menu-widget-settings>

  <div *ngIf="isLayoutMode && m?.items.menu?.length"
       [ngClass]="{'horizontal': m.settings.menu.type ===  horizontalMenuType}"
       [ngStyle]="m.styles.menu"
       class="menu">

    <ng-container *ngIf="m?.settings?.menu?.flexDirection === 'row'; else verticalMenu">
      <ul *ngFor="let menuContainer of m.horizontalMenuContainers" [class]="'menu-list ' + menuContainer ">
        <app-menu-item
          *ngFor="let item of m.items.menu | splitUpMenuItemsByPosition: menuContainer: m.settings | filterIrrelevantMenuItems | async"
          [ngClass]="{'stretched': m.settings.menu.stretch}"
          [item]="item"
          [m]="m"
          [queryParams]="queryParams">
        </app-menu-item>
      </ul>
    </ng-container>

    <ng-template #verticalMenu>
      <ul [style.flex-direction]="m.settings.menu.flexDirection" class="menu-list vertical">
        <app-menu-item *ngFor="let item of m.items.menu | filterIrrelevantMenuItems | async" [item]="item" [m]="m"
                       [queryParams]="queryParams"></app-menu-item>
      </ul>
    </ng-template>

  </div>
</div>
