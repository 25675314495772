/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GroupResponseDto } from '../../models/group-response-dto';

export interface TemplateControllerGroupby$Params {
  filter?: string;
  groupBy: string;
  dateFormat?: 'YEAR' | 'MONTH' | 'DAY' | 'WEEK' | 'HOUR' | 'MINUTE' | 'SECOND';
  limit?: number;
  skip?: number;

}

export function templateControllerGroupby(http: HttpClient, rootUrl: string, params: TemplateControllerGroupby$Params, context?: HttpContext): Observable<StrictHttpResponse<GroupResponseDto>> {
  const rb = new RequestBuilder(rootUrl, templateControllerGroupby.PATH, 'get');
  if (params) {
    rb.query('filter', params.filter, {});
    rb.query('groupBy', params.groupBy, {});
    rb.query('dateFormat', params.dateFormat, {});
    rb.query('limit', params.limit, {});
    rb.query('skip', params.skip, {});
  }
  rb.header('ngsw-bypass', 'true', {});

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GroupResponseDto>;
    })
  );
}

templateControllerGroupby.PATH = '/api/tenant/template/groupby';
