<app-loader [loading]="m.inProgress"></app-loader>
<app-attribute-autocomplete-overlay></app-attribute-autocomplete-overlay>
<app-formula-autocomplete-overlay></app-formula-autocomplete-overlay>

<div class="col-12 top_box">
  <div>
    @if (m.rule && m.rule.id && m.rule.ownerType === RuleOwnerType.CUSTOM) {
      <button pButton label="{{ 'Run this workflow' | translate }}" type="button" class="p-button p-button-secondary"
              (click)="openRunWorkflowOverlay($event)"></button>
    }
    <a [routerLink]="['/admin/workflow-list']">
      <button pButton label="{{ 'Cancel' | translate }}" type="button" class="p-button p-button-warning"></button>
    </a>
    <button pButton label="{{ 'Save' | translate }}" type="button" class="p-button p-button-success" (click)="save()">
      <i *ngIf="m.inProgress" class="pi pi-spin pi-spinner"></i>
    </button>
  </div>
</div>
<app-workflows-rule *ngIf="m.rule && m.options.pages.loaded && m.options.artifactTypes.loaded"
                    [rule]="m.rule"
                    [artifactTypes]="m.options.artifactTypes.list"
                    [attributes]="m.options.attributes"
                    [dataTypes]="m.options.dataTypes"
                    [linkTypes]="m.options.linkTypes"
                    [applications]="m.options.applications"
                    [pages]="m.options.pages"
                    [isNew]="m.isCreateMode"
                    [editable]="true"
                    [ruleAdministrationType]="RuleAdministrationType.GENERAL"
                    [scrollable]="false"
></app-workflows-rule>

@if (m.rule?.id) {
  <p-overlayPanel #runWorkflowOverlayPanel dismissable="false" showCloseIcon="true" appendTo="body">
    <app-workflow-custom-run #customRunComponent [ruleId]="m.rule.id"></app-workflow-custom-run>
  </p-overlayPanel>
}
