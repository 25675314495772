import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IsFilterDiffTypePipe } from '@shared/components/date-filter/pipes/is-filter-diff-type.pipe';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { NewAttribute } from '@shared/types/attribute.types';
import { WorkflowAttributeSelectorComponent } from '@workflows/components/common';
import { RuleConditionOperationTypeDefinitions } from '@workflows/types/condition-operation-definitions.types';
import { RuleCondition } from '@workflows/types/conditions/rule-condition';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { BaseDataType } from '../../../../../../../../e2e/enums/base-data-type-enum/baseDataType.enum';

@Component({
  selector: 'app-workflow-rule-date-condition',
  standalone: true,
  imports: [
    CalendarModule,
    InputNumberModule,
    ElvisSharedModule,
    ElvisSharedModule,
    FormsModule,
    TranslateModule,
    IsFilterDiffTypePipe,
    DropdownModule,
    WorkflowAttributeSelectorComponent,
    WorkflowAttributeSelectorComponent,
  ],
  templateUrl: './workflow-rule-date-condition.component.html',
  styleUrls: ['./workflow-rule-date-condition.component.scss', '../../../../workflow-common.scss'],
})
export class WorkflowRuleDateConditionComponent {
  @Input() condition: RuleCondition;
  @Input() dateAttributes: NewAttribute[];
  readonly operationTypes = RuleConditionOperationTypeDefinitions.SIMPLE_DEFINITIONS[BaseDataType.integer];

  constructor() {}

  onAttributeChange(attributeId: string): void {
    this.condition.destination.value = attributeId;
  }
}
