import { ArtifactLinkResponseDto } from '@api/models';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { TeamResponseDto } from '@api/models/team-response-dto';
import { Application } from '@private/pages/application-management/application/types/application.types';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DateRangeFilterEnum, LinkFilterEnum } from '@shared/types/filter.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { SortTypeEnum, SortTypeValueEnum } from '@shared/types/sort.types';
import { NewTableColumn } from '@shared/types/table.types';
import { NewTeam } from '@shared/types/team.types';
import { range } from 'lodash';
import { GroupCollapseEnum, GroupOrderEnum, PaginationSettingEnum } from '../../shared/components/artifact-list-table/types/list-widget-grouping.types';
import { ListWidgetOptions } from '../../shared/components/artifact-list-table/types/list-widget-options.types';

export class LinkPopupOptions extends ListWidgetOptions {
  artifactTypes = new ListContainer<NewArtifactType>();
  linkTypes = new ListContainer<LinkType>();
  attributes = new ListContainer<NewAttribute>();
  dataTypes = new ListContainer<NewDataType>();
  users = new ListContainer<ArtifactLinkResponseDto>();
  applications = new ListContainer<Application>();
  teams = new ListContainer<NewTeam>();
  pages = new ListContainer<Page>();

  relevantArtifactTypes: SelectOption<string, NewArtifactType>[] = [];
  columns = new ListContainer<NewTableColumn>();
  applicableColumns: NewTableColumn[] = [];
  linkTypesOptions: SelectOption<string, LinkType, LinkDirection>[] = [];
  linkFilter: SelectOption<string, LinkFilterEnum>[] = [];
  artifactTypesByApplication = new ListContainer<NewArtifactType>();
  systemAttributes = new ListContainer<NewAttribute>();

  groupByDateRange: SelectOption<string, DateRangeFilterEnum>[] = [];
  groupByDateOffset: SelectOption<string, number>[] = [];
  groupPagination: SelectOption<typeof PaginationSettingEnum, typeof PaginationSettingEnum>[] = [];
  groupCollapse: SelectOption<string, GroupCollapseEnum>[] = [];
  groupSort: SelectOption<string, SortTypeValueEnum>[] = [];
  groupOrder: SelectOption<string, GroupOrderEnum>[] = [];

  constructor() {
    super();
    this.setStaticOptions();
  }

  async init(cache: NewCacheService): Promise<void> {
    const { attributes, linkTypes, dataTypes, artifactTypes, applications, teams, pages } = cache.data;

    this.artifactTypes.setList(
      artifactTypes.value!.map(dto => new NewArtifactType(dto as ArtifactTypeResponseDto)),
      ID_KEY,
    );
    this.attributes.setList(
      attributes.value!.map(dto => new NewAttribute(dto as AttributeResponseDto)),
      ID_KEY,
    );
    this.linkTypes.setList(
      linkTypes.value!.map(dto => new LinkType(dto as LinkTypeResponseDto)),
      ID_KEY,
    );
    this.dataTypes.setList(
      dataTypes.value!.map(dto => new NewDataType(dto as DataTypeResponseDto)),
      ID_KEY,
    );
    this.applications.setList(
      applications.value!.map(dto => new Application(dto as ApplicationResponseDto)),
      ID_KEY,
    );
    this.teams.setList(
      teams.value!.map(dto => new NewTeam(dto as TeamResponseDto)),
      ID_KEY,
    );
    this.pages.setList(
      pages.value!.map(dto => new Page(dto as PageResponseDto)),
      ID_KEY,
    );

    this.linkTypesOptions = this.linkTypes.list
      .map(linkType => [
        new SelectOption(linkType.outgoingName, linkType, LinkDirection.outgoing),
        new SelectOption(linkType.incomingName, linkType, LinkDirection.incoming),
      ])
      .flat();
  }

  setGroupCollapseOptions(paginationSetting: PaginationSettingEnum): void {
    this.groupCollapse = Object.keys(GroupCollapseEnum).map(option => {
      const disabled = option === GroupCollapseEnum.expandAll && paginationSetting === PaginationSettingEnum.perGroup;
      return new SelectOption(option, GroupCollapseEnum[option as keyof typeof GroupCollapseEnum], null, disabled);
    });
  }

  private setStaticOptions(): void {
    this.linkFilter = Object.keys(LinkFilterEnum).map(
      key => new SelectOption(key, LinkFilterEnum[key as keyof typeof LinkFilterEnum], null, key !== LinkFilterEnum.containsUrlParamKey),
    );
    this.groupByDateOffset = range(-12, 13).map(number => new SelectOption<string, number>('' + number, number));
    this.groupByDateRange = Object.keys(DateRangeFilterEnum).map(
      option => new SelectOption(option, DateRangeFilterEnum[option as keyof typeof DateRangeFilterEnum]),
    );
    this.groupPagination = GetSelectOptionsFromEnum(PaginationSettingEnum);
    this.groupSort = Object.keys(SortTypeEnum).map(
      option => new SelectOption(SortTypeEnum[option as keyof typeof SortTypeEnum], SortTypeValueEnum[option as keyof typeof SortTypeValueEnum]),
    );
    this.groupOrder = Object.keys(GroupOrderEnum).map(option => new SelectOption(option, GroupOrderEnum[option as keyof typeof GroupOrderEnum]));
  }
}
