import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { ArtifactFormatFileDataResponseDto, ArtifactResponseDto } from '@api/models';
import { TranslateModule } from '@ngx-translate/core';
import { FileService } from '@private/services/file.service';
import { FileExtensions } from '@private/types/file-service.types';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent {
  url: string;
  private FileExtensions = FileExtensions;
  private _file: ArtifactResponseDto | File;
  private _isArtifact = false;

  constructor(private readonly config: DynamicDialogConfig, private readonly fileService: FileService) {
    this.file = this.config.data.file;
    this.url = this.config.data.url;
  }

  get isArtifact(): boolean {
    return this._isArtifact;
  }

  get file() {
    return this._file;
  }

  @Input() set file(file: ArtifactResponseDto | File) {
    this._file = file;
    if ((file as ArtifactResponseDto).id) {
      this._isArtifact = true;
    } else {
      this._isArtifact = false;
    }
  }

  get fileName(): string {
    return this.isArtifact ? ((this.file as ArtifactResponseDto).formatData as ArtifactFormatFileDataResponseDto).filename : (this.file as File).name;
  }

  download(): void {
    this.isArtifact && this.fileService.downloadFileArtifact(this.file as ArtifactResponseDto);
  }

  openTab(): void {
    (window as any).open(this.url, '_blank').focus();
  }

  isPDF(fileName: string): boolean {
    return this.getFileExtensions(fileName) === 'pdf';
  }

  isImage(fileName: string): boolean {
    return Object.values(this.FileExtensions).includes(this.getFileExtensions(fileName));
  }

  private getFileExtensions(fileName: string): string {
    return fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
  }
}

@NgModule({
  declarations: [FileViewerComponent],
  imports: [TooltipModule, TranslateModule, CommonModule, ButtonModule, CommonPipesModule],
  exports: [FileViewerComponent],
})
export class FileViewerModule {}
