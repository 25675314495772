<p-dialog
  (onHide)="onLinkingPopupHide()"
  [(visible)]="m.displayModal"
  [baseZIndex]="2"
  [contentStyle]="{ minHeight: '250px', paddingBottom: 0, overflow: 'visible' }"
  [draggable]="false"
  [header]="m.directionalLinkType?.label || ''"
  [modal]="true"
  [resizable]="false"
  [appendTo]="'body'"
  [style]="{ width: '80vw', position: 'absolute', top: '5rem' }"
>
  <div class="advanced-container" *ngIf="!isPreviewMode">
    <i (click)="setWidgetAsAdvancedMode()" class="bi bi-tools" pRipple pTooltip="{{ 'Advanced Mode' | translate }}"
       tooltipPosition="top"> </i>
  </div>

  <div class="grid">
    <div class="col-6" *ngIf="m.options.relevantArtifactTypes?.length <= 1"></div>
    <div class="col-6" *ngIf="m.options.relevantArtifactTypes?.length > 1">
      <span class="dynamic-label p-float-label">
        <p-dropdown
          (onChange)="onArtifactTypeChange()"
          (onShow)="storeDto()"
          [(ngModel)]="m.selected.artifactType"
          [autoDisplayFirst]="false"
          [filter]="true"
          [options]="m.options.relevantArtifactTypes"
          appendTo="body"
          class="modal-dropdown"
          inputId="linkingModalArtifactTypeSelect"
        >
        </p-dropdown>
        <label *ngIf="!m.selected.artifactType && creatingPopUpModel.widget" class="dynamic-label-text"
               [for]="'linkingModalArtifactTypeSelect'">
          {{ 'Artifact type' | translate }}
        </label>
      </span>
    </div>

    <div class="col-6 add-button-container">
      <ng-container *ngIf="m.selected.artifactType?.defaultWidgets?.artifactWidgetTemplateId">
        <button class="p-button" icon="pi pi-plus" iconPos="left" [label]="'Create' | translate" pButton type="button"
                (click)="openCreatePopUp()"></button>
      </ng-container>
    </div>
  </div>

  <div class="list" style="overflow-y: auto">
    <app-artifact-list-widget-table
      *ngIf="m.settings.showTable; else loading"
      style="display: block; max-height: calc(90vh - 250px)"
      [addButtonItems]="m.addButtonItems"
      [ids]="m.ids"
      [loadDataMethod]="helper.getLoadDataMethod()"
      [options]="m.options"
      [selected]="m.selected"
      [settings]="m.settings"
      [state]="m.state"
      [restrictions]="restrictions"
      [hash]="m.hash"
      [sortByAttribute]="true"
      [queryParams]="queryParams"
      [isInLinkingPopup]="true"
    >
    </app-artifact-list-widget-table>
  </div>

  <ng-template pTemplate="footer">
    <!--    TODO Message if linking is not allowed-->
    <span *ngIf="false">Can`t add link because of link restrictions</span>
    <div class="dialog-footer">
      <button (click)="close()" class="p-button p-button-warning" label="{{ 'Cancel' | translate }}" pButton
              type="button"></button>
      <!--      [disabled]="!m.selected.artifacts.length || disableSaveDueToSingleRestrictions"-->
      <button
        (click)="create()"
        [disabled]="!(m.selected.artifacts.length || m.selected.artifact)"
        class="p-button p-button-success"
        label="{{ m.selected.artifacts.length | getCreateLinkButtonLabel : m.settings.multiselect | translate }}"
        pButton
        pRipple
        type="button"
      ></button>
    </div>
  </ng-template>
</p-dialog>

<p-sidebar
  (onHide)="onSettingsSidebarHide()"
  [dismissible]="true"
  [modal]="false"
  [style]="{ width: '40em' }"
  [visible]="m.isSettingsSidebarVisible"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <app-sidebar-header [name]="'Linking popup'" [showPositionControls]="false"></app-sidebar-header>
  </ng-template>
  <br />

  <div *ngIf="!isPreviewMode && m" class="list_top">
    <ng-container>
      <div class="grid list_top__filters">
        <div class="col-6">
          <label for="columns" translate> Columns</label>
          <p-multiSelect
            (onChange)="onSelectedColumnsChange($event.value)"
            [ngModel]="m.selected.columns"
            [options]="m.options.applicableColumns"
            defaultLabel="Select columns"
            optionLabel="label"
            id="columns"
          >
            <ng-template let-option pTemplate="item">
              <span class="link-label">{{ option.label }}</span>
              <app-link-option-with-arrow [direction]="option.meta.linkDirection"></app-link-option-with-arrow>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>

      <app-link-popup-table-settings [settings]="m.settings" [options]="m.options"
                                     [onGroupAttributeChangeCb]="onGroupAttributeChangeCb.bind(this)">
      </app-link-popup-table-settings>
    </ng-container>
  </div>
</p-sidebar>

<ng-template #loading>
  <div>loading...</div>
</ng-template>

<p-dialog
  [(visible)]="creatingPopUpModel.visible"
  [baseZIndex]="3"
  [contentStyle]="{ minHeight: '250px', paddingBottom: 0, overflow: 'auto' }"
  [draggable]="true"
  [header]="creatingPopUpLabel"
  [modal]="true"
  [resizable]="false"
  [appendTo]="'body'"
  [style]="{ width: '50vw', position: 'absolute', top: '6rem', maxHeight: '80vh', minWidth: '300px' }"
>
  <app-widget-container
    *ngIf="m.selected.artifactType && creatingPopUpModel.visible"
    [isClickable]="false"
    [isLayoutMode]="true"
    [isListMatrixCard]="false"
    [isPreviewMode]="isPreviewMode"
    [disabled]="isPreviewMode"
    [widget]="creatingPopUpModel.widget"
    appWidgetContainerStopPropagation
  >
  </app-widget-container>
</p-dialog>
