import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedMethods } from '@shared/methods/shared.methods';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
  @Input() onChangeCb: (color?: string) => void;
  @Input() label: string;
  @Input() color: string;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() hideInput: boolean = false;
  @Input() appendToBody: boolean = false;
  @Output() colorChange = new EventEmitter<string>();
  uniqueId = SharedMethods.getUniqueId();

  onColorChange(value: string): void {
    this.colorChange.emit(value);
    this.onChangeCb && this.onChangeCb(value);
  }
}
