import { Directive } from '@angular/core';
import { AuthService } from '@api/services';
import { Constants } from '@shared/constants/constants';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { StateKey } from '@shared/types/local-storage.types';

@Directive()
export abstract class BaseSsoService {
  abstract tooltip: string;
  abstract content: string;

  windowRef: Window | null = null;
  cbTimeout: any;
  storageCb: () => void;

  constructor(
    protected readonly authService: AuthService,
    protected readonly authorizationService: AuthorizationService,
    protected readonly localStorageService: LocalStorageService,
  ) {}

  abstract onClickHandler(redirectIfLogged?: () => void): Promise<void>;

  protected async clickHandler(redirectAfterLogin: () => void, sessionFlow: string): Promise<void> {
    if (this.windowRef !== null && !this.windowRef.closed) {
      this.windowRef.focus();
      return;
    }

    if (!this.authorizationService.getAnonymousUser) await this.authorizationService.anonymousLogin();

    const user = this.authorizationService.getAnonymousUser;
    const googleSessionFlow = (user?.tenant?.ssoSessionFlows as any)?.['google'] || '';
    const path = ((this.authService.constructor as any).AuthControllerFlowAuthorizePath || '').replace('{id}', googleSessionFlow);
    const ssoUrl = `${this.authService.rootUrl}${path}?domain=${encodeURIComponent(window.location.origin)}`;

    this.windowRef = window.open(ssoUrl, '_blank', 'location=no,toolbar=no,menubar=no,width=600,height=700');
    this.storageCb = () => this.onUserSavedToStorage(redirectAfterLogin);
    window.addEventListener('storage', this.storageCb);
  }

  private onUserSavedToStorage(redirectAfterLogin: () => void): void {
    const token = this.localStorageService.getFromState(StateKey.session, Constants.token);
    const user = this.localStorageService.getFromState(StateKey.session, Constants.user);

    if (token && user) {
      window.removeEventListener('storage', this.storageCb);
      redirectAfterLogin();
    } else {
      this.cbTimeout && clearTimeout(this.cbTimeout);
      this.cbTimeout = setTimeout(() => this.onUserSavedToStorage(redirectAfterLogin), 200);
    }
  }
}
