import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { ActionType } from '@widgets/shared/types/click-action-settings-dto';
import { ArtifactWidgetItemActions, DefaultElvisActions } from '../types/actions.types';

export const IsActionArtifactWidgetType = (actionType: ActionType): boolean => {
  return Object.keys(ArtifactWidgetItemActions).includes(actionType);
};

export const IsActionCustomEventType = (actionType: ActionType): boolean => {
  return actionType === DefaultElvisActions.customEvent;
};

export const IsActionItemClickType = (actionType: ActionType): boolean => {
  return Object.keys(ArtifactListItemClickAction).includes(actionType);
};

export const IsActionDoNothing = (actionType: ActionType): boolean => {
  return actionType === DefaultElvisActions.doNothing;
};
