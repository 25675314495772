import { DeleteLinksActionWorkflowSettingsDto } from '@api/models';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { WorkflowActionType } from '@workflows/types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum DeleteLinksKeys {
  ids = 'ids',
  hardDelete = 'hardDelete',
}

export class WorkflowActionDeleteLinksDto implements DeleteLinksActionWorkflowSettingsDto {
  ids: DeleteLinksActionWorkflowSettingsDto[DeleteLinksKeys.ids] = { value: [''], isDynamic: false };
  hardDelete: DeleteLinksActionWorkflowSettingsDto[DeleteLinksKeys.hardDelete] = { value: 'false', isDynamic: false };

  constructor(action?: WorkflowActionDeleteLinksDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionDeleteLinks extends AbstractWorkflowAction<DeleteLinksActionWorkflowSettingsDto> {
  hardDelete = false;

  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionDeleteLinksDto, conditionConverter, type: WorkflowActionType.DELETE_LINKS, dto });
    dto && (this.hardDelete = (dto.actionSettings as DeleteLinksActionWorkflowSettingsDto)[DeleteLinksKeys.hardDelete].value === 'true');
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    const hardDeleteActionSettings = {
      isDynamic: false,
      value: this.hardDelete ? 'true' : 'false',
    };

    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [DeleteLinksKeys.hardDelete]: hardDeleteActionSettings,
      },
    };
  }
}
