<p-table *ngIf="loadDataMethod"
         (onLazyLoad)="onLazyLoad($event)"
         [columns]="columns"
         [filters]="{deleted: [{ value: null, matchMode: 'equals', operator: 'and' }]}"
         [lazy]="true"
         [loading]="loading"
         [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
         [resizableColumns]="true"
         [rowsPerPageOptions]="meta.limitOptions"
         [rows]="meta.limit"
         [totalRecords]="meta.totalCount"
         [value]="data"
         dataKey="id"
         styleClass="p-datatable-striped"
         appThirdClickUndoSort
>
  <ng-template let-columns pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of columns">
        <th class="p-text-truncate" pResizableColumn>
          {{ col.label }}

          <p-sortIcon [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>
          <ng-container [ngSwitch]="col.key">
            <ng-container *ngSwitchCase="WORKFLOW_TYPE_KEY" [ngTemplateOutlet]="enumFilter"
                          [ngTemplateOutletContext]="{ col, options: options.workflowTypeOptions, placeholder: 'Type'}"></ng-container>
            <ng-container *ngSwitchCase="WORKFLOW_OWNER_TYPE_KEY" [ngTemplateOutlet]="enumFilter"
                          [ngTemplateOutletContext]="{ col, options: options.workflowOwnerTypeOptions, placeholder: 'Owner type'}"></ng-container>
            <ng-container *ngSwitchCase="CREATED_BY_KEY" [ngTemplateOutlet]="userFilter"
                          [ngTemplateOutletContext]="{ col, placeholder: 'Created by'}"></ng-container>
            <ng-container *ngSwitchCase="NAME_KEY" [ngTemplateOutlet]="defaultFilter"
                          [ngTemplateOutletContext]="{ col }"></ng-container>
          </ng-container>

        </th>
      </ng-container>
      <th>{{ 'Actions' | translate }}</th>
    </tr>


  </ng-template>
  <ng-template let-columns="columns" let-rule pTemplate="body">
    <tr>
      <td *ngFor="let col of columns">
        {{ rule | workflowToTable : col.key | async }}
      </td>
      <td>
        <div class="table_buttons">
          <a [routerLink]="['/admin/workflow', rule.id]">
            <button class="p-button p-button-warning" icon="pi pi-pencil" iconPos="left"
                    label="{{ 'Edit' | translate }}" pButton type="button"></button>
          </a>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length + 1">{{ 'No records found' | translate }}</td>
    </tr>
  </ng-template>

  <ng-template #enumFilter let-col="col" let-options="options" let-placeholder="placeholder">
    <p-columnFilter
      [field]="col.key"
      [showMatchModes]="false"
      [showOperator]="false"
      [showAddButton]="false"
      matchMode="in"
      display="menu"
    >
      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <p-multiSelect [ngModel]="value" [options]="options" [placeholder]="placeholder"
                       (onChange)="filter($event.value)">
          <ng-template let-option pTemplate="item">
            <div>
              <span class="ml-1">{{ option.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
      </ng-template>
    </p-columnFilter>
  </ng-template>

  <ng-template #userFilter let-col="col" let-placeholder="placeholder">
    <p-columnFilter
      [field]="col.key"
      [showMatchModes]="false"
      [showOperator]="false"
      [showAddButton]="false"
      matchMode="in"
      display="menu"
    >
      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <app-user-autocomplete [value]="value"
                               [placeholder]="placeholder"
                               [multipleValues]="true"
                               (valueChange)="filter($event.value)"
        ></app-user-autocomplete>
      </ng-template>
    </p-columnFilter>
  </ng-template>

  <ng-template #defaultFilter let-col="col">
    <p-columnFilter matchMode="contains" type="text" [field]="col.key" display="menu"></p-columnFilter>
  </ng-template>
</p-table>
