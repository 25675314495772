import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { BoxShadowGeneratorComponent } from '@shared/components/box-shadow-generator/box-shadow-generator.component';
import { GradientGeneratorComponent } from '@shared/components/gradient-generator/gradient-generator.component';
import { ImagePointerModule } from '@shared/components/image-pointer/image-pointer.module';
import { SharedBorderSettingsComponent } from '@shared/components/shared-border-settings/shared-border-settings.component';
import { UpdatedStylesNotifierService } from '@shared/services/updated-styles-notifier.service';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { BackgroundTypeEnum, BorderSideEnum, DisplayEnum, StyleOptions } from '@widgets/shared/types/style.types';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CssSettingsVisibility } from '../../types/shared-styles-settings.types';

@Component({
  selector: 'app-shared-styles-settings',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    FormsModule,
    DropdownModule,
    InputTextModule,
    TranslateModule,
    StyleFormModule,
    ImagePointerModule,
    GradientGeneratorComponent,
    BoxShadowGeneratorComponent,
    SharedBorderSettingsComponent,
  ],
  templateUrl: './shared-styles-settings.component.html',
  styleUrl: './shared-styles-settings.component.scss',
})
export class SharedStylesSettingsComponent {
  @Input({ required: true }) applicationId: string;
  @Input({ required: true }) styles: Partial<StylesDto>;
  @Input() settingsVisibility: CssSettingsVisibility = {};

  @Output() onColorChange = new EventEmitter<Partial<StylesDto>>();
  @Output() onChange = new EventEmitter<Partial<StylesDto>>();

  options = new StyleOptions();

  readonly DisplayEnum = DisplayEnum;
  readonly BackgroundTypeEnum = BackgroundTypeEnum;
  protected readonly borderSideEnum = BorderSideEnum;
  private readonly updatedStylesNotifierService = inject(UpdatedStylesNotifierService);

  onBgTypeChange(): void {
    delete this.styles.background;
    this.styles.backgroundImage = '';
    this.styles.backgroundColor = '';
    this.onAnythingChange();
  }

  onBackgroundFileIdChange(id: string) {
    this.styles.backgroundImage = id;
    this.updatedStylesNotifierService.notifyUpdate();
    this.onAnythingChange();
  }

  onBackgroundFileUrlChange(url: string) {
    this.styles.backgroundImage = `url(${url})`;
    this.updatedStylesNotifierService.notifyUpdate();
    this.onAnythingChange();
  }

  onStylesChange(styles: Partial<StylesDto>): void {
    const { backgroundRepeat, backgroundSize, backgroundPosition, backgroundAttachment, backgroundOpacity } = styles;
    Object.assign(this.styles, { backgroundRepeat, backgroundSize, backgroundPosition, backgroundAttachment, backgroundOpacity });
    this.updatedStylesNotifierService.notifyUpdate();
    this.onAnythingChange();
  }

  onAnythingChange(): void {
    this.onChange.emit(this.styles);
  }
}
