import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ConvertToClientDatetime, ConvertToDateByFormat } from '@shared/methods/date.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ArtifactWidgetFormatsMap } from '@widgets/artifact-widget/types/artifact-widget.types';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import moment from 'moment';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';
import {
  ElementDisplayStylesDtoKeysArray,
  FlexContainerStylesDtoKeysArray, StylesDtoKeys
} from '@private/pages/page-management/page-builder-graphical/types/styles-dto';

@Component({
  selector: 'app-display-at-datetime',
  templateUrl: './display-at-datetime.component.html',
  styleUrls: ['./display-at-datetime.component.scss'],
})
export class DisplayAtDatetimeComponent implements OnInit {
  @Input() dataType: NewDataType;
  @Input() attrId: string;
  @Input() formatStyles: FormatStyles;
  @Input() isDate = false;
  @Input() columnStylesEvent: RuntimeStateNotification<any> | undefined;
  @Input() formatsMap: ArtifactWidgetFormatsMap;
  @Input() selectedVariant: string;

  values: string[];
  topContainerRelevantStyleKeys: StylesDtoKeys[] = [];

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setCssKeys();
  }

  @Input() set artifactNew(art: NewArtifact) {
    if (!art) return;
    setTimeout(() => {
      const attr = art.attributes?.[this.attrId];
      this.setValue(attr);
    });
  }

  @Input() set attributeNew(attr: NewClientAttribute) {
    setTimeout(() => {
      this.setValue(attr);
    });
  }

  private setValue(attr: NewClientAttribute): void {
    this.values = [''];

    if (!attr?.value) {
      return;
    }

    const dateArr: any[] = Array.isArray(attr.value)? attr.value: [attr.value];

    if (attr && dateArr.length) {
      const displayVariant = this.formatsMap
        ? (attr.value && this.formatsMap.attribute[attr.value].value.displayMetadata?.selectedVariantCode) || ''
        : this.selectedVariant;

      this.values = dateArr.map(dateStr => {
        const date = new Date(dateStr);
        const isDate = this.dataType?.isDate || this.isDate;

        if (isDate) {
          return displayVariant ? ConvertToDateByFormat(date, displayVariant, isDate) : moment(dateStr).utc().local().format('DD.MM.Y');
        } else {
          return displayVariant ? ConvertToDateByFormat(date, displayVariant, isDate) : ConvertToClientDatetime(date);
        }
      }) || ['']

      this.cdr.markForCheck();
    }
  }

  private setCssKeys() {
    this.topContainerRelevantStyleKeys = [...FlexContainerStylesDtoKeysArray, ...ElementDisplayStylesDtoKeysArray];
  }
}
