import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { GetDaysBetweenDates, GetStartOfTheDay, IsDateInRange, IsDateValid } from '@shared/methods/date.methods';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { DateDiffsEnum, DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { RuleConditionsUtilService } from '@workflows/services';
import { WorkflowConditionType, WorkflowTriggerEvent } from '@workflows/types';
import { ConditionDateFilterType, NumericComparisonOperationEnum, RuleCondition } from '@workflows/types/conditions/rule-condition';
import { AbstractWorkflowCondition } from './abstract/abstract.condition';

export class WorkflowDatesCondition extends AbstractWorkflowCondition {
  constructor(
    conditionsUtil: RuleConditionsUtilService,
    private readonly filterMetadataUtil: FilterMetadataUtil,
  ) {
    super(conditionsUtil);
    this.type = WorkflowConditionType.DATES;
    this.supportedTypes = [BaseDataType.date, BaseDataType.dateTime];
    this.supportedKinds = [DataTypeKind.simple];
  }

  // TODO: to refactor to isMet(sourceValue: Date, destinationValue: Date)
  isMet(source: Date, condition: RuleCondition): boolean {
    const type = condition.extra!.settings.operationType;

    if (this.isDateRangeType(type)) {
      const dateRange = this.filterMetadataUtil.transformDateRangeEnumToDateRange(type as DateRangeFilterEnum, condition.extra!.settings);
      return IsDateInRange(source, dateRange);
    } else if (this.isDateDiffType(type)) {
      return this.isDiffConditionMet(source, condition);
    }

    return this.isDateConditionMet(source, condition);
  }

  protected getConditionValue(condition: RuleCondition, ruleTriggerEvent?: WorkflowTriggerEvent, artifact?: NewArtifact): any {
    if (condition.extra!.settings.operationType === DateDiffsEnum.diffInDays) {
      if (artifact && ruleTriggerEvent?.payload?.getAttributeValueForArtifactFn) {
        condition.extra!.settings.artifact = artifact;
        condition.extra!.settings.getAttributeValueForArtifactFn = ruleTriggerEvent.payload.getAttributeValueForArtifactFn;
      }

      if (ruleTriggerEvent?.payload?.getAttributeValueFn) {
        condition.extra!.settings.getAttributeValueFn = ruleTriggerEvent.payload.getAttributeValueFn;
      }
    }

    return condition;
  }

  private isDiffConditionMet(source: Date, condition: RuleCondition): boolean {
    const destinationValue = this.getDestinationValueFromCondition(condition);

    if (!IsDateValid(destinationValue) || !IsDateValid(source)) return false;

    const operation = condition.extra!.settings.diffOperationType;
    const expectedDaysDiff = condition.extra!.settings.diffInDays;
    const calculatedDaysDiff = GetDaysBetweenDates(GetStartOfTheDay(source), GetStartOfTheDay(destinationValue));

    switch (operation) {
      case NumericComparisonOperationEnum.EQUALS:
        return expectedDaysDiff === calculatedDaysDiff;
      case NumericComparisonOperationEnum.NOT_EQUALS:
        return expectedDaysDiff !== calculatedDaysDiff;
      case NumericComparisonOperationEnum.GREATER_THAN:
        return calculatedDaysDiff > expectedDaysDiff;
      case NumericComparisonOperationEnum.GREATER_OR_EQUALS_THAN:
        return calculatedDaysDiff >= expectedDaysDiff;
      case NumericComparisonOperationEnum.LOWER_THAN:
        return calculatedDaysDiff < expectedDaysDiff;
      case NumericComparisonOperationEnum.LOWER_OR_EQUALS_THAN:
        return calculatedDaysDiff <= expectedDaysDiff;
      default:
        return false;
    }
  }

  private isDateConditionMet(source: Date | string, condition: RuleCondition) {
    const sourceDate = source instanceof Date ? source.getTime() : null;
    const destinationDate = this.getDateAsMilliseconds(condition.destination.value);

    switch (condition.extra!.settings.operationType) {
      case DateFilterEnum.dateIs:
        return sourceDate === destinationDate;
      case DateFilterEnum.dateIsNot:
        return sourceDate !== destinationDate;
      case DateFilterEnum.dateAfter:
        return !!destinationDate && !!sourceDate && sourceDate > destinationDate;
      case DateFilterEnum.dateAfterOrEqualTo:
        return !!destinationDate && !!sourceDate && sourceDate >= destinationDate;
      case DateFilterEnum.dateBefore:
        return !!destinationDate && !!sourceDate && sourceDate < destinationDate;
      case DateFilterEnum.dateBeforeOrEqualTo:
        return !!destinationDate && !!sourceDate && sourceDate <= destinationDate;
      case DateFilterEnum.isEmpty:
        return !sourceDate;
      case DateFilterEnum.isNotEmpty:
        return !!sourceDate;
    }
    return false;
  }

  private isDateRangeType(type: ConditionDateFilterType): boolean {
    return SharedMethods.isValueInEnum(type, DateRangeFilterEnum);
  }

  private isDateDiffType(type: ConditionDateFilterType): boolean {
    return SharedMethods.isValueInEnum(type, DateDiffsEnum);
  }

  private getDateAsMilliseconds(value: Date): number | null {
    if (!value) {
      return null;
    }

    value.setSeconds(0);
    return value.getTime();
  }

  private getDestinationValueFromCondition(condition: RuleCondition): Date {
    if (condition.extra!.settings.getAttributeValueFn) {
      return condition.extra!.settings.getAttributeValueFn(condition.destination.value);
    } else if (condition.extra!.settings.getAttributeValueForArtifactFn) {
      return condition.extra!.settings.getAttributeValueForArtifactFn(condition.extra!.settings.artifact!, condition.destination.value);
    }

    return new Date();
  }
}
