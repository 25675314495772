import { AfterViewInit, Component, Inject, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ArtifactLinkResponseDto, ArtifactResponseDto, FolderDataResponseDto, LinkListResponseDto, LinkResponseDto } from '@api/models';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { LinkDirection, NewLinkBoilerplate } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { FadeAnimation } from '@shared/animations/animations';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ArtifactAttributeFormFieldComponent } from '@shared/components/artifact-attribute-form-field/artifact-attribute-form-field.component';
import { ArtifactHistoryComponent } from '@shared/components/artifact-history/artifact-history.component';
import { ISO_FORMAT_WITH_TIMEZONE } from '@shared/constants/constants';
import { AttributeValueToClient } from '@shared/methods/client-attribute.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { LinkPrimaryAttributesToStringPipe } from '@shared/pipes/attribute-pipes/link-primary-attributes-to-string.pipe';
import { AnnouncementService } from '@shared/services/announcement.service';
import { FormFieldCommunicatorService } from '@shared/services/form-field-communicator.service';
import { ArtifactLinkService } from '@shared/services/links/artifact-link.service';
import { GoogleSsoService } from '@shared/services/sso/google.sso.service';
import { OpenIdSsoService } from '@shared/services/sso/openid.sso.service';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';
import { NewLink } from '@shared/types/link.types';
import { DoSomethingWithConfirmationParams, SelectOption } from '@shared/types/shared.types';
import { ArrayUtil } from '@shared/utils/array.util';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ArtifactWidgetReadonlyFieldComponent } from '@widgets/artifact-widget/components/artifact-widget-form/components/artifact-widget-readonly-field/artifact-widget-readonly-field.component';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { ArtifactWidgetService } from '@widgets/artifact-widget/services/artifact-widget.service';
import { ArtifactSaveEventEnum, ArtifactSaveEventObject, ArtifactWidgetModel } from '@widgets/artifact-widget/types/artifact-widget.types';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { FolderTreeNode } from '@widgets/folder-widget/types/folder-widget.types';
// import { ArtifactLinkDialogComponent } from '@widgets/link-popup/artifact-link-dialog.component';
import { ArtifactListTableIdentifiers } from '@widgets/shared/components/artifact-list-table/types/artifact-list-widget-table.types';
import { FolderPickerComponent } from '@widgets/shared/components/folder-picker/folder-picker.component';
import { LinkDialogOpenArguments } from '@widgets/shared/types/link-popup/link-dialog-open-arguments';
import { LabelBehaviourEnum, WidgetSaveButtonVisibilityEnum } from '@widgets/shared/types/style.types';
import { IS_PREVIEW_MODE, PAGE_ID, WIDGET_ID } from '@widgets/widgets-core/constants/widgets-core.constants';
import { RuleDataHolderService, RuleTriggerEventHandlerService } from '@workflows/services';
import {
  RuleTriggerType,
  WorkflowTriggerEvent,
  WorkflowTriggerLinkAdded,
  WorkflowTriggerPageLoad,
  WorkflowTriggerWidgetDataLoad,
  WorkflowTriggerWidgetLoad,
} from '@workflows/types';
import { WorkflowTriggerArtifactWidgetCreateMode } from '@workflows/types/triggers/trigger-artifact-widget-create-mode';
import { cloneDeep } from 'lodash';
import moment from 'moment/moment';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { forkJoin, lastValueFrom, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-artifact-widget-form',
  templateUrl: './artifact-widget-form.component.html',
  styleUrls: ['./artifact-widget-form.component.scss'],
  animations: [FadeAnimation],
  providers: [GoogleSsoService, OpenIdSsoService],
})
export class ArtifactWidgetFormComponent implements AfterViewInit {
  @Input() model: ArtifactWidgetModel;
  @Input() applicationId: string;
  @Input() hash: string;
  @Input() ids: ArtifactListTableIdentifiers;
  @Input() onLinkDialogOpen: (linkDialogArguments: LinkDialogOpenArguments) => Promise<void> | void;
  @Input() changeFormFocus: () => void;
  @Input() resetConfigurableSettings: () => void;

  @ViewChild('artifactHistory') artifactHistory: ArtifactHistoryComponent;
  @ViewChild('folderPicker') folderPicker: FolderPickerComponent;
  @ViewChild('accessOverlay') accessOverlay: OverlayPanel;
  @ViewChildren('awFormField') formFields: QueryList<ArtifactAttributeFormFieldComponent>;
  @ViewChildren('awFormReadonlyField') readonlyFormFields: QueryList<ArtifactWidgetReadonlyFieldComponent>;

  labelBehaviourEnum = LabelBehaviourEnum;
  saveVisibilityEnum = WidgetSaveButtonVisibilityEnum;
  isInSavingProcess = false;
  artifactDto: ArtifactResponseDto = new NewArtifact();
  ssoCb: () => Promise<any>;

  constructor(
    @Inject(PAGE_ID) public pageId: string,
    @Inject(WIDGET_ID) public widgetId: string,
    @Inject(IS_PREVIEW_MODE) protected readonly isPreviewMode: boolean,
    public readonly artifactWidgetService: ArtifactWidgetService,
    public readonly artifactWidgetHelper: ArtifactWidgetHelper,
    public readonly artifactWidgetCustomAttributeHelper: ArtifactWidgetCustomAttributeHelper,
    public readonly googleSso: GoogleSsoService,
    public readonly openIdSso: OpenIdSsoService,
    private readonly cache: NewCacheService,
    private readonly ruleDataHolder: RuleDataHolderService,
    private readonly ruleTriggerEventHandler: RuleTriggerEventHandlerService,
    private readonly router: Router,
    private readonly elvisUtil: ElvisUtil,
    private readonly confirmationService: ConfirmationService,
    private readonly artifactService: TenantArtifactService,
    private readonly artifactLinkService: ArtifactLinkService,
    private readonly announcement: AnnouncementService,
    private readonly formFieldCommunicator: FormFieldCommunicatorService,
  ) {}

  get autoSave(): boolean {
    return this.artifactWidgetHelper.shouldAutoSave(this.model);
  }

  get isEditMode(): boolean {
    return this.artifactWidgetHelper.isEditMode(this.model);
  }

  ngAfterViewInit(): void {
    this.synchronizeDtoWithForm();
    this.ssoCb = () => this.artifactWidgetService.redirectIfLoggedIn();
    setTimeout(() => this.initWorkflowTriggersWhenPageInited());
  }

  onSave(shouldNotify: boolean): void {
    this.isInSavingProcess = true;
    this.artifactWidgetService
      .save(shouldNotify)
      .then(() => {
        this.notifySettingsWithFormFieldCommunicator();
        this.resetConfigurableSettings();
      })
      .finally(() => (this.isInSavingProcess = false));
  }

  onFormChange(isShowSaveButtons?: boolean): void {
    this.model.updateHasFormChangedFlag();
    this.model.updateShowSaveButtonFlag();
    this.synchronizeDtoWithForm();

    if (this.autoSave && this.isEditMode) {
      this.model.subscriptions.saveDebounce$.next(
        new ArtifactSaveEventObject({
          event: ArtifactSaveEventEnum.change,
          form: cloneDeep(this.model.form),
          artifactId: this.model.artifactId() || '',
        }),
      );
    }

    if (isShowSaveButtons) {
      this.model.settings.saveButtonVisibility() === WidgetSaveButtonVisibilityEnum.onChange && this.model.showSaveButton.set(true);
      this.model.settings.saveAndNotifyButtonVisibility() === WidgetSaveButtonVisibilityEnum.onChange && this.model.showSaveAndNotifyButton.set(true);
      this.model.isEditInProgress.set(true);
    }
  }

  onFormBlur(): void {
    if (!this.isEditMode) {
      this.changeFormFocus();
      this.model.subscriptions.saveDebounce$.next(new ArtifactSaveEventObject({ event: ArtifactSaveEventEnum.blur }));
    }
  }

  onEdit(): void {
    this.model.isEditInProgress.set(true);
    this.notifySettingsWithFormFieldCommunicator();
  }

  onCancelEdit(): void {
    this.model.isEditInProgress.set(false);
    this.model.form = cloneDeep(this.model.originalForm);
    this.notifySettingsWithFormFieldCommunicator();
    this.resetConfigurableSettings();
  }

  createNewLinkBoilerplate(artifact: NewArtifact, linkType: LinkType, direction: LinkDirection): NewLinkBoilerplate {
    const destinationArtifactId = LinkMethods.isOutgoing(direction) ? artifact.id : undefined;
    const destinationArtifactTypeId = LinkMethods.isOutgoing(direction) ? artifact.artifactTypeId : undefined;
    const sourceArtifactId = LinkMethods.isOutgoing(direction) ? undefined : artifact.id;
    const sourceArtifactTypeId = LinkMethods.isOutgoing(direction) ? undefined : artifact.artifactTypeId;
    const { artifactTypes } = this.model.options;

    return new NewLinkBoilerplate(
      sourceArtifactId,
      sourceArtifactTypeId,
      destinationArtifactId,
      destinationArtifactTypeId,
      linkType.id,
      direction,
      new LinkPrimaryAttributesToStringPipe().transform(
        new NewLink({
          sourceArtifactId,
          destinationArtifactId,
          linkTypeId: linkType.id,
        }),
        direction,
        { [artifact.id]: artifact },
        artifactTypes.listMap,
      ),
    );
  }

  boilerplateCb(artifacts: NewArtifact[], linkType: SelectOption<string, LinkType, LinkDirection>): void {
    if (!this.model.newLinksMap[linkType.value.id]) this.model.newLinksMap[linkType.value.id] = {} as Record<LinkDirection, NewLinkBoilerplate[]>;
    if (!this.model.newLinksMap[linkType.value.id][linkType.meta]) this.model.newLinksMap[linkType.value.id][linkType.meta] = [];

    const boilerplates = artifacts.map(artifact => this.createNewLinkBoilerplate(artifact, linkType.value, linkType.meta));
    if (this.model.newLinksMap[linkType.value.id][linkType.meta].length === 0) {
      this.model.newLinksMap[linkType.value.id][linkType.meta].push(...boilerplates);
      return;
    }
    const currentBoilerPlates = this.model.newLinksMap[linkType.value.id][linkType.meta];
    const boilerplatesToAdd = boilerplates.filter(
      item =>
        !currentBoilerPlates.find(bpItem => {
          return (
            item.sourceId === bpItem.sourceId &&
            item.sourceTypeId === bpItem.sourceTypeId &&
            item.destinationId === bpItem.destinationId &&
            item.destinationTypeId === bpItem.destinationTypeId &&
            item.linkTypeId === bpItem.linkTypeId
          );
        }),
    );
    this.model.newLinksMap[linkType.value.id][linkType.meta].push(...boilerplatesToAdd);
  }

  onLinkAdded(linkedArtifacts?: NewArtifact[]) {
    if (this.ruleDataHolder.hasRuleForPageAndTriggerType(this.pageId, RuleTriggerType.LINK_ADDED) && ArrayUtil.isNotEmptyArray(linkedArtifacts)) {
      (linkedArtifacts as NewArtifact[]).forEach(linkedArtifact => this.checkAndNotifyLinkAddedWorkflowTrigger(linkedArtifact));
    }
  }

  onExternalLinkedArtifactsToBeAdded(linkedArtifacts: NewArtifact[], linkType: SelectOption<string, LinkType, LinkDirection>) {
    const doSendTrigger = this.ruleDataHolder.hasRuleForPageAndTriggerType(this.pageId, RuleTriggerType.LINK_ADDED);
    if (!this.model.artifactId()) {
      this.boilerplateCb(linkedArtifacts, linkType);
      doSendTrigger && this.onLinkAdded(linkedArtifacts);
      return;
    }
    const isOutgoing = linkType.meta === LinkDirection.outgoing;
    this.createMultiLink$(linkedArtifacts, this.model.artifactId() || '', linkType.value.id, isOutgoing, doSendTrigger).subscribe(responses => {
      const successes = responses.filter(item => item.meta.errors?.length === 0).map(item => item.data);
      const successesNumber = successes.length;
      const failures = responses.length - successesNumber;
      // if (successesNumber > 0) {
      //   this to be checked for potential UI optimization
      //   this.updateLinksInModel(linkedArtifacts, successes, linkType);
      // }
      const notificationMessage = `Created ${successesNumber} / ${responses.length} links due to active automation rule...`;
      if (failures > 0) {
        this.announcement.notifySimpleWarning(notificationMessage);
      } else {
        this.announcement.notifySimpleInfo(notificationMessage);
      }
    });
  }

  async onAddLinkClick(linkType: SelectOption<string, LinkType, LinkDirection>): Promise<void> {
    const restriction = LinkMethods.getRestrictionForArtifactTypeAndLinkType(
      this.model.selected.artifactType!.id,
      this.model.linkRestrictions,
      linkType.value.id,
      linkType.meta,
    );
    const args: LinkDialogOpenArguments = new LinkDialogOpenArguments({
      originalArtifact: this.model.selected.artifact || null,
      linkTypeId: linkType.value.id,
      linkDirection: linkType.meta,
      restriction,
      boilerplateCb: artifacts => this.onExternalLinkedArtifactsToBeAdded(artifacts, linkType),
      successCb: this.onLinkAdded.bind(this),
    });

    await this.onLinkDialogOpen(args);
  }

  async onShowHistoryClick(): Promise<void> {
    await this.artifactHistory.loadAndOpenHistory(this.model.selected.artifact);
  }

  onShowAccessRightsClick(event: any): void {
    this.accessOverlay.show(event);
  }

  async removeArtifact(): Promise<void> {
    const id = this.model.selected.artifact?.id;
    const pageId = this.model.selected.artifactType?.defaultPageId;
    try {
      id && (await lastValueFrom(this.artifactService.artifactControllerDelete({ id, notify: false })));
      pageId && (await this.router.navigateByUrl(`page/${pageId}`));
      await this.announcement.success('Artifact deleted successfully');
    } catch (e: any) {
      const errorMessage = e.status === 403 ? 'Failed to delete artifact due to insufficient permissions' : 'Failed to delete artifact';
      await this.announcement.error(errorMessage);
      throw new Error(errorMessage);
    }
  }

  async deleteWithConfirmation(): Promise<void> {
    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Delete', `Are you sure that you want to delete this artifact?`, 'Yes', 'No'),
      () => this.removeArtifact(),
    );
  }

  showPopupFolder(settings?: FolderWidgetSettings): void {
    !this.model.selected.artifact && (this.model.selected.artifact = new NewArtifact());
    this.model.hasExistingArtifactContext.set(false);
    this.folderPicker.showFolderPicker(this.model.selected.artifact, false, settings);
  }

  onChangeFolder(folder: FolderTreeNode): void {
    if (this.model.selected.artifact) {
      this.model.selected.artifact.folderPath = '' + folder.folderPath + folder.label;
      this.model.selected.artifact.parentFolderId = folder.id;

      // TODO pathRank and sequence needs to be implemented
      this.model.selected.artifact.folderData = {
        parentId: folder.id,
        path: '' + folder.folderPath + folder.label,
      } as FolderDataResponseDto;

      // allow set FormChangedFlag
      this.model.form.forEach(item => {
        item.attribute?.value.id == NonAttributeKeys.FOLDER_PATH_ID && (item.attribute.value.value = {});
      });
      this.onFormChange();
    }
  }

  getAttributeValueFn(attributeId: string): any {
    return this.model.options.clientAttributes.find(attr => attr.value.id === attributeId)?.value?.value;
  }

  resetFormEditabilityToDefault(): void {
    this.formFields.forEach(field => {
      const isEditableByDefault = field.formatSettings.editable;
      field.setAttributeEditable(isEditableByDefault);
    });
  }

  resetFormVisibilityToDefault(): void {
    this.formFields.forEach(field => {
      const isVisibleByDefault = !field.formatSettings.hideOnPageLoad;
      field.setAttributeVisible(isVisibleByDefault);
    });
  }

  triggerWidgetDataLoad(): void {
    if (this.ruleDataHolder.hasRuleForPageAndTriggerType(this.pageId, RuleTriggerType.WIDGET_DATA_LOAD)) {
      this.ruleTriggerEventHandler.notifyTriggerEvent({
        pageId: this.pageId,
        definition: new WorkflowTriggerWidgetDataLoad(this.widgetId),
        payload: {
          artifact: this.model.selected.artifact as NewArtifact,
          artifactDto: this.artifactDto,
          getAttributeValueFn: this.getAttributeValueFn.bind(this),
        },
        widgetId: this.widgetId,
      });
    }
  }

  triggerWidgetCreateMode(): void {
    this.ruleTriggerEventHandler.notifyTriggerEvent({
      pageId: this.pageId,
      definition: new WorkflowTriggerArtifactWidgetCreateMode(this.widgetId),
      payload: {
        artifact: this.model.selected.artifact as NewArtifact,
        artifactDto: this.artifactDto,
      },
      widgetId: this.widgetId,
    });
  }

  handleReCaptcha(event: string | null): void {
    this.model.isCaptchaValid = Boolean(event);
  }

  private initWorkflowTriggersWhenPageInited() {
    // Page load
    if (this.ruleDataHolder.hasRuleForPageAndTriggerType(this.pageId, RuleTriggerType.PAGE_LOAD)) {
      this.ruleTriggerEventHandler.notifyTriggerEvent({
        pageId: this.pageId,
        definition: new WorkflowTriggerPageLoad(),
        payload: {
          artifact: this.model.selected.artifact as NewArtifact,
          artifactDto: this.artifactDto,
        },
      });
    }
    // Widget load
    this.ruleTriggerEventHandler.notifyTriggerEvent({
      pageId: this.pageId,
      definition: new WorkflowTriggerWidgetLoad(this.widgetId),
      payload: {
        artifact: this.model.selected.artifact as NewArtifact,
        artifactDto: this.artifactDto,
      },
      widgetId: this.widgetId,
    });
    // Link added on form load (linked artifact scenario)
    if (this.ruleDataHolder.hasRuleForPageAndTriggerType(this.pageId, RuleTriggerType.LINK_ADDED)) {
      const linkedArtifactLinkDto = this.artifactWidgetService.getLinkedArtifactLinkDto();
      if (linkedArtifactLinkDto) {
        const linkedArtifactId = linkedArtifactLinkDto.destinationArtifactId || (linkedArtifactLinkDto.sourceArtifactId as string);
        this.cache.data.artifacts.get$(linkedArtifactId).subscribe(artifactResponseDto => {
          const artifact = new NewArtifact({ dto: artifactResponseDto, artifactTypesMap: this.model.options.artifactTypes.listMap });

          artifact.clientAttributes.forEach(
            attribute =>
              (attribute.value = AttributeValueToClient(
                new AttributeToClientParams({
                  dataTypes: this.model.options.dataTypes,
                  attributes: this.model.options.attributes,
                  clientAttribute: attribute,
                  value: attribute.value,
                }),
              )),
          );

          this.checkAndNotifyLinkAddedWorkflowTrigger(artifact);
        });
      }
    }
  }

  private checkAndNotifyLinkAddedWorkflowTrigger(linkedArtifact: NewArtifact) {
    const ruleTriggerEvent: WorkflowTriggerEvent = {
      pageId: this.pageId,
      definition: new WorkflowTriggerLinkAdded(linkedArtifact.artifactTypeId),
      payload: {
        artifact: linkedArtifact,
      },
      widgetId: this.widgetId,
    };
    if (this.ruleDataHolder.hasRuleForPageAndTrigger(this.pageId, ruleTriggerEvent)) {
      this.ruleTriggerEventHandler.notifyTriggerEvent(ruleTriggerEvent);
    }
  }

  private createMultiLink$(
    artifacts: NewArtifact[],
    artifactId: string,
    linkTypeId: string,
    isOutgoing: boolean,
    doSendWorkflowTrigger: boolean,
  ): Observable<LinkListResponseDto[]> {
    const createLinksArray = artifacts.map(artifact => {
      const destinationArtifactId = isOutgoing ? artifact.id : artifactId;
      const sourceArtifactId = isOutgoing ? artifactId : artifact.id;
      return this.artifactLinkService
        .createLink$(linkTypeId, destinationArtifactId, sourceArtifactId, true)
        .pipe(tap(() => doSendWorkflowTrigger && this.checkAndNotifyLinkAddedWorkflowTrigger(artifact)));
    });
    return forkJoin(createLinksArray);
  }

  private updateLinksInModel(linkedArtifacts: NewArtifact[], linkResponseDtos: LinkResponseDto[][], linkType: SelectOption<string, LinkType, LinkDirection>) {
    const linkedArtifactsMap = linkedArtifacts.reduce((map, item) => map.set(item.id, item), new Map());
    const currentLinkMap = this.model.linkMap || {};
    currentLinkMap[linkType.value.id] ??= { INCOMING: [], OUTGOING: [] };
    linkResponseDtos.forEach(links => {
      currentLinkMap[linkType.value.id][linkType.meta].push(...links);
      links.forEach(link => {
        const linkedArtifactId = link.destinationArtifactId === this.model.artifactId() ? link.sourceArtifactId : link.destinationArtifactId;
        this.model.linkedArtifactsMap[linkedArtifactId] = linkedArtifactsMap.get(linkedArtifactId);
      });
    });
    this.model.linkMap = currentLinkMap;
    this.model.linkedArtifactsMap;
  }

  private synchronizeDtoWithForm(): void {
    if (this.model.artifactDto) {
      this.artifactDto = this.model.artifactDto;
      this.artifactWidgetService.mapFormAttributesToServer(this.artifactDto.attributes, this.model.form);
    } else {
      this.artifactDto = this.generateArtifactDto() as ArtifactResponseDto;
    }
  }

  private generateArtifactDto(): Partial<ArtifactResponseDto> {
    const attributes: Record<string, any> = {};
    this.artifactWidgetService.mapFormAttributesToServer(attributes, this.model.form);

    const now = moment().format(ISO_FORMAT_WITH_TIMEZONE);
    const userId = (this.cache.userProfile.value as ArtifactLinkResponseDto).id;

    return {
      id: '',
      artifactTypeId: this.model.selected.artifactType?.id || '',
      attributes,
      folderData: { parentId: null, path: null, pathRank: 0, sequence: 0 },
      deleted: null,
      created: { by: userId, on: now },
      updated: { by: userId, on: now },
      importStamp: null,
      moduleData: null,
    };
  }

  /*
    Here lies synchronization between readonly and editable modes
  */
  private notifySettingsWithFormFieldCommunicator(): void {
    const formFields = [...this.formFields, ...this.readonlyFormFields];

    const attributeVisibilityMap = formFields.reduce((attributesMap, formField) => {
      return {
        ...attributesMap,
        [formField.attribute.id]: formField.visible,
      };
    }, {});

    setTimeout(() => this.formFieldCommunicator.notifyFormFieldVisibility(this.widgetId, attributeVisibilityMap));
  }

  storeFilesUploaded(files: File | File[]) {
    const uploadedFiles = Array.isArray(files) ? files : [files];

    let allFiles: Blob[] = [];

    if (this.model.newArtifactFile) {
      if (Array.isArray(this.model.newArtifactFile)) {
        allFiles.push(...this.model.newArtifactFile);
      } else {
        allFiles.push(this.model.newArtifactFile);
      }
    }

    allFiles.push(...uploadedFiles.map(file => new Blob([file], { type: file.type })));

    if (allFiles.length === 0) {
      this.model.newArtifactFile = null;
    } else if (allFiles.length === 1) {
      this.model.newArtifactFile = allFiles[0];
    } else {
      this.model.newArtifactFile = allFiles;
    }
  }
}
