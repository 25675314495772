import { Injectable } from '@angular/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import {
  BLANK_OPTION_FILTER_URL_VALUE,
  EMPTY_FILTER_VALUE_MULTIPLE,
  EMPTY_FILTER_VALUE_SINGLE,
  EMPTY_OPTION_FILTER_URL_VALUE,
  IS_NOT_EMPTY_OPTION_FILTER_VALUE,
} from '@shared/constants/constants';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { GetArtifactAttributeValuePath } from '@shared/methods/client-attribute.methods';
import { BooleanValueFilterEnum } from '@shared/types/filter.types';
import { SelectOption } from '@shared/types/shared.types';
import { DateUtil } from '@shared/utils/date.util';
import { NewDataType } from '../types/data-type.types';
import { FilterType } from '../types/table.types';

@Injectable({ providedIn: 'root' })
export class FilterUtil {
  getAttributesDbFilterKey(key: string): string {
    return GetArtifactAttributeValuePath(key);
  }

  getAttributesFolderParentDbFilterKey(): string {
    return 'folderData.parentId';
  }

  getAttributesFolderPathDbFilterKey(): string {
    return 'folderData.path';
  }

  getMongoFilterByMatchMode(matchMode: CoreListFilterEnum, value: any, useIsoDate: boolean): Record<string, any> {
    switch (matchMode) {
      case CoreListFilterEnum.startsWith:
        return { $regex: '^' + String(value), $options: 'i' };
      case CoreListFilterEnum.endsWith:
        return { $regex: String(value) + '$', $options: 'i' };
      case CoreListFilterEnum.contains:
        return { $regex: String(value), $options: 'i' };
      case CoreListFilterEnum.notContains:
        return { $not: { $regex: String(value), $options: 'i' } };
      case CoreListFilterEnum.equals:
        return { $eq: value };
      case CoreListFilterEnum.notEquals:
        return { $not: { $eq: String(value) } };
      case CoreListFilterEnum.isEmpty:
        return { $eq: value };
      case CoreListFilterEnum.isNotEmpty:
        return { $not: { $eq: value } };
      case CoreListFilterEnum.lessThan:
        return { $lt: String(value) };
      case CoreListFilterEnum.lessThanOrEqualTo:
        return { $lte: String(value) };
      case CoreListFilterEnum.greaterThan:
        return { $gt: String(value) };
      case CoreListFilterEnum.greaterThanOrEqualTo:
        return { $gte: String(value) };
      case CoreListFilterEnum.in:
        return { $in: this.toArray(value) };
      case CoreListFilterEnum.notIn:
        return { $nin: this.toArray(value) };
      case CoreListFilterEnum.isNot:
        return { $not: value };
      case CoreListFilterEnum.before:
        return { $lt: value };
      case CoreListFilterEnum.after:
        return { $gt: value };
      case CoreListFilterEnum.dateIs:
        return { $eq: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.dateIsNot:
        return { $not: { $eq: useIsoDate ? { $date: value } : value } };
      case CoreListFilterEnum.dateBefore:
        return { $lt: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.dateAfter:
        value = DateUtil.getEndOfTheDay(new Date(value));
        return { $gt: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.dateBeforeOrEqualTo:
        value = DateUtil.getEndOfTheDay(new Date(value));
        return { $lte: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.dateAfterOrEqualTo:
        return { $gte: useIsoDate ? { $date: value } : value };
      default:
        throw new Error(`Match mode value must be one of CoreListFilterEnum, but received: "${String(matchMode)}"`);
    }
  }

  getTextFilterMatchModes(): CoreListFilterEnum[] {
    return [
      CoreListFilterEnum.startsWith,
      CoreListFilterEnum.endsWith,
      CoreListFilterEnum.contains,
      CoreListFilterEnum.notContains,
      CoreListFilterEnum.equals,
      CoreListFilterEnum.notEquals,
      CoreListFilterEnum.isEmpty,
      CoreListFilterEnum.isNotEmpty,
    ];
  }

  isTextFilterMatchMode(matchMode: CoreListFilterEnum): boolean {
    switch (matchMode) {
      case CoreListFilterEnum.startsWith:
      case CoreListFilterEnum.endsWith:
      case CoreListFilterEnum.contains:
      case CoreListFilterEnum.notContains:
      case CoreListFilterEnum.equals:
      case CoreListFilterEnum.notEquals:
      case CoreListFilterEnum.isEmpty:
      case CoreListFilterEnum.isNotEmpty:
        return true;
      default:
        return false;
    }
  }

  isEnumFilterMatchMode(matchMode: CoreListFilterEnum): boolean {
    switch (matchMode) {
      case CoreListFilterEnum.in:
      case CoreListFilterEnum.notIn:
        return true;
      default:
        return false;
    }
  }

  getFilterTypeByDataType(dataType: NewDataType): FilterType | null {
    const baseDataType = dataType.baseDataType;
    if (dataType.kind === DataTypeKind.simple || dataType.kind === DataTypeKind.bounded) {
      switch (baseDataType) {
        case BaseDataType.date:
          return FilterType.date;
        case BaseDataType.dateTime:
          return FilterType.datetime;
        case BaseDataType.time:
          return FilterType.time;
        case BaseDataType.boolean:
          return FilterType.boolean;
        case BaseDataType.integer:
        case BaseDataType.decimal:
          return FilterType.numeric;
        case BaseDataType.text:
          return FilterType.text;
        case BaseDataType.user:
          return FilterType.user;
        case BaseDataType.hyperlink:
          return FilterType.hyperlink;
        case BaseDataType.file:
          return FilterType.file;
      }
    } else {
      switch (dataType.kind) {
        case DataTypeKind.enumerated:
          return FilterType.enum;
        case DataTypeKind.counter:
          return FilterType.text;
      }
    }
    return null;
  }

  getBooleanValueFilterSelectOptions(withEmptySelectOption: boolean): SelectOption<string, string>[] {
    const selectOptions = Object.keys(BooleanValueFilterEnum).map(
      key => new SelectOption(key.toLowerCase(), BooleanValueFilterEnum[key as keyof typeof BooleanValueFilterEnum]),
    );
    return withEmptySelectOption ? [...selectOptions, ...this.booleanEmptyOptions()] : selectOptions;
  }

  booleanEmptyOptions(): SelectOption<string, string>[] {
    return [
      new SelectOption(EMPTY_OPTION_FILTER_URL_VALUE, EMPTY_FILTER_VALUE_SINGLE),
      new SelectOption(IS_NOT_EMPTY_OPTION_FILTER_VALUE, IS_NOT_EMPTY_OPTION_FILTER_VALUE),
    ];
  }

  isValueEmpty(value: any): boolean {
    return value === BLANK_OPTION_FILTER_URL_VALUE || value === EMPTY_FILTER_VALUE_SINGLE || value === EMPTY_FILTER_VALUE_MULTIPLE;
  }

  containsBlankOrEmptyValue(values: any[]): boolean {
    return !!values.find(value => this.isValueEmpty(value));
  }

  withoutEmptyValues(values: any[]): any[] {
    return values.filter(value => !this.isValueEmpty(value));
  }

  toArray(value: any): any[] {
    return Array.isArray(value) ? value : [value];
  }
}
