import { SharedTestUtil } from '../../../../../../e2e/helper/general-data-helper/shared-test-util.methods';

export class GradientGeneratorColor {
  id: string = SharedTestUtil.getUniqueId();
  color: string;
  position: number;

  constructor(color?: Partial<GradientGeneratorColor>) {
    color && Object.assign(this, color);
  }
}
