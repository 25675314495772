import { CopyAttributeValueActionWorkflowSettingsDto } from '@api/models/copy-attribute-value-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { AttributeActionHandlerService, ModifyAttributeValueOperation } from '@workflows/shared';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { WorkflowActionType, WorkflowTriggerEvent } from '@workflows/types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export enum CopyAttributeValueKeys {
  copyFromAttributeId = 'copyFromAttributeId',
  operation = 'operation',
}

export class WorkflowActionCopyAttributeValueDto implements CopyAttributeValueActionWorkflowSettingsDto {
  copyFromAttributeId: CopyAttributeValueActionWorkflowSettingsDto[CopyAttributeValueKeys.copyFromAttributeId] = { value: '', isDynamic: false };
  operation: CopyAttributeValueActionWorkflowSettingsDto[CopyAttributeValueKeys.operation] = { value: '' as any, isDynamic: false };

  constructor(action?: CopyAttributeValueActionWorkflowSettingsDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionCopyAttributeValue extends AbstractWorkflowAttributeBasedAction<CopyAttributeValueActionWorkflowSettingsDto> {
  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super({ actionSettingDto: WorkflowActionCopyAttributeValueDto, conditionConverter, type: WorkflowActionType.COPY_VALUE, dto, actionAttributeHandler });
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifact && !!triggerEvent?.payload?.getAttributeValueFn;
  }

  execute(triggerEvent?: WorkflowTriggerEvent): void {
    if (!triggerEvent?.payload?.getAttributeValueFn) return;

    this.actionAttributeHandler.notifyModifyAttributeValueEvent({
      attributeId: this.attributeId,
      artifactTypeId: this.artifactTypeId,
      copyFromAttributeId: this.actionSettings.copyFromAttributeId.value as string,
      operation: this.actionSettings.operation.value as ModifyAttributeValueOperation,
      value: triggerEvent.payload.getAttributeValueFn(this.actionSettings.copyFromAttributeId.value),
    });
  }

  isValid(): boolean {
    return super.isValid() && !!(this.artifactTypeId && this.attributeId);
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      actionSettings: {
        [CopyAttributeValueKeys.copyFromAttributeId]: {
          isDynamic: false,
          value: this.actionSettings.copyFromAttributeId.value,
        },
        [CopyAttributeValueKeys.operation]: {
          isDynamic: false,
          value: this.actionSettings.operation.value || ModifyAttributeValueOperation.replace,
        },
      },
    };
  }
}
