import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { ArtifactTypeModel } from '@private/pages/artifact-type-management/artifact-type/types/artifact.type.types';
import { NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { GetAttributeFromClientAttribute, GetDataTypeFromDataTypeId } from '@shared/methods/artifact.methods';
import { IsCounter } from '@shared/methods/data-type.methods';

@Component({
  selector: 'app-artifact-type-attributes-form',
  templateUrl: './artifact-type-attributes-form.component.html',
  styleUrls: ['./artifact-type-attributes-form.component.scss'],
})
export class ArtifactTypeAttributesFormComponent {
  @Input() m: ArtifactTypeModel;
  @Input() updatePrimaryAttributes: () => void;
  @Input() updateAvailableAttributes: () => void;
  @Input() onAttributeChange: (artifactTypeAttribute: NewArtifactTypeClientAttribute, index: number) => void;

  initialValue: boolean;

  ngOnInit(): void {
    const { attributes, dataTypes } = this.m.options;
    Object.keys(this.m.artifactType.attributes).forEach(key => {
      const attribute = GetAttributeFromClientAttribute(this.m.artifactType.attributes[key], attributes.listMap);

      if (attribute && IsCounter(GetDataTypeFromDataTypeId(attribute.dataTypeId, dataTypes.listMap)?.kind)) {
        this.initialValue = !!this.m.artifactType.attributes[key]?.initialValue;
      }
    });
  }

  onInitValueChange(artifactTypeAttribute: NewArtifactTypeClientAttribute): void {
    artifactTypeAttribute.initialValue = this.initialValue ? this.initialValue.toString(): '';
  }

  addAttribute(): void {
    this.m.editableHelpingAttributes.clientAttributes = [...this.m.editableHelpingAttributes.clientAttributes, new NewArtifactTypeClientAttribute()];
  }

  dropItem(event: CdkDragDrop<any, any>): void {
    moveItemInArray(this.m.editableHelpingAttributes.clientAttributes, event.previousIndex, event.currentIndex);
    this.m.editableHelpingAttributes.clientAttributes = [...this.m.editableHelpingAttributes.clientAttributes];
  }

  removeAttribute(index: number): void {
    this.m.editableHelpingAttributes.clientAttributes = this.m.editableHelpingAttributes.clientAttributes.filter((_, i) => i !== index);
    this.updatePrimaryAttributes();
    this.updateAvailableAttributes();
  }
}
