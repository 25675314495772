<app-acl-user-picker
  *ngIf="showUserPicker"
  [label]="currentAclType"
  [memberType]="memberType"
  [showExpiration]="true"
  [teams]="teams"
  [aclType]="model[currentAclType]"
  (onClose)="showUserPicker = false"
  (onAddMembers)="s.addMembers()"
></app-acl-user-picker>

<p-accordion *ngIf="model && aclTypeList">
  <p-accordionTab *ngFor="let key of aclTypeList; let tabIndex = index"
                  [header]="aclTypes[key]"
                  (selectedChange)="activeState[tabIndex] = $event">
    <ng-template pTemplate="header">
      <div class="action-cont">
        <i class="pi"
           *ngIf="model[key]?.teamIds.length > 0 || model[key]?.userIds.length > 0"
           [ngClass]="edit[tabIndex] ? 'pi-check': 'pi-pencil'"
           (click)="toggleEdit($event, aclTypes[key], tabIndex)"
        ></i>
        <i [ngClass]="s.isOpenPickerByKey(key) ? 'pi pi-minus': 'pi pi-plus'"
           (click)="openPickUsers($event, aclTypes[key], tabIndex)"
        ></i>
      </div>
    </ng-template>

    <div class="grid my-3" *ngFor="let item of model[key]?.userIds; let i = index" style="position: relative">
      <ng-container *ngIf="item && users?.listMap[item]">
        <div class="col-7 p-xl-3 py-0">{{ users.listMap[item]?.attributes?.[nameAttributeId]?.value }}</div>
        <ng-container
          *ngTemplateOutlet="itemTemplate; context: {$implicit: {item: item, list: model[key].userIds, key: key, tabIndex: tabIndex}}"></ng-container>
      </ng-container>
    </div>

    <div class="grid my-3" *ngFor="let item of model[key]?.teamIds; let i = index" style="position: relative">
      <ng-container *ngIf="item && teams?.listMap[item]">
        <div class="col-7 p-xl-3 py-0">{{ teams.listMap[item]?.name }}</div>
        <ng-container
          *ngTemplateOutlet="itemTemplate; context: {$implicit: {item: item, list: model[key].teamIds, key: key, tabIndex: tabIndex}}"></ng-container>
      </ng-container>
    </div>
  </p-accordionTab>
</p-accordion>

<div class="my-3" style="text-align: center">
  <div *ngIf="!!folderId" class="checkbox-wrapper">
    <p-checkbox [(ngModel)]="setFolderItemsAcl" [binary]="true" label="{{'Pass to Child' | translate}}"></p-checkbox>
  </div>

  <button pButton class="p-button p-button-warning mx-2" label="{{'Cancel' | translate}}" type="button"
          (click)="s.closeAcl()"></button>
  <button pButton class="p-button p-button-success mx-2" label="{{'Update' | translate}}" type="button"
          (click)="s.update()" [disabled]="!isChanged"></button>
</div>

<ng-template #itemTemplate let-data>
  <div class="col-3 mx-0 px-0 py-0 calendar-container">
    <p-calendar
      [(ngModel)]="model[data.key]?.expirations[data.item]"
      [dateFormat]="dateFormat"
      [disabled]="!edit[data.tabIndex]"
      [firstDayOfWeek]="userData?.uiConfig?.firstDayOfWeek"
      [placeholder]="'no expiration' | translate"
      [inputStyle]="{ border: 'none', fontSize: '12px', padding: 0, color: '#000' }"
      [inputStyleClass]="'cursor-pointer'"
      (ngModelChange)="onChangeDate()"
      appendTo="body"
    ></p-calendar>
  </div>
  <div class="col-1 px-0 py-0 icon-wrapper">
          <span *ngIf="edit[data.tabIndex] && model[data.key].expirations[data.item]"
                class="pi pi-times-circle icon close"
                (click)="removeExpiration(data.key, data.item)"
          ></span>
  </div>
  <div class="col-1 px-0 py-0">
    <i class="pi pi-trash" *ngIf="edit[data.tabIndex]" (click)="s.remove(data.list, data.item)"></i>
  </div>
</ng-template>
