import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { NewArtifact } from '@shared/types/artifact.types';
import { RuleFormatHandlerService } from '@workflows/shared/index';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { ActionResetKeys, WorkflowActionReset } from '@workflows/types/actions/action-reset';
import { FormatAttributeAction, WorkflowActionType, WorkflowTriggerEvent } from '@workflows/types/index';

export class WorkflowActionResetFormat extends WorkflowActionReset {
  attributeId: string;

  constructor(
    conditionConverter: RuleConditionValueConverterService,
    dto?: WorkflowActionDto,
    public actionFormatHandler?: RuleFormatHandlerService,
  ) {
    super(conditionConverter, dto);
    this.type = WorkflowActionType.RESET;
    this.actionSettings.actionType.value = WorkflowActionType.FORMAT;
    this.attributeId = dto?.attributeId || this.attributeId;
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifacts?.length;
  }

  execute(ruleTriggerEvent: WorkflowTriggerEvent, artifact: NewArtifact): void {
    const event: FormatAttributeAction = {
      attributeId: this.attributeId,
      artifactId: artifact?.id,
      widgetId: ruleTriggerEvent.widgetId!,
      artifactTypeId: artifact.artifactTypeId || '',
    };
    this.actionFormatHandler?.notifyFormatEvent(event);
  }

  isValid(): boolean {
    return !!this.actionFormatHandler && !!this.attributeId;
  }

  isAttributeRequired(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      attributeId: this.attributeId,
      actionSettings: {
        [ActionResetKeys.actionType]: {
          isDynamic: false,
          value: this.actionSettings.actionType.value,
        },
      },
    };
  }
}
