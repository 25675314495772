import { ArtifactLinkResponseDto } from '@api/models';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { FitMode } from '@widgets/chart-widget/types/chart-widget-selected.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ChartData, ChartWidgetTypeChart } from './chart-widget-view.types';

export class ChartWidgetOptions {
  artifactTypes = new ListContainer<NewArtifactType>();
  linkTypes = new ListContainer<DirectionalLinkType>();
  artifacts: NewArtifact[] = [];
  attributes: NewAttribute[] = [];
  systemAttributes: NewAttribute[] = [];
  artifactAttributes: NewAttribute[] = [];
  artifactAttributesForTotal: NewAttribute[] = [];
  artifactAttributeValues = new ListContainer<string>();
  dataTypes = new ListContainer<NewDataType>();
  chartTypes = new ListContainer<ChartWidgetTypeChart>();
  fitModes = new ListContainer<FitMode>();
  chartLegendPosition = new ListContainer<ChartPositionType>();
  chartTotalPosition = new ListContainer<ChartTotalPosition>();
  chartData: ChartData | null = null;
  systemUser: ArtifactLinkResponseDto;

  constructor() {
    this.chartTypes.setList(Object.keys(ChartWidgetTypeChart).map(key => ChartWidgetTypeChart[key as keyof typeof ChartWidgetTypeChart]));
    this.chartLegendPosition.setList(Object.keys(ChartPositionType).map(key => ChartPositionType[key as keyof typeof ChartPositionType]));
    this.fitModes.setList(Object.keys(FitMode).map(key => FitMode[key as keyof typeof FitMode]));
    this.initChartTotalPosition();
  }

  initChartTotalPosition(cutCenter = false): void {
    this.chartTotalPosition.setList(
      Object.keys(ChartTotalPosition)
        .filter(key => !cutCenter || key !== ChartTotalPosition.center)
        .map(key => ChartTotalPosition[key as keyof typeof ChartTotalPosition]),
    );
  }

  initChartTotalPositionForVertical(): void {
    this.chartTotalPosition.setList([ChartTotalPosition.center]);
  }
}

export enum ChartTotalPosition {
  bottom = 'bottom',
  center = 'center',
}

export enum ChartPositionType {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
}
