import { Component, computed, effect, OnInit, Signal, signal, untracked, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormatActionWorkflowSettingsDto } from '@api/models/format-action-workflow-settings-dto';
import { TranslateModule } from '@ngx-translate/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { WorkflowsComponentsModule } from '@workflows/components';
import { BaseWorkflowsAdministrationComponent } from '@workflows/components/administration/base-workflows-administration.component';
import { WorkflowsPipesModule } from '@workflows/pipes';
import { GetFormatActionsPipe } from '@workflows/pipes/get-format-actions.pipe';
import { RuleDataHolderService, WorkflowActionFactory, WorkflowTriggerFactory } from '@workflows/services';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { RuleTriggerType, WorkflowActionType, WorkflowOwnerType, WorkflowSetting, WorkflowTriggerWidgetDataLoad, WorkflowType } from '@workflows/types';
import { FormatActionsOnConditionsNotMet, WorkflowActionFormat } from '@workflows/types/actions/action-format';
import { WorkflowActionReset } from '@workflows/types/actions/action-reset';
import { RuleConditionGroup } from '@workflows/types/conditions/rule-condition-group';
import { WorkflowRule } from '@workflows/types/rule.types';
import { ButtonDirective } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-workflows-widget-administration',
  standalone: true,
  imports: [
    TranslateModule,
    WorkflowsPipesModule,
    WorkflowsComponentsModule,
    DropdownModule,
    FormsModule,
    StyleFormModule,
    ButtonDirective,
    InputNumberModule,
    CheckboxModule,
    ElvisSharedModule,
    FloatLabelModule,
    InputTextModule,
    GetFormatActionsPipe,
    TooltipModule,
  ],
  templateUrl: './workflow-widget-administration.component.html',
  styleUrls: ['./workflow-widget-administration.component.scss', '../../workflow-common.scss'],
})
export class WorkflowsWidgetAdministrationComponent extends BaseWorkflowsAdministrationComponent implements OnInit {
  rules: WorkflowRule[] = [];
  attribute: NewAttribute;
  widgetId: string;
  readonly ActionOnConditionsNotMetOptions = Object.values(FormatActionsOnConditionsNotMet);

  visible: WritableSignal<boolean> = signal(false);
  rule: WritableSignal<WorkflowRule | undefined> = signal(undefined);
  possibleDefaultArtifactTypes: WritableSignal<NewArtifactType[]> = signal([]);
  actionOnConditionsNotMet: WritableSignal<FormatActionsOnConditionsNotMet> = signal(FormatActionsOnConditionsNotMet.clearFormatting);

  isRuleNew = computed(() => this.rule() && !this.rule()!.id);
  formatSettings: Signal<WorkflowSetting> = computed(() => (this.rule()!.actions[0].actionSettings as FormatActionWorkflowSettingsDto).styles);
  defaultArtifactType: Signal<NewArtifactType | null> = computed(() => {
    const artifactTypeId = this.rule()?.defaultArtifactTypeId;
    if (artifactTypeId) return this.artifactTypes.listMap[artifactTypeId];

    return this.possibleDefaultArtifactTypes().length === 1 ? this.possibleDefaultArtifactTypes()[0] : null;
  });

  constructor(
    cache: NewCacheService,
    announcement: AnnouncementService,
    private readonly conditionConverter: RuleConditionValueConverterService,
    private readonly actionFactory: WorkflowActionFactory,
    private readonly ruleDataHolder: RuleDataHolderService,
    private readonly triggerFactory: WorkflowTriggerFactory,
  ) {
    super(cache, announcement);

    effect(
      () => {
        if (this.visible()) {
          this.loadWidgetRules();
          untracked(this.rule) === undefined && this.setNewRule();
          this.setActionOnConditionsNotMet();
        }
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  addNewFormattingRule(): void {
    if (!this.isRuleNew()) this.setNewRule();
  }

  selectFormattingRule(rule: WorkflowRule): void {
    this.rule.set(rule);
  }

  onDefaultArtifactTypeChange(artifactTypeId: string): void {
    this.rule.update(
      rule =>
        new WorkflowRule({
          ...rule!,
          defaultArtifactTypeId: artifactTypeId,
          condition: RuleConditionGroup.defaultGroup(this.conditionConverter),
        }),
    );
  }

  addAction(index?: number): void {
    this.rule()!.actions.splice(index !== undefined ? index + 1 : this.rule()!.actions.length, 0, this.getNewFormatAction());
    this.notifyActionsChange();
  }

  removeAction(index: number): void {
    this.rule()!.actions.splice(index, 1);
    this.notifyActionsChange();
  }

  doSaveRule(rule: WorkflowRule): void {
    if (this.isRuleNew()) {
      this.ruleDataHolder.saveRule$(rule).subscribe(serverRule => {
        this.rule.set(serverRule);
        this.loadWidgetRules();
        this.announcement.success('Formatting rule successfully saved...');
      });
      return;
    }
    this.ruleDataHolder.updateRule$(rule).subscribe(serverRule => {
      this.rule.set(serverRule);
      this.loadWidgetRules();
      this.announcement.success('Formatting rule successfully updated...');
    });
  }

  doDeleteRule(rule: WorkflowRule) {
    this.ruleDataHolder.deleteRule$(rule).subscribe(() => {
      this.rule.set(undefined);
      this.loadWidgetRules();
      this.announcement.success('Workflow rule successfully deleted...');
    });
  }

  onActionWhenConditionsNotMetChange(value: FormatActionsOnConditionsNotMet): void {
    if (value === FormatActionsOnConditionsNotMet.clearFormatting) {
      this.rule()!.actions.push(this.getNewResetFormatAction());
    } else {
      this.rule()!.actions = this.rule()!.actions.filter(action => action.type !== WorkflowActionType.RESET);
    }
  }

  moveActionUp(index: number): void {
    [this.rule()!.actions[index], this.rule()!.actions[index - 1]] = [this.rule()!.actions[index - 1], this.rule()!.actions[index]];
    this.notifyActionsChange();
  }

  moveActionDown(index: number): void {
    [this.rule()!.actions[index], this.rule()!.actions[index + 1]] = [this.rule()!.actions[index + 1], this.rule()!.actions[index]];
    this.notifyActionsChange();
  }

  private loadWidgetRules(): void {
    this.rules = this.getRulesForWidgetAndAttribute();
  }

  private getRulesForWidgetAndAttribute(): WorkflowRule[] {
    return this.ruleDataHolder.getFormattingRulesForWidgetAndAttribute(this.widgetId, this.attribute.id);
  }

  private setNewRule(): void {
    const resetFormatAction = this.getNewResetFormatAction();
    const formatAction = this.getNewFormatAction();
    const triggerDto = new WorkflowTriggerWidgetDataLoad(this.widgetId);

    const rule = new WorkflowRule();
    rule.ownerId = this.widgetId;
    rule.usedIn = [this.widgetId];
    rule.triggers = [this.triggerFactory.getWorkflowTrigger(RuleTriggerType.WIDGET_DATA_LOAD, triggerDto)];
    rule.type = WorkflowType.fe;
    rule.ownerType = WorkflowOwnerType.WIDGET;
    rule.actions = [formatAction, resetFormatAction];

    this.rule.set(rule);
  }

  private setActionOnConditionsNotMet(): void {
    this.actionOnConditionsNotMet.set(
      this.isResetActionPresent() ? FormatActionsOnConditionsNotMet.clearFormatting : FormatActionsOnConditionsNotMet.doNothing,
    );
  }

  private getNewFormatAction(): WorkflowActionFormat {
    const action = this.actionFactory.getWorkflowAction(WorkflowActionType.FORMAT) as WorkflowActionFormat;
    action.attributeId = this.attribute?.id;
    action.condition = RuleConditionGroup.defaultGroup(this.conditionConverter);

    return action;
  }

  private getNewResetFormatAction(): WorkflowActionReset {
    const action = this.actionFactory.getResetWorkflowAction(WorkflowActionType.FORMAT) as WorkflowActionReset;
    action.attributeId = this.attribute?.id;
    action.actionSettings.actionType.value = WorkflowActionType.FORMAT;

    return action;
  }

  private isResetActionPresent(): boolean {
    return !!this.rule()?.actions.some(action => action.type === WorkflowActionType.RESET);
  }

  // Needed for change detection
  private notifyActionsChange(): void {
    this.rule()!.actions = [...this.rule()!.actions];
  }
}
