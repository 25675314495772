import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { OrderListModule } from 'primeng/orderlist';
import { AclComponent } from './acl.component';
import { AclItemComponent } from './components/acl-item/acl-item.component';

@NgModule({
  declarations: [AclComponent, AclItemComponent],
  imports: [
    CommonModule,
    AccordionModule,
    TranslateModule,
    DropdownModule,
    OrderListModule,
    FormsModule,
    ButtonModule,
    CheckboxModule,
    StyleFormModule,
    CalendarModule,
    CommonPipesModule,
  ],
  exports: [AclComponent],
})
export class AclModule {}
