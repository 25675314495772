@if (formatSettings) {
  <div [ngClass]="{'hidden': !visible, 'reserve-space': formatSettings.reserveSpaceWhenHidden}">
    <div [ngClass]="formatSettings.label | labelClass : labelBehaviour : true" class="readonly-attribute-wrapper">
      <span *ngIf="attr.isMandatory && formatSettings?.label.visible" class="mandatory-field"></span>
      <label [ngStyle]="formatSettings?.label?.styles" class="readonly-label">
        <span *ngIf="formatSettings.label.showIcon && attribute?.icon" [class]="attribute.icon" class="mr-1"></span>
        <span
          *ngIf="item?.attribute?.value && formatSettings?.label.visible && labelBehaviour !== labelBehaviourEnum.placeholder">
          {{ formatSettings.label.customLabel | getAttributeLabel : attribute.name : formatSettings.label }}
        </span>
      </label>
      <div class="readonly-attribute">
        <ng-container *ngIf="dataType.isUser">
          <div class="flex">
            <span *ngIf="formatSettings.value.showIcon && attribute?.icon" [class]="attribute.icon"
                  class="mr-2 attribute-icon"></span>
            <app-form-readonly-field-user
              [ownerId]="ownerId"
              [item]="item"
              [attribute]="attribute"
              [formatSettings]="formatSettings"
              [model]="model"
              [selected]="selected"
            >
            </app-form-readonly-field-user>
          </div>
        </ng-container>

        <ng-container *ngIf="isRedirectIcon">
          <app-display-hyperlink-icon
            [attr]="attr"
            [attribute]="attribute"
            [ownerId]="attr.id"
          ></app-display-hyperlink-icon>
        </ng-container>

        <ng-container *ngIf="isBoolean">
          <app-display-at-boolean
            class="block-container full-size-container"
            [ownerId]="ownerId"
            [attributeId]="attribute.id"
            [artifact]="selected.artifact"
            [dataType]="dataType"
            [formatSettings]="formatSettings"
            [selectedVariant]="formatSettings?.value?.displayMetadata?.selectedVariantCode"
            [doInternalUpdateOfValue]="true"
          >
          </app-display-at-boolean>
        </ng-container>

        <ng-container *ngIf="isEnum">
          <app-display-at-enum
            style="display: block; width: fit-content;"
            [ownerId]="ownerId"
            [attributeId]="attribute.id"
            [attribute]="attribute"
            [artifact]="model.selected.artifact"
            [artifactDto]="artifactDto"
            [dataType]="dataType"
            [enumOptions]="dataType.values"
            [contentColumnFormat]="formatSettings?.value"
            [selectedVariant]="formatSettings?.value?.displayMetadata?.selectedVariantCode"
            [doInternalUpdateOfValue]="true"
          >
          </app-display-at-enum>
        </ng-container>

        <ng-container *ngIf="dataType.isDateTime || dataType.isDate">
          <app-display-at-datetime
            [selectedVariant]="formatSettings?.value?.displayMetadata?.selectedVariantCode"
            [dataType]="dataType"
            [attributeNew]="attr"
            [attrId]="attr.id"
            [formatStyles]="formatSettings?.value?.styles"
          ></app-display-at-datetime>
        </ng-container>
        <ng-container *ngIf="dataType.isCounter && formatSettings.showCounterOption">
            <span class="dynamic-label dynamic-checkbox">
              <span class="dynamic-checkbox-box">
                <p-checkbox [(ngModel)]="item.isCreateCounter" binary="true"
                inputId="counterOption"></p-checkbox>
              </span>
            </span>
        </ng-container>

        <ng-container
          *ngIf="!dataType.isUser && !isEnum && !isBoolean && !isRedirectIcon && !dataType.isDateTime && !dataType.isDate && !dataType.isCounter">
          <div [ngClass]="{'flex': !dataType.isEnum}">
            <div *ngIf="!dataType.isHtml; else htmlEditor" [ngStyle]="formatSettings?.value?.styles" class="readonly-value">
              <div class="value-container" [ngStyle]="{'justify-content': formatSettings?.value?.styles?.textAlign}">
                <span *ngIf="formatSettings.value.showIcon && attribute?.icon && !dataType.isEnum" [class]="attribute.icon" class="mr-2 attribute-icon"></span>
                <span *ngIf="item?.attribute?.value?.value" class="prefix"> {{ formatSettings.value.styles.prefix }} </span>
                <div [innerHTML]="item.attribute?.value | getAttributeReadonlyValue : attributes : dataTypes : formatSettings.value.showIcon
                             | useDecimalPlaces : formatSettings.value.styles.minDecimalPlaces : formatSettings.value.styles.maxDecimalPlaces
                             | replaceRuntimeVariables : artifactDto | async">
                </div>
                <span *ngIf="item?.attribute?.value?.value" class="suffix"> {{ formatSettings.value.styles.suffix }} </span>
              </div>
            </div>

            <ng-template #htmlEditor>
              <div
                [innerHTML]="item?.attribute?.value | getAttributeReadonlyValue : attributes : dataTypes | replaceRuntimeVariables : artifactDto | async | safeHtml"
                class="mce-content-body mce-content-readonly"
              ></div>
              <span *ngIf="item?.attribute?.value?.value" class="suffix"> {{ formatSettings.value.styles.suffix }} </span>
            </ng-template>
          </div>

        </ng-container>
      </div>
    </div>
  </div>
}
<app-display-at-overlay [ownerId]="attr.id"></app-display-at-overlay>
