import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArtifactResponseDto, AvrWidgetModelDto } from '@api/models';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { DynamicArtifactAvrWidgetArtifactSourceService } from '@widgets/avr-widget/services/artifact-source-services/dynamic-artifact.artifact-source.service';
import { ListItemAvrWidgetArtifactSourceService } from '@widgets/avr-widget/services/artifact-source-services/list-item.artifact-source.service';
import { StaticArtifactAvrWidgetArtifactSourceService } from '@widgets/avr-widget/services/artifact-source-services/static-artifact.artifact-source.service';
import { AvrTypesServices, AvrTypesSettingsService } from '@widgets/avr-widget/services/avr-types-services/index.avr-types-services';
import { AvrWidgetService } from '@widgets/avr-widget/services/avr-widget.service';
import { AvrWidgetModel, AvrWidgetValue } from '@widgets/avr-widget/types/avr-widget.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, ARTIFACT, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-avr-widget',
  templateUrl: './avr-widget.component.html',
  styleUrls: ['./avr-widget.component.scss'],
  providers: [
    ...AvrTypesServices,
    AvrWidgetService,
    DynamicArtifactAvrWidgetArtifactSourceService,
    ListItemAvrWidgetArtifactSourceService,
    StaticArtifactAvrWidgetArtifactSourceService,
    {
      provide: AvrTypesSettingsService,
      useFactory: (...avrTypesServices: any[]) => {
        return new AvrTypesSettingsService(avrTypesServices);
      },
      deps: AvrTypesServices as unknown as any[],
    },
  ],
})
export class AvrWidgetComponent extends WidgetsCoreComponent<AvrWidgetValue> implements OnInit {
  m: AvrWidgetModel;
  init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(ARTIFACT) public readonly artifact: ArtifactResponseDto | null = null,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<AvrWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    public readonly s: AvrWidgetService,
  ) {
    super(route, router, announcement, elRef);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    await this.s.init(this, this.isLayoutMode && this.widget.value?.model ? (this.widget.value.model as AvrWidgetModelDto) : null);
    this.init$.next(true);
  }
}
