import { Styles } from '@private/pages/page-management/page-builder-graphical/types/styles';
import { ListMatrixWidgetSettingsDto } from '@widgets/list-matrix-widget/types/list-matrix-widget-settings-dto';
import { ListMatrixWidgetStylesDto } from '@widgets/list-matrix-widget/types/list-matrix-widget-styles-dto';
import { ListOrientation } from '@widgets/list-matrix-widget/types/list-orientation';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { cloneDeep } from 'lodash';
import { ActionItem, ActionItemDto } from '@widgets/menu-widget/types/menu-action-types';

export const INITIAL_SETTINGS: ListMatrixWidgetSettingsDto = {
  list: {
    artifactTypeId: '',
    caption: '',
    summary: '',
    emptyMessage: 'No records found',
    cardsPerPage: 4,
    columnsCount: 3,
    styles: new Styles({
      columnGap: '10px',
      rowGap: '10px',
    }),
    doFilterOnUrlChange: true,
    doSortOnUrlChange: true,
    orientation: ListOrientation.horizontal,
  },
  card: {
    templateId: null,
    widgetId: null,
  },
  filtersForQueryParams: [],
  actions: [new ActionItem()],
};

export class ListMatrixWidgetSettings implements ListMatrixWidgetSettingsDto {
  list: {
    paginator: boolean;
    rows: number;
    caption: string;
    summary: string;
    emptyMessage: string;
    cardsPerPage: number;
    columnsCount: number;
    artifactTypeId: string;
    filterBy: string;
    sortField: string;
    sortOrder: number;
    lazy: boolean;
    doFilterOnUrlChange?: boolean;
    doSortOnUrlChange?: boolean;
    styles: ListMatrixWidgetStylesDto;
    orientation: ListOrientation;
  };
  card: {
    templateId: string | null;
    widgetId: string | null;
  };
  filtersForQueryParams: ArtifactFilter[] = [];
  actions: ActionItem[] = [new ActionItem()];

  constructor(dto: ListMatrixWidgetSettingsDto = INITIAL_SETTINGS) {
    Object.assign(this, cloneDeep(dto));
    dto.actions?.length && (this.actions = dto.actions.map(action => new ActionItem(action as ActionItemDto)));
  }

  toServer(): ListMatrixWidgetSettingsDto {
    const dto = {
      list: cloneDeep(this.list),
      card: cloneDeep(this.card),
      filtersForQueryParams: this.filtersForQueryParams.map((filter: ArtifactFilter) => filter.toServer()) || [],
      actions: this.actions.map(action => action.toServer()) as ActionItem[] || [],
    } as ListMatrixWidgetSettingsDto;

    return dto;
  }
}
