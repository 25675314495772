import {
  AvrWidgetButtonSettings as _BUTTON_SETTINGS_DTO,
  AvrWidgetDataSourceSettings as _DATA_SOURCE_SETTINGS_DTO,
  AvrWidgetResponseSettings as _RESPONSE_SETTINGS_DTO,
  AvrWidgetSettingsDto,
} from '@api/models';
import { AvrWidgetSettings as _SETTINGS_DTO } from '@api/models/avr-widget-settings';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';

export enum AvrArtifactSources {
  listItem = 'LIST_ITEM',
  staticArtifact = 'STATIC_ARTIFACT',
  dynamicArtifact = 'DYNAMIC_ARTIFACT',
}

type AvrArtifactSourcesType = Exclude<_SETTINGS_DTO['dataSource']['artifactSource'], null>;
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avrArtifactSourcesCheck: [AvrArtifactSourcesType] extends [`${AvrArtifactSources}`]
  ? `${AvrArtifactSources}` extends AvrArtifactSourcesType
    ? true
    : false
  : false = true;

export enum AvrActionTypes {
  render = 'RENDER',
  renderOnClick = 'RENDER_ON_CLICK',
  download = 'DOWNLOAD',
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avrActionCheck: [_SETTINGS_DTO['response']['actionType']] extends [`${AvrActionTypes}`]
  ? `${AvrActionTypes}` extends _SETTINGS_DTO['response']['actionType']
    ? true
    : false
  : false = true;

export class AvrWidgetDataSourceSettings implements _DATA_SOURCE_SETTINGS_DTO {
  artifactTypeId: string | null = null;
  staticArtifactId: string | null = null;
  artifactSource: AvrArtifactSourcesType | null = null;
  artifactListeningKey: string | null = null;
}

export class AvrWidgetButtonSettings implements _BUTTON_SETTINGS_DTO {
  label = 'Get Artifact Visual Representation';
}

export class AvrWidgetResponseSettings implements _RESPONSE_SETTINGS_DTO {
  avrType: AvrTypes | null = null;
  actionType: AvrActionTypes = AvrActionTypes.download;
  avrTypeSettings: AvrWidgetSettingsDto = {};
}

export class AvrWidgetSettings implements _SETTINGS_DTO {
  dataSource: AvrWidgetDataSourceSettings = new AvrWidgetDataSourceSettings();
  button: AvrWidgetButtonSettings = new AvrWidgetButtonSettings();
  response: AvrWidgetResponseSettings = new AvrWidgetResponseSettings();

  constructor(settings?: AvrWidgetSettings) {
    settings && Object.assign(this, settings);
  }
}
