import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'getPictureSource',
})
export class PictureGetSourcePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string | null): SafeUrl {
    return url ? this.sanitizer.bypassSecurityTrustUrl(url) : '';
  }
}
