import { TenantArtifactService } from '@api/services/tenant-artifact.service';

const getUrlFromImageFileId = (imageFileId: string, rootUrl: string, token?: string): string => {
  if (!imageFileId) return '';

  let imageUrl = (rootUrl + TenantArtifactService.ArtifactControllerDownloadPath).replace('{id}', imageFileId);
  if (token) imageUrl = imageUrl + '?authorization=Bearer%20' + token;

  return imageUrl;
};

const checkIsExternalUrl = (value: string | null | undefined): boolean => {
  if (!value || /^[a-f0-9]{24}$/i.test(value)) return false;
  try {
    const cleanedValue = value.replace(/^url\((['"]?)(.*?)\1\)$/, '$2');
    const url = new URL(cleanedValue);
    return !(url.pathname.includes('/api/tenant/artifact/') && url.searchParams.has('authorization'));
  } catch {
    return true;
  }
};

function extractImageFileId(input: string): string | null {
  if (/^[a-zA-Z0-9]+$/.test(input)) {
    return input;
  }
  const match = input.match(/artifact\/([^/]+)\/download/);
  return match ? match[1] : null;
}

function hasGradient(backgroundValue: string) {
  const gradientRegex = /(linear-gradient|radial-gradient|conic-gradient|repeating-linear-gradient|repeating-radial-gradient)/;
  return gradientRegex.test(backgroundValue);
}

function fixGradient(gradient: string): string {
  gradient = gradient.trim().toLowerCase();
  const validGradientRegex = /^(linear|radial|conic)-gradient\([^)]*\)$/;
  if (validGradientRegex.test(gradient)) {
    return gradient;
  }
  if (/(circle|ellipse)/.test(gradient)) {
    return `radial-gradient(${gradient.replace(/(linear|radial|conic)-gradient\(/, '')}`;
  } else if (/\d+deg|to /.test(gradient)) {
    return `linear-gradient(${gradient.replace(/(linear|radial|conic)-gradient\(/, '')}`;
  } else if (/from/.test(gradient)) {
    return `conic-gradient(${gradient.replace(/(linear|radial|conic)-gradient\(/, '')}`;
  }
  return gradient;
}

export const ImageMethods = {
  getUrlFromImageFileId,
  checkIsExternalUrl,
  extractImageFileId,
  hasGradient,
  fixGradient,
};
