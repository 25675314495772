import { Injectable } from '@angular/core';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { Constructor } from '@shared/types/constructor.types';
import { AbstractAvrQuerySettingsComponent } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/abstract.avr-types-settings.component';
import { AvrDocumentGenerationTypesSettingsComponent } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/document-generation/document-generation.avr-types-settings.component';
import { AvrQrCodesTypesSettingsComponent } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/qr-codes/qr-codes.avr-types-settings.component';
import { AvrSwissBillTypesSettingsComponent } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/swiss-bill/swiss-bill.avr-types-settings.component';

export const AvrTypesSettingsDeclarations = [
  AvrSwissBillTypesSettingsComponent,
  AvrDocumentGenerationTypesSettingsComponent,
  AvrQrCodesTypesSettingsComponent,
] as const;

type DefinedAvrTypesSettings = `${InstanceType<(typeof AvrTypesSettingsDeclarations)[number]>['avrType']}`;
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avrTypesSettingsDeclarationsCheck: [DefinedAvrTypesSettings] extends [`${AvrTypes}`]
  ? `${AvrTypes}` extends DefinedAvrTypesSettings
    ? true
    : false
  : false = true;

@Injectable()
export class AvrTypesSettingsService {
  private avrTypesSettings: {
    [K in AvrTypes]: Constructor<AbstractAvrQuerySettingsComponent<K, any>>;
  } = {
    [AvrTypes.swissBill]: AvrSwissBillTypesSettingsComponent,
    [AvrTypes.documentGeneration]: AvrDocumentGenerationTypesSettingsComponent,
    [AvrTypes.qrCodes]: AvrQrCodesTypesSettingsComponent,
  } as const;

  getAvrTypesSettings<K extends AvrTypes>(avrType: K): (typeof this.avrTypesSettings)[K] {
    return this.avrTypesSettings[avrType];
  }
}
