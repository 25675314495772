import { Pipe, PipeTransform } from '@angular/core';
import { RuleUtilsService } from '../services';
import { WorkflowRule } from '../types';

@Pipe({
  name: 'canRuleBeUnassigned',
  standalone: true,
})
export class CanRuleBeUnassignedPipe implements PipeTransform {
  constructor(private ruleUtils: RuleUtilsService) {}

  transform(rule: WorkflowRule, pageId: string): boolean {
    return !!rule?.global && this.ruleUtils.isUsedByPage(rule, pageId) && !this.ruleUtils.isOwnedByPage(rule, pageId);
  }
}
