import { Component, inject, Input, OnInit } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ClientData } from '@shared/types/local-storage.types';
import { ElvisUtil } from '@shared/utils/elvis.util';

@Component({
  selector: 'app-simple-kind-input',
  templateUrl: './simple-kind-input.component.html',
  styleUrls: ['./simple-kind-input.component.scss'],
})
export class SimpleKindInputComponent implements OnInit {
  @Input() index: number;
  @Input() artifactAttribute: NewClientAttribute;
  @Input() fullAttribute: NewAttribute;
  @Input() fullDataType: NewDataType;

  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  settings: Record<string, any>;
  firstDayOfWeek: number;

  private readonly cache = inject(NewCacheService);
  private readonly elvisUtil = inject(ElvisUtil);
  private readonly tinyMceHelper = inject(TinyMceHelper);

  get isInteger(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.integer;
  }

  get isDecimal(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.decimal;
  }

  ngOnInit(): void {
    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;
    this.settings = this.tinyMceHelper.getTinyMceAdvancedSettings();
  }

  checkNumFormat(event: string, id: string, targetObject: any, key: string): void {
    this.elvisUtil.checkNumFormat(event, id, targetObject, key, this.isInteger, this.isDecimal);
  }

  checkNumFormatMultiple(): void {
    const checkNum = (startValue: string): string => {
      const decimal = /^[0-9]{0,}([.][0-9]{0,})?$/g;
      const integer = /^[0-9]{0,}$/g;
      let value = startValue;

      if (this.isInteger && !integer.test(value)) {
        value = value.substr(0, value.length - 1);
        return checkNum(value);
      } else if (this.isDecimal && !decimal.test(value)) {
        value = value.substr(0, value.length - 1);
        return checkNum(value);
      }
      return value;
    };
    this.artifactAttribute.value = this.artifactAttribute.value.map((value: any) => checkNum(value)).filter((item: any) => !!item);
  }
}
