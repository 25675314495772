import { RecordDto } from '@api/models/record-dto';
import { Attribute } from '@private/pages/artifact-type-management/attribute/types/attribute.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';

export class ArtifactModel {
  constructor(
    public inProgress: boolean = false,
    public artifact: NewArtifact = new NewArtifact(),
    public artifactTypes = new ListContainer<NewArtifactType>(),
    public artifactType: NewArtifactType | null = null,
    public artifactTypeOptions: SelectOption<string, NewArtifactType>[] = [],
    public attributes = new ListContainer<NewAttribute>(),
    public dataTypes = new ListContainer<NewDataType>(),
    public linkTypeOptions: SelectOption<string, LinkType, LinkDirection>[] = [],
    public linkColumns: TableColumn[] = [],
    public rowsPerPage: number | null = null,
    public rowsPerPageOptions: number[] = [],
    public links: Link[] = [],
  ) {}
}

export class Artifact {
  public id = '';
  public artifactTypeId: string;
  public folderId: string;
  public attributes: Record<string, Partial<ArtifactAttribute>> = {};
  public clientAttributes: ArtifactAttribute[] = [];
  public links: Link[] = [];
  public created: RecordDto;
  public updated: RecordDto;
  public deleted: RecordDto | null = null;

  constructor(artifact?: Partial<Artifact>) {
    artifact && Object.assign(this, artifact);
  }
}

export class ArtifactAttribute {
  constructor(
    public attribute: Attribute | null = null,
    public value: any = null,
    public isMandatory: boolean = false,
  ) {}
}

export class Link {
  constructor(
    public id: string = '',
    public source: ArtifactLinkRef | null = null,
    public destination: ArtifactLinkRef | null = null,
    public linkTypeId: string = '',
    public created: RecordDto | null = null,
    public updated: RecordDto | null = null,
    public deleted: RecordDto | null = null,
    public direction: LinkDirection | null = null,
    public linkType: SelectOption<string, LinkType, LinkDirection> | null = null,
    public target: NewArtifact | null = null,
    public targetArtifactType: NewArtifactType | null = null,
    public artifactOptions: SelectOption<string, NewArtifact>[] = [],
  ) {}
}

export class NewLinkBoilerplate {
  constructor(
    public sourceId: string | null | undefined = null,
    public sourceTypeId: string | null | undefined = null,
    public destinationId: string | null | undefined = null,
    public destinationTypeId: string | null | undefined = null,
    public linkTypeId: string = '',
    public direction: LinkDirection | null = null,
    public linkName: string | null = null,
  ) {}
}

export class ArtifactLinkRef {
  constructor(
    public artifactTypeId: string | null = null,
    public artifactId: string | null = null,
    public artifactType: NewArtifactType | null = null,
    public artifact: NewArtifact | null = null,
  ) {}
}

export enum LinkDirection {
  outgoing = 'OUTGOING',
  incoming = 'INCOMING',
}
