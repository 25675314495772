import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BoxShadowGeneratorComponent } from '@shared/components/box-shadow-generator/box-shadow-generator.component';
import { GradientGeneratorComponent } from '@shared/components/gradient-generator/gradient-generator.component';
import { FileUploadModule } from 'primeng/fileupload';
import { TableModule } from 'primeng/table';
import { ElvisSharedModule } from '../../../shared/elvis-shared.module';

// Components
import { DashboardComponent } from './dashboard.component';

const declarations = [DashboardComponent];

@NgModule({
  declarations: [...declarations],
  imports: [CommonModule, FileUploadModule, TableModule, ElvisSharedModule, TranslateModule, GradientGeneratorComponent, BoxShadowGeneratorComponent],
  exports: [...declarations],
})
export class DashboardModule {}
