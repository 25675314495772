import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormatAttributeAction } from '@workflows/types';
import { filter } from 'rxjs';
import { RuleFormatHandlerService } from '../services';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';
import { WidgetFormatEventMap } from '../types';

@Directive({
  selector: '[appFormatAttribute]',
})
export class FormatAttributeDirective extends AbstractRuleAttributeDirective implements OnInit, OnDestroy {
  private static readonly BACKGROUND_COLOR_STYLE = 'background-color';
  private static readonly COLOR_STYLE = 'color';

  @Input() attributeValue: any;
  @Input() artifactId: string;

  private backgroundColor: string;
  private color: string;
  private lastFormatEvent: FormatAttributeAction;

  constructor(
    protected ruleFormatHandler: RuleFormatHandlerService,
    private elementRef: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.backgroundColor = this.elementRef.nativeElement.style[FormatAttributeDirective.BACKGROUND_COLOR_STYLE];
    this.color = this.elementRef.nativeElement.style[FormatAttributeDirective.COLOR_STYLE];

    this.initSubscription = this.ruleFormatHandler.formatEvent$
      .pipe(filter(formatEvent => this.isMatchingArtifactId(formatEvent) && !this.isMatchingLastFormatEvent(formatEvent)))
      .subscribe(formatEvent => this.applyStyles(formatEvent[this.widgetId][this.attributeId][this.artifactId]));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private applyStyles(event: FormatAttributeAction) {
    this.lastFormatEvent = event;
    if (!event.formatStyles) {
      this.elementRef.nativeElement.style[FormatAttributeDirective.BACKGROUND_COLOR_STYLE] = this.backgroundColor;
      this.elementRef.nativeElement.style[FormatAttributeDirective.COLOR_STYLE] = this.color;
      return;
    }
    for (const styleKey in event.formatStyles) {
      if (event.formatStyles.hasOwnProperty(styleKey)) {
        this.elementRef.nativeElement.style[styleKey] = event.formatStyles[styleKey];
      }
    }
  }

  private isMatchingArtifactId(formatEventMap: WidgetFormatEventMap): boolean {
    return !!formatEventMap[this.widgetId]?.[this.attributeId]?.[this.artifactId];
  }

  private isMatchingLastFormatEvent(formatEventMap: WidgetFormatEventMap): boolean {
    const lastFormatEvent = formatEventMap[this.widgetId]?.[this.attributeId]?.[this.artifactId];
    return lastFormatEvent && this.lastFormatEvent === lastFormatEvent;
  }
}
