import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { MenuWidgetValue } from '@widgets/menu-widget/types/menu-widget.types';

export type ArtifactButtonContainersType = {
  [position in ButtonContainerPositionEnum]?: BlockPartWidget<MenuWidgetValue>;
};

export type ArtifactButtonContainersTypeDto = {
  [position in ButtonContainerPositionEnum]?: string | BlockPartWidget;
};

export interface ArtifactButtonContainers extends ArtifactButtonContainersType {}

export interface ArtifactButtonContainersDto extends ArtifactButtonContainersTypeDto {}

export enum ButtonContainerPositionEnum {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom',
}
