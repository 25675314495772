import { Pipe, PipeTransform } from '@angular/core';
import { ConditionDateFilterType } from '../../../../workflows/types/conditions/rule-condition';
import { SharedMethods } from '../../../methods/shared.methods';
import { DateDiffsEnum } from '../../../types/filter.types';

@Pipe({
  name: 'isFilterDiffType',
  standalone: true,
})
export class IsFilterDiffTypePipe implements PipeTransform {
  transform(filterType: ConditionDateFilterType): boolean {
    return SharedMethods.isValueInEnum(filterType, DateDiffsEnum);
  }
}
