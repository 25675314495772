import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { TeamResponseDto } from '@api/models/team-response-dto';
import { Application } from '@private/pages/application-management/application/types/application.types';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { APPLICATION_ID_KEY, ID_KEY } from '@shared/constants/constants';
import { LinkMethods } from '@shared/methods/link.methods';
import { TableMethods } from '@shared/methods/table.methods';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { Exception } from '@shared/types/exception.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewTableColumn, TableFormatSettings } from '@shared/types/table.types';
import { NewTeam } from '@shared/types/team.types';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ArtifactGroupingService } from '@widgets/shared/components/artifact-list-table/services/grouping/artifact-grouping.service';
import { ListWidgetTableHelper } from '@widgets/shared/components/artifact-list-table/services/list-widget-table.helper';
import { ListInternalWidgetModelDto } from '@widgets/shared/components/artifact-list-table/types/list-widget-selected.types';
import { ListWidgetTableLoadModeEnum } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';
import {
  ListWidgetModel,
  ListWidgetType,
  ListWidgetValue,
  TABLE_FORMAT_STATE_KEY,
} from '@widgets/shared/components/artifact-list-table/types/list-widget.types';
import { FilterMetadata } from 'primeng/api';
import { ListWidgetComponent } from '../list-widget.component';

@Injectable()
// TODO: redesign whole process of initialisation
export class ListWidgetService {
  c: ListWidgetComponent;
  m: ListWidgetModel;
  /** original context model */
  ocm: ListWidgetModel;

  constructor(
    public readonly cache: NewCacheService,
    private readonly listWidgetTableHelper: ListWidgetTableHelper,
    private readonly localStorageService: LocalStorageService,
    private readonly artifactGroupingService: ArtifactGroupingService,
    private readonly filtersService: ArtifactFiltersService,
  ) {}

  async init(context: ListWidgetComponent): Promise<void> {
    await this.loadOptions(context);
  }

  setArtifactTypesByApplication(applications: SelectOption<string, Application>[]): void {
    this.m.options.artifactTypesByApplication.setList(this.m.options.artifactTypes.filterByKey(APPLICATION_ID_KEY, applications[0].value.id));
  }

  updateColumns(model: ListWidgetModel = this.m): void {
    const columns = this.listWidgetTableHelper.generateOptionColumns(model);
    model.options.columns.setList(columns, 'key');
    model.selected.setColumnsFromSavedDto(model.options.columns);
  }

  resetColumns(model: ListWidgetModel = this.m): void {
    model.options.columns.setList([]);
    model.selected.setColumns([]);
  }

  resetGrouping(model: ListWidgetModel = this.m): void {
    model.settings.groupingOptions = [];
    model.settings.grouping.groupingAttributes = [];
  }

  updateStateInLocalStorage(stateKey: string, attributeKey: string, value: any): void {
    this.localStorageService.setToState(stateKey, attributeKey, value);
  }

  async getModuleFromFolderId(moduleId: string): Promise<ArtifactResponseDto | null> {
    const res = await this.cache.data.artifacts.getManyAsync([], [{ 'formatData.folderId': { $oid: moduleId } }]).catch(() => []);
    return res && res.length ? res[0] : null;
  }

  updateModelStateAndSetIntoLocalStorage(hash: string, model: ListWidgetModel) {
    const selected = model.selected;
    const state = model.state;
    if (state && selected.dateFilters) {
      Object.keys(selected.dateFilters)
        .filter(key => state.filters[key]?.length)
        .forEach(key => {
          const selectedFilter = selected.dateFilters[key].filters[0];
          const columnFilter = state.filters[key][0] as FilterMetadata;
          columnFilter.matchMode = selectedFilter.filterType;
          columnFilter.value = selectedFilter.value;
        });
    }
    this.localStorageService.set(hash, model.state);
  }

  private async loadOptions(context: ListWidgetComponent): Promise<void> {
    try {
      if (context.isLayoutMode && (!context.m || context.m.isFirstLoad || !Object.keys(context.m).length)) {
        if (!context.widget.value || !Object.keys(context.widget.value).length) {
          context.widget.value = new ListWidgetValue({ model: new ListWidgetModel({ listType: ListWidgetType.artifact }) });
        }

        if (context.widget.value?.model?.state?.filters) {
          TableMethods.formatTableFilterNamesToClient(context.widget.value.model.state.filters);
        }

        if (!context.widget.value?.model?.state) {
          context.widget.value.model.state = {};
          context.widget.value.model.state.filters = {};
        }

        if (context.widget.value.model.state[TABLE_FORMAT_STATE_KEY]) {
          context.widget.value.model.state[TABLE_FORMAT_STATE_KEY] = new TableFormatSettings(context.widget.value.model.state[TABLE_FORMAT_STATE_KEY]);
        }

        const model = new ListWidgetModel();

        this.ocm = context.widget.value.model;
        context.widget.value.model = model;
        context.widget.value.model.state = this.ocm.state;

        model.options.systemAttributes.setList(this.filtersService.getSystemAttributes(), ID_KEY);
        this.setApplicationsToModel(model);
        this.setPagesToModel(model);
        this.setArtifactTypesToModel(model);
        this.setAttributesToModel(model);
        this.setDataTypesToModel(model);
        this.setTeamsToModel(model);
        this.setLinkTypesToModel(model);
        await this.optionsLoadedCb(context, model);
      } else {
        this.setModels(context);
      }
    } catch (e: any) {
      console.error(
        new Exception({
          name: 'ListWidgetLoadOptionsException',
          message: 'Something went wrong during ListWidgetOptions initialization',
          originalEvent: e,
        }),
      );
    }
  }

  private setApplicationsToModel(model: ListWidgetModel): void {
    const applications = this.cache.data.applications.value?.map(dto => new Application(dto as ApplicationResponseDto)) || [];
    model.options.applications.setList(applications, ID_KEY);
  }

  private setPagesToModel(model: ListWidgetModel): void {
    const pages = this.cache.data.pages.value?.map(dto => new Page(dto as PageResponseDto)) || [];
    model.options.pages.setList(pages, ID_KEY);
  }

  private setArtifactTypesToModel(model: ListWidgetModel): void {
    const artifactTypes = this.cache.data.artifactTypes.value?.map(dto => new NewArtifactType(dto as ArtifactTypeResponseDto)) || [];
    model.options.artifactTypes.setList(artifactTypes, ID_KEY);
  }

  private setAttributesToModel(model: ListWidgetModel): void {
    const attributes = this.cache.data.attributes.value?.map(dto => new NewAttribute(dto as AttributeResponseDto)) || [];
    model.options.attributes.setList(attributes, ID_KEY);
  }

  private setDataTypesToModel(model: ListWidgetModel): void {
    const dataTypes = this.cache.data.dataTypes.value?.map(dto => new NewDataType(dto as DataTypeResponseDto)) || [];
    model.options.dataTypes.setList(dataTypes, ID_KEY);
  }

  private setTeamsToModel(model: ListWidgetModel): void {
    const teams = this.cache.data.teams.value?.map(dto => new NewTeam(dto as TeamResponseDto)) || [];
    model.options.teams.setList(teams, ID_KEY);
  }

  private setLinkTypesToModel(model: ListWidgetModel): void {
    const linkTypes = this.cache.data.linkTypes.value?.map(dto => new LinkType(dto as LinkTypeResponseDto)) || [];

    model.options.linkTypes.setList(linkTypes, ID_KEY);
    model.options.linkTypesOptions = linkTypes
      .map(linkType => [
        new SelectOption(linkType.outgoingName, linkType, LinkDirection.outgoing),
        new SelectOption(linkType.incomingName, linkType, LinkDirection.incoming),
      ])
      .flat();
  }

  private async optionsLoadedCb(context: ListWidgetComponent, model: ListWidgetModel): Promise<void> {
    try {
      const modelDto = (!this.m ? this.ocm : context.widget.value.model) as ListInternalWidgetModelDto;

      await model.initModel(modelDto, context.applicationId, this.cache);
      model.hash = context.hash;

      if (model.state) {
        this.updateModelStateAndSetIntoLocalStorage(context.hash, model);
        if (model.state.columnOrder) {
          const columnsMap: Record<string, NewTableColumn> = model.selected.columns.reduce(
            (prev, item) => ({
              ...prev,
              [item.key]: item,
            }),
            {},
          );
          const columns = model.state.columnOrder
            .filter((key: string) => columnsMap[key])
            .map((key: string) => {
              const result = columnsMap[key];
              delete columnsMap[key];
              return result;
            }) as NewTableColumn[];
          columns.push(...model.selected.columns.filter(col => !!columnsMap[col.key]));
          model.selected.setColumns(columns);
        }
      }
      context.widget.value.model = model;
      this.setModels(context);
      this.setRestrictions();
      this.updateColumns(model);
      this.registerQueryParamSubscriptions(context, model);

      if (this.m.isFirstLoad) {
        this.c.helper.generateAddButtonOptions();
        const groupAttribute = context.m.settings.grouping.groupingAttributes[0];
        this.artifactGroupingService.setGroupingHandler(context.hash, groupAttribute?.value, { ...this.m.options, data: this.c.listWidgetTable?.data || [] });
        this.m.options.setGroupCollapseOptions(this.m.settings.grouping.pagination);
        this.m.isFirstLoad = false;
      }

      this.m.selected.applications.length && this.setArtifactTypesByApplication(this.m.selected.applications);
      this.m.settings.showTable = true;
    } catch (e: any) {
      console.error(
        new Exception({
          name: 'ListWidgetInitModelException',
          message: 'Something went wrong during LinkPopupWidgetModel initialization',
          originalEvent: e,
        }),
      );
    }
  }

  private setModels(context: ListWidgetComponent): void {
    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
  }

  // TODO - check this handling
  private async setRestrictions(): Promise<void> {
    this.m.restrictions = await LinkMethods.getGroupedLinkRestrictions(this.m.options.artifactTypes.list, this.m.options.linkTypes.list);
    // await this.setMirrorColumns();
  }

  private registerQueryParamSubscriptions(context: ListWidgetComponent, model: ListWidgetModel): void {
    context.registerSubscriptions([
      context.route.queryParams.subscribe(async (params: Params) => {
        if (context.m.settings.loadMode === ListWidgetTableLoadModeEnum.byModule) {
          await this.onArtifactIdQueryParamChange(params, context, model);
        }
      }),
    ]);
  }

  private async onArtifactIdQueryParamChange(params: Params, context: ListWidgetComponent, model: ListWidgetModel): Promise<void> {
    const moduleId = params[model.settings.urlKeys.listeningKeys.artifactId];
    const isNewModule = moduleId && moduleId !== model.selected.module?.id;

    isNewModule && this.c.helper.generateAddButtonOptions();
  }

  private shouldRefreshTableOnQueryParamChange(loadMode: ListWidgetTableLoadModeEnum, isNewModule: boolean): boolean {
    return loadMode === ListWidgetTableLoadModeEnum.byArtifactType || (loadMode === ListWidgetTableLoadModeEnum.byModule && isNewModule);
  }
}
