import { FormulaActionWorkflowSettingsDto } from '@api/models/formula-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ReplaceRuntimeVariablesPipe } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { AttributeActionHandlerService, ModifyAttributeValueOperation } from '@workflows/shared';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { WorkflowActionType, WorkflowTriggerEvent } from '@workflows/types';
import * as Parser from 'hot-formula-parser';
import { FormulaErrorId } from 'hot-formula-parser';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export interface FormulaParsedResult {
  result: string | number | boolean | null;
  error: FormulaErrorId | null;
}

export enum ActionFormulaJSKeys {
  formula = 'formula',
}

export class WorkflowActionFormulaDto implements FormulaActionWorkflowSettingsDto {
  formula: FormulaActionWorkflowSettingsDto[ActionFormulaJSKeys.formula] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionFormulaDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionFormula extends AbstractWorkflowAttributeBasedAction<FormulaActionWorkflowSettingsDto> {
  replaceRuntimeVariablesPipe?: ReplaceRuntimeVariablesPipe;
  parser: Parser.Parser = new Parser.Parser();

  constructor(
    conditionConverter: RuleConditionValueConverterService,
    dto?: WorkflowActionDto,
    actionAttributeHandler?: AttributeActionHandlerService,
    replaceRuntimeVariablesPipe?: ReplaceRuntimeVariablesPipe,
  ) {
    super({ actionSettingDto: WorkflowActionFormulaDto, conditionConverter, type: WorkflowActionType.FORMULA, dto, actionAttributeHandler });

    this.supportedAttributeDataTypes = [BaseDataType.text, BaseDataType.html, BaseDataType.integer, BaseDataType.decimal];
    this.supportedAttributeDataKinds = [DataTypeKind.simple, DataTypeKind.bounded];
    this.replaceRuntimeVariablesPipe = replaceRuntimeVariablesPipe;
  }

  static extractExpressionVariables(expression: string): string[] {
    const variables: string[] = [];
    const regex = /{(.*?)}/g;

    let match;
    while ((match = regex.exec(expression)) !== null) {
      variables.push(match[1]);
    }
    return variables;
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifactDto;
  }

  execute(ruleTriggerEvent: WorkflowTriggerEvent): void {
    let formula = this.actionSettings.formula.value;

    this.replaceRuntimeVariablesPipe?.transform(formula, ruleTriggerEvent.payload?.artifactDto).then(formulaWithReplacedVariables => {
      const formulaResult: FormulaParsedResult = this.parser.parse(formulaWithReplacedVariables);

      this.actionAttributeHandler.notifyModifyAttributeValueEvent({
        artifactTypeId: this.artifactTypeId,
        attributeId: this.attributeId,
        value: formulaResult.error ? formulaWithReplacedVariables : formulaResult.result,
        operation: ModifyAttributeValueOperation.replace,
      });
    });
  }

  isValid(): boolean {
    return super.isValid() && !!(this.artifactTypeId && this.attributeId);
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      actionSettings: {
        [ActionFormulaJSKeys.formula]: {
          isDynamic: false,
          value: this.actionSettings.formula.value,
        },
      },
    };
  }
}
