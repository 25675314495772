import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { StyleCopyPastingService } from '../../../../services/style-copy-pasting.service';
import { ChipStyles, FontStyles, IconStyles } from '../../../../types/menu-widget-styles.types';

@Component({
  selector: 'app-content-style-form',
  templateUrl: './content-style-form.component.html',
  styleUrls: ['./content-style-form.component.scss'],
})
export class ContentStyleFormComponent implements AfterViewInit {
  @Input() contentStyle: FontStyles | IconStyles | ChipStyles;
  @Input() isShowExtended: boolean;
  isLoaded: boolean;

  constructor(
    public readonly styleService: StyleCopyPastingService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.isLoaded = true;
    this.cdr.markForCheck();
  }

  get isIconStyle(): boolean {
    return this.contentStyle instanceof IconStyles;
  }

  get isChipStyle(): boolean {
    return this.contentStyle instanceof ChipStyles;
  }

  get isShowOrder(): boolean {
    // @ts-ignore
    return this.contentStyle?.order !== undefined;
  }
}
