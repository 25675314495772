import { inject, Pipe, PipeTransform } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { PageHelper } from '@shared/helpers/page-helper';
import { ImageMethods } from '@shared/methods/image.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import {
  BackgroundAttachmentEnum,
  BackgroundPositionEnum,
  BackgroundRepeatEnum,
  BackgroundSizeEnum,
} from '@shared/components/image-pointer/types/image-pointer.types';

@Pipe({
  name: 'imageFilterStyles',
})
export class ImageFilterStylesPipe implements PipeTransform {
  private readonly apiConfiguration = inject(ApiConfiguration);
  private readonly authorizationService = inject(AuthorizationService);
  private readonly pageHelper = inject(PageHelper);

  internalImageIdToUrlPipe(imageFileId: string): string {
    if (!imageFileId) return '';

    let token = this.authorizationService.getToken;
    if (!token || this.pageHelper.usePublicToken) token = this.authorizationService.getAnonymousToken;

    return ImageMethods.getUrlFromImageFileId(imageFileId, this.apiConfiguration.rootUrl, token);
  }

  getCleanUrl(url: string): string {
    return url.replace(/^url\((.+)\)$/, '$1');
  }

  transform(styles: Partial<StylesDto>): Partial<StylesDto> {
    delete styles.background;

    const flagHasGradient = (styles.backgroundImage) ? ImageMethods.hasGradient(styles.backgroundImage) : false;

    if (!styles.backgroundColor) delete styles.backgroundColor;
    if (!styles.backgroundRepeat) styles.backgroundRepeat = BackgroundRepeatEnum.noRepeat;
    if (!styles.backgroundSize) styles.backgroundSize = BackgroundSizeEnum.auto;
    if (!styles.backgroundPosition) styles.backgroundPosition = BackgroundPositionEnum.centerCenter;
    if (!styles.backgroundAttachment) styles.backgroundAttachment = BackgroundAttachmentEnum.scroll;
    if (!styles.backgroundOpacity) styles.backgroundOpacity = '1';
    else if(styles.backgroundOpacity === '0') styles.backgroundOpacity = '1';

    if (!styles.borderTopStyle) styles.borderTopStyle = 'none';
    if (!styles.borderRightStyle) styles.borderRightStyle = 'none';
    if (!styles.borderBottomStyle) styles.borderBottomStyle = 'none';
    if (!styles.borderLeftStyle) styles.borderLeftStyle = 'none';
    if (!styles.boxShadow) styles.boxShadow = 'none';

    let convertedStyles = {
      ...styles,
      '--background-color': styles?.backgroundColor,
      '--background-repeat': styles?.backgroundRepeat,
      '--background-size': styles?.backgroundSize,
      '--background-position': styles?.backgroundPosition,
      '--background-attachment': styles?.backgroundAttachment,
      '--opacity': styles?.backgroundOpacity || '1',

      '--border-top-width': styles?.borderTopWidth,
      '--border-right-width': styles?.borderRightWidth,
      '--border-bottom-width': styles?.borderBottomWidth,
      '--border-left-width': styles?.borderLeftWidth,
      '--border-top-style': styles?.borderTopStyle,
      '--border-right-style': styles?.borderRightStyle,
      '--border-bottom-style': styles?.borderBottomStyle,
      '--border-left-style': styles?.borderLeftStyle,
      '--border-top-color': styles?.borderTopColor,
      '--border-right-color': styles?.borderRightColor,
      '--border-bottom-color': styles?.borderBottomColor,
      '--border-left-color': styles?.borderLeftColor,
      '--border-top-left-radius': styles?.borderTopLeftRadius,
      '--border-top-right-radius': styles?.borderTopRightRadius,
      '--border-bottom-right-radius': styles?.borderBottomRightRadius,
      '--border-bottom-left-radius': styles?.borderBottomLeftRadius,
      '--border-radius-range': styles?.borderRadiusRange,
      '--box-shadow': styles?.boxShadow,
  };

    if(styles.backgroundImage) {
      styles.backgroundImage = this.getCleanUrl(styles.backgroundImage);
      if (!ImageMethods.checkIsExternalUrl(styles.backgroundImage)) {
        const imageId = ImageMethods.extractImageFileId(styles.backgroundImage);
        if(imageId) styles.backgroundImage = this.internalImageIdToUrlPipe(imageId);
      }

      if (flagHasGradient) convertedStyles = {
        ...convertedStyles,
        '--background-image': ImageMethods.fixGradient(styles?.backgroundImage),
      }
      else {
        convertedStyles = {
          ...convertedStyles,
          '--background-image': `url(${styles?.backgroundImage})`,
        }
      }
    }

    const excludedKeys = new Set([
      'backgroundImage',
      'backgroundColor',
      'backgroundRepeat',
      'backgroundSize',
      'backgroundPosition',
      'backgroundAttachment',
      'opacity',

      'borderTopWidth',
      'borderRightWidth',
      'borderBottomWidth',
      'borderLeftWidth',
      'borderTopStyle',
      'borderRightStyle',
      'borderBottomStyle',
      'borderLeftStyle',
      'borderTopColor',
      'borderRightColor',
      'borderBottomColor',
      'borderLeftColor',
      'borderTopLeftRadius',
      'borderTopRightRadius',
      'borderBottomRightRadius',
      'borderBottomLeftRadius',
      'borderRadiusRange',
      'boxShadow',
  ]);
    let stylesMap = Object.entries(convertedStyles)
      .filter(([key]) => !excludedKeys.has(key))
      .reduce(
        (acc, [key, value]) => {
          const kebabKey = this.toKebabCase(key);
          acc[kebabKey as string] = value as string;
          return acc;
        },
        {} as { [key: string]: string },
      );

    return stylesMap;
  }

  private toKebabCase(str: string): string {
    return str.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);
  }
}
