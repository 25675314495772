import { inject, Pipe, PipeTransform } from '@angular/core';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { ArtifactWidgetModel, ArtifactWidgetSelectedEntities } from '@widgets/artifact-widget/types/artifact-widget.types';

@Pipe({ name: 'getCustomFieldInnerHtml' })
export class GetCustomFieldInnerHtmlPipe implements PipeTransform {
  private readonly artifactWidgetCustomAttributeHelper = inject(ArtifactWidgetCustomAttributeHelper);

  async transform(hasCustomInnerHtml: boolean, dataTypeId: string, selected: ArtifactWidgetSelectedEntities, awModel: ArtifactWidgetModel): Promise<string> {
    return hasCustomInnerHtml ? this.artifactWidgetCustomAttributeHelper.getCustomAttributeValue(dataTypeId, selected, awModel) : '---';
  }
}
