import { ImportDto } from '@api/models';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { NewDataType } from '@shared/types/data-type.types';
import { kebabCase } from 'lodash';

export interface BaseAttributeItem {
  id: string;
  name?: string;
  dataTypeId: string;
  multipleValues: boolean;
}

export enum AttributeMultipleValuesOrdering {
  asc = 'ASC',
  desc = 'DESC',
  none = 'NONE',
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const attributeMultipleValuesOrderingCheck: [Required<AttributeResponseDto>['multipleValuesOrdering']] extends [`${AttributeMultipleValuesOrdering}`]
  ? `${AttributeMultipleValuesOrdering}` extends Required<AttributeResponseDto>['multipleValuesOrdering']
    ? true
    : false
  : false = true;

export class NewAttribute implements AttributeResponseDto, BaseAttributeItem {
  id: string;
  applicationId: string;
  name = '';
  alias = '';
  icon: string;
  description = '';
  dataTypeId: string;
  uri = '';
  multipleValues = false;
  multipleValuesOrdering? = AttributeMultipleValuesOrdering.asc;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null = null;
  counterFormat? = '';
  counterNextValue? = 1;
  systemAttributeKey?: string;
  private = false;
  imported?: ImportDto | undefined;

  constructor(attribute?: Partial<AttributeResponseDto>) {
    attribute && Object.assign(this, attribute);
  }

  get isSystemAttribute(): boolean {
    return !!this.systemAttributeKey;
  }

  get nameInKebab(): string {
    return kebabCase(this.name);
  }

  get isSingleValue(): boolean {
    return !this.multipleValues;
  }

  canBePrimary({ isUser, isFile, isHtml }: NewDataType): boolean {
    return this.isSingleValue && !isUser && !isFile && !isHtml;
  }
}

export class NewClientAttribute {
  id: string;
  value: any = null;
  isMandatory = false;
  valueFilled? = false;

  name?: string;

  constructor(clientAttribute?: Partial<NewClientAttribute>) {
    clientAttribute && Object.assign(this, clientAttribute);
  }
}

export class NonAttributeKeys {
  /** password label used in registration form */
  static readonly PASSWORD_ID = '6632656111156f6e64617984';
  static readonly PASSWORD_LABEL = 'Password';
  static readonly NEW_PASSWORD_LABEL = 'New Password';
  static readonly CONFIRM_PASSWORD_ID = '9032698113556f6e63194984';
  static readonly CONFIRM_PASSWORD_LABEL = 'Confirm password';

  static readonly LOGIN_ID = '9026578113556f6e68167984';
  static readonly LOGIN_LABEL = 'Login';

  static readonly RECOVER_PASSWORD_ID = '9326588113552f6e68167987';
  static readonly RECOVER_PASSWORD_LABEL = 'Email';

  static readonly CREATED_ON = 'created.on';
  /** created on label used in url filter */
  static readonly CREATED_ON_URL_FILTER = 'created-on';
  static readonly CREATED_ON_ID = '6372656174656f6e64617465';
  static readonly CREATED_ON_LABEL = 'Created On';

  static readonly UPDATED_ON = 'updated.on';
  /** updated on label used in url filter */
  static readonly UPDATED_ON_URL_FILTER = 'updated-on';
  static readonly UPDATED_ON_LABEL = 'Updated On';
  static readonly UPDATED_ON_ID = '7570646174656f6e64617465';

  static readonly CREATED_BY = 'created.by';
  /** created by label used in url filter */
  static readonly CREATED_BY_URL_FILTER = 'created-by';
  static readonly CREATED_BY_LABEL = 'Created By';
  static readonly CREATED_BY_ID = '637265617465627975736572';

  static readonly UPDATED_BY = 'updated.by';
  /** updated by label used in url filter */
  static readonly UPDATED_BY_URL_FILTER = 'updated-by';
  static readonly UPDATED_BY_LABEL = 'Updated By';
  static readonly UPDATED_BY_ID = '757064617465627975736572';

  static readonly FOLDER_PATH = 'folderData.path';
  static readonly FOLDER_PATH_LABEL = 'Folder Path';
  static readonly FOLDER_PATH_ID = '841304078065627975736572';

  static readonly FILE_ARTIFACT_DTO_PATH = 'formatData.filename';
  static readonly FILE_ARTIFACT_PATH = 'fileArtifact.file';
  static readonly FILE_ARTIFACT_LABEL = 'File';
  static readonly FILE_ARTIFACT_PATH_ID = '000000000000000000000001';

  static readonly CONTENT = 'content';
  static readonly CONTENT_LABEL = 'Content';
  static readonly CONTENT_ID = 'content_id_todo_generate';

  static readonly SECTION = 'folderData.sequence';
  static readonly SECTION_LABEL = 'Section';
  static readonly SECTION_ID = 'section_id_todo_generate';

  static readonly SECTION_ATTRIBUTE = 'sectionAttribute';
  static readonly SECTION_ATTRIBUTE_LABEL = 'Section';
  static readonly SECTION_ATTRIBUTE_ID = 'section_attribute_id_todo_generate';

  static readonly MODULE = 'moduleData.moduleId';

  static readonly IS_HEADING = 'moduleData.isHeading';
  static readonly IS_HEADING_LABEL = 'Is heading';
  static readonly IS_HEADING_ID = 'is_heading_id_todo_generate';

  static readonly ARTIFACT_TYPE = 'artifact-type';
  static readonly ARTIFACT_TYPE_LABEL = 'Artifact Type';
  static readonly ARTIFACT_TYPE_ID = 'artifactTypeId';
  static readonly ARTIFACT_TYPE_URL_FILTER = 'artifact-type';

  static readonly ID = 'id';
  static readonly CURRENT_USER_ID = 'currentUser';
  static readonly CURRENT_USER_TEAMS_ID = 'currentUserTeams';

  static isUserSpecificAttributeKey(key: string): boolean {
    return key === NonAttributeKeys.CREATED_BY || key === NonAttributeKeys.UPDATED_BY;
  }

  static isUserSpecificAttributeKeyForUrlFilter(key: string): boolean {
    return key === NonAttributeKeys.CREATED_BY_URL_FILTER || key === NonAttributeKeys.UPDATED_BY_URL_FILTER;
  }

  static isDateSpecificAttributeKey(key: string): boolean {
    return key === NonAttributeKeys.CREATED_ON || key === NonAttributeKeys.UPDATED_ON;
  }

  static isDateSpecificAttributeKeyForUrlFilter(key: string): boolean {
    return key === NonAttributeKeys.CREATED_ON_URL_FILTER || key === NonAttributeKeys.UPDATED_ON_URL_FILTER;
  }

  static isUserOrDateSpecificAttributeKey(key: string): boolean {
    return NonAttributeKeys.isUserSpecificAttributeKey(key) || NonAttributeKeys.isDateSpecificAttributeKey(key);
  }

  static isModuleSpecificAttributeKey(key: string): boolean {
    return NonAttributeKeys.isIsHeadingKey(key) || NonAttributeKeys.isSectionKey(key);
  }

  static isContentKey(key: string): boolean {
    return key === NonAttributeKeys.CONTENT;
  }

  static isIsHeadingKey(key: string): boolean {
    return key === NonAttributeKeys.IS_HEADING;
  }

  static isSectionKey(key: string): boolean {
    return key === NonAttributeKeys.SECTION;
  }

  static isDefaultNonAttributeKey(key: string): boolean {
    return (
      NonAttributeKeys.isUserSpecificAttributeKey(key) ||
      NonAttributeKeys.isDateSpecificAttributeKey(key) ||
      NonAttributeKeys.isContentKey(key) ||
      NonAttributeKeys.isIsHeadingKey(key) ||
      NonAttributeKeys.isSectionKey(key) ||
      NonAttributeKeys.isArtifactTypeKey(key)
    );
  }

  static isDateSpecificAttributeId(id: string): boolean {
    return id === NonAttributeKeys.CREATED_ON_ID || id === NonAttributeKeys.UPDATED_ON_ID;
  }

  static isUserSpecificAttributeId(id: string): boolean {
    return id === NonAttributeKeys.CREATED_BY_ID || id === NonAttributeKeys.UPDATED_BY_ID;
  }

  static isDefaultNonAttributeId(id: string): boolean {
    return NonAttributeKeys.isDateSpecificAttributeId(id) || NonAttributeKeys.isUserSpecificAttributeId(id);
  }

  static isArtifactTypeKey(key: string): boolean {
    return NonAttributeKeys.ARTIFACT_TYPE_ID === key;
  }

  static isUserSpecificAttributeKeyOrId(keyOrId: string): boolean {
    return NonAttributeKeys.isUserSpecificAttributeKey(keyOrId) || NonAttributeKeys.isUserSpecificAttributeId(keyOrId);
  }
}
