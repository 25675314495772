@let settings = condition.extra!.settings;

@if (settings.operationType | isFilterCalendarType) {
  <p-calendar [(ngModel)]="condition.destination.value" [showTime]="settings.isDateTime"></p-calendar>
} @else if (settings.operationType | isFilterNumericType) {
  <p-inputNumber [(ngModel)]="settings.offsetInDays"></p-inputNumber>
} @else if (settings.operationType | isFilterRangeType) {
  <div class="date-range-container">
    <p-calendar [(ngModel)]="settings.start" [placeholder]="'From' | translate"></p-calendar>
    <span> - </span>
    <p-calendar [(ngModel)]="settings.end" [placeholder]="'To' | translate" [minDate]="settings.start"></p-calendar>
  </div>
} @else if (settings.operationType | isFilterDiffType) {
  <app-workflow-attribute-selector
    [selectedAttributeId]="condition.destination.value"
    [attributes]="dateAttributes"
    (attributeChange)="onAttributeChange($event)"
    [editable]="true"
  ></app-workflow-attribute-selector>
  <p-dropdown
    [(ngModel)]="settings.diffOperationType"
    [options]="operationTypes"
    appendTo="body"
    styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
    class="workflow-element-dropdown"
  >
    <ng-template let-selectedOption pTemplate="selectedItem"> {{ selectedOption | translate }}</ng-template>
    <ng-template let-option pTemplate="item"> {{ option | translate }}</ng-template>
  </p-dropdown>
  <p-inputNumber [(ngModel)]="settings.diffInDays"></p-inputNumber>
}
