import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrInputMappersType, AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGeneationAvrTemplates } from '@shared/services/artifact-visual-representation/document-generation/dto/document-geneation-avr-templates.dto';

export const DocumentGenerationOutputTypes = [AvrOutputTypes.pdf, AvrOutputTypes.docx, AvrOutputTypes.pptx] as const;

export enum DocumentGenerationOutputName {
  id = 'ID',
  nameAttribute = 'NAME_ATTRIBUTE',
}
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const outputNameCheck: [AvrInputMappersType[AvrTypes.documentGeneration]['outputName']] extends [`${DocumentGenerationOutputName}`]
  ? `${DocumentGenerationOutputName}` extends AvrInputMappersType[AvrTypes.documentGeneration]['outputName']
    ? true
    : false
  : false = true;

export class DocumentGenerationAvrInputMapperDto extends BaseAvrInputMapperDto<AvrTypes.documentGeneration> {
  constructor() {
    super(
      {},
      {
        ['fileArtifactTypeId']: {
          isOptional: false,
          value: '',
          readableName: 'File Artifact type',
          description: 'Artifact type of format FILE, templates will be uploaded as',
        },
        ['everythingWithinArtifactType']: {
          isOptional: false,
          value: true,
          readableName: 'All files within selected Artifact type',
          description: 'To use all files, that fit "outputType" criterium within selected Artifact type',
        },
        ['outputTypes']: {
          isOptional: false,
          value: [AvrOutputTypes.docx],
          readableName: 'Output type',
          description: 'Select from supported output types',
        },
        ['outputName']: {
          isOptional: false,
          value: DocumentGenerationOutputName.nameAttribute,
          readableName: 'Output name',
          description: 'Select option for output document name',
        },
        ['templates']: {
          isOptional: false,
          value: [new DocumentGeneationAvrTemplates({ fileArtifactId: '' })],
          readableName: 'Template',
          description: 'Select from uploaded file artifacts, based on output type (PDF output type can mix PPTX & DOCX templates)',
        },
      },
    );
  }
}
