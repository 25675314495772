import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { LinkQueryParamActionEnum } from '@widgets/menu-widget/types/tree-types';

@Component({
  selector: 'app-query-params',
  templateUrl: './query-params.component.html',
  styleUrl: './query-params.component.scss'
})
export class QueryParamsComponent {
  @Input() action: ActionItem;
  @Input() selectedAction: ActionItem;
  @Output() actionChange = new EventEmitter<ActionItem[]>();

  linkQueryParamAction = GetSelectOptionsFromEnum(LinkQueryParamActionEnum);
  queryParamActions = LinkQueryParamActionEnum;

  onAddQueryParamClick(): void {
    if (this.selectedAction) this.selectedAction.queryParams.push({ key: '', value: '', action: LinkQueryParamActionEnum.addNew });
  }

  onDeleteParamClick(index: number): void {
    if (this.selectedAction) this.selectedAction.queryParams.splice(index, 1);
  }

  onActionChange(): void {
    this.actionChange.emit();
  }

}
