import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { TenantWorkflowService } from '@api/services/tenant-workflow.service';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { WorkflowModel } from '@private/pages/workflow-management/workflow/types/workflow.types';
import { WorkflowComponent } from '@private/pages/workflow-management/workflow/workflow.component';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { RuleDataAccessService, WorkflowTriggerFactory } from '@workflows/services';
import { WorkflowOwnerType, WorkflowRule } from '@workflows/types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkflowService extends CoreService<WorkflowComponent, WorkflowModel> {
  constructor(
    private readonly cache: NewCacheService,
    private readonly tenantWorkflowService: TenantWorkflowService,
    private readonly ruleDataAccessService: RuleDataAccessService,
    private readonly triggerFactory: WorkflowTriggerFactory,
    private readonly blockUiService: BlockUiService,
  ) {
    super();
  }

  init(context: WorkflowComponent, model: WorkflowModel) {
    super.init(context, model);
    this.registerSubscriptions();

    this.initData();
  }

  async save(): Promise<void> {
    this.blockUiService.blockUi();
    this.prepareRuleToServer();

    if (this.c.urlParams.id) this.ruleDataAccessService.updateRule$(this.m.rule).subscribe(() => this.onSaveSuccess());
    else this.ruleDataAccessService.saveRule$(this.m.rule).subscribe(() => this.onSaveSuccess());
  }

  private initData(): void {
    const ruleId = this.c.urlParams.id;

    if (ruleId) {
      this.loadWorkflowRule(ruleId);
    } else {
      this.initNewWorkflowRule();
    }

    this.m.isCreateMode = !ruleId;
  }

  private loadWorkflowRule(id: string): void {
    this.m.inProgress = true;

    setTimeout(async () => {
      try {
        const ruleDto = await lastValueFrom(this.tenantWorkflowService.workflowControllerGet({ id }));
        this.m.rule = this.ruleDataAccessService.fromDto(ruleDto);
      } finally {
        this.m.inProgress = false;
      }
    });
  }

  private initNewWorkflowRule(): void {
    const rule = new WorkflowRule();
    rule.triggers = [this.triggerFactory.getBaseWorkflowTrigger()];
    this.m.rule = rule;
  }

  private prepareRuleToServer(): void {
    if (this.m.rule.ownerType === WorkflowOwnerType.CUSTOM) {
      this.m.rule.ownerId = null;
      this.m.rule.usedIn = null;
      this.m.rule.global = false;
    }
  }

  private async onSaveSuccess(): Promise<void> {
    this.blockUiService.unblockUi();
    await this.redirectToWorkflowsList();
    await this.c.announcement.success('Saved successfully');
  }

  private async redirectToWorkflowsList(): Promise<void> {
    await this.c.router.navigateByUrl('/admin/workflow-list');
  }

  private registerSubscriptions(): void {
    const tenantData = this.cache.data;
    this.c.registerSubscriptions([
      tenantData.artifactTypes.subscribe(
        artifactTypes =>
          artifactTypes &&
          this.m.options.artifactTypes.setList(
            (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
            ID_KEY,
          ),
      ),
      tenantData.attributes.subscribe(
        attributes =>
          attributes &&
          this.m.options.attributes.setList(
            (attributes as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
            ID_KEY,
          ),
      ),
      tenantData.dataTypes.subscribe(
        dataTypes =>
          dataTypes &&
          this.m.options.dataTypes.setList(
            (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
            ID_KEY,
          ),
      ),
      tenantData.linkTypes.subscribe(
        linkTypes =>
          linkTypes &&
          this.m.options.linkTypes.setList(
            (linkTypes as LinkTypeResponseDto[]).map(dto => new LinkType(dto)),
            ID_KEY,
          ),
      ),
      tenantData.pages.subscribe(
        pages =>
          pages &&
          this.m.options.pages.setList(
            (pages as PageResponseDto[]).map(dto => new Page(dto)),
            ID_KEY,
          ),
      ),
      tenantData.applications.subscribe(applications => applications && this.m.options.applications.setList(applications as ApplicationResponseDto[], ID_KEY)),
    ]);
  }
}
