import { Component, OnInit } from '@angular/core';
import { SystemTenantResponseDto } from '@api/models/system-tenant-response-dto';
import { SystemTenantService } from '@api/services/system-tenant.service';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { DELETED_LABEL, DELETED_KEY, ID_LABEL, ID_KEY, NAME_LABEL, NAME_KEY } from '@shared/constants/constants';
import { ListReqMetaData, ListResDtoI } from '@shared/core/types/core.types';
import { NewTableColumn } from '@shared/types/table.types';
import { lastValueFrom } from 'rxjs';
import { TenantListModel } from './types/tenant-list.types';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss'],
})
export class TenantListComponent implements OnInit {
  m = new TenantListModel();
  loadDataMethod: (meta: Record<string, string>) => Promise<ListResDtoI<SystemTenantResponseDto>>;

  constructor(
    private readonly systemTenantService: SystemTenantService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Tenants' }]);
    this.loadDataMethod = ((meta?: Record<string, string>) => lastValueFrom(this.systemTenantService.systemTenantControllerList(meta))).bind(this);
    this.m.columns = [
      new NewTableColumn({ label: ID_LABEL, key: ID_KEY }),
      new NewTableColumn({ label: NAME_LABEL, key: NAME_KEY }),
      new NewTableColumn({ label: DELETED_LABEL, key: DELETED_KEY }),
    ];
  }
}
