import { WorkflowActionDto } from '@api/models';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeActionHandlerService } from '@workflows/shared';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import { WorkflowActionType, WorkflowTriggerEvent } from '..';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export interface WorkflowActionCopyLinkValueFromLinkDto extends WorkflowActionDto {
  linkArtifactTypeId: string;
  sourceLinkTypeId: string;
  targetLinkTypeId: string;
  targetLinkArtifactTypeId: string;
}

export class WorkflowActionCopyLinkValueFromLink
  extends AbstractWorkflowAttributeBasedAction<CommonFeAction>
  implements WorkflowActionCopyLinkValueFromLinkDto
{
  linkArtifactTypeId: string;
  sourceLinkTypeId: string;
  targetLinkTypeId: string;
  targetLinkArtifactTypeId: string;

  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super({ actionSettingDto: CommonFeAction, conditionConverter, type: WorkflowActionType.COPY_LINK_FROM_LINK, dto, actionAttributeHandler });
    this.linkArtifactTypeId = (dto as WorkflowActionCopyLinkValueFromLinkDto)?.linkArtifactTypeId;
    this.sourceLinkTypeId = (dto as WorkflowActionCopyLinkValueFromLinkDto)?.sourceLinkTypeId;
    this.targetLinkTypeId = (dto as WorkflowActionCopyLinkValueFromLinkDto)?.targetLinkTypeId;
    this.targetLinkArtifactTypeId = (dto as WorkflowActionCopyLinkValueFromLinkDto)?.targetLinkArtifactTypeId;
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifact;
  }

  execute(triggerEvent?: WorkflowTriggerEvent): void {
    const linkedArtifact = triggerEvent?.payload?.artifact as NewArtifact;
    this.actionAttributeHandler.notifySetLinkValueEvent({
      defaultArtifactTypeId: this.artifactTypeId,
      linkedArtifact: linkedArtifact,
      linkArtifactTypeId: this.linkArtifactTypeId,
      sourceLinkTypeId: this.sourceLinkTypeId,
      targetLinkTypeId: this.targetLinkTypeId,
      targetLinkArtifactTypeId: this.targetLinkArtifactTypeId,
    });
    return;
  }

  isValid(): boolean {
    return (
      super.isValid() && !!(this.artifactTypeId && this.linkArtifactTypeId && this.sourceLinkTypeId && this.targetLinkTypeId && this.targetLinkArtifactTypeId)
    );
  }

  toServer(): WorkflowActionCopyLinkValueFromLinkDto {
    return {
      ...super.toServer(),
      linkArtifactTypeId: this.linkArtifactTypeId,
      sourceLinkTypeId: this.sourceLinkTypeId,
      targetLinkTypeId: this.targetLinkTypeId,
      targetLinkArtifactTypeId: this.targetLinkArtifactTypeId,
    };
  }
}
