import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { GradientGeneratorPresetModel } from '@shared/components/gradient-generator/types/gradient-generator-preset.model';
import { Button } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { AnnouncementService } from '../../services/announcement.service';
import { GradientGeneratorColor } from './types/gradient-generator-color.model';

@Component({
  selector: 'app-gradient-generator',
  standalone: true,
  imports: [
    CardModule,
    DropdownModule,
    FormsModule,
    SliderModule,
    ColorPickerModule,
    InputNumberModule,
    Button,
    TranslateModule,
    InputTextareaModule,
    RadioButtonModule,
  ],
  templateUrl: './gradient-generator.component.html',
  styleUrl: './gradient-generator.component.scss',
})
export class GradientGeneratorComponent {
  @Output() onChange = new EventEmitter();
  gradientType: string = 'linear-gradient';
  angle: number = 90;
  colors: GradientGeneratorColor[] = [];
  gradientPresets: GradientGeneratorPresetModel[] = [
    new GradientGeneratorPresetModel({ name: 'Sunset', css: 'linear-gradient(90deg, #ff7e5f 0%, #feb47b 100%)' }),
    new GradientGeneratorPresetModel({ name: 'Cool Blues', css: 'linear-gradient(90deg, #2193b0 0%, #6dd5ed 100%)' }),
    new GradientGeneratorPresetModel({ name: 'Mild Purple', css: 'linear-gradient(90deg, #833ab4 0%, #fd1d1d 50%, #fcb045 100%)' }),
  ];
  private _styles: Partial<StylesDto> = {};
  private readonly announcement = inject(AnnouncementService);

  @Input() set styles(dto: Partial<StylesDto>) {
    this._styles = dto;
    dto.backgroundImage && this.applyPreset({ name: '', css: dto.backgroundImage });
  }

  get gradientCss(): string {
    const colorStops = this.colors.map(c => `${c.color} ${c.position}%`).join(', ');
    const value = this.gradientType === 'linear-gradient' ? `linear-gradient(${this.angle}deg, ${colorStops})` : `radial-gradient(circle, ${colorStops})`;
    this._styles.backgroundImage = value;
    return value;
  }

  addColor(): void {
    this.colors.push(new GradientGeneratorColor({ color: '#ffffff', position: 50 }));
    this.onChange.emit();
  }

  removeColor(index: number): void {
    this.colors.splice(index, 1);
    this.onChange.emit();
  }

  applyPreset(preset: { name: string; css: string }): void {
    const [type, stops] = preset.css.match(/(linear-gradient|radial-gradient)\((.*)\)/)!.slice(1);

    this.gradientType = type;

    if (this.gradientType === 'linear-gradient') {
      const [angle, ...stopColors] = stops.split(',');

      this.angle = parseFloat(angle.replace('deg', '').trim());
      this.colors = stopColors.map(stop => {
        const [color, position] = stop.trim().split(' ');
        return new GradientGeneratorColor({ color, position: parseFloat(position.replace('%', '')) });
      });
      this.onChange.emit();
      return;
    }

    this.colors = stops.split(',').map(stop => {
      const [color, position] = stop.trim().split(' ');
      return new GradientGeneratorColor({ color, position: parseFloat(position.replace('%', '')) });
    });
    this.onChange.emit();
  }

  copyCss(): void {
    navigator.clipboard.writeText(this.gradientCss).then(() => this.announcement.success('CSS gradient successfully copied to clipboard'));
  }
}
