import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListWidgetTableChangesDetectorService {

  private rowsSubject = new BehaviorSubject<string | undefined>(undefined);
  private rowsOddSubject = new BehaviorSubject<string | undefined>(undefined);
  private activeSubject = new BehaviorSubject<string | undefined>(undefined);
  private multiselectSubject = new BehaviorSubject<boolean | undefined>(undefined);

  rows$ = this.rowsSubject.asObservable();
  rowsOdd$ = this.rowsOddSubject.asObservable();
  active$ = this.activeSubject.asObservable();

  backgroundChanges$ = combineLatest([
    this.rows$,
    this.rowsOdd$,
    this.active$
  ]).pipe(distinctUntilChanged());

  multiselectChange$ = this.multiselectSubject.asObservable()

  updateBackground(newBackground: Partial<{ rows: string; rowsOdd: string; active: string }>) {
    if (newBackground.rows !== undefined) {
      this.rowsSubject.next(newBackground.rows);
    }
    if (newBackground.rowsOdd !== undefined) {
      this.rowsOddSubject.next(newBackground.rowsOdd);
    }
    if (newBackground.active !== undefined) {
      this.activeSubject.next(newBackground.active);
    }
  }

  updateMultiselect(newMultiselect: boolean) {
    if (newMultiselect !== undefined) {
      this.multiselectSubject.next(newMultiselect);
    }
  }

}
