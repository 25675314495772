import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, convertToParamMap } from '@angular/router';
import { ArtifactFormatFileDataResponseDto, ArtifactResponseDto, DocumentGenerationAvrInputMapperDto } from '@api/models';
import { TenantArtifactService, TenantArtifactTypeService } from '@api/services';
import { URL_KEY_VALUE_TEMPLATE_ID } from '@shared/constants/constants';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGenerationAvrService } from '@shared/services/artifact-visual-representation/document-generation/document-generation.avr.service';
import {
  DocumentGenerationOutputName,
  DocumentGenerationOutputTypes,
} from '@shared/services/artifact-visual-representation/document-generation/dto/document-generation.input-mapper.dto';

import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import {
  AvrTemplateFileArtifactSources,
  DocumentGenerationAvrQueryDto,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/document-generation/types/document-generation.avr-types-settings.types';
import { AbstractAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/abstract.avr-types-services.service';
import { AvrWidgetService } from '@widgets/avr-widget/services/avr-widget.service';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';
import { Observable, Subject, concatMap, debounceTime, firstValueFrom, lastValueFrom, map, merge, of, switchMap, takeUntil, tap } from 'rxjs';

@Injectable()
export class DocumentGenerationAvrTypeService extends AbstractAvrTypeService<AvrTypes.documentGeneration> implements OnDestroy {
  templateFileArtifactType: NewArtifactType | null = null;
  selectedTemplateArtifact: NewArtifact | null = null;

  public readonly stopListenToUrl: Subject<void> = new Subject<void>();
  public readonly listeningKeyChange: Subject<void> = new Subject<void>();
  private readonly manualListenToUrlTrigger: Subject<ParamMap> = new Subject<ParamMap>();

  constructor(
    private readonly artifactTypeService: TenantArtifactTypeService,
    private readonly artifactService: TenantArtifactService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super(AvrTypes.documentGeneration);
    this.listeningKeyChange.pipe(debounceTime(750)).subscribe(() => {
      this.manualTrigerUrlListenToUrl();
    });
  }

  ngOnDestroy(): void {
    this.stopListenToUrl.next();
    this.stopListenToUrl.complete();
    this.manualListenToUrlTrigger.complete();
  }

  updateSelectedArtifact(): void {
    this.s.setCurrentArtifact(this.m.currentArtifact);
  }

  getAdditionalQueryParams(): DocumentGenerationAvrQueryDto | null {
    if (this.selectedTemplateArtifact === null) {
      this.m.avrBlobError = 'Template artifact is not accessible';
      return null;
    }
    if (this.m.currentAvrFileType === null) {
      this.m.avrBlobError = 'Output type is not selected';
      return null;
    }
    return new DocumentGenerationAvrQueryDto({
      outputType: this.m.currentAvrFileType as Extract<AvrOutputTypes, `${(typeof DocumentGenerationOutputTypes)[number]}`>,
      templateFileArtifactId: this.selectedTemplateArtifact.id,
    });
  }

  async initTemplateArtifactSource(): Promise<void> {
    this.stopListenToUrl.next();
    const settings = this.m.settings.response.avrTypeSettings[AvrTypes.documentGeneration];
    switch (settings?.templateFileArtifactSource) {
      case AvrTemplateFileArtifactSources.staticArtifact:
        await lastValueFrom(this.setTemplateArtifact$(settings.templateFileArtifactId));
        this.updateSelectedArtifact();
        break;
      case AvrTemplateFileArtifactSources.dynamicArtifact:
        !settings.templateFileArtifactListeningKey && (settings.templateFileArtifactListeningKey = URL_KEY_VALUE_TEMPLATE_ID);
        this.subscribeOnTemplateArtifactIdChanges();
        break;
      default:
        return;
    }
  }

  init$(m: AvrWidgetModel, s: AvrWidgetService): Observable<boolean> {
    return super.init$(m, s).pipe(
      switchMap(() => {
        if (this.m.currentArtifactTypeOption?.value.avrMapper[AvrTypes.documentGeneration]) {
          const templateFileArtifactTypeId = (
            this.m.currentArtifactTypeOption.value.avrMapper[AvrTypes.documentGeneration] as DocumentGenerationAvrInputMapperDto
          ).fileArtifactTypeId;
          return this.artifactTypeService
            .artifactTypeControllerGet({
              id: templateFileArtifactTypeId,
            })
            .pipe(
              tap(async artifactTypeResponse => {
                this.templateFileArtifactType = new NewArtifactType(artifactTypeResponse);
                await this.initTemplateArtifactSource();
              }),
              map(() => true),
            );
        }
        return of(true);
      }),
    );
  }

  setTemplateArtifact$(artifactId: string | null): Observable<boolean> {
    return of(artifactId).pipe(
      switchMap(templateFileArtifactId => {
        const avrMapper = this.m.currentArtifactTypeOption?.value.avrMapper[AvrTypes.documentGeneration] as DocumentGenerationAvrInputMapperDto;

        if (templateFileArtifactId === null) return of(null);
        const templateFileArtifactIds = avrMapper.templates.map(template => template.fileArtifactId);

        if (!templateFileArtifactIds.includes(templateFileArtifactId) && avrMapper.everythingWithinArtifactType === false) return of(null);
        return this.artifactService.artifactControllerList({
          body: {
            filter: JSON.stringify({
              _id: { $oid: templateFileArtifactId },
              format: ArtifactTypeFormatEnum.file,
              deleted: null,
            }),
            limit: 1,
          },
        });
      }),
      tap(artifactListResponse => {
        if (!artifactListResponse?.meta.totalCount) {
          this.selectedTemplateArtifact = null;
          return;
        }
        const artifact = artifactListResponse!.data[0]!;
        const avrMapper = this.m.currentArtifactTypeOption?.value.avrMapper[AvrTypes.documentGeneration] as DocumentGenerationAvrInputMapperDto;

        if (avrMapper.everythingWithinArtifactType === true) {
          const mimetypes: string[] = DocumentGenerationAvrService.mimetypeIntersection(avrMapper.outputTypes as AvrOutputTypes[]);

          if (!mimetypes.includes((artifact.formatData as ArtifactFormatFileDataResponseDto).mimetype)) {
            this.selectedTemplateArtifact = null;
            return;
          }
        }
        this.selectedTemplateArtifact = this.artifactResponseTransform(artifact, this.templateFileArtifactType!);
      }),
      map(() => true),
    );
  }

  artifactResponseTransform(artifact: ArtifactResponseDto, artifactType: NewArtifactType): NewArtifact {
    return new NewArtifact({
      dto: artifact,
      artifactTypesMap: {
        [artifactType.id]: artifactType,
      },
    });
  }

  private async manualTrigerUrlListenToUrl(): Promise<void> {
    this.manualListenToUrlTrigger.next(convertToParamMap(await firstValueFrom(this.activatedRoute.queryParams)));
  }

  private subscribeOnTemplateArtifactIdChanges(): void {
    merge(this.activatedRoute.queryParamMap, this.manualListenToUrlTrigger)
      .pipe(
        map((paramMap: ParamMap) => {
          return paramMap.get(this.m.settings.response.avrTypeSettings[AvrTypes.documentGeneration]?.templateFileArtifactListeningKey || '') || null;
        }),
        concatMap(async (artifactId: string | null) => {
          await lastValueFrom(this.setTemplateArtifact$(artifactId));
          this.updateSelectedArtifact();
        }),
        takeUntil(this.stopListenToUrl),
      )
      .subscribe();
  }
}
