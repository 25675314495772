import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { DisplayAtUtilService } from '@shared/components/common-display-at';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { IconsService } from '@shared/services/icons.service';
import { NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DEFAULT_VARIANT_KEY, DisplayAtDropdownItem, DisplayAttributeType } from '@shared/types/display-at-types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { IsSingleFileAttributePipe } from '@widgets/card-widget/pipes/is-single-file-attribute.pipe';
import { CardWidgetEventsService } from '@widgets/card-widget/services/card-widget-events.service';
import { AttributeSettings } from '@widgets/card-widget/types/attribute-settings';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import { CardWidgetAreaContentItem } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetEventType } from '@widgets/card-widget/types/card-widget-event-type';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { BackgroundTypeEnum, TextHorizontalAlignEnum } from '@widgets/shared/types/style.types';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { IsMultipleFileAttributePipe } from '@widgets/card-widget/pipes/is-multi-file-attribute.pipe';
import {
  ApplicationSwitcherService
} from '@shared/components/application-switcher/services/application-switcher.service';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';

export const INITIAL_ATTRIBUTE_SETTINGS: AttributeSettings = {
  settings: new AttributeFormatSettings(),
  enumAttributeSettings: {},
};

INITIAL_ATTRIBUTE_SETTINGS.settings.editable = false;

Object.assign(INITIAL_ATTRIBUTE_SETTINGS.settings.value.styles, {
  backgroundType: BackgroundTypeEnum.color,
  color: '',
  fontSize: '',
  textAlign: TextHorizontalAlignEnum.left,
});

export enum TextDisplayOption {
  wrap = 'wrap',
  ellipsis = 'ellipsis',
  scroll = 'scroll',
}

@Component({
  selector: 'app-card-area-styler',
  templateUrl: './card-area-styler.component.html',
  styleUrls: ['./card-area-styler.component.scss'],
  providers: [IsSingleFileAttributePipe, IsMultipleFileAttributePipe],
})
export class CardAreaStylerComponent implements OnInit, OnDestroy {
  @Input() options: CardWidgetOptions;
  @Input() areas: GenericArea<CardWidgetAreaContent>[];
  @Input() attributeSettings: { [attributeId: string]: AttributeSettings };

  iconClassnameOptions: SelectOption<string, string>[] = [];
  usedAttributeOptions: SelectOption<string, NewClientAttribute | LinkType, undefined | LinkDirection>[] = [];

  selectedArea: GenericArea<CardWidgetAreaContent> | null = null;

  selectedAttribute: NewClientAttribute | LinkType | null = null;
  selectedAttributeSettings: AttributeSettings | null = null;
  dataType: NewDataType | null = null;
  attributeDisplayVariantOptions: SelectOption<string, string>[] = [];

  applicationId: string;

  tabState = {
    attributeStyles: false,
  };

  private iconsSubscription: Subscription;
  private cardWidgetEventSubscription: Subscription;

  constructor(
    iconsService: IconsService,
    private readonly cardWidgetEventsService: CardWidgetEventsService,
    private readonly displayAtUtilService: DisplayAtUtilService,
    private readonly isSingleFileAttributePipe: IsSingleFileAttributePipe,
    private readonly isMultipleFileAttributePipe: IsMultipleFileAttributePipe,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    this.iconsSubscription = iconsService.icons$.subscribe((iconOptions: SelectOption<string, string>[]) => (this.iconClassnameOptions = iconOptions));
  }

  get textDisplay(): typeof TextDisplayOption {
    return TextDisplayOption;
  }

  ngOnInit(): void {
    this.cardWidgetEventSubscription = this.cardWidgetEventsService.subscribe<CardWidgetAreaContentItem>(
      CardWidgetEventType.openAttributeSettings,
      (contentItem: CardWidgetAreaContentItem) => this.onAttributeSettingsOpen(contentItem),
    );
    this.applicationId = this.applicationSwitcherService.selectedApplication?.id || '';
  }

  ngOnDestroy(): void {
    this.iconsSubscription.unsubscribe();
    this.cardWidgetEventSubscription.unsubscribe();
  }

  reset(): void {
    this.selectedArea = null;
    this.selectedAttribute = null;
    this.selectedAttributeSettings = null;
    this.dataType = null;
    this.usedAttributeOptions = this.getUsedAttributeOptions();
  }

  onAttributeChange(): void {
    this.setExistingOrInitialAttributeStyles();
    this.setDataTypeIfAttributeSelected();
    this.setDisplayVariantsAndMeta();
  }

  onDisplayVariantChange(): void {
    this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.updateDisplayVariant, null);
  }

  onWrapClick(): void {
    this.selectedArea?.content.styles &&
      Object.assign(this.selectedArea?.content.styles, {
        whiteSpace: 'normal',
        overflow: 'visible',
        textOverflow: 'clip',
      });
  }

  onEllipsisClick(): void {
    this.selectedArea?.content.styles &&
      Object.assign(this.selectedArea?.content.styles, {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      });
  }

  onScrollClick(): void {
    this.selectedArea?.content.styles &&
      Object.assign(this.selectedArea?.content.styles, {
        whiteSpace: 'normal',
        overflow: 'auto',
        textOverflow: 'clip',
      });
  }

  private onAttributeSettingsOpen(contentItem: CardWidgetAreaContentItem): void {
    this.usedAttributeOptions = this.getUsedAttributeOptions();

    this.selectedAttribute = this.usedAttributeOptions.find(({ value }: SelectOption<string, NewClientAttribute | LinkType, undefined | LinkDirection>) => {
      return value.id === contentItem.content;
    })!.value;

    this.onAttributeChange();

    this.tabState.attributeStyles = true;
  }

  private setExistingOrInitialAttributeStyles(): void {
    const { id: attributeId } = this.selectedAttribute!;

    if (attributeId in this.attributeSettings) {
      this.selectedAttributeSettings = this.attributeSettings[attributeId];
    } else {
      const attributeStyles = cloneDeep(INITIAL_ATTRIBUTE_SETTINGS);

      this.attributeSettings[attributeId] = attributeStyles;
      this.selectedAttributeSettings = attributeStyles;
    }
  }

  private setDataTypeIfAttributeSelected(): void {
    if (!this.selectedAttribute) {
      return;
    }

    const attribute = this.options.attributes.listMap[this.selectedAttribute.id];
    this.dataType = attribute ? this.options.dataTypes.listMap[attribute.dataTypeId] : null;

    if(!this.dataType && (this.selectedAttribute.id === NonAttributeKeys.FILE_ARTIFACT_PATH_ID))
      this.dataType = new NewDataType({ baseDataType: 'FILE' } as DataTypeResponseDto);
  }

  private getUsedAttributeOptions(): SelectOption<string, NewClientAttribute | LinkType, undefined | LinkDirection>[] {
    return this.options.clientAttributeOptions.filter(
      ({ value: attribute, meta: attributeLinkDirection }: SelectOption<string, NewClientAttribute | LinkType, undefined | LinkDirection>) => {
        return this.areas.some(({ content }: GenericArea<CardWidgetAreaContent>) => {
          return content.items.some((contentItem: CardWidgetAreaContentItem) => {
            return contentItem.content === attribute.id && contentItem.linkDirection == attributeLinkDirection;
          });
        });
      },
    );
  }

  private setDisplayVariantsAndMeta(): void {
    const isLink = this.selectedAttribute instanceof LinkType;

    if (
      !this.selectedAttribute ||
      !(
        this.dataType ||
        isLink ||
        NonAttributeKeys.isUserSpecificAttributeId(this.selectedAttribute.id) ||
        NonAttributeKeys.isDateSpecificAttributeId(this.selectedAttribute.id)
      )
    ) {
      this.attributeDisplayVariantOptions = [];
      return;
    }

    const isUser = this.dataType?.isUser;
    const isHyperlink = this.dataType?.isHyperlink;
    const isSystemUser = NonAttributeKeys.isUserSpecificAttributeId(this.selectedAttribute.id);
    const isSystemDate = NonAttributeKeys.isDateSpecificAttributeId(this.selectedAttribute.id);
    const isDateTime = this.dataType?.isDateTime;
    const isDate = this.dataType?.isDate;
    const isEnum = this.dataType?.isEnum;
    const isBoolean = this.dataType?.isBoolean;
    const isSystemFile = this.dataType?.isFile;

    const isSingleFileAttribute = this.isSingleFileAttributePipe.transform(this.selectedAttribute?.id || '', this.options);
    const isMultipleFileAttribute = this.isMultipleFileAttributePipe.transform(this.selectedAttribute?.id || '', this.options);

    if (!isLink && !isDateTime && !isDate && !isUser && !isSystemUser && !isSingleFileAttribute && !isMultipleFileAttribute && !isHyperlink && !isSystemDate && !isEnum && !isBoolean && !isSystemFile) {
      this.attributeDisplayVariantOptions = [];
      return;
    }

    const attribute = this.options.attributes.listMap[this.selectedAttribute.id];
    let attributeType;

    if (isSystemUser) attributeType = DisplayAttributeType.user;
    else if (isSystemDate) attributeType = DisplayAttributeType.systemDate;
    else if (isLink) attributeType = DisplayAttributeType.link;
    else attributeType = this.displayAtUtilService.fromAttributeAndDataType(attribute, this.dataType!);

    const displayAttributeTypeEnumObject = this.displayAtUtilService.getDisplayAtEnumObjectForType(attributeType);

    this.attributeDisplayVariantOptions = this.displayAtUtilService
      .getDisplayAtDropdownItems(displayAttributeTypeEnumObject)
      .filter(({ code }: DisplayAtDropdownItem) => code !== 'DROPDOWN' && code !== 'CUSTOM')
      .map(({ label, code }: DisplayAtDropdownItem) => new SelectOption<string, string>(label, code));

    this.attributeSettings[this.selectedAttribute.id].settings.value.displayMetadata ??= {
      attributeType,
      selectedVariantCode: this.attributeSettings[this.selectedAttribute.id].settings.value.displayMetadata?.selectedVariantCode || DEFAULT_VARIANT_KEY,
    };
  }

  protected readonly JSON = JSON;
}
