@let formattingRule = rule();

<div class="main-rules-container full-height-container">
  <div class="rule-list">
    <div class="add-rule-element clickable" [class.active]="formattingRule !== undefined && isRuleNew()"
         (click)="addNewFormattingRule()">
      <div>
        <i class="bi bi-plus-circle"></i>
        {{ 'Add' | translate }}
      </div>
    </div>

    <div class="rule-list-container">
      <div class="rules-title-header">
        <div>
          <span> Name </span>
        </div>
      </div>
      @if (rules?.length) {
        @for (rule of rules; track rule.id) {
          <div class="clickable single-rule-container single-rule" [class.selected]="formattingRule?.id === rule.id"
               (click)="selectFormattingRule(rule)">
            <span [class.inactive]="!rule.active">
              <div>{{ rule.name }}</div>
            </span>
            <span class="priority-rule-mark"> {{ rule.priority }} </span>
          </div>
        }
      }

      @if (!rules?.length && !formattingRule) {
        <div class="no-rules-placeholder">{{ 'WORKFLOWS.COMMON.NO_FORMATTING_RULES' | translate }}</div>
      }

      @if (formattingRule !== undefined && isRuleNew()) {
        <div class="clickable selected single-rule-container single-rule">
          <span>
            <i class="bi bi-plus-info"></i>
            @if (formattingRule?.name) {
              {{ formattingRule.name }}
            } @else {
              [ <span class="new-rule-placeholder"> {{ 'WORKFLOWS.COMMON.ADDING_NEW_RULE' | translate }} . . . </span> ]
            }
          </span>
        </div>
      }
    </div>
  </div>

  @if (formattingRule) {
    <div class="rule-detail-container">
      <div class="rule-detail">
        <div class="name-and-priority-container">
          <div class="col-5 pl-0">
            <p-floatLabel>
              <input pInputText id="rule-name" type="text" class="p-inputtext p-component w-100"
                     [(ngModel)]="formattingRule.name" />
              <label for="rule-name">{{ 'Name' | translate }}</label>
            </p-floatLabel>
          </div>
          <div class="col-2">
            <p-floatLabel>
              <p-inputNumber
                id="rule-priority"
                mode="decimal"
                class="rule-priority-input"
                [maxFractionDigits]="5"
                [useGrouping]="false"
                [(ngModel)]="formattingRule.priority"
              ></p-inputNumber>
              <label for="rule-priority">{{ 'Priority' | translate }}</label>
            </p-floatLabel>
          </div>
          <div class="col-3">
            <p-floatLabel>
              <p-dropdown
                appendTo="body"
                id="action-on-condition-not-met"
                [(ngModel)]="actionOnConditionsNotMet"
                [options]="ActionOnConditionsNotMetOptions"
                (onChange)="onActionWhenConditionsNotMetChange($event.value)"
              >
                <ng-template let-option pTemplate="item">{{ option | translate }}</ng-template>
                <ng-template let-option pTemplate="selectedItem">{{ option | translate }}</ng-template>
              </p-dropdown>
              <label for="action-on-condition-not-met" translate> Action when no conditions are met </label>
            </p-floatLabel>
          </div>
          <div class="col-2 centered">
            <p-checkbox id="rule-is-active" [(ngModel)]="formattingRule.active" [binary]="true"></p-checkbox>
            <label for="rule-is-active" class="ml-2" translate>Is Active</label>
          </div>
        </div>

        @let formattingActions = formattingRule.actions | getFormatActions;

        <div class="condition-and-action-container">
          @for (action of formattingActions; let index = $index; track index) {
            <div class="condition-and-action-row">
              <div>
                <h6 class="pl-4 pt-4">{{ 'When' | translate }}</h6>
                @if (possibleDefaultArtifactTypes().length > 1) {
                  <p-dropdown
                    class="workflow-element-dropdown"
                    styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
                    appendTo="body"
                    placeholder="{{ 'WORKFLOWS.PLACEHOLDER.DEFAULT_ARTIFACT_TYPE' | translate }}"
                    [filter]="true"
                    filterBy="name"
                    optionValue="id"
                    [options]="possibleDefaultArtifactTypes()"
                    [(ngModel)]="formattingRule.defaultArtifactTypeId"
                    (onChange)="onDefaultArtifactTypeChange($event.value)"
                  >
                    <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
                    <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
                  </p-dropdown>
                }
                @if (defaultArtifactType() && action.condition) {
                  <app-workflows-rule-condition
                    [defaultArtifactType]="defaultArtifactType()"
                    [group]="action.condition"
                    [artifactTypes]="artifactTypes.list"
                    [attributes]="attributes.list"
                    [allAttributes]="attributes"
                    [dataTypes]="dataTypes"
                    [triggers]="formattingRule.triggers"
                    [workflowType]="formattingRule.type"
                    [depth]="1"
                    [allowOnlyNonManualConditions]="true"
                  ></app-workflows-rule-condition>
                }

                <div class="format-style-container">
                  <h6 class="m-0">{{ 'Then' | translate }}</h6>
                  <div class="format-style-block">
                    <span> {{ 'Background color' | translate }}</span>
                    <app-color-picker
                      [(color)]="action.actionSettings.styles.value.backgroundColor"
                      [placeholder]="'Not set' | translate"
                      [appendToBody]="true"
                    ></app-color-picker>
                  </div>
                  <div class="format-style-block">
                    <span> {{ 'Text color' | translate }}</span>
                    <app-color-picker
                      [(color)]="action.actionSettings.styles.value.color"
                      [placeholder]="'Not set' | translate"
                      [appendToBody]="true"
                    ></app-color-picker>
                  </div>
                </div>
              </div>
            </div>

            <div class="action-buttons-container">
              <div class="action-button-move-up">
                @if (index > 0) {
                  <button pButton
                          class="p-button p-button-info p-button-text pi pi-arrow-up"
                          [pTooltip]="'Move up' | translate"
                          (click)="moveActionUp(index)"
                  ></button>
                }
              </div>
              <div class="action-buttons-add-remove">
                <button pButton label="{{ 'Add' | translate }}" class="p-button p-button-info p-button-text"
                        (click)="addAction(index)"></button>
                @if (index > 0) {
                  <button pButton label="{{ 'Delete' | translate }}" class="p-button p-button-danger p-button-text"
                          (click)="removeAction(index)"></button>
                }
              </div>
              <div class="action-button-move-down">
                @if (index < formattingActions.length - 1) {
                  <button pButton
                          class="p-button p-button-info p-button-text pi pi-arrow-down"
                          [pTooltip]="'Move down' | translate"
                          (click)="moveActionDown(index)"
                  ></button>
                }
              </div>
            </div>
          }
        </div>
      </div>

      <div class="rule-detail-actions-container">
        <div class="rule-detail-actions">
          <button
            pButton
            label="{{ (isRuleNew() ? 'Save' : 'Update') | translate }}"
            class="p-button p-button-success workflows-action-btn"
            (click)="doSaveRule(formattingRule)"
          ></button>
          @if (!isRuleNew()) {
            <button
              pButton
              label="{{ 'Delete' | translate }}"
              class="p-button p-button-danger workflows-action-btn"
              (click)="doDeleteRule(formattingRule)"
            ></button>
          }
        </div>
      </div>
    </div>
  }
</div>
