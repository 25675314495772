import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { TranslateService } from '@ngx-translate/core';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { IsBoolean, IsDateTime, IsEnumerated } from '@shared/methods/data-type.methods';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { DateDiffsEnum, DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { ListContainer } from '@shared/types/list-container.types';
import { AbstractWorkflowRuleItemComponent } from '@workflows/components/rule/abstract';
import { GetConditionOperationTypesPipe } from '@workflows/components/rule/condition/pipes/get-condition-operation-types.pipe';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import {
  DYNAMIC_ATTRIBUTE_PREFIX,
  DYNAMIC_ATTRIBUTE_SUFFIX,
  RuleConditionOperatorType,
  WorkflowConditionType,
  WorkflowTrigger,
  WorkflowType,
} from '@workflows/types';
import {
  ConditionDateFilterType,
  NumericComparisonOperationEnum,
  RuleCondition,
  RuleConditionDateSettings,
  RuleConditionExtra,
  RuleConditionValue,
} from '@workflows/types/conditions/rule-condition';
import { RuleConditionGroup } from '@workflows/types/conditions/rule-condition-group';
import { take } from 'rxjs';

@Component({
  selector: 'app-workflows-rule-condition',
  templateUrl: './workflow-rule-condition.component.html',
  styleUrls: ['./workflow-rule-condition.component.scss'],
})
export class WorkflowRuleConditionComponent extends AbstractWorkflowRuleItemComponent implements OnInit, AfterViewInit {
  @Input() group: RuleConditionGroup;
  @Input() depth: number;
  @Input() allAttributes: ListContainer<NewAttribute> = new ListContainer();
  @Input() triggers: WorkflowTrigger[];
  @Input() workflowType?: WorkflowType;
  @Input() allowOnlyManualConditions?: boolean;
  @Input() allowOnlyNonManualConditions?: boolean;

  @ViewChildren('conditionGroup') conditionGroups: QueryList<WorkflowRuleConditionComponent>;

  operatorTypes: RuleConditionOperatorType[];
  selectedAttributes: NewAttribute[] = [];
  protected readonly WorkflowType = WorkflowType;
  protected readonly conditionDateFilterTypes: ConditionDateFilterType[] = [];

  constructor(
    private readonly cache: NewCacheService,
    protected translateService: TranslateService,
    private readonly conditionConverter: RuleConditionValueConverterService,
  ) {
    super(translateService);
    this.operatorTypes = Object.values(RuleConditionOperatorType);
    this.conditionDateFilterTypes = [
      ...Object.values(DateFilterEnum),
      ...Object.values(DateDiffsEnum),
      ...Object.values(DateRangeFilterEnum).filter(range => range !== DateRangeFilterEnum.custom),
    ];
  }

  ngOnInit(): void {
    this.updateAvailableAttributes();
  }

  ngAfterViewInit(): void {
    this.initializeManualSources(this.group);
  }

  onAddCondition(): void {
    this.group.conditions.push(RuleConditionGroup.defaultCondition());
  }

  onAddGroup(): void {
    this.group.groups ??= [];
    this.group.groups.push(RuleConditionGroup.defaultGroup(this.conditionConverter));
  }

  onRemoveGroup(index: number): void {
    if (this.group.groups) {
      this.group.groups.splice(index, 1);
    }
  }

  onRemoveCondition(index: number): void {
    this.group.conditions.splice(index, 1);
  }

  onIsConditionManuallySetChange(condition: RuleCondition): void {
    condition.source.manual = !condition.source.manual;

    const attributeId = this.getAttributeIdFromCondition(condition);
    !condition.source.manual && this.onManualSetFalse(condition.source, condition.destination, attributeId);
    condition.source.manual && this.onManualSetTrue(condition.source, condition.destination, attributeId);
    condition.destination.value = this.getConditionDestinationDefaultValue(attributeId);
  }

  onManualSetFalse(source: RuleConditionValue, destination: RuleConditionValue, attributeId: string): void {
    if (attributeId && this.defaultArtifactType.attributes[attributeId]) {
      source.value = attributeId;
    } else {
      source.value = '';
    }
    destination.value = this.getConditionDestinationDefaultValue(attributeId || '');
    destination.isDynamic = false;
  }

  onManualSetTrue(source: RuleConditionValue, destination: RuleConditionValue, attributeId: string): void {
    if (attributeId && this.defaultArtifactType.attributes[attributeId]) {
      source.value = `${DYNAMIC_ATTRIBUTE_PREFIX}${source.value}${DYNAMIC_ATTRIBUTE_SUFFIX}`;
    } else {
      source.value = '';
    }
    destination.isDynamic = true;
  }

  onAttributeChange(attributeId: string, condition: RuleCondition): void {
    condition.source.value = attributeId;
    condition.destination.value = this.getConditionDestinationDefaultValue(attributeId);
    condition.operationType = new GetConditionOperationTypesPipe().transform(
      condition.source.value,
      this.selectedAttributes,
      this.dataTypes,
      condition.source.manual,
    )[0];
    condition.extra =
      condition.operationType === WorkflowConditionType.DATES
        ? new RuleConditionExtra({
            settings: new RuleConditionDateSettings({
              isDateTime: this.isConditionDateTime(attributeId),
              diffOperationType: NumericComparisonOperationEnum.EQUALS,
            }),
          })
        : undefined;
  }

  onDateFilterChange(value: ConditionDateFilterType, condition: RuleCondition): void {
    const attributeId = this.getAttributeIdFromCondition(condition);
    condition.destination.value = this.getConditionDestinationDefaultValue(attributeId);
    condition.extra = new RuleConditionExtra({
      settings: new RuleConditionDateSettings({
        operationType: value,
        isDateTime: this.isConditionDateTime(condition.source.value),
        diffOperationType: NumericComparisonOperationEnum.EQUALS,
      }),
    });
  }

  onIsConditionDestinationDynamicChange(condition: RuleCondition): void {
    const attributeId = this.getAttributeIdFromCondition(condition);
    condition.destination.value = this.getConditionDestinationDefaultValue(attributeId);
  }

  updateAvailableAttributes(): void {
    if (!this.defaultArtifactType) {
      return;
    }

    const linkedArtifactTypeIds = this.triggers.filter(trigger => trigger.type === 'LINK_ADDED').map(trigger => trigger.artifactTypeId);
    const linkedArtifactSet = new Set<NewAttribute>();
    linkedArtifactTypeIds.forEach(artifactTypeId => this.getArtifactTypeAttributes(artifactTypeId!).forEach(attr => linkedArtifactSet.add(attr)));

    this.selectedAttributes = [...this.getArtifactTypeAttributes(this.defaultArtifactType.id), ...Array.from(linkedArtifactSet.values())];

    if (this.conditionGroups) {
      this.conditionGroups.forEach(group => group.updateAvailableAttributes());
    }
  }

  private initializeManualSources(group: RuleConditionGroup): void {
    const init = () => {
      group.conditions.forEach(condition => {
        if (this.allowOnlyManualConditions) {
          condition.source.manual = true;
        } else if (this.allowOnlyNonManualConditions) {
          condition.source.manual = false;
        } else if (condition.source.value) {
          const isDynamicNotation = SharedMethods.isDynamicAttributeNotation(condition.source.value);
          const attributeId = this.getAttributeIdFromCondition(condition);
          const valueIsId = attributeId && condition.source.value === attributeId;

          condition.source.manual =
            (!isDynamicNotation && !valueIsId) || !attributeId || (this.defaultArtifactType && !this.defaultArtifactType.attributes[attributeId]);
        } else if (this.defaultArtifactType === undefined) {
          condition.source.manual = true;
        }
      });

      group.groups.forEach(group => this.initializeManualSources(group));
    };
    if (this.cache.isLoaded === true) {
      init();
    } else {
      this.cache.isLoaded$.pipe(take(1)).subscribe(init);
    }
  }

  private getConditionDestinationDefaultValue(attributeId: string): string | null {
    if (this.isConditionBoolean(attributeId) || this.isConditionEnum(attributeId)) {
      return null;
    }
    return '';
  }

  private getAttributeIdFromCondition(condition: RuleCondition): string {
    const attributeId = SharedMethods.getMongoIdFromString(condition.source.value);
    return attributeId?.[0] || '';
  }

  private isConditionEnum(attributeId: string): boolean {
    const dataType = this.getDataTypeFromAttributeId(attributeId);
    return !!dataType && IsEnumerated(dataType.kind as DataTypeKind);
  }

  private isConditionBoolean(attributeId: string): boolean {
    const dataType = this.getDataTypeFromAttributeId(attributeId);
    return !!dataType && IsBoolean(dataType.baseDataType);
  }

  private isConditionDateTime(attributeId: string): boolean {
    const dataType = this.getDataTypeFromAttributeId(attributeId);
    return !!dataType && IsDateTime(dataType.baseDataType);
  }

  private getDataTypeFromAttributeId(attributeId: string): DataTypeResponseDto | null {
    const attribute = this.cache.data.attributes.get(attributeId);
    return this.cache.data.dataTypes.get(attribute?.dataTypeId || '');
  }
}
