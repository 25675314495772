import { inject, Pipe, PipeTransform } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { PageHelper } from '@shared/helpers/page-helper';
import { ImageMethods } from '@shared/methods/image.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';

@Pipe({
  name: 'imageFilterStyles',
})
export class ImageFilterStylesPipe implements PipeTransform {
  private static readonly allowedProperties = Object.freeze({
    paddingTop: true,
    paddingRight: true,
    paddingBottom: true,
    paddingLeft: true,
    borderTopLeftRadius: true,
    borderTopRightRadius: true,
    borderBottomRightRadius: true,
    borderBottomLeftRadius: true,
    boxShadow: true,
    backgroundImage: true,
    backgroundColor: true,
    backgroundRepeat: true,
    backgroundSize: true,
    backgroundPosition: true,
    backgroundAttachment: true,
    marginTop: true,
    marginRight: true,
    marginBottom: true,
    marginLeft: true,
    maxWidth: true,
    minWidth: true,
  });
  private readonly apiConfiguration = inject(ApiConfiguration);
  private readonly authorizationService = inject(AuthorizationService);
  private readonly pageHelper = inject(PageHelper);

  internalImageIdToUrlPipe(imageFileId: string): string {
    if (!imageFileId) return '';

    let token = this.authorizationService.getToken;
    if (!token || this.pageHelper.usePublicToken) token = this.authorizationService.getAnonymousToken;

    let url = `url(${ImageMethods.getUrlFromImageFileId(imageFileId, this.apiConfiguration.rootUrl, token)})`;

    return url;
  }

  transform(styles: Partial<StylesDto>): Partial<StylesDto> {
    const allowedKeys = Object.keys(styles).filter(
      (key): key is keyof typeof ImageFilterStylesPipe.allowedProperties => key in ImageFilterStylesPipe.allowedProperties,
    ) as (keyof StylesDto)[];

    const filteredStyles = Object.assign({}, ...allowedKeys.map(key => ({ [key]: styles[key] }))) as Partial<StylesDto>;

    if (filteredStyles.backgroundImage) {
      if (!ImageMethods.checkIsExternalUrl(filteredStyles.backgroundImage)) {
        filteredStyles.backgroundImage = this.internalImageIdToUrlPipe(filteredStyles.backgroundImage);
      }
      if (styles.backgroundOpacity) {
        filteredStyles.backgroundImage = ImageMethods.convertToUrlWithAlpha(filteredStyles.backgroundImage, styles.backgroundOpacity);
      }
    }

    return filteredStyles;
  }
}
