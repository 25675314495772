<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'Filter' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.filter].isDynamic" id="isDynamicFilter" binary="true"
                class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <textarea
      [(ngModel)]="action.actionSettings[RuleKeys.filter].value"
      [placeholder]="'Enter filter' | translate"
      rows="3"
      pInputTextarea
      class="w-100"
      id="filterInput"
    ></textarea>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Sort' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.sort].isDynamic" id="isDynamicSort" binary="true"
                class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <textarea
      [(ngModel)]="action.actionSettings[RuleKeys.sort].value"
      [placeholder]="'Enter sort' | translate"
      rows="3"
      pInputTextarea
      class="w-100"
      id="sortInput"
    ></textarea>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Scope path to store' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.scopePathToStore].isDynamic" id="isDynamicScope"
                binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.scopePathToStore].value"
      [placeholder]="'Enter scope path to store' | translate"
      pInputText
      class="w-100"
      id="scopeInput"
    />
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'User' | translate }}
  </div>
  <div class="col-8">
    <div class="input-field">
      <app-user-autocomplete [(value)]="action.actionSettings[RuleKeys.userId].value"
                             [multipleValues]="false"
                             [appendTo]="'body'"
                             class="w-100"
      ></app-user-autocomplete>
    </div>
  </div>
</div>
