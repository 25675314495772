import { inject, Pipe, PipeTransform } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { PageHelper } from '../../helpers/page-helper';
import { ImageMethods } from '../../methods/image.methods';
import { AuthorizationService } from '../../services/authorization/authorization.service';

@Pipe({ name: 'internalImageIdToUrl' })
export class InternalImageIdToUrlPipe implements PipeTransform {
  private readonly apiConfiguration = inject(ApiConfiguration);
  private readonly authorizationService = inject(AuthorizationService);
  private readonly pageHelper = inject(PageHelper);

  transform(imageFileId: string): string {
    if (!imageFileId) return '';

    let token = this.authorizationService.getToken;
    if (!token || this.pageHelper.usePublicToken) token = this.authorizationService.getAnonymousToken;

    return ImageMethods.getUrlFromImageFileId(imageFileId, this.apiConfiguration.rootUrl, token);
  }
}
