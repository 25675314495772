import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { UserAutocompleteComponent } from '@shared/components/user-autocomplete/user-autocomplete.component';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { filter, map, Observable, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    MultiSelectModule,
    InputSwitchModule,
    ArtifactAttributeFormFieldLabelWithIconComponent,
    UserAutocompleteComponent,
  ],
  selector: 'app-artifact-attribute-form-field-user',
  templateUrl: './artifact-attribute-form-field-user.component.html',
  styleUrls: ['./artifact-attribute-form-field-user.component.scss'],
})
export class ArtifactAttributeFormFieldUserComponent implements OnInit {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;
  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() onChange: (value: any) => Promise<void>;
  @Input() onBlur: () => void;

  currentUserSelected$: Observable<boolean>;
  private currentUserId: string;

  constructor(private readonly cache: NewCacheService) {}

  ngOnInit() {
    this.currentUserSelected$ = this.cache.userProfile.pipe(
      filter(() => this.attribute.multipleValues),
      tap((currentUser: any) => (this.currentUserId = currentUser.id)),
      map(currentUser => Boolean((this.attr.value as string[])?.find(id => id === currentUser.id))),
    );
  }

  onSubscribeMeToggle(amSelected: boolean) {
    if (this.currentUserId) {
      this.attr.value ??= [];
      this.attr.value = amSelected ? [...this.attr.value, this.currentUserId] : (this.attr.value as string[]).filter(id => id !== this.currentUserId);
      this.onChange(this.attr.value);
    }
  }
}
