import { Component, Inject } from '@angular/core';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import {
  AbstractAvrQuerySettingsComponent,
  AVR_SETTINGS_INJECTOR,
  AvrSettingsInjectorBody,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/abstract.avr-types-settings.component';
import { SwissBillAvrWidgetSettingsDto } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/swiss-bill/types/swiss-bill.avr-types-settings.types';
import { SwissBillAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/swiss-bill/swiss-bill.avr-types-services.service';

@Component({
  selector: 'app-avr-widget-swiss-bill-types-settingss',
  templateUrl: './swiss-bill.avr-types-settings.component.html',
  styleUrls: ['./swiss-bill.avr-types-settings.component.scss'],
})
export class AvrSwissBillTypesSettingsComponent extends AbstractAvrQuerySettingsComponent<AvrTypes.swissBill, SwissBillAvrTypeService> {
  constructor(@Inject(AVR_SETTINGS_INJECTOR) protected injector: AvrSettingsInjectorBody) {
    super(injector, AvrTypes.swissBill, SwissBillAvrWidgetSettingsDto);
  }

  get avrType(): AvrTypes.swissBill {
    return AvrTypes.swissBill;
  }
}
