import { Component, Input } from '@angular/core';
import { NAME_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { ArtifactWidgetModel, ArtifactWidgetType } from '@widgets/artifact-widget/types/artifact-widget.types';

@Component({
  selector: 'app-artifact-widget-type-picker',
  templateUrl: './artifact-widget-type-picker.component.html',
  styleUrls: ['./artifact-widget-type-picker.component.scss'],
})
export class ArtifactWidgetTypePickerComponent {
  @Input() m: ArtifactWidgetModel;
  @Input() onArtifactTypeChange: () => Promise<void>;
  @Input() onLinkTypeChange: () => void;

  NAME_KEY = NAME_KEY;

  constructor(public readonly artifactWidgetHelper: ArtifactWidgetHelper) {}

  onWidgetTypeChange(widgetType: ArtifactWidgetType): void {
    this.m.settings.urlChangeAction = widgetType === ArtifactWidgetType.artifact;
    this.m.selected.artifactType = null;
    this.m.selected.linkType = null;
    this.handleRegistrationMode(widgetType);
    this.handleLoginMode(widgetType);
    this.handleRecoverPasswordMode(widgetType);
  }

  private handleRegistrationMode(widgetType: ArtifactWidgetType): void {
    if (widgetType === ArtifactWidgetType.registration) {
      this.m.settings.useReCaptcha = true;
      this.m.selected.artifactType = this.m.options.artifactTypes.listMap[GlobalConstants.getValue(GlobalConstantsEnum.profileArtifactTypeId)];
      this.onArtifactTypeChange && this.onArtifactTypeChange();
    }
  }

  private handleLoginMode(widgetType: ArtifactWidgetType): void {
    if (widgetType === ArtifactWidgetType.login) {
      this.m.linkRestrictions = [];
      this.m.selected.artifactType = null;
      this.onArtifactTypeChange && this.onArtifactTypeChange();
    }
  }

  private handleRecoverPasswordMode(widgetType: ArtifactWidgetType): void {
    if (widgetType === ArtifactWidgetType.recoverPassword) {
      this.m.linkRestrictions = [];
      this.m.settings.useReCaptcha = true;
      this.m.selected.artifactType = null;
      this.onArtifactTypeChange && this.onArtifactTypeChange();
    }
  }
}
