import { Component, Input } from '@angular/core';
import { GetArtifactsKeys, WorkflowActionGetArtifacts } from '@workflows/types/actions/action-get-artifacts';

@Component({
  selector: 'app-action-get-artifacts',
  templateUrl: './action-get-artifacts.component.html',
  styleUrls: ['./action-get-artifacts.component.scss'],
})
export class ActionGetArtifactsComponent {
  @Input() action: WorkflowActionGetArtifacts;
  protected readonly RuleKeys = GetArtifactsKeys;
}
