import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayAtBooleanModule, DisplayAtEnumModule, DisplayAtLinkModule, DisplayAtOverlayModule, DisplayAtSystemDateModule, DisplayAtUserModule, DisplayHyperlinkIconModule,
} from '@shared/components/common-display-at';
import { DisplayAtDatetimeModule } from '@shared/components/common-display-at/components/display-at-datetime/display-at-datetime.module';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { GetHyperlinkValuePipe } from '@widgets/card-widget/components/card-area/pipes/get-hyperlink-value.pipe';
import { IsRedirectIconHyperlinkPipe } from '@widgets/card-widget/components/card-area/pipes/is-redirect-icon-hyperlink.pipe';
import { AreaContentItemsToolbarDirective } from '@widgets/card-widget/directives/area-content-items-toolbar.directive';
import { CardWidgetEventsService } from '@widgets/card-widget/services/card-widget-events.service';
import { AttributeFormatLabelStylesFormModule } from '@widgets/shared/components/attribute-format-settings';
import { ListItemClickActionSettingsFormModule } from '@widgets/shared/components/list-item-click-action-settings-form/list-item-click-action-settings-form.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { ReplaceRuntimeVariablesModule } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { ResizableModule } from 'angular-resizable-element';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { CardWidgetComponent } from './card-widget.component';
import { CardAreaStylerComponent } from './components/card-area-styler/card-area-styler.component';
import { CardAreaComponent } from './components/card-area/card-area.component';
import { CardResizerComponent } from './components/card-resizer/card-resizer.component';
import { CardWidgetSettingsComponent } from './components/card-widget-settings/card-widget-settings.component';
import { AreaMinHeightDirective } from './directives/area-min-height.directive';
import { InheritCornerBorderRadiusDirective } from './directives/inherit-corner-border-radius.directive';
import { RangedGridItemDirective } from './directives/ranged-grid-item.directive';
import { GetAttributeIconPipe } from './pipes/get-attribute-icon.pipe';
import { GetAttributeNamePipe } from './pipes/get-attribute-name.pipe';
import { GetAttributeValuePipe } from './pipes/get-attribute-value.pipe';
import { GetEnumeratedOptionsPipe } from './pipes/get-enumerated-option.pipe';
import { GetHyperlinksPipe } from './pipes/get-hyperlinks.pipe';
import { IsBaseDataTypeEqualToPipe } from './pipes/is-base-data-type-equal-to.pipe';
import { IsLinksAttributePipe } from './pipes/is-links-attribute.pipe';
import { IsSingleFileAttributePipe } from './pipes/is-single-file-attribute.pipe';
import { IsSingleHyperlinkAttributePipe } from './pipes/is-single-hyperlink-attribute.pipe';
import { PrimaryAttributeValuesPipe } from './pipes/primary-attribute-values.pipe';
import { LinkPipesModule } from '@shared/pipes/links/link-pipes.module';
import { BadgeModule } from 'primeng/badge';
import { IsMultipleFileAttributePipe } from '@widgets/card-widget/pipes/is-multi-file-attribute.pipe';
import { ImagePipesModule } from '@shared/pipes/internal-image/image-pipes.module';
import { ImageFilterStylesForCardAdmin } from '@widgets/card-widget/pipes/image-filter-styles-for-card-admin.pipe';
import { DisplayAtFileModule } from '@shared/components/common-display-at/components/display-at-file/display-at-file.module';
import { IsSystemFileAttributePipe } from '@widgets/card-widget/pipes/is-system-file-attribute.pipe';
import { IsClickablePipe } from '@widgets/shared/pipes/is-clickable-by-actions.pipe';

const directives = [RangedGridItemDirective, AreaMinHeightDirective, InheritCornerBorderRadiusDirective, AreaContentItemsToolbarDirective];
const components = [
  CardWidgetComponent,
  CardWidgetSettingsComponent,
  CardAreaComponent,
  CardAreaStylerComponent,
  CardResizerComponent,
];
const pipes = [
  GetAttributeValuePipe,
  IsLinksAttributePipe,
  IsBaseDataTypeEqualToPipe,
  GetAttributeNamePipe,
  GetEnumeratedOptionsPipe,
  GetHyperlinksPipe,
  PrimaryAttributeValuesPipe,
  IsSystemFileAttributePipe,
  IsSingleFileAttributePipe,
  IsMultipleFileAttributePipe,
  IsSingleHyperlinkAttributePipe,
  GetAttributeIconPipe,
  IsRedirectIconHyperlinkPipe,
  GetHyperlinkValuePipe,
  ImageFilterStylesForCardAdmin,
];

@NgModule({
  declarations: [components, directives, pipes],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    AccordionModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    DragDropModule,
    ColorPickerModule,
    ElvisSharedModule,
    StyleFormModule,
    OverlayPanelModule,
    RippleModule,
    TooltipModule,
    WidgetContainerModule,
    SidebarModule,
    RadioButtonModule,
    CheckboxModule,
    ListItemClickActionSettingsFormModule,
    AttributeFormatLabelStylesFormModule,
    ReplaceRuntimeVariablesModule,
    DataTypePipesModule,
    DisplayAtUserModule,
    ResizableModule,
    InputNumberModule,
    DisplayHyperlinkIconModule,
    DisplayAtOverlayModule,
    DisplayAtSystemDateModule,
    DisplayAtDatetimeModule,
    DisplayAtEnumModule,
    DisplayAtBooleanModule,
    TabViewModule,
    DisplayAtLinkModule,
    LinkPipesModule,
    BadgeModule,
    ImagePipesModule,
    DisplayAtFileModule,
    IsClickablePipe,
  ],
  providers: [CardWidgetEventsService, GetAttributeValuePipe, GetEnumeratedOptionsPipe, GetHyperlinksPipe],
  exports: [CardWidgetComponent],
})
export class CardWidgetModule {}
