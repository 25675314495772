<div class="header">
  <a [routerLink]="['/admin/workflow']">
    <button class="p-button" icon="pi pi-plus" iconPos="left" label="{{'Add' | translate }}" pButton></button>
  </a>
</div>

<app-workflow-list-table [options]="m.options"
                         [columns]="m.columns"
                         [loadDataMethod]="loadDataMethod">
</app-workflow-list-table>
