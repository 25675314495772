import { Pipe, PipeTransform } from '@angular/core';
import { EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { GetDataTypeByAttributeId } from '@shared/methods/attribute.methods';
import { SharedMethods, WithEmptyEnumOption } from '@shared/methods/shared.methods';
import { SelectOption } from '@shared/types/shared.types';
import { ListWidgetOptions } from '../types/list-widget-options.types';

@Pipe({ name: 'getEnumOrUserFilterOptions' })
export class GetEnumOrUserFilterOptionsPipe implements PipeTransform {
  transform(attributeId: string, options: ListWidgetOptions, multipleValues: boolean, isTeamFilter = false): SelectOption<string, any>[] | EnumeratedOption[] {
    const dataType = GetDataTypeByAttributeId(attributeId, options.attributes.listMap, options.dataTypes.listMap);

    if (isTeamFilter) return SharedMethods.withCurrentUserTeamsOption(options.teams.toSelectOptions(NAME_KEY, ID_KEY));

    return WithEmptyEnumOption(dataType?.values || [], multipleValues);
  }
}
