<a *ngIf="item.uiType !== menuItemUiType.divider else divider"
   (click)="onAnchorClick($event, item)"
   (mouseenter)="onMouseEnter($event, item)"
   (mouseleave)="onMouseLeave(item)"
   [href]="item | getHrefForMenuItem : m.options.pages.listMap"
   [attr.hidden]="!item.visible"
   [style.display]="(!(m.items.menu | isItemInFirstLevel : item) && m.settings.menu.type | isMenuHorizontal)
                    || m.settings.menu.type === 'tiered' ? 'flex':'block'"
   [ngClass]="{ 'hidden': !item.visible }">

  <div (mouseenter)="m.settings.item[item.hash].mouseOvered = true"
       (mouseleave)="m.settings.item[item.hash].mouseOvered = false"
       [class]="(item.isTextOnly) ? 'inactive-link':'menu-link'"
       [ngStyle]="m | getMenuItemStyles : item : m.styles.generalMenuItemStyles[item.hash] : mouseOvered : queryParams"
       [style.width]="!((m.items.menu | isItemInFirstLevel : item) && (m.settings.menu.type | isMenuHorizontal)) ? '-webkit-fill-available':''">

    <div class="d-flex" style="height: 100%" [ngStyle]="directionStyles">

      <div class="menu-icon-container"
           [style.order]="(m | getContentStyles : item : menuStylesPropNamesEnum.icon : mouseOvered : queryParams)?.order">
        <span *ngIf="item.menuIcon" [class]="item.menuIcon + ' menu-icon'"
              [ngStyle]="m | getContentStyles : item : menuStylesPropNamesEnum.icon : mouseOvered : queryParams">
        </span>
      </div>

      <span *ngIf="item.chip"
            [ngStyle]="m | getContentStyles : item : menuStylesPropNamesEnum.chip : mouseOvered : queryParams"
            class="chip">
        {{ item?.chip }}
      </span>

      <div *ngIf="item.label || item.subLabel" class="labels">
        <span [ngStyle]="m | getContentStyles : item : menuStylesPropNamesEnum.label : mouseOvered : queryParams"
              class="truncated">
          {{ item?.label }}
        </span>

        <span [ngStyle]="m | getContentStyles : item : menuStylesPropNamesEnum.subLabel : mouseOvered : queryParams"
              class="truncated">
          {{ item?.subLabel }}
        </span>
      </div>

      <span *ngIf="item.children.length"
            [ngStyle]="m | getContentStyles : item : menuStylesPropNamesEnum.label : mouseOvered : queryParams"
            class="chevron">
        <span [class]="m.items.menu | getSubMenuArrow : item : !!item.expanded : m.settings.menu.type"></span>
      </span>

    </div>
  </div>

  <app-sub-menu-container *ngIf="!(item | isItemInTree) && item.expanded && item.children.length"
                          (mouseenter)="this.subMenuMouseOvered=true"
                          (mouseleave)="this.subMenuMouseOvered = false"
                          [item]="item"
                          [m]="m"
                          [queryParams]="queryParams"
                          [subMenuDomRectangle]="subMenuDomRectangle">
  </app-sub-menu-container>
</a>

<ng-template #divider>
  <app-divider
    [isItemHorizontallyOrdered]="(m.settings.menu.type | isMenuHorizontal) && (m.items.menu | isItemInFirstLevel : item)"
    [item]="item"
    [m]="m" class="divider"
    [ngStyle]="{'justify-content': (item | getDividerStyles : m : false).justifyContent}"></app-divider>
</ng-template>
