import { Injectable } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  BLANK_FILTER_OPTION_LABEL,
  BLANK_OPTION_FILTER_URL_VALUE,
  CREATED_BY_KEY,
  CURRENT_USER_OPTION_LABEL,
  CURRENT_USER_URL_FILTER_VALUE,
  IS_NOT_EMPTY_OPTION_FILTER_VALUE,
  UNRESOLVED_VALUE,
} from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetArtifactAttributeValuePath } from '@shared/methods/client-attribute.methods';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactFilterHelper } from '@widgets/shared/components/artifact-filters/services/artifact-filter.helper';
import { ArtifactFilter, ArtifactFilterType } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Injectable({
  providedIn: 'root',
})
export class UserFilterService {
  constructor(
    private readonly helper: ArtifactFilterHelper,
    private readonly cache: NewCacheService,
  ) {}

  getQuery(filter: ArtifactFilter): any {
    if (!filter.value?.length) {
      return null;
    }

    return filter.type === ArtifactFilterType.system ? this.getQueryForRecordAuthor(filter) : this.getQueryForNonSystemAttribute(filter);
  }

  async getFilterValueFromString(str: string): Promise<SelectOption<string, string>[] | null> {
    const ids = str.split(',');
    return (await this.cache.data.users.getManyAsync(ids)).map(
      user =>
        new SelectOption((user.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) || UNRESOLVED_VALUE, user.id),
    );
  }

  private getQueryForRecordAuthor(filter: ArtifactFilter): any {
    return {
      [this.getRecordAuthorKey(filter.name)]: {
        $in: filter.value.map(({ value }: SelectOption<string, string>) => {
          return {
            $oid:
              value === NonAttributeKeys.CURRENT_USER_ID || value === CURRENT_USER_URL_FILTER_VALUE
                ? (this.cache.userProfile.value as ArtifactLinkResponseDto)?.id
                : value,
          };
        }),
      },
    };
  }

  private getQueryForNonSystemAttribute(filter: ArtifactFilter): any {
    return {
      $or: [
        ...(Array.isArray(filter.value) ? filter.value : [filter.value]).map((option: SelectOption<string, string>) => {
          const key = GetArtifactAttributeValuePath(filter.attributeId);
          const isIsNotEmpty = option.label === IS_NOT_EMPTY_OPTION_FILTER_VALUE;
          const value = this.isCurrentUser(option) ? (this.cache.userProfile.value as ArtifactLinkResponseDto)?.id : this.isBlank(option) ? '' : option.value;

          return isIsNotEmpty ? { $and: [{ [key]: { $ne: null } }, { [key]: { $ne: '' } }] } : { [key]: value };
        }),
      ],
    };
  }

  private isCurrentUser(option: SelectOption<string, string>): boolean {
    return option.label === CURRENT_USER_OPTION_LABEL || option.value === CURRENT_USER_URL_FILTER_VALUE;
  }

  private isBlank(option: SelectOption<string, string>): boolean {
    return option.label === BLANK_FILTER_OPTION_LABEL || option.value === BLANK_OPTION_FILTER_URL_VALUE;
  }

  private getRecordAuthorKey(value: string): string {
    return this.helper.getKeyByLabel(value) || CREATED_BY_KEY;
  }
}
