import { Injectable } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BulkOperationResult } from '@shared/components/bulk-artifact-edit-popup/types/bulk-operation-result';
import { EMPTY_GROUP_VALUE } from '@shared/constants/constants';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { Exception } from '@shared/types/exception.types';
import { NewLink } from '@shared/types/link.types';
import { RuntimeStateNotification, RuntimeStateNotificationEnum, UpdateLinksDto } from '@widgets/shared/types/runtime-state-notification.types';
import { LazyLoadEvent } from 'primeng/api';
import { lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ArtifactListWidgetTableComponent } from '../artifact-list-widget-table.component';
import { GroupMetaDataItem } from '../types/list-widget-grouping.types';

@Injectable()
export class ArtifactListWidgetTableHelper {
  c: ArtifactListWidgetTableComponent;

  init(context: ArtifactListWidgetTableComponent): void {
    this.c = context;
  }

  deleteLink$(artifact: NewArtifact, link: NewLink): Observable<LinkResponseDto> {
    return this.c.tenantLinkService.linkControllerDelete({ id: link.id }).pipe(
      tap(
        dto => {
          this.deleteLinkFromModel(dto.linkTypeId, dto.sourceArtifactId, dto.id);
          this.deleteLinkFromModel(dto.linkTypeId, dto.destinationArtifactId, dto.id);
          this.c.m.links = { ...this.c.m.links };
          const notificationData = [dto.destinationArtifactId, dto.sourceArtifactId];
          this.c.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.deleteLink, notificationData, this.c.ids.hash, dto.linkTypeId);
        },
        error => {
          console.log(error);
          this.c.announcement.error('Link not deleted');
        },
      ),
    );
  }

  onRowDeleteClick(row: NewArtifact): void {
    this.c.confirmationService.confirm({
      message: 'Are you sure that you want to delete this row?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        try {
          await this.deleteRowWithLinks(row);
        } finally {
          await this.c.onLazyLoad(this.c.latestLazyLoadEvent!);
        }
      },
    });
  }

  async onArtifactDeleteClick(event: MouseEvent, artifact: NewArtifact): Promise<void> {
    event.stopPropagation();
    const artifactsToDelete = this.c.filterUtil.toArray(this.c.isArtifactSelected(artifact) ? this.c.selection : artifact);

    if (await this.confirmDeletion(artifactsToDelete.length > 1)) {
      this.c.showLoader();
      const deletionResult = await this.deleteArtifacts(artifactsToDelete);
      this.c.resetSelection();
      await this.c.onLazyLoad(this.c.latestLazyLoadEvent!);
      await this.announceDeletionResult(deletionResult);
    }
  }

  private async confirmDeletion(plural: boolean): Promise<boolean> {
    return new Promise(resolve => {
      this.c.confirmationService.confirm({
        message: `Are you sure that you want to delete ${plural ? 'selected artifacts' : 'this artifact'}?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => resolve(true),
        reject: () => resolve(false),
      });
    });
  }

  private async deleteArtifacts(artifactsToDelete: NewArtifact[]): Promise<BulkOperationResult & { permissionFailures: number }> {
    let success = 0;
    let failure = 0;
    let permissionFailure = 0;

    await Promise.all(
      artifactsToDelete.map(async (artifact: NewArtifact) => {
        try {
          await this.deleteArtifactWithLinks(artifact);
          success++;
        } catch (e: any) {
          console.error(
            new Exception({
              name: 'DeleteArtifactException',
              message: 'An error occurred while deleting an artifact',
              originalEvent: e,
            }),
          );
          failure++;
          e.status === 403 && permissionFailure++;
        }
      }),
    );

    return { success, failure, permissionFailures: permissionFailure };
  }

  private async announceDeletionResult(deletionResult: BulkOperationResult & { permissionFailures: number }): Promise<void> {
    const { success, failure, permissionFailures } = deletionResult;
    let message = `Success: ${success}. Failure: ${failure}.`;
    permissionFailures && (message += ` (Insufficient permissions: ${permissionFailures})`);

    if (!success) {
      return await this.c.announcement.error(message);
    }

    if (failure || permissionFailures) {
      return await this.c.announcement.warn(message);
    }

    return await this.c.announcement.info(message);
  }

  // TODO: data is array, groups are array of arrays, check indexing
  onRowEditCancel(row: any, rowIndex: number, groupIndex: number, currentlyEditedRowsMap: Record<string, boolean>, event: MouseEvent): void {
    event.stopPropagation();
    this.c.data[rowIndex] = this.c.cloneRows[row.id];
    delete this.c.cloneRows[row.id];
    currentlyEditedRowsMap['editingButton' + groupIndex + rowIndex] = false;
  }

  shouldUpdateGroupedData(clientAttributes: NewClientAttribute[], rowId: string): boolean {
    const { groupingAttributes } = this.c.settings.grouping;
    if (!groupingAttributes.length) {
      return false;
    }

    const groupedId = groupingAttributes[0].value.id;
    const editedGroupAttribute = clientAttributes.find(item => item.id === groupedId);
    if (!editedGroupAttribute) {
      return false;
    }

    const originalGroupAttribute = this.c.cloneRows[rowId].clientAttributes.find(attr => attr.id === groupedId);
    return editedGroupAttribute.value !== (originalGroupAttribute && originalGroupAttribute.value);
  }

  getNewPositionInGroup(artifact: any): GroupMetaDataItem | null {
    const { groupingAttributes } = this.c.settings.grouping;
    let groupingValue = artifact.attributes[groupingAttributes[0].value.id].value;
    if (!groupingValue) {
      groupingValue = EMPTY_GROUP_VALUE;
    }
    return this.c.groupMetaData$.value ? this.c.groupMetaData$.value[groupingValue] : null;
  }

  isModuleEditingEnabled(filters: Record<string, any>, event: LazyLoadEvent | null): boolean {
    return this.isNoFilterActive(filters) && this.isOrderBySectionAscending(event);
  }

  private isOrderBySectionAscending(event: LazyLoadEvent | null): boolean {
    return !!event && event.sortField === NonAttributeKeys.SECTION && event.sortOrder === 1;
  }

  private isNoFilterActive(filters: Record<string, any>): boolean {
    return !Object.keys(filters).length;
  }

  private async deleteArtifactWithLinks({ id }: NewArtifact): Promise<void> {
    const dto = await lastValueFrom(this.c.artifactService.artifactControllerDelete({ id, notify: false }));
    this.c.runtimeStateNotificationService.events$.next(
      new RuntimeStateNotification<ArtifactResponseDto>(RuntimeStateNotificationEnum.deleteArtifact, dto, this.c.ids.hash),
    );
    await this.deleteRowLinks(dto);
  }

  private async deleteRowWithLinks(row: NewArtifact): Promise<void> {
    try {
      const dto = await lastValueFrom(this.c.artifactService.artifactControllerDelete({ id: row.id, notify: false }));
      this.c.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<ArtifactResponseDto>(RuntimeStateNotificationEnum.deleteArtifact, dto, this.c.ids.hash),
      );
      await this.deleteRowLinks(dto);
    } catch (e: any) {
      console.error(
        new Exception({
          name: 'DeleteRowException',
          message: 'An error occurred while deleting an artifact',
          originalEvent: e,
        }),
      );
      await this.c.announcement.error('An error occurred while deleting an artifact');
    }
  }

  private async deleteRowLinks(dto: ArtifactResponseDto): Promise<void> {
    try {
      const filter = JSON.stringify({
        $and: [
          {
            $or: [{ destinationArtifactId: { $in: [{ $oid: dto.id }] } }, { sourceArtifactId: { $in: [{ $oid: dto.id }] } }],
          },
          { deleted: { $eq: null } },
          { linkTypeId: { $in: this.c.options.linkTypes.list.map(linkType => ({ $oid: linkType.id })) } },
        ],
      });
      const links = (await lastValueFrom(this.c.tenantLinkService.linkControllerList({ body: { filter } }))).data;
      for await (const link of links) {
        const linkDto = await lastValueFrom(this.c.tenantLinkService.linkControllerDelete({ id: link.id }));
        this.deleteLinkFromModel(linkDto.linkTypeId, linkDto.sourceArtifactId, linkDto.id);
        this.deleteLinkFromModel(linkDto.linkTypeId, linkDto.destinationArtifactId, linkDto.id);
      }
      this.c.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<UpdateLinksDto[]>(
          RuntimeStateNotificationEnum.updateLinks,
          links.map(link => ({
            linkTypeId: link.linkTypeId,
            artifactIds: [link.sourceArtifactId, link.destinationArtifactId],
          })),
          this.c.ids.hash,
        ),
      );
    } catch (e: any) {
      console.error(
        new Exception({
          name: 'DeleteRowLinksException',
          message: 'An error occurred while deleting artifact links',
          originalEvent: e,
        }),
      );
      await this.c.announcement.error('An error occurred while deleting artifact links');
    }
  }

  private deleteLinkFromModel(linkTypeId: string, artifactId: string, linkId: string): void {
    if (!this.c.m.links[linkTypeId] || !this.c.m.links[linkTypeId][artifactId]) {
      return;
    }

    this.c.m.links[linkTypeId][artifactId][LinkDirection.incoming] = this.c.m.links[linkTypeId][artifactId][LinkDirection.incoming].filter(
      link => link.id !== linkId,
    );
    this.c.m.links[linkTypeId][artifactId][LinkDirection.outgoing] = this.c.m.links[linkTypeId][artifactId][LinkDirection.outgoing].filter(
      link => link.id !== linkId,
    );
  }
}
