import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactWidgetItemActions } from '@shared/types/actions.types';
import { ActionType } from '@widgets/shared/types/click-action-settings-dto';

@Pipe({
  name: 'isArtifactWidgetSpecial',
  standalone: true,
})
export class IsArtifactWidgetSpecialPipe implements PipeTransform {
  transform(actionType: ActionType): boolean {
    return Object.keys(ArtifactWidgetItemActions).includes(actionType);
  }
}
