import { ListContainer } from '@shared/types/list-container.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';

export class LinkFilterByLinked {
	matchType: LinkFilterMatchType;
	ruleTypes: LinkFilterOption[] = [new LinkFilterOption()];

	constructor(dto?: LinkFilterByLinked) {
		if (dto) {
			Object.assign(this, dto);
			return;
		}
		this.matchType = LinkFilterMatchType.matchAny;
		this.ruleTypes = [new LinkFilterOption()];
	}
}

export class LinkFilterOptions {
	matchType = new ListContainer<LinkFilterMatchType>();

	constructor() {
		this.matchType.setList(Object.keys(LinkFilterMatchType).map(key => LinkFilterMatchType[key as keyof typeof LinkFilterMatchType]));
	}
}

export class LinkFilterOption {
	artifactType: NewArtifactType[] = [];
	attribute: NewAttribute;
	attributes: NewAttribute[];
	filtersHolder: any = {};

	constructor() {}
}

export enum LinkFilterMatchType {
	matchAll = 'Match all',
	matchAny = 'Match any',
}
