import {
  AdminUserResponseDto,
  AdminUserTenantResponseDto,
  RegularUserResponseDto,
  RegularUserTenantResponseDto,
  SystemAdminUserResponseDto,
  SystemAdminUserTenantResponseDto,
} from '@api/models';
import { RecordDto } from '@api/models/record-dto';
import { ClientData } from '@shared/types/local-storage.types';

export class NewSystemUser implements SystemAdminUserResponseDto {
  id: string;
  email: string;
  isSystemAdmin = false;
  isLoginDisabled = false;
  consecutiveFailedLoginAttempts: number;
  failedLoginAttempts: number;
  lastFailedLoginDate: null | string;
  isGuest: boolean;
  tenant: SystemAdminUserTenantResponseDto;
  clientData: ClientData;
  created: RecordDto;
  updated: RecordDto;
  deleted: null | RecordDto;
  teamIds: Array<string>;
  defaultTeamId: string;

  constructor(user?: Partial<SystemAdminUserResponseDto>) {
    user && Object.assign(this, user);
  }
}

export class UserFull implements AdminUserResponseDto {
  id: string;
  email: string;
  isLoginDisabled: boolean;
  tenant: AdminUserTenantResponseDto;
  clientData: ClientData;
  created: RecordDto;
  updated: RecordDto;
  deleted: null | RecordDto = null;
  teamIds: Array<string>;
  defaultTeamId: string;

  constructor(user?: Partial<AdminUserResponseDto>) {
    user && Object.assign(this, user);
  }
}

export class NewUser implements RegularUserResponseDto {
  id: string;
  email: string;
  teamIds: Array<string>;
  defaultTeamId: string;
  tenant: RegularUserTenantResponseDto | null;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null;

  constructor(user?: Partial<RegularUserResponseDto | AdminUserResponseDto | SystemAdminUserResponseDto>) {
    user && Object.assign(this, user);
  }
}
