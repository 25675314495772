import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextFilterOptionsTypes } from '@widgets/shared/components/artifact-filters/components/text-filter/types/text-filter-options.types';
import { TextFilter, TextFilterOption } from '@widgets/shared/components/artifact-filters/components/text-filter/types/text-filter.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { TEXT_CONTAINER } from '@widgets/shared/constants/widget.constants';

@Component({
  selector: 'app-text-filter',
  templateUrl: './text-filter.component.html',
  styleUrls: ['./text-filter.component.scss'],
})
export class TextFilterComponent implements OnInit {
  @Input() m: ArtifactFilter;
  @Output() onFilterChange = new EventEmitter<any>();

  options = new TextFilterOptionsTypes();
  selected: TextFilter;
  textClass = TEXT_CONTAINER;

  ngOnInit(): void {
    !this.m.value?.ruleTypes?.length && (this.m.value = new TextFilter());
    this.selected = this.m.value;
  }

  addRule(): void {
    this.selected.ruleTypes.push(new TextFilterOption());
  }

  removeRule(index: number): void {
    this.selected.ruleTypes.splice(index, 1);
  }

  onChange(): void {
    this.onFilterChange.emit();
  }
}
