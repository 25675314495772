<div class="grid">
  <div class="col-12 pb-0">
    <h4 class="m-0">Rounding</h4>
  </div>

  <div class="col-6 pr-0 pb-0">
    <div class="p-inputgroup">
      <input [(ngModel)]="styles.borderTopLeftRadius" class="w-100 text-right" pInputText type="text" />
      <span class="p-inputgroup-addon">
              <i class="bi bi-arrow-up-left"></i>
            </span>
    </div>
  </div>

  <div class="col-6 pl-0 pb-0">
    <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="bi bi-arrow-up-right"></i>
            </span>
      <input [(ngModel)]="styles.borderTopRightRadius" class="w-100" pInputText type="text" />
    </div>
  </div>

  <div class="col-6 pt-0 pr-0">
    <div class="p-inputgroup">
      <input [(ngModel)]="styles.borderBottomLeftRadius" class="w-100 text-right" pInputText type="text" />
      <span class="p-inputgroup-addon">
              <i class="bi bi-arrow-down-left"></i>
            </span>
    </div>
  </div>

  <div class="col-6 pl-0 pt-0">
    <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="bi bi-arrow-down-right"></i>
            </span>
      <input [(ngModel)]="styles.borderBottomRightRadius" class="w-100" pInputText type="text" />
    </div>
  </div>

  <div class="col-12 pb-0">
    <h4 class="m-0">Styling</h4>
  </div>

  <!--  Top side-->
  <div class="col-2 mb-0 border-arrow">
    <h6>Side</h6>
    <i class="pi pi-arrow-up"></i>
  </div>

  <div class="col-2 mb-0">
    <app-color-picker (colorChange)="checkIfColorEmpty($event, borderSideEnum.top)" [onChangeCb]="isChange()"
                      [(color)]="styles.borderTopColor" [hideInput]="true" label="Color"></app-color-picker>
  </div>

  <div class="col-4 mb-0">
    <h6>Style</h6>
    <p-dropdown [(ngModel)]="styles.borderTopStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                [options]="options.borderStyles"></p-dropdown>
  </div>

  <div class="col-4 mb-0">
    <h6>Width</h6>
    <p-dropdown [(ngModel)]="styles.borderTopWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                [options]="options.borderWidth"></p-dropdown>
  </div>

  <!--  Right side-->
  <div class="col-2 mb-0 border-arrow">
    <i class="pi pi-arrow-right"></i>
  </div>

  <div class="col-2 mb-0">
    <app-color-picker (colorChange)="checkIfColorEmpty($event, borderSideEnum.right)" [onChangeCb]="isChange()"
                      [(color)]="styles.borderRightColor" [hideInput]="true"></app-color-picker>
  </div>

  <div class="col-4 mb-0">
    <p-dropdown [(ngModel)]="styles.borderRightStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                [options]="options.borderStyles"></p-dropdown>
  </div>

  <div class="col-4 mb-0">
    <p-dropdown [(ngModel)]="styles.borderRightWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                [options]="options.borderWidth"></p-dropdown>
  </div>

  <!--  Down side-->
  <div class="col-2 mb-0 border-arrow">
    <i class="pi pi-arrow-down"></i>
  </div>

  <div class="col-2 mb-0">
    <app-color-picker (colorChange)="checkIfColorEmpty($event, borderSideEnum.bottom)" [onChangeCb]="isChange()"
                      [(color)]="styles.borderBottomColor" [hideInput]="true"></app-color-picker>
  </div>

  <div class="col-4 mb-0">
    <p-dropdown [(ngModel)]="styles.borderBottomStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                [options]="options.borderStyles"></p-dropdown>
  </div>

  <div class="col-4 mb-0">
    <p-dropdown [(ngModel)]="styles.borderBottomWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                [options]="options.borderWidth"></p-dropdown>
  </div>

  <!--  Left side-->
  <div class="col-2 mb-0 border-arrow">
    <i class="pi pi-arrow-left"></i>
  </div>

  <div class="col-2 mb-0">
    <app-color-picker (colorChange)="checkIfColorEmpty($event, borderSideEnum.left)" [onChangeCb]="isChange()"
                      [(color)]="styles.borderLeftColor" [hideInput]="true"></app-color-picker>
  </div>

  <div class="col-4 mb-0">
    <p-dropdown [(ngModel)]="styles.borderLeftStyle" [autoDisplayFirst]="false" (onChange)="isChange()"
                [options]="options.borderStyles"></p-dropdown>
  </div>

  <div class="col-4 mb-0">
    <p-dropdown [(ngModel)]="styles.borderLeftWidth" [autoDisplayFirst]="false" (onChange)="isChange()"
                [options]="options.borderWidth"></p-dropdown>
  </div>
</div>