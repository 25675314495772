import { Injectable } from '@angular/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { DisplayAtUtilService } from '@shared/components/common-display-at';
import { Constants, EMPTY_GROUP_VALUE, NAME_KEY } from '@shared/constants/constants';
import { AttributeValueToClient } from '@shared/methods/client-attribute.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DEFAULT_VARIANT_KEY, DisplayAtMetadata, DisplayAttributeType } from '@shared/types/display-at-types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import {
  FilterType,
  LegacyTableColumnFormat,
  NewTableColumn,
  NewTableColumnAttributeMetadata,
  NewTableColumnMetaData,
  TableColumnFormat,
  TableColumnFormatSettings,
  TableFormatSettings,
  TableParamFilterSettings,
} from '@shared/types/table.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { FilterUtil } from '@shared/utils/filter.util';
import { LinkPopupOptions } from '@widgets/link-popup/types/link-popup-options.types';
import { LinkPopupSelected } from '@widgets/link-popup/types/link-popup-selected.types';
import { LinkPopupSettings } from '@widgets/link-popup/types/link-popup-settings.types';
import { LinkPopupWidgetModel } from '@widgets/link-popup/types/link-popup.types';
import { GroupAttributeItem, GroupMetaDataItem } from '../types/list-widget-grouping.types';
import { ListWidgetOptions } from '../types/list-widget-options.types';
import { ListWidgetSelected } from '../types/list-widget-selected.types';
import { ListWidgetTableLoadModeEnum, ListWidgetTableSettings } from '../types/list-widget-settings.types';
import { ListWidgetModel, TABLE_FORMAT_STATE_KEY, TABLE_PARAM_FILTER_STATE_KEY } from '../types/list-widget.types';
import { TableFilterUrlControlService } from './table-filter';

@Injectable({
  providedIn: 'root',
})
export class ListWidgetTableHelper {
  defaultGroupAttributeItems: GroupAttributeItem[];

  constructor(
    public readonly filterUtil: FilterUtil,
    private readonly tableFilterUrlControlService: TableFilterUrlControlService,
    private readonly displayAtUtilService: DisplayAtUtilService,
    private readonly elvisU: ElvisUtil,
  ) {}

  initAndGetGroupingOptions(
    options: ListWidgetOptions,
    settings: ListWidgetTableSettings,
    selected: ListWidgetSelected,
  ): SelectOption<string, GroupAttributeItem>[] {
    const dataTypesMap = options.dataTypes.listMap;
    const attributeIds = this.getRelevantAttributeIds(options, settings, selected);
    const groupAttributeItems: GroupAttributeItem[] = options.attributes
      .filterByKey('id', [...attributeIds])
      .filter(attribute => this.isRelevantGroupAttribute(attribute, dataTypesMap))
      .map(attribute => ({
        id: attribute.id,
        name: attribute.name,
        multipleValues: attribute.multipleValues,
        baseDataType: dataTypesMap[attribute.dataTypeId].baseDataType as BaseDataType,
        kind: dataTypesMap[attribute.dataTypeId].kind,
        dataType: dataTypesMap[attribute.dataTypeId],
        dataTypeId: attribute.dataTypeId,
      }));
    const allGroupItems = [...groupAttributeItems, ...this.getDefaultGroupAttributeItems()];
    return this.elvisU.transformAnyToSelectOptions(allGroupItems, NAME_KEY);
  }

  synchronizeGroupingSettings(settings: ListWidgetTableSettings): void {
    if (settings.grouping.groupingAttributes) {
      settings.grouping.groupingAttributes.forEach((groupingAttribute, index) => {
        const optionFound = settings.groupingOptions.find(groupingOption => groupingOption.value.id === groupingAttribute.value.id);
        optionFound && (settings.grouping.groupingAttributes[index] = optionFound);
      });
    }
  }

  generateOptionColumns(model: ListWidgetModel | LinkPopupWidgetModel): NewTableColumn[] {
    const tableFormatSettings = new TableFormatSettings(model.state?.[TABLE_FORMAT_STATE_KEY]);
    const tableParamFilterSettings = new TableParamFilterSettings(model.state?.[TABLE_PARAM_FILTER_STATE_KEY]);
    const columns = this.getRelevantAttributeIds(model.options, model.settings, model.selected).map(attributeId =>
      this.createAttributeColumn(attributeId, model, tableFormatSettings, tableParamFilterSettings),
    );
    const linkTypes = this.createLinkTypeColumns(model, tableFormatSettings);
    const idTableColumn = this.getIdColumn();
    const defaultColumns = this.generateDefaultColumns(tableFormatSettings, model.selected.artifactTypes);

    return [idTableColumn, ...columns, ...defaultColumns, ...linkTypes];
  }

  // TODO - to check the whole function again
  groupTableData(settings: ListWidgetTableSettings, data: NewArtifact[], attributes: NewAttribute[]): Record<string, GroupMetaDataItem> | null {
    // TODO: create GroupMetaData type
    if (!settings.grouping.groupingAttributes.length) {
      return null;
    }
    const matchedGroupingOption = settings.groupingOptions.find(option => option.value.id === settings.grouping.groupingAttributes[0].value.id);
    settings.grouping.groupingAttributes[0] = matchedGroupingOption || settings.grouping.groupingAttributes[0];
    const attributeId = settings.grouping.groupingAttributes[0].value.id;
    const rowGroupMetadata: Record<string, GroupMetaDataItem> = {};

    data &&
      data.forEach((artifact, i) => {
        const groupingValue = this.getGroupingValue(artifact, attributes, attributeId) || EMPTY_GROUP_VALUE;

        if (i == 0) {
          rowGroupMetadata[groupingValue] = { index: 0, size: 1, toggled: true };
        } else {
          const previousGroupingValue = this.getGroupingValue(data[i - 1], attributes, attributeId);

          if (groupingValue === previousGroupingValue) rowGroupMetadata[groupingValue].size++;
          else rowGroupMetadata[groupingValue] = { index: i, size: 1, toggled: true };
        }
      });
    return rowGroupMetadata;
  }

  mapArtifactAttributesToClient(artifact: NewArtifact, attributes: ListContainer<NewAttribute>, dataTypes: ListContainer<NewDataType>): void {
    artifact.clientAttributes?.map(attribute => {
      attribute.value = AttributeValueToClient(
        new AttributeToClientParams({
          dataTypes,
          attributes,
          clientAttribute: attribute,
          value: attribute.value,
        }),
      );
    });
  }

  getRelevantAttributeIds(
    options: ListWidgetOptions | LinkPopupOptions,
    settings: ListWidgetTableSettings | LinkPopupSettings,
    selected: ListWidgetSelected | LinkPopupSelected,
  ): string[] {
    const attributeIds = new Set<string>();

    if (settings.loadMode === ListWidgetTableLoadModeEnum.byModule) {
      return options.attributes.list.map(attribute => attribute.id);
    } else {
      if (selected instanceof LinkPopupSelected)
        Object.keys((selected as LinkPopupSelected).artifactType.attributes).forEach(attributeId => attributeIds.add(attributeId));
      else selected.artifactTypes.forEach(type => Object.keys(type.value.attributes).forEach(attributeId => attributeIds.add(attributeId)));
    }

    return [...attributeIds];
  }

  getDefaultGroupAttributeItems(): GroupAttributeItem[] {
    if (this.defaultGroupAttributeItems) {
      return this.defaultGroupAttributeItems;
    }
    this.defaultGroupAttributeItems = [
      {
        id: NonAttributeKeys.CREATED_BY,
        name: NonAttributeKeys.CREATED_BY_LABEL,
        baseDataType: BaseDataType.user,
        kind: DataTypeKind.simple,
        multipleValues: false,
        isNonAttribute: true,
        dataTypeId: null,
      },
      {
        id: NonAttributeKeys.CREATED_ON,
        name: NonAttributeKeys.CREATED_ON_LABEL,
        baseDataType: BaseDataType.date,
        kind: DataTypeKind.simple,
        multipleValues: false,
        isNonAttribute: true,
        dataTypeId: null,
      },
      {
        id: NonAttributeKeys.UPDATED_BY,
        name: NonAttributeKeys.UPDATED_BY_LABEL,
        baseDataType: BaseDataType.user,
        kind: DataTypeKind.simple,
        multipleValues: false,
        isNonAttribute: true,
        dataTypeId: null,
      },
      {
        id: NonAttributeKeys.UPDATED_ON,
        name: NonAttributeKeys.UPDATED_ON_LABEL,
        baseDataType: BaseDataType.date,
        kind: DataTypeKind.simple,
        multipleValues: false,
        isNonAttribute: true,
        dataTypeId: null,
      },
      // {
      //   id: NonAttributeKeys.FOLDER_PATH,
      //   name: NonAttributeKeys.FOLDER_PATH_LABEL,
      //   baseDataType: BaseDataType.text,
      //   kind: DataTypeKind.simple,
      //   multipleValues: false,
      //   isNonAttribute: true,
      // },
    ];
    return this.defaultGroupAttributeItems;
  }

  isRelevantGroupAttribute(attribute: NewAttribute, dataTypesMap: Record<string, NewDataType>): boolean {
    const { multipleValues, dataTypeId } = attribute;
    return (
      !multipleValues &&
      (dataTypesMap[dataTypeId].baseDataType === BaseDataType.user ||
        dataTypesMap[dataTypeId].baseDataType === BaseDataType.date ||
        dataTypesMap[dataTypeId].baseDataType === BaseDataType.dateTime ||
        dataTypesMap[dataTypeId].kind === DataTypeKind.enumerated)
    );
  }

  protected getIdColumn(): NewTableColumn {
    return new NewTableColumn({ label: NonAttributeKeys.ID, key: NonAttributeKeys.ID });
  }

  private createAttributeColumn(
    attributeId: string,
    model: ListWidgetModel | LinkPopupWidgetModel,
    tableFormatSettings: TableFormatSettings,
    tableParamFilterSettings: TableParamFilterSettings,
  ): NewTableColumn {
    const attribute = model.options.attributes.listMap[attributeId];
    const dataType = model.options.dataTypes.listMap[attribute.dataTypeId];
    const filterType = this.filterUtil.getFilterTypeByDataType(dataType);
    const filterUrlTypeService = this.tableFilterUrlControlService.getFilterUrlTypeServiceFrom(filterType);
    const columnFormatSettings = this.getColumnFormatSettings(attribute.id, tableFormatSettings) || ({ columnKey: attribute.id } as TableColumnFormatSettings);
    const columnParamFilterSettings = tableParamFilterSettings.columns[attribute.id] || {};
    const attributeMetadata = new NewTableColumnAttributeMetadata(attribute, dataType);
    const displayAtEnumType = this.displayAtUtilService.fromAttributeAndDataType(attribute, dataType);
    const displayAtMetadata = this.getDisplayAtMetadata(displayAtEnumType, columnFormatSettings, attribute);
    const meta = new NewTableColumnMetaData({
      filterType,
      filterUrlTypeService,
      dbFilterKey: this.filterUtil.getAttributesDbFilterKey(attribute.id),
      filterKey: attribute.id,
      isAttribute: true,
      displayAtEnumType,
      attributeMetadata,
      displayAtMetadata,
      columnFormatSettings,
      columnParamFilterSettings,
    });

    return new NewTableColumn({ label: attribute.name, key: attribute.id, meta });
  }

  private createLinkTypeColumns(model: ListWidgetModel | LinkPopupWidgetModel, tableFormatSettings: TableFormatSettings): NewTableColumn[] {
    return model.options.linkTypesOptions
      .filter(({ value }) => this.isApplicableLinkType(model, value))
      .map(({ label, value, meta }) => {
        const key = `${value.id}_${meta}`;
        const columnFormatSettings = this.getColumnFormatSettings(key, tableFormatSettings) || ({ columnKey: key } as TableColumnFormatSettings);
        const displayAtMetadata = this.getDisplayAtMetadata(DisplayAttributeType.link, columnFormatSettings);
        const filterUrlTypeService = this.tableFilterUrlControlService.getFilterUrlTypeServiceFrom(FilterType.link);
        const linkMeta = new NewTableColumnMetaData({
          isLink: true,
          filterType: FilterType.link,
          filterUrlTypeService,
          linkRestrictionParams: { direction: meta, linkTypeId: value.id },
          displayAtMetadata,
          columnFormatSettings,
          columnParamFilterSettings: {},
        });

        return new NewTableColumn({ label, key, meta: linkMeta });
      });
  }

  private getColumnFormatSettings(columnKey: string, tableFormatSettings: TableFormatSettings): TableColumnFormatSettings {
    const settings = tableFormatSettings.columns[columnKey] || ({ columnKey } as TableColumnFormatSettings);

    settings.columnFormat = settings.columnFormat ? this.ensureTableColumnFormat(settings.columnFormat) : new TableColumnFormat();
    settings.contentColumnFormat = settings.contentColumnFormat ? new TableColumnFormat(settings.contentColumnFormat) : new TableColumnFormat();

    return { ...new TableColumnFormat(), ...settings };
  }

  private ensureTableColumnFormat(format: TableColumnFormat | LegacyTableColumnFormat): TableColumnFormat {
    return TableColumnFormat.isLegacyTableColumnFormat(format)
      ? TableColumnFormat.fromLegacyTableColumnFormat(format as LegacyTableColumnFormat)
      : new TableColumnFormat(format as TableColumnFormat);
  }

  private getGroupingValue(artifact: NewArtifact, attributes: NewAttribute[], attributeId: string): string {
    const attribute = attributes.find(attribute => attribute.id === attributeId);
    if (!attribute || !artifact.attributes[attribute.id]) {
      return EMPTY_GROUP_VALUE;
    }
    return artifact.attributes[attribute.id].value?.value || artifact.attributes[attribute.id].value;
  }

  private isApplicableLinkType(model: ListWidgetModel | LinkPopupWidgetModel, linkType: LinkType): boolean {
    if (model instanceof ListWidgetModel && model.settings.loadMode === ListWidgetTableLoadModeEnum.byModule) {
      return model.applicationId === linkType.applicationId;
    }

    return (
      linkType.restrictions.find(restriction => {
        if (model instanceof ListWidgetModel) {
          return model.selected.artifactTypes.find(({ value }) => LinkMethods.isUsedRestriction(restriction, value)) !== undefined;
        } else {
          return LinkMethods.isUsedRestriction(restriction, model.selected.artifactType);
        }
      }) !== undefined
    );
  }

  private generateDefaultColumns(tableFormatSettings: TableFormatSettings, artifactTypes: SelectOption<string, NewArtifactType>[]): NewTableColumn[] {
    let columns = [
      new NewTableColumn({
        label: NonAttributeKeys.ARTIFACT_TYPE_LABEL,
        key: NonAttributeKeys.ARTIFACT_TYPE_ID,
        meta: this.getMetaDataForArtifactTypeColumn(tableFormatSettings),
      }),
      new NewTableColumn({
        label: NonAttributeKeys.CONTENT_LABEL,
        key: NonAttributeKeys.CONTENT,
        meta: this.getMetaDataForContentColumn(tableFormatSettings),
      }),
      new NewTableColumn({
        label: NonAttributeKeys.SECTION_LABEL,
        key: NonAttributeKeys.SECTION,
        meta: this.getMetaData(FilterType.text, NonAttributeKeys.SECTION),
      }),
      new NewTableColumn({
        label: NonAttributeKeys.IS_HEADING_LABEL,
        key: NonAttributeKeys.IS_HEADING,
        meta: this.getMetaData(FilterType.boolean, NonAttributeKeys.IS_HEADING),
      }),
      new NewTableColumn({
        label: NonAttributeKeys.CREATED_BY_LABEL,
        key: NonAttributeKeys.CREATED_BY,
        meta: this.getMetaData(FilterType.user, NonAttributeKeys.CREATED_BY, true, DisplayAttributeType.user, tableFormatSettings),
      }),
      new NewTableColumn({
        label: NonAttributeKeys.CREATED_ON_LABEL,
        key: NonAttributeKeys.CREATED_ON,
        meta: this.getMetaData(FilterType.datetime, NonAttributeKeys.CREATED_ON, true, DisplayAttributeType.systemDate, tableFormatSettings),
      }),
      new NewTableColumn({
        label: NonAttributeKeys.UPDATED_BY_LABEL,
        key: NonAttributeKeys.UPDATED_BY,
        meta: this.getMetaData(FilterType.user, NonAttributeKeys.UPDATED_BY, true, DisplayAttributeType.user, tableFormatSettings),
      }),
      new NewTableColumn({
        label: NonAttributeKeys.UPDATED_ON_LABEL,
        key: NonAttributeKeys.UPDATED_ON,
        meta: this.getMetaData(FilterType.datetime, NonAttributeKeys.UPDATED_ON, true, DisplayAttributeType.systemDate, tableFormatSettings),
      }),
      new NewTableColumn({
        label: NonAttributeKeys.FOLDER_PATH_LABEL,
        key: NonAttributeKeys.FOLDER_PATH,
        meta: this.getMetaDataForFolderPathColumn(tableFormatSettings),
      }),
    ];

    const fileArtifactType = artifactTypes.find(option => option.value.format === ArtifactTypeFormatEnum.file);

    if (fileArtifactType) {
      columns = columns.concat(
        Constants.fileArtifactCustomAttributes.map(
          ({ name }) =>
            new NewTableColumn({
              label: name,
              key: NonAttributeKeys.FILE_ARTIFACT_PATH_ID,
              // key: NonAttributeKeys.FILE_ARTIFACT_DTO_PATH,
              meta: this.getMetaDataForFormatFileColumn(tableFormatSettings),
            }),
        ),
      );
    }

    return columns;
  }

  private getMetaData(
    filterType: FilterType,
    filterKey: string,
    setDisplayAtMetadata = false,
    displayAtEnumType?: DisplayAttributeType,
    tableFormatSettings?: TableFormatSettings,
  ): NewTableColumnMetaData {
    const filterUrlTypeService = this.tableFilterUrlControlService.getFilterUrlTypeServiceFrom(filterType);
    const columnFormatSettings = tableFormatSettings?.columns[filterKey] || { columnKey: filterKey } || undefined;
    const displayAtMetadata = (setDisplayAtMetadata && this.getDisplayAtMetadata(displayAtEnumType as DisplayAttributeType, columnFormatSettings)) || undefined;
    return new NewTableColumnMetaData({
      filterType,
      filterKey,
      dbFilterKey: filterKey,
      filterUrlTypeService,
      displayAtEnumType,
      columnFormatSettings,
      displayAtMetadata,
    });
  }

  private getMetaDataForContentColumn(tableFormatSettings: TableFormatSettings): NewTableColumnMetaData {
    const displayAtEnumType = DisplayAttributeType.text;
    const columnFormatSettings = tableFormatSettings.columns[NonAttributeKeys.CONTENT] || { columnKey: NonAttributeKeys.CONTENT };
    const displayAtMetadata: DisplayAtMetadata = this.getDisplayAtMetadata(displayAtEnumType, columnFormatSettings);

    return new NewTableColumnMetaData({
      displayAtEnumType: DisplayAttributeType.text,
      columnFormatSettings,
      displayAtMetadata,
    });
  }

  private getMetaDataForArtifactTypeColumn(tableFormatSettings: TableFormatSettings): NewTableColumnMetaData {
    const displayAtEnumType = DisplayAttributeType.enumerated;
    const filterUrlTypeService = this.tableFilterUrlControlService.getFilterUrlTypeServiceFrom(FilterType.enum);
    const columnFormatSettings = tableFormatSettings.columns[NonAttributeKeys.ARTIFACT_TYPE_ID] || { columnKey: NonAttributeKeys.ARTIFACT_TYPE_ID };
    const displayAtMetadata = this.getDisplayAtMetadata(displayAtEnumType, columnFormatSettings);

    return new NewTableColumnMetaData({
      filterType: FilterType.enum,
      filterKey: NonAttributeKeys.ARTIFACT_TYPE_ID,
      filterUrlTypeService,
      displayAtEnumType,
      columnFormatSettings,
      displayAtMetadata,
    });
  }

  private getMetaDataForFormatFileColumn(tableFormatSettings: TableFormatSettings): NewTableColumnMetaData {
    const displayAtEnumType = DisplayAttributeType.file;
    const filterUrlTypeService = this.tableFilterUrlControlService.getFilterUrlTypeServiceFrom(FilterType.enum);
    const columnFormatSettings = tableFormatSettings.columns[NonAttributeKeys.FILE_ARTIFACT_PATH_ID] || { columnKey: NonAttributeKeys.FILE_ARTIFACT_PATH_ID };
    const displayAtMetadata = this.getDisplayAtMetadata(displayAtEnumType, columnFormatSettings);

    return new NewTableColumnMetaData({
      filterType: null,
      filterKey: NonAttributeKeys.FILE_ARTIFACT_DTO_PATH,
      filterUrlTypeService,
      displayAtEnumType,
      columnFormatSettings,
      displayAtMetadata,
    });
  }

  private getMetaDataForFolderPathColumn(tableFormatSettings: TableFormatSettings): NewTableColumnMetaData {
    const filterUrlTypeService = this.tableFilterUrlControlService.getFilterUrlTypeServiceFrom(FilterType.folder);
    const columnFormatSettings = tableFormatSettings.columns[NonAttributeKeys.FOLDER_PATH] || { columnKey: NonAttributeKeys.FOLDER_PATH };

    return new NewTableColumnMetaData({
      filterType: FilterType.folder,
      filterKey: NonAttributeKeys.FOLDER_PATH,
      filterUrlTypeService,
      columnFormatSettings,
    });
  }

  private getDisplayAtMetadata(
    attributeType: DisplayAttributeType,
    columnFormatSettings: TableColumnFormatSettings,
    attribute?: NewAttribute,
  ): DisplayAtMetadata {
    return {
      attributeType,
      selectedVariantCode: columnFormatSettings.selectedVariant || this.getDefaultVariantKey(attributeType, attribute),
      selectedEditCode: columnFormatSettings.selectedEditVariant || DEFAULT_VARIANT_KEY,
    };
  }

  private getDefaultVariantKey(attributeType: DisplayAttributeType, attribute?: NewAttribute): string {
    if (attributeType == DisplayAttributeType.user) {
      return attribute?.multipleValues ? 'ICON_ONLY' : 'ICON_AND_NAME';
    }

    return DEFAULT_VARIANT_KEY;
  }
}
