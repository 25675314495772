import { Component, Injector, Input, OnInit } from '@angular/core';
import { AvrWidgetSettingsDto } from '@api/models';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AvrServices } from '@shared/services/artifact-visual-representation/index.avr-services';
import {
  AVR_SETTINGS_INJECTOR,
  AvrSettingsInjectorBody,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/abstract.avr-types-settings.component';
import { AvrTypesSettingsService } from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/index.avr-types-settings';
import { AbstractAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/abstract.avr-types-services.service';
import { AvrWidgetService } from '@widgets/avr-widget/services/avr-widget.service';
import { AvrArtifactSources } from '@widgets/avr-widget/types/avr-widget-settings.types';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';

@Component({
  selector: 'app-avr-widget-settings',
  templateUrl: './avr-widget-settings.component.html',
  styleUrls: ['./avr-widget-settings.component.scss'],
})
export class AvrWidgetSettingsComponent implements OnInit {
  @Input() m: AvrWidgetModel;

  public AvrArtifactSources = AvrArtifactSources;
  public injectorForAvrSettingFields: Injector | null = null;

  constructor(
    public readonly avrTypesSettingsService: AvrTypesSettingsService,
    public readonly avrServices: AvrServices,
    private readonly s: AvrWidgetService,
    private readonly injector: Injector,
  ) {}

  ngOnInit(): void {
    this.injectorForAvrSettingFields = this.getAvrSettingFieldsInjector();
  }

  async onArtifactSourceChange(): Promise<void> {
    await this.s.onArtifactSourceChange();
  }

  async onArtifactTypeChange(): Promise<void> {
    await this.s.onArtifactTypeChange();
  }

  async onSelectedArtifactChange(): Promise<void> {
    await this.s.onSelectedArtifactChange();
  }

  async onArtifactListeningKeyChange(): Promise<void> {
    await this.s.onArtifactListeningKeyChange();
  }

  onAvrTypeChange(): void {
    this.s.onAvrTypeChange();
  }

  isArtifactSource(artifactSource: AvrArtifactSources): boolean {
    return this.s.isArtifactSource(artifactSource);
  }

  isSettingStep(settingStep: number): boolean {
    return this.s.isSettingStep(settingStep);
  }

  private getAvrSettingFieldsInjector(value?: AvrWidgetSettingsDto): Injector {
    return Injector.create({
      providers: [
        {
          provide: AVR_SETTINGS_INJECTOR,
          useValue: value
            ? value
            : ({
                model: this.m,
                avrTypeServices: Object.values(AvrTypes).reduce(
                  (output, avrType) => {
                    output[avrType] = this.s.avrTypesServices.getAvrTypesServices(avrType) as AbstractAvrTypeService<AvrTypes>;
                    return output;
                  },
                  {} as Record<AvrTypes, AbstractAvrTypeService<AvrTypes>>,
                ),
              } as AvrSettingsInjectorBody),
        },
      ],
      parent: this.injector,
    });
  }
}
