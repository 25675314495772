import { Injectable } from '@angular/core';
import { NewArtifact } from '@shared/types/artifact.types';
import { RuleConditionsCheckerService } from '..';
import { WorkflowAction, WorkflowRule, WorkflowTriggerEvent } from '../types';

@Injectable({ providedIn: 'root' })
export class RuleActionExecutorService {
  constructor(private readonly ruleConditionsChecker: RuleConditionsCheckerService) {}

  executeAction(action: WorkflowAction, triggerEvent?: WorkflowTriggerEvent) {
    if (action.canBeExecuted(triggerEvent)) {
      action.execute(triggerEvent);
    }
  }

  executeActions(rule: WorkflowRule, triggerEvent?: WorkflowTriggerEvent) {
    const containsActionWithCondition = this.containsActionWithCondition(rule);
    if (!containsActionWithCondition) {
      rule.actions.forEach(action => this.executeAction(action, triggerEvent));
      return;
    }
    // actions with conditions
    let actionsExecuted = 0;
    const resetAction = this.getResetAction(rule);
    if (this.isMultiArtifactTrigger(triggerEvent)) {
      this.executeActionForMultiArtifactTrigger(rule, triggerEvent!, resetAction);
      return;
    }
    const actions = this.getExecutableActions(rule, triggerEvent);
    actions.forEach(action => {
      const conditionsMet = !action.condition || this.ruleConditionsChecker.areConditionsMet(action.condition, triggerEvent);
      if (conditionsMet) {
        action.execute(triggerEvent);
        actionsExecuted++;
      }
    });
    this.doCheckAndHandleResetAction(actionsExecuted, triggerEvent!, resetAction);
  }

  private executeActionForMultiArtifactTrigger(rule: WorkflowRule, triggerEvent: WorkflowTriggerEvent, resetAction?: WorkflowAction) {
    const artifacts = triggerEvent.payload!.artifacts!;
    const actions = this.getExecutableActions(rule, triggerEvent);
    artifacts.forEach(artifact => {
      let actionsExecuted = 0;
      actions.forEach(action => {
        const conditionsMet = !action.condition || this.ruleConditionsChecker.areConditionsMet(action.condition, triggerEvent, artifact);
        if (conditionsMet) {
          action.execute(triggerEvent, artifact);
          actionsExecuted++;
        }
      });
      this.doCheckAndHandleResetAction(actionsExecuted, triggerEvent, resetAction, artifact);
    });
  }

  private getExecutableActions(rule: WorkflowRule, triggerEvent?: WorkflowTriggerEvent): WorkflowAction[] {
    return rule.actions.filter(action => !this.isResetAction(action) && action.canBeExecuted(triggerEvent));
  }

  private doCheckAndHandleResetAction(actionsExecuted: number, triggerEvent: WorkflowTriggerEvent, resetAction?: WorkflowAction, artifact?: NewArtifact) {
    if (actionsExecuted === 0 && resetAction && resetAction.canBeExecuted(triggerEvent)) {
      resetAction.execute(triggerEvent, artifact);
    }
  }

  private containsActionWithCondition(rule: WorkflowRule): boolean {
    return !!rule.actions.find(a => !!a.condition);
  }

  private getResetAction(rule: WorkflowRule): WorkflowAction | undefined {
    return rule.actions.find(action => this.isResetAction(action));
  }

  private isResetAction(action: WorkflowAction): boolean {
    return action.type === 'RESET';
  }

  private isMultiArtifactTrigger(triggerEvent?: WorkflowTriggerEvent): boolean {
    return !!triggerEvent?.payload?.artifacts?.length;
  }
}
