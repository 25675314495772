import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Background,
  Border,
  BoxShadow,
  ContainerClass,
  Dimension,
  Margin,
  Overflow,
  Padding,
} from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { UpdatedStylesNotifierService } from '@shared/services/updated-styles-notifier.service';
import { BackgroundTypeEnum, StyleOptions } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-page-element-styler',
  templateUrl: './page-element-styler.component.html',
  styleUrls: ['./page-element-styler.component.scss'],
})
export class PageElementStylerComponent {
  @Input() applicationId: string;
  @Input() styles: ContainerClass & Dimension & Border & BoxShadow & Background & Margin & Padding & Overflow;
  @Input() element: HTMLElement;
  @Input() isContainerClassEditable = false;
  @Input() isMarginEditable = true;
  @Input() isShowOverflow = true;
  @Input() isShowBorderRadius = true;
  @Input() showHeightSettings = false;
  @Output() onChange = new EventEmitter();

  readonly backgroundType: typeof BackgroundTypeEnum = BackgroundTypeEnum;
  readonly styleOptions: StyleOptions = new StyleOptions();

  constructor(private updatedStylesNotifierService: UpdatedStylesNotifierService) {}

  onBgTypeChange(): void {
    delete this.styles.background;
    this.styles.backgroundImage = '';
    this.styles.backgroundColor = '';
  }

  onColorChange(): void {
    this.onChange.emit();
  }

  onBackgroundFileIdChange(id: string) {
    this.styles.backgroundImage = id;
    this.updatedStylesNotifierService.notifyUpdate();
  }

  onBackgroundFileUrlChange(url: string) {
    this.styles.backgroundImage = `url(${url})`;
    this.updatedStylesNotifierService.notifyUpdate();
  }

  onStylesChange(_styles: any) {
    this.styles.backgroundRepeat = _styles.backgroundRepeat;
    this.styles.backgroundSize = _styles.backgroundSize;
    this.styles.backgroundPosition = _styles.backgroundPosition;
    this.styles.backgroundAttachment = _styles.backgroundAttachment;
    this.styles.backgroundOpacity = _styles.backgroundOpacity;
    this.updatedStylesNotifierService.notifyUpdate();
  }
}
