import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactWidgetType } from '../types/artifact-widget.types';

@Pipe({
  name: 'showFixedArtifactButtons',
  standalone: true,
})
export class ShowFixedArtifactButtonsPipe implements PipeTransform {
  transform(widgetType: ArtifactWidgetType): boolean {
    return widgetType === ArtifactWidgetType.login || widgetType === ArtifactWidgetType.registration || widgetType === ArtifactWidgetType.recoverPassword;
  }
}
