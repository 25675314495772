import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { FilterByArtifactTypePipe } from '@widgets/list-matrix-widget/pipes/filter-attributes-by-artifact-type.pipe';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import { ArtifactSortsModule } from '@widgets/shared/components/artifact-sorts/artifact-sorts.module';
import { ListItemClickActionSettingsFormModule } from '@widgets/shared/components/list-item-click-action-settings-form/list-item-click-action-settings-form.module';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ListMatrixWidgetComponent } from './list-matrix-widget.component';
import { CloneDeepPipe } from './pipes/clone-deep.pipe';
import { TransposePipe } from './pipes/transpose.pipe';
import { IsClickablePipe } from '@widgets/shared/pipes/is-clickable-by-actions.pipe';

@NgModule({
  declarations: [ListMatrixWidgetComponent, FilterByArtifactTypePipe, CloneDeepPipe, TransposePipe],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ElvisSharedModule,
    WidgetContainerModule,
    CheckboxModule,
    DataViewModule,
    DropdownModule,
    InputTextModule,
    AccordionModule,
    MultiSelectModule,
    CardModule,
    ButtonModule,
    RippleModule,
    InputNumberModule,
    PaginatorModule,
    ListItemClickActionSettingsFormModule,
    ArtifactFiltersModule,
    ArtifactSortsModule,
    LoaderModule,
    SelectButtonModule,
    IsClickablePipe,
  ],
  exports: [ListMatrixWidgetComponent],
})
export class ListMatrixWidgetModule {}
