@if (service.m.currentArtifactTypeOption && init$ | async) {
  <div class="col-12 p-0 d-flex flex-wrap">
    <div class="col-6">
      <div class="dynamic-label">
        <p-dropdown
          (onChange)="onOutputTypeChange()"
          [options]="outputTypesOptions"
          [(ngModel)]="settingFields.outputType"
          [autoDisplayFirst]="false"
          appendTo="body"
          inputId="avrDocumentGenerationOutputType"
        >
        </p-dropdown>
        <label [for]="'avrDocumentGenerationOutputType'" class="dynamic-label-text">{{ 'Output type' | translate }}</label>
      </div>
    </div>

    <div class="col-6">
      <div class="dynamic-label">
        <p-dropdown
          (onChange)="onTemplateArtifactSourceChange()"
          [options]="templateArtifactSourcesOptions"
          [(ngModel)]="settingFields!.templateFileArtifactSource"
          [autoDisplayFirst]="false"
          appendTo="body"
          inputId="avrDocumentGenerationTemplateFileArtifactSource"
        >
          <ng-template let-option pTemplate="item">
            <ng-container *ngTemplateOutlet="artifactSourceOption; context: { $implicit: option }"></ng-container>
          </ng-template>
          <ng-template let-selected pTemplate="selectedItem">
            <ng-container *ngTemplateOutlet="artifactSourceOption; context: { $implicit: selected }"></ng-container>
          </ng-template>

          <ng-template #artifactSourceOption let-option>
            {{ option.label | translate }}
          </ng-template>
        </p-dropdown>
        <label [for]="'avrDocumentGenerationTemplateFileArtifactSource'" class="dynamic-label-text">{{ 'Template file artifact source' | translate }}</label>
      </div>
    </div>

    @if (settingFields!.templateFileArtifactSource === AvrTemplateFileArtifactSources.staticArtifact) {
      <div class="col-6">
        <div class="dynamic-label">
          <p-dropdown
            (onChange)="onStaticArtifactChange()"
            [options]="artifactOptions"
            [(ngModel)]="settingFields!.templateFileArtifactId"
            [autoDisplayFirst]="false"
            appendTo="body"
            inputId="avrDocumentGenerationTemplateStaticFileArtifact"
            optionValue="value.id"
          >
            <ng-template let-option pTemplate="item">
              <ng-container *ngTemplateOutlet="templateArtifactOption; context: { $implicit: option }"></ng-container>
            </ng-template>
            <ng-template let-selected pTemplate="selectedItem">
              <ng-container *ngTemplateOutlet="templateArtifactOption; context: { $implicit: selected }"></ng-container>
            </ng-template>

            <ng-template #templateArtifactOption let-option>
              {{ option.label | translate }}
            </ng-template>
          </p-dropdown>
          <label [for]="'avrDocumentGenerationTemplateStaticFileArtifact'" class="dynamic-label-text">{{ 'Static template file artifact' | translate }}</label>
        </div>
      </div>
    }

    @if (settingFields!.templateFileArtifactSource === AvrTemplateFileArtifactSources.dynamicArtifact) {
      <div class="col-6">
        <div class="dynamic-label">
          <input
            (input)="onTemplateArtifactListeningKeyChange()"
            [(ngModel)]="settingFields!.templateFileArtifactListeningKey"
            id="avrDocumentGenerationTemplateDynamicListeningKey"
            pInputText
            type="text"
            ngClass="full-width-container"
          />
          <label [for]="'avrDocumentGenerationTemplateDynamicListeningKey'" class="dynamic-label-text">{{
            'URL parameter key for template artifact id' | translate
          }}</label>
        </div>
      </div>
    }
  </div>
}
