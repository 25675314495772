import { Pipe, PipeTransform } from '@angular/core';
import { ActionType } from '@widgets/shared/types/click-action-settings-dto';
import { ArtifactListItemClickAction } from '../../../types/artifact-list-item-click-action';

@Pipe({ name: 'isListWidgetSpecial' })
export class IsListWidgetSpecialPipe implements PipeTransform {
  transform(actionType: ActionType): boolean {
    return Object.keys(ArtifactListItemClickAction).includes(actionType);
  }
}
