import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
})
export class UserFilterComponent {
  @Input() m: ArtifactFilter;
  @Output() onFilterChange: EventEmitter<void> = new EventEmitter<void>();

  onChange(): void {
    this.onFilterChange.emit();
  }
}
