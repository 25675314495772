import { FetchDataImapActionWorkflowSettingsDto } from '@api/models/fetch-data-imap-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { ConvertToServerDatetime, IsDateValid } from '@shared/methods/date.methods';
import { BooleanValueFilterEnum } from '@shared/types/filter.types';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { FilterValueDataType, ImapFilter, ImapFilterDataType, ImapFilterKeyEnum, ImapFilterKeyWithDataType } from '@workflows/shared/types/imap-filters.types';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum FetchDataImapKeys {
  scheme = 'scheme',
  host = 'host',
  auth = 'auth',
  mailboxName = 'mailboxName',
  downloadAttachments = 'downloadAttachments',
  filters = 'filters',
  storeResultsPath = 'storeResultsPath',
}

export enum ImapRequestSchemesEnum {
  imap = 'IMAP',
  imaps = 'IMAPS',
}

export class WorkflowActionFetchDataImapDto implements FetchDataImapActionWorkflowSettingsDto {
  scheme: FetchDataImapActionWorkflowSettingsDto[FetchDataImapKeys.scheme] = { value: ImapRequestSchemesEnum.imap, isDynamic: false };
  host: FetchDataImapActionWorkflowSettingsDto[FetchDataImapKeys.host] = { value: '', isDynamic: false };
  auth: FetchDataImapActionWorkflowSettingsDto[FetchDataImapKeys.auth] = {
    value: { session_id: null, plain_user: '', plain_pass: '' },
    isDynamic: false,
  };
  mailboxName: FetchDataImapActionWorkflowSettingsDto[FetchDataImapKeys.mailboxName] = { value: 'INBOX', isDynamic: false };
  downloadAttachments: FetchDataImapActionWorkflowSettingsDto[FetchDataImapKeys.downloadAttachments] = { value: 'false', isDynamic: false };
  filters: FetchDataImapActionWorkflowSettingsDto[FetchDataImapKeys.filters] = { value: {}, isDynamic: false };
  storeResultsPath: FetchDataImapActionWorkflowSettingsDto[FetchDataImapKeys.storeResultsPath] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionFetchDataImapDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionFetchDataImap extends AbstractWorkflowAction<WorkflowActionFetchDataImapDto> {
  downloadAttachments = false;
  imapFilters: ImapFilter[] = [];

  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionFetchDataImapDto, conditionConverter, type: WorkflowActionType.FETCH_DATA_IMAP, dto });
    dto && this.fromDto(dto);
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  fromDto(dto: WorkflowActionDto) {
    if (dto) {
      Object.assign(this, dto);

      const filters = (dto.actionSettings as FetchDataImapActionWorkflowSettingsDto).filters.value;
      this.imapFilters = Object.entries(filters).map(([filterKey, filterValue]) =>
        this.filterToClient(filterKey as ImapFilterKeyEnum, filterValue, (dto.actionSettings as FetchDataImapActionWorkflowSettingsDto).filters.isDynamic),
      );
      this.downloadAttachments = (dto.actionSettings as FetchDataImapActionWorkflowSettingsDto).downloadAttachments.value === 'true';
    }
  }

  toServer(): WorkflowActionDto {
    const isDynamic = this.actionSettings.filters.isDynamic;
    const filters = this.imapFilters.reduce(
      (filterObject, imapFilter) => ({
        ...filterObject,
        [imapFilter.key]: this.filterValueToServer(imapFilter, isDynamic),
      }),
      {},
    );
    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [FetchDataImapKeys.downloadAttachments]: { isDynamic: false, value: this.downloadAttachments ? 'true' : 'false' },
        [FetchDataImapKeys.scheme]: { isDynamic: false, value: this.actionSettings.scheme.value },
        [FetchDataImapKeys.mailboxName]: { isDynamic: false, value: this.actionSettings.mailboxName.value },
        [FetchDataImapKeys.filters]: { isDynamic: this.actionSettings.filters.isDynamic, value: filters },
      },
    };
  }

  private filterToClient(key: ImapFilterKeyEnum, filterValue: string, isDynamic: boolean): ImapFilter {
    const dataType: ImapFilterDataType = ImapFilterKeyWithDataType[key];

    let value: FilterValueDataType | null = filterValue;

    if (!isDynamic) {
      if (dataType === ImapFilterDataType.boolean) {
        value = value === BooleanValueFilterEnum.TRUE;
      } else if (dataType === ImapFilterDataType.number) {
        value = parseInt(value) || null;
      } else if (dataType === ImapFilterDataType.date) {
        value = IsDateValid(new Date(value)) ? new Date(value) : null;
      }
    }

    return new ImapFilter({ key, value, dataType });
  }

  private filterValueToServer(filter: ImapFilter, isDynamic: boolean): any {
    if (isDynamic) {
      return filter.value;
    }

    switch (filter.dataType) {
      case ImapFilterDataType.boolean:
        return filter.value?.toString() || BooleanValueFilterEnum.FALSE;
      case ImapFilterDataType.number:
        return filter.value?.toString();
      case ImapFilterDataType.date:
        return ConvertToServerDatetime(filter.value as Date);
    }

    return filter.value;
  }
}
