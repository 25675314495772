import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgModule, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactResponseDto } from '@api/models';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { NewAttribute, NewClientAttribute } from '../../types/attribute.types';
import { UploadProgressModule } from '@widgets/shared/components/upload-progress/upload-progress.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from 'primeng/badge';
import { TranslateModule } from '@ngx-translate/core';
import { AttributePipesModule } from '@shared/pipes/attribute-pipes/attribute-pipes.module';
import { PaginatorModule } from 'primeng/paginator';
import {
  AbstractDisplayAtComponent
} from '@shared/components/common-display-at/components/abstract-display-at.component';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { DisplayAtControlService } from '@shared/components/common-display-at';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import {
  RuntimeStateNotification,
  RuntimeStateNotificationEnum,
} from '@widgets/shared/types/runtime-state-notification.types';
import { Subscription } from 'rxjs';
import { DisplayAtSelectionItem } from '@shared/types/display-at-types';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { FileAttributeNumberOfFilesPipe } from '@shared/components/file-attribute/pipes/file-attribute-number-of-files.pipe';
import { FileAttributeSelectIconPipe } from '@shared/components/file-attribute/pipes/file-attribute-select-icon.pipe';

@Component({
  selector: 'app-file-attribute',
  templateUrl: './file-attribute.component.html',
  styleUrls: ['./file-attribute.component.scss'],
})
export class FileAttributeComponent extends AbstractDisplayAtComponent {
  @Input() clientAttribute: NewClientAttribute;
  @Input() attributesMap: Record<string, NewAttribute>;
  @Input() files: Record<string, ArtifactResponseDto>;
  @Input() unSavedFiles: Record<string, Record<string, File[]>>;
  @Input() artifactId: string;
  @Input() truncate: boolean;
  @Input() index: number;
  @Input() isEditMode: boolean;
  @Input() onChangeCb: (value: string | string[]) => void;
  @Input() isInSavingProcess: boolean;
  @Input() valueStyles: FormatStyles;
  @Input() summaryPopupIcon!: string | undefined;

  @Output() valueFilledChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('fileUpload') fileUpload: FileUpload;

  currentPage = 0;
  showAllFiles = true;
  visibleFilesCount = 5;
  private eventsSubscription: Subscription;

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly cdr: ChangeDetectorRef,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    super(isPreviewMode, displayAtControlService, dataTypeHelper, cdr);
    this.init();
  }

  private init(): void {
    this.eventsSubscription = this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<any>) => {
      if (event.type === RuntimeStateNotificationEnum.updateColumnStyles) {
        event?.data?.key === this.attributeId && this.cdr.markForCheck();
      }
    });
  }

  protected onDisplayAtSelectionChange(selection: DisplayAtSelectionItem) {
    super.onDisplayAtSelectionChange(selection);
    this.currentPage = 0;
    this.showAllFiles = this.selectedVariant === 'DEFAULT';
  }

  ngOnDestroy(): void {
    this.eventsSubscription && this.eventsSubscription.unsubscribe();
  }

  uploadHandler(event: { files: File[] }): void {
    setTimeout(() => {
      if (!event.files.length) return;

      const filesArray = this.fileUpload.multiple ? event.files : [event.files[0]];
      this.processFilesToModel(filesArray);

      /* work-around to be approved on validation step for mandatory value before the files will be uploaded to server physically */
      if(!this.fileUpload.multiple && filesArray.length === 1) this.clientAttribute.valueFilled = true;
      if(this.fileUpload.multiple && filesArray.length > 0) this.clientAttribute.valueFilled = true;
      this.valueFilledChange.emit(this.clientAttribute.valueFilled);

      this.fileUpload.clear();
      this.onChange();
    });
  }

  deleteFile(event: MouseEvent, fileId?: string, unsavedFile?: File): void {
    event.stopPropagation();

    const savedFile = fileId ? this.files[fileId] : this.files[this.clientAttribute.value];

    savedFile && this.removeFileFromSavedFiles(savedFile);
    this.removeFileFromUnsavedFiles(unsavedFile);

    this.clientAttribute = { ...this.clientAttribute };

    this.onChange();
  }

  removeFileFromSavedFiles(file: ArtifactResponseDto): void {
    const oldValueFilled = this.clientAttribute.valueFilled;
    if (this.attributesMap[this.clientAttribute?.id]?.multipleValues) {
      this.clientAttribute.value = this.clientAttribute.value.filter((id: string) => id !== file.id);
      if(Array.isArray(this.clientAttribute.value) && this.clientAttribute.value.length > 0) this.clientAttribute.valueFilled = false;
      else this.clientAttribute.valueFilled = true;
    }
    else {
      this.clientAttribute.value = null;
      this.clientAttribute.valueFilled = false;
    }
    if(oldValueFilled != this.clientAttribute.valueFilled) this.valueFilledChange.emit(this.clientAttribute.valueFilled);
  }

  removeFileFromUnsavedFiles(file?: File): void {
    if (this.unSavedFiles[this.artifactId] && this.unSavedFiles[this.artifactId][this.clientAttribute.id]) {
      this.unSavedFiles[this.artifactId][this.clientAttribute.id] = this.unSavedFiles[this.artifactId][this.clientAttribute.id].filter(f => f !== file);
    }
  }

  private processFilesToModel(files: File[]): void {
    if (!this.unSavedFiles[this.artifactId]) this.unSavedFiles[this.artifactId] = {};
    if (!this.unSavedFiles[this.artifactId][this.clientAttribute.id]) this.unSavedFiles[this.artifactId][this.clientAttribute.id] = [];
    this.unSavedFiles[this.artifactId][this.clientAttribute.id] = this.fileUpload.multiple
      ? [...this.unSavedFiles[this.artifactId][this.clientAttribute.id], ...files]
      : files;

    // change detection in pipe
    this.unSavedFiles = { ...this.unSavedFiles };
  }

  private onChange(): void {
    this.onChangeCb && this.onChangeCb(this.clientAttribute.value);
  }

  getVisibleFiles(files: any): any {
    if (!files) {
      return [];
    }
    const start = this.currentPage * this.visibleFilesCount;
    const end = start + this.visibleFilesCount;
    return this.showAllFiles ? files : files.slice(start, (end > files.length)? files.length : end);
  }

  paginate(pageChangeEvent: any) {
    this.currentPage = pageChangeEvent.page;
  }

  protected readonly JSON = JSON;
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    FileUploadModule,
    ElvisSharedModule,
    UploadProgressModule,
    OverlayPanelModule,
    BadgeModule,
    TranslateModule,
    AttributePipesModule,
    PaginatorModule,
  ],
  exports: [FileAttributeComponent],
  declarations: [
    FileAttributeComponent,
    FileAttributeNumberOfFilesPipe,
    FileAttributeSelectIconPipe,
  ],
})
export class FileAttributeModule {}
