import { EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ID_KEY, URL_KEY_VALUE_ARTIFACT_ID } from '@shared/constants/constants';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { AttributeSettings } from '@widgets/card-widget/types/attribute-settings';
import { CardWidgetMode } from '@widgets/card-widget/types/card-widget-mode';
import { CardWidgetModelOptions } from '@widgets/card-widget/types/card-widget-model';
import { EnumOptionSettings } from '@widgets/card-widget/types/enum-option-settings';
import { ArtifactFilter, ArtifactFilterDto } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';
import { ClickActionSettings } from '@widgets/shared/types/click-action-settings';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { cloneDeep } from 'lodash';
import { CardWidgetSettingsDto } from './card-widget-settings-dto';
import { DisplayAttributeType } from '@shared/types/display-at-types';
import { ActionItem, ActionItemDto } from '@widgets/menu-widget/types/menu-action-types';

interface Options {
  attributes: ListContainer<NewAttribute>;
  dataTypes: ListContainer<NewDataType>;
}

const INITIAL_SETTINGS: CardWidgetSettingsDto = {
  widgetMode: CardWidgetMode.staticArtifact,
  artifactTypeId: '',
  selectedArtifactId: '',
  listeningKey: URL_KEY_VALUE_ARTIFACT_ID,
  attributeStyles: {},
  filtersForQueryParams: [],
  actions: [],
  responsiveness: false,
};

export class CardWidgetSettings {
  widgetMode: CardWidgetMode = INITIAL_SETTINGS.widgetMode;
  artifactTypeId = INITIAL_SETTINGS.artifactTypeId;
  selectedArtifactId = INITIAL_SETTINGS.selectedArtifactId;
  listeningKey = INITIAL_SETTINGS.listeningKey;
  attributeStyles: {
    [attributeId: string]: AttributeSettings;
  } = cloneDeep(INITIAL_SETTINGS.attributeStyles);
  filtersForQueryParams: ArtifactFilter[] = [];
  actions: ActionItem[] = [new ActionItem()];
  responsiveness = INITIAL_SETTINGS.responsiveness;

  private constructor(dto: CardWidgetSettingsDto) {
    Object.assign(this, dto);

    /* initialize selectedVariantCode for system file attribute only */
    if (this.attributeStyles?.[NonAttributeKeys.FILE_ARTIFACT_PATH_ID]?.settings?.value) {
      this.attributeStyles[NonAttributeKeys.FILE_ARTIFACT_PATH_ID].settings.value.displayMetadata ??= {
        attributeType: DisplayAttributeType.file,
        selectedVariantCode: 'DEFAULT',
      };
    }
  }

  get isArtifactTypeSelected(): boolean {
    return !!this.artifactTypeId;
  }

  static initial(): CardWidgetSettings {
    const settings = new CardWidgetSettings(cloneDeep(INITIAL_SETTINGS));
    settings.actions = [new ActionItem()];
    return settings;
  }

  static fromDtoAndOptions(dto: CardWidgetSettingsDto, options: CardWidgetModelOptions): CardWidgetSettings {
    const clickActionSettingsOptions: ClickActionSettingsOptions = {
      attributes: [...options.attributes.list, ...options.systemAttributes.list],
      dataTypes: options.dataTypes.list,
      pages: options.pages.toSelectOptions('name', ID_KEY),
    };

    const settings = new CardWidgetSettings(dto);

    dto.actions?.length && (settings.actions = dto.actions.map(action => new ActionItem(action as ActionItemDto)));
    dto?.filtersForQueryParams && (settings.filtersForQueryParams = dto.filtersForQueryParams.map((dto: ArtifactFilterDto) => ArtifactFilter.fromDtoAndOptions(dto, clickActionSettingsOptions) || []));

    settings.attributeStyles = CardWidgetSettings.getAttributeStylesWithEnumAttributeSettings(settings.attributeStyles, options);

    return settings;
  }

  private static getAttributeStylesWithEnumAttributeSettings(
    attributeStyles: { [attributeId: string]: AttributeSettings },
    { attributes, dataTypes }: Options,
  ): { [attributeId: string]: AttributeSettings } {
    return Object.entries(attributeStyles).reduce(
      (
        acc: {
          [attributeId: string]: AttributeSettings;
        },
        [attributeId, settings]: [string, AttributeSettings],
      ) => {
        const attribute = attributes.listMap[attributeId];
        const dataType = attribute ? dataTypes.listMap[attribute.dataTypeId] : null;

        settings.enumAttributeSettings = dataType?.isEnum
          ? dataType.values!.reduce(
              (
                enumOptionSettings: {
                  [enumValue: string]: EnumOptionSettings;
                },
                option: EnumeratedOption,
              ) => {
                // for correct set enum colors need not present color property in styles
                const enumSettings: any = settings.enumAttributeSettings[option.value];

                return {
                  ...enumOptionSettings,
                  [option.value]: enumSettings || {
                    isIconVisible: true,
                    styles: {} as any,
                  },
                };
              },
              {},
            )
          : settings.enumAttributeSettings;

        return { ...acc, [attributeId]: settings };
      },
      {},
    );
  }

  toServer(): CardWidgetSettingsDto {
    return {
      ...this,
      actions: this.actions.map(action => action.toServer()) as ActionItemDto[] || [],
      filtersForQueryParams: this.filtersForQueryParams?.map((filter: ArtifactFilter) => filter.toServer() || []),
    };
  }
}
