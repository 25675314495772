import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'extractUrl' })
export class InternalImageExtractUrlPipe implements PipeTransform {

  transform(value: string): string {
    const match = value.match(/url\(["']?(.*?)["']?\)/);
    return match ? match[1] : value;
  }
}
