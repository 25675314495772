import { Directive, Input } from '@angular/core';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AvrBlobType } from '@widgets/avr-widget/types/avr-widget.types';

@Directive()
export abstract class AbstractAvrRenderFileTypeComponent {
  @Input() set file(file: AvrBlobType | null) {
    this.onSetFile(file);
  }
  @Input() avrType: AvrTypes | null = null;

  abstract onSetFile(file: AvrBlobType | null): void;
}
