<div [ngSwitch]="action.type" class="col-12 flex flex-column">
    <div *ngSwitchCase="ArtifactListItemClickAction.addToLink" class="grid p-0">
        <div class="col-1">
            <span class="dynamic-label dynamic-checkbox pt-3">
                <span class="dynamic-checkbox-box">
                    <p-checkbox
                        [(ngModel)]="action.shouldEmitKey"
                        binary="true"
                        id="shouldEmitKey"
                        inputId="shouldEmitKey"
                    ></p-checkbox>
                </span>
            </span>
        </div>
        <div class="col-11">
            <span class="dynamic-label">
                <label for="urlKey">{{ 'Key for changing artifact url on item click' | translate }}</label>
                <input
                    [(ngModel)]="action.emittingKey"
                    id="urlKey"
                    pInputText
                    placeholder="'artifactId' url key"
                    type="text"
                />
            </span>
        </div>

        <div class="col-1">
            <span class="dynamic-label dynamic-checkbox pt-3">
                <span class="dynamic-checkbox-box">
                    <p-checkbox
                        [(ngModel)]="action.shouldEmitFolderKey"
                        binary="true"
                        id="ShouldEmitFolderUrlKey"
                        inputId="ShouldEmitFolderUrlKey"
                    ></p-checkbox>
                </span>
            </span>
        </div>
        <div class="col-11">
            <span class="dynamic-label">
                <label for="urlKey">{{ 'Key for changing folder url on item click' | translate }}</label>
                <input
                        [(ngModel)]="action.folderKey"
                        id="urlKey"
                        pInputText
                        placeholder="'folderId' url key"
                        type="text"
                />
            </span>
        </div>
    </div>
</div>
