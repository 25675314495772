import { Component, Input } from '@angular/core';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { ArtifactListItemClickAction } from '@widgets/shared/types/artifact-list-item-click-action';

@Component({
  selector: 'app-matrix-widget-actions',
  templateUrl: './matrix-widget-actions.component.html',
  styleUrl: './matrix-widget-actions.component.scss'
})
export class MatrixWidgetActionsComponent {
  @Input() action: ActionItem;

  protected readonly ArtifactListItemClickAction: typeof ArtifactListItemClickAction = ArtifactListItemClickAction;
}
