import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';

const getUrlFromImageFileId = (imageFileId: string, rootUrl: string, token?: string): string => {
  if (!imageFileId) return '';

  let imageUrl = (rootUrl + TenantArtifactService.ArtifactControllerDownloadPath).replace('{id}', imageFileId);
  if (token) imageUrl = imageUrl + '?authorization=Bearer%20' + token;

  return imageUrl;
};

const checkIsExternalUrl = (value: string | null) => {
  if (!value) return false;
  const urlPattern = /^[a-f0-9]{24}$/i;
  if (urlPattern.test(value)) return false;
  return true;
};

const sanitizeImageUrl = (imageUrl: string, sanitizer: DomSanitizer): SafeUrl => {
  return imageUrl ? sanitizer.bypassSecurityTrustUrl(imageUrl) : '';
};

const convertToUrlWithAlpha = (value: string, alpha?: string) => {
  let url = value;
  if (alpha) {
    const alphaNum = parseFloat(alpha);
    if (alphaNum >= 0 && alphaNum <= 1) {
      url = `linear-gradient(rgba(0, 0, 0, ${alpha}), rgba(0, 0, 0, ${alpha})), ${url}`;
    }
  }
  return url;
};

export const ImageMethods = {
  getUrlFromImageFileId,
  checkIsExternalUrl,
  sanitizeImageUrl,
  convertToUrlWithAlpha,
};
