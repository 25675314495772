import { ArtifactWidgetEventResponse, WidgetEventResponseSettings, WidgetEventResponseSettingsDto } from '../../shared/types/custom-event';

export class ArtifactWidgetEventResponseSettings extends WidgetEventResponseSettings<ArtifactWidgetEventResponse> {
  events: ArtifactWidgetEventResponse[] = [];

  constructor(dto?: ArtifactWidgetEventResponseSettingsDto) {
    super(dto);
    this.fromDto(dto);
  }

  addEvent(): void {
    this.events.push(new ArtifactWidgetEventResponse());
  }
}

export interface ArtifactWidgetEventResponseSettingsDto extends WidgetEventResponseSettingsDto<ArtifactWidgetEventResponse> {}
