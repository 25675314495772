import { AvrWidgetModelDto as _DTO } from '@api/models';
import { AvrOutputTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { SelectOption } from '@shared/types/shared.types';
import { cloneDeep } from 'lodash';
import { WidgetModel } from '../../shared/types/widget-model.interface';
import { AvrWidgetOptions } from './avr-widget-options.types';
import { AvrWidgetSettings } from './avr-widget-settings.types';

export type AvrBlobType = { file: Blob; fileName: string };

export class AvrWidgetValue {
  constructor(public model: AvrWidgetModel = new AvrWidgetModel()) {}
}

export class AvrWidgetModel implements WidgetModel<_DTO> {
  // RUNTIME properties
  options = new AvrWidgetOptions();
  currentArtifactTypeOption: SelectOption<string, NewArtifactType, any> | null = null;
  currentArtifact: NewArtifact | null = null;
  initModel: AvrWidgetModel; // used during RESET phase, no need for init model specifically for every reset
  settingsStep = 0;
  currentAvrFileType: AvrOutputTypes | null = null;
  avrBlob: AvrBlobType | null = null;
  avrBlobError = '';

  // DTO properties
  settings = new AvrWidgetSettings();

  constructor(dto?: _DTO) {
    this.initModel = cloneDeep(this);
    if (dto) {
      this.fromDto(dto);
    }

    this.initSettingsStep();
  }

  fromDto(dto: _DTO): void {
    Object.assign(this, dto);
  }

  toServer(): _DTO {
    return { settings: this.settings };
  }

  private initSettingsStep(): void {
    if (this.settings.dataSource.artifactSource) this.settingsStep = 1;
    else return;
    if (this.settings.dataSource.artifactTypeId) this.settingsStep = 2;
    else return;
    if (this.settings.response.avrType) this.settingsStep = 3;
    else return;
  }
}
