import { Pipe, PipeTransform } from '@angular/core';
import { IsWorkflowActionBE } from '@workflows/shared/methods/workflows.methods';
import { WorkflowAction, WorkflowType } from '../types';

@Pipe({
  name: 'allowOnlyManualConditions',
  standalone: true,
})
export class AllowOnlyManualConditionsPipe implements PipeTransform {
  transform(ruleType: WorkflowType, action?: WorkflowAction): boolean {
    if (action) {
      return IsWorkflowActionBE(action);
    }

    return ruleType === WorkflowType.be;
  }
}
