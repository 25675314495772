import { CreateArtifactsActionWorkflowSettingsDto } from '@api/models/create-artifacts-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { IsCounter } from '@shared/methods/data-type.methods';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { WorkflowActionType } from '@workflows/types';
import { WorkflowActionDeleteArtifactsDto } from '@workflows/types/actions/action-delete-artifacts';
import { cloneDeep, isArray } from 'lodash';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export const COUNTER_UNSET_VALUE = 'COUNTER_UNSET_VALUE';
export const PARSER_GET_NULL = '{(getNull)}';

export enum CreateArtifactsKeys {
  forEachPath = 'forEachPath',
  artifactTypeId = 'artifactTypeId',
  attributesMap = 'attributesMap',
  storeResultsPath = 'storeResultsPath',
  parentFolderId = 'parentFolderId',
  filePath = 'filePath',
}

export type WorkflowCreateAttributeMapValue = string | string[] | null | boolean;
export type WorkflowCreateAttributeMap = Record<string, WorkflowCreateAttributeMapValue>;

export class WorkflowActionCreateArtifactsDto implements CreateArtifactsActionWorkflowSettingsDto {
  forEachPath: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.forEachPath] = { value: '', isDynamic: false };
  artifactTypeId: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.artifactTypeId] = { value: '', isDynamic: false };
  attributesMap: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.attributesMap] = { value: {}, isDynamic: false };
  storeResultsPath: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.storeResultsPath] = { value: '', isDynamic: false };
  parentFolderId: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.parentFolderId] = { value: '', isDynamic: false };
  filePath: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.filePath] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionDeleteArtifactsDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionCreateArtifacts extends AbstractWorkflowAction<WorkflowActionCreateArtifactsDto> {
  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto, cache?: NewCacheService) {
    super({ actionSettingDto: WorkflowActionCreateArtifactsDto, conditionConverter, type: WorkflowActionType.CREATE_ARTIFACTS, dto, cache });
    dto && this.fromDto(dto);
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  fromDto(dto: WorkflowActionDto) {
    if (dto) {
      Object.assign(this, dto);

      const attributesMap = this.actionSettings.attributesMap.value;
      Object.keys(attributesMap).forEach(key => {
        const attribute = this.options!.attributes.listMap[key];
        const dataType = this.options!.dataTypes.listMap[attribute.dataTypeId];

        if (IsCounter(dataType.kind)) {
          this.formatCounterFromDto(attributesMap, key);
        }
      });
    }
  }

  toServer(): WorkflowActionDto {
    const attributesMap = cloneDeep(this.actionSettings.attributesMap.value) as WorkflowCreateAttributeMap;

    Object.keys(attributesMap).forEach(key => {
      const attribute = this.options!.attributes.listMap[key];
      const dataType = this.options!.dataTypes.listMap[attribute.dataTypeId];

      if (IsCounter(dataType.kind)) {
        this.formatCounterToServer(attributesMap, key);
      } else if (isArray(attributesMap[key]) && !attributesMap[key].length) {
        delete attributesMap[key];
      } else if (!isArray(attributesMap[key]) && attributesMap[key] === null) {
        delete attributesMap[key];
      }
    });

    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [CreateArtifactsKeys.attributesMap]: {
          isDynamic: this.actionSettings.attributesMap.isDynamic,
          value: attributesMap,
        },
        [CreateArtifactsKeys.parentFolderId]: {
          isDynamic: this.actionSettings.parentFolderId.isDynamic,
          value: this.actionSettings.parentFolderId.value || null,
        },
        [CreateArtifactsKeys.storeResultsPath]: {
          isDynamic: false,
          value: this.actionSettings.storeResultsPath.value || null,
        },
        [CreateArtifactsKeys.forEachPath]: {
          isDynamic: false,
          value: this.actionSettings.forEachPath.value || null,
        },
        [CreateArtifactsKeys.filePath]: {
          isDynamic: false,
          value: this.actionSettings.filePath.value || null,
        },
      },
    };
  }

  private formatCounterFromDto(attributesMap: Record<string, WorkflowCreateAttributeMapValue>, key: string): void {
    // backward compatibility - existing string values and booleans should remain as are
    if (
      typeof attributesMap[key] === 'boolean' ||
      (typeof attributesMap[key] === 'string' && attributesMap[key].length && attributesMap[key] !== PARSER_GET_NULL)
    ) {
      return;
    }
    (attributesMap[key] as WorkflowCreateAttributeMapValue) = this.actionSettings.attributesMap.isDynamic
      ? attributesMap[key] === PARSER_GET_NULL
      : attributesMap[key] === null;
  }

  private formatCounterToServer(attributesMap: Record<string, WorkflowCreateAttributeMapValue>, key: string): void {
    // backward compatibility
    if (typeof attributesMap[key] === 'string') {
      if (attributesMap[key] === COUNTER_UNSET_VALUE) {
        delete attributesMap[key];
      }
      return;
    }

    this.actionSettings.attributesMap.isDynamic && (attributesMap[key] = attributesMap[key] ? PARSER_GET_NULL : '');
    !this.actionSettings.attributesMap.isDynamic && (attributesMap[key] = attributesMap[key] ? null : '');
  }
}
