import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ListOrientation } from '@widgets/list-matrix-widget/types/list-orientation';
import { WidgetOption } from '@widgets/widgets-core/types/widgets.types';

export class ListMatrixWidgetOptions {
  applications = new ListContainer<NewApplication>();
  artifactTypes = new ListContainer<NewArtifactType>();
  artifactTypesByApplication = new ListContainer<NewArtifactType>();

  attributes = new ListContainer<NewAttribute>();
  systemAttributes = new ListContainer<NewAttribute>();
  /** @deprecated */
  customAttributes = new ListContainer<NewAttribute>();

  combinedAttributes: (NewAttribute | NewClientAttribute)[] = [];
  combinedAttributeOptions: SelectOption<string, NewAttribute | NewClientAttribute>[] = [];

  combinedAttributeAndLinkOptions: SelectOption<string, NewAttribute | NewClientAttribute | DirectionalLinkType>[] = [];

  artifacts = new ListContainer<ArtifactResponseDto>();
  linkTypes = new ListContainer<DirectionalLinkType>();
  pages = new ListContainer<Page>();
  widgets: WidgetOption<any>[] = [];
  dataTypes = new ListContainer<NewDataType>();

  listOrientation: SelectOption<ListOrientation, ListOrientation>[] = GetSelectOptionsFromEnum(ListOrientation);
}
