import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantAttributeService } from '@api/services/tenant-attribute.service';
import { TranslateService } from '@ngx-translate/core';
import { AttributeListService } from '@private/pages/artifact-type-management/attribute-list/services/attribute-list.service';
import { AttributeListModel } from '@private/pages/artifact-type-management/attribute-list/types/attribute-list.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';

@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss'],
})
export class AttributeListComponent extends CoreComponent<AttributeListService, AttributeListModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: AttributeListService,
    announcement: AnnouncementService,
    private readonly tenantAttributeService: TenantAttributeService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new AttributeListModel(), service, announcement);
  }

  onInit(): void {
    this.breadcrumbService.setItems([{ label: 'Attributes' }]);
  }

  loadData() {
    return (meta?: Record<string, string>) => this.tenantAttributeService.attributeControllerList(meta).toPromise();
  }
}
