import { Directive, Input, OnInit } from '@angular/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttributeActionHandlerService } from '../services';
import { ModifyAttributeValueEvent } from '../types';
import { AttributeValueModifiable } from '../types/attribute-value-modifiable';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';

@Directive({
  selector: '[appModifyAttributeValue]',
})
export class ModifyAttributeValueDirective extends AbstractRuleAttributeDirective implements OnInit {
  @Input() attributeOwner: AttributeValueModifiable;

  constructor(
    protected actionAttributeHandler: AttributeActionHandlerService,
    private cache: NewCacheService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.actionAttributeHandler.registerModifyAttributeValueEventListener(this.key);
    this.initSubscription = this.actionAttributeHandler.modifyAttributeValueEventMap$
      .pipe(
        filter(e => !!e.event && this.isMatchingAttributeData(e.event) && this.actionAttributeHandler.containsModifyAttributeValueEvent(this.key)),
        map(() => this.actionAttributeHandler.getModifyAttributeValueEvent(this.key)),
      )
      .subscribe((modifyAttributeValueEvent: ModifyAttributeValueEvent) => {
        this.modifyAttributeValue(modifyAttributeValueEvent);
        this.actionAttributeHandler.removeModifyAttributeValueEventFromMap(this.key, false);
      });
  }

  ngOnDestroy(): void {
    this.actionAttributeHandler.unregisterModifyAttributeValueEventListener(this.key);
    super.ngOnDestroy();
  }

  private modifyAttributeValue(event: ModifyAttributeValueEvent) {
    this.attributeOwner.modifyAttributeValue(event.value, event.operation);
  }
}
