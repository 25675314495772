import { Directive, HostBinding, Inject, InjectionToken } from '@angular/core';
import { AvrWidgetSettingsDto } from '@api/models';
import { AvrOutputTypes, AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { Constructor } from '@shared/types/constructor.types';
import { SelectOption } from '@shared/types/shared.types';
import { AbstractAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/abstract.avr-types-services.service';
import { AvrWidgetModel } from '@widgets/avr-widget/types/avr-widget.types';

export type AvrSettingsInjectorBody = {
  model: AvrWidgetModel;
  avrTypeServices: Partial<Record<AvrTypes, AbstractAvrTypeService<AvrTypes>>>;
};
export const AVR_SETTINGS_INJECTOR = new InjectionToken<AvrSettingsInjectorBody>('settingFields');

@Directive()
export abstract class AbstractAvrQuerySettingsComponent<K extends AvrTypes, S extends AbstractAvrTypeService<K>> {
  @HostBinding('class') hostClass = 'full-width-container';

  protected settingFields: AvrWidgetSettingsDto[K];
  protected service: S;
  protected outputTypesOptions: SelectOption<AvrOutputTypes, AvrOutputTypes>[];

  constructor(
    @Inject(AVR_SETTINGS_INJECTOR) protected readonly injector: AvrSettingsInjectorBody,
    private _avrType: K,
    protected avrSettingDto: Constructor<AvrWidgetSettingsDto[K]>,
  ) {
    this.clearSharedSettingFields(injector.model.settings.response.avrTypeSettings);
    this.settingFields = injector.model.settings.response.avrTypeSettings[this._avrType] || new avrSettingDto();
    injector.model.settings.response.avrTypeSettings[this._avrType] = this.settingFields;
    // every AVR type with additional "SETTING" fields in AVR widget OR additional "QUERY" fields in GET artifact's AVR request, NEEDS to have its service
    this.service = injector.avrTypeServices[this._avrType]! as S;
    this.outputTypesOptions = this.service.m.currentArtifactTypeOption!.value.avrMapper[this._avrType]!.outputTypes.map(outputType => {
      return new SelectOption<AvrOutputTypes, AvrOutputTypes>(outputType as AvrOutputTypes, outputType as AvrOutputTypes);
    });
  }

  get avrType(): K {
    return this._avrType;
  }

  protected onOutputTypeChange(newOutputType: AvrOutputTypes): void {
    this.service.m.currentAvrFileType = newOutputType;
  }

  private clearSharedSettingFields(sharedSettingFields: AvrWidgetSettingsDto): void {
    (Object.keys(sharedSettingFields) as [AvrTypes]).forEach(settingAvrType => settingAvrType !== this._avrType && delete sharedSettingFields[settingAvrType]);
  }
}
