import { Injectable } from '@angular/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  CREATED_BY_KEY,
  CREATED_BY_LABEL,
  CREATED_ON_KEY,
  CREATED_ON_LABEL,
  NAME_KEY,
  NAME_LABEL,
  WORKFLOW_OWNER_TYPE_KEY,
  WORKFLOW_OWNER_TYPE_LABEL,
  WORKFLOW_TYPE_KEY,
  WORKFLOW_TYPE_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { WorkflowOwnerType, WorkflowType } from '@workflows/types';
import { WorkflowListModel } from '../types/workflow-list.types';
import { WorkflowListComponent } from '../workflow-list.component';

@Injectable({ providedIn: 'root' })
export class WorkflowListService extends CoreService<WorkflowListComponent, WorkflowListModel> {
  constructor(private readonly cache: NewCacheService) {
    super();
  }

  init(context: WorkflowListComponent, model: WorkflowListModel): void {
    super.init(context, model);

    this.initColumns();
    this.initOptions();
  }

  initColumns(): void {
    this.m.columns = [
      new TableColumn(NAME_LABEL, NAME_KEY),
      new TableColumn(WORKFLOW_TYPE_LABEL, WORKFLOW_TYPE_KEY),
      new TableColumn(WORKFLOW_OWNER_TYPE_LABEL, WORKFLOW_OWNER_TYPE_KEY),
      new TableColumn(CREATED_BY_LABEL, CREATED_BY_KEY),
      new TableColumn(CREATED_ON_LABEL, CREATED_ON_KEY),
    ];
  }

  initOptions(): void {
    this.m.options.workflowTypeOptions = Object.values(WorkflowType).map(type => new SelectOption(type));
    this.m.options.workflowOwnerTypeOptions = Object.values(WorkflowOwnerType).map(ownerType => new SelectOption(ownerType));
  }
}
