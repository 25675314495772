import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ImageMethods } from '@shared/methods/image.methods';

@Component({
  selector: 'app-bg-gradient-settings',
  standalone: true,
  templateUrl: './bg-gradient-settings.component.html',
  styleUrls: ['./bg-gradient-settings.component.scss'],
  imports: [InputTextareaModule, FormsModule, TranslateModule],
})
export class BgGradientSettingsComponent {
  @Input() styles: Partial<StylesDto>;
  @Input() gradientChangeCb: () => void;

  visible = false;

  onGradientPaste(e: ClipboardEvent): void {
    const regex = /(?<=t\()(.*)(?=\);)/g;
    const regex2 = /(?<=t\()(.*)(?=\))/g;
    const data = e.clipboardData?.getData('text/plain');
    const parsed = data?.match(regex) || data?.match(regex2);

    if (parsed?.length) {
      let gradient = `linear-gradient(${parsed[0]})`;
      if(gradient) {
        if(ImageMethods.hasGradient(gradient)) {
          gradient = ImageMethods.fixGradient(gradient);
          setTimeout(() => (this.styles.backgroundImage = gradient));
        }
      }
    }
  }
}
