import { LinkPopupSettingsDto } from '@api/models/link-popup-settings-dto';
import { Padding } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { ID_KEY } from '@shared/constants/constants';
import { SelectOption } from '@shared/types/shared.types';
import { ActionItem, ActionItemDto } from '@widgets/menu-widget/types/menu-action-types';
import { cloneDeep } from 'lodash';
import { ArtifactFilter, ArtifactFilterDto } from '../../shared/components/artifact-filters/types/artifact-filter.types';
import { GroupAttributeItem, GroupingSettings, GroupMetaDataItem } from '../../shared/components/artifact-list-table/types/list-widget-grouping.types';
import {
  DEFAULT_CELL_PADDING,
  ListWidgetTableLoadModeEnum,
  ListWidgetTableResponsiveEnum,
  ListWidgetTableSettings,
  ListWidgetTableSizeEnum,
  ListWidgetTextWrapEnum,
  TableBackgroundSettings,
} from '../../shared/components/artifact-list-table/types/list-widget-settings.types';
import { ArtifactListItemClickAction } from '../../shared/types/artifact-list-item-click-action';
import { ClickActionSettingsOptions } from '../../shared/types/click-action-settings-options';
import { LinkPopupOptions } from './link-popup-options.types';

export class LinkPopupSettings extends ListWidgetTableSettings {
  showTable = false;
  loadMode: ListWidgetTableLoadModeEnum = ListWidgetTableLoadModeEnum.byArtifactType;
  background: TableBackgroundSettings = {};
  showSum = false;
  showCount = false;
  showLinkButtons?: boolean = false;
  sticky = true;
  caption: string | null = null;
  summary: string | null = null;
  emptyMessage = 'No records found';
  multiselect = true;
  rowSelector = true;
  showNumberOfSelected = true;
  rowsPerPage = 50;
  actions: ActionItem[] = [new ActionItem({ type: ArtifactListItemClickAction.selectItem } as ActionItemDto)];
  responsive: ListWidgetTableResponsiveEnum = ListWidgetTableResponsiveEnum.rebuild;
  size: ListWidgetTableSizeEnum = ListWidgetTableSizeEnum.small;
  textWrap: ListWidgetTextWrapEnum = ListWidgetTextWrapEnum.nowrap;
  showLineBreaks = false;
  rowsStriped = false;
  customColors = false;
  showAddButton = false;
  addButtonLabel = 'Add';
  groupingOptions: SelectOption<string, GroupAttributeItem>[] = [];
  groupMetaData: Record<string, GroupMetaDataItem> | null = null;
  grouping = new GroupingSettings();
  filtersForQueryParams: ArtifactFilter[] = [];
  editableRow = false;
  doSortOnUrlChange = false;
  doFilterOnUrlChange = false;

  cellPaddings: Padding = {
    paddingBottom: DEFAULT_CELL_PADDING,
    paddingLeft: DEFAULT_CELL_PADDING,
    paddingRight: DEFAULT_CELL_PADDING,
    paddingTop: DEFAULT_CELL_PADDING,
  };

  constructor(settings?: Partial<LinkPopupSettings>) {
    super();
    settings && Object.assign(this, settings);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  toServer(): LinkPopupSettingsDto {
    const copy = cloneDeep(this);

    copy.actions = [];
    copy.showTable = false;
    copy.groupingOptions.forEach(option => {
      option.value.dataTypeId = option.value.dataType?.id || null;
      delete option.value.dataType;
    });
    copy.filtersForQueryParams = copy.filtersForQueryParams.map((filter: ArtifactFilter) => filter.toServer() as any);

    return copy as any as LinkPopupSettingsDto;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fromDto(dto: LinkPopupSettingsDto, options: LinkPopupOptions) {
    const dtoCopy = cloneDeep(dto);

    dtoCopy.groupingOptions.forEach(option => {
      // @ts-ignore
      option.value.dataTypeId && (option.value.dataType = options.dataTypes.listMap[option.value.dataTypeId]);
    });

    Object.assign(this, dtoCopy);

    const clickActionSettingsOptions: ClickActionSettingsOptions = {
      attributes: options.attributes.list,
      dataTypes: options.dataTypes.list,
      pages: options.pages.toSelectOptions('name', ID_KEY),
    };
    this.filtersForQueryParams = this.filtersForQueryParams.map((filterDto: ArtifactFilterDto) =>
      ArtifactFilter.fromDtoAndOptions(filterDto, clickActionSettingsOptions),
    );
  }
}
