import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationListResponseDto } from '@api/models/application-list-response-dto';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TenantApplicationService } from '@api/services/tenant-application.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NAME_KEY, NAME_LABEL } from '@shared/constants/constants';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { TableColumn } from '@shared/types/table.types';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-application-selection',
  templateUrl: './application-selection.component.html',
  styleUrls: ['./application-selection.component.scss'],
})
export class ApplicationSelectionComponent implements OnInit, OnDestroy {
  applications: ApplicationResponseDto[] = [];
  applications$: Subscription;
  user: SelfUserResponseDto;
  user$: Subscription;
  columns: TableColumn[] = [new TableColumn(NAME_LABEL, NAME_KEY)];
  isShowTable = false;
  canUserAccessAdmin: boolean;

  loading$: Observable<boolean>;

  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly cache: NewCacheService,
    private readonly tenantApplicationService: TenantApplicationService,
  ) {
    this.loading$ = this.loading.asObservable();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.applications = this.cache.data.applications.value as ApplicationResponseDto[];
      this.user = this.cache.user.value as SelfUserResponseDto;
      this.canUserAccessAdmin = Boolean(this.user.tenant?.isAdmin || this.user.tenant?.applications.some(app => app.isAdmin));

      this.loading.next(false);
    });
  }

  loadData() {
    return (meta?: Record<string, string>) => firstValueFrom(this.tenantApplicationService.applicationControllerList(meta));
  }

  showTable(): void {
    this.isShowTable = true;
  }

  hideTable(): void {
    this.isShowTable = false;
  }

  ngOnDestroy(): void {
    this.applications$ && this.applications$.unsubscribe();
    this.user$ && this.user$.unsubscribe();
  }
}
