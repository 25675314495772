import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { AttributeLabelFormatSettings } from '../../../types/attribute-format-settings.types';
import { LabelPositionEnum, TextHorizontalAlignEnum, TextVerticalAlignEnum } from '../../../types/style.types';

interface LabelPositionChange {
  originalEvent: PointerEvent;
  value: LabelPositionEnum;
}

@Component({
  selector: 'app-attribute-format-label-styles-form',
  templateUrl: './attribute-format-label-styles-form.component.html',
  styleUrls: ['./attribute-format-label-styles-form.component.scss'],
})
export class AttributeFormatLabelStylesFormComponent {
  @Input() labelFormatSettings: AttributeLabelFormatSettings;
  @Input() showHideOption = true;
  @Input() showLabelOption = true;
  @Input() enableLabelPositioning = true;
  @Input() enableHorizontalAlignment = true;
  @Input() enableVerticalAlignment = true;
  @Input() showFontBackgroundSettings = false;
  @Input() showPrefixSuffixSettings = false;
  @Input() showDecimalPlacesSettings = false;
  @Input() isLink = false;
  @Input() isContentStyles = false;
  @Input() hideFilter? = false;
  @Input() hideSorting? = false;
  @Input() hideLabelFontSettings? = false;

  @Output() labelStyleChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() labelPositionChange: EventEmitter<LabelPositionChange> = new EventEmitter<LabelPositionChange>();
  @Output() hideFilterChange?: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() hideSortingChange?: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly labelPositionOptions = GetSelectOptionsFromEnum(LabelPositionEnum);
  readonly textHorizontalAlignOptions = GetSelectOptionsFromEnum(TextHorizontalAlignEnum);
  readonly textVerticalAlignOptions = GetSelectOptionsFromEnum(TextVerticalAlignEnum);

  get isVerticalAlignmentAvailable(): boolean {
    const { position } = this.labelFormatSettings;

    return position === LabelPositionEnum.left || position === LabelPositionEnum.right;
  }

  onCustomLabelUseChange(): void {
    this.labelFormatSettings.customLabel = '';
    this.invokeLabelStyleChange();
  }

  onMinDecimalPlacesChange(value: any): void {
    if (value === null) this.labelFormatSettings.styles.minDecimalPlaces = undefined;
    this.invokeLabelStyleChange();
  }

  onMaxDecimalPlacesChange(value: any): void {
    if (value === null) this.labelFormatSettings.styles.maxDecimalPlaces = undefined;
    this.invokeLabelStyleChange();
  }

  invokeLabelStyleChange() {
    this.labelStyleChange.emit();
  }

  invokeLabelPositionChange(positionChange: LabelPositionChange) {
    this.labelPositionChange.emit(positionChange);
  }

  onHideFilterChange(event: CheckboxChangeEvent) {
    this.hideFilterChange?.emit(event.checked);
  }

  onHideSortingChange(event: CheckboxChangeEvent) {
    this.hideSortingChange?.emit(event.checked);
  }
}
