import { SidebarModalEventResponse, WidgetEventResponseSettings, WidgetEventResponseSettingsDto } from '../../shared/types/custom-event';

export class SidebarModalEventResponseSettings extends WidgetEventResponseSettings<SidebarModalEventResponse> {
  events: SidebarModalEventResponse[] = [];

  constructor(dto?: SidebarModalEventResponseSettingsDto) {
    super(dto);
    this.fromDto(dto);
  }

  addEvent(): void {
    this.events.push(new SidebarModalEventResponse());
  }
}

export interface SidebarModalEventResponseSettingsDto extends WidgetEventResponseSettingsDto<SidebarModalEventResponse> {}
