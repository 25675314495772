import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  BaseDataType,
  DataTypeKind,
  EnumeratedOption,
} from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { CREATED_ON_KEY, UPDATED_ON_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ConvertToClientDate, ConvertToClientDatetime, ConvertToClientTime } from '../../methods/date.methods';
import { SharedMethods } from '../../methods/shared.methods';
import { NewArtifact } from '../../types/artifact.types';
import { NewAttribute } from '../../types/attribute.types';
import { NewDataType } from '../../types/data-type.types';

@Pipe({ name: 'displayArtifactValueToTable', pure: false })
export class DisplayArtifactValueToTablePipe implements PipeTransform {
  private readonly cache = inject(NewCacheService);

  async transform(artifact: NewArtifact, key: string, attributesMap: Record<string, NewAttribute>, dataTypesMap: Record<string, NewDataType>): Promise<string> {
    if ([CREATED_ON_KEY, UPDATED_ON_KEY].includes(key)) return ConvertToClientDatetime(new Date(SharedMethods.getValueFromPath(key, artifact)));

    const attribute = attributesMap[key];

    if (attribute) {
      const clientAttribute = artifact.attributes[key];

      if (clientAttribute) {
        const { value } = clientAttribute;
        const { dataTypeId, multipleValues } = attribute;
        const { user, date, time, dateTime } = BaseDataType;
        const dataType = dataTypesMap[dataTypeId];

        if (!value && typeof value !== 'boolean') return '';
        if (dataType.kind === DataTypeKind.enumerated) return this.formatEnumLabels(value, multipleValues, dataType);
        if ([user, date, time, dateTime].includes(dataType.baseDataType as BaseDataType))
          return this.formatValueByBaseDataType(dataType, value, multipleValues);
        if (['string', 'number', 'boolean'].includes(typeof value)) return String(value);

        return value && value.join(', ');
      }

      return '';
    }

    return SharedMethods.getValueFromPath(key, artifact);
  }

  private formatEnumLabels(value: string | string[], multipleValues: boolean, dataType: NewDataType): string {
    return multipleValues ? (value as string[]).map(value => this.formatEnumLabel(value, dataType)).join('') : this.formatEnumLabel(value as string, dataType);
  }

  // TODO: perhaps add flag if the value should be templated as html
  private formatEnumLabel(value: any, dataType: NewDataType): string {
    const enumValue = value.value || value;
    const option: EnumeratedOption | undefined = dataType.values?.find(option => option.value === enumValue);
    if (option) {
      const { label, backgroundColor, textColor, icon } = option;
      let template = `<span class="table-icon-label" style="background-color: ${backgroundColor}; color: ${textColor}">`;
      if (icon) template += `<span class="icon ${icon}"></span> <span class="icon-place"></span>`;
      template += `${label}</span>`;
      return template;
    }
    return '';
  }

  private async formatValueByBaseDataType(dataType: NewDataType, value: any, multipleValues: boolean): Promise<string> {
    switch (dataType.baseDataType) {
      case BaseDataType.user:
        return this.formatUserValue(value);
      case BaseDataType.date:
        return this.formatDateValue(value, multipleValues);
      case BaseDataType.time:
        return this.formatTimeValue(value, multipleValues);
      case BaseDataType.dateTime:
        return this.formatDateTimeValue(value, multipleValues);
      default:
        return '';
    }
  }

  private async formatUserValue(value: any): Promise<string> {
    const userIds = (Array.isArray(value) ? value : [value]).map(userId => userId?.value || userId);
    const users = await this.cache.data.users.getManyAsync(userIds);

    return users
      .map(user => user.attributes[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value)
      .filter(Boolean)
      .join(', ');
  }

  private formatDateValue(value: any, multipleValues: boolean): string {
    return multipleValues
      ? value.map((date: string | Date) => ConvertToClientDate(this.formatStringOrDateToDate(date))).join(', ')
      : ConvertToClientDate(this.formatStringOrDateToDate(value));
  }

  private formatTimeValue(value: any, multipleValues: boolean): string {
    if (multipleValues) return value.map((time: string | Date) => ConvertToClientTime(this.formatStringOrTimeToDate(time)));
    else return ConvertToClientTime(this.formatStringOrTimeToDate(value));
  }

  private formatDateTimeValue(value: any, multipleValues: boolean): string {
    return multipleValues ? value.map((datetime: string) => ConvertToClientDatetime(new Date(datetime))).join(', ') : ConvertToClientDatetime(new Date(value));
  }

  private formatStringOrDateToDate(value: Date | string): Date {
    return typeof value === 'string' ? new Date(value) : value;
  }

  private formatStringOrTimeToDate(value: Date | string): Date {
    return typeof value === 'string' ? new Date(new Date().toISOString().replace(/\d{2}:\d{2}/, value)) : value;
  }
}
