import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Environment } from '@environments/environment';
import { UiConfig } from '@private/pages/profile/types/user.types';
import { UserAutocompleteComponent } from '@shared/components/user-autocomplete/user-autocomplete.component';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DATDateCalendarFormat } from '@shared/types/display-at-types';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { CLIENT_DATA_KEY, UI_CONFIG_KEY } from '../../constants/constants';
import { IsDecimal, IsHtml, IsInteger, IsSimple } from '../../methods/data-type.methods';
import { CommonPipesModule } from '../../pipes/common/common-pipes.module';
import { DataTypePipesModule } from '../../pipes/data-type-pipes/data-type-pipes.module';
import { LocalStorageService } from '../../services/local-storage.service';
import { StateKey } from '../../types/local-storage.types';

@Component({
  selector: 'app-attribute-value-edit-field',
  templateUrl: './attribute-value-edit-field.component.html',
  styleUrls: ['./attribute-value-edit-field.component.scss'],
})
export class AttributeValueEditFieldComponent implements OnInit, OnChanges {
  @Input() value: unknown;
  @Input() disabled: boolean;
  @Input() multipleValues: boolean;
  @Input() index: number;
  @Input() dataType: NewDataType;
  @Input() attribute: NewAttribute;
  @Input() showCurrentUserOption: boolean;
  @Output() valueChange = new EventEmitter<unknown>();
  settings: Record<string, any>;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  decimalPattern = /^[0-9]{0,}([.][0-9]{0,})?$/;
  integerPattern = /^[0-9]{0,}$/;
  uiConfig: UiConfig;
  uniqueId = '';

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly tinyMceHelper: TinyMceHelper,
  ) {}

  @Input() set selectedVariant(displayVariant: string) {
    this.dateFormat = DATDateCalendarFormat[displayVariant as keyof typeof DATDateCalendarFormat] || Environment.calendarConfig.clientDateFormat;
  }

  async ngOnInit(): Promise<void> {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataType) {
      this.initialize();
    }
  }

  onChangeCb(value: unknown): void {
    if (value === 'null' || value === 'undefined') {
      value = null;
    }

    this.valueChange.emit(value);
  }

  async checkNumFormatMultiple(): Promise<void> {
    if (this.dataType) {
      const checkNum = (startValue: string): string => {
        let value = startValue;
        if (this.dataType.baseDataType) {
          if (IsInteger(this.dataType.baseDataType) && !this.integerPattern.test(value)) {
            value = value.substr(0, value.length - 1);
            return checkNum(value);
          } else if (IsDecimal(this.dataType.baseDataType) && !this.decimalPattern.test(value)) {
            value = value.substr(0, value.length - 1);
            return checkNum(value);
          }
        }
        return value;
      };
      this.onChangeCb((this.value as string[]).map(value => checkNum(value)).filter(item => !!item));
    }
  }

  onHyperlinkChipClickEvent(e: any): void {
    e;
    console.log('// NEEDS TO BE IMPLEMENTED //');
  }

  private initialize(): void {
    this.setUiConfig();

    if (this.dataType.baseDataType) {
      if (IsHtml(this.dataType.baseDataType) && IsSimple(this.dataType.kind) && !this.multipleValues) {
        this.settings = this.tinyMceHelper.getTinyMceAdvancedSettings();
        this.settings.inline = false;
        this.value = this.tinyMceHelper.addOrRemoveImageAuth(this.value as string, false);
      }
      this.uniqueId = SharedMethods.getUniqueId();
    }
  }

  private setUiConfig(): void {
    const data = this.localStorageService.getFromState(StateKey.session, CLIENT_DATA_KEY);
    this.uiConfig = new UiConfig(data[UI_CONFIG_KEY]);
  }
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ChipsModule,
    MultiSelectModule,
    SliderModule,
    DropdownModule,
    InputTextareaModule,
    CalendarModule,
    EditorModule,
    DataTypePipesModule,
    CommonPipesModule,
    InputNumberModule,
    TriStateCheckboxModule,
    UserAutocompleteComponent,
  ],
  exports: [AttributeValueEditFieldComponent],
  declarations: [AttributeValueEditFieldComponent],
})
export class AppAttributeValueEditFieldModule {}
