<h2 *ngIf="label && !isLayoutMode">{{ label }}</h2>

<div
  *ngIf="m?.settings?.url"
  class="image-container resizable"
  [ngStyle]="{
    background: m.settings.backgroundColorContainer,
    'justify-content': m.settings.picturePosition | picturePosition
   }"
   appPictureWidgetResizeObserver
   (resizeEvent)="onContainerResize($event)">

   <div #imageContainer
    [ngClass]="[ m.settings.format, 'picture-container' ]"
    [ngStyle]="{
      'clip-path': isCircle ? 'circle(50%)' : null,
      background: m.settings.backgroundColorPicture,
      height: m.settings.height,
      width: isCircle ? m.settings.height : null,
      'object-fit': m.settings.pictureSize,
      'border-radius': (!isCircle && m.showBorderRadius) ? m.settings.borderRadius + 'px' : null
    }">

    <img #imageInContainer
      [src]="(m.settings.useExternalLink ? this.m.settings.url : (m.settings.fileId | internalImageIdToUrl)) | getPictureSource"
      [alt]="m.settings.alt"
      [ngClass]="m.settings.format"
      [ngStyle]="{
        height: m.settings.height,
        width: isCircle ? m.settings.height : null,
        'object-fit': m.settings.pictureSize,
        'border-radius': (!isCircle && m.showBorderRadius) ? m.settings.borderRadius + 'px' : null,
        'vertical-align': isCircle ? 'middle' : null
      }"
      (click)="onImageClick()"
    />
    <ng-template [ngTemplateOutlet]="OverlayContent"></ng-template>
  </div>

</div>

<div *ngIf="!isLayoutMode" class="image-settings">

  <app-image-pointer
    [applicationId]="applicationId"
    [imageUrl]="m.settings.useExternalLink ? m.settings.url : m.fileObjectUrl"
    [imageFileId]="m.settings.fileId"
    [onlyUpload]="true"
    [useExternalLink]="m.settings.useExternalLink"
    (imageUrlChange)="onImageUrlChange($event)"
    (imageFileIdChange)="onImageFileIdChange($event)"
    (useExternalLinkChange)="onUseExternalLink($event)"
  >
  </app-image-pointer>

  <div class="option-container">
    <span>{{'Format' | translate}}</span>
    <p-dropdown
      (onChange)="onPictureFormatChange()"
      [(ngModel)]="m.settings.format"
      [autoDisplayFirst]="false"
      [options]="m.options.pictureFormatOptions"
      [placeholder]="'Choose picture format'"
      appendTo="body"
    >
    </p-dropdown>
  </div>

  <div class="option-container">
    <span>{{'Picture size' | translate}}</span>
    <p-dropdown
      [(ngModel)]="m.settings.pictureSize"
      [autoDisplayFirst]="false"
      [options]="m.options.pictureSizeOptions"
      [placeholder]="'Choose picture size'"
      appendTo="body"
    >
    </p-dropdown>
  </div>

  <div class="option-container">
    <span>{{'Image position' | translate}}</span>
    <p-dropdown
      [(ngModel)]="m.settings.picturePosition"
      [autoDisplayFirst]="false"
      [options]="m.options.picturePositionOptions"
      [placeholder]="'Choose image position'"
      appendTo="body"
    >
    </p-dropdown>
  </div>

  <div class="option-container">
    <span>{{'Height' | translate}}</span>
    <input [(ngModel)]="m.settings.height" pInputText placeholder="{{ 'Height' }}" type="text" />
  </div>

  <div *ngIf="m.showBorderRadius" class="option-container">
    <span>{{'Border radius' | translate}}</span>
    <p-inputNumber [(ngModel)]="m.settings.borderRadius" placeholder="{{ 'Border radius' }}" suffix="px" mode="decimal"></p-inputNumber>
  </div>

  <div class="option-container col">
    <span>{{'Alt text' | translate}}</span>
    <input [(ngModel)]="m.settings.alt" pInputText placeholder="{{ 'Alt text' }}" type="text" />
  </div>

  <div class="option-container">
    <span>{{'Background color' | translate}}</span>
    <app-color-picker label="For container" [(color)]="m.settings.backgroundColorContainer"></app-color-picker>
    <app-color-picker label="For picture" [(color)]="m.settings.backgroundColorPicture"></app-color-picker>
  </div>

  <div class="option-container check">
    <label for="openInLightbox" translate> Open in Lightbox </label>
    <p-checkbox (onChange)="onOpenInLightboxChange()" [(ngModel)]="m.settings.openInLightbox" binary="true" id="openInLightbox"> </p-checkbox>
  </div>

  <div *ngIf="m.settings.openInLightbox" class="option-container check">
    <label for="pictureOverlay" translate> Overlay </label>
    <p-checkbox [(ngModel)]="m.settings.pictureOverlay.enabled" binary="true" id="pictureOverlay"> </p-checkbox>
  </div>

  <ng-container *ngIf="m.settings.openInLightbox && m.settings.pictureOverlay.enabled">
    <div class="option-container">
      <span>{{'Overlay opacity' | translate}}</span>
      <p-inputNumber
        [(ngModel)]="m.settings.pictureOverlay.opacity"
        placeholder="{{ 'Opacity' }}"
        mode="decimal"
        [minFractionDigits]="2"
        [showButtons]="true"
        min="0"
        max="1"
        step="0.1"
      ></p-inputNumber>
    </div>
    <div class="option-container">
      <span>{{'Overlay color' | translate}}</span>
      <app-color-picker label="Color" [(color)]="m.settings.pictureOverlay.color"></app-color-picker>
    </div>
  </ng-container>

  <div class="option-container check">
    <label for="is-link" translate> Link </label>
    <p-checkbox [(ngModel)]="m.settings.pictureLinkOptions.enabled" [disabled]="m.settings.openInLightbox" binary="true" id="is-link"> </p-checkbox>
  </div>

  <ng-container *ngIf="!m.settings.openInLightbox && m.settings.pictureLinkOptions.enabled">
    <div class="option-container check">
      <label for="open-in-new-tab" translate> Open in new tab </label>
      <p-checkbox [(ngModel)]="m.settings.pictureLinkOptions.openInNewTab" binary="true" id="open-in-new-tab"> </p-checkbox>
    </div>

    <div class="option-container check">
      <label for="external-link" translate> External link </label>
      <p-checkbox [(ngModel)]="m.settings.pictureLinkOptions.external" binary="true" id="external-link"> </p-checkbox>
    </div>

    <div *ngIf="m.settings.pictureLinkOptions.external" class="option-container">
      <span>{{'URL' | translate}}</span>
      <input [(ngModel)]="m.settings.pictureLinkOptions.url" pInputText placeholder="{{ 'Enter url' }}" type="text" />
    </div>

    <div>
      <p-dropdown
        *ngIf="!m.settings.pictureLinkOptions.external"
        [(ngModel)]="m.settings.pictureLinkOptions.page"
        [autoDisplayFirst]="false"
        [options]="m.options.pageOptions"
        [placeholder]="'Choose page'"
        appendTo="body"
        [virtualScroll]="true"
        [virtualScrollItemSize]="20"
        appNotifyIfSelectedPageDeletedDirective
        [selectedPageId]="m.settings.pictureLinkOptions.page"
      >
      </p-dropdown>
    </div>
  </ng-container>

  <div class="option-container check">
    <label for="is-open-sidebar" translate> Open sidebar </label>
    <p-checkbox [(ngModel)]="m.settings.openSidebar" [disabled]="!m.options.sidebarList" binary="true" id="is-open-sidebar"> </p-checkbox>
  </div>

  <ng-container *ngIf="m.settings.openSidebar">
    <div class="check">
      <p-dropdown [(ngModel)]="m.settings.sidebarId" [options]="m.options.sidebarList | transformArrayToSelectOptions : NAME_KEY: ID_VALUE">
        <ng-template pTemplate="selectedItem" let-selected> {{ selected.label || selected.value }} </ng-template>
        <ng-template let-option pTemplate="item"> {{ option.label || option.value }}</ng-template>
      </p-dropdown>
    </div>
  </ng-container>
</div>

<p-dialog [(visible)]="m.lightboxVisible">
  <div class="image-container"
    [ngStyle]="{
      background: m.settings.backgroundColorContainer,
      'justify-content': m.settings.picturePosition | picturePosition
     }">
  

    <div #imageContainer
    [ngClass]="[ m.settings.format, 'picture-container' ]"
    [ngStyle]="{
      'clip-path': isCircle ? 'circle(50%)' : null,
      background: m.settings.backgroundColorPicture,
      height: m.settings.height,
      'object-fit': m.settings.pictureSize,
      'border-radius': (!isCircle && m.showBorderRadius) ? m.settings.borderRadius + 'px' : null,
    }">

      <img #imageInContainer
        id="image-simple"
        [src]="m.settings.useExternalLink ? m.settings.url : m.fileObjectUrl"
        [alt]="m.settings.alt"
        [ngClass]="m.settings.format"
        [ngStyle]="{
          height: '100%',
          width: isCircle ? m.settings.height : null,
          'object-fit': m.settings.pictureSize,
          'border-radius': (!isCircle && m.showBorderRadius) ? m.settings.borderRadius + 'px' : null,
        }"
      />

    </div>

  </div>
</p-dialog>
    
<ng-template #OverlayContent >
  <i *ngIf="!m.settings.useExternalLink && !m.settings.fileId" class="pi pi-exclamation-triangle warning-icon"></i>
  <div
    *ngIf="m.settings.pictureOverlay.enabled"
    class="image-overlay-container"
    [ngClass]="m.settings.format"
    [ngStyle]="{
      height: '100%',
      background: m.settings.pictureOverlay.color,
      'object-fit': m.settings.pictureSize,
      'border-radius': m.showBorderRadius ? m.settings.borderRadius + 'px' : null,
      opacity: m.settings.pictureOverlay.opacity
    }">

    <div
      *ngIf="m.settings.url"
      [ngClass]="m.settings.format"
      [ngStyle]="{
        height: '100%',
        background: getStylesBackground(),
        'border-radius': m.showBorderRadius ? m.settings.borderRadius + 'px' : null
      }"
      class="image-overlay"
      (click)="onImageClick()"
    ></div>
  </div>
</ng-template>
