import { NewCacheService } from '@shared/cache/new-cache.service';
import { EMPTY_GROUP_HEADER_SUFFIX, EMPTY_GROUP_VALUE } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { FilterMatchMode, FilterMetadata, FilterOperator } from 'primeng/api';
import { GroupAttributeItem, GroupingSettings, GroupItem } from '../../types/list-widget-grouping.types';
import { AbstractGroupingHandler } from './abstract-grouping-handler';

export class UserGroupingHandler extends AbstractGroupingHandler {
  private _data: NewArtifact[] = [];
  private _groupLabelsMap: Map<string, string> = new Map();

  setData(data: NewArtifact[]): void {
    this._data = data;
  }

  async groupTableData(data: NewArtifact[], groupingSettings: GroupingSettings, cache?: NewCacheService): Promise<Record<string, GroupItem>> {
    const groupAttributeItem = groupingSettings.groupingAttributes[0].value;
    const groupData: Record<string, GroupItem> = {};
    const groupIds = new Set(data.map(artifact => this.getGroupId(artifact, groupAttributeItem)).filter(id => id !== EMPTY_GROUP_VALUE));

    if (cache) {
      this._groupLabelsMap = (await cache.data.users.getManyAsync([...groupIds])).reduce((res, user) => {
        res.set(user.id, user.attributes[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)].value as string);
        return res;
      }, new Map());
    }

    data.forEach(artifact => {
      const groupId = this.getGroupId(artifact, groupAttributeItem);

      if (groupData[groupId]) {
        groupData[groupId].artifacts.push(artifact);
        groupData[groupId].total! += 1;
      } else {
        groupData[groupId] = this.getNewGroup(groupingSettings, groupId, 0, [artifact]);
      }
    });
    return groupData;
  }

  getAllGroups(groupingSettings: GroupingSettings): Record<string, GroupItem> {
    const userIds: Set<string> = this._data.reduce((res: Set<string>, item: NewArtifact): Set<string> => {
      res.add(item.attributes[groupingSettings.groupingAttributes[0].value.id].value);
      return res;
    }, new Set());
    const groupMap: Record<string, GroupItem> = {};

    [...userIds].forEach(
      (userId, index) =>
        (groupMap[userId] = {
          id: userId,
          artifacts: [],
          metaData: {
            isDisplayed: true,
            count: 1,
            header: userId,
            toggled: false,
          },
          isDefaultGroup: false,
          sortOrder: index,
          total: 0,
        }),
    );

    return groupMap;
  }

  getGroupingHeader(groupId: string): string {
    if (groupId === EMPTY_GROUP_VALUE) return EMPTY_GROUP_HEADER_SUFFIX;
    return this._groupLabelsMap.has(groupId) ? (this._groupLabelsMap.get(groupId) as string) : groupId;
  }

  getGroupingValue(): string {
    return '';
  }

  getGroupId(artifact: NewArtifact, groupAttributeItem: GroupAttributeItem): string {
    if (NonAttributeKeys.isUserSpecificAttributeKey(groupAttributeItem.id)) return SharedMethods.getValueFromPath(groupAttributeItem.id, artifact);

    const groupValue = artifact.attributes[groupAttributeItem.id].value;
    if (!groupValue || !groupValue.length) return EMPTY_GROUP_VALUE;

    return groupValue;
  }

  getFilterMetadataForGroupItem(groupItem: GroupItem): FilterMetadata {
    return {
      matchMode: FilterMatchMode.IN,
      operator: FilterOperator.AND,
      value: [groupItem.id],
    };
  }

  sortAndSetSortOrderValue(groupItems: GroupItem[], groupingSettings: GroupingSettings): GroupItem[] {
    return this.doSortAndSetSortOrderValue(groupItems, groupingSettings, false);
  }
}
