import { Injectable } from '@angular/core';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { DisplayAtControlService } from '@shared/components/common-display-at';
import { ID_KEY } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ListWidgetOptions } from '../types/list-widget-options.types';

@Injectable({
  providedIn: 'root',
})
export class ArtifactListWidgetNestedWidgetLoaderService {
  constructor(
    private readonly widgetService: WidgetService,
    private readonly displayAtControlService: DisplayAtControlService,
    private readonly cache: NewCacheService,
    public readonly announcement: AnnouncementService,
  ) {}

  loadNestedWidget(ownerId: string, attributeId: string, widgetId: string, options: ListWidgetOptions): void {
    if (!widgetId) {
      return;
    }

    this.cache.data.widgets
      .get$(widgetId)
      .pipe(
        map(widgetDto => {
          const widget = new BlockPartWidget(widgetDto);
          const widgetModelDto = { ...widget.value.model };

          switch (widget.code) {
            case WidgetType.card: {
              const model = CardWidgetModel.fromDtoAndOptions(widgetModelDto, options);
              widget.value = { model };
              this.setCardWidgetOptions(model, options);
              break;
            }
            case WidgetType.avr: {
              break;
            }
            default:
              throw new Error('Not supported widget type as custom column');
          }

          return widget;
        }),
        switchMap(widget => {
          switch (widget.code) {
            case WidgetType.card:
              return this.widgetService.loadCardInnerWidgets$(widget.value.model).pipe(map(() => widget));

            // no additional loading required
            default:
              return of(widget);
          }
        }),
      )
      .subscribe(widget => this.displayAtControlService.doUpdateLoadedCustomVariantTemplate(ownerId, attributeId, widget));
  }

  private setCardWidgetOptions(model: CardWidgetModel, options: ListWidgetOptions): void {
    model.options.attributes.setList(options.attributes.list, ID_KEY);
    model.options.systemAttributes.setList(options.systemAttributes.list, ID_KEY);
    model.options.dataTypes.setList(options.dataTypes.list, ID_KEY);
    model.options.artifactTypes.setList(options.artifactTypes.list, ID_KEY);
    model.options.linkTypes.setList(options.linkTypes.list, ID_KEY);
    model.options.applications.setList(options.applications.list, ID_KEY);
    model.options.pages.setList(options.pages.list, ID_KEY);
  }
}
