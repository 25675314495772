import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantApplicationService } from '@api/services/tenant-application.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreComponent } from '@shared/core/components/core.component';
import { ListReqMetaData } from '@shared/core/types/core.types';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { ApplicationListService } from './services/application-list.service';
import { ApplicationListModel } from './types/application-list.types';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
  providers: [ApplicationListService],
})
export class ApplicationListComponent extends CoreComponent<ApplicationListService, ApplicationListModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: ApplicationListService,
    announcement: AnnouncementService,
    private readonly tenantApplicationService: TenantApplicationService,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new ApplicationListModel(), service, announcement);
  }

  loadData() {
    return (meta?: Record<string, string>) => this.tenantApplicationService.applicationControllerList(meta).toPromise();
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Applications' }]);
  }
}
