import { Component, Input } from '@angular/core';
import { NAME_KEY } from '@shared/constants/constants';
import { ChartWidgetModel } from '@widgets/chart-widget/types/chart-widget.types';
import { ChartWidgetService } from '@widgets/chart-widget/services/chart-widget.service';
import { ChartWidgetTypeChart } from '@widgets/chart-widget/types/chart-widget-view.types';
import { FitMode } from '@widgets/chart-widget/types/chart-widget-selected.types';

@Component({
  selector: 'app-chart-widget-settings',
  templateUrl: './chart-widget-settings.component.html',
  styleUrls: ['./chart-widget-settings.component.scss'],
})
export class ChartWidgetSettingsComponent {
  @Input() m: ChartWidgetModel;
  @Input() s: ChartWidgetService;

  NAME_KEY = NAME_KEY;

  get isVerticalFit(): boolean {
    return this.m.selected.fitMode === FitMode.vertical;
  }

  isDoughnut(): boolean {
    return this.m.settings.chartType === ChartWidgetTypeChart.doughnut;
  }

  isVertical(): boolean {
    return this.m.selected.fitMode === FitMode.vertical;
  }
}
