import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { FormatAttributeAction } from '../../types';
import { WidgetFormatEventMap } from '../types';

@Injectable({ providedIn: 'root' })
export class RuleFormatHandlerService {
  private formatEventMap: WidgetFormatEventMap = {};

  private formatEvent: ReplaySubject<WidgetFormatEventMap>;
  private formatEventObservable: Observable<WidgetFormatEventMap>;

  constructor() {
    this.formatEvent = new ReplaySubject(1);
    this.formatEventObservable = this.formatEvent.asObservable();
  }

  get formatEvent$(): Observable<WidgetFormatEventMap> {
    return this.formatEventObservable;
  }

  notifyFormatEvent(event: FormatAttributeAction) {
    if (!this.formatEventMap[event.widgetId]) {
      this.formatEventMap[event.widgetId] = {};
    }
    if (!this.formatEventMap[event.widgetId][event.attributeId]) {
      this.formatEventMap[event.widgetId][event.attributeId] = {};
    }
    this.formatEventMap[event.widgetId][event.attributeId][event.artifactId] = event;
    this.formatEvent.next(this.formatEventMap);
  }
}
