import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArtifactWidgetSettingsDto } from '@api/models/artifact-widget-settings-dto';
import { ArtifactWidgetTypeResponseDto } from '@api/models/artifact-widget-type-response-dto';
import { CardWidgetTypeResponseDto } from '@api/models/card-widget-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { ListWidgetTypeResponseDto } from '@api/models/list-widget-type-response-dto';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AttributeWithPriorityOrder, DefaultPageParams } from '@private/types/page-builder-helper.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { RangedStyleValue } from '@shared/components/grid-layout-generator/types/ranged-style-value';
import { DESCRIPTION_KEY, PRIMARY_TEXT_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { LinkMethods } from '@shared/methods/link.methods';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { ArtifactWidgetItemActions } from '@shared/types/actions.types';
import { ArtifactTypeFormatEnum, NewArtifactType, NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DisplayAttributeType } from '@shared/types/display-at-types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { NewTableColumn, NewTableColumnMetaData } from '@shared/types/table.types';
import { WidgetResponseDto } from '@shared/types/widget.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { ButtonContainerPositionEnum } from '@widgets/artifact-widget/types/artifact-widget-button-containers.types';
import { ArtifactTabSettings, ArtifactWidgetFormItemDto } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { ArtifactWidgetOptions } from '@widgets/artifact-widget/types/artifact-widget-options.types';
import { ArtifactWidgetSettings } from '@widgets/artifact-widget/types/artifact-widget-settings.types';
import { ArtifactWidgetFormatsMap, ArtifactWidgetModel, ArtifactWidgetValue } from '@widgets/artifact-widget/types/artifact-widget.types';
import { CardWidgetAreaContentItem } from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { MenuWidgetHelper } from '@widgets/menu-widget/helpers/menu-widget.helper';
import { ActionItem } from '@widgets/menu-widget/types/menu-action-types';
import { MenuItemSettings, MenuWidgetSettings } from '@widgets/menu-widget/types/menu-widget-settings.types';
import {
  ChipStyles,
  FontStyles,
  HorizontalDividerStyles,
  IconStyles,
  MenuItemGeneralStyles,
  MenuItemStyles,
  MenuStylesPropNamesEnum,
  MenuWidgetAllStyles,
  VerticalDividerStyles,
} from '@widgets/menu-widget/types/menu-widget-styles.types';
import { MenuWidgetModel, MenuWidgetModelDto, MenuWidgetValue } from '@widgets/menu-widget/types/menu-widget.types';
import { TreeItem } from '@widgets/menu-widget/types/tree-types';
import { ListWidgetTableLoadModeEnum } from '@widgets/shared/components/artifact-list-table/types/list-widget-settings.types';
import { ListWidgetModel, ListWidgetType } from '@widgets/shared/components/artifact-list-table/types/list-widget.types';
import { ReplaceRuntimeVariablesPipe } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import {
  AttributeFormatSettings,
  AttributeLabelFormatSettings,
  AttributeValueFormatSettings,
  FormatStyles,
} from '@widgets/shared/types/attribute-format-settings.types';
import { BorderStyles, FontWeightEnum } from '@widgets/shared/types/style.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WidgetGeneratorHelper {
  defaultLabelColor = '#CCCCCC';
  defaultLabelFontSize = '10px';
  defaultSaveColor = '#689f38';
  defaultEditColor = '#fbc02d';
  defaultCancelColor = '#c02929';
  defaultSaveHoverColor = '#537f2d';
  defaultEditHoverColor = '#e8a704';
  defaultCancelHoverColor = '#aa2424';
  defaultVerticalButtonPaddings = { paddingTop: '20px', paddingBottom: '20px' };
  defaultVerticalSubMenuButtonPaddings = { paddingTop: '10px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px' };

  constructor(
    private readonly tenantWidgetService: TenantWidgetService,
    private readonly cache: NewCacheService,
    private readonly artifactWidgetCustomAttributeHelper: ArtifactWidgetCustomAttributeHelper,
    private readonly route: ActivatedRoute,
    private readonly replaceRuntimeVariablesPipe: ReplaceRuntimeVariablesPipe,
    private readonly menuWidgetHelper: MenuWidgetHelper,
    private readonly widgetService: WidgetService,
  ) {}

  async createDefaultArtifactWidget(artifactType: NewArtifactType, defaultPageParams: DefaultPageParams): Promise<ArtifactWidgetTypeResponseDto> {
    const widgetAlias = ElvisUtil.makeHash(10);

    const defaultMenuBlockPart = this.createDefaultMenuForArtifactWidget(widgetAlias);
    defaultMenuBlockPart.id = (await this.widgetService.saveWidget(defaultMenuBlockPart.requestDto)).id;

    const model = await this.createDefaultArtifactWidgetModel(artifactType, defaultPageParams, defaultMenuBlockPart, widgetAlias);
    const artifactBlockPart = new BlockPartWidget({ code: WidgetType.artifact, value: new ArtifactWidgetValue(model) } as unknown as WidgetResponseDto);

    return (await lastValueFrom(this.tenantWidgetService.widgetControllerCreate({ body: artifactBlockPart.requestDto }))) as ArtifactWidgetTypeResponseDto;
  }

  async createDefaultCardWidget(artifactType: NewArtifactType, defaultPageParams: DefaultPageParams): Promise<CardWidgetTypeResponseDto> {
    const model = await this.createDefaultCardWidgetModel(artifactType, defaultPageParams);
    const blockPart = new BlockPartWidget({ code: WidgetType.card, value: { model: model.toServer() } } as unknown as WidgetResponseDto);

    return (await lastValueFrom(this.tenantWidgetService.widgetControllerCreate({ body: blockPart.requestDto }))) as CardWidgetTypeResponseDto;
  }

  async createDefaultListWidget(): Promise<ListWidgetTypeResponseDto> {
    const model = this.createDefaultListWidgetModel();
    const blockPart = new BlockPartWidget({ code: WidgetType.listNew, value: { model: new ListWidgetModel(model) } } as unknown as WidgetResponseDto);

    return (await lastValueFrom(this.tenantWidgetService.widgetControllerCreate({ body: blockPart.requestDto }))) as ListWidgetTypeResponseDto;
  }

  createDefaultMenuForArtifactWidget(artifactWidgetAlias: string): BlockPartWidget<MenuWidgetValue> {
    const modelDto = this.createDefaultMenuWidgetModelDto(artifactWidgetAlias);
    return new BlockPartWidget({ code: WidgetType.menu, value: { model: new MenuWidgetModel(modelDto) } } as unknown as WidgetResponseDto);
  }

  private async createDefaultArtifactWidgetModel(
    artifactType: NewArtifactType,
    defaultPageParams: DefaultPageParams,
    defaultBottomMenu: BlockPartWidget<MenuWidgetValue>,
    widgetAlias: string,
  ): Promise<ArtifactWidgetModel> {
    const { attributes, dataTypes, artifactTypeDto, artifactTypes, linkTypes } = defaultPageParams;

    const form: ArtifactWidgetFormItemDto[] = Object.keys(artifactType.attributes).map(key => ({
      id: key,
      meta: null,
      columns: '12',
      tabSettings: new ArtifactTabSettings(),
    }));

    const relevantLinkTypes = (this.cache.data.linkTypes.value as LinkTypeResponseDto[]).filter(linkType => {
      const { restrictions } = linkType as LinkTypeResponseDto;

      if (restrictions) {
        return restrictions.some(
          restriction => restriction.sourceArtifactTypeId === artifactType.id || restriction.destinationArtifactTypeId === artifactType.id,
        );
      }

      return false;
    });
    const restrictions = LinkMethods.getLinkRestrictionsForArtifactType(artifactType.id, relevantLinkTypes);
    const settings = new ArtifactWidgetSettings({
      urlChangeAction: true,
      addCreatedArtifactIdToUrlParam: artifactType.format === ArtifactTypeFormatEnum.module,
      addCreatedFolderIdToUrlParam: artifactType.format === ArtifactTypeFormatEnum.module,
      buttonContainers: { [ButtonContainerPositionEnum.bottom]: defaultBottomMenu },
      widgetAlias: widgetAlias,
    });

    const options = new ArtifactWidgetOptions();
    options.artifactTypes = artifactTypes;
    options.artifactTypes.listMap[artifactType.id] = artifactType;
    options.attributes = attributes;
    options.dataTypes = dataTypes;
    await options.setLinkTypeOptionsAndIdsMap(restrictions, linkTypes);

    const model = new ArtifactWidgetModel({ replaceRuntimeVariablesPipe: this.replaceRuntimeVariablesPipe });
    await model.setFromDto({
      options,
      model: {
        selected: { artifactTypeId: artifactTypeDto.id, linkType: null },
        form,
        formatsMap: new ArtifactWidgetFormatsMap(),
        linkRestrictions: restrictions,
        settings: settings as any as ArtifactWidgetSettingsDto,
      },
      attributes,
      cache: this.cache,
      artifactWidgetCustomAttributeHelper: this.artifactWidgetCustomAttributeHelper,
      route: this.route,
    });

    return model;
  }

  private async createDefaultCardWidgetModel(artifactType: NewArtifactType, defaultPageParams: DefaultPageParams): Promise<CardWidgetModel> {
    const model = CardWidgetModel.initial();
    model.grid.gridTemplateRows = RangedStyleValue.fromValueAndBreakpoint('auto', { value: Infinity });
    model.styles.background = 'transparent';

    const defaultLabelSettings = new AttributeLabelFormatSettings({
      styles: new FormatStyles({ color: this.defaultLabelColor, fontSize: this.defaultLabelFontSize }),
      visible: true,
    });

    const systemNameValueFormat = new AttributeValueFormatSettings({
      styles: new FormatStyles({ fontWeight: FontWeightEnum.bold, fontSize: '20px' }),
    });

    const systemNameAttributeItem = new CardWidgetAreaContentItem(ContentType.attribute, GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId));

    model.settings.artifactTypeId = artifactType.id;
    model.areas[0].content.items = [systemNameAttributeItem];
    model.settings.attributeStyles[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)] = {
      enumAttributeSettings: {},
      settings: new AttributeFormatSettings({ label: defaultLabelSettings, value: systemNameValueFormat }),
    };

    // push attributes sorted, create styles
    const attributesSorted = this.getAttributesSortedByPriority(
      artifactType.attributes,
      defaultPageParams,
      GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId),
    );
    attributesSorted.forEach(attribute => {
      model.areas[0].content.items.push(new CardWidgetAreaContentItem(ContentType.attribute, attribute.attribute.id));
      model.settings.attributeStyles[attribute.attribute.id] = {
        enumAttributeSettings: {},
        settings: new AttributeFormatSettings({
          label: defaultLabelSettings,
          value: this.getValueFormatSettings(attribute.dataType.baseDataType, attribute.attribute.multipleValues, attribute.dataType.kind),
        }),
      };
    });

    [NonAttributeKeys.CREATED_BY_ID, NonAttributeKeys.CREATED_ON_ID, NonAttributeKeys.UPDATED_BY_ID, NonAttributeKeys.UPDATED_ON_ID].forEach(id => {
      model.areas[0].content.items.push(new CardWidgetAreaContentItem(ContentType.attribute, id));
      model.settings.attributeStyles[id] = {
        enumAttributeSettings: {},
        settings: new AttributeFormatSettings({ label: defaultLabelSettings, value: this.getValueFormatSettings(BaseDataType.user, false) }),
      };
    });

    return model;
  }

  private getAttributesSortedByPriority(
    artifactTypeAttributes: Record<string, NewArtifactTypeClientAttribute>,
    defaultPageParams: DefaultPageParams,
    systemNameAttrId: string,
  ): AttributeWithPriorityOrder[] {
    const { attributes, dataTypes } = defaultPageParams;

    const attributesWithPriority: AttributeWithPriorityOrder[] = [];

    Object.keys(artifactTypeAttributes).forEach(id => {
      const attribute = attributes.listMap[id];
      const dataType = dataTypes.listMap[attribute.dataTypeId];
      const priority = this.getAttributeOrderPriority(attribute, dataType);
      attribute.id !== systemNameAttrId && attributesWithPriority.push({ attribute, dataType, priority });
    });

    return attributesWithPriority.sort((a, b) => a.priority - b.priority);
  }

  private getAttributeOrderPriority(attribute: NewAttribute, dataType: NewDataType): number {
    let priority = 4;
    if (dataType.baseDataType === BaseDataType.text) priority = 1;
    if (dataType.baseDataType === BaseDataType.html) {
      priority = 3;
      if ([DESCRIPTION_KEY, PRIMARY_TEXT_KEY].includes(attribute.name.toLowerCase())) priority = 2;
    }

    return priority;
  }

  private getValueFormatSettings(baseDataType: BaseDataType | null, multiValue: boolean, kind?: DataTypeKind): AttributeValueFormatSettings {
    if (baseDataType === BaseDataType.user) {
      return this.getUserValueFormatSettings(multiValue);
    }
    if (kind === DataTypeKind.enumerated) {
      return this.getEnumValueFormatSettings();
    }
    if (baseDataType === BaseDataType.file) {
      return this.getFileValueFormatSettings();
    }
    return new AttributeValueFormatSettings();
  }

  private getUserValueFormatSettings(multiValue: boolean): AttributeValueFormatSettings {
    if (multiValue) {
      return new AttributeValueFormatSettings({
        displayMetadata: { attributeType: DisplayAttributeType.user, selectedVariantCode: 'ICON_ONLY' },
      });
    } else {
      return new AttributeValueFormatSettings({
        displayMetadata: { attributeType: DisplayAttributeType.user, selectedVariantCode: 'ICON_AND_NAME' },
      });
    }
  }

  private getEnumValueFormatSettings(): AttributeValueFormatSettings {
    return new AttributeValueFormatSettings({
      displayMetadata: { attributeType: DisplayAttributeType.enumerated, selectedVariantCode: 'CHIP_V1' },
    });
  }

  private getFileValueFormatSettings(): AttributeValueFormatSettings {
    return new AttributeValueFormatSettings({
      displayMetadata: { attributeType: DisplayAttributeType.file, selectedVariantCode: 'DEFAULT' },
    });
  }

  private createDefaultListWidgetModel(): ListWidgetModel {
    const model = new ListWidgetModel();
    model.settings.loadMode = ListWidgetTableLoadModeEnum.byModule;
    model.settings.showAddButton = true;
    model.selected.columns = [
      new NewTableColumn({ label: NonAttributeKeys.IS_HEADING_LABEL, key: NonAttributeKeys.IS_HEADING }),
      new NewTableColumn({ label: NonAttributeKeys.SECTION_LABEL, key: NonAttributeKeys.SECTION }),
      new NewTableColumn({
        label: NonAttributeKeys.CONTENT_LABEL,
        key: NonAttributeKeys.CONTENT,
        meta: new NewTableColumnMetaData({
          displayAtEnumType: DisplayAttributeType.text,
          displayAtMetadata: { attributeType: DisplayAttributeType.text, selectedVariantCode: 'DEFAULT_CARD' },
        }),
      }),
    ];
    model.listType = ListWidgetType.artifact;
    model.settings.customColors = true;
    model.settings.background = { rows: 'transparent' };

    return model;
  }

  private createDefaultMenuWidgetModelDto(artifactWidgetAlias: string): MenuWidgetModelDto {
    const saveAction = this.createDefaultItemAction(ArtifactWidgetItemActions.save, artifactWidgetAlias);
    const saveAndNotifyAction = this.createDefaultItemAction(ArtifactWidgetItemActions.saveAndNotify, artifactWidgetAlias);
    const editAction = this.createDefaultItemAction(ArtifactWidgetItemActions.edit, artifactWidgetAlias);
    const cancelAction = this.createDefaultItemAction(ArtifactWidgetItemActions.cancel, artifactWidgetAlias);
    const deleteAction = this.createDefaultItemAction(ArtifactWidgetItemActions.delete, artifactWidgetAlias);
    const showAccessRightsAction = this.createDefaultItemAction(ArtifactWidgetItemActions.showAccessRights, artifactWidgetAlias);
    const showOperationHistoryAction = this.createDefaultItemAction(ArtifactWidgetItemActions.showOperationHistory, artifactWidgetAlias);
    const showMoreActionsAction = this.createDefaultItemAction(ArtifactWidgetItemActions.openMoreActions, artifactWidgetAlias);

    const saveButton = new TreeItem({ label: 'Save', usesDefaultStyle: false, actions: [saveAction], visible: false });
    const saveAndNotifyButton = new TreeItem({ label: 'Save and notify', usesDefaultStyle: false, actions: [saveAndNotifyAction], visible: false });
    const editButton = new TreeItem({ label: 'Edit', usesDefaultStyle: false, actions: [editAction], visible: false });
    const cancelButton = new TreeItem({ label: 'Cancel', usesDefaultStyle: false, actions: [cancelAction], visible: false });
    const deleteButton = new TreeItem({ label: 'Delete', menuIcon: 'pi pi-trash', usesDefaultStyle: false, actions: [deleteAction], visible: false });
    const showAccessRightsButton = new TreeItem({
      label: 'Show access rights',
      menuIcon: 'pi pi-users',
      usesDefaultStyle: false,
      actions: [showAccessRightsAction],
    });
    const showOperationHistoryButton = new TreeItem({
      label: 'Show operation history',
      menuIcon: 'bi bi-clock-history',
      usesDefaultStyle: false,
      actions: [showOperationHistoryAction],
    });
    const showMoreActionsButtons = new TreeItem({
      usesDefaultStyle: false,
      actions: [showMoreActionsAction],
      children: [deleteButton, showAccessRightsButton, showOperationHistoryButton],
    });

    const settings = new MenuWidgetSettings();
    settings.item[saveButton.hash] = new MenuItemSettings({ positioned: 'right' });
    settings.item[saveAndNotifyButton.hash] = new MenuItemSettings({ positioned: 'right' });
    settings.item[editButton.hash] = new MenuItemSettings({ positioned: 'right' });
    settings.item[cancelButton.hash] = new MenuItemSettings({ positioned: 'right' });
    settings.item[deleteButton.hash] = new MenuItemSettings({ positioned: 'right' });
    settings.item[showAccessRightsButton.hash] = new MenuItemSettings({ positioned: 'right' });
    settings.item[showOperationHistoryButton.hash] = new MenuItemSettings({ positioned: 'right' });
    settings.item[showMoreActionsButtons.hash] = new MenuItemSettings({ positioned: 'right' });

    const styles = new MenuWidgetAllStyles();

    this.setDefaultStylesByHash(styles, saveButton.hash, this.defaultSaveColor, this.defaultSaveHoverColor);
    this.setDefaultStylesByHash(styles, saveAndNotifyButton.hash, this.defaultSaveColor, this.defaultSaveHoverColor);
    this.setDefaultStylesByHash(styles, editButton.hash, this.defaultEditColor, this.defaultEditHoverColor, '#212529');
    this.setDefaultStylesByHash(styles, cancelButton.hash, this.defaultCancelColor, this.defaultCancelHoverColor);
    this.setDefaultStylesByHash(styles, showMoreActionsButtons.hash, 'transparent', 'transparent');
    this.setDefaultStylesByHash(styles, deleteButton.hash, 'transparent', '#ebebeb', '#212529');
    this.setDefaultStylesByHash(styles, showAccessRightsButton.hash, 'transparent', '#ebebeb', '#212529');
    this.setDefaultStylesByHash(styles, showOperationHistoryButton.hash, 'transparent', '#ebebeb', '#212529');
    this.setDefaultStylesForSaveButtons(styles, saveButton, saveAndNotifyButton);
    this.setDefaultStylesForEditAndCancelButtons(styles, editButton, cancelButton);
    this.setDefaultStylesForMoreActionsButton(styles, showMoreActionsButtons);
    this.setDefaultStylesForSubmenuActions(styles, deleteButton, showAccessRightsButton, showOperationHistoryButton);
    this.setDefaultSubmenuStyles(styles);

    const treeItems = [saveButton, saveAndNotifyButton, editButton, cancelButton, showMoreActionsButtons];
    const menuItems = this.menuWidgetHelper.convertTreeItemsToMenu(treeItems);
    menuItems.forEach(menu => (menu.visible = false));
    return { styles, settings, menuItems };
  }

  private createDefaultItemAction(action: ArtifactWidgetItemActions, artifactWidgetAlias: string): ActionItem {
    return new ActionItem({
      isPageSelection: true,
      model: { name: action },
      queryParams: [],
      type: action,
      useAlias: true,
      widgetAlias: artifactWidgetAlias,
    });
  }

  private setDefaultStylesByHash(
    styles: MenuWidgetAllStyles,
    hash: string,
    backgroundColor: string,
    hoverBackgroundColor: string,
    textColor = '#ffffff',
  ): void {
    const { item, label, subLabel, chip, icon } = MenuStylesPropNamesEnum;
    const borderRadius = '4px';
    const borderRadiusStyles: Partial<BorderStyles> = {
      borderTopRightRadius: borderRadius,
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    };

    const itemStandardStyles = new MenuItemStyles({ backgroundColor, ...borderRadiusStyles });
    const itemHoverStyles = new MenuItemStyles({ backgroundColor: hoverBackgroundColor, ...borderRadiusStyles });
    const labelStyles = new FontStyles({ color: textColor });

    styles.standardStyles[item][hash] = itemStandardStyles;
    styles.hoverStyles[item][hash] = itemHoverStyles;
    styles.activeStyles[item][hash] = itemStandardStyles;

    styles.standardStyles[label][hash] = labelStyles;
    styles.hoverStyles[label][hash] = labelStyles;
    styles.activeStyles[label][hash] = labelStyles;

    styles.standardStyles[subLabel][hash] = new FontStyles();
    styles.hoverStyles[subLabel][hash] = new FontStyles();
    styles.activeStyles[subLabel][hash] = new FontStyles();

    styles.standardStyles[chip][hash] = new ChipStyles();
    styles.hoverStyles[chip][hash] = new ChipStyles();
    styles.activeStyles[chip][hash] = new ChipStyles();

    styles.standardStyles[icon][hash] = new IconStyles();
    styles.hoverStyles[icon][hash] = new IconStyles();
    styles.activeStyles[icon][hash] = new IconStyles();

    styles.horizontalDivider[hash] = new HorizontalDividerStyles();
    styles.verticalDivider[hash] = new VerticalDividerStyles();
  }

  private setDefaultStylesForSaveButtons(styles: MenuWidgetAllStyles, saveButton: TreeItem, saveAndNotifyButton: TreeItem): void {
    styles.generalMenuItemStyles[saveButton.hash] = new MenuItemGeneralStyles({
      ...this.defaultVerticalButtonPaddings,
      paddingLeft: '36px',
      paddingRight: '36px',
      marginRight: '20px',
    });

    styles.generalMenuItemStyles[saveAndNotifyButton.hash] = new MenuItemGeneralStyles({
      ...this.defaultVerticalButtonPaddings,
      paddingLeft: '15px',
      paddingRight: '15px',
    });
  }

  private setDefaultStylesForEditAndCancelButtons(styles: MenuWidgetAllStyles, editButton: TreeItem, cancelButton: TreeItem): void {
    styles.generalMenuItemStyles[editButton.hash] = new MenuItemGeneralStyles({
      ...this.defaultVerticalButtonPaddings,
      paddingLeft: '36px',
      paddingRight: '36px',
    });

    styles.generalMenuItemStyles[cancelButton.hash] = new MenuItemGeneralStyles({
      ...this.defaultVerticalButtonPaddings,
      paddingLeft: '36px',
      paddingRight: '36px',
    });
  }

  private setDefaultStylesForMoreActionsButton(styles: MenuWidgetAllStyles, moreActionsButton: TreeItem): void {
    styles.standardStyles[MenuStylesPropNamesEnum.label][moreActionsButton.hash].color = '#2196F3';
    styles.hoverStyles[MenuStylesPropNamesEnum.label][moreActionsButton.hash].color = '#2196F3';
    styles.activeStyles[MenuStylesPropNamesEnum.label][moreActionsButton.hash].color = '#2196F3';
    styles.standardStyles[MenuStylesPropNamesEnum.label][moreActionsButton.hash].fontSize = '24px';
    styles.hoverStyles[MenuStylesPropNamesEnum.label][moreActionsButton.hash].fontSize = '24px';
    styles.activeStyles[MenuStylesPropNamesEnum.label][moreActionsButton.hash].fontSize = '24px';
    styles.generalMenuItemStyles[moreActionsButton.hash] = new MenuItemGeneralStyles({
      ...this.defaultVerticalButtonPaddings,
      paddingLeft: '15px',
      paddingRight: '15px',
      marginRight: '20px',
    });
  }

  private setDefaultStylesForSubmenuActions(
    styles: MenuWidgetAllStyles,
    deleteButton: TreeItem,
    showAccessRightsButton: TreeItem,
    showOperationHistoryButton: TreeItem,
  ): void {
    styles.generalMenuItemStyles[deleteButton.hash] = new MenuItemGeneralStyles({ ...this.defaultVerticalSubMenuButtonPaddings });
    styles.generalMenuItemStyles[showAccessRightsButton.hash] = new MenuItemGeneralStyles({ ...this.defaultVerticalSubMenuButtonPaddings });
    styles.generalMenuItemStyles[showOperationHistoryButton.hash] = new MenuItemGeneralStyles({ ...this.defaultVerticalSubMenuButtonPaddings });
  }

  private setDefaultSubmenuStyles(styles: MenuWidgetAllStyles): void {
    styles.subMenu.backgroundColor = '#ffffff';
    styles.subMenu.paddingTop = '7px';
    styles.subMenu.paddingBottom = '7px';
    styles.subMenu.paddingLeft = '7px';
    styles.subMenu.paddingRight = '7px';
  }
}
