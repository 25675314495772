import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { FontStyleEnum, FontWeightEnum, TextDecorationEnum } from '@widgets/shared/types/style.types';

export class TextStyleHelper {
  onBoldClick(styles: Partial<StylesDto>): void {
    styles.fontWeight = styles.fontWeight === FontWeightEnum.normal ? FontWeightEnum.bold : FontWeightEnum.normal;
  }

  onItalicClick(styles: Partial<StylesDto>): void {
    styles.fontStyle = styles.fontStyle === FontStyleEnum.normal ? FontStyleEnum.italic : FontStyleEnum.normal;
  }

  onUnderscoreClick(styles: Partial<StylesDto>): void {
    styles.textDecoration = styles.textDecoration === TextDecorationEnum.underline ? TextDecorationEnum.none : TextDecorationEnum.underline;
  }

  onLineThroughClick(styles: Partial<StylesDto>): void {
    styles.textDecoration = styles.textDecoration === TextDecorationEnum.lineThrough ? TextDecorationEnum.none : TextDecorationEnum.lineThrough;
  }
}
