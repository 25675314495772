import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { FormatHistoryMessageToClientPipe } from '@shared/components/artifact-history/pipes/format-history-message-to-client.pipe';
import { AttributeValueModule } from '@shared/components/attribute-value/attribute-value.module';
import { ChipDesignStylerModule } from '@shared/components/chip-design-styler/chip-design-styler.module';
import { IsFilterRangeTypePipe } from '@shared/components/date-filter/pipes/is-filter-range-type.pipe';
import { GridLayoutGeneratorModule } from '@shared/components/grid-layout-generator/grid-layout-generator.module';
import { ImageLoaderComponent } from '@shared/components/image-loader/image-loader.component';
import { ImagePointerModule } from '@shared/components/image-pointer/image-pointer.module';
import { ExportModalComponent } from '@shared/components/import-export-modals/export-modal/export-modal.component';
import { ImportModalComponent } from '@shared/components/import-export-modals/import-modal/import-modal.component';
import { PageElementStylerModule } from '@shared/components/page-element-styler/page-element-styler.module';
import { ParsedImportComponent } from '@shared/components/parsed-import/parsed-import.component';
import { TemplateMetaFormComponent } from '@shared/components/template-form/template-meta-form.component';
import { SectionGenerationFormComponent } from '@shared/components/templates/components/section-generation-form/section-generation-form.component';
import { WidgetListModule } from '@shared/components/widget-list/widget-list.module';
import { NotifyIfSelectedPageDeletedDirective } from '@shared/directives/notify-if-selected-page-deleted.directive';
import { ArtifactPipesModule } from '@shared/pipes/artifact-pipes/artifact-pipes.module';
import { GetAttributeLabelWithApplicationPipe } from '@shared/pipes/attribute-pipes/get-attribute-label-with-application.pipe';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { GetDataForFileArtifactPipe } from '@shared/pipes/file/get-data-for-file-artifact.pipe';
import { ProcessFileArtifactToFilesPipe } from '@shared/pipes/file/process-file-artifact-to-files.pipe';
import { AvrServices, AvrServicesArray } from '@shared/services/artifact-visual-representation/index.avr-services';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { ArtifactWidgetItemFormatSettingsComponent } from '@widgets/artifact-widget/components/format-settings/components/artifact-widget-item-format-settings/artifact-widget-item-format-settings.component';
import { AttributeFormatSettingsComponent } from '@widgets/artifact-widget/components/format-settings/components/attribute-format-settings/attribute-format-settings.component';
import { AttributeFormatValueStylesFormComponent } from '@widgets/artifact-widget/components/format-settings/components/attribute-format-settings/components/attribute-format-value-styles-form/attribute-format-value-styles-form.component';
import { AttributeInitialValueFormComponent } from '@widgets/artifact-widget/components/format-settings/components/attribute-format-settings/components/attribute-initial-value-form/attribute-initial-value-form.component';
import { LinkTypeLabelStylesFormComponent } from '@widgets/artifact-widget/components/format-settings/components/link-type-format-settings/components/link-type-label-styles-form/link-type-label-styles-form.component';
import { LinkTypeValueStylesFormComponent } from '@widgets/artifact-widget/components/format-settings/components/link-type-format-settings/components/link-type-value-styles-form/link-type-value-styles-form.component';
import { LinkTypeFormatSettingsComponent } from '@widgets/artifact-widget/components/format-settings/components/link-type-format-settings/link-type-format-settings.component';
import { FormatSettingsComponent } from '@widgets/artifact-widget/components/format-settings/format-settings.component';
import { FolderWidgetModule } from '@widgets/folder-widget/folder-widget.module';
import { IsRegularDateFilterTypePipe } from '@widgets/shared/components/artifact-filters/components/date-time-filter/is-regular-date-filter-type.pipe';
import { IsRegularEnumFilterTypePipe } from '@widgets/shared/components/artifact-filters/components/enum-filter/pipes/is-regular-enum-filter-type.pipe';
import { IsNumberFilterRangeTypePipe } from '@widgets/shared/components/artifact-filters/components/number-filter/pipes/is-number-filter-range-type.pipe';
import { IsRegularNumberFilterTypePipe } from '@widgets/shared/components/artifact-filters/components/number-filter/pipes/is-regular-number-filter-type.pipe';
import { IsRegularTextFilterTypePipe } from '@widgets/shared/components/artifact-filters/components/text-filter/pipes/is-regular-text-filter-type.pipe';
import { AttributeFormatLabelStylesFormModule } from '@widgets/shared/components/attribute-format-settings';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { FileArtifactViewerDirective } from '@widgets/shared/directives/fileArtifact-viewer.directive';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';

import { ArtifactHistoryComponent } from './components/artifact-history/artifact-history.component';
import { ArtifactHistoryViewComponent } from './components/artifact-history/components/artifact-history-view/artifact-history-view.component';
import { FormatHistoryAttributesToStringPipe } from './components/artifact-history/pipes/format-history-attributes-to-string.pipe';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { IsFilterCalendarTypePipe } from './components/date-filter/pipes/is-filter-calendar-type.pipe';
import { IsFilterNumericTypePipe } from './components/date-filter/pipes/is-filter-numeric-type.pipe';
import { FolderFilterComponent } from './components/folder-filter';
import { LinkOptionWithArrowComponent } from './components/link-arrow/link-option-with-arrow/link-option-with-arrow.component';
import { SidebarHeaderComponent } from './components/sidebar-header/sidebar-header.component';
import { TemplateDetailComponent } from './components/templates/components/template-detail/template-detail.component';
import { TemplatesListComponent } from './components/templates/components/templates-list/templates-list.component';
import { ShouldShowWidgetTypeFilterPipe } from './components/templates/pipes/should-show-widget-type-filter.pipe';

import { RowSelectorBackgroundColorPipe } from '@shared/pipes/row-selector/row-selector-background-color.pipe';
import { FileAttributeCheckFilesValidityPipe } from '@widgets/shared/pipes/file-attribute-check-files-validity.pipe';
import { AutofocusDirective } from './directives/autofocus.directive';
import { OverlayPanelShowFixDirective } from './directives/overlay-panel-show-fix.directive';
import { StopUnwantedClickPropagationDirective } from './directives/stop-unwanted-click-propagation.directive';
import { TableUnsortDirective } from './directives/table-unsort.directive';
import { GetModuleArtifactTypesPipe } from './pipes/artifact-type-pipes/get-module-artifact-types.pipe';
import { GetTextArtifactTypesPipe } from './pipes/artifact-type-pipes/get-text-artifact-types.pipe';
import { GetFileSizePipe } from './pipes/file/get-file-size.pipe';
import { GetFilesForArtifactPipe } from './pipes/file/get-files-for-artifact.pipe';
import { GetBooleanFilterValuePipe } from './pipes/filter/get-boolean-filter-value.pipe';
import { GetLinkQueryParamsPipe } from './pipes/links/get-link-query-params.pipe';
import { GetLinkUrlPipe } from './pipes/links/get-link-url.pipe';
import { LinkPipesModule } from './pipes/links/link-pipes.module';
import { IsWidgetOfTypePipe, UnderscoreToDashPipe } from './pipes/widget-pipes/is-widget-of-type.pipe';

const declarations = [
  NotifyIfSelectedPageDeletedDirective,
  TableUnsortDirective,
  IsWidgetOfTypePipe,
  UnderscoreToDashPipe,
  LinkOptionWithArrowComponent,
  GetLinkQueryParamsPipe,
  GetLinkUrlPipe,
  ImageLoaderComponent,
  AutofocusDirective,
  SectionGenerationFormComponent,
  GetAttributeLabelWithApplicationPipe,
  GetBooleanFilterValuePipe,
  TemplatesListComponent,
  TemplateDetailComponent,
  ParsedImportComponent,
  ImportModalComponent,
  ExportModalComponent,
  GetFileSizePipe,
  TemplateMetaFormComponent,
  FormatSettingsComponent,
  AttributeFormatSettingsComponent,
  AttributeFormatValueStylesFormComponent,
  AttributeInitialValueFormComponent,
  LinkTypeFormatSettingsComponent,
  ArtifactWidgetItemFormatSettingsComponent,
  LinkTypeLabelStylesFormComponent,
  LinkTypeValueStylesFormComponent,
  StopUnwantedClickPropagationDirective,
  OverlayPanelShowFixDirective,
  FileArtifactViewerDirective,
  FolderFilterComponent,
  RowSelectorBackgroundColorPipe,
  FileAttributeCheckFilesValidityPipe,
];

@NgModule({
  declarations: [
    ...declarations,
    GetFilesForArtifactPipe,
    GetDataForFileArtifactPipe,
    ProcessFileArtifactToFilesPipe,
    ArtifactHistoryComponent,
    FormatHistoryMessageToClientPipe,
    ArtifactHistoryViewComponent,
    FormatHistoryAttributesToStringPipe,
    DateFilterComponent,
    IsFilterCalendarTypePipe,
    IsFilterNumericTypePipe,
    IsFilterRangeTypePipe,
    IsNumberFilterRangeTypePipe,
    IsRegularNumberFilterTypePipe,
    IsRegularTextFilterTypePipe,
    IsRegularEnumFilterTypePipe,
    IsRegularDateFilterTypePipe,
    GetModuleArtifactTypesPipe,
    GetTextArtifactTypesPipe,
    SidebarHeaderComponent,
    ShouldShowWidgetTypeFilterPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    AccordionModule,
    AutoCompleteModule,
    CheckboxModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    OverlayPanelModule,
    ImagePointerModule,
    InputTextModule,
    InputTextareaModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    MenuModule,
    TabMenuModule,
    DataViewModule,
    FileUploadModule,
    TagModule,
    TreeModule,
    SkeletonModule,

    CommonPipesModule,
    PageElementStylerModule,
    GridLayoutGeneratorModule,
    WidgetListModule,
    AttributeValueModule,
    StyleFormModule,
    ChipDesignStylerModule,
    DialogModule,
    CalendarModule,
    InputNumberModule,
    DataTypePipesModule,
    ArtifactPipesModule,
    InputTextareaModule,
    AttributeFormatLabelStylesFormModule,
    EditorComponent,
    FolderWidgetModule,
    LinkPipesModule,
    FloatLabelModule,
  ],
  exports: [
    ...declarations,
    CommonPipesModule,
    PageElementStylerModule,
    GridLayoutGeneratorModule,
    WidgetListModule,
    AttributeValueModule,
    GetFilesForArtifactPipe,
    GetDataForFileArtifactPipe,
    ProcessFileArtifactToFilesPipe,
    ChipDesignStylerModule,
    ArtifactHistoryComponent,
    DateFilterComponent,
    GetTextArtifactTypesPipe,
    SidebarHeaderComponent,
    ImagePointerModule,
    IsNumberFilterRangeTypePipe,
    IsRegularNumberFilterTypePipe,
    IsRegularTextFilterTypePipe,
    IsRegularEnumFilterTypePipe,
    IsRegularDateFilterTypePipe,
    IsFilterNumericTypePipe,
    IsFilterCalendarTypePipe,
    IsFilterRangeTypePipe,
    RowSelectorBackgroundColorPipe,
  ],
  providers: [
    DialogService,
    ...AvrServicesArray,
    {
      provide: AvrServices,
      useFactory: (...avrServices: any[]) => {
        return new AvrServices(avrServices);
      },
      deps: AvrServicesArray as unknown as any[],
    },
  ],
})
export class ElvisSharedModule {}
