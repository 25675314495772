<div class="grid">
    <div class="col-12">
        <p-dropdown
            (onChange)="onChangeWorkflow()"
            [(ngModel)]="workflow"
            [options]="workflows.list | transformArrayToSelectOptions: NAME_KEY"
            [showClear]="true"
            [filter]="true"
            id="workflows"
            appendTo="body"
        >
        </p-dropdown>
    </div>

    <div class="col-6">
        <p-calendar
            (onChange)="onActionChange()"
            [(ngModel)]="scheduleDateTime"
            [showTime]="true"
            placeholder="Schedule time"
        ></p-calendar>
    </div>
    <div class="col-6">
        <p-checkbox
            (onChange)="onActionChange()"
            [(ngModel)]="waitUntilFinished"
            binary="true" class="mr-2 pt-2"
        ></p-checkbox>
        {{ 'wait until finished' | translate }}
    </div>


    <div class="col-12" *ngFor="let item of workflowData; index as i">
        <div class="grid">
            <div class="col-8">
                {{ item.key }}
            </div>
            <div class="col-4">
                <p-dropdown
                    (onChange)="onActionChange()"
                    [(ngModel)]="item.type"
                    [options]="valueItemTypeOptions"
                    id="workflows"
                    appendTo="body"
                >
                </p-dropdown>
            </div>
            <div class="col-12">
                <input
                    (blur)="onActionChange()"
                    [(ngModel)]="item.value"
                    [placeholder]="isStatic(item) ? item.valueType: 'url key or variable'"
                    class="p-inputtext full-width-container"
                    pInputText
                    type="text">
            </div>
        </div>
    </div>
</div>
