import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TenantLinkService } from '@api/services';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { AttributeValueToClient } from '@shared/methods/client-attribute.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { LinkType } from '@shared/types/link-type.types';
import { LinkRestrictionParamsBase, NewLink } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetService } from '@widgets/artifact-widget/services/artifact-widget.service';
import { ArtifactWidgetModel } from '@widgets/artifact-widget/types/artifact-widget.types';
import { ArtifactLinkDialogComponent } from '@widgets/shared/components/artifact-link-dialog/artifact-link-dialog.component';
import { LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { AttributeLinkSettable, SetLinkValueEvent } from '@workflows/shared';
import { ConfirmationService } from 'primeng/api';
import { filter, switchMap } from 'rxjs';

@Component({
  selector: 'app-artifact-widget-link-field',
  templateUrl: './artifact-widget-link-field.component.html',
  styleUrls: ['./artifact-widget-link-field.component.scss'],
})
export class ArtifactWidgetLinkFieldComponent implements AttributeLinkSettable {
  @Input() hash: string;
  @Input() linkAttributeId: string;
  @Input() model: ArtifactWidgetModel;
  @Input() linkDialog: ArtifactLinkDialogComponent;
  @Input() onAddLinkClick: (linkType: SelectOption<string, LinkType, LinkDirection>) => void;
  @Input() isLinkTypeRequired: boolean;
  @Output() onExternalLinkedArtifactsToBeAdded: EventEmitter<NewArtifact[]> = new EventEmitter();
  linkRestrictionParams?: LinkRestrictionParamsBase;
  linkMethods = LinkMethods;
  visible = true;
  private _linkType: SelectOption<string, LinkType, LinkDirection>;
  private _formatSettings: LinkTypeFormatSettings;

  constructor(
    @Inject(IS_PREVIEW_MODE) private readonly isPreviewMode: boolean,
    public readonly artifactWidgetService: ArtifactWidgetService,
    private readonly tenantLinkService: TenantLinkService,
    private readonly cache: NewCacheService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  get linkType(): SelectOption<string, LinkType, LinkDirection> {
    return this._linkType;
  }

  @Input() set linkType(linkType: SelectOption<string, LinkType, LinkDirection>) {
    this._linkType = linkType;
    this.linkRestrictionParams = linkType && { direction: linkType.meta, linkTypeId: linkType.value.id };
  }

  get formatSettings(): LinkTypeFormatSettings {
    return this._formatSettings;
  }

  @Input() set formatSettings(settings: LinkTypeFormatSettings) {
    this._formatSettings = settings;
    this.visible = this.isPreviewMode ? !settings.hideOnPageLoad : true;
  }

  deleteLinkBoilerplate(index: number): void {
    const link = this.model.newLinksMap[this.linkType.value.id];
    link[this.linkType.meta].splice(index, 1);

    if (!link.INCOMING?.length && !link.OUTGOING?.length) {
      delete this.model.newLinksMap[this.linkType.value.id];
    }
  }

  async onDeleteLinkClick(link: NewLink, index: number, id: string, direction: LinkDirection): Promise<void> {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this link?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await this.artifactWidgetService.deleteLink(link, index, id, direction);
        if (!this.model.linkMap[id].INCOMING?.length && !this.model.linkMap[id].OUTGOING?.length) {
          delete this.model.linkMap[id];
        }
      },
    });
  }

  onDeleteLink(link: NewLink) {
    const linkId = this.linkType.value.id;
    const index = this.model.linkMap[linkId][this.linkType.meta].findIndex(item => item === link);
    this.onDeleteLinkClick(link, index, linkId, this.linkType.meta);
  }

  setLinkValue(setLinkValue: SetLinkValueEvent): void {
    const { linkArtifactTypeId, linkedArtifact, targetLinkArtifactTypeId } = setLinkValue;
    const linkTypes: LinkType[] = this.model.options.linkTypes.list.filter(linkType =>
      linkType.isLinkingBothSourceAndDestination(linkArtifactTypeId, targetLinkArtifactTypeId),
    );

    const linkFilter = JSON.stringify({
      $and: [
        { $or: [{ destinationArtifactId: { $eq: { $oid: linkedArtifact.id } } }, { sourceArtifactId: { $eq: { $oid: linkedArtifact.id } } }] },
        { linkTypeId: { $in: linkTypes.map(linkType => ({ $oid: linkType.id })) } },
        { deleted: { $eq: null } },
      ],
    });
    this.tenantLinkService
      .linkControllerList({ body: { filter: linkFilter } })
      .pipe(
        filter(response => response.meta.totalCount > 0),
        switchMap(response => {
          const links = response.data;
          const artifactIds = links.map(({ destinationArtifactId, sourceArtifactId }) =>
            destinationArtifactId === linkedArtifact.id ? sourceArtifactId : destinationArtifactId,
          );
          return this.cache.data.artifacts.getMany$([...artifactIds], [{ artifactTypeId: { $eq: { $oid: targetLinkArtifactTypeId } } }]);
        }),
        filter(response => response.length > 0),
      )
      .subscribe(response => {
        const linkedArtifacts = response.map(dto => new NewArtifact({ dto, artifactTypesMap: this.model.options.artifactTypes.listMap }));
        linkedArtifacts.forEach(artifact => this.mapClientAttributes(artifact));
        this.onExternalLinkedArtifactsToBeAdded.emit(linkedArtifacts);
      });
  }

  private mapClientAttributes(artifact: NewArtifact): void {
    artifact.clientAttributes?.map(
      attribute =>
        (attribute.value = AttributeValueToClient(
          new AttributeToClientParams({
            dataTypes: this.model.options.dataTypes,
            attributes: this.model.options.attributes,
            clientAttribute: attribute,
            value: attribute.value,
          }),
        )),
    );
  }
}
