import { Directive } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { Subscription } from 'rxjs';

@Directive()
export class BaseWorkflowsAdministrationComponent {
  subscriptions: Subscription[] = [];
  artifactTypes: ListContainer<NewArtifactType> = new ListContainer();
  attributes: ListContainer<NewAttribute> = new ListContainer();
  dataTypes: ListContainer<NewDataType> = new ListContainer();
  linkTypes: ListContainer<LinkType> = new ListContainer();
  applications: ListContainer<NewApplication> = new ListContainer();

  constructor(
    private readonly cache: NewCacheService,
    protected readonly announcement: AnnouncementService,
  ) {}

  ngOnInit(): void {
    const data = this.cache.data;
    this.subscriptions = [
      data.artifactTypes.subscribe(
        artifactTypes =>
          artifactTypes &&
          this.artifactTypes.setList(
            (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
            ID_KEY,
          ),
      ),
      data.attributes.subscribe(
        attributes =>
          attributes &&
          this.attributes.setList(
            (attributes as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
            ID_KEY,
          ),
      ),
      data.dataTypes.subscribe(
        dataTypes =>
          dataTypes &&
          this.dataTypes.setList(
            (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
            ID_KEY,
          ),
      ),
      data.linkTypes.subscribe(
        linkTypes =>
          linkTypes &&
          this.linkTypes.setList(
            (linkTypes as LinkTypeResponseDto[]).map(dto => new LinkType(dto)),
            ID_KEY,
          ),
      ),
      data.applications.subscribe(applications => applications && this.applications.setList(applications as ApplicationResponseDto[], ID_KEY)),
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription && subscription.unsubscribe());
  }
}
