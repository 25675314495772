import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationResponseDto, FolderResponseDto, FolderUpdateRequestDto } from '@api/models';
import { FolderListResponseDto } from '@api/models/folder-list-response-dto';
import { TenantFolderService } from '@api/services/tenant-folder.service';
import { TenantTeamService } from '@api/services/tenant-team.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { Constants, TENANT_LABEL } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { NewApplication } from '@shared/types/application.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { StateKey } from '@shared/types/local-storage.types';
import { NewTeam } from '@shared/types/team.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { FolderWidgetHelper } from '@widgets/folder-widget/helpers/folder-widget.helper';
import { ContextType, FolderWidgetOptions } from '@widgets/folder-widget/types/folder-widget-options.types';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import {
  FOLDER_WIDGET_CONTEXT_MENU_WIDTH,
  PRIVATE_USER_FOLDER_LABEL,
  USER_DEFAULT_TEAM_FOLDER,
  USER_DEFAULT_TEAM_FOLDER_LABEL,
} from '@widgets/shared/constants/widget.constants';
import { WidgetDataShareService } from '@widgets/shared/services/widget-data-share.service';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom, Subscription } from 'rxjs';
import { FolderWidgetComponent } from '../folder-widget.component';
import { FolderTreeNode, FolderUrlKeyChange, FolderWidgetModel, FolderWidgetModelDto, FolderWidgetValue } from '../types/folder-widget.types';

@Injectable()
export class FolderWidgetService {
  c: FolderWidgetComponent;
  m: FolderWidgetModel;

  private selectedApplication$: Subscription;
  private lastAppId: string;
  private isLayoutMode: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private folderService: TenantFolderService,
    private confirmationService: ConfirmationService,
    private helper: FolderWidgetHelper,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly cache: NewCacheService,
    private localStorageService: LocalStorageService,
    private readonly elvisUtil: ElvisUtil,
    private readonly tenantTeamService: TenantTeamService,
    private readonly widgetDataShareService: WidgetDataShareService,
  ) {}

  init(context: FolderWidgetComponent, dto?: FolderWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new FolderWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new FolderWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
    this.m.options.handler = this.contextHandler.bind(this);
    this.isLayoutMode = context.isLayoutMode;
    context.isShowTenant !== undefined && (this.m.settings.showAllApp = context.isShowTenant);
    context.settings && (this.m.settings = context.settings);

    this.initFolderIds();
    this.initSubscriptions();
    this.checkSidebarPosition();

    lastValueFrom(this.tenantTeamService.teamControllerList({ filter: JSON.stringify({ deleted: { $eq: null } }) })).then(res => {
      this.m.options.teams = res.data.map(t => new NewTeam(t));
    });

    this.selectedApplication$ = this.applicationSwitcherService.selectedApplication$.subscribe(this.checkAndFetchData.bind(this));

    this.route.queryParams.subscribe(params => {
      if (!this.c.artifact) {
        this.m.selectedFolderIds = params[this.m.settings.folderUrlKey]?.split(',') || [];
        this.setSelected();
      }
    });
    this.c.artifact && (this.m.selectedFolderIds = [this.c.artifact.parentFolderId]);
  }

  checkAndFetchData(app: NewApplication): void {
    this.m.currentApp = this.c.page && this.c.page.applicationId !== app.id ? this.m.applications.find(app => app.id === this.c.page.applicationId)! : app;
    this.lastAppId !== app.id && this.isLayoutMode && this.fetchData();
    this.lastAppId = app.id;
  }

  deleteSubscriptions(): void {
    this.selectedApplication$ && this.selectedApplication$.unsubscribe();
  }

  updateMembers(): void {
    this.m.overlayAccess.hide();
  }

  onKeypress(e: any): void {
    e.key === 'Enter' && this.fetchData();
  }

  async dndHandler(e: any): Promise<void> {
    // if root folder is dragged then rollback dNd
    if (!e.dragNode.parent) {
      const id = e.dragNode.id;
      e.dropNode.children = e.dropNode.children.filter((ch: any) => ch.id !== id);
      this.m.folders.push(e.dragNode);
      this.m.folders = this.helper.sortFolders(this.m.folders);
      return;
    }

    // check if empty folder after move then fix icon
    this.fixIcon(this.m.folders);

    const folderSequence = await this.getFolderSequence(e);

    let parentFolderId = e.dropNode.parent?.id;
    if (!parentFolderId) {
      this.m.folders = this.m.folders.filter(folder => folder.id !== e.dragNode.id);
      parentFolderId = e.dropNode.id;
      if (e.dropNode.children?.length < 2) {
        await this.fetchNodeContent({ node: e.dropNode });
        e.dropNode.children.push(e.dragNode);
        e.dropNode.children = this.helper.sortFolders(e.dropNode.children);
      }
    }

    e.dropNode.children.forEach((ch: any) => {
      if (ch.id === e.dragNode.id) {
        parentFolderId = e.dropNode.id;
      }
    });

    const body: FolderUpdateRequestDto = {
      id: e.dragNode.id,
      parentFolderId,
      folderSequence,
    };

    await lastValueFrom(this.folderService.folderControllerUpdate({ body }));
  }

  // wait for p-tree completely put drag into drop node for detecting right sequence
  async getFolderSequence(e: any): Promise<number> {
    return new Promise(resolve => {
      setTimeout(() => {
        let folderSequence = 0;
        e.dragNode.parent?.children.forEach((child: any, index: number) => {
          if (child.id === e.dragNode.id) {
            folderSequence = index;
          }
        });

        resolve(folderSequence);
      });
    });
  }

  updateContextMenu(): void {
    const isMultiselect = this.helper.isMultipleSelect(this.m.selectedFile);
    const isRootFolder = this.helper.isRootFolder(this.m.selectedFile);
    this.m.options = new FolderWidgetOptions(isMultiselect, isRootFolder, this.m.options);
    this.m.options.handler = this.contextHandler.bind(this);
    this.fixPosition();
    this.hideAllOverlay();
  }

  updateUrlParams(folderIds: string[], moduleIds: string[], settings: FolderWidgetSettings): void {
    const queryParams: any = {};
    const excludeKeys: string[] = [];
    const { folderUrlKey, moduleUrlKey } = this.m.settings;

    if (settings.enableFolderUrl) {
      excludeKeys.push(folderUrlKey);
      queryParams[folderUrlKey] = folderIds.join(',') || null;
    }

    if (settings.enableModuleUrl) {
      excludeKeys.push(moduleUrlKey);
      queryParams[moduleUrlKey] = moduleIds.join(',') || null;
    }

    this.doUpdateUrl(queryParams, excludeKeys);
  }

  doUpdateUrl(queryParams: any, excludeKeys: string[]): void {
    Object.keys(this.route.snapshot.queryParams)
      .filter(key => !excludeKeys.includes(key))
      .forEach(key => {
        queryParams[key] = this.route.snapshot.queryParams[key];
      });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }

  changeUrlKey({ from, to }: FolderUrlKeyChange): void {
    const queryParams: any = {};
    queryParams[to] = this.route.snapshot.queryParams[from] || null;
    this.m.settings.folderUrlKey = to;

    Object.keys(this.route.snapshot.queryParams)
      .filter(key => key !== from)
      .forEach(key => {
        queryParams[key] = this.route.snapshot.queryParams[key];
      });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }

  hideAllOverlay(): void {
    this.m.details.hide();
    this.m.op.hide();
    this.m.overlayColor.hide();
    this.m.overlayAccess.hide();
  }

  contextHandler(e: any): void {
    if (e.item.label === ContextType.remove) {
      this.showRemoveFolder();
    } else if (e.item.label === ContextType.details) {
      this.getFolderDetails();
      this.m.details.show(e.originalEvent);
    } else if (e.item.label === ContextType.create) {
      this.m.folderName = '';
      this.m.isCreateFolder = true;
      this.m.op.show(e.originalEvent);
    } else if (e.item.label === ContextType.rename) {
      this.m.folderName = this.m.selectedFile[0].label || '';
      this.m.isCreateFolder = false;
      this.m.op.show(e.originalEvent);
    } else if (e.item.label === ContextType.color) {
      this.m.color = this.m.selectedFile[0].styles.color;
      this.m.overlayColor.show(e.originalEvent);
    } else if (e.item.label === ContextType.access) {
      this.m.currentFolderId = this.m.selectedFile[0].id;
      this.m.overlayAccess.show(e.originalEvent);
    }

    this.fixPosition();
  }

  fixPosition(): void {
    if (!this.m.selectedFile) return;

    setTimeout(() => {
      const overlayContainer: any = document.getElementById(this.m.containerId);
      const id = this.m.selectedFile[0]?.id + this.m.containerId;
      const targetEl: any = document.getElementById(id)?.closest('.p-treenode-content');
      const overlayEl: any = document.getElementById(this.m.currentFolderId)?.closest('.p-overlaypanel');
      if (!targetEl || !overlayEl) return;

      const targetOffset = targetEl.getBoundingClientRect();
      const leftGap = this.m.isSidebarOnRight ? -10 : 20;
      let left = targetOffset.left + leftGap + window.scrollX;

      if (this.m.isSidebarOnRight && overlayContainer.clientWidth < overlayEl.offsetWidth) {
        left -= FOLDER_WIDGET_CONTEXT_MENU_WIDTH - overlayContainer.clientWidth;
      }

      overlayEl.style.top = targetOffset.top + targetEl.offsetHeight + window.scrollY + 'px';
      overlayEl.style.left = left + 'px';
    });
  }

  updateColor(): void {
    const id = this.m.selectedFile[0].id;
    id &&
      this.helper.setFolderColor(this.helper.getFolderById(this.m.folders, id), this.m.color, this.m.settings.coloredFolderArrow, this.m.settings.coloredText);
    id && (this.m.folderColor[id] = this.m.color);
  }

  updateFolderIcons(folders: FolderTreeNode[]): void {
    folders.forEach(folder => {
      folder.collapsedIcon = folder.hasChilds ? this.m.settings.collapsedIcon || 'pi pi-folder' : this.m.settings.emptyFolderIcon;
      folder.expandedIcon = this.m.settings.expandedIcon || 'pi pi-folder-open';
      if (folder.moduleId) {
        folder.collapsedIcon = this.m.settings.moduleIcon;
      }
      folder.children && this.updateFolderIcons(folder.children);
    });
  }

  async getFolderDetails(): Promise<void> {
    const ids = [...new Set(this.m.selectedFile.map(node => ({ $oid: node.id })))];
    const $and = [{ parentFolderId: { $in: ids } }, { deleted: { $eq: null } }];
    this.m.folderCounter = (await lastValueFrom(this.folderService.folderControllerCount({ filter: JSON.stringify({ $and }) }))).count;
  }

  showRemoveFolder(): void {
    this.confirmationService.confirm({
      message: this.helper.getRemoveMessage(this.m.selectedFile),
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: this.removeFolder.bind(this),
    });
  }

  async removeFolder(): Promise<void> {
    let ids: string[] = this.m.selectedFile.filter(f => !f.parent?.id).map(f => f.id) || [];
    (await Promise.all(this.m.selectedFile.map(({ id }) => this.helper.getAllChildrenIds(id)))).forEach(arr => {
      ids = ids.concat(arr);
    });
    ids = [...new Set(ids)];

    await Promise.all(ids.map(id => lastValueFrom(this.folderService.folderControllerDelete({ id }))));

    // remove from ui
    this.m.selectedFile.forEach(folder => {
      folder.parent && (folder.parent.children = folder.parent?.children?.filter(ch => ch.id !== folder.id));
    });
  }

  async createFolder(): Promise<void> {
    const id = this.m.selectedFile[0]?.id || this.m.currentApp.defaultFolderId;
    const folder = this.helper.getFolderById(this.m.folders, id) || this.helper.getFolderById(this.m.folders, '');

    const node: FolderResponseDto = await lastValueFrom(this.folderService.folderControllerCreate({ body: { name: this.m.folderName, parentFolderId: id } }));

    !folder.children && (folder.children = []);
    folder.children.push(this.getNewNode(node));
  }

  async renameFolder(): Promise<void> {
    const selected = this.m.selectedFile[0];
    if (!selected) return;

    await lastValueFrom(this.folderService.folderControllerUpdate({ body: { id: selected.id, name: this.m.folderName } }));

    const folder = this.helper.getFolderById(this.m.folders, selected.id);
    folder.label = this.m.folderName;
  }

  async fetchNodeContent(e: any, checkExpanded = true): Promise<void> {
    if (e.node.label === '\\' || e.node.label === USER_DEFAULT_TEAM_FOLDER_LABEL || (checkExpanded && e.node.expanded === true && !!e.node.children.length))
      return;

    if (e.node.label === TENANT_LABEL) {
      await this.fetchData(true);
    } else {
      const parentValue = e.node.id ? { $oid: e.node.id } : null;
      const $and = [{ parentFolderId: parentValue }, { deleted: { $eq: null } }];
      const resp: FolderListResponseDto = await lastValueFrom(this.folderService.folderControllerList({ filter: JSON.stringify({ $and }) }));
      e.node.children = this.helper.sortFolders(resp.data.map(folder => this.getNewNode(folder)));
      (e.node as FolderTreeNode).children?.forEach(({ id }) => {
        this.widgetDataShareService.folderIds.push(id + this.m.containerId);
      });
    }

    e.node.expand = true;
    const node = this.helper.getFolderById(this.m.folders, e.node.id);
    !!node && (node.expanded = true);

    setTimeout(() => {
      this.helper.setAllFolderColors(e.node.children, this.m.folderColor, this.m.settings.coloredFolderArrow, this.m.settings.coloredText);
      this.helper.updateArrows(e.node.id, this.m.settings.showFolderArrow);
    });
  }

  async fetchData(isFetchTenant = false): Promise<void> {
    const parentValue = isFetchTenant || this.m.settings.showAllApp || !this.m.currentApp ? null : { $oid: this.m.currentApp.defaultFolderId };
    const $or: any = [{ parentFolderId: parentValue }];
    this.m.userPrivateFolderId && $or.push({ _id: { $oid: this.m.userPrivateFolderId } });

    if (this.m.settings.showUsersFolder && this.m.usersFolderId) {
      $or.push({ _id: { $oid: this.m.usersFolderId } });
    }

    if (this.m.settings.showTeamsFolder && this.m.teamsFolderId) {
      $or.push({ _id: { $oid: this.m.teamsFolderId } });
    }

    if (this.m.settings.enableSearch && this.m.searchFolder) {
      const value = { $regex: String(this.m.searchFolder), $options: 'i' };
      $or.push({ name: value });
      this.m.settings.searchInPath && $or.push({ folderPath: value });
    }

    const $and: any = [{ $or }, { deleted: { $eq: null } }];

    if (this.m.searchFolder) {
      const value = { $regex: String(this.m.searchFolder), $options: 'i' };
      $and.push({ $or: [{ name: value }, { folderPath: value }] });
    }

    try {
      const res: FolderListResponseDto = await lastValueFrom(this.folderService.folderControllerList({ filter: JSON.stringify({ $and }) }));

      this.sortFolders(res);
      this.storeFolderIds();
      this.setSelected();
      !isFetchTenant && !this.m.searchFolder && (await this.fixRootLabel());
      this.moveSpecialFoldersToRoot();
      if (this.m.settings.showAllApp) {
        this.checkSpecialFoldersVisible();
        this.setRestriction();
      } else {
        this.registerAppFolderForDrop();
      }
      this.uiUpdate();
    } catch (e) {
      console.log(e);
      // disable toast for best ux
      // await this.announcement.error('Failed to load folders');
    }
  }

  private registerAppFolderForDrop(): void {
    const id = this.m.folders[0]?.id;
    id && this.widgetDataShareService.folderIds.push(id + this.m.containerId);
  }

  private initFolderIds(): void {
    this.m.teamsFolderId = GlobalConstants.getValue(GlobalConstantsEnum.teamsFolderId) || '';
    this.m.usersFolderId = GlobalConstants.getValue(GlobalConstantsEnum.usersFolderId) || '';
  }

  private setRestriction(): void {
    this.m.folders.forEach(folder => {
      folder.draggable = false;
    });
  }

  private checkSpecialFoldersVisible(): void {
    const ids: string[] = [];
    !this.m.settings.showTeamsFolder && ids.push(this.m.teamsFolderId);
    !this.m.settings.showUsersFolder && ids.push(this.m.usersFolderId);

    if (this.m.folders[0]?.label === TENANT_LABEL) {
      if (!this.m.folders[0].children) return;

      this.m.folders[0].children = this.m.folders[0].children.filter(node => {
        return !ids.includes(node.id);
      });
    } else {
      this.m.folders = this.m.folders.filter(node => {
        return !ids.includes(node.id);
      });
    }
  }

  private moveSpecialFoldersToRoot(): void {
    let folderList = this.m.settings.showAllApp ? this.m.folders : this.m.folders[0]?.children;
    if (!folderList) return;

    const usersFolderNode = folderList.find(node => node.id === this.m.usersFolderId);
    const teamsFolderNode = folderList.find(node => node.id === this.m.teamsFolderId);
    const privateFolder = folderList.find(node => node.id === this.m.userPrivateFolderId);
    const arr = [];

    if (privateFolder) {
      folderList = folderList.filter(node => node.id !== this.m.userPrivateFolderId);
      privateFolder.label = PRIVATE_USER_FOLDER_LABEL;
      arr.push(privateFolder);

      if (!this.m.settings.showAllApp) {
        this.m.folders.forEach(folder => {
          folder.children && (folder.children = folder.children?.filter(node => node.id !== this.m.userPrivateFolderId));
        });
      }
    }

    if (this.c.isShowUserDefaultTeamFolder) {
      arr.push(this.getNewNode({ name: USER_DEFAULT_TEAM_FOLDER_LABEL, id: USER_DEFAULT_TEAM_FOLDER, hasChilds: false } as FolderResponseDto));
    }

    if (usersFolderNode) {
      folderList = folderList.filter(node => node.id !== this.m.usersFolderId);
      arr.push(usersFolderNode);
    }

    if (teamsFolderNode) {
      folderList = folderList.filter(node => node.id !== this.m.teamsFolderId);
      arr.push(teamsFolderNode);
    }

    const list = this.m.settings.showAllApp ? folderList : this.m.folders;
    arr && (this.m.folders = [...arr, ...list]);

    if (this.m.settings.isHorizontal) {
      const node = this.getNewNode({ name: TENANT_LABEL, id: '', hasChilds: true } as FolderResponseDto);
      node.children = [...this.m.folders];
      this.m.folders = [node];
    }

    this.m.folders.forEach(folder => {
      folder.children && (folder.children = folder.children.filter(item => this.cutSpecialFolders(item)));
    });
  }

  private cutSpecialFolders(folder: FolderResponseDto | FolderTreeNode): boolean {
    return !(
      (this.m.settings.showUsersFolder && folder.id === this.m.usersFolderId) ||
      (this.m.settings.showTeamsFolder && folder.id === this.m.teamsFolderId)
    );
  }

  private sortFolders(res: FolderListResponseDto): void {
    this.m.folders = this.helper.sortFolders(res.data.map((item: any) => this.getNewNode(item))) || [];
  }

  private storeFolderIds(): void {
    !this.widgetDataShareService.folderIds && (this.widgetDataShareService.folderIds = []);
    this.m.folders.forEach(folder => this.widgetDataShareService.folderIds.push(folder.id + this.m.containerId));
  }

  private async fixRootLabel(): Promise<void> {
    // horizontal mode or only one app need root for display tree
    if (this.m.settings.isHorizontal || !this.m.settings.showAllApp) {
      const id = this.m.settings.showAllApp ? '' : this.m.currentApp?.defaultFolderId;

      let label = '\\';
      if (this.m.currentApp?.defaultFolderId) {
        const rootFolder = await lastValueFrom(this.folderService.folderControllerGet({ id: this.m.currentApp.defaultFolderId }));
        label = this.m.settings.showAllApp ? TENANT_LABEL : rootFolder.name;
      }

      // folder-picker: catch duplicate root
      if (this.m.folders[0]?.id !== id) {
        const node = this.getNewNode({ name: label, id, hasChilds: this.m.folders.length > 0 } as FolderResponseDto);
        node.children = this.m.folders.map(item => item);
        if (node.children[0]?.label === PRIVATE_USER_FOLDER_LABEL) {
          const privateFolder = node.children[0];
          node.children = node.children.slice(1);
          this.m.folders = [privateFolder, node];
          this.m.selectedFile.map(f => f.id).includes(privateFolder.id) && this.fetchNodeContent({ node: privateFolder }, false);
        } else {
          this.m.folders = [node];
        }
        this.m.selectedFolderIds.includes(node.id) && this.m.selectedFile.push(node);
      }
    }
  }

  private uiUpdate(): void {
    if (!this.m.folders[0]) return;

    !this.m.settings.showAllApp && (this.m.folders[0].expanded = true);

    setTimeout(() => {
      this.helper.updateArrows(this.m.folders[0].id, this.m.settings.showFolderArrow);
      this.helper.setAllFolderColors(this.m.folders, this.m.folderColor, this.m.settings.coloredFolderArrow, this.m.settings.coloredText);
    });
  }

  private setSelected(): void {
    this.m.selectedFile = this.m.folders.filter(f => this.m.selectedFolderIds.includes(f.id));
    this.m.selectedFile.length !== this.m.selectedFolderIds.length && this.fetchPathToSelectedChildren();
  }

  private async fetchPathToSelectedChildren(): Promise<void> {
    const ids = this.m.selectedFile.map(f => f.id);
    const childrenIds = this.m.selectedFolderIds.filter(id => !!id && !ids.includes(id));

    await Promise.all(childrenIds.map(id => this.getChildPath(id, [])));

    childrenIds.forEach(id => {
      const folder = this.helper.getFolderById(this.m.folders, id);
      folder && this.m.selectedFile.push(folder);
    });
  }

  private async getChildPath(id: string, list: any[], childNode?: FolderTreeNode): Promise<any> {
    const res: FolderResponseDto = await lastValueFrom(this.folderService.folderControllerGet({ id }));
    const node = this.getNewNode(res, true);
    await this.fetchNodeContent({ node }, false);
    node.parentId = res.parentFolderId as string;

    if (childNode && node.children) {
      node.children = node.children.filter(ch => ch.id !== childNode.id);
      node.children.push(childNode);
      node.children = node.children.sort(({ folderSequence: a = 0 }, { folderSequence: b = 0 }) => {
        return a > b ? 1 : a === b ? 0 : -1;
      });

      if (!res.parentFolderId) {
        const rootFolder = this.helper.getFolderById(this.m.folders, node.id);
        if (!rootFolder) return;

        if (!rootFolder?.children?.length) {
          rootFolder.expanded = true;
          rootFolder.children = node.children;
        } else {
          this.mergeChildren(rootFolder.children, node.children);
        }
      }
    }

    list.push(node);
    res.parentFolderId && (await this.getChildPath(res.parentFolderId, list, node));

    return list;
  }

  // check if folder widget inside sidebar and if it on right set flag for fixed context popup position
  private checkSidebarPosition(): void {
    setTimeout(() => {
      const overlayContainer: any = document.getElementById(this.m.containerId);
      if (!overlayContainer) return;
      this.m.isSidebarOnRight = overlayContainer.closest('.p-sidebar')?.classList.contains('p-sidebar-right');
    });
  }

  private mergeChildren(root: FolderTreeNode[] = [], node: FolderTreeNode[] = []): FolderTreeNode[] {
    node.forEach(child => {
      const ch = this.helper.getFolderById(root, child.id);
      if (ch) {
        ch.expanded = true;
        ch.children = this.mergeChildren(ch.children, child.children);
      } else {
        root.push(child);
      }
    });
    return root;
  }

  private fixIcon(folders: FolderTreeNode[]): void {
    folders.forEach(f => {
      f.hasChilds = f.children && f.children?.length > 0;
      f.collapsedIcon = f.hasChilds ? this.m.settings.collapsedIcon : this.m.settings.emptyFolderIcon;
      f.children && this.fixIcon(f.children);
    });
  }

  private getNewNode(dto: FolderResponseDto, expanded?: boolean): FolderTreeNode {
    return {
      expanded,
      id: dto.id,
      label: dto.name,
      hasChilds: dto.hasChilds,
      folderPath: dto.folderPath,
      folderSequence: dto.folderSequence || 0,
      children: [],
      expandedIcon: this.m.settings.expandedIcon,
      collapsedIcon: dto.moduleId ? this.m.settings.moduleIcon : dto.hasChilds ? this.m.settings.collapsedIcon : this.m.settings.emptyFolderIcon,
      styles: { color: '' },
      moduleId: dto.moduleId,
    };
  }

  private initSubscriptions(): void {
    const data = this.cache.data;

    this.c.registerSubscriptions([
      data.applications.subscribe(applications => {
        this.m.applications = applications as ApplicationResponseDto[];

        const applicationId: string = this.c?.page?.applicationId || this.localStorageService.getFromState(StateKey.session, Constants.selectedApplication);
        const app = this.m.applications.find(app => app.id === applicationId);
        this.c.isShowUserFolder && (this.m.userPrivateFolderId = GlobalConstants.getValue(GlobalConstantsEnum.currentUserPrivateFolderId) || '');

        app && this.checkAndFetchData(app);
      }),
    ]);
  }
}
