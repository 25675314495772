<div class="grid">
  <div class="col-6">
    <h5>{{ 'Filtering' | translate }}</h5>
  </div>
  <div class="col-6">
    <button (click)="addFilter()" class="d-block ml-auto" label="Add filter" pButton type="button"></button>
  </div>

  <div class="col-12">
    <p-accordion>
      <p-accordionTab
        *ngFor="let filter of filters; let filterIndex = index"
        [(selected)]="tabsActiveState[filterIndex]"
        [header]="filter.name"
        [selected]="preselectAttribute?.name === filter.name"
        [ngClass]="preselectAttribute?.name === filter.name ? containerName: ''"
        class="filter-tab"
      >
        <a (click)="removeFilter(filter)"
           class="pi pi-trash p-button p-button-rounded p-button-text p-button-danger remove-filter" pRipple> </a>

        <p-dropdown
          (ngModelChange)="onFilterAttributeChange($event, filter, filterIndex)"
          [dataKey]="filter.isLinkFilter ? 'idAndDirection' : null"
          [filter]="true"
          [ngModel]="filter.attribute"
          [options]="attributeAndLinkOptions"
          placeholder="Attribute or LinkType"
        >
          <ng-template let-option pTemplate="item">
            <app-link-option-with-arrow
              *ngIf="option.value.direction; else nonLinkAttributeTemplate"
              [direction]="option.value.direction"
              [label]="option.label"
            >
            </app-link-option-with-arrow>
            <ng-template #nonLinkAttributeTemplate>{{ option.label }}</ng-template>
          </ng-template>
          <ng-template let-selectedOption pTemplate="selectedItem">
            <app-link-option-with-arrow
              *ngIf="selectedOption.value.direction; else nonLinkAttributeTemplate"
              [direction]="selectedOption.value.direction"
              [label]="selectedOption.label"
            >
            </app-link-option-with-arrow>
            <ng-template #nonLinkAttributeTemplate>{{ selectedOption.label }}</ng-template>
          </ng-template>
        </p-dropdown>

        <app-enum-filter (onFilterChange)="onFilterChange()" *ngIf="filterTypeDetectionService.isEnum(filter)"
                         [m]="filter"></app-enum-filter>
        <app-user-filter (onFilterChange)="onFilterChange()" *ngIf="filterTypeDetectionService.isUser(filter)"
                         [m]="filter"></app-user-filter>
        <app-number-filter
          (onFilterChange)="onFilterChange()"
          *ngIf="filterTypeDetectionService.isInteger(filter) || filterTypeDetectionService.isDecimal(filter)"
          [m]="filter"
        >
        </app-number-filter>
        <app-text-filter
          (onFilterChange)="onFilterChange()"
          *ngIf="filterTypeDetectionService.isText(filter) || filterTypeDetectionService.isCounter(filter) || filterTypeDetectionService.isHtml(filter) || filterTypeDetectionService.isFolderPath(filter) || filterTypeDetectionService.isHyperlink(filter)"
          [m]="filter"
        >
        </app-text-filter>
        <app-boolean-filter (onFilterChange)="onFilterChange()" (onClear)="onFilterChange()"
                            *ngIf="filterTypeDetectionService.isBoolean(filter)" [m]="filter"></app-boolean-filter>
        <app-file-filter (onFilterChange)="onFilterChange()" *ngIf="filterTypeDetectionService.isFile(filter)"
                         [m]="filter"></app-file-filter>
        <app-date-time-filter
          (onFilterChange)="onFilterChange()"
          *ngIf="filterTypeDetectionService.isDateTime(filter)"
          [dataType]="filter.dataType?.baseDataType"
          [m]="filter"
        >
        </app-date-time-filter>
        <app-link-filter (onFilterChange)="onFilterChange()" *ngIf="filterTypeDetectionService.isLink(filter)"
                         [m]="filter"></app-link-filter>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
