<p-card>
  <div class="gradient-generator-container">
    <!-- Gradient Preview -->
    <div class="gradient-preview" [style.background]="gradientCss">
      <p>Gradient Preview</p>
    </div>

    <!-- Gradient Controls -->
    <div class="controls">
      <!-- Gradient Type -->
      <div class="control">
        <label class="mr-2">{{ 'Gradient Type:' | translate }}</label>
        <p-radioButton class="mr-2" name="gradientType" value="linear-gradient" [(ngModel)]="gradientType"
                       label="Linear" (ngModelChange)="onChange.emit()"></p-radioButton>
        <p-radioButton class="mr-2" name="gradientType" value="radial-gradient" [(ngModel)]="gradientType"
                       label="Radial" (ngModelChange)="onChange.emit()"></p-radioButton>
      </div>

      <!-- Angle Control -->
      @if (gradientType === 'linear-gradient') {
        <div class="control">
          <label for="angle">Angle:</label>
          <div class="angle-slider">
            <p-slider [(ngModel)]="angle" [min]="0" [max]="360" [style]="{width: '200px'}"
                      (ngModelChange)="onChange.emit()"></p-slider>
            <p-inputNumber class="mt-1" [(ngModel)]="angle" [min]="0" [max]="360" [suffix]="' deg'"
                           (ngModelChange)="onChange.emit()"></p-inputNumber>
          </div>
        </div>
      }

      <!-- Color Stops -->
      <div class="color-stops">
        <h3>Color Stops</h3>
        @for (color of colors; let i = $index; track color.id) {
          <div class="color-stop">
            <p-colorPicker [(ngModel)]="colors[i].color" format="hex" (ngModelChange)="onChange.emit()"></p-colorPicker>
            <p-inputNumber [(ngModel)]="colors[i].position" [min]="0" [max]="100"
                           (ngModelChange)="onChange.emit()"></p-inputNumber>
            <span>%</span>

            <p-button icon="pi pi-trash" class="p-button-danger" (click)="removeColor(i)"></p-button>
          </div>
        }

        <p-button label="Add Color" icon="pi pi-plus" class="p-button-sm" (click)="addColor()"></p-button>
      </div>

      <!-- Presets -->
      <div class="presets">
        <h3>Presets</h3>
        @for (preset of gradientPresets; track preset.id) {
          <div class="preset" (click)="applyPreset(preset)">
            <div class="preset-preview" [style.background]="preset.css"></div>
            <span>{{ preset.name }}</span>
          </div>
        }
      </div>
    </div>

    <!-- CSS Code -->
    <div class="css-output">
      <h3>CSS Code</h3>
      <textarea
        style="width: 100%;"
        [autoResize]="true"
        [rows]="3"
        [readonly]="true"
        pInputTextarea
        [ngModel]="gradientCss"
      ></textarea>
      <p-button label="Copy CSS" icon="pi pi-copy" (click)="copyCss()"></p-button>
    </div>
  </div>
</p-card>