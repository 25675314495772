import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import {
  ElementDisplayStylesDtoKeysArray,
  FlexContainerStylesDtoKeysArray,
  StylesDtoKeys,
} from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { DisplayAtSelectionItem } from '@shared/types/display-at-types';
import { LinkRestrictionParamsBase, NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ArtifactLinks } from '@widgets/shared/components/artifact-list-table/types/artifact-list-widget-table.types';
import { ArtifactClickHandlerService } from '@widgets/shared/services/artifact-click-handler.service';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { Subscription } from 'rxjs';
import { DisplayAtControlService } from '../../services';
import { AbstractDisplayAtComponent } from '../abstract-display-at.component';

@Component({
  selector: 'app-display-at-link',
  templateUrl: './display-at-link.component.html',
  styleUrls: ['display-at-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtLinkComponent extends AbstractDisplayAtComponent {
  static readonly ITEMS_PER_PAGE = 5;

  @Input() artifactTypes: ListContainer<NewArtifactType>;
  @Input() linkRestrictionParams: LinkRestrictionParamsBase;
  @Input() links: Record<string, Record<string, ArtifactLinks>> = {};
  @Input() linkedData: Record<string, NewArtifact> = {};
  @Input() linksDto: LinkResponseDto[] = [];
  @Input() files: Record<string, ArtifactResponseDto> = {};
  @Input() filesLoaded: boolean;
  @Input() linkStyleClass: string;
  @Input() showAddLinkButton = true;
  @Input() showRemoveButton = true;
  @Input() addLinkStyleClass = 'p-button-rounded p-button-text button-add-link';
  @Input() deleteLinkStyleClass = 'p-button-rounded p-button-danger p-button-outlined';
  @Input() showMoreStyleClass = 'button-show-more';
  @Input() hideMoreStyleClass = 'button-hide-more';
  @Input() itemsPerPage = DisplayAtLinkComponent.ITEMS_PER_PAGE;
  @Input() tooltipEnabled: boolean;
  @Input() showLinkButtons = true;
  @Input() valueStyles: FormatStyles;
  @Input() columnStylesEvent: RuntimeStateNotification<any> | undefined;

  @Output() onAddLinkClick: EventEmitter<string> = new EventEmitter();
  @Output() onDeleteLinkClick: EventEmitter<NewLink> = new EventEmitter();

  currentPage = 0;
  topContainerRelevantStyleKeys: StylesDtoKeys[] = [];
  private eventsSubscription: Subscription;

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly artifactClickHandlerService: ArtifactClickHandlerService,
    protected readonly cdr: ChangeDetectorRef,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    super(isPreviewMode, displayAtControlService, dataTypeHelper, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.init();
    this.setCssKeys();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventsSubscription && this.eventsSubscription.unsubscribe();
  }

  addLinkClicked(event: MouseEvent, attributeId: string = this.attributeId): void {
    event.stopPropagation();
    this.onAddLinkClick.emit(attributeId);
  }

  deleteLink(link: NewLink, event: Event): void {
    this.onDeleteLinkClick.emit(link);
    this.stopEventPropagation(event);
  }

  handleLinkClick(link: NewLink, event: PointerEvent) {
    const artifact = this.getTargetArtifactFromLink(link);
    if (artifact) {
      this.handleLinkedArtifactClick(artifact, event);
    }
  }

  handleLinkedArtifactClick(artifact: NewArtifact, event: PointerEvent) {
    const openInNewTab = event.ctrlKey || event.metaKey;
    const artifactType = this.artifactTypes.listMap[artifact.artifactTypeId];
    const commands = artifactType?.defaultPageId ? [artifactType.defaultPageId] : [];
    const queryParams = { artifactId: artifact.id };
    const extras = { queryParams };
    this.artifactClickHandlerService.openPage({ openInNewTab, commands, extras });
    this.stopEventPropagation(event);
  }

  showMoreItems(event: Event) {
    this.currentPage++;
    event.stopPropagation();
  }

  hideMoreItems(event: Event) {
    this.currentPage = 0;
    event.stopPropagation();
  }

  paginate(pageChangeEvent: any) {
    this.currentPage = pageChangeEvent.page;
  }

  protected onDisplayAtSelectionChange(selection: DisplayAtSelectionItem) {
    super.onDisplayAtSelectionChange(selection);
    this.currentPage = 0;
  }

  private init(): void {
    this.eventsSubscription = this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<any>) => {
      if (event.type === RuntimeStateNotificationEnum.updateColumnStyles) {
        event?.data?.key === this.attributeId && this.cdr.markForCheck();
      }
    });
  }

  private getTargetArtifactFromLink(link: NewLink): NewArtifact | null {
    const targetArtifactId = this.getTargetArtifactIdFromLink(link);
    return this.linkedData[targetArtifactId] || null;
  }

  private getTargetArtifactIdFromLink(link: NewLink): string {
    return this.artifact.id === link.sourceArtifactId ? link.destinationArtifactId : link.sourceArtifactId;
  }

  private setCssKeys() {
    this.topContainerRelevantStyleKeys = [...FlexContainerStylesDtoKeysArray, ...ElementDisplayStylesDtoKeysArray];
  }
}
