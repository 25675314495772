import { WorkflowActionDto } from '@api/models';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { AttributeActionHandlerService } from '@workflows/shared';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { CommonFeAction } from '@workflows/types/actions/common-fe-action';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAttributeBasedAction } from './abstract/abstract-attribute-based.action';

export interface WorkflowActionResetAttributeValuesDto extends WorkflowActionDto {
  dataTypeId: string;
}

export class WorkflowActionResetAttributeValues extends AbstractWorkflowAttributeBasedAction<CommonFeAction> implements WorkflowActionResetAttributeValuesDto {
  dataTypeId: string;

  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto, actionAttributeHandler?: AttributeActionHandlerService) {
    super({ actionSettingDto: CommonFeAction, conditionConverter, type: WorkflowActionType.RESET_ATTRIBUTE_VALUES, dto, actionAttributeHandler });
    this.dataTypeId = (dto as WorkflowActionResetAttributeValuesDto)?.dataTypeId;
    this.supportedAttributeDataKinds = [DataTypeKind.enumerated];
  }

  canBeExecuted(): boolean {
    return this.isValid();
  }

  execute(): void {
    this.actionAttributeHandler.notifySetAttributeOptionsEvent({
      artifactTypeId: this.artifactTypeId,
      attributeId: this.attributeId,
      value: [],
    });
  }

  isValid(): boolean {
    return this.actionAttributeHandler && !!(this.artifactTypeId && this.attributeId && this.dataTypeId);
  }

  toServer(): WorkflowActionResetAttributeValuesDto {
    return {
      ...super.toServer(),
      dataTypeId: this.dataTypeId,
    };
  }
}
