import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';

@Pipe({
  name: 'fileAttributeCheckFilesValidity',
})
export class FileAttributeCheckFilesValidityPipe implements PipeTransform {

  transform(files: ArtifactResponseDto | ArtifactResponseDto[]): boolean {
    if (!files) {
      return false;
    }
    const filesArray = Array.isArray(files) ? files : [files];
    return filesArray.length > 0;
  }
}
