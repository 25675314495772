import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayAtEnumModule } from '@shared/components/common-display-at';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ArtifactWidgetActionsComponent } from '@widgets/shared/components/action-list/components/artifact-widget-actions/artifact-widget-actions.component';
import { ListWidgetActionsComponent } from '@widgets/shared/components/action-list/components/list-widget-actions/list-widget-actions.component';
import { IsShowQueryOptionsPipe } from '@widgets/shared/components/action-list/components/list-widget-actions/pipes/is-show-query-options.pipe';
import { MatrixWidgetActionsComponent } from '@widgets/shared/components/action-list/components/matrix-widget-actions/matrix-widget-actions.component';
import { MenuWidgetActionsComponent } from '@widgets/shared/components/action-list/components/menu-widget-actions/menu-widget-actions.component';
import { QueryParamsComponent } from '@widgets/shared/components/action-list/components/query-params/query-params.component';
import { IsArtifactWidgetSpecialPipe } from '@widgets/shared/components/action-list/pipes/is-artifact-widget-special.pipe';
import { IsListWidgetSpecialPipe } from '@widgets/shared/components/action-list/pipes/is-list-widget-special.pipe';
import { IsMenuWidgetSpecialPipe } from '@widgets/shared/components/action-list/pipes/is-menu-widget-special.pipe';
import { ActionListService } from '@widgets/shared/components/action-list/services/action-list-service';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import { SelectedValuesToIndexesPipe } from '@widgets/shared/components/list-item-click-action-settings-form/pipes/enum-values-to-indexes.pipe';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { CustomEventSettingsFormModule } from '../custom-event-settings-form/custom-event-settings-form.module';
import { StyleFormModule } from '../style-form.module';
import { ActionListComponent } from './action-list.component';

@NgModule({
  declarations: [
    ActionListComponent,
    ListWidgetActionsComponent,
    MatrixWidgetActionsComponent,
    MenuWidgetActionsComponent,
    QueryParamsComponent,
    IsListWidgetSpecialPipe,
    IsMenuWidgetSpecialPipe,
    IsShowQueryOptionsPipe,
    SelectedValuesToIndexesPipe,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    SharedModule,
    TreeModule,
    InputTextModule,
    FormsModule,
    CalendarModule,
    TranslateModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    CheckboxModule,
    OverlayPanelModule,
    StyleFormModule,
    FloatLabelModule,
    TreeSelectModule,
    ElvisSharedModule,
    CustomEventSettingsFormModule,
    DisplayAtEnumModule,
    ArtifactFiltersModule,
    AccordionModule,
    IsArtifactWidgetSpecialPipe,
    ArtifactWidgetActionsComponent,
  ],
  exports: [ActionListComponent, ListWidgetActionsComponent, MatrixWidgetActionsComponent, MenuWidgetActionsComponent, QueryParamsComponent],
  providers: [ActionListService, IsListWidgetSpecialPipe, IsMenuWidgetSpecialPipe, IsShowQueryOptionsPipe],
})
export class ActionListModule {}
