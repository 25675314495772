import { Pipe, PipeTransform } from '@angular/core';
import { StylesDto, StylesDtoKeys } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';

@Pipe({
  name: 'excludeNotRelevantStyles',
  standalone: true,
})
export class ExcludeNotRelevantStylesPipe implements PipeTransform {
  transform(styles: Partial<StylesDto>, keysForExclude: StylesDtoKeys[], columnStylesEvent: RuntimeStateNotification): Partial<StylesDto> {
    const clonedStyles = { ...styles };
    keysForExclude.forEach(key => delete clonedStyles[key]);

    return clonedStyles;
  }
}
