<div *ngIf="file$ | async as file">
  <ng-container *ngIf="isDefaultLayout">
    <ng-container *ngTemplateOutlet="link; context: { $implicit: file }"></ng-container>
  </ng-container>

  <ng-container *ngIf="isSummaryIconLayout">
    <i class="pi pi-cog" style="font-size: 2rem; color: #f39cb2;">SUMMARY_ICON</i>
  </ng-container>

  <ng-container *ngIf="isPictureLayout || isPictureWithNameLayout || isPictureWithLinkLayout">
    <ng-container *ngIf="file.isImage && !file.isPDF">
      <img
        [alt]="file.formatData.filename"
        [class.image]="file && file.isImage"
        [src]="file.isImage ? file.url : nonImageFileIcon"
        [title]="file.formatData.filename"
      />
    </ng-container>

    <app-pdf-preview *ngIf="file.isPDF" [pdfFile]="file"></app-pdf-preview>
  </ng-container>

  <ng-container *ngIf="isPictureWithLinkLayout">
    <ng-container *ngTemplateOutlet="link; context: { $implicit: file }"></ng-container>
  </ng-container>

  <h6 *ngIf="isPictureWithNameLayout" class="m-0">{{ file.formatData.filename }} picture name</h6>
</div>

<ng-template #link let-file>
  <a appFileArtifactViewer [file]="file" [ngStyle]="formatStyles" class="link"> {{ file.formatData.filename }}
    [{{ file.formatData.size | getFileSize }}] </a>
</ng-template>
