import { inject, Pipe, PipeTransform } from '@angular/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { MenuItem } from '../types/menu-widget.types';

@Pipe({ name: 'filterIrrelevantMenuItems', standalone: true })
export class FilterIrrelevantMenuItemsPipe implements PipeTransform {
  private readonly cache = inject(NewCacheService);

  async transform(items: MenuItem[]): Promise<MenuItem[]> {
    const pageIds: string[] = [];
    this.collectItemsPageIds(items, pageIds);
    const pagesMap = new Map<string, boolean>();
    await this.cache.data.pages.getManyAsync(pageIds).then(pages => pages.forEach(page => pagesMap.set(page.id, true)));

    return this.filterRelevantItems(items, pagesMap);
  }

  private collectItemsPageIds(items: MenuItem[], ids: string[]): void {
    items.forEach(item => {
      item.actions.forEach(action => action.pageId && ids.push(action.pageId));
      item.children && this.collectItemsPageIds(item.children, ids);
    });
  }

  private filterRelevantItems(items: MenuItem[], pagesMap: Map<string, boolean>): MenuItem[] {
    return items.filter(item => {
      if (!item.actions.every(action => !action.pageId || pagesMap.has(action.pageId))) return false;
      if (item.children) item.children = this.filterRelevantItems(item.children, pagesMap);
      return true;
    });
  }
}
