import { Injectable } from '@angular/core';
import { RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { ArtifactCustomEventSettings, CustomEventSettings } from '../types/custom-event';
import { RuntimeStateNotificationService } from './runtime-state-notification.service';

@Injectable({ providedIn: 'root' })
export class CustomClickService {
  constructor(private readonly runtimeStateNotificationService: RuntimeStateNotificationService) {}

  sendCustomEvent(customEventSettings: CustomEventSettings): void {
    if (!customEventSettings.enable) return;

    this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.customEvent, customEventSettings);
  }

  sendArtifactEvent(customEventSettings: ArtifactCustomEventSettings, hash: string): void {
    if (!customEventSettings.enable) return;

    this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.customEvent, customEventSettings, hash);
  }
}
