import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AttributeFieldComponent } from '@shared/components/bulk-artifact-edit-popup/components/attribute-field/attribute-field.component';
import { AttributesFormButtonsComponent } from '@shared/components/bulk-artifact-edit-popup/components/attributes-form-buttons/attributes-form-buttons.component';
import { BulkArtifactEditService } from '@shared/components/bulk-artifact-edit-popup/services/bulk-artifact-edit.service';
import { BulkEditingAttribute } from '@shared/components/bulk-artifact-edit-popup/types/bulk-editing-attribute';
import { BulkOperationResult } from '@shared/components/bulk-artifact-edit-popup/types/bulk-operation-result';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, AttributeFieldComponent, AttributesFormButtonsComponent],
  selector: 'app-attributes-form-component',
  templateUrl: './attributes-form.component.html',
  styleUrls: ['./attributes-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesFormComponent {
  @Input() attributes: BulkEditingAttribute[] = [];

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<BulkOperationResult> = new EventEmitter<BulkOperationResult>();

  readonly formatSettings: AttributeFormatSettings = new AttributeFormatSettings();
  readonly labelBehavior: LabelBehaviourEnum = LabelBehaviourEnum.static;

  constructor(
    private readonly bulkArtifactEditService: BulkArtifactEditService,
    private readonly announcement: AnnouncementService,
  ) {}

  async onSave(notify: boolean): Promise<void> {
    if (this.attributes.some((attribute: BulkEditingAttribute) => attribute.isInvalid)) {
      await this.notifyAboutInvalidAttributes();
    } else {
      this.save.emit(await this.bulkArtifactEditService.updateArtifacts(this.attributes, notify));
    }
  }

  private async notifyAboutInvalidAttributes(): Promise<void> {
    const fields = this.attributes
      .filter((attribute: BulkEditingAttribute) => attribute.isInvalid)
      .map(({ attribute }: BulkEditingAttribute) => attribute.name)
      .join(', ');
    await this.announcement.error(`Mandatory fields: {{fields}} are not set`, { fields });
  }
}
