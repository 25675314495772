import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Signal, signal, viewChild, ViewChild, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TranslateService } from '@ngx-translate/core';
import { PageBuilderHelper } from '@private/helpers/page-builder.helper';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { PageBuilderGraphicalEventsService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-events.service';
import { BlockLocation } from '@private/pages/page-management/page-builder-graphical/types/block-location';
import { BlockPartDropEvent } from '@private/pages/page-management/page-builder-graphical/types/block-part-drop-event';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBlock } from '@private/pages/page-management/page-builder-graphical/types/page-block';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageBuilderEventType } from '@private/pages/page-management/page-builder-graphical/types/page-builder-event-type';
import { PageBuilderGraphicalModel } from '@private/pages/page-management/page-builder-graphical/types/page-builder-graphical-model';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { RedesignColumnsEvent } from '@private/pages/page-management/page-builder-graphical/types/redesign-columns-event';
import { RowDropEvent } from '@private/pages/page-management/page-builder-graphical/types/row-drop-event';
import { RowLocation } from '@private/pages/page-management/page-builder-graphical/types/row-location';
import { SaveAsTemplateEvent } from '@private/pages/page-management/page-builder-graphical/types/save-as-template-event';
import { SectionLocation } from '@private/pages/page-management/page-builder-graphical/types/section-location';
import { SetHtmlIdEvent } from '@private/pages/page-management/page-builder-graphical/types/set-html-id-event';
import { StyleElementEvent } from '@private/pages/page-management/page-builder-graphical/types/style-element-event';
import { TemplateMeta } from '@private/pages/page-management/page-builder-graphical/types/template-meta';
import { ToolbarActionsLayoutLocation } from '@private/pages/page-management/page-builder-graphical/types/toolbar-actions-layout-location';
import { WidgetIntoPartDropEvent } from '@private/pages/page-management/page-builder-graphical/types/widget-into-part-drop-event';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { TemplateMetaFormComponent } from '@shared/components/template-form/template-meta-form.component';
import { SectionGenerationFormComponent } from '@shared/components/templates/components/section-generation-form/section-generation-form.component';
import { TemplatesCloseResultRole, TemplateType } from '@shared/components/templates/types/templates.types';
import { ALIAS_LABEL, NAME_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { WidgetHelper } from '@shared/helpers/widget.helper';
import { UrlMethods } from '@shared/methods/url.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { TemplateService } from '@shared/services/page-management/template.service';
import { SidebarWidgetService } from '@shared/services/sidebar-widget.service';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewSystemUser } from '@shared/types/user.types';
import { WidgetResponseDto } from '@shared/types/widget.types';
import { CustomScriptsUtil } from '@shared/utils/custom-scripts.util';
import { Debounce } from '@shared/utils/debounce.util';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ReplaceRuntimeVariablesService } from '@widgets/shared/services/replace-runtime-variables.service';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import {
  ConditionalFormattingDialogEvent,
  RuntimeStateNotification,
  RuntimeStateNotificationEnum,
} from '@widgets/shared/types/runtime-state-notification.types';
import { TemplateToModal, WidgetToModal } from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { SIDEBAR_Z_INDEX } from '@widgets/sidebar-widget/constants/sidebar-widget.constants';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { WorkflowsWidgetAdministrationComponent } from '@workflows/components/administration/widget';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { PageBuilderGraphicalCopyPasterService } from './services/page-builder-graphical-copy-paster.service';
import { PageBuilderGraphicalService } from './services/page-builder-graphical.service';

@Component({
  selector: 'app-page-builder-graphical',
  templateUrl: './page-builder-graphical.component.html',
  styleUrls: ['./page-builder-graphical.component.scss'],
  providers: [PageBuilderGraphicalDragDropService, PageBuilderGraphicalService, PageBuilderGraphicalEventsService],
})
export class PageBuilderGraphicalComponent extends CoreComponent<PageBuilderGraphicalService, PageBuilderGraphicalModel> implements AfterViewInit {
  @ViewChild(TemplateMetaFormComponent) templateForm: TemplateMetaFormComponent;
  @ViewChild(SectionGenerationFormComponent) sectionGenerationForm: SectionGenerationFormComponent;
  @ViewChild('htmlIdOp') htmlIdOp: OverlayPanel;
  widgetWorkflowsAdministrationModal: Signal<WorkflowsWidgetAdministrationComponent | undefined> = viewChild('widgetWorkflowsAdministrationModal');

  stylizationEvent: StyleElementEvent | null = null;
  advancedWidget: BlockPartWidget | null;
  isPageSettings: boolean;
  blockPartForWidgetPlacement: PageBlockPart | null = null;
  isPageBuilderReadyToRender = false;
  setHtmlIdEvent: SetHtmlIdEvent | null = null;
  pageWorkflowsDialogVisible = false;
  pageWidgets: BlockPartWidget[];
  htmlIdRegex = /^[_$a-z][_$a-zA-Z0-9]*$/;
  conditionalFormattingDialogAttribute: WritableSignal<NewAttribute | null> = signal(null);
  conditionalFormattingDialogVisible: WritableSignal<boolean> = signal(false);

  applicationId: string;
  canDeactivate: boolean = true;

  readonly NAME_LABEL = NAME_LABEL;
  readonly ALIAS_LABEL = ALIAS_LABEL;
  readonly widgetType = WidgetType;
  readonly elementType = {
    page: Page,
    section: PageSection,
    row: PageRow,
    blockPart: PageBlockPart,
  };

  constructor(
    route: ActivatedRoute,
    translate: TranslateService,
    service: PageBuilderGraphicalService,
    announcement: AnnouncementService,
    router: Router,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
    public readonly elvisUtil: ElvisUtil,
    public readonly widgetHelper: WidgetHelper,
    public readonly localStorageService: LocalStorageService,
    public readonly pageBuilderGraphicalCopyPasterService: PageBuilderGraphicalCopyPasterService,
    public readonly dragDropService: PageBuilderGraphicalDragDropService,
    public readonly widgetService: SidebarWidgetService,
    public readonly replaceRuntimeVariablesService: ReplaceRuntimeVariablesService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly blockUiService: BlockUiService,
    private readonly pageBuilderHelper: PageBuilderHelper,
    private readonly templateService: TemplateService,
    private readonly eventsService: PageBuilderGraphicalEventsService,
    private readonly cache: NewCacheService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly translateUtil: TranslateUtil,
    private readonly confirmationService: ConfirmationService,
  ) {
    super(route, router, translate, new PageBuilderGraphicalModel(), service, announcement);
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.isPageBuilderReadyToRender = true));
  }

  onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Page builder' }]);

    this.registerSubscriptions([
      this.cache.user.subscribe(user => user && (this.m.user = new NewSystemUser(user as SelfUserResponseDto))),

      this.eventsService.subscribe<RedesignColumnsEvent>(PageBuilderEventType.redesignColumns, (payload: RedesignColumnsEvent) =>
        this.onRedesignColumns(payload),
      ),

      this.eventsService.subscribe<PartLocation>(PageBuilderEventType.addSection, (payload: SectionLocation) => this.onAddSection(payload)),
      this.eventsService.subscribe<PartLocation>(PageBuilderEventType.addBlockPart, (payload: BlockLocation) => this.onAddBlockPart(payload)),
      this.eventsService.subscribe<PartLocation>(PageBuilderEventType.addWidget, (payload: PartLocation) => this.onAddWidget(payload)),

      this.eventsService.subscribe<ToolbarActionsLayoutLocation>(PageBuilderEventType.copy, (payload: ToolbarActionsLayoutLocation) => this.onCopy(payload)),
      this.eventsService.subscribe<ToolbarActionsLayoutLocation>(PageBuilderEventType.paste, (payload: ToolbarActionsLayoutLocation) => this.onPaste(payload)),
      this.eventsService.subscribe<ToolbarActionsLayoutLocation>(PageBuilderEventType.delete, (payload: ToolbarActionsLayoutLocation) =>
        this.onDelete(payload),
      ),
      this.eventsService.subscribe<ToolbarActionsLayoutLocation>(PageBuilderEventType.export, (payload: ToolbarActionsLayoutLocation) =>
        this.onExport(payload),
      ),

      this.eventsService.subscribe<SaveAsTemplateEvent>(PageBuilderEventType.saveAsTemplate, (payload: SaveAsTemplateEvent) => this.onSaveAsTemplate(payload)),

      this.eventsService.subscribe<SetHtmlIdEvent>(PageBuilderEventType.setHtmlId, (setHtmlIdEvent: SetHtmlIdEvent) => this.onSetHtmlId(setHtmlIdEvent)),

      this.eventsService.subscribe<StyleElementEvent>(PageBuilderEventType.styleElement, (payload: StyleElementEvent) =>
        this.openElementStylizationSidebar(payload),
      ),

      this.eventsService.subscribe<BlockPartWidget | null>(PageBuilderEventType.widgetAdvancedMode, (payload: BlockPartWidget | null) =>
        this.setWidgetAsAdvancedMode(payload),
      ),

      this.eventsService.subscribe<CdkDragDrop<RowDropEvent>>(PageBuilderEventType.rowDrop, (payload: CdkDragDrop<RowDropEvent>) => this.onRowDrop(payload)),
      this.eventsService.subscribe<CdkDragDrop<BlockPartDropEvent>>(PageBuilderEventType.blockPartDrop, (payload: CdkDragDrop<BlockPartDropEvent>) =>
        this.onBlockPartDrop(payload),
      ),
      this.eventsService.subscribe<CdkDragDrop<WidgetIntoPartDropEvent>>(PageBuilderEventType.widgetDrop, (payload: CdkDragDrop<WidgetIntoPartDropEvent>) =>
        this.dropWidgetIntoExistingBlockPart(payload),
      ),
      this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<unknown>) => {
        if (event.type === RuntimeStateNotificationEnum.togglePageBuilderHeaderVisible) {
          this.m.isHeaderVisible = event.data as boolean;
        }

        if (event.type === RuntimeStateNotificationEnum.openConditionalFormattingDialog) {
          this.openConditionalFormattingDialog((event as RuntimeStateNotification<ConditionalFormattingDialogEvent>).data);
        }
      }),
    ]);

    this.applicationId = this.applicationSwitcherService.selectedApplication?.id || '';
    this.checkBeforeTabClose();
  }

  openPageSettings(): void {
    const pageElement = this.m.page;
    this.eventsService.publish<StyleElementEvent>(PageBuilderEventType.styleElement, { pageElement });
    this.setPossiblePageStructureChange();
  }

  openWorkflowSettings(): void {
    this.pageWorkflowsDialogVisible = true;
    if (!this.pageWidgets) {
      this.pageWidgets = [];
      this.setWidgetsFromSections(this.m.page.sections, this.pageWidgets);
    }
  }

  navigateToPreview(): void {
    const openPreview = () => UrlMethods.windowOpen(`/page/${this.m.page.id}`, this.s.params);
    this.checkIfCanBeDeactivated(undefined, openPreview);
    this.canDeactivate && openPreview();
  }

  setWidgetsFromSections(sections: PageSection[], pageWidgets: BlockPartWidget[]): any {
    sections.forEach(pageSection => {
      pageWidgets.push(
        ...pageSection.parts.reduce((widgets: BlockPartWidget[], part) => {
          if (part.widget) {
            widgets.push(part.widget as BlockPartWidget);
          }

          if (part.widget?.code === WidgetType.sidebar) {
            this.setWidgetsFromSections([part.widget.value.model], widgets);
          }
          if (part.widget?.code === WidgetType.sidebarModal) {
            this.setWidgetsFromSections(part.widget?.value?.model?.page?.sections, widgets);
          }

          return widgets;
        }, [] as BlockPartWidget[]),
      );
    });
  }

  dropSection(event: CdkDragDrop<void>): void {
    if ((event.previousContainer.data as any).modalId !== this.m.page.modalId) return;
    this.dragDropService.dropSection(event, this.m.page);
    this.setPossiblePageStructureChange();
  }

  async save(): Promise<void> {
    this.blockUiService.blockUi();
    this.m.isInSavingProcess = true;

    try {
      if (this.m.page.id) await this.s.updatePage();
      else await this.s.createPage();

      this.m.isDefaultTenantPage = this.m.page.alias === '' && Boolean(this.m.user?.tenant?.isPublic);

      await this.announcement.success(`Page successfully ${this.m.page.id ? 'updated' : 'created'}`);
    } catch (e) {
      console.log(e);
      await this.announcement.error('Failed to save page');
    } finally {
      this.blockUiService.unblockUi();
      this.m.isInSavingProcess = false;
    }
  }

  showSectionTemplateForm(section: PageSection): void {
    this.templateForm.show(TemplateType.section);
    this.m.sectionForTemplate = section;
  }

  showWidgetTemplateForm(part: PageBlockPart): void {
    this.templateForm.show(TemplateType.widget);
    this.m.partWithWidgetForTemplate = part;
  }

  async createTemplate(templateMeta: TemplateMeta): Promise<void> {
    try {
      switch (this.templateForm.templateType) {
        case TemplateType.section:
          await this.s.createSectionTemplate(templateMeta);
          break;
        case TemplateType.widget:
          await this.s.createWidgetTemplate(templateMeta);
          break;
      }
      await this.announcement.success('Template created');
    } catch (e) {
      console.log(e);
      await this.announcement.error('Failed to create template');
    }
    this.templateForm.hide();
  }

  onPageNameChange(name: string): void {
    if (!this.m.page.id) {
      this.m.page.alias = this.pageBuilderHelper.generateAliasFromPageName(name);
    }
    this.setPossiblePageStructureChange();
  }

  onPageBuilderSidebarHide(): void {
    this.stylizationEvent = null;
    this.advancedWidget = null;
    this.blockPartForWidgetPlacement = null;
    this.setPossiblePageStructureChange();
  }

  onPageBuilderSidebarShow(el: any): void {
    if (el?.container?.style && this.advancedWidget?.code === WidgetType.text) {
      el.container.style['z-index'] = SIDEBAR_Z_INDEX;
    }
    this.setPossiblePageStructureChange();
  }

  onDestroy(): void {
    super.onDestroy();
    this.localStorageService.clearRedundantData();
    this.s.pageHelper.resetBackgroundOnBody();
    CustomScriptsUtil.removeScripts(this.m.page.scriptModels || []);
  }

  async showWidgetTemplates(): Promise<void> {
    const part = this.blockPartForWidgetPlacement;
    this.m.pageBuilderSidebar.closeSidebar();
    const { role, templates } = await this.templateService.pickTemplate();

    if (this.m.location.modalId) {
      this.m.pageBuilderSidebar.closeSidebar();
      this.eventsService.publish<TemplateToModal>(PageBuilderEventType.addTemplateToModal, { location: this.m.location, templateResult: { role, templates } });
      return;
    }

    for (const template of templates || []) {
      await this.s.pasteWidgetTemplate(template!, part!, role === TemplatesCloseResultRole.reuseTemplate);
    }
  }

  async onAddSection({ sectionIndex, modalId }: SectionLocation): Promise<void> {
    if (modalId) return;
    await this.showSectionTemplates(sectionIndex);
  }

  async showSectionTemplates(previousSectionIndex: number = this.m.page.sections.length - 1): Promise<void> {
    const { role, templates, sectionScheme } = await this.templateService.pickTemplate(TemplateType.section);

    for (const template of templates || []) {
      if (role === TemplatesCloseResultRole.reuseTemplate) await this.s.pasteSectionTemplateAsReused(template!, previousSectionIndex);
      if (role === TemplatesCloseResultRole.copyTemplate) await this.s.pasteSectionTemplateAsCopy(template!, previousSectionIndex);
    }

    if (role === TemplatesCloseResultRole.generateSection) this.s.generateNewSection(sectionScheme!, previousSectionIndex);
    this.setPossiblePageStructureChange();
  }

  redesignSection(scheme: string[]): void {
    this.s.redesignSection(scheme);
  }

  setWidgetToBlockPart(code: WidgetType): void {
    if (this.m.location.modalId) {
      this.m.pageBuilderSidebar.closeSidebar();
      this.eventsService.publish<WidgetToModal>(PageBuilderEventType.addWidgetToModal, { location: this.m.location, code });
      return;
    }

    const widget = new BlockPartWidget({ code, value: {} } as WidgetResponseDto);
    this.blockPartForWidgetPlacement!.widget = widget;
    this.blockPartForWidgetPlacement = null;
    this.dragDropService.setDropListConnectionIds(this.m.page.sections, true);

    if (![WidgetType.text].includes(code)) {
      this.setWidgetAsAdvancedMode(widget);
    } else {
      this.m.pageBuilderSidebar.closeSidebar();
    }
  }

  changeDefaultTenantPageFlag(isDefaultTenantPage: boolean): void {
    this.m.page.alias = isDefaultTenantPage ? '' : undefined;
    this.setPossiblePageStructureChange();
  }

  togglePanelPosition(): void {
    // widget advanced mode or page element stylization mode
    if (this.advancedWidget || this.stylizationEvent) {
      this.m.pageBuilderSidebar.closeSidebar();
      this.advancedWidget && this.setWidgetAsAdvancedMode(this.advancedWidget);
      this.stylizationEvent && this.openElementStylizationSidebar(this.stylizationEvent);
      return;
    }

    // widget selection mode
    const { position, fullScreen } = this.widgetService.getPositionSettings(SidebarWidgetService.commonSettingsKey);
    this.m.pageBuilderSidebar.position = position;
    this.m.pageBuilderSidebar.fullScreen = fullScreen;
  }

  async checkIfCanBeDeactivated(url?: string, acceptCb?: () => any): Promise<void> {
    if (this.canDeactivate) return;

    await this.confirmBeforeLeavePageBuilder(
      () => {
        this.canDeactivate = true;
        url && this.router.navigateByUrl(url);
        acceptCb && acceptCb();
      },
      () => (this.canDeactivate = false),
    );
  }

  setPossiblePageStructureChange(): void {
    this.canDeactivate = false;
  }

  private async confirmBeforeLeavePageBuilder(accept: () => void, reject: () => void): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll([
      'Leave Page Builder',
      'You tried to change something on the page. Do you want to leave the page without saving your changes?',
      'Yes',
      'No',
    ]);
    this.confirmationService.confirm({
      header,
      message,
      acceptLabel,
      rejectLabel,
      accept: () => accept && accept(),
      reject: () => reject && reject(),
    });
  }

  private openElementStylizationSidebar(stylizationEvent: StyleElementEvent): void {
    this.isPageSettings = stylizationEvent.pageElement.constructor.name === 'Page';
    this.stylizationEvent = stylizationEvent;
    this.advancedWidget = null;
    const settings = this.widgetService.getPositionSettings();
    this.m.pageBuilderSidebar.openSidebar(undefined, settings);
  }

  private setWidgetAsAdvancedMode(widget: BlockPartWidget | null): void {
    this.blockPartForWidgetPlacement = null;
    this.stylizationEvent = null;
    this.advancedWidget = widget;
    const settings = this.widgetService.getPositionSettings(widget?.id as string);
    this.m.pageBuilderSidebar.openSidebar(widget?.code !== WidgetType.card, settings);
  }

  private onRedesignColumns({ event, sectionIndex }: RedesignColumnsEvent): void {
    const section = this.m.page.getByLocation<PageSection>({ sectionIndex });
    this.s.toggleSectionRedesignForm(event, section, sectionIndex);
  }

  private onCopy(location: ToolbarActionsLayoutLocation): void {
    if (location.modalId) return;
    const layout = this.m.page.getByLocation(location);
    this.pageBuilderGraphicalCopyPasterService.copyLayout(layout);
  }

  private onPaste(location: ToolbarActionsLayoutLocation): void {
    if (location.modalId) return;
    const layout = this.m.page.getByLocation(location);
    this.pageBuilderGraphicalCopyPasterService.pasteLayout(layout);
    this.dragDropService.setDropListConnectionIds(this.m.page.sections, true);
  }

  private onDelete(location: PartLocation | RowLocation | SectionLocation): void {
    if (location.modalId) return;

    if ('partIndex' in location) {
      this.s.deleteBlockPart(location, this.m.page);

      return;
    }

    if ('rowIndex' in location) {
      this.s.deleteRow(location, this.m.page);

      return;
    }

    this.s.deleteSection(location, this.m.page);
  }

  private onExport(location: PartLocation | RowLocation | SectionLocation): void {
    if ('partIndex' in location) {
      this.s.exportPagePart(
        location,
        `${TemplateType.widget} [${location.sectionIndex}][${location.rowIndex}][${location.blockIndex}][${location.partIndex}]`,
        TemplateType.widget,
      );
      return;
    }

    if ('sectionIndex' in location) {
      this.s.exportPagePart(location, `${TemplateType.section} [${location.sectionIndex}]`, TemplateType.section);
      return;
    }
  }

  private onSaveAsTemplate({ templateType, location }: SaveAsTemplateEvent): void {
    if (location.modalId) return;
    if (templateType === TemplateType.section) {
      const section = this.m.page.getByLocation<PageSection>(location);
      this.showSectionTemplateForm(section);
    }

    if (templateType === TemplateType.widget) {
      const part = this.m.page.getByLocation<PageBlockPart>(location);
      this.showWidgetTemplateForm(part);
    }
  }

  private onSetHtmlId(setHtmlIdEvent: SetHtmlIdEvent): void {
    this.htmlIdOp.hide();
    this.setHtmlIdEvent = setHtmlIdEvent;
    setTimeout(() => this.htmlIdOp.show(setHtmlIdEvent.event), 150);
  }

  private onAddWidget(location: PartLocation): void {
    this.m.location = location;
    this.advancedWidget = null;
    if (!location.modalId) {
      this.blockPartForWidgetPlacement = this.m.page.getByLocation(location);
    } else {
      this.blockPartForWidgetPlacement = {} as PageBlockPart;
    }
    this.m.pageBuilderSidebar.openSidebar(false);
  }

  @Debounce(100)
  private onAddBlockPart(location: BlockLocation): void {
    if (location.modalId) return;
    const block = this.m.page.getByLocation<PageBlock>(location);
    this.s.addBlockPart(block);
  }

  private onRowDrop(event: CdkDragDrop<RowDropEvent>): void {
    if (event.container.data.modalId) return;
    this.dragDropService.dropRow(event, this.m.page);
  }

  private onBlockPartDrop(event: CdkDragDrop<BlockPartDropEvent>): void {
    if (event.container.data.modalId || event.previousContainer.data.modalId) return;
    if (!event.previousContainer.data) {
      this.dropWidgetIntoNewBlockPart(event.container.data, event.item.data.code);

      return;
    }

    this.dragDropService.dropBlockPart(event, this.m.page);

    if (this.m.page.modalId !== event.previousContainer.data.modalId) {
      const { blockIndex, modalId, rowIndex, sectionIndex } = event.previousContainer.data;
      const { partIndex } = event.item.data;
      this.eventsService.publish<PartLocation>(PageBuilderEventType.delete, { blockIndex, modalId, partIndex, rowIndex, sectionIndex });
    }
  }

  private dropWidgetIntoExistingBlockPart({ container, item }: CdkDragDrop<WidgetIntoPartDropEvent>): void {
    this.blockPartForWidgetPlacement = container.data.part;
    this.setWidgetToBlockPart(item.data.code);
  }

  private dropWidgetIntoNewBlockPart({ sectionIndex, rowIndex, blockIndex }: BlockPartDropEvent, code: WidgetType): void {
    const block = this.m.page.sections[sectionIndex].rows[rowIndex].blocks[blockIndex];
    this.s.addBlockPart(block);
    this.blockPartForWidgetPlacement = block.parts[block.parts.length - 1];
    this.setWidgetToBlockPart(code);
  }

  private checkBeforeTabClose(): void {
    window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
      if (!this.canDeactivate) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  }

  private openConditionalFormattingDialog(event: ConditionalFormattingDialogEvent): void {
    const modal = this.widgetWorkflowsAdministrationModal()!;
    modal.attribute = event.attribute;
    modal.widgetId = event.widgetId;
    modal.rule.set(event.selectedRule);
    modal.possibleDefaultArtifactTypes.set(event.artifactTypes);
    this.conditionalFormattingDialogAttribute.set(modal.attribute);
    this.conditionalFormattingDialogVisible.set(true);
    modal.visible.set(true);
  }
}
