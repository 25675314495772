<div class="grid mt-1">
  @for (event of eventResponseSettings.events; let i = $index; track i) {
    <div class="col-6">
      <p-floatLabel>
        <input pInputText [id]="'event-key-' + i" [(ngModel)]="event.key" />
        <label [for]="'event-key-' + i" translate>Event key</label>
      </p-floatLabel>
    </div>
    <div class="col-5">
      <p-floatLabel>
        <p-dropdown
          [(ngModel)]="event.action"
          [options]="actionOptions"
          [id]="'event-action-' + i"
        ></p-dropdown>
        <label [for]="'event-action-' + i" translate>Event action</label>
      </p-floatLabel>
    </div>
    <div class="col-1">
      <span (click)="eventResponseSettings.removeEvent(i)" class="pi pi-trash p-button p-button-rounded p-button-text p-button-danger" pRipple> </span>
    </div>
  }

  <div class="col-12 center">
    <button (click)="eventResponseSettings.addEvent()" class="p-button p-button-text" pButton type="button" icon="pi pi-plus" translate>
      Add event
    </button>
  </div>
</div>
