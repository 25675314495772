import { Injectable } from '@angular/core';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { AbstractAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/abstract.avr-types-services.service';
import { DocumentGenerationAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/document-generation/document-generation.avr-types-services.service';
import { QrCodesAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/qr-codes/qr-codes.avr-types-services.service';
import { SwissBillAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/swiss-bill/swiss-bill.avr-types-services.service';

export const AvrTypesServices = [SwissBillAvrTypeService, DocumentGenerationAvrTypeService, QrCodesAvrTypeService] as const;

type DefinedAvrTypesSettings = `${InstanceType<(typeof AvrTypesServices)[number]>['type']}`;
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const avrTypesServicesCheck: [DefinedAvrTypesSettings] extends [`${AvrTypes}`] ? (`${AvrTypes}` extends DefinedAvrTypesSettings ? true : false) : false = true;

@Injectable()
export class AvrTypesSettingsService {
  private avrTypesServices: {
    [K in AvrTypes]: AbstractAvrTypeService<K>;
  };

  constructor(avrTypesServices: InstanceType<(typeof AvrTypesServices)[number]>[]) {
    this.avrTypesServices = avrTypesServices.reduce(
      (output, avrTypesService) => {
        output[avrTypesService.type] = avrTypesService as any;
        return output;
      },
      {} as typeof this.avrTypesServices,
    );
  }

  getAvrTypesServices<K extends AvrTypes>(avrType: K): (typeof this.avrTypesServices)[K] {
    return this.avrTypesServices[avrType];
  }
}
