import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { ArtifactPipesModule } from '@shared/pipes/artifact-pipes/artifact-pipes.module';
import { AttributePipesModule } from '@shared/pipes/attribute-pipes/attribute-pipes.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { LinkPipesModule } from '@shared/pipes/links/link-pipes.module';
import { FilterRelevantStylesPipe } from '@shared/pipes/styles-pipes/filter-relevant-styles.pipe';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { RippleModule } from 'primeng/ripple';
import { DisplayAtLinkComponent } from './display-at-link.component';

@NgModule({
  declarations: [DisplayAtLinkComponent],
  imports: [
    CommonModule,
    RouterModule,
    BadgeModule,
    ArtifactPipesModule,
    AttributePipesModule,
    LinkPipesModule,
    ButtonModule,
    PaginatorModule,
    RippleModule,
    TranslateModule,
    WidgetContainerModule,
    CommonPipesModule,
    FilterRelevantStylesPipe,
  ],
  exports: [DisplayAtLinkComponent],
})
export class DisplayAtLinkModule {}
