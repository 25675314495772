import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';

export class UserOptionMeta {
  email: string = '';
  profilePictureId: string = '';

  constructor(dto?: ArtifactResponseDto) {
    if (dto) {
      this.email = dto.attributes[GlobalConstants.getValue(GlobalConstantsEnum.emailAttributeId)].value as string;
      this.profilePictureId = dto.attributes[GlobalConstants.getValue(GlobalConstantsEnum.profilePictureAttributeId)].value as string;
    }
  }
}
