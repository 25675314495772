import { Component, HostBinding, Inject } from '@angular/core';
import { NON_MAPPABLE_FIELDS } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/types/artifact-type-avr-form.types';
import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { SwissBillOutputTypes } from '@shared/services/artifact-visual-representation/swiss-bill/dto/swiss-bill.input-mapper.dto';
import { SelectOption } from '@shared/types/shared.types';

@Component({
  selector: 'app-avr-swiss-bill-non-mappable-fields',
  templateUrl: './avr-swiss-bill.non-mappable-fields.component.html',
  styleUrls: ['./avr-swiss-bill.non-mappable-fields.component.scss'],
})
export class AvrSwissBillNonMappableFieldsComponent {
  @HostBinding('class') hostClass = 'p-datatable-tbody';

  protected test = [];
  public debtorIsOptionalOptions: SelectOption<string, boolean>[] = [];
  public outputTypesOptions: SelectOption<string, string>[] = [];

  constructor(@Inject(NON_MAPPABLE_FIELDS) public nonMappableFields: BaseAvrInputMapperDto<AvrTypes.swissBill>['nonMappableFields']) {
    this.debtorIsOptionalOptions = [new SelectOption('True', true), new SelectOption('False', false)];
    this.outputTypesOptions = Object.values(SwissBillOutputTypes).map(outputType => new SelectOption(outputType, outputType));
  }
}
