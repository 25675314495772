<div class="grid">
  <div class="col-6">
    <div class="dynamic-label">
      <p-dropdown
        (onChange)="onWidgetModeChange()"
        [(ngModel)]="m.settings.widgetMode"
        [options]="m.options.widgetModes.list | transformArrayToSelectOptions"
        appendTo="body"
        inputId="cardWidgetType">
      </p-dropdown>
      <label [for]="'cardWidgetType'" class="dynamic-label-text">{{ 'Widget type' | translate }}</label>
    </div>
  </div>

  <div class="col-6">
    <div class="dynamic-label">
      <p-dropdown
        (onChange)="onArtifactTypeChange()"
        [(ngModel)]="m.settings.artifactTypeId"
        [autoDisplayFirst]="false"
        [filter]="true"
        filterBy="label"
        [options]="m.options.artifactTypes.list | transformArrayToSelectOptions : NAME_KEY"
        appendTo="body"
        inputId="cardWidgetArtifactType"
        optionValue="value.id">
        <ng-template let-option pTemplate="item">
          <ng-container *ngTemplateOutlet="attributeOption; context: { $implicit: option }"></ng-container>
        </ng-template>
        <ng-template let-selected pTemplate="selectedItem">
          <ng-container *ngTemplateOutlet="attributeOption; context: { $implicit: selected }"></ng-container>
        </ng-template>

        <ng-template #attributeOption let-option>
          {{ option | getAttributeLabelWithApplication : m.options.applications }}
        </ng-template>
      </p-dropdown>
      <label [for]="'cardWidgetArtifactType'" class="dynamic-label-text">{{ 'Artifact type' | translate }}</label>
    </div>
  </div>

  <div *ngIf="isArtifactSelectionAvailable" class="col-6">
    <div class="dynamic-label">
      <p-dropdown
        (onChange)="onSelectedArtifactChange()"
        [(ngModel)]="m.settings.selectedArtifactId"
        [autoDisplayFirst]="false"
        [filter]="true"
        [options]="m.options.artifactOptions.list | transformArrayToSelectOptions : NAME_KEY"
        appendTo="body"
        inputId="selectedArtifact"
        optionValue="value.id">
        <ng-template let-item pTemplate="item">
          <ng-container *ngTemplateOutlet="artifactOptionItem; context: { $implicit: item }"></ng-container>
        </ng-template>
        <ng-template let-item pTemplate="selectedItem">
          <ng-container *ngTemplateOutlet="artifactOptionItem; context: { $implicit: item }"></ng-container>
        </ng-template>

        <ng-template #artifactOptionItem let-item>
          {{ item.value | primaryAttributeValues : m.options.artifactTypes.listMap[item.value.artifactTypeId] }}
        </ng-template>
      </p-dropdown>
      <label [for]="'selectedArtifact'" class="dynamic-label-text">{{ 'Artifact' | translate }}</label>
    </div>
  </div>

  <div *ngIf="isArtifactIdUrlParameterKeyChangeAvailable" class="col-6">
    <div class="dynamic-label">
      <input
        [(ngModel)]="m.settings.listeningKey"
        id="cardWidgetDynamicArtifactIdKey"
        pInputText
        style="width: 100%"
        type="text">
      <label
        [for]="'cardWidgetDynamicArtifactIdKey'"
        class="dynamic-label-text">
        {{ 'URL parameter key for artifact id' | translate }}
      </label>
    </div>
  </div>

  <div *ngIf="clickActionCanBeHandled" class="col-12">
    <app-list-item-click-action-settings-form
      [isListWidget]="true"
      [(actions)]="m.settings.actions"
      [header]="'Card click action'"
      [filtersHolder]="$any(m.settings)"
      [filtersKey]="'filtersForQueryParams'"
      [isAddToLinkDisabled]="!m.settings.isArtifactTypeSelected"
      [isGoToDefaultPageDisabled]="!m.settings.isArtifactTypeSelected"
      [isHideMultiselect]="true"
      [options]="clickActionSettingsOptions"
    >
    </app-list-item-click-action-settings-form>
  </div>

  <div class="col-12" *ngIf="m.settings.widgetMode !== CardWidgetMode.listItem">
    <h5>{{ 'Responsiveness' | translate }}</h5>
    <div class="field-radiobutton mb-0">
      <p-checkbox
        [(ngModel)]="m.settings.responsiveness"
        [binary]="true"
        id="Responsiveness"
        inputId="responsiveness"
        (onChange)="onResponsivenessChange()">
      </p-checkbox>
      <label for="responsiveness">{{ 'Add responsiveness on mobile' | translate }}</label>
    </div>
  </div>

  <div class="col-12">
    <p-accordion>
      <p-accordionTab *ngIf="areaAttributeSelectionAvailable" [header]="'Attributes & Links' | translate">
        <ul
          (cdkDropListEntered)="resetDraggedItemData()"
          [cdkDropListConnectedTo]="connectionIds"
          [cdkDropListSortingDisabled]="true"
          cdkDropList
          class="d-flex flex-wrap m-0 p-0">
          <li *ngIf="draggedItem" [style.order]="draggedItemIndex" class="attribute">
            <app-link-option-with-arrow
              *ngIf="draggedItem.meta; else nonLinkAttributeTemplate"
              [direction]="draggedItem.meta"
              [label]="draggedItem.label"
              class="link-option">
            </app-link-option-with-arrow>
            <ng-template #nonLinkAttributeTemplate>{{ draggedItem.label }}</ng-template>
          </li>

          <li
            (cdkDragDropped)="resetDraggedItemData()"
            (cdkDragExited)="onAttributeDragExited(attributeOption, optionIndex)"
            *ngFor="let attributeOption of m.options.clientAttributeOptions; index as optionIndex"
            [cdkDragData]="{ type: attributeOption.meta ? ContentType.link : ContentType.attribute, content: attributeOption.value, linkDirection: attributeOption.meta }"
            [style.order]="optionIndex"
            cdkDrag
            class="attribute">
            <app-link-option-with-arrow
              *ngIf="attributeOption.meta; else nonLinkAttributeTemplate"
              [direction]="attributeOption.meta"
              [label]="attributeOption.label"
              class="link-option">
            </app-link-option-with-arrow>
            <ng-template #nonLinkAttributeTemplate>{{ attributeOption.label }}</ng-template>
          </li>
        </ul>
      </p-accordionTab>
      <p-accordionTab [header]="'Widgets' | translate" [selected]="true">
        <app-widget-list [connectionIds]="connectionIds" [widgetOptions]="widgetOptions"></app-widget-list>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>



