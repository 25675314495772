import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DisplayAtFileComponent } from './display-at-file.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FileViewerModule } from '@widgets/shared/components/file-viewer/file-viewer.component';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { PdfPreviewComponent } from '@shared/components/common-display-at/components/display-at-file/pdf-preview/pdf-preview.component';

@NgModule({
  declarations: [DisplayAtFileComponent, PdfPreviewComponent],
  providers: [DialogService, DynamicDialogConfig],
  imports: [CommonModule, RouterModule, TranslateModule, OverlayPanelModule, FileViewerModule, ElvisSharedModule],
  exports: [DisplayAtFileComponent],
})
export class DisplayAtFileModule {}
