<div class="grid">
  <div class="col-6">
    {{ 'Sidebar position' | translate }}
  </div>
  <div class="col-6">
    <p-dropdown
      (onChange)="onChange(true)"
      [options]="options.position"
      [(ngModel)]="settings.position"
    ></p-dropdown>
  </div>
  <div class="col-6">
    {{ 'Position' | translate }}
  </div>
  <div class="col-6" style="font-size: 16px">
    <p-dropdown
      (onChange)="onChange()"
      [options]="options.inside"
      [(ngModel)]="settings.isInside"
    ></p-dropdown>
  </div>
  <div class="col-6">
    {{ 'Behavior' | translate }}
  </div>
  <div class="col-6" style="font-size: 16px">
    <p-dropdown
      (onChange)="onChange()"
      [options]="options.fixed"
      [(ngModel)]="settings.isFixed"
    ></p-dropdown>
  </div>

  <div class="col-6">
    {{ 'Height' | translate }}
  </div>
  <div class="col-6">
    <input class="w-full" type="text" pInputText [(ngModel)]="settings.height" (blur)="onChange()"
           [disabled]="settings.position === position.right || settings.position === position.left" />
  </div>
  <div class="col-6">
    {{ 'Width' | translate }}
  </div>
  <div class="col-6">
    <input class="w-full" type="text" pInputText [(ngModel)]="settings.width" (blur)="onChange()"
           [disabled]="settings.position === position.top || settings.position === position.bottom" />
  </div>

  <ng-container *ngIf="settings.position !== position.center">
    <div class="col-6">
      {{ 'Top' | translate }}
    </div>
    <div class="col-6">
      <input class="w-full" type="text" pInputText [(ngModel)]="settings.topGap" (blur)="onChange()" />
    </div>
    <div class="col-6">
      {{ 'Bottom' | translate }}
    </div>
    <div class="col-6">
      <input class="w-full" type="text" pInputText [(ngModel)]="settings.bottomGap" (blur)="onChange()" />
    </div>
    <div class="col-6">
      {{ 'Left' | translate }}
    </div>
    <div class="col-6">
      <input class="w-full" type="text" pInputText [(ngModel)]="settings.leftGap" (blur)="onChange()" />
    </div>
    <div class="col-6">
      {{ 'Right' | translate }}
    </div>
    <div class="col-6">
      <input class="w-full" type="text" pInputText [(ngModel)]="settings.rightGap" (blur)="onChange()" />
    </div>
  </ng-container>

  <div class="col-4">
    {{ 'Container overflow' | translate }}
  </div>
  <div class="col-8">
    <button (click)="overflowOverlayPanel.toggle($event)"
            icon="bi bi-stack-overflow"
            label="{{'Overflow' | translate}}"
            pButton></button>
  </div>

  <div class="col-6">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="settings.byPageContent" binary="true" id="toContent"></p-checkbox>
            <label for="toContent">{{ 'Relative to content' | translate }}</label>
          </span>
        </span>
  </div>

  <div class="col-6">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="settings.showOnInit" binary="true" id="openOnInit"></p-checkbox>
            <label for="openOnInit">{{ 'Open on init' | translate }}</label>
          </span>
        </span>
  </div>

  <div class="col-6">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="settings.storeState" binary="true" id="storeState"></p-checkbox>
            <label for="storeState">{{ 'Store state for user' | translate }}</label>
          </span>
        </span>
  </div>
  <div class="col-6"></div>

  <p-accordion class="col-12">
    <p-accordionTab *ngIf="!settings.isFixed" header="Overlay">
      <div class="grid">
        <div class="col-6">
                <span class="dynamic-label dynamic-checkbox">
                  <span class="dynamic-checkbox-box">
                    <p-checkbox (onChange)="s.onOverlayModeChange()" [(ngModel)]="settings.isOverlay" binary="true" id="enableOverlay"></p-checkbox>
                    <label for="enableOverlay">{{ 'Enable overlay' | translate }}</label>
                  </span>
                </span>
        </div>
        <div class="col-6"></div>

        <div class="col-6">
          <app-color-picker [disabled]="!settings.isOverlay" [placeholder]="'Overlay color' | translate" [(color)]="settings.overlayColor"></app-color-picker>
        </div>
        <div class="col-6">
          <div class="dynamic-label p-float-label">
            <input
              [(ngModel)]="settings.overlayOpacity"
              [disabled]="!settings.isOverlay"
              (blur)="s.render()"
              pInputText
              type="text"
              id="opacity"
            />
            <label for="opacity" translate> Overlay transparent </label>
          </div>
        </div>

        <div class="col-6">
                <span class="dynamic-label dynamic-checkbox">
                  <span class="dynamic-checkbox-box">
                    <p-checkbox (onChange)="s.render()" [disabled]="!settings.isOverlay" [(ngModel)]="settings.isBlur" binary="true" id="isBlur"></p-checkbox>
                    <label for="isBlur">{{ 'Enable blur' | translate }}</label>
                  </span>
                </span>
        </div>
        <div class="col-6">
          <div class="dynamic-label p-float-label">
            <input
              [(ngModel)]="settings.blurValue"
              [disabled]="!settings.isBlur || !settings.isOverlay"
              (blur)="s.render()"
              pInputText
              type="text"
              id="blurValue"
            />
            <label for="blurValue" translate> Blur value </label>
          </div>
        </div>

        <div class="col-6">
                <span class="dynamic-label dynamic-checkbox">
                  <span class="dynamic-checkbox-box">
                        <p-checkbox [(ngModel)]="settings.isCloseByOverlayClick" binary="true" id="isCloseOverlay"></p-checkbox>
                        <label for="isCloseOverlay">{{ 'Close by overlay click' | translate }}</label>
                    </span>
                </span>
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Sidebar Design">
      <div class="grid">
        <div class="col-6">
          {{ 'Background color' | translate }}
        </div>
        <div class="col-6">
          <app-color-picker [(color)]="settings.bgColor" (colorChange)="onChange()"></app-color-picker>
        </div>

        <div class="col-6">
          <button (click)="borderOp.toggle($event)"
                  icon="bi bi-border-style"
                  label="{{'Borders' | translate}}"
                  pButton></button>
        </div>
      </div>

    </p-accordionTab>
    <p-accordionTab header="Button settings">
      <div class="grid mt-2">
        <div class="col-6">
                <span class="dynamic-label">
                  <p-dropdown
                    [(ngModel)]="settings.buttonContentFlexDirection"
                    [options]="options.flexDirection"
                    appendTo="body"
                    id="buttonContentDirection">
                  </p-dropdown>
                  <label class="dynamic-label-text" for="buttonContentDirection" translate> Direction </label>
                </span>
        </div>

        <div class="col-6">
                    <span class="dynamic-label">
                        <p-dropdown
                          [(ngModel)]="settings.alignButton"
                          [autoDisplayFirst]="false"
                          [options]="options.horizontalAlignOptions"
                          id="buttonAlignement">
                          </p-dropdown>
                        <label class="dynamic-label-text" for="buttonAlignement" translate> Align button </label>
                </span>
        </div>
      </div>

      <p-accordion class="col-12">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <!--<app-state-header [label]="'Close' | translate" [button]="settings.buttons.buttonView.closeNoHover" ></app-state-header>-->
            <div class="full-width-container">
              <span class="vertical-align-middle">Close</span>

            </div>

            <p-button (click)="stopEventPropagation($event);copyService.copyContent(settings.buttons.buttonView.closeNoHover)" class="mr-2"
                      styleClass="p-button-warning" icon="pi pi-copy" size="small" pTooltip="{{'Copy'| translate}}"></p-button>
            <p-button *ngIf="copyService.isCopiedButtonStyles"
                      (click)="stopEventPropagation($event);copyService.pasteStyles(settings.buttons.buttonView.closeNoHover)" class="mr-2"
                      styleClass="p-button-secondary" icon="pi bi-clipboard-plus" size="small" pTooltip="{{'Paste style'| translate}}"></p-button>
            <p-button *ngIf="copyService.isCopiedButtonStyles"
                      (click)="stopEventPropagation($event);copyService.pasteContent(settings.buttons.buttonView.closeNoHover)" class="mr-2"
                      styleClass="p-button-secondary" icon="pi bi-clipboard-plus" size="small" pTooltip="{{'Paste content'| translate}}"></p-button>
          </ng-template>
          <app-sidebar-modal-button-form [button]="settings.buttons.buttonView.closeNoHover" [settings]="settings"></app-sidebar-modal-button-form>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="full-width-container">
              <span class="vertical-align-middle">Close hover</span>

            </div>

            <p-button (click)="stopEventPropagation($event);copyService.copyContent(settings.buttons.buttonView.closeHover)" class="mr-2"
                      styleClass="p-button-warning" icon="pi pi-copy" size="small" pTooltip="{{'Copy'| translate}}"></p-button>
            <p-button *ngIf="copyService.isCopiedButtonStyles"
                      (click)="stopEventPropagation($event);copyService.pasteStyles(settings.buttons.buttonView.closeHover)" class="mr-2"
                      styleClass="p-button-secondary" icon="pi bi-clipboard-plus" size="small" pTooltip="{{'Paste style'| translate}}"></p-button>
            <p-button *ngIf="copyService.isCopiedButtonStyles"
                      (click)="stopEventPropagation($event);copyService.pasteContent(settings.buttons.buttonView.closeHover)" class="mr-2"
                      styleClass="p-button-secondary" icon="pi bi-clipboard-plus" size="small" pTooltip="{{'Paste content'| translate}}"></p-button>

          </ng-template>
          <app-sidebar-modal-button-form [button]="settings.buttons.buttonView.closeHover" [settings]="settings"></app-sidebar-modal-button-form>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="full-width-container">
              <span class="vertical-align-middle">Open</span>

            </div>

            <p-button (click)="stopEventPropagation($event);copyService.copyContent(settings.buttons.buttonView.openNoHover)" class="mr-2"
                      styleClass="p-button-warning" icon="pi pi-copy" size="small" pTooltip="{{'Copy'| translate}}"></p-button>
            <p-button *ngIf="copyService.isCopiedButtonStyles"
                      (click)="stopEventPropagation($event);copyService.pasteStyles(settings.buttons.buttonView.openNoHover)" class="mr-2"
                      styleClass="p-button-secondary" icon="pi bi-clipboard-plus" size="small" pTooltip="{{'Paste style'| translate}}"></p-button>
            <p-button *ngIf="copyService.isCopiedButtonStyles"
                      (click)="stopEventPropagation($event);copyService.pasteContent(settings.buttons.buttonView.openNoHover)" class="mr-2"
                      styleClass="p-button-secondary" icon="pi bi-clipboard-plus" size="small" pTooltip="{{'Paste content'| translate}}"></p-button>

          </ng-template>
          <app-sidebar-modal-button-form [button]="settings.buttons.buttonView.openNoHover" [settings]="settings"></app-sidebar-modal-button-form>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="full-width-container">
              <span class="vertical-align-middle">Open hover</span>

            </div>

            <p-button (click)="stopEventPropagation($event);copyService.copyContent(settings.buttons.buttonView.openHover)" class="mr-2"
                      styleClass="p-button-warning" icon="pi pi-copy" size="small" pTooltip="{{'Copy'| translate}}"></p-button>
            <p-button *ngIf="copyService.isCopiedButtonStyles"
                      (click)="stopEventPropagation($event);copyService.pasteStyles(settings.buttons.buttonView.openHover)" class="mr-2"
                      styleClass="p-button-secondary" icon="pi bi-clipboard-plus" size="small" pTooltip="{{'Paste style'| translate}}"></p-button>
            <p-button *ngIf="copyService.isCopiedButtonStyles"
                      (click)="stopEventPropagation($event);copyService.pasteContent(settings.buttons.buttonView.openHover)" class="mr-2"
                      styleClass="p-button-secondary" icon="pi bi-clipboard-plus" size="small" pTooltip="{{'Paste content'| translate}}"></p-button>

          </ng-template>
          <app-sidebar-modal-button-form [button]="settings.buttons.buttonView.openHover" [settings]="settings"></app-sidebar-modal-button-form>
        </p-accordionTab>
      </p-accordion>
    </p-accordionTab>
    <p-accordionTab header="Custom event listening">
      <app-custom-event-listening-settings
        [actionOptions]="options.actionOptions"
        [eventResponseSettings]="settings.eventResponseSettings">
      </app-custom-event-listening-settings>
    </p-accordionTab>
  </p-accordion>
</div>

<p-overlayPanel #borderOp [style]="{ 'max-width': '500px' }" appendTo="body">
  <ng-template pTemplate>
    <app-border-form (onChange)="s.onBorderShadowChange()" [allowShadowInput]="true" [styles]="styles"></app-border-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #overflowOverlayPanel [style]="{ 'max-width': '300px' }" appendTo="body">
  <ng-template pTemplate>
    <app-overflow-form [styleOptions]="styleOptions" [styles]="settings"></app-overflow-form>
  </ng-template>
</p-overlayPanel>
