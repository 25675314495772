import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileAttributeSelectIcon',
})
export class FileAttributeSelectIconPipe implements PipeTransform {

  transform(summaryPopupIcon: string | undefined): string {
    let result = " attribute-icon";
    if(summaryPopupIcon) result = summaryPopupIcon + result;
    else result = "bi bi-paperclip" + result;
    return result;
  }
}
