import { CONST_MIMETYPES_MAP } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/components/document-generation/avr-document-generation.non-mappable-fields.component';
import { AvrInputMappersType, AvrOutputTypes, AvrTypes, BaseAvrAbstractService } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGeneationAvrTemplates } from '@shared/services/artifact-visual-representation/document-generation/dto/document-geneation-avr-templates.dto';
import {
  DocumentGenerationAvrInputMapperDto,
  DocumentGenerationOutputTypes,
} from '@shared/services/artifact-visual-representation/document-generation/dto/document-generation.input-mapper.dto';
import { intersection } from 'lodash';

export class DocumentGenerationAvrService extends BaseAvrAbstractService<AvrTypes.documentGeneration, DocumentGenerationAvrInputMapperDto> {
  constructor() {
    super();
    this.superConstructor(AvrTypes.documentGeneration, DocumentGenerationAvrInputMapperDto, 'Document Generation', [...DocumentGenerationOutputTypes]);
  }

  static mimetypeIntersection = (outputTypes: AvrOutputTypes[]): string[] =>
    intersection(
      ...outputTypes.reduce((output, outputType) => {
        switch (outputType) {
          case AvrOutputTypes.pdf:
            output.push([...CONST_MIMETYPES_MAP[AvrOutputTypes.docx], ...CONST_MIMETYPES_MAP[AvrOutputTypes.pptx]]);
            break;
          case AvrOutputTypes.docx:
            output.push(CONST_MIMETYPES_MAP[AvrOutputTypes.docx]);
            break;
          case AvrOutputTypes.pptx:
            output.push(CONST_MIMETYPES_MAP[AvrOutputTypes.pptx]);
            break;
          default:
            throw new Error('outputType');
        }
        return output;
      }, [] as string[][]),
    );

  fromDto(inputMapperDto: AvrInputMappersType[AvrTypes.documentGeneration]): DocumentGenerationAvrInputMapperDto {
    // logic is part of HARDCODED BE WORKFLOW
    const avrMapper = super.fromDto(inputMapperDto);
    if (inputMapperDto.everythingWithinArtifactType === true && inputMapperDto.templates.length === 0) {
      avrMapper.nonMappableFields.templates!.value = [new DocumentGeneationAvrTemplates({ fileArtifactId: '' })];
    }
    return avrMapper;
  }

  toServer(inputMapperDto: DocumentGenerationAvrInputMapperDto): AvrInputMappersType[AvrTypes.documentGeneration] {
    // logic is part of HARDCODED BE WORKFLOW
    if (
      inputMapperDto.nonMappableFields.everythingWithinArtifactType!.value === true &&
      inputMapperDto.nonMappableFields.templates!.value.length === 1 &&
      inputMapperDto.nonMappableFields.templates!.value[0].fileArtifactId === ''
    ) {
      inputMapperDto.nonMappableFields.templates!.value = [];
    }
    return super.toServer(inputMapperDto);
  }
}
