import { ResetActionWorkflowSettingsDto } from '@api/models/reset-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowTriggerEvent } from '@workflows/types';
import { AbstractWorkflowAction } from '@workflows/types/actions/abstract/abstract.action';
import { RuleConditionValueConverterService } from '../../shared/services/rule-condition-value-converter.service';
import { WorkflowActionType } from '../action.types';
import { NewArtifact } from '@shared/types/artifact.types';

export enum ActionResetKeys {
  actionType = 'actionType',
}

export class WorkflowActionResetDto implements ResetActionWorkflowSettingsDto {
  actionType: ResetActionWorkflowSettingsDto[ActionResetKeys.actionType] = { value: WorkflowActionType.RESET, isDynamic: false };

  constructor(action?: WorkflowActionResetDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionReset extends AbstractWorkflowAction<WorkflowActionResetDto> {
  attributeId?: string;

  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionResetDto, type: WorkflowActionType.RESET, dto, conditionConverter });
    this.attributeId = dto?.attributeId || this.attributeId;
  }

  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean {
    return true;
  }

  execute(ruleTriggerEvent: WorkflowTriggerEvent, artifact?: NewArtifact): void {
    // TODO: implement
  }

  isValid(): boolean {
    return true;
  }

  isAttributeRequired(): boolean {
    return true;
  }

  toServer(): WorkflowActionDto {
    return {
      ...super.toServer(),
      attributeId: this.attributeId,
      actionSettings: {
        [ActionResetKeys.actionType]: {
          isDynamic: false,
          value: this.actionSettings.actionType.value,
        },
      },
    };
  }
}
