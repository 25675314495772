import { Pipe, PipeTransform } from '@angular/core';
import { WorkflowAction, WorkflowActionType } from '../types';
import { AbstractWorkflowAction } from '../types/actions/abstract/abstract.action';
import { WorkflowActionFormat } from '../types/actions/action-format';

@Pipe({
  name: 'getFormatActions',
  standalone: true,
})
export class GetFormatActionsPipe implements PipeTransform {
  transform(actions: WorkflowAction[]): WorkflowActionFormat[] {
    return actions.filter(action => (action as AbstractWorkflowAction<any>).type === WorkflowActionType.FORMAT) as WorkflowActionFormat[];
  }
}
