<div class="grid">
    <div class="col-6 mb-0 text-center">
        <button
                (click)="selectedAction = action; queryParamsOp.toggle($event)"
                label="{{ 'Query parameters' | translate }}"
                class="w-100"
                icon="bi bi-question-lg"
                pButton
                type="button"
        ></button>
    </div>
    <div class="col-6 mb-0 text-center">
        <button
                (click)="selectedAction = action; fragmentParamOp.toggle($event)"
                label="{{ 'Scroll to div parameter' | translate }}"
                class="w-100"
                icon="bi bi-hash"
                pButton
                type="button"
        ></button>
    </div>
</div>

<p-overlayPanel #queryParamsOp [style]="{ 'min-width': '800px' }" appendTo="body">
    <div *ngIf="selectedAction" class="p-grid query-params-overlay">
        <div *ngFor="let param of selectedAction.queryParams; let i = index" class="row">
            <div class="col-3">
                <p-dropdown [(ngModel)]="param.action" [options]="linkQueryParamAction" [autoDisplayFirst]="false"
                            appendTo="body">
                    <ng-template pTemplate="selectedItem" let-selected> {{ selected.label | translate }}</ng-template>
                    <ng-template let-option pTemplate="item"> {{ option.label | translate }}</ng-template>
                </p-dropdown>
            </div>
            <div
                    [ngClass]="{ 'col-4': param.action === queryParamActions.addNew, 'col-8': param.action === queryParamActions.removeExisting || param.action === queryParamActions.holdExisting }">
                <input type="text" [id]="'queryParamKey' + i" pInputText [(ngModel)]="param.key" placeholder="Enter key"
                       class="w-100" />
            </div>
            <div *ngIf="param.action === queryParamActions.addNew" class="col-4">
                <input type="text" [id]="'queryParamVal' + i" pInputText [(ngModel)]="param.value" placeholder="Enter value"
                       class="w-100" />
            </div>
            <div class="col-1 text-center">
                <button
                        pButton
                        class="p-button p-button-rounded p-button-danger p-button-outlined"
                        type="button"
                        pTooltip="{{ 'Delete' | translate }}"
                        icon="pi pi-trash"
                        iconPos="left"
                        (click)="onDeleteParamClick(i)"
                ></button>
            </div>
        </div>
        <button (click)="onAddQueryParamClick()" label="{{ 'Add' | translate }}" icon="pi pi-plus" pButton
                type="button"></button>
    </div>
</p-overlayPanel>

<p-overlayPanel #fragmentParamOp [style]="{ 'max-width': '380px' }" appendTo="body">
    <div *ngIf="selectedAction">
        <input
                type="text"
                pInputText
                [(ngModel)]="selectedAction.fragmentParam"
                (ngModelChange)="onActionChange()"
                placeholder="Enter div ID to scroll into"
        />
    </div>
</p-overlayPanel>
