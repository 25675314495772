import { Component, Input, ViewChild } from '@angular/core';
import { ArtifactHistoryResponseDto } from '@api/models/artifact-history-response-dto';
import { TenantArtifactHistoryService } from '@api/services/tenant-artifact-history.service';
import { CREATED_ON_KEY } from '@shared/constants/constants';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Component({
  selector: 'app-artifact-history',
  templateUrl: './artifact-history.component.html',
  styleUrls: ['./artifact-history.component.scss'],
})
export class ArtifactHistoryComponent {
  @Input() artifactTypes: ListContainer<NewArtifactType>;
  @Input() attributes: ListContainer<NewAttribute>;
  @Input() dataTypes: ListContainer<NewDataType>;

  @ViewChild('modal') modal: any;

  artifactHistory: ArtifactHistoryResponseDto[] = [];
  artifactType: NewArtifactType | null = null;
  displayModal = false;

  constructor(
    private readonly tinyMceHelper: TinyMceHelper,
    private readonly tenantArtifactHistoryService: TenantArtifactHistoryService,
    private readonly announcement: AnnouncementService,
  ) {}

  async loadAndOpenHistory(artifact: NewArtifact | null): Promise<void> {
    if (!artifact) return;

    try {
      const historyMeta = { filter: JSON.stringify({ artifactId: { $oid: artifact.id } }), sort: JSON.stringify({ [CREATED_ON_KEY]: -1 }) };
      const history = await this.tenantArtifactHistoryService.artifactHistoryControllerList(historyMeta).toPromise();
      if (history) {
        this.artifactType = this.artifactTypes.listMap[artifact.artifactTypeId];
        this.processImagesInHistoryRecords(history.data);
        this.artifactHistory = history.data;
        this.displayModal = true;
      }
    } catch (e) {
      console.error(e);
      await this.announcement.error('Failed to load artifact operation history');
    }
  }

  private processImagesInHistoryRecords(history: ArtifactHistoryResponseDto[]): void {
    history.forEach(record => {
      for (const attributeId in record.attributes) {
        if ((record.attributes as any)[attributeId]?.new?.value && typeof (record.attributes as any)[attributeId]?.new?.value === 'string') {
          (record.attributes as any)[attributeId].new.value = this.tinyMceHelper.addOrRemoveImageAuth((record.attributes as any)[attributeId].new.value, false);
        }
        if ((record.attributes as any)[attributeId]?.old?.value && typeof (record.attributes as any)[attributeId]?.new?.value === 'string') {
          (record.attributes as any)[attributeId].old.value = this.tinyMceHelper.addOrRemoveImageAuth((record.attributes as any)[attributeId].old.value, false);
        }
      }
    });
  }
}
