import { SafeUrl } from '@angular/platform-browser';
import { FolderDataResponseDto } from '@api/models/folder-data-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { cloneDeep } from 'lodash';
import { FileExtensions } from '@private/types/file-service.types';
import { ArtifactAttributeResponseDto, ArtifactFormatFileDataResponseDto, ArtifactResponseDto } from '@api/models';

export class FileWithUrl implements ArtifactResponseDto {
  artifactTypeId: string;
  attributes: Record<string, ArtifactAttributeResponseDto>;
  created: RecordDto;
  deleted: null | RecordDto;
  folderData: FolderDataResponseDto;
  format: 'FILE';
  formatData: ArtifactFormatFileDataResponseDto;
  id: string;
  importStamp = null;
  moduleData = null;
  updated: RecordDto;
  url: SafeUrl;

  constructor(dto: Partial<FileWithUrl>) {
    Object.assign(this, cloneDeep(dto));
  }

  get isImage(): boolean {
    return Object.values(FileExtensions).findIndex(extension => `image/${extension}` === this.formatData.mimetype) !== -1;
  }

  get isPDF(): boolean {
    return Object.values(FileExtensions).findIndex(extension => `application/${extension}` === this.formatData.mimetype) !== -1;
  }
}
