<div cdkDropList (cdkDropListDropped)="dropItem($event)">
  <div *ngFor="let action of actions; let i = index" class="grid mt-2 action-wrapper" cdkDrag>
    <div class="col-1 p-1">
      <i class="bi bi-grip-vertical" cdkDragHandle pTooltip="{{'Move' | translate}}" tooltipPosition="top" pRipple></i>
    </div>
    <div class="col-9 p-1">
      {{ action.type | translate }}
      @if (!action.isEdit && action.type | isArtifactWidgetSpecial) {
        <span class="action-hash"> ({{ action.widgetAlias }}) </span>
      }
    </div>
    <div class="col-1 p-1 pt-2 center cursor-pointer">
      <i class="pi" [ngClass]="action.isEdit ? 'pi-check': 'pi-pencil'" (click)="toggleEdit(action, i)"></i>
    </div>
    <div class="col-1 p-1 right">
      <a *ngIf="actions.length > 1" (click)="remove(i)"
         class="p-1 pi pi-trash p-button p-button-rounded p-button-text p-button-danger" pRipple> </a>
    </div>

    <ng-container *ngIf="action.isEdit">
      <div class="col-12">
        <p-treeSelect
          [(ngModel)]="action.selectedType"
          [options]="elvisActions"
          (onNodeSelect)="onNodeSelect($event, i)"
          [placeholder]="'Select action' | translate"
          [filter]="true"
          [filterInputAutoFocus]="true"
          class="w-full"
          containerStyleClass="w-full"
        >
          <ng-template let-expanded pTemplate="itemtogglericon">
            <i [class]="expanded ? 'pi pi-minus-circle' : 'pi pi-plus-circle'"></i>
          </ng-template>
        </p-treeSelect>
      </div>

      <div class="col-12" *ngIf="action.type | isListWidgetSpecial">
        <app-list-widget-actions
          [action]="action"
          [filtersDisabled]="filtersDisabled"
          [filtersHolder]="filtersHolder"
          [filtersKey]="filtersKey"
          [options]="options"
          [isAddToLinkDisabled]="isAddToLinkDisabled"
          [showFolderEmittingOption]="showFolderEmittingOption"
          [isHideMultiselect]="isHideMultiselect"
          [multiselect]="multiselect"
          (isMultiselectChange)="isMultiselectChange.emit($event)"
          (actionChange)="onActionChange()"
        ></app-list-widget-actions>
      </div>

      <div class="col-12" *ngIf="action.type | isMenuWidgetSpecial">
        <app-menu-widget-actions
          [action]="action"
          (actionChange)="onActionChange()"
        ></app-menu-widget-actions>
      </div>

      <div *ngIf="action.type | isArtifactWidgetSpecial" class="col-12">
        <app-artifact-widget-actions [action]="action" (actionChange)="onActionChange()"></app-artifact-widget-actions>
      </div>

      <div class="col-12" *ngIf="action.type === defaultElvisActions.customEvent">
        <p-floatLabel>
          <input pInputText id="custom-event" class="full-width-container" [(ngModel)]="action.model.name" />
          <label for="custom-event" translate>Event name</label>
        </p-floatLabel>
      </div>

      <ng-container *ngIf="action.type === currentPageBehavior.leaveCurrentPage">
        <div class="col-4 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox [(ngModel)]="action.isPageSelection" (ngModelChange)="onActionChange()"
                          binary="true" id="typeUrl" class="mr-1"></p-checkbox>
              <label
                for="typeUrl">{{ action.isPageSelection ? ('Page' | translate) : ('Url' | translate) }}</label>
            </span>
          </span>

          <span class="dynamic-label dynamic-checkbox" *ngIf="action.isPageSelection">
              <span class="dynamic-checkbox-box">
                <p-checkbox [(ngModel)]="action.useAlias" (ngModelChange)="onActionChange()" binary="true"
                            id="useAlias" class="mr-1"></p-checkbox>
                <label for="useAlias">{{ 'Use page alias' | translate }}</label>
              </span>
          </span>

          <span class="dynamic-label dynamic-checkbox">
              <span class="dynamic-checkbox-box">
                <p-checkbox [(ngModel)]="action.openInNewTab" (ngModelChange)="onActionChange()" binary="true"
                            id="openInNewTab" class="mr-1"></p-checkbox>
                <label for="openInNewTab">{{ 'Open page in new tab' | translate }}</label>
              </span>
          </span>

          <span class="dynamic-label dynamic-checkbox">
              <span class="dynamic-checkbox-box">
                <p-checkbox [(ngModel)]="action.holdAllUrlParam" (ngModelChange)="onActionChange()" binary="true"
                            id="holdAllUrlParam" class="mr-1"></p-checkbox>
                <label for="holdAllUrlParam">{{ 'Keep all params' | translate }}</label>
              </span>
          </span>
        </div>

        <div class="col-8 my-0">
                  <span *ngIf="!action.isPageSelection" class="dynamic-label">
                    <input
                      #urlInput="ngModel"
                      [(ngModel)]="action.url"
                      (ngModelChange)="onActionChange()"
                      [pattern]="http_https_validation_regex"
                      id="url"
                      pInputText
                      type="text"
                    />
                    <label class="dynamic-label-text" for="url" translate>{{ 'Url' | translate }}</label>
                    <div *ngIf="urlInput.errors?.pattern" class="mt-3">
                      <p class="text-red">{{ 'Please include' | translate }} <span class="text-bold"> http:// </span> or <span
                        class="text-bold"> https:// </span></p>
                    </div>
                  </span>

          <span *ngIf="action.isPageSelection" class="dynamic-label">
                    <p-dropdown
                      [(ngModel)]="action.pageId"
                      (onChange)="onPageSelect(action)"
                      [options]="pages.list | transformArrayToSelectOptions : NAME_KEY : ID_VALUE"
                      [autoDisplayFirst]="false"
                      [filter]="true"
                      id="page"
                      appendTo="body"
                      [virtualScroll]="false"
                      [virtualScrollItemSize]="10"
                      appNotifyIfSelectedPageDeletedDirective
                      [selectedPageId]="action.pageId"
                    >
                    </p-dropdown>
                    <label class="dynamic-label-text" for="page">{{ 'Select Page' | translate }}</label>
                  </span>
        </div>
      </ng-container>

      <div *ngIf="action.type === currentPageBehavior.stayOnCurrentPage" class="col-12 mb-0">
                <span class="dynamic-label dynamic-checkbox">
                  <span class="dynamic-checkbox-box">
                    <p-checkbox
                      [(ngModel)]="showEmptyParamsAsActive"
                      (ngModelChange)="showEmptyParamsAsActiveChange.emit($event); onActionChange();"
                      binary="true"
                      id="showNoParamsAsActive"
                    >
                    </p-checkbox>
                    <label for="showNoParamsAsActive">{{ 'Show item with no parameters as active' | translate }}</label>
                  </span>
                </span>
      </div>

      <div *ngIf="action.type === currentPageBehavior.leaveCurrentPage || action.type === currentPageBehavior.stayOnCurrentPage" class="col-12 mb-0">
          <app-query-params
            [action]="action"
            [selectedAction]="selectedAction"
            (actionChange)="onActionChange()">
          </app-query-params>
        </div>

    </ng-container>
  </div>
  <div class="col-12 center p-0 pt-2">
    <i class="bi bi-plus-circle-fill cursor-pointer pl-4 pr-4" (click)="add()"></i>
  </div>
  <div class="col-12 center p-0">
    <button class="p-button p-button-text p-0 add-btn" pButton type="button" (click)="add()">Add action</button>
  </div>
</div>
